const AccessDenied = () => {
  return (
    <div className="center-container">
      <div className="center-container-wrapper">
        <div className="center-element">
          <div className="normal-1x zoomIn">
            <div className="tfx-user-form-wrapper box-effect full-border">
              <div className="tfx-user-form-inner">
                <div
                  id="adv-processing-signup-successful"
                  className="loading-overlay"
                >
                  <div className="form-loader form-loader-login verify ">
                    <div className="centerLoader">
                      <div className="loading success-loader">
                        <i className="animated float-label webapp-ripple-notification error-text fab fa-grav" />
                      </div>

                      <div className="center-txt webapp-ripple-text">
                        Access Denied!!
                      </div>

                      <div className="onboarding-response center-txt webapp-ripple-text">
                        We are sorry but you are unauthorised to access this
                        page.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
