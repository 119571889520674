import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// Router
import { NavLink, withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import useRepaymentReport from "../../custom-hooks/useRepaymentReportDetails";
import { showModal } from "./../../redux/actions/modal";

// UI Components
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { formatMoney } from "../../helpers/formatter";

const today = new Date().toISOString().slice(0, 10);
const lastMonth = new Date(new Date());
lastMonth.setDate(lastMonth.getDate() - 30);
const aMonthAgo = lastMonth.toISOString().slice(0, 10);

const RepaymentReports = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(20);
  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [date, setDate] = useState({
    FromDate: aMonthAgo,
    ToDate: today,
  });

  //  Methods
  const modalHandler = (modalData, type) => {
    props.showModal({
      modalIdentifier: "audittrail",
      dataRecord: modalData,
      action: "customrange",
      type,
    });
  };

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  const filterData = (e) => {
    e.preventDefault();

    const diffTime = new Date(endDate) - new Date(startDate);
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    }

    resetTable();
    setDate({
      FromDate: startDate,
      ToDate: endDate,
    });
  };

  //  Life Cycle Hooks
  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!props.isAuthenticated) {
      props.history.push("/login");
    }
  }, [props.history, props.isAuthenticated, props.loggedInUser]);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error } = useRepaymentReport({
    FromDate: date.FromDate,
    ToDate: date.ToDate,
    page: pageNumber,
    size,
  });

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Repayment Reports
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Repayment Reports..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              repayment reports, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Loan Ref</th>
                <th>Principal</th>
                <th>Principal Paid</th>
                <th>Interest</th>
                <th>Interest Paid</th>
                <th>Installment</th>
                <th>Days Due</th>
                <th>Due Date</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                data.data.map((repaymentReport, _id) => {
                  const {
                    loanRef,
                    installment,
                    principal,
                    principalPaid,
                    interest,
                    interestPaid,
                    daysOverdue,
                    dateDue,
                    currency,
                  } = repaymentReport;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{loanRef}</td>
                      <td>
                        {principal
                          ? formatMoney(principal, currency)
                          : principal}
                      </td>
                      <td>
                        {principalPaid
                          ? formatMoney(principalPaid, currency)
                          : principalPaid}
                      </td>
                      <td>
                        {interest ? formatMoney(interest, currency) : interest}
                      </td>
                      <td>
                        {interestPaid
                          ? formatMoney(interestPaid, currency)
                          : interestPaid}
                      </td>
                      <td>{installment}</td>
                      <td>{daysOverdue}</td>
                      <td>{dateDue.substring(0, 10)}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }
  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Repayment Reports Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12 dashboard-title flex__row">
              <h3 className="m-l-1">
                <aside className="rounder">1</aside>
                &nbsp; Reports
              </h3>

              <div className="breadcrumb__location">
                <NavLink to="dashboard">
                  <h3 className="breadcrumb__location__title">Dashboard</h3>
                </NavLink>
                <span className="breadcrumb__location__icon">
                  <i className="fas fa-angle-double-right" />
                </span>
                <NavLink to="reports">
                  <h3 className="breadcrumb__location__title">
                    Pending Repayment
                  </h3>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
              <label htmlFor="table-fetch" className="form-group">
                Number of results:{" "}
                <select
                  onChange={(e) => {
                    resetTable();
                    setSize(e.target.value);
                  }}
                  id="table-fetch"
                  className="table-fetch-select m-l-1"
                  value={size}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>

            <form className="d-flex flex-row m-t-md" onSubmit={filterData}>
              <div
                className="d-flex flex-row flex-wrap justify-content-start"
                style={{ alignItems: "flex-end" }}
              >
                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">Start Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={aMonthAgo}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">End Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={today}
                    max={today}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <button
                  className="m-l-1 m-r-1 zoom-element btn login-btn"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </form>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={count}
          changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    authReducer: { isAuthenticated, loggedInUser },
  } = state;

  return {
    isAuthenticated,
    loggedInUser,
  };
};

export default withRouter(
  connect(mapStateToProps, { showModal })(RepaymentReports)
);
