import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TReceiverWalletDetails = {
  accountNumber: string;
  accountName: string;
  bankCode: string;
  bankName: string;
  currency: string;
};

const getFundingReceiverWalletDetails = async () => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_FUNDING_RECEIVER_WALLET_DETAILS}`
  );

  return data;
};

export default function useFundingReceiverWalletDetails() {
  return useQuery<IResponse<TReceiverWalletDetails>>(
    "getFundingReceiverWalletDetails",
    getFundingReceiverWalletDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
