import React, { FunctionComponent, useState } from "react";
import { Loader } from "../../../../UI/Loaders/Loaders";
import RequestsErrorMessage from "../../components/RequestsErrorMessage";
import RequestsAlert from "../../components/RequestsAlert";
import RequestsInfo from "../../components/RequestsInfo";
import RequestsSearchAndSizeControls from "../../components/RequestsSearchAndSizeControls";
import Paginator from "../../../Pagination/Pagination.component";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../../../redux/actions/modal";
import { usePendingAdminWalletWithdrawalRequests } from "../../../../custom-hooks/usePendingAdminWalletRequests";
import WithdrawalRequestsDataTable from "../../components/WithdrawalRequestsDataTable";
import WalletWithdrawalDataTable from "../../components/WalletWithdrawalDataTable";

const AdminWalletWithdrawal: FunctionComponent = (props: any) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [size, setSize] = useState<number>(20);
  const [searchValue, setSearchValue] = useState<string>("");
  const {
    data: pendingAdminWalletWithdrawals,
    status,
    error,
  } = usePendingAdminWalletWithdrawalRequests();

  const renderContent = (): JSX.Element | null => {
    switch (status) {
      case "loading":
        return <Loader centered={true} text="Loading withdrawal requests..." />;
      case "error":
        return (
          <RequestsErrorMessage message="There was an issue while fetching pending withdrawal requests, Please Reload The Page" />
        );
      case "success":
        if (
          !pendingAdminWalletWithdrawals ||
          pendingAdminWalletWithdrawals.length === 0
        ) {
          return (
            <RequestsErrorMessage message="There are currently no pending withdrawal requests." />
          );
        }
        return (
          <WalletWithdrawalDataTable
            data={pendingAdminWalletWithdrawals}
            showModal={props.showModal}
            hideModal={props.hideModal}
            initiator="admin"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <RequestsAlert />
      {status !== "loading" && (
        <div className="d-flex justify-content-between">
          <RequestsInfo />
          <RequestsSearchAndSizeControls
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            size={size}
            onSizeChange={setSize}
          />
        </div>
      )}
      {renderContent()}
      <Paginator
        size={size}
        page={pageNumber}
        count={
          pendingAdminWalletWithdrawals
            ? pendingAdminWalletWithdrawals.length
            : 0
        }
        changeCurrentPage={setPageNumber}
      />
    </div>
  );
};

export default connect(null, { showModal, hideModal })(AdminWalletWithdrawal);
