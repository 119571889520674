import { useLocation } from "react-router-dom";
import useWalletTransactionLog from "../../custom-hooks/useWalletTransactionLog";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import Loader from "../../NewComponents/Loader/Loader.component";
import NoTableDataAvailable from "../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";

type TState = {
  data: {
    account_name: string;
    account_number: string;
    aggregator_id: number;
    aggregator_name: string;
    amount: number;
    bank_code: string;
    bank_name: string;
    id: number;
    macker_checker_status: number;
    maker_checker_reference: string;
    narration: string;
    pub_date: string;
    status: string;
    transaction_date: string;
    transaction_reference: string;
  };
};
const WalletTransactionLog = () => {
  const location = useLocation();

  const state = location?.state as TState;

  const {
    data: transactionLog,
    status,
    error,
  } = useWalletTransactionLog({
    transaction_ref: state?.data?.maker_checker_reference,
  });

  return (
    <div>
      <div className="d-flex align-items-center justify-content-start">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
          Wallet Transaction Approval Log
        </h3>
      </div>
      {error && (
        <p className="alert alert-danger small">{JSON.stringify(error)}</p>
      )}
      {!error && (
        <div className="mt-4">
          <div className="card">
            {status === "loading" ? (
              <div className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <div className="card-body p-3 mt-2">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>Email</th>
                        <th>Approval Comment</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionLog &&
                      transactionLog?.data?.data?.data.length > 0 ? (
                        transactionLog?.data?.data?.data.map((log, _id) => {
                          const { email, approvalComment, createdAt } = log;
                          return (
                            <tr key={_id}>
                              <td>{email}</td>
                              <td>{approvalComment}</td>
                              <td>{createdAt}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable text="" />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletTransactionLog;
