import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getMobileVersionSettings = async () => {
  const response = await getData(ajaxEndpoints.GET_MOBILE_VERSION_SETTINGS);
  return response?.data;
};

export default function useMobileVersionSettings() {
  return useQuery(["mobileVersion"], getMobileVersionSettings, {
    refetchOnWindowFocus: false,
  });
}
