import { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import { getAllInvestors } from "../../../../custom-hooks/useAllInvestors";
import moment from "moment";

import {
  formatMoney,
  // resolveDurationName,
} from "../../../../helpers/formatter";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { convertToBase64 } from "../../../../helpers/convertToBase64";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import useInvestmentProductRate from "../../../../custom-hooks/useInvestmentProductRate";
import CustomSearchDropDown from "../../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { appInsights } from "../../../../config/appInsights";
import { getCurrencyInfo } from "../../helper";
import { useWalletDetailsByInvestmentProduct } from "../../../../custom-hooks/useWallet";

type TValidator = {
  status: boolean;
  type?: string;
  message: string;
};

export default function CreateInvestmentModal({
  onSubmit,
  dismissModalRef,
  modalActionType,
  discardChanges,
  loading,
  handleChange,
  state,
  setState,
  logInvestment,
  data,
  setModalActionType,
  handleChangedState,
  setLoading,
  resetState,
  handleCurrency,
  investRateId,
  tenantId,
}: any) {
  const [submitLoading, setSubmitLoading] = useState(false);
  //eslint-disable-next-line
  const [, setSubmitError] = useState(false);
  const [error, setError] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [daysOfYear, setDaysOfYear] = useState("");
  const [calculatedInterest, setCalculatedInterest] = useState({
    interest_calculated: "",
    maturity_amount: "",
    monthly_interest_payable: "",
    monthly_interest_accruals: [],
  });
  const [validatorObject, setValidatorObject] = useState<TValidator>({
    status: false,
    type: "",
    message: "",
  });
  const [amountToCreditValidator, setAmountToCreditValidator] =
    useState<TValidator>({
      status: false,
      type: "",
      message: "",
    });
  const [amount_to_credit, setAmountToCredit] = useState(0);
  const [, setWalletId] = useState();
  const [numberOfDaysInYear, setNumberOfDaysInYear] = useState(0);

  const history = useHistory();

  const onChangeReceipt = async (e: any) => {
    if (!e.target.files[0]) {
      setState((oldState: any) => {
        return {
          ...oldState,
          transactionReceiptBase64: "",
          transactionReceiptName: "",
        };
      });
      return;
    }
    setError("");
    const fileType = e.target.files[0].type;
    const isValidFile =
      fileType.startsWith("image/png") ||
      fileType.startsWith("image/jpg") ||
      fileType.startsWith("image/jpeg") ||
      fileType.startsWith("application/pdf");

    if (!isValidFile) {
      e.target.value = "";
      return setError("Invalid File type.");
    }
    const fileBase64 = await convertToBase64(e.target.files[0]);

    setState((oldState: any) => {
      return {
        ...oldState,
        transactionReceiptBase64: fileBase64,
        transactionReceiptName: e.target.files[0].name,
      };
    });
  };

  const validateTopUpAmount = (e: any) => {
    const amountInQuestion = Number(e.target.value);
    const principalAmount = Number(state?.principal_amount);
    const walletBalance = Number(wallet?.availableBalance || 0);

    const validateAmt = principalAmount - walletBalance;

    if (principalAmount > walletBalance) {
      if (amountInQuestion && amountInQuestion < validateAmt) {
        setAmountToCreditValidator({
          status: true,
          type: "maxLength",
          message: `Investment Top Up Amount cannot be less than: ${formatMoney(
            principalAmount - walletBalance
          )}`,
        });
      } else {
        setAmountToCreditValidator({
          status: false,
          message: "",
        });
        setAmountToCredit(amountInQuestion);
      }
    } else {
      setAmountToCredit(Number(e.target.value));
      setAmountToCreditValidator({
        status: false,
        message: "",
      });
    }
  };

  const validateInvestmentPrincipal = (e: any) => {
    const amountInQuestion = e.target.value;

    const productDetails = data?.investment_product_details.find(
      (item: any) => item?.currency_code === state?.currency
    );

    if (
      amountInQuestion &&
      amountInQuestion > productDetails?.maximum_investment
    ) {
      setValidatorObject({
        status: true,
        type: "maxLength",
        message: `Investment principal cannot be greater than the maximum investment amount: ${formatMoney(
          productDetails?.maximum_investment
        )}`,
      });
    } else if (
      amountInQuestion &&
      amountInQuestion < productDetails?.minimum_investment
    ) {
      setValidatorObject({
        status: true,
        message: `Investment principal cannot be less than the minimum investment amount: ${formatMoney(
          productDetails?.minimum_investment
        )}`,
      });
    } else {
      setValidatorObject({
        status: false,
        message: "",
      });
    }
  };

  const {
    data: InvestmentProductRate,
    isLoading,
    error: rateError,
  } = useInvestmentProductRate({
    investRateId,
    enteredPrincipalAmount: state?.principal_amount ?? 0,
    tenure: state?.tenure ?? 0,
    tenantId: tenantId,
    depositType:
      data?.investment_product_details[0]?.deposit_type === "RecurringDeposit"
        ? 4
        : 3,
  });

  const handleInvestorChange = (record: any) => {
    setState((prevState: any) => {
      return {
        ...prevState,
        funder_id: record?.value,
        wallets: record?.wallets,
      };
    });
    const selectedWallet = record?.wallets.find(
      (w: any) => w.currency === state.currency && w.isExternal === true
    );
    if (selectedWallet) {
      setWalletId(selectedWallet?.walletId);
    }
  };

  function getOptions(query: any) {
    return new Promise((resolve, reject) => {
      getAllInvestors({ searchValue: query })
        .then((data) => {
          resolve(
            data.map(({ funder_id, first_name, last_name, wallets }: any) => ({
              value: funder_id,
              label: `${first_name} ${last_name} / ${funder_id} `,
              wallets: wallets,
            }))
          );
        })
        .catch(reject);
    });
  }

  const submitLoggedInvestment = async (e: any) => {
    e.preventDefault();

    if (!state.transactionReceiptBase64) {
      setError("Please upload the receipt of this transaction");
      return;
    }

    setLoading(true);
    setError("");
    const {
      funder_id,
      principal_amount,
      // interest_rate,
      tenure,
      currency,
      interest_repayment_type,
      effective_date,
      transactionReceiptBase64,
      transactionReceiptName,
    } = state;

    let investmentProductDetail = data.investment_product_details.find(
      (item: any) => item.currency_code === currency
    );

    const reqBody = {
      funder_id: Number(funder_id),
      interest_calculation_days_in_year_type:
        daysOfYear === "" ? Number(numberOfDaysInYear) : Number(daysOfYear),
      investment_product_details_id: Number(investmentProductDetail?.id),
      principal_amount: Number(principal_amount),
      interest_rate:
        InvestmentProductRate?.data?.depositProductInterestRate?.interest,
      // interest_rate: Number(interest_rate),
      tenure: Number(tenure),
      currency,
      interest_repayment_type,
      effective_date: effective_date || new Date().toISOString(),
      base64_file_string: transactionReceiptBase64,
      filename_with_extension: transactionReceiptName,
      amount_to_credit,
    };
    await logInvestment(reqBody);
    setDaysOfYear("");
  };
  // const filteredInvestmentRange = numberOfDaysInYear?.interest_ranges.find(
  //   (range) =>
  //     range.lower_amount <= Number(state.principal_amount) &&
  //     range.upper_amount >= Number(state.principal_amount)
  // );
  const setType = (e: any) => {
    e.preventDefault();
    setModalActionType("step2");
  };
  const calculateInterest = async (e: any) => {
    e.preventDefault();
    setSubmitLoading(true);
    const reqBoday = {
      // interest_rate: Number(state.interest_rate),
      interest_rate:
        InvestmentProductRate?.data?.depositProductInterestRate?.interest,
      principal_amount: Number(state?.principal_amount),
      tenure: Number(state.tenure),
      start_date: state.effective_date,
      interest_repayment_type: e.target.value,
      number_of_days_in_year:
        daysOfYear === "" ? Number(numberOfDaysInYear) : Number(daysOfYear),
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.CALCULATE_INVESTMENT_INTEREST}`,
        reqBoday
      );
      setSubmitLoading(false);
      setCalculatedInterest({
        ...calculatedInterest,
        interest_calculated: response.data.interest_amount,
        maturity_amount: response.data.maturity_amount,
        monthly_interest_payable: response.data.monthly_interest_payable,
        monthly_interest_accruals: response.data.monthly_interest_accruals,
      });
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreateInvestmentModal.jsx",
        },
      });
      setSubmitLoading(false);
      setSubmitError(errorHandler(error));
    }
  };

  const {
    data: wallet,
    status,
    isLoading: loadingWallet,
    error: walletError,
  } = useWalletDetailsByInvestmentProduct(
    data?.id,
    state?.funder_id,
    state?.currency
  );

  useEffect(() => {
    console.log({
      currencyCode,
    });
    if (currencyCode) {
      if (currencyCode === "USD") {
        setNumberOfDaysInYear(
          Number(data?.usd_product?.number_of_days_in_year)
        );
        setState({
          ...state,
          number_of_days_in_year: Number(
            data?.usd_product?.number_of_days_in_year
          ),
        });
      } else {
        setNumberOfDaysInYear(
          Number(data?.lcy_product?.number_of_days_in_year)
        );
        setState({
          ...state,
          number_of_days_in_year: Number(
            data?.lcy_product?.number_of_days_in_year
          ),
        });
      }
    }
  }, [currencyCode]);

  return (
    <Fragment>
      <div
        className="modal fade"
        id="createInvestmentModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="createInvestmentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {(() => {
              switch (modalActionType) {
                case "update":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Update Documents</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Updates you make to the Investment funds will be
                          effected.
                        </p>
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                                disabled={loading}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? "Updating..." : "Confirm Update"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "cancel":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Discard Changes</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Are you sure you want to discard? All the information
                          provided so far will be discarded.
                        </p>
                        <form onSubmit={discardChanges}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                onClick={() => history.goBack()}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "deactivate":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Deactivate Fund</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Enter the generated 2FA to confirm deactivation
                        </p>
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <CustomInputField
                                label="Generated 2FA"
                                name="otp"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12">
                              <CustomInputField
                                label="Comment"
                                name="comment"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "goback":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Discard Changes</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Are you sure you want to discard? All the information
                          provided so far will be discarded.
                        </p>
                        <form onSubmit={discardChanges}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                onClick={() => history.goBack()}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "activate":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">
                          Two Factor Authentication
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Enter the generated 2FA to confirm activation
                        </p>
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <CustomInputField
                                label="Generated 2FA"
                                name="otp"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12">
                              <CustomInputField
                                label="Comment"
                                name="comment"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "step1":
                  return (
                    <Fragment>
                      {error && <Alert message={error} />}
                      {walletError && (
                        <Alert message={"Error fetching wallet balance"} />
                      )}
                      <div className="modal-header ">
                        <h5 className="page-subtitle mb-3">
                          Log Investment Details
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                          onClick={() => resetState()}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mt-4">
                          <form>
                            <div className="row">
                              <div className="col-12">
                                <CustomInputField
                                  type="number"
                                  placeholder="e.g. 100,000.00"
                                  label={`Investment Amount ${formatMoney(
                                    state.principal_amount,
                                    state.currency
                                  )}`}
                                  name="principal_amount"
                                  defaultValue={state.principal_amount}
                                  onChange={(e) => {
                                    validateInvestmentPrincipal(e);
                                    handleChangedState(e);
                                  }}
                                  errors={
                                    validatorObject?.status && validatorObject
                                  }
                                />
                              </div>
                              <div className="col-12">
                                <CustomInputField
                                  type="text"
                                  label={`${
                                    loadingWallet
                                      ? "Loading Wallet Balance..."
                                      : "Wallet Balance"
                                  }`}
                                  name="wallet_balance"
                                  value={`${formatMoney(
                                    wallet?.availableBalance,
                                    wallet?.currencyCode
                                  )}`}
                                  readOnly
                                />
                              </div>
                              <div className="col-12">
                                <CustomInputField
                                  type="number"
                                  placeholder="e.g. 100,000.00"
                                  label={`Wallet Top-up Amount ${formatMoney(
                                    amount_to_credit || 0,
                                    state.currency
                                  )}`}
                                  name="amount_to_credit"
                                  defaultValue={amount_to_credit}
                                  onChange={(e) => {
                                    validateTopUpAmount(e);
                                    handleChangedState(e);
                                  }}
                                  errors={
                                    amountToCreditValidator?.status &&
                                    amountToCreditValidator
                                  }
                                />
                              </div>
                              <div className="col-12">
                                <CustomSelectDropdown
                                  label="Investment Currency"
                                  name="currency"
                                  value={state.currency}
                                  onChange={(e) => {
                                    handleChangedState(e);
                                    handleCurrency(e.target.value);
                                    setCurrencyCode(e.target.value);
                                    console.log({
                                      currencySelect: e.target.value,
                                    });
                                    /* if (e.target.value === "USD") {
                                      setState({
                                        ...state,
                                        number_of_days_in_year:
                                          data?.usd_product
                                            ?.number_of_days_in_year,
                                      });
                                    } else {
                                      setState({
                                        ...state,
                                        number_of_days_in_year:
                                          data?.lcy_product
                                            ?.number_of_days_in_year,
                                      });
                                    }*/
                                  }}
                                >
                                  <option value="">Choose Currency</option>

                                  {data &&
                                    data?.investment_product_details.map(
                                      (item: any, index: number) => (
                                        <option
                                          key={index}
                                          value={item?.currency_code}
                                        >
                                          {
                                            getCurrencyInfo(item?.currency_code)
                                              ?.name
                                          }
                                        </option>
                                      )
                                    )}
                                </CustomSelectDropdown>
                              </div>
                              <div className="col-12">
                                <CustomSearchDropDown
                                  isShowLabel
                                  label="Select Investor"
                                  loadOptions={getOptions}
                                  onChange={handleInvestorChange}
                                />
                              </div>
                              <div className="col-12">
                                <CustomSelectDropdown
                                  label="Select Fund"
                                  name="investment_product_id"
                                  disabled
                                >
                                  <option value={data?.id}>{data?.name}</option>
                                </CustomSelectDropdown>
                              </div>

                              <div className="col-12">
                                <CustomInputField
                                  type="date"
                                  label="Effective Date"
                                  max={moment(new Date()).format("YYYY-MM-DD")}
                                  name="effective_date"
                                  defaultValue={state.effective_date}
                                  onChange={(e) => handleChangedState(e)}
                                />
                              </div>
                              <div className="col-12">
                                <CustomInputField
                                  type="file"
                                  placeholder=""
                                  label="Kindly upload the transaction receipt"
                                  onChange={onChangeReceipt}
                                  name="transactionReceiptName"
                                  accept="image/png, image/jpg, image/jpeg, application/pdf"
                                />
                              </div>
                              <div className="col-12 d-flex justify-content-end mt-3 flex-wrap">
                                <button
                                  type="button"
                                  data-dismiss="modal"
                                  className="btn btn-sm advancly-white-btn"
                                  onClick={() => resetState()}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-sm advancly-btn"
                                  disabled={
                                    !state.funder_id ||
                                    !state.currency ||
                                    !state.principal_amount ||
                                    !state.effective_date ||
                                    !state.transactionReceiptBase64 ||
                                    validatorObject.status
                                  }
                                  onClick={(e) => {
                                    setType(e);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Fragment>
                  );
                case "step2":
                  return (
                    <Fragment>
                      {error && <Alert message={error} />}
                      <div className="modal-header">
                        <h5 className="page-subtitle mb-3">
                          Investment Duration
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                          onClick={() => resetState()}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mt-4">
                          <form>
                            <div className="mt-4">
                              <div className="row">
                                <div className="col-12">
                                  <p className="form-label col-12">
                                    How long do you want to Invest?
                                  </p>

                                  <div className="col-12">
                                    {rateError ? (
                                      <small className="text-danger">
                                        {
                                          (rateError as any)?.response?.data
                                            ?.message
                                        }
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                    <CustomInputField
                                      type="number"
                                      name="tenure"
                                      label="Tenor(Days)"
                                      placeholder=" Enter Tenor"
                                      max="365"
                                      min="10"
                                      onChange={(e) => {
                                        handleChangedState(e);
                                      }}
                                      value={state.tenure}
                                    />
                                  </div>
                                  <div className="col-12">
                                    {isLoading ? (
                                      <small className="text-danger">
                                        Loading...
                                      </small>
                                    ) : (
                                      ""
                                    )}

                                    <CustomInputField
                                      type="number"
                                      label="Interest Rate (%)"
                                      readOnly
                                      value={
                                        InvestmentProductRate?.data
                                          ?.depositProductInterestRate?.interest
                                      }
                                      onChange={(e) => {
                                        setState({
                                          ...state,
                                          interest_rate:
                                            InvestmentProductRate?.data
                                              ?.depositProductInterestRate
                                              ?.interest,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <CustomSelectDropdown
                                      label="Days Of The Year"
                                      onChange={(e) => {
                                        setDaysOfYear(e.target.value);
                                      }}
                                    >
                                      <option value="">
                                        {` ${numberOfDaysInYear}(Default)`}
                                      </option>
                                      <option value="360">360</option>
                                      <option value="365">365</option>
                                    </CustomSelectDropdown>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p className="mt-3">
                                  Select an interest payment plan:
                                </p>
                                <div className="form-check mb-3 cursor-pointer">
                                  <input
                                    className="form-check-input cursor-pointer"
                                    type="radio"
                                    name="interest_repayment_type"
                                    value={"Monthly"}
                                    checked={
                                      state?.interest_repayment_type ===
                                      "Monthly"
                                    }
                                    onChange={(e) => {
                                      handleChangedState(e);
                                      calculateInterest(e);
                                    }}
                                  />
                                  <label className="form-check-label cursor-pointer">
                                    Monthly <br />
                                    {/* Interest will be paid monthly and principal
                                    will be paid at maturity */}
                                  </label>
                                </div>
                                <div className="form-check mb-3 cursor-pointer">
                                  <input
                                    className="form-check-input cursor-pointer"
                                    type="radio"
                                    name="interest_repayment_type"
                                    value={"EndOfTenure"}
                                    checked={
                                      state?.interest_repayment_type ===
                                      "EndOfTenure"
                                    }
                                    onChange={(e) => {
                                      handleChangedState(e);

                                      calculateInterest(e);
                                    }}
                                  />
                                  <label className="form-check-label cursor-pointer">
                                    End of Tenure <br />
                                    {/* Both Interest and principal will be paid at
                                    maturity */}
                                  </label>
                                </div>
                              </div>

                              <hr />
                              <p className="mt-4">
                                Estimated Returns:{" "}
                                {submitLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  formatMoney(
                                    calculatedInterest.maturity_amount,
                                    state?.currency
                                  )
                                )}
                              </p>
                              <p className="mt-3">
                                Total Interest Payable:{" "}
                                {submitLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  formatMoney(
                                    calculatedInterest.interest_calculated,
                                    state?.currency
                                  )
                                )}
                              </p>
                              <p className="mt-3">
                                Monthly Interest Payable:{" "}
                                {submitLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  formatMoney(
                                    calculatedInterest.monthly_interest_payable,
                                    state?.currency
                                  )
                                )}
                              </p>
                              {calculatedInterest?.monthly_interest_accruals
                                ?.length > 0 && (
                                <p
                                  style={{
                                    color: "#713fff",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setModalActionType("breakdown")
                                  }
                                >
                                  View Interest Breakdown
                                </p>
                              )}
                              <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                                <button
                                  type="button"
                                  className="btn btn-sm advancly-nobg-btn mr-3"
                                  onClick={() => setModalActionType("step1")}
                                >
                                  Previous
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-sm advancly-white-btn"
                                  onClick={(e) => submitLoggedInvestment(e)}
                                  disabled={loading}
                                >
                                  Submit {loading && <ButtonLoader />}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Fragment>
                  );
                case "breakdown":
                  return (
                    <>
                      {error && <Alert message={error} />}
                      <div className="modal-header">
                        <h5 className="page-subtitle mb-3">
                          Investment Interest Breakdown
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                          onClick={() => resetState()}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mt-4">
                          {calculatedInterest.monthly_interest_accruals
                            ?.length > 0 && (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="font-weight-bold">Date</th>
                                  <th className="font-weight-bold">
                                    Interest Amount ({state?.currency})
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {calculatedInterest.monthly_interest_accruals.map(
                                  (item: any, index) => (
                                    <tr key={index}>
                                      <td>
                                        {moment(item?.endDate).format("ll")}
                                      </td>
                                      <td>
                                        {formatMoney(
                                          item?.interestAmount || 0,
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          )}
                          <br />
                          <button
                            type="button"
                            className="btn btn-sm advancly-white-btn"
                            onClick={() => setModalActionType("step2")}
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
