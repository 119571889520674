import { useState, ChangeEvent, MouseEvent } from "react";
import { MessageStatus, NotificationListParam } from "./types";
import moment from "moment";
import useBirthNotifications from "../../custom-hooks/useBirthNotifications";
import Badge from "./Components/Badge/badge";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";

import Styles from "./birthdayNotifications.module.scss";

interface BirthdayMessage {
  created_date: string;
  date_of_birth: string;
  id: number;
  message_status: "Sent" | "Pending" | "Failed";
  message_type: string;
  recipient_email: string;
  recipient_phone_number: string;
  sent_date: string;
  name: string;
}

const BirthdayNotifications = () => {
  const [searchQuery, setSearchQuery] = useState<NotificationListParam>({
    Page: "1",
    PageSize: "10",
    message_status: "",
    to_date: "",
    from_date: "",
    searchParam: "",
  });
  const [params, setParams] = useState<NotificationListParam>({
    Page: "1",
    PageSize: "10",
    message_status: "",
    to_date: "",
    from_date: "",
    searchParam: "",
  });

  const {
    data: birthdayNotifications,
    status,
    error,
  } = useBirthNotifications({
    Page: searchQuery.Page,
    PageSize: searchQuery.PageSize,
    from_date: searchQuery.from_date,
    to_date: searchQuery.to_date,
    message_status: searchQuery.message_status,
    searchParam: searchQuery.searchParam,
  });

  const messageStatus = [
    { id: 1, name: "Pending" },
    { id: 2, name: "Sent" },
    { id: 3, name: "Failed" },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setParams((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    return setSearchQuery((prev) => ({
      ...prev,
      ...params,
    }));
  };

  let RenderedComponent = null;

  if (!birthdayNotifications && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Birthday Notification
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Previous Messages..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              birthday messages, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (birthdayNotifications &&
        birthdayNotifications?.data &&
        birthdayNotifications?.data.length === 0) ||
      (birthdayNotifications && !birthdayNotifications?.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no
              notifications.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className={`table-responsive ${Styles.TableResponsive} `}>
            <table className={`table ${Styles.Table}`}>
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Date Created</th>
                  <th>Investor Name</th>
                  <th>Message Status</th>
                  <th>Message Type</th>
                  <th>Recipient Email</th>
                  <th>Recipient Phone Number</th>
                  <th>Sent Date</th>
                </tr>
              </thead>
              <tbody>
                {birthdayNotifications?.data.map(
                  (request: BirthdayMessage, _id: number) => {
                    return (
                      <tr key={_id}>
                        <td className={Styles.TableCell}>{_id + 1}</td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {moment(request.created_date).format("YYYY-MM-DD")}
                        </td>
                        <td>{request?.name}</td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          <Badge message={request.message_status} />
                        </td>
                        <td className={Styles.TableCell}>
                          {request.message_type}
                        </td>
                        <td className={Styles.TableCell}>
                          {request.recipient_email}
                        </td>
                        <td className={Styles.TableCell}>
                          {request.recipient_phone_number}
                        </td>

                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {request?.sent_date
                            ? moment(request.sent_date).format("YYYY-MM-DD")
                            : ""}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="animated fadeInRight">
      <div>
        <h3 className={`${Styles.Header} page-title`}>
          Birthday Notifications
        </h3>
      </div>

      <div className={Styles.ActionContainer}>
        <div className={Styles.FilterOptions}>
          <div className={`form-group ${Styles.FormGroup}`}>
            <input
              type="text"
              className={Styles.SearchInput}
              placeholder="Search"
              name="searchParam"
              onChange={handleChange}
            />
            <i className={`fas fa-search ${Styles.Icon}`}></i>
          </div>
          <div className={`form-group ${Styles.DateGroup}`}>
            <div>
              <span className={Styles.DateLabel}>From:</span>
              <input
                type="date"
                className={`${Styles.FilterInput} ${Styles.DateInput}`}
                onChange={handleChange}
                name="from_date"
                max={moment(new Date()).format("YYYY-MM-DD")}
                placeholder="YYYY/MM/DD"
              />
            </div>
            <div>
              <span className={Styles.DateLabel}>To:</span>
              <input
                type="date"
                className={`${Styles.FilterInput} ${Styles.DateInput}`}
                name="to_date"
                onChange={handleChange}
                max={moment(new Date()).format("YYYY-MM-DD")}
                placeholder="YYYY/MM/DD"
              />
            </div>
          </div>
          <div className={`form-group ${Styles.FormGroup}`}>
            <select
              className={`${Styles.FilterInput} ${Styles.FilterMessageType}`}
              onChange={handleChange}
              name="message_status"
            >
              <option value="" disabled selected hidden>
                Status
              </option>
              {messageStatus.map((type: MessageStatus, index: number) => (
                <option key={index} value={type?.id}>
                  {type?.name}
                </option>
              ))}
            </select>
            <i className={`fas fa-solid fa-angle-down ${Styles.Icon}`}></i>
          </div>
        </div>
        <div className={Styles.FormButtonContainer}>
          <button
            type="submit"
            className={`btn advancly-btn btn-md ${Styles.SearchActionButton}`}
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>

      <div>{RenderedComponent}</div>

      <Paginator
        size={Number(params.PageSize)}
        page={Number(params.Page)}
        count={birthdayNotifications?.totalCount}
        changeCurrentPage={(pageNumber: number) =>
          setSearchQuery((prev) => ({ ...prev, Page: pageNumber.toString() }))
        }
      />
    </div>
  );
};

export default BirthdayNotifications;
