import React from "react";

export const FirstCentralBusinessData = ({ tableData }) => {
  const {
    businessName,
    dateOfIncorporation,
    businessRegistrationNumber,
    telephone1,
  } = tableData;
  return (
    <div>
      {Object.keys(tableData).length > 0 && (
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0 mb-3">
              <i className="adv-icon fas fa-cogs" />
              &nbsp;Details
            </h3>

            <div className="row col-md-12">
              <div>
                <div className="col-md-6">
                  <label className="label-txt">Business Name</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={businessName ? businessName : "Name not found"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Phone Number</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={telephone1 ? telephone1 : "Phone number not found"}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="col-md-6">
                  <label className="label-txt">Date of Incorporation</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        dateOfIncorporation
                          ? dateOfIncorporation
                          : "Date of Birth not found"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">RC Number</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        businessRegistrationNumber
                          ? businessRegistrationNumber
                          : "N/A"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
