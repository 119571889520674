// Redux Action CONSTANTS
import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";

// Helpers
import { dateFormatter } from "../../helpers/stringControllers";

import { reOrderFunderProductRows } from "./Product";
import { appInsights } from "../../config/appInsights";

// Ajax call to get Funder Profile
export const getFunderInvestmentPool = () => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.FUNDER_INVESTMENT_POOL_START,
    });

    ajaxMethods
      .get(ajaxEndpoints.FUNDER_INVESTMENT_POOL)
      .then((response) => {
        if (response) {
          const { count, data, next_page, prev_page } = response;

          const tableColumns = [
            { label: "#", field: "id", sort: "asc", width: 150 },
            { label: "ID", field: "pool_id", sort: "asc", width: 150 },
            {
              label: "AGGREGATOR",
              field: "biz_category",
              sort: "asc",
              width: 270,
            },
            { label: "ECOSYSTEM", field: "biz_name", sort: "asc", width: 100 },
            { label: "DATE", field: "pub_date", sort: "asc", width: 100 },
            { label: "MANAGE", field: "manage", sort: "asc", width: 100 },
          ];

          const reOrderedTableData = reOrderRows(tableColumns, data);

          dispatch({
            type: actionTypes.FUNDER_INVESTMENT_POOL_SUCCESS,
            payload: {
              investmentPoolTableData: reOrderedTableData,
              investmentPool: data,
              count,
              next_page,
              prev_page,
            },
          });
        } else {
          dispatch({
            type: actionTypes.FUNDER_INVESTMENT_POOL_ERROR,
            payload: {
              error: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "funders.js",
          },
        });
        dispatch({
          type: actionTypes.FUNDER_INVESTMENT_POOL_ERROR,
          payload: {
            error,
          },
        });
      });
  };
};

// Ajax call to get Funder Profile
export const getFunderProfile = () => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.FUNDER_PROFILE_START,
    });

    ajaxMethods
      .get(ajaxEndpoints.FUNDER_PROFILE)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.FUNDER_PROFILE_SUCCESS,
            payload: {
              profile: response.data,
            },
          });
        } else {
          dispatch({
            type: actionTypes.FUNDER_PROFILE_ERROR,
            payload: {
              error: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "funders.js",
          },
        });
        dispatch({
          type: actionTypes.FUNDER_PROFILE_ERROR,
          payload: {
            error,
          },
        });
      });
  };
};

// Ajax call to get Funder Notifications
export const getFunderNotifications = () => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.FUNDER_NOTIFICATIONS_START,
    });

    ajaxMethods
      .get(ajaxEndpoints.FUNDER_NOTIFICATIONS)
      .then((response) => {
        if (response) {
          const { count, data, next_page, prev_page } = response;

          const tableColumns = [
            { label: "#", field: "id", sort: "asc", width: 150 },
            { label: "AMOUNT", field: "amount", sort: "asc", width: 150 },
            {
              label: "DESCRIPTION",
              field: "description",
              sort: "asc",
              width: 270,
            },
            { label: "TIMESTAMP", field: "timestamp", sort: "asc", width: 100 },
            { label: "VERB", field: "verb", sort: "asc", width: 100 },
            { label: "MANAGE", field: "manage", sort: "asc", width: 100 },
          ];

          const reOrderedTableData = reOrderRows(tableColumns, data);

          dispatch({
            type: actionTypes.FUNDER_NOTIFICATIONS_SUCCESS,
            payload: {
              notificationsTableData: reOrderedTableData,
              notifications: data,
              count,
              next_page,
              prev_page,
            },
          });
        } else {
          dispatch({
            type: actionTypes.FUNDER_NOTIFICATIONS_ERROR,
            payload: {
              error: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "funders.js",
          },
        });
        dispatch({
          type: actionTypes.FUNDER_NOTIFICATIONS_ERROR,
          payload: {
            error,
          },
        });
      });
  };
};

// Ajax call to get Funder Investments
export const getFunderInvestments = () => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.FUNDER_INVESTMENTS_START,
    });

    ajaxMethods
      .get(ajaxEndpoints.FUNDER_INVESTMENTS)
      .then((response) => {
        if (response) {
          if (response.status_code === 200) {
            const { count, data, next_page, prev_page } = response;

            // Columns for DataTable
            const tableColumns = [
              { label: "#", field: "id", sort: "asc" },
              { label: "ID", field: "investment_id", sort: "asc" },
              { label: "CREDIT STATUS", field: "has_recredited", sort: "asc" },
              { label: "AGGREGATOR", field: "biz_name", sort: "asc" },
              { label: "ECOSYSTEM", field: "biz_category", sort: "asc" },
              {
                label: "INVESTMENT AMOUNT",
                field: "amount_invested",
                sort: "asc",
              },
              {
                label: "REPAYMENT AMOUNT",
                field: "loan_amount_repay",
                sort: "asc",
              },
              { label: "OUTSTANDING", field: "loan_outstanding", sort: "asc" },
              { label: "REPAYMENT STATUS", field: "has_repaid", sort: "asc" },
              { label: "CREATED DATE ", field: "pub_date", sort: "asc" },
              { label: "DUE DATE", field: "loan_due_date", sort: "asc" },
              { label: "STATUS", field: "is_active", sort: "asc" },
              { label: "MANAGE", field: "manage", sort: "asc" },
            ];

            const reOrderedTableData = reOrderInvestmentRows(
              tableColumns,
              data
            );

            dispatch({
              type: actionTypes.FUNDER_INVESTMENTS_SUCCESS,
              payload: {
                investmentsTableData: reOrderedTableData,
                investments: data,
                count,
                next_page,
                prev_page,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.FUNDER_INVESTMENTS_ERROR,
            payload: {
              error: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "funders.js",
          },
        });
        dispatch({
          type: actionTypes.FUNDER_INVESTMENTS_ERROR,
          payload: {
            error,
          },
        });
      });
  };
};

export const getFundersProduct = (params: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.FUNDER_PRODUCTS_START,
    });
    ajaxMethods
      .get(ajaxEndpoints.GET_ALL_FUNDERS_PRODUCT + "?funder_id=" + params)
      .then((response) => {
        if (response) {
          if (response.status_code === 200) {
            const { count, data, next_page, prev_page } = response;

            // Columns for Datatable
            const tableColumns = [
              { label: "Id", field: "id", sort: "asc" },
              { label: "Funder", field: "funder_name", sort: "asc" },
              { label: "Funder Id", field: "funder_id", sort: "asc" },
              { label: "Mapping Id", field: "mapping_id", sort: "asc" },
              { label: "Aggregator", field: "aggregator_name", sort: "asc" },
              { label: "Aggregator Id", field: "aggregator_id", sort: "asc" },
              { label: "Product Name", field: "product_name", sort: "asc" },
              {
                label: "Product Category",
                field: "product_category_name",
                sort: "asc",
              },
              { label: "Amount", field: "amount", sort: "asc" },
              {
                label: "Product category id",
                field: "product_category_id",
                sort: "asc",
              },
              { label: "Product code", field: "product_code", sort: "asc" },
              { label: "Edit", field: "edit", sort: "asc" },
            ];

            const reOrderedTableData = reOrderFunderProductRows(
              tableColumns,
              response.data
            );
            dispatch({
              type: actionTypes.FUNDER_PRODUCTS_SUCCESS,
              payload: {
                funderProductsTableData: reOrderedTableData,
                products: data,
                count,
                next_page,
                prev_page,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.FUNDER_PRODUCTS_ERROR,
            payload: {
              error: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "funders.js",
          },
        });
        dispatch({
          type: actionTypes.FUNDER_INVESTMENTS_ERROR,
          payload: {
            error,
          },
        });
      });
  };
};

// Ajax call to Subscribe to Aggregator
export const subscribeToAggregator = (aggregators: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.SUBSCRIPTION_START,
    });

    ajaxMethods
      .post(ajaxEndpoints.SUBSCRIBE_TO_AGGREGATOR, aggregators)
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            dispatch({
              type: actionTypes.SUBSCRIPTION_SUCCESS,
              payload: response.data.message,
            });
          }
        } else {
          dispatch({
            type: actionTypes.SUBSCRIPTION_ERROR,
            error: { message: "Network" },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "funders.js",
          },
        });
        dispatch({
          type: actionTypes.SUBSCRIPTION_ERROR,
          error,
        });
      });
  };
};

export const subscriptionClose = () => {
  return {
    type: actionTypes.SUBSCRIPTION_CLOSE,
  };
};
//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns: any, responseDataRecords: any) => {
  let newRows = [] as any[];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row: any, index: number) => {
        let newRow = {} as any;
        tableColumns.forEach((column: any) => {
          if (column.field === "id") {
            newRow[column.field] = index + 1;
            return;
          } else if (column.field === "pub_date") {
            newRow[column.field] = dateFormatter(row[column.field]);
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};

//  Reorder response data records from the API
//  @dataTable {Object}
export const reOrderInvestmentRows = (tableColumns: any, data: any) => {
  let newRows = [] as any[];

  if (data) {
    if (data.length > 0) {
      newRows = data.map((record: any, index: number) => {
        let invest_details = {} as any;
        let agg_details = {} as any;
        let borr_details = {} as any;
        let invest_details_keys = {} as any;
        let agg_details_keys = {} as any;
        let borr_details_keys = {} as any;

        if (record.investment_details) {
          const { investment_details } = record;

          invest_details = { ...investment_details };
          invest_details_keys = Object.keys(invest_details);

          if (investment_details.aggregator_details) {
            const { aggregator_details } = investment_details;

            agg_details = { ...aggregator_details };
            agg_details_keys = Object.keys(agg_details);
          }

          if (investment_details.borrower_details) {
            const { borrower_details } = investment_details;

            borr_details = { ...borrower_details };
            borr_details_keys = Object.keys(borr_details);
          }
        }

        let newRecord = {} as any;

        tableColumns.forEach((column: any) => {
          if (record.hasOwnProperty(column.field)) {
            newRecord[column.field] = record[column.field];
          } else if (column.field === "id") {
            newRecord[column.field] = index;
            return;
          } else {
            if (invest_details_keys.includes(column.field)) {
              newRecord[column.field] = invest_details[column.field];
            } else if (agg_details_keys.includes(column.field)) {
              newRecord[column.field] = agg_details[column.field];
            } else if (borr_details_keys.includes(column.field)) {
              newRecord[column.field] = borr_details[column.field];
            } else {
              return;
            }
          }
        });
        return newRecord;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};
