import { getFromLocalStorage, postToLocalStorage } from "./localStorage";

class IdleTimer {
  constructor({ timeout, onTimeout }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = getFromLocalStorage("expiryTime");

      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanup();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      postToLocalStorage("expiryTime", Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
    window.addEventListener("click", this.eventHandler);
    window.addEventListener("mousedown", this.eventHandler);
    window.addEventListener("keypress", this.eventHandler);
  }

  cleanup() {
    localStorage.removeItem("expiryTime");
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
    window.removeEventListener("click", this.eventHandler);
    window.removeEventListener("mousedown", this.eventHandler);
    window.removeEventListener("keypress", this.eventHandler);
  }
}

export default IdleTimer;
