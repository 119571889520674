import * as ajaxEndpoints from "../api/ajax-endpoints";
import { QueryParam } from "../components/Messaging/types";
import { getData } from "../newApis/dotNetApiMethods";

export const getRegisteredUsers = async (queryParams: QueryParam) => {
  const response = await getData(
    `${ajaxEndpoints.GET_REGISTERED_USERS}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );
  return response?.data;
};
