export const AGGREGATOR_ONBOARDING_STATUS = {
  Initiated: "Initiated",
  EligibilityInProgress: "Eligibility In Progress",
  EligibilitySubmitted: "Eligibility Submitted",
  DocumentUploadInProgress: "Document Upload In Progress",
  DocumentUploadCompleted: "Document Upload Completed",
  RequestOnboarding: "Requested Onboarding",
  OnboardingApproved: "Onboarding Approved",
  OnboardingDecline: "Onboarding Declined",
  SLAOfferSubmitted: "SLA Offer Submitted",
  AggregatorActivated: "Aggregator Activated",
};
