import { useState } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

// Router

// Redux
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { useQueryCache } from "react-query";
// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { postDataWithDotNet, putData } from "../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../config/appInsights";
import useChangeSignatorySettings from "../../../custom-hooks/useChangeSignatorySettings";

const ManageChangeSignatory = () => {
  const [loading, setLoading] = useState("");
  const [success, setSuccess] = useState("");

  const [error, setError] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(10);

  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  //  Methods
  const dialogHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "managesignatoriesettings",
        dataRecord: modalData,
      })
    );
  };

  const approveSignatory = async (aggregator_id: number) => {
    setLoading("Approving Signatorie(s)...");
    setError("");
    const reqBody = {
      aggregator_id,
    };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.APPROVE_SIGNATORIES,
        reqBody
      );
      setLoading("");
      setSuccess(response.message);
      queryCache.invalidateQueries(["getChangeSignatorySettings"]);
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageChangeSignatory.jsx",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const declineSignatory = async (aggregator_id: number, comment: string) => {
    setLoading("Declining Signatorie(s)...");
    setError("");
    const reqBody = {
      aggregator_id,
      comment,
    };
    try {
      const response = await putData(
        ajaxEndpoints.REJECT_SIGNATORY_REQUEST,
        reqBody
      );
      setLoading("");
      setSuccess(response?.data?.message);
      queryCache.invalidateQueries(["getChangeSignatorySettings"]);

      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageChangeSignatory.jsx",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error: dataError } = useChangeSignatorySettings();

  if (data) {
    if (count === null) {
      setCount(data.length);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Signatories
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Signatories settings..." />
    );
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else {
    if (dataError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue fetching
              Signatory settings, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {error}
            </h4>
          </div>
        </div>
      );
    } else if (success) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {success}
            </h4>
          </div>
        </div>
      );
    } else if (data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              Signatories settings to manage
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Created Date</th>
                <th>Aggregator Name</th>
                <th>Request Type</th>
                <th>Request Id</th>
                <th>Approval Status</th>
                <th>Signatories User Name</th>
                <th>Signatories User Level</th>
                <th>Signatories User Type</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((signatoryRequest: any, _id: number) => {
                  const {
                    aggregator_name,
                    approval_status,
                    created_At,
                    request_id,
                    request_type,
                    signatories,
                  } = signatoryRequest;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{created_At.substring(0, 10)}</td>
                      <td>{aggregator_name}</td>
                      <td>{request_type}</td>
                      <td>{request_id}</td>
                      <td>{approval_status}</td>
                      <td>
                        {signatories.map(({ user_name }: any) => {
                          return (
                            <ul>
                              <li>{user_name}</li>
                            </ul>
                          );
                        })}
                      </td>
                      <td>
                        {signatories.map(({ level_no }: any) => {
                          return (
                            <ul>
                              <li>{level_no}</li>
                            </ul>
                          );
                        })}
                      </td>
                      <td>
                        {signatories.map(({ user_type }: any) => {
                          return (
                            <ul>
                              <li>{user_type}</li>
                            </ul>
                          );
                        })}
                      </td>
                      <td className="d-flex flex-row">
                        <span
                          className="view btn-manage btn-success zoom-element btn-slim"
                          onClick={() => {
                            dialogHandler({
                              type: "Approve",
                              signatoryRequest,
                              action: approveSignatory,
                            });
                          }}
                        >
                          Approve
                        </span>
                        <span
                          className="view btn-manage btn-danger zoom-element btn-slim"
                          onClick={() => {
                            dialogHandler({
                              type: "Decline",
                              signatoryRequest,
                              action: declineSignatory,
                            });
                          }}
                        >
                          Decline
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.length : 0}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Manage aggregator settings Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-lg-9 col-md-6 col-sm-7 dashboard-title flex__row">
              <h3 className="m-l-1">
                <aside className="rounder">5</aside>
                &nbsp; Manage Signatory Settings
              </h3>
            </div>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
          <label htmlFor="table-fetch" className="form-group">
            Number of results:
            <select
              onChange={(e) => {
                setSize(Number(e.target.value));
              }}
              id="table-fetch"
              className="table-fetch-select m-l-1"
              value={size}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
        {RenderedComponent}
      </div>
    </div>
  );
};

export default ManageChangeSignatory;
