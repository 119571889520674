import React, { useEffect, useState } from "react";
import styles from "./InvestorProspectComments.module.scss";
import { useInvestmentProspectComments } from "../../../../../custom-hooks/useDebtFund";
import { useRouteMatch } from "react-router-dom";
import Loader from "../../../../../NewComponents/Loader/Loader.component";
import { ICommentDetail } from "../../../../../interfaces/investmentProspect";
import moment from "moment";
import InvestmentProspectMessageTab from "./InvestmentProspectMessageTab";
import { useQueryCache } from "react-query";

const CommentCard = ({
  firstName,
  lastName,
  comment,
  dateCreated,
}: ICommentDetail) => {
  const formattedDate = moment(dateCreated).calendar("", {
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    nextWeek: "dddd [at] LT",
    lastDay: "[Yesterday at] LT",
    lastWeek: "[Last] dddd [at] LT",
    sameElse: "DD/MM/YYYY [at] LT",
  });
  return (
    <div className={styles.commentCard}>
      <div className={styles.commentContent}>{comment}</div>
      <div className={styles.commentFooter}>
        <div className={styles.commentAuthor}>
          <span
            className={styles.authorInitial}
          >{`${firstName[0]} ${lastName[0]}`}</span>
          <span
            className={styles.authorName}
          >{`${firstName} ${lastName}`}</span>
        </div>
        <div className={styles.commentTimestamp}>{formattedDate}</div>
      </div>
    </div>
  );
};

const InvestmentProspectCommentsTab = () => {
  const queryCache = useQueryCache();
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };
  const { data: comments, isLoading } = useInvestmentProspectComments({
    pageNumber: 1,
    size: 20,
    id: id,
  });
  const [showAddCommentUI, setShowAddCommentUI] = useState(false);

  useEffect(() => {
    queryCache.invalidateQueries([
      {
        pageNumber: 1,
        size: 20,
        id: id,
      },
      "getInvestmentProspectComments",
    ]);
  }, [showAddCommentUI]);

  if (isLoading) {
    return (
      <>
        <h5 className="page-subtitle">All Comments</h5>
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
        <br />
        <h5 className="text-center font-weight-bold">Loading comments....</h5>
      </>
    );
  }
  if (showAddCommentUI) {
    return (
      <InvestmentProspectMessageTab
        id={Number(id)}
        setShowAddCommentUI={setShowAddCommentUI}
      />
    );
  }
  return (
    <div className="investment-prospect-comments">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="page-subtitle">All Comments</h5>
        <button
          onClick={() => setShowAddCommentUI(true)}
          className="advancly-btn"
        >
          Add Comment
        </button>
      </div>
      <br />
      {comments && comments.length === 0 ? (
        <p className="text-center">No comments available</p>
      ) : (
        comments?.map((comment: any) => (
          <CommentCard key={comment.id} {...comment} />
        ))
      )}
    </div>
  );
};

export default InvestmentProspectCommentsTab;
