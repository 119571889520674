import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  page: number;
  size: number;
};

const getFailedInvestments = async ({ page, size }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.FAILED_INVESTMENTS}?page=${page}&PageSize=${size}`
  );
  return data;
};

export default function useFailedInvestments({ page, size }: TQuery) {
  return useQuery(
    [{ page, size }, "getFailedInvestments"],
    getFailedInvestments,
    {
      refetchOnWindowFocus: false,
    }
  );
}
