import * as yup from "yup";

// Regular expression for phone number
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const numberRegExp = /[0-9]/;
export const yupValidators = {
  email: yup
    .string()
    .trim()
    .email("This is an invalid email")
    .required("Please enter your email")
    .min(5, "Email must have at least 5 characters")
    .max(256, "Email cannot be longer than 256 characters"),
  businessEmail: yup
    .string()
    .trim()
    .email("This is an invalid business email")
    .required("Please enter your business email")
    .min(5, "Business Email must have at least 5 characters")
    .max(256, "Business Email cannot be longer than 256 characters"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
  firstName: yup
    .string()
    .trim()
    .required("Please enter your first name")
    .min(2, "First name must have at least 2 characters")
    .max(30, "First name cannot be longer than 30 characters"),
  lastName: yup
    .string()
    .trim()
    .required("Please enter your last name")
    .min(2, "Last name must have at least 2 characters")
    .max(30, "Last name cannot be longer than 30 characters"),
  businessName: yup
    .string()
    .trim()
    .required("Please enter your business name")
    .min(2, "Business name must have at least 2 characters")
    .max(30, "Business name cannot be longer than 30 characters"),
  otp: yup
    .string()
    .trim()
    .required("Please enter your OTP")
    .matches(numberRegExp, "Invalid OTP")
    .min(4, "OTP must have at least 6 digits")
    .max(6, "OTP cannot have more than 6 digits"),
  countryCode: yup.string().trim().required("Please select a country"),
  gender: yup.string().trim().required("Please select a gender"),
  rcNumber: yup.string().trim().required("Rc number cannot be empty"),
  phoneNumber: yup
    .string()
    .trim()
    .required("Please enter your phone number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits")
    .matches(phoneRegExp, "Invalid Phone Number"),
  bankId: yup.string().trim().required("Please select a bank"),
  accountNumber: yup
    .string()
    .trim()
    .required("Please enter your account number")
    .min(7, "Account number must have at least 7 digits")
    .max(20, "Account number cannot have more than 20 digits")
    .matches(phoneRegExp, "Invalid Account Number"),
  BVN: yup
    .string()
    .trim()
    .required("Please enter your bvn")
    .min(7, "BVN must have at least 7 digits")
    .max(20, "BVN cannot have more than 20 digits")
    .matches(phoneRegExp, "Invalid BVN"),
};
