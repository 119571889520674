import { QueryResult, useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import {
  getDataWithDotNet,
  postDataWithDotNet,
} from "../newApis/dotNetApiMethods";
import {
  ICurrencyInfo,
  IWallet,
  IWalletProvider,
} from "../interfaces/funderInvestment";

type CustomerData = {
  customerId: number;
  currency: string;
  providerType: string;
  isExternalWallet: boolean;
  EffectiveDate: string | null;
};

const getWalletList = async (funderId: number) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_CUSTOMER_WALLETS}?customerId=${funderId}`
  );
  const { wallets } = data.data;

  return wallets;
};

const getWalletDetailsByInvestmentProduct = async (
  investmentProductId: number,
  funderId: number,
  currencyCode: string
) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_CUSTOMER_WALLET_BY_INVESTMENT_PRODUCT}?investmentProductId=${investmentProductId}&funderId=${funderId}&currencyCode=${currencyCode}`
  );
  return data.data;
};

const getWalletDetailsByWalletId = async (
  savingsWalletId: number,
  funderId: number
) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_CUSTOMER_WALLET_DETAILS}?savingsWalletId=${savingsWalletId}&funderId=${funderId}`
  );
  return data.data;
};

const getWalletCurrencies = async () => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.INVESTMENT_CURRENCIES}`
  );
  return data.data;
};

const getWalletProviders = async () => {
  const data = await getDataWithDotNet(`${ajaxEndpoints.GET_WALLET_PROVIDERS}`);
  return data.data;
};

export const createCustomerWallet = async (payload: CustomerData) => {
  const data = await postDataWithDotNet(
    `${ajaxEndpoints.CREATE_CUSTOMER_WALLET}`,
    payload
  );
  return data;
};

export function useWalletList(funderId: number): QueryResult<IWallet[]> {
  return useQuery([funderId, "getWalletList"], getWalletList, {
    refetchOnWindowFocus: false,
  });
}

export function useWalletDetailsByInvestmentProduct(
  investmentProductId: number,
  funderId: number,
  currencyCode: string
): QueryResult<any> {
  return useQuery(
    [
      investmentProductId,
      funderId,
      currencyCode,
      "getWalletDetailsByInvestmentProduct",
    ],
    getWalletDetailsByInvestmentProduct,
    {
      refetchOnWindowFocus: false,
      enabled: investmentProductId && funderId && currencyCode,
    }
  );
}
export function useWalletDetailsByWalletId(
  savingsWalletId: number,
  funderId: number
): QueryResult<any> {
  return useQuery(
    [savingsWalletId, funderId, "getWalletDetailsByWalletId"],
    getWalletDetailsByWalletId,
    {
      refetchOnWindowFocus: false,
      enabled: savingsWalletId && funderId,
    }
  );
}

export function useWalletCurrencies(): QueryResult<ICurrencyInfo[]> {
  return useQuery(["getWalletCurrencies"], getWalletCurrencies, {
    refetchOnWindowFocus: false,
  });
}

export function useWalletProviders(): QueryResult<IWalletProvider[]> {
  return useQuery(["getWalletProviders"], getWalletProviders, {
    refetchOnWindowFocus: false,
  });
}
