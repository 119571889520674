import { FormEvent, useState } from "react";
// Redux
import { useSelector } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
// UI Components
import { Loader } from "../../../../../UI/Loaders/Loaders";
import { Fragment } from "react";
import Paginator from "../../../../Pagination/Pagination.component";
import { attachClassNameToTag } from "../../../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../../../helpers/formatter";
import useDisbursementApproverApprovedLoans from "../../../../../custom-hooks/useDisbursementProviderApprovedLoans";
import { RootState } from "../../../../../redux/store";

const today = new Date().toISOString().slice(0, 10);
const lastMonth = new Date(new Date());
lastMonth.setDate(lastMonth.getDate() - 30);
const aMonthAgo = lastMonth.toISOString().slice(0, 10);

const ApprovedLoans = () => {
  const [size, setSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [preStartDate, setPreStartDate] = useState(aMonthAgo);
  const [preEndDate, setPreEndDate] = useState(today);

  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const { data, isLoading, error } = useDisbursementApproverApprovedLoans({
    startDate,
    endDate,
    email: loggedInUser?.email,
  });

  const filterData = (e: FormEvent) => {
    e.preventDefault();
    const diffTime =
      new Date(preEndDate).valueOf() - new Date(preStartDate).valueOf();
    if (diffTime < 0) {
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: "",
        action: "customrange",
        type: "alert",
      });
      return;
    } else {
      setStartDate(preStartDate);
      setEndDate(preEndDate);
    }
    resetTable();
  };

  const resetTable = () => {
    setPageNumber(1);
  };
  //JSX Template
  let RenderedComponent = null;
  //  If loans object is equal to null e.g. Initial component render before API fetch
  if (error && !isLoading) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to Fetch Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    RenderedComponent = (
      <Loader
        text="Loading available Loans for Disbursement ..."
        centered={true}
      />
    );
  } else if (data?.length === 0) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are Currently No Loans to
            Disburse
          </h4>
        </div>
      </div>
    );
  } else if (data?.length > 0) {
    RenderedComponent = (
      <Fragment>
        <MDBTable responsive minimal="true" bordered striped hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Aggregator Name</th>
              <th>Borrower Name</th>
              <th>Loan Status</th>
              <th>Loan Amount</th>
              <th>Loan Interest</th>
              <th>Loan Ref</th>
              <th>Created Date</th>
              <th>Product Name</th>
              <th>Product Catergory Name</th>
              <th>Trust SCORE</th>
              <th>CONSOLIDATED SCORE DECISION</th>
              <th>SUPERFLUID SCORE</th>
              <th>SUPERFLUID STATUS</th>
              <th>Manage</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data
              .slice(
                (pageNumber - 1) * Number(size),
                Number(size) * (pageNumber - 1) + Number(size)
              )
              .map((data: any, _id: number) => {
                const {
                  currency,
                  aggregator_details,
                  borrower_details,
                  loan_amount,
                  loan_interest,
                  loan_ref,
                  pub_date,
                  product_name,
                  product_category_name,
                  trust_score,
                  disbursement_decision,
                  sf_status,
                  sf_score,
                } = data;
                return (
                  <tr key={_id}>
                    <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                    <td>{aggregator_details?.biz_name}</td>
                    <td>
                      {borrower_details?.first_name}{" "}
                      {borrower_details?.last_name}
                    </td>
                    <td>
                      <span className={attachClassNameToTag("Pending")}>
                        {attachClassNameToTag("Pending")}
                      </span>
                    </td>
                    <td>{formatMoney(Number(loan_amount), currency)}</td>
                    <td>{formatMoney(Number(loan_interest), currency)}</td>
                    <td>{loan_ref}</td>
                    <td>{pub_date && pub_date.substring(0, 10)}</td>
                    <td>{product_name}</td>
                    <td>{product_category_name}</td>
                    <td>{trust_score}</td>
                    <td>{disbursement_decision}</td>
                    <td>{sf_score}</td>
                    <td>{sf_status}</td>
                    <td>
                      <span className="d-flex justify-content-center align-items-center">
                        <span
                          className="pendingloan details btn-manage zoom-element btn-slim"
                          onClick={() => {
                            showModal({
                              modalIdentifier: "loan",
                              dataRecord: data,
                              action: "approval_trail",
                            });
                          }}
                        >
                          Details
                        </span>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </MDBTableBody>
        </MDBTable>
      </Fragment>
    );
  }
  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div>
          <div className="block-header">
            <div className="d-flex flex-wrap justify-content-between align-items-baseline">
              <h3>Approved Loans</h3>
              <div className="d-flex my-3">
                <form
                  className="d-flex flex-row flex-wrap"
                  onSubmit={filterData}
                >
                  <div className="form-group mr-3 mt-2">
                    <div className="input-group ">
                      <label className="mr-2">From </label>
                      <input
                        type="date"
                        name="startDate"
                        className="form-control"
                        value={preStartDate}
                        onChange={(e) => setPreStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-2 mr-3">
                    <div className="input-group">
                      <label className="mr-2">To </label>
                      <input
                        type="date"
                        className="form-control"
                        name="endDate"
                        value={preEndDate}
                        onChange={(e) => setPreEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: "7px" }}>
                    <button
                      type="submit"
                      className="btn advancly-btn btn-md"
                      value="submit"
                      disabled={isLoading}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="table-fetch" className="form-group">
              Number of results:{" "}
              <select
                onChange={(e) => {
                  resetTable();
                  setSize(Number(e.target.value));
                }}
                id="table-fetch"
                className="table-fetch-select"
                value={size}
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
              </select>
            </label>
          </div>
          {/* All Loans Ready for Disbursement Datatable || Loader || Error Message*/}
          {RenderedComponent}
          <Paginator
            size={size}
            page={pageNumber}
            count={data?.length}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovedLoans;
