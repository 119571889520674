import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  pageNumber: string;
  size: string;
  startDate?: string;
  endDate?: string;
  borrowerName?: string;
  loanId?: string;
  bvn?: string;
  rcNumber?: string;
  aggregatorId?: string;
  loanRef?: string;
};

const getPendingAggregatorLoans = async (queryParams: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.PENDING_AGGREGATOR_LOANS}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );

  return data;
};

export default function usePendingAggregatorLoans(params: TQuery) {
  return useQuery(
    [params, "getPendingAggregatorLoans"],
    getPendingAggregatorLoans,
    {
      refetchOnWindowFocus: false,
    }
  );
}
