import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { NotificationListParam } from "../components/Messaging/types";
import { getData } from "../newApis/dotNetApiMethods";

const getNotificationList = async (queryParams: NotificationListParam) => {
  const response = await getData(
    `${ajaxEndpoints.GET_MESSAGES}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );
  return response?.data;
};

export default function useNotificationList({
  Page,
  PageSize,
  Search,
  Created,
  MessageClass,
}: NotificationListParam) {
  return useQuery(
    [
      { Page, PageSize, Search, Created, MessageClass },
      "notificatonMessageList",
    ],
    getNotificationList,
    {
      refetchOnWindowFocus: false,
    }
  );
}
