import { useState, Fragment, useRef, useEffect } from "react";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import Alert from "../../../NewComponents/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { useHistory, useLocation } from "react-router-dom";
import ApprovalInvestorModal from "../ApprovalRequests/ApprovalInvestorModal";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { appInsights } from "../../../config/appInsights";

export default function PreviewInvestorDetails(props) {
  const [modalActionType, setModalActionType] = useState("");
  const [modifier, setModifier] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const [activateState, setActivateState] = useState({
    comment: "",
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Preview Investor Details - (PreviewInvestorDetails.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const location = useLocation();
  const { data } = location?.state;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setActivateState({ ...activateState, [name]: value });
  };

  const dismissModalRef = useRef();
  const history = useHistory();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const reqBody = {
      reference: data?.reference,
      comment: activateState.comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.APPROVE_PENDING_INVESTOR}`,
        reqBody
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewInvestorDetails.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveError(errorHandler(error));
      setActivateState({ comment: "" });
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    }
  };
  const handleDecline = async (e) => {
    setLoading(true);
    e.preventDefault();
    const reqBody = {
      reference: data?.reference,
      comment: activateState.comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.REJECT_REQUEST}`,
        reqBody
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewInvestorDetails.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveError(errorHandler(error));
      setActivateState({ comment: "" });
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    }
  };
  const discardChanges = (e) => {
    e.preventDefault();
    if (dismissModalRef && dismissModalRef.current) {
      dismissModalRef.current.click();
    }
  };
  return (
    <Fragment>
      <div className="animated fadeInRight mt-2">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
        </h3>
        <div className="d-flex flex-row flex-wrap mt-3 mb-1">
          {saveError && <Alert message={saveError} />}
          {successMessage && <Alert message={successMessage} type="success" />}
          <h3 className="page-title">
            {data?.first_name} {data?.last_name}
          </h3>

          <div className="d-flex flex-row flex-wrap justify-content-end">
            <button
              className="btn advancly-btn btn-md mr-3"
              disabled={loading}
              data-toggle="modal"
              data-target="#approvalRequestModal"
              onClick={() => {
                setModalActionType("approve");
                setModifier("investor");
              }}
            >
              Approve
            </button>
            <button
              className="btn advancly-dimred-btn btn-md m-0"
              disabled={loading}
              data-toggle="modal"
              data-target="#approvalRequestModal"
              onClick={() => {
                setModifier("investor");
                setModalActionType("decline");
              }}
            >
              Decline
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <h3 className="page-subtitle">Investor Details</h3>
            <hr
              style={{
                width: "63%",
                marginLeft: 0,
                marginBottom: "30",
              }}
            ></hr>
            <div className="col-md-6 mb-3">
              <h6>Funder Type</h6>
              <p className="page-subtitle">
                {data?.funder_type_id === 1 || data?.funder_type_id === "1"
                  ? "Individual"
                  : data?.funder_type_id === 2 || data?.funder_type_id === "2"
                  ? "Corporate"
                  : ""}
              </p>
            </div>

            <div className="col-md-6 mb-3">
              <h6>Contact Email</h6>
              <p className="page-subtitle">{data?.email || "N/A"}</p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Phone Number</h6>
              <p className="page-subtitle">{data?.phone_number || "N/A"}</p>
            </div>

            <div className="col-md-6 mb-3">
              <h6>Account Number</h6>
              <p className="page-subtitle">
                {data?.bank_account_number || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Country</h6>
              <p className="page-subtitle">{data?.country_code || "N/A"}</p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Gender</h6>
              <p className="page-subtitle">
                {data?.gender.toLowerCase() === "female"
                  ? "Female"
                  : data?.gender.toLowerCase() === "male"
                  ? "Male"
                  : "N/A"}
              </p>
            </div>
            {(data?.funder_type_id === 1 || data?.funder_type_id === "1") && (
              <div className="col-md-6 mb-3">
                <h6>Identification Number</h6>
                <p className="page-subtitle">
                  {data?.identification_number || "N/A"}
                </p>
              </div>
            )}
            {(data?.funder_type_id === 2 || data?.funder_type_id === "2") && (
              <>
                <div className="col-md-6 mb-3">
                  <h6>BUSINESS NAME</h6>
                  <p className="page-subtitle">{data?.biz_name || "N/A"}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Business Email</h6>
                  <p className="page-subtitle">{data?.biz_email || "N/A"}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>RC Number</h6>
                  <p className="page-subtitle">{data?.rc_number || "N/A"}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <ApprovalInvestorModal
        onSubmit={handleSubmit}
        onDecline={handleDecline}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
        handleChange={handleChange}
        modifier={modifier}
      />
    </Fragment>
  );
}
