import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { appInsights } from "../../../../config/appInsights";
import useFunderDetails from "../../../../custom-hooks/useFunderDetails";
import { showModal } from "../../../../redux/actions/modal";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { Link } from "react-router-dom";
import Paginator from "../../../Pagination/Pagination.component";
import { formatMoney } from "../../../../helpers/formatter";
import { useInvestmentProspects } from "../../../../custom-hooks/useDebtFund";

const InvestmentProspect = () => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    email: "",
    phoneNumber: "",
  });

  const [count, setCount] = useState<number | undefined>(0);

  const dispatch = useDispatch();
  useSelector((state: RootState) => state?.authReducer?.loggedInUser);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investors  - (InvestmentProspect.tsx)",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    console.log({ state });
  }, [state]);

  const {
    data: paginatedData,
    status,
    error,
  } = useInvestmentProspects(preState);

  const { data = [], totalCount = 0 } = paginatedData || {};

  if (data) {
    if (count === null) {
      setCount(totalCount);
    }
  }

  const resetTable = () => {
    setCount(0);
    setPreState({ ...state, pageNumber: 1 });
  };
  const modalHandler = (modalData: any, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      }),
    );
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate).valueOf() - new Date(state.startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Investment Prospects
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Investment Prospect..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investment prospect, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (data && data?.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no investment
              prospect.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone Number</th>
                  <th>Email Address</th>
                  <th>Currency</th>
                  <th>Date Indicated</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((request) => {
                    const {
                      id,
                      firstname,
                      lastname,
                      phoneNumber,
                      emailAddress,
                      dateIndicated,
                      currency,
                      amount,
                    } = request;
                    return (
                      <tr key={id}>
                        <td>
                          {(preState.pageNumber - 1) * preState.size + (id + 0)}
                        </td>
                        <td>{firstname || "N/A"}</td>
                        <td>{lastname}</td>
                        <td>{phoneNumber}</td>
                        <td>{emailAddress}</td>
                        <td>{currency}</td>
                        <td>{dateIndicated?.substring(0, 10)}</td>
                        <td>{formatMoney(Number(amount) || Number(100000))}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/investment-prospect/${id}`,
                              state: request,
                            }}
                            className="color-blue"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">Investment Prospect</h3>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
              <div className="form-group mr-3 mt-2">
                <div className="input-group ">
                  <label className="mr-2">Funder Email </label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Funder Email..."
                    value={state.email}
                    name="email"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="form-group mr-3 mt-2">
                <div className="input-group ">
                  <label className="mr-2">Funder Phone number </label>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Phone number..."
                    value={state.phoneNumber}
                    name="phoneNumber"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="form-group mr-3 mt-2">
                <label className="mr-2">From </label>
                <div className="input-group ">
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={state.startDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-group mt-2 mr-3">
                <label className="mr-2">To </label>
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "19px" }}
                className="d-flex flex-column flex-wrap"
              >
                <button
                  type="submit"
                  className="btn advancly-btn btn-md mb-2"
                  value="submit"
                  // disabled={loading}
                >
                  Search
                </button>

                <button
                  type="submit"
                  className="btn advancly-white-btn btn-md"
                  value="submit"
                  // disabled={loading}
                >
                  Download
                </button>
              </div>
            </form>
          </div>
          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          {data?.length > 0 && (
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber: number) =>
                setPreState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentProspect;
