import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    stats: {
        total_disbursement: "0",
        all_revenue: "0",
        total_repayment: "0",
        total_repayment_paid: "0",
        outstanding_collection: "0",
        total_denied_loan: "0",
        total_loan_request: "0",
        total_borrower: "0",
        global_aggregator_share: "0",
        global_funder_share: "0",
        total_aggregator: "0",
        total_issue_closed: "0",
        total_issue: "0",
    }, 
    aggregatorStats: {
        total_disbursement: "0",
        all_revenue: "0",
        total_repayment: "0",
        total_repayment_paid: "0",
        outstanding_collection: "0",
        total_denied_loan: "0",
        total_loan_request: "0",
        total_borrower: "0",
        global_aggregator_share: "0",
        global_funder_share: "0",
        total_aggregator: "0",
        total_issue_closed: "0",
        total_issue: "0",
    }, 
    // loading: false,
    // error: null
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        // case actionTypes.TRANSACTION_AJAX_START:
        //     return {
        //         ...state,
        //         loading: true
        //     }
        
        case actionTypes.GET_ADMIN_STATISTICS_SUCCESS:
            return {
                ...state,
                // loading: false,
                // error: null,
                stats: {
                    ...state.stats,
                    ...action.payload
                }
            }

        case actionTypes.GET_AGGREGATOR_STATISTICS_SUCCESS:
            return {
                ...state,
                // loading: false,
                // error: null,
                aggregatorStats: {
                    ...state.stats,
                    ...action.payload
                }  
        }

        // case actionTypes.GET_TRANSACTIONS_FAILURE:
        //     return {
        //         loading: false,
        //         error: action.error
        // }

        default:
            return state;
    }
}

export default reducer;