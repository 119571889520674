import { Fragment, useEffect, useState } from "react";
import Paginator from "../Pagination/Pagination.component";
import ButtonLoader, { Loader } from "./../../UI/Loaders/Loaders";
import Alert from "../../NewComponents/Alert/Alert.component";
import useAllPendingRepayment from "../../custom-hooks/useAllPendingRepayment";

import { showModal, hideModal } from "../../redux/actions/modal";
import { setToast } from "../../redux/actions/toast";
import { connect } from "react-redux";
import { postDataWithDotNet } from "../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { useQueryCache } from "react-query";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../../config/appInsights";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";

import styles from "./BatchRepaymentRequest.module.scss";
import { repaymentSource } from "../BatchLoanRepayment/util";

const BatchRepaymentRequest = ({ showModal, hideModal, setToast }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  const [submitError, setSubmitError] = useState(null);
  const [loading, setLoading] = useState(false);
  // Component State
  const [state, setState] = useState({
    // loading: false,
    checkStatus: "off",
    loansForRepayment: [],
    totalAmount: 0,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Repayment - (BatchRepaymentRequest.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data, status, error } = useAllPendingRepayment();
  const queryCache = useQueryCache();
  const resetLoansForRepayment = () => {
    setState({
      ...state,
      loansForRepayment: [],
      totalAmount: 0,
      checkStatus: "off",
    });
  };

  // repay Selected Loans
  const repaySelectedLoans = (e) => {
    e.preventDefault();
    const loansForRepayment = state.loansForRepayment;
    if (loansForRepayment.length === 0)
      return setToast("warning", "You have not selected any loans");
    showModal({
      modalIdentifier: "batchloanrepayment",
      dataId: e.target.dataset["ref"],
      action: "loanrepayment",
      subModal: "multiple",
      loans: state.loansForRepayment,
      totalAmount: state.totalAmount,
      handleDeclineRepayment,
      handleApproveRepayment,
    });
  };

  // Multiple Checkbox
  const checkAllLoansForRepayment = (e) => {
    e.preventDefault();
    if (e.target.dataset["state"] === "checked") {
      const allLoans = document.querySelectorAll(".loans_for_repayment");
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }

      e.target.dataset["state"] = "unchecked";

      setState({
        ...state,
        checkStatus: "off",
        totalAmount: 0,
        loansForRepayment: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans = document.querySelectorAll(".loans_for_repayment");
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loanId = Number(allLoans[i].dataset["ref"]);

      loans.push(loanId);
      totalAmount += parseFloat(
        JSON.parse(allLoans[i].dataset["record"]).repayment_amount
      );
    }

    e.target.dataset["state"] = "checked";

    setState({
      ...state,
      checkStatus: "on",
      totalAmount,
      loansForRepayment: [...state.loansForRepayment, ...loans],
    });
  };

  // Single Checkbox
  const checkboxHandler = ({
    dataRecord: { repayment_amount },
    eventTarget,
  }) => {
    const loanId = Number(eventTarget.dataset["ref"]);
    if (eventTarget.checked === false) {
      const loansForRepayment = state.loansForRepayment;
      const newLoansForRepayment = loansForRepayment.filter((loan) => {
        return loan !== loanId;
      });

      return setState(
        {
          ...state,
          loansForRepayment: [...newLoansForRepayment],
          totalAmount: state.totalAmount - parseFloat(repayment_amount),
        },
        () => {}
      );
    }

    setState(
      {
        ...state,
        loansForRepayment: [...state.loansForRepayment, Number(loanId)],
        totalAmount: state.totalAmount + parseFloat(repayment_amount),
      },
      () => {}
    );
  };

  const handleApproveRepayment = async (loans, comment) => {
    hideModal();
    setLoading(true);
    const reqBody = { ids: loans, comment };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.APPROVE_BATCH_REPAYMENT,
        reqBody
      );
      setLoading(false);
      setTimeout(() => {
        showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          success: response?.message ? response?.message : "",
          fileUploadError: response?.data?.failure?.data?.data
            ? response?.data?.failure?.data?.data
            : "",
        });
        setTimeout(() => {
          hideModal();
        }, 9000);
      });
      resetLoansForRepayment();
      queryCache.invalidateQueries("getAllPendingRepayment");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "BatchRepaymentRequest.jsx",
        },
      });
      setLoading(false);
      if (error.response && error?.response?.data?.data?.failure) {
        showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          resetLoansForRepayment,
          fileUploadError: error?.response?.data?.data?.failure,
          loading,
        });
        setTimeout(() => {
          hideModal();
        }, 9000);
      } else {
        setSubmitError(errorHandler(error));
      }
      resetLoansForRepayment();
    }
  };
  const handleDeclineRepayment = async (loans, comment) => {
    hideModal();
    setLoading(true);
    const reqBody = { ids: loans, comment };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.DECLINE_BATCH_REPAYMENT,
        reqBody
      );
      // setLoading(false);

      setLoading(false);
      setTimeout(() => {
        showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          success: response?.message ? response?.message : "",
          fileUploadError: response?.data?.failure?.data?.data
            ? response?.data?.failure?.data?.data
            : "",
        });
        setTimeout(() => {
          hideModal();
        }, 9000);
      });
      resetLoansForRepayment();
      queryCache.invalidateQueries("getAllPendingRepayment");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "BatchRepaymentRequest.jsx",
        },
      });
      setLoading(false);
      if (error.response.data.data && error.response.data.data.failure) {
        showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          resetLoansForRepayment,
          success: "",
          fileUploadError: error?.response?.data?.data?.failure,
          loading,
        });
        setTimeout(() => {
          hideModal();
        }, 9000);
      } else {
        setSubmitError(errorHandler(error));
      }
      resetLoansForRepayment();
    }
  };

  const handleView = (item) => {
    showModal({
      modalIdentifier: "viewrepaymentdocuments",
      dataRecord: item,
    });
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Batch Loan Repayment
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Approvable Repayment..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Batch Loan Repayment Requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th></th>
                  <th>Aggregator Name</th>
                  <th>Loan Ref</th>
                  <th>Source</th>
                  <th>Document</th>
                  <th>Repayment Ref</th>
                  <th>Repayment Amount</th>
                  <th>Repayment Date</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.data.map((request, _id) => {
                    const {
                      aggregator_name,
                      loan_ref,
                      repayment_amount,
                      repayment_ref,
                      repayment_date,
                      repayment_id,
                      repayment_source,
                      uploaded_document_path,
                    } = request;

                    return (
                      <tr key={_id}>
                        <td>
                          <input
                            type="checkbox"
                            data-record={JSON.stringify(request)}
                            data-id={repayment_id}
                            data-ref={repayment_id}
                            checked={state.loansForRepayment.includes(
                              repayment_id
                            )}
                            onClick={(e) =>
                              checkboxHandler({
                                dataRecord: request,
                                eventTarget: e.target,
                              })
                            }
                            className={`Loan loans_for_repayment`}
                          />
                        </td>
                        <td>{aggregator_name}</td>
                        <td>{loan_ref}</td>
                        <td>
                          {!repayment_source ? (
                            "NIL"
                          ) : (
                            <span
                              className={attachClassNameToTag(repayment_source)}
                            >
                              {attachClassNameToTag(repayment_source)}
                            </span>
                          )}
                        </td>
                        <td>
                          {repayment_source ===
                          repaymentSource?.CREDIT_OFFICER ? (
                            "NIL"
                          ) : (
                            <span
                              className={styles.ViewDetails}
                              onClick={() => handleView(uploaded_document_path)}
                            >
                              View
                            </span>
                          )}
                        </td>
                        <td>{repayment_ref}</td>
                        <td>{repayment_amount}</td>
                        <td>{repayment_date?.substring(0, 10)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          {submitError && <Alert message={state.submitError} />}
          {/*Welcome Block*/}
          <div className="block-header">
            <div className="flex-row m-b-2 m-t-1">
              <h3 className="m-l-1 m-b-1 flex-row"> Batch Repayment Request</h3>

              <div className="flex-row m-b-1">
                <div className="m-r-1">
                  <div className="rounded-border flex-row">
                    <div
                      data-state="unchecked"
                      onClick={checkAllLoansForRepayment}
                    >
                      <i
                        className={`adv-icon fas fa-toggle-${state.checkStatus} fa-2x`}
                      />
                    </div>
                    &nbsp; <span>Select all</span>
                  </div>
                </div>
                <div className="m-r-1 rounded-border">
                  Selected: {state?.loansForRepayment?.length}
                </div>

                <div className="rounded-border">
                  Total Amount: {state.totalAmount}
                </div>
              </div>

              <div className="m-b-1">
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="create zoom-element btn login-btn"
                  onClick={repaySelectedLoans}
                >
                  <i className="far fa-credit-card" /> Proceed
                  {loading && <ButtonLoader />}
                </button>
              </div>
            </div>
          </div>
          {RenderedComponent}
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.length : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default connect(null, { showModal, hideModal, setToast })(
  BatchRepaymentRequest
);
