import { ChangeEvent, useEffect, useState } from "react";

import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import ButtonLoader, { Loader } from "../../../../../../UI/Loaders/Loaders";
import { postData } from "../../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../../config/appInsights";
import { TBankAccountDetails } from "../type";
import CustomSearchDropDown from "../../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import useBankAccountDetails from "../../../../../../custom-hooks/useBankAccountDetails";
import { BASE_URL } from "../../../../../../appConstants";
import { getTokenFromLocalStorage } from "../../../../../../helpers/localStorage";
import { formatMoney } from "../../../../../../helpers/formatter";

interface IAccountStatistics {
  statistics: [];
  totalAverageBalance: number;
  totalCreditTurnover: number;
  totalDebitTurnover: number;
  totalHighestCreditTransaction: number;
  totalHighestDebitTransaction: number;
  totalLowestCreditTransaction: number;
  totalLowestDebitTransaction: number;
}

const AccountStatistics = ({ dataRecord }: any) => {
  const [accountStatistics, setAccountStatistics] = useState<
    IAccountStatistics | undefined
  >();
  const [isDownloading, setIsDownloading] = useState(false);
  const [currency, setCurrency] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [searchQuery, setSearchQuery] = useState({
    dateFrom: "",
    dateTo: "",
    accountNo: "",
  });
  const [inputSearch, setInputSearch] = useState<any>();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: bankAccountDetails } = useBankAccountDetails(
    dataRecord?.borrower_details?.customer_id
  );

  const handleDownloadAccountStatistics = (e: any) => {
    e.preventDefault()
    setIsDownloading(true);

    let token = getTokenFromLocalStorage();

    const downloadParams = {
      customerId: dataRecord?.borrower_details?.customer_id,
      accountNo: accountNo,
      dateFrom: inputSearch?.dateFrom,
      dateTo: inputSearch?.dateTo,
      bankName: bankName,
      accountName: accountName,
      currency: currency,
    };

    fetch(
      BASE_URL +
        `${ajaxEndpoints.GENERATE_ACCOUNT_STATISTICS_CSV}?${new URLSearchParams(
          downloadParams
        )}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setIsDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `account statistics from ${searchQuery?.dateFrom} to ${searchQuery?.dateTo}.csv`
            );
            link.click();
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AccountStatistics.tsx",
          },
        });
        setIsDownloading(false);
      });
  };

  const generatAccountStatistics = async () => {
    setIsLoading(true);
    try {
      const payload = {
        customerId: dataRecord?.borrower_details?.customer_id,
        dateFrom: searchQuery?.dateFrom,
        dateTo: searchQuery?.dateTo,
        accountNo: searchQuery?.accountNo,
      };
      const response = await postData(
        ajaxEndpoints.GENERATE_ACCOUNT_STATISTICS,
        payload
      );

      if (response?.data?.status_code === 200) {
        setAccountStatistics(response?.data?.data);
      } else {
        setError(true);
      }
    } catch (error: any) {
      setError(true);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AccountStatistics.tsx",
        },
      });
    }
    setIsLoading(false);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    return setInputSearch((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const cleanupBankAccountList = (
    _bankAccountDetails: TBankAccountDetails[] | undefined
  ) => {
    if (_bankAccountDetails && _bankAccountDetails.length > 0) {
      let result = _bankAccountDetails.map((_item) => {
        return {
          label: `${_item?.accountNo}/${_item?.accountName}/${_item?.bankName}/${_item?.currency}`,
          value: _item?.accountNo,
          currency: _item?.currency,
          bankName: _item?.bankName,
          accountName: _item?.accountName,
        };
      });

      return result;
    }

    return [];
  };

  const handleSearch = (e: any) => {
    e.preventDefault()
    setSearchQuery((prev) => {
      return {
        ...prev,
        ...inputSearch,
      };
    });
  };

  const handleChangeBankAccount = (data: any) => {
    setCurrency(data?.currency);
    setAccountName(data?.accountName);
    setAccountNo(data?.value);
    setBankName(data?.bankName);
    setInputSearch((prev: any) => {
      return {
        ...prev,
        accountNo: data?.value,
      };
    });
  };

  useEffect(() => {
    if (
      searchQuery?.dateFrom &&
      searchQuery?.dateTo &&
      searchQuery?.accountNo
    ) {
      generatAccountStatistics();
    }
  }, [searchQuery?.dateFrom, searchQuery?.dateTo, searchQuery?.accountNo]);

  let RenderedComponent = null;
  //  If loans object is equl to null e.g. Initial component render before API fetch
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to Fetch Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    RenderedComponent = (
      <Loader text="Loading account statistics ..." centered={true} />
    );
  } else if (accountStatistics) {
    // Verify that a loans object exists
    if (accountStatistics?.statistics.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Account Statistics available
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <div className="row mx-1">
            <div className="col-md-6 text-left">
              <p className="font-bold">
                Total Average Balance:
                <span className="font-weight-medium ml-2">
                  {formatMoney(accountStatistics?.totalAverageBalance)}
                </span>
              </p>
            </div>

            <div className="col-md-6 text-right">
              <p className="font-bold">
                Total Credit Turnover:
                <span className="font-weight-medium ml-2">
                  {formatMoney(accountStatistics?.totalCreditTurnover)}
                </span>
              </p>
            </div>

            <div className="col-md-6 text-left">
              <p className="font-bold">
                Total Debit Turnover:
                <span className="font-weight-medium ml-2">
                  {formatMoney(accountStatistics?.totalDebitTurnover)}
                </span>
              </p>
            </div>

            <div className="col-md-6 text-right">
              <p className="font-bold">
                Total Highest Credit Transaction:
                <span className="font-weight-medium ml-2">
                  {formatMoney(
                    accountStatistics?.totalHighestCreditTransaction
                  )}
                </span>
              </p>
            </div>

            <div className="col-md-6 text-left">
              <p className="font-bold">
                Total Lowest Credit Transaction:
                <span className="font-weight-medium ml-2">
                  {formatMoney(accountStatistics?.totalLowestCreditTransaction)}
                </span>
              </p>
            </div>

            <div className="col-md-6 text-right">
              <p className="font-bold">
                Total Lowest Debit Transaction:
                <span className="font-weight-medium ml-2">
                  {formatMoney(accountStatistics?.totalLowestDebitTransaction)}
                </span>
              </p>
            </div>

            <div className="col-md-6 text-left">
              <p className="font-bold">
                Total Highest Credit Transaction:
                <span className="font-weight-medium ml-2">
                  {formatMoney(
                    accountStatistics?.totalHighestCreditTransaction
                  )}
                </span>
              </p>
            </div>
          </div>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th></th>
                <th>MONTH</th>
                <th>HIGHEST CREDIT TRANSACTION</th>
                <th>HIGHEST DEBIT TRANSACTION</th>
                <th>LOWEST CREDIT TRANSACTION</th>
                <th>LOWEST DEBIT TRANSACTION</th>
                <th>HIGHEST BALANCE</th>
                <th>LOWEST BALANCE</th>
                <th>CREDIT TURNOVER</th>
                <th>DEBIT TURNOVER</th>
                <th>AVERAGE BALANCE</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {accountStatistics &&
                accountStatistics?.statistics.map((data, _id) => {
                  const {
                    averageBalance,
                    creditTurnover,
                    debitTurnover,
                    highestCreditTransaction,
                    highestDebitTransaction,
                    lowestCreditTransaction,
                    lowestDebitTransaction,
                    month,
                    highestBalance,
                    lowestBalance,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td></td>
                      <td>{month}</td>
                      <td>{formatMoney(highestCreditTransaction)}</td>
                      <td>{formatMoney(highestDebitTransaction)}</td>
                      <td>{formatMoney(lowestCreditTransaction)}</td>
                      <td>{formatMoney(lowestDebitTransaction)}</td>
                      <td>{formatMoney(highestBalance)}</td>
                      <td>{formatMoney(lowestBalance)}</td>
                      <td>{formatMoney(creditTurnover)}</td>
                      <td>{formatMoney(debitTurnover)}</td>
                      <td>{formatMoney(averageBalance)}</td>
                    </tr>
                  );
                })}
              <tr>
                <td>
                  <span style={{ fontWeight: 900 }}>Total</span>
                </td>
                <td>
                  <span style={{ fontWeight: 900 }}></span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {formatMoney(
                      accountStatistics?.totalHighestCreditTransaction
                    )}
                  </span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {formatMoney(
                      accountStatistics?.totalHighestDebitTransaction
                    )}
                  </span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {formatMoney(
                      accountStatistics?.totalLowestCreditTransaction
                    )}
                  </span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {" "}
                    {formatMoney(
                      accountStatistics?.totalLowestDebitTransaction
                    )}
                  </span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}></span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}></span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {" "}
                    {formatMoney(accountStatistics?.totalCreditTurnover)}
                  </span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {formatMoney(accountStatistics?.totalDebitTurnover)}
                  </span>
                </td>
                <td>
                  <span style={{ fontWeight: 700 }}>
                    {formatMoney(accountStatistics?.totalAverageBalance)}
                  </span>
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div className="">
          <div className="flex-row m-b-2 m-t-1">
            <div className="d-flex">
              <div className="mr-4 d-flex">
                <span
                  style={{
                    display: "inline-block",
                    minWidth: "6rem",
                  }}
                >
                  Start Date:
                </span>
                <input
                  className="user__form form-control"
                  name="dateFrom"
                  type="date"
                  onChange={handleInputChange}
                  style={{
                    height: "2.55rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="d-flex">
                <span
                  style={{
                    display: "inline-block",
                    minWidth: "6rem",
                  }}
                >
                  End Date:
                </span>
                <input
                  className="user__form form-control"
                  name="dateTo"
                  type="date"
                  onChange={handleInputChange}
                  style={{
                    height: "2.55rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="ml-4 flex-row">
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="create btn advancly-btn"
                  onClick={handleDownloadAccountStatistics}
                >
                  <i className="fas fa-download m-r-1" />
                  Download
                  {isDownloading && <ButtonLoader />}
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div
                style={{
                  width: "15rem",
                  paddingTop: "1.1rem",
                }}
              >
                <CustomSearchDropDown
                  label="Select Aggregator"
                  defaultOptions={cleanupBankAccountList(bankAccountDetails)}
                  onChange={handleChangeBankAccount}
                  isShowLabel={false}
                  inputHeight={40}
                  isSearchable={false}
                />
              </div>
              <div className="">
                <button
                  className="create btn m-3 advancly-btn"
                  onClick={handleSearch}
                >
                  <i className="fas fa-search m-r-1" />
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "25rem",
            overflowY: "scroll",
          }}
        >
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default AccountStatistics;
