import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getBvnKycProviders = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.BVN_KYC_PROVIDERS);
  return data;
};

export default function useBvnKycProviders() {
  return useQuery("getBvnKycProviders", getBvnKycProviders, {
    refetchOnWindowFocus: false,
  });
}
