import { useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { setToast } from "../../../../redux/actions/toast";

// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { appInsights } from "../../../../config/appInsights";
import { RootState } from "../../../../redux/store";

const Deny = (props: any) => {
  const [state, setState] = useState({
    comment: "",
  });

  const {
    dataRecord: { loan_id },
  } = props;

  const {
    disbursements: { records },
  } = useSelector((state: RootState) => state?.loansReducer);

  const dispatch = useDispatch();
  const onCommentChange = (e: any) => {
    setState((prev) => {
      return {
        ...prev,
        comment: e.target.value,
      };
    });
  };

  // Deny Single Loan
  const denyLoan = (e: any) => {
    e.preventDefault();
    const loans = e.target.dataset["id"];
    const { comment } = state;

    const payload = {
      mass_loan_id: loans,
      disapproval_tag: comment,
    };

    ajaxMethods
      .post(ajaxEndpoints.DENY_LOANS, payload)
      .then((response) => {
        if (response) {
          if (response.data.status === true) {
            // this.props.removeDeniedLoans(loans);
            dispatch(setToast("success", response.data.message));

            props.onCloseModal();
          } else {
            dispatch(setToast("warning", "Could not deny selected loans"));

            props.onCloseModal();
          }
        } else {
          dispatch(setToast("warning", "Could not deny selected loans"));
          props.onCloseModal();
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Deny.tsx",
          },
        });
        dispatch(setToast("warning", error));
        props.onCloseModal();
      });
  };

  const {
    borrower_details: { first_name, last_name },
    loan_amount,
  } = records.find((record: any) => {
    return parseInt(record.loan_id) === parseInt(loan_id);
  });

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        {/* <h3 className="Modal__title p-b-1">Loan ID: <span>{loan_id}</span></h3> */}
        <h3 className="Modal__title p-b-1">Denial Summary</h3>
        {/* 2. Confirm Update Role Modal*/}
        <div id="update-role-confirm">
          <div className="m-b-30">
            <h5>Are you sure you want to deny the following loan? </h5>
            <p>Borrower Name: {`${first_name} ${last_name}`}</p>
            <p>Loan Amount: {loan_amount}</p>
            <form onSubmit={denyLoan}>
              <label style={{ width: "100%" }}>
                Comment
                <input
                  className="form-control"
                  value={state?.comment}
                  onChange={onCommentChange}
                  placeholder="Reason for denying this loan"
                />
              </label>
              <div className="Form__action-buttons m-t-2">
                <button
                  type="submit"
                  data-mode="disburse"
                  data-id={loan_id}
                  onClick={denyLoan}
                  disabled={!state?.comment}
                  className="btn zoom-element btn login-btn m-0 mr-auto"
                >
                  <i className="fa fa-check-circle" /> Proceed
                </button>
                <button
                  type="button"
                  onClick={props.onCloseModal}
                  className="btn zoom-element btn login-btn m-0 ml-auto"
                >
                  <i className="fa fa-times-circle" /> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* 3. Updating Role Loader*/}
        <div
          id="update-role"
          style={{ display: "none" }}
          className=" loading-overlay speed-1x fadeInRight"
        >
          <div className=" form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="loading" id="adv-loader-alt" />
              <div className="center-txt webapp-ripple-text">
                <i className="notifier fas fa-user-cog" />
                Denying Loan Request ...
              </div>
            </div>
          </div>
        </div>

        {/* 4. Update Role Success Message*/}
        <div
          id="update-role-success"
          style={{ display: "none" }}
          className="loading-overlay speed-1x fadeInRight"
        >
          <div className="form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="loading success-loader">
                <i className="webapp-ripple-notification success-text fa fa-check-circle" />
              </div>
              <div className="center-txt webapp-ripple-text">
                <i className="notifier far fa-check-circle" />
                Loan Request Denied
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deny;
