import { useState } from "react";
import Paginator from "../../../../components/Pagination/Pagination.component";
import { formatMoney } from "../../../../helpers/formatter";

// Loan Details
export default function EachBatchedLoanDetails(props) {
  const loanDetails = props?.details?.loans
    ? props?.details?.loans
    : props?.details;
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  let RenderedComponent = null;

  if (!loanDetails) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are currently no logged
            activities.
          </h4>
        </div>
      </div>
    );
  } else if ((loanDetails && loanDetails.length === 0) || !loanDetails) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are currently no logged
            activities.
          </h4>
        </div>
      </div>
    );
  } else {
    RenderedComponent = (
      <div className="row m-x-sm m-y-2 grid__padding">
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Amount</th>
                <th>Interest Rate</th>
                <th>Loan ref</th>
                <th>Loan Tenure</th>
                <th>Batch Number</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {loanDetails &&
                loanDetails
                  .slice(
                    (pageNumber - 1) * size,
                    size * (pageNumber - 1) + size
                  )
                  .map((batchDisbursementData, _id) => {
                    const {
                      currencyCode,
                      firstName,
                      lastName,
                      loanAmount,
                      loanRef,
                      pubDate,
                      loanTenure,
                      batchLoanNumber,
                      interestRate,
                    } = batchDisbursementData;
                    return (
                      <tr key={_id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{formatMoney(loanAmount, currencyCode)}</td>
                        <td>{formatMoney(interestRate, currencyCode)}</td>
                        <td>{loanRef}</td>
                        <td>{loanTenure}</td>
                        <td>{batchLoanNumber}</td>
                        <td>{pubDate && pubDate.substring(0, 10)}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  return (
    <div className="m-t-1">
      {/* All Batch Loans Ready for Disbursement Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={loanDetails && loanDetails.length}
        changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
      />
    </div>
  );
}
// Account Details
export function AccountDetails(props) {
  const {
    debitAccountNumber,
    debitAccountName,
    debitBankName,
    debitBankId,
    creditAccountNumber,
    creditAccountName,
    creditBankName,
    creditBankId,
  } = props.details;

  return (
    <div className="m-b-2">
      <h5>CREDIT ACCOUNT</h5>

      <label className="label-txt m-t-1">Bank Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditBankName || "Unavailable"}
        />
        <i className="fa fa-bank " />
      </div>
      <label className="label-txt">Account Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditAccountName || "Unavailable"}
        />
        <i className="fa fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditAccountNumber || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditBankId || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <br />
      <h5>DEBIT ACCOUNT</h5>
      <label className="label-txt m-t-1">Bank Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitBankName || "Unavailable"}
        />
        <i className="fa fa-bank" />
      </div>
      <label className="label-txt">Account Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitAccountName || "Unavailable"}
        />
        <i className="fas fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitAccountNumber || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitBankId || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
    </div>
  );
}
// loan gateway details
export function LoanGatewayDetails(props) {
  const {
    disbursementGatewayReferenceNo,
    disbursementGatewayResponse,
    disbursementGatewayStatusId,
    disbursementResponseDateTime,
    disbursementGatewayRequest,
    disbursementReferenceNo,
    disbursementChannelId,
    disbursementRequestDateTime,
    disbursementGatewayFinal,
  } = props.details;

  return (
    <div className="m-b-2">
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Gateway Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursementGatewayReferenceNo || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Gateway Status ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementGatewayStatusId || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Disbursement Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursementReferenceNo || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Channel ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementChannelId || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <label className="label-txt">Disbursement Gateway Final:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementGatewayFinal || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Response:</label>
        <div className="form-group">
          <textarea
            type="text"
            readOnly
            className="user__form form-control"
            value={disbursementGatewayResponse || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Request:</label>
        <div className="form-group">
          <textarea
            type="text"
            readOnly
            className="user__form form-control"
            value={disbursementGatewayRequest || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Response Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={
              disbursementResponseDateTime
                ? disbursementResponseDateTime.substring(0, 10)
                : "Unavailable"
            }
          />
        </div>
      </div>

      <div className="row">
        <label className="label-txt">Request Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={
              disbursementRequestDateTime
                ? disbursementRequestDateTime.substring(0, 10)
                : "Unavailable"
            }
          />
        </div>
      </div>
    </div>
  );
}
