import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

export type TFundingRequestStatus = {
  reference: string;
  accountNo: string;
  loanAccountNo: string;
  amount: string;
  duration: string;
  disburseDate: string;
  status: string;
  statusDescription: string;
};

type TQuery = {
  loanRef: string;
};

const getFundingRequestStatus = async ({ loanRef }: TQuery) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_FUNDING_REQUEST_STATUS}?loanRef=${loanRef}`
  );

  return data;
};

export default function useFundingRequestStatus({ loanRef }: TQuery) {
  return useQuery<IResponse<TFundingRequestStatus>>(
    [{ loanRef }, "getFundingRequestStatus"],
    getFundingRequestStatus,
    {
      refetchOnWindowFocus: false,
    }
  );
}
