import { useEffect } from "react";

import CustomInputField from "../../../../../../components/CustomHTMLElements/CustomInputField";
import { appInsights } from "../../../../../../config/appInsights";
import { TFundingWallet } from "../../../../../../custom-hooks/useFundingWallets";

import styles from "./generalInformation.module.scss";

interface IGeneralInformation {
  walletInfo: TFundingWallet;
}

const GeneralInformation = ({ walletInfo }: IGeneralInformation) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "GeneralInformation",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className={styles.GeneralInformation}>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
          <CustomInputField
            type="text"
            label="Aggregator Name"
            name="aggregatorName"
            disabled
            labelClass={styles.Label}
            defaultValue={walletInfo?.aggregatorName}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
          <CustomInputField
            type="text"
            label="RC Number"
            name="rcNumber"
            disabled
            labelClass={styles.Label}
            defaultValue={walletInfo?.rcNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
          <CustomInputField
            type="text"
            label="Funding Partner"
            name="fundingPartner"
            disabled
            labelClass={styles.Label}
            defaultValue={walletInfo?.fundingPartner}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
          <CustomInputField
            type="text"
            label="Currency"
            name="currency"
            disabled
            labelClass={styles.Label}
            defaultValue={walletInfo?.currency}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
