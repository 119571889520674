import { useHistory } from "react-router-dom";

import ButtonLoader, { Loader } from "../../../../UI/Loaders/Loaders";
import useEachInvestorsInvestment from "../../../../custom-hooks/useEachInvestorsInvestment";
import useEachInvestorInvestmentSummary from "../../../../custom-hooks/useEachInvestorInvestmentSummary";
import { formatMoney } from "../../../../helpers/formatter";
import { attachClassNameToTag } from "../../../../helpers/attachClassNameToTag";
import {
  handleFundInvestmentModalToggle,
  handleInvestmentWithdrawalModalToggle,
  handleRollOverModalToggle,
  InvestmentStatus,
} from "./helper";
import { getCurrencyInfo } from "../../../FunderInvestmentProduct/helper";

import styles from "../InvestorsInvestment.module.scss";
import { useSelector } from "react-redux";
import { ROLE_TYPES } from "../../../../helpers/roleTypes";

const InvestorInvestment = ({
  id,
  data,
  investment_product_id,
  setSelectedRecord,
  selectedFund: currency,
}) => {
  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);
  const { roles } = loggedInUser;
  const history = useHistory();

  const {
    data: investmentsData,
    status: investmentsStatus,
    error,
  } = useEachInvestorsInvestment({
    id,
    currency,
    investmentProductId: investment_product_id,
  });

  const { data: investmentSummaryData, status: investmentSummaryStatus } =
    useEachInvestorInvestmentSummary({
      id,
      currency,
      investmentProductId: investment_product_id,
    });

  return (
    <div>
      {investmentsStatus === "loading" ? (
        <Loader centered={true} text="Loading ..." />
      ) : error ? (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> Unable to Fetch Investor's
              Investment
            </h4>
          </div>
        </div>
      ) : investmentsData &&
        investmentsData?.data &&
        investmentsStatus !== "loading" ? (
        <div className="datatable-wrapper">
          <div className=" speed-1x animated fadeInRight">
            {/*Datatable || Loader || Error Message*/}
            <div className="row mt-4">
              <div className="col-md-4 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Active Investments
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {investmentSummaryStatus === "loading" ? (
                      <ButtonLoader />
                    ) : (
                      investmentSummaryData?.total_active_investment || "N/A"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Amount Invested
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {investmentSummaryStatus === "loading" ? (
                      <ButtonLoader />
                    ) : (
                      formatMoney(
                        investmentSummaryData?.total_amount_invested,
                        getCurrencyInfo(investmentsData?.data[0]?.currency)
                          ?.symbol
                      ) || "N/A"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Interest Payable
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {investmentSummaryStatus === "loading" ? (
                      <ButtonLoader />
                    ) : (
                      formatMoney(
                        investmentSummaryData?.total_interest_payable,
                        getCurrencyInfo(investmentsData?.data[0]?.currency)
                          ?.symbol
                      ) || "N/A"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <h5 className={`${styles.portfolio} mb-3`}>Investments</h5>

            <div className="row m-x-sm m-y-2 grid__padding">
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>Amount Invested</th>
                      <th>Tenor</th>
                      <th>Total Interest Payable</th>
                      <th>Interest Repayment Type</th>
                      <th>Monthly Yield</th>
                      <th>Reference No</th>
                      <th>Deposit Type</th>
                      <th>Status</th>
                      <th>Start Date</th>
                      <th>MaturityDate</th>
                      <th>Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {investmentsData &&
                      investmentsData?.data.length > 0 &&
                      investmentsData?.data.map((request, _id) => {
                        const {
                          principal_amount,
                          tenure,
                          interest_amount,
                          interest_repayment_type,
                          monthly_interest_repayment,
                          reference_number,
                          effective_date,
                          maturity_date,
                          investment_status,
                          funder_id,
                          deposit_type,
                        } = request;
                        return (
                          <tr key={_id}>
                            <td>
                              {formatMoney(
                                principal_amount,
                                getCurrencyInfo(
                                  investmentsData?.data[0]?.currency
                                )?.symbol
                              )}
                            </td>
                            <td>{tenure} days</td>
                            <td>
                              {formatMoney(
                                interest_amount,
                                getCurrencyInfo(
                                  investmentsData?.data[0]?.currency
                                )?.symbol
                              )}
                            </td>
                            <td>{interest_repayment_type}</td>
                            <td>
                              {formatMoney(
                                monthly_interest_repayment,
                                getCurrencyInfo(
                                  investmentsData?.data[0]?.currency
                                )?.symbol
                              )}
                            </td>
                            <td>{reference_number}</td>
                            <td>{deposit_type}</td>
                            <td>
                              <span
                                className={attachClassNameToTag(
                                  investment_status
                                )}
                              >
                                {attachClassNameToTag(investment_status)}
                              </span>
                            </td>
                            <td>{effective_date?.substring(0, 10)}</td>
                            <td>{maturity_date?.substring(0, 10)}</td>
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn zoom-element btn-slim dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  {investment_status ===
                                    InvestmentStatus.ACTIVE ||
                                  investment_status ===
                                    InvestmentStatus.CLOSED ? null : (
                                    <span
                                      className="cursor-pointer dropdown-item"
                                      id="custom-rollover-button"
                                      onClick={() => {
                                        handleRollOverModalToggle();
                                        setSelectedRecord({
                                          ...request,
                                          investment_product_id,
                                        });
                                      }}
                                    >
                                      ROll OVER
                                    </span>
                                  )}

                                  <span
                                    className="cursor-pointer dropdown-item"
                                    onClick={() => {
                                      history.push({
                                        pathname: `/investment-details/${funder_id}`,
                                        state: {
                                          data: request,
                                        },
                                      });
                                    }}
                                  >
                                    VIEW DETAILS
                                  </span>
                                  {deposit_type !== "FixedDeposit" &&
                                    roles &&
                                    roles?.length > 0 &&
                                    roles?.includes(
                                      ROLE_TYPES.INVESTMENT_TOP_UP_INITIATOR
                                    ) &&
                                    investment_status ===
                                      InvestmentStatus.ACTIVE && (
                                      <span
                                        className="cursor-pointer dropdown-item"
                                        id="custom-fund-investment-button"
                                        onClick={() => {
                                          handleFundInvestmentModalToggle();
                                          setSelectedRecord({
                                            ...request,
                                            investment_product_id,
                                          });
                                        }}
                                      >
                                        FUND
                                      </span>
                                    )}

                                  {roles &&
                                    roles?.length > 0 &&
                                    roles?.includes(
                                      ROLE_TYPES.INVESTMENT_WITHDRAWAL_INITIATOR
                                    ) &&
                                    investment_status ===
                                      InvestmentStatus.ACTIVE && (
                                      <span
                                        className="cursor-pointer dropdown-item"
                                        id="custom-withdraw-investment-button"
                                        onClick={() => {
                                          handleInvestmentWithdrawalModalToggle();
                                          setSelectedRecord({
                                            ...request,
                                            investment_product_id,
                                          });
                                        }}
                                      >
                                        {deposit_type !== "FixedDeposit"
                                          ? "WITHDRAW"
                                          : "PREMATURE LIQUIDATION"}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : !investmentsData ? (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> There are currently no Investment
            </h4>
          </div>
        </div>
      ) : (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> There are currently no Investment
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestorInvestment;
