import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  Page: number;
  PageSize: number;
};

const getAllApprovableBatchRepayment = async ({ Page, PageSize }: TQuery) => {
  let queryParams = `Page=${Page}&PageSize=${PageSize}`;

  const data = await getData(
    `${ajaxEndpoints.GET_APPROVABLE_BATCH_LOAN_REPAYMENT}?${queryParams}`
  );
  return data;
};

export default function useAllApprovableBatchRepayment({
  Page,
  PageSize,
}: TQuery) {
  return useQuery(
    [{ Page, PageSize }, "getAllApprovableBatchRepayment"],
    getAllApprovableBatchRepayment
  );
}
