import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    EditorState,
  } from "draft-js";
  import React from "react";
  import { Editor } from "react-draft-wysiwyg";
  import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
  import draftToHtml from "draftjs-to-html";
  
  export const TextEditor = (props: any) => {
    const [editorState, setEditorState] = React.useState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props?.defaultValue) as any
        )
      )
    );
  
    const onEditorStateChange = (editorState: any) => {
      setEditorState(editorState);
      return props.onChange(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
    };
    return (
      <div>
        <div
          className="px-1 py-0"
          style={{
            minHeight: "250px",
            border: "1px solid #d7dce0",
            borderRadius: "4px"
          }}
        >
          <Editor
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ["inline", "blockType", "list", "textAlign"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              blockType: {
                inDropdown: false,
                options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
              },
              list: { options: ["unordered", "ordered"] },
              textAlign: {
                options: ["left", "center", "right", "justify"],
              },
            }}
          />
        </div>
      </div>
    );
  };
  