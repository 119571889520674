import { FormEvent, useState } from "react";
import { Loader } from "../../../UI/Loaders/Loaders";
import useAllInvestmentsProducts from "../../../custom-hooks/useInvestmentsProducts";
import { Link } from "react-router-dom";
import Paginator from "../../Pagination/Pagination.component";

const DraftedInvestment = () => {
  const [searchBy, setSearchBy] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  const { data, status, error } = useAllInvestmentsProducts(0);

  const filterData = (e: FormEvent) => {
    e.preventDefault();

    setSearchBy(searchBy);
  };
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt py-5">
            <i className="fas fa-bell" /> Unable to Fetch Investment Funds
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading investment funds requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              drafted investment funds requests, Please Reload The Page.
            </h4>
          </div>
        </div>
      );
    } else if ((data && data.data.length === 0) || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Fund Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  {/* <th>Created Date</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data
                    .filter(
                      (eachData) =>
                        eachData &&
                        eachData.name
                          .toString()
                          .toLowerCase()
                          .includes(searchBy.toString().toLowerCase())
                    )
                    .slice(
                      (pageNumber - 1) * size,
                      size * (pageNumber - 1) + size
                    )
                    .map((request, _id) => {
                      const { name, id, end_date, start_date } = request;
                      return (
                        <tr key={_id}>
                          <td>{_id + 1}</td>
                          <td>{name}</td>
                          <td>
                            {start_date ? start_date.substring(0, 10) : ""}
                          </td>
                          <td>{end_date ? end_date.substring(0, 10) : ""}</td>
                          {/* <td>{pub_date}</td> */}

                          <td>
                            <Link
                              to={{
                                pathname: `/investments/preview/${id}`,
                                state: {
                                  data: request,
                                },
                              }}
                              className="color-blue"
                            >
                              View Details
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      {/* {signUpError && <Alert message={signUpError} />}
        {successMessage && <Alert message={successMessage} type="success" />} */}
      <div className="row mx-1 my-3">
        <form className="" onSubmit={filterData}>
          <div className="input-group mr-3" style={{ maxWidth: "250px" }}>
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
              value={searchBy}
              name="searchBy"
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </div>
        </form>
      </div>

      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={data ? data.data.length : 0}
        changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
      />
    </div>
  );
};

export default DraftedInvestment;
