import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TQuery = {
  SearchValue?: string;
  IsActive?: string;
  OnboardingStartDate?: string;
  OnboardingEndDate?: string;
  OnboardingStatus?: string;
};

export type TAggregatorResponse = {
  data: {
    data: {
      aggregator_id: number;
      aggregator_photo_url: string | null;
      aggregator_rep: string | null;
      aggregator_type: string;
      all_borrowers: any;
      all_funders: any;
      allow_bank_account_validation: boolean;
      allow_bulk_loan_application_via_ui: number;
      allow_bvn_validation: boolean;
      allow_loan_approval_notification: boolean;
      allow_widget_edit: boolean;
      biz_Address: string;
      biz_category: string;
      biz_description: string;
      biz_email: string;
      biz_name: string;
      biz_phone: string;
      biz_registration_number: string;
      biz_registration_type: number;
      city: string | null;
      is_active: boolean;
      maximum_running_loan: number;
      modified_date: string;
      photo: string | null;
      private_key: string | null;
      pub_date: string;
      public_key: string | null;
      sector_id: number;
      send_email_to_funders: boolean;
      short_name: string;
      state_name: string | null;
      xaggregator_domain: string | null;
    }[];
  };
};
export const getAllAggregators = async (queryParams?: TQuery) => {
  let data = null;
  if (queryParams) {
    data = await getData(
      `${ajaxEndpoints.ECOSYSTEMS}?${new URLSearchParams(
        queryParams
      ).toString()}`
    );
  } else {
    data = await getData(ajaxEndpoints.ECOSYSTEMS);
  }

  return data;
};

export default function useAllAggregatorsTyped(queryParams?: TQuery) {
  return useQuery<TAggregatorResponse>(
    [queryParams, "getAllAggregatorsTyped"],
    getAllAggregators,
    {
      refetchOnWindowFocus: false,
    }
  );
}
