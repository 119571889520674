import { MDBTable } from "mdbreact";
import { useState } from "react";
import { useDispatch } from "react-redux";
import useBatchDisbursement from "../../../../../custom-hooks/useBatchDisbursement";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import Paginator from "../../../../Pagination/Pagination.component";
import { showModal } from "../../../../../redux/actions/modal";
import { setToast } from "../../../../../redux/actions/toast";
import { postDataWithDotNet } from "../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { queryCache } from "react-query";
import { appInsights } from "../../../../../config/appInsights";

function BatchDisbursement() {
  const [state, setState] = useState<{
    checkStatus: string;
    loansForDisbursement: any[];
    totalAmount: number;
    disbursementError: boolean;
    disbursementSuccess: boolean;
  }>({
    // loading: false,
    checkStatus: "off",
    loansForDisbursement: [],
    totalAmount: 0,
    disbursementError: false,
    disbursementSuccess: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  const [loading, setLoading] = useState<string | null>(null);
  const [success, setSuccess] = useState(null);
  const [disburseError, setDisburseError] = useState(null);

  const dispatch = useDispatch();

  const resetLoansForDisbursement = () => {
    setState({
      ...state,
      loansForDisbursement: [],
      totalAmount: 0,
      checkStatus: "off",
    });
  };
  // Disburse Selected Loans
  const disburseSelectedLoans = (e: any) => {
    e.preventDefault();
    const loansForDisbursement = state.loansForDisbursement;
    if (loansForDisbursement.length === 0)
      return dispatch(setToast("warning", "You have not selected any loans"));

    dispatch(
      showModal({
        modalIdentifier: "Loan",
        dataId: e.target.dataset["ref"],
        action: "disburseapprovedbatchloan",
        subModal: "multiple",
        loans: state.loansForDisbursement,
        totalAmount: state.totalAmount,
        resetLoansForDisbursement,
        disburseBatchLoans,
        setDisburseError,
        setSuccess,
      })
    );
  };
  const disburseBatchLoans = async (reference: any) => {
    setLoading("Disbursing Batch Loans...");
    setDisburseError(null);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.DISBURSEMENT_APPROVED_BATCH_DISBURSEMENT,
        reference
      );
      setLoading(null);
      setSuccess(response.message);
      queryCache.invalidateQueries(["getBatchDisbursement"]); // setSuccess("loan has been disbursed");
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "BatchDisbursement.jsx",
        },
      });
      setLoading(null);
      setDisburseError(errorHandler(error));
      setTimeout(() => {
        setDisburseError(null);
      }, 5000);
    }
  };
  // Multiple Checkbox
  const checkAllLoansForDisbursement = (e: any) => {
    e.preventDefault();
    if (e.target.dataset["state"] === "checked") {
      const allLoans = document.querySelectorAll(
        ".loans_for_disbursement"
      ) as any;
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }

      e.target.dataset["state"] = "unchecked";

      setState({
        ...state,
        checkStatus: "off",
        totalAmount: 0,
        loansForDisbursement: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans = document.querySelectorAll(
      ".loans_for_disbursement"
    ) as any;
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const productId = Number(allLoans[i].dataset["ref"]);
      const loanCount = Number(allLoans[i].dataset["loancount"]);
      loans.push({ productId, loanCount });
      totalAmount += parseFloat(
        JSON.parse(allLoans[i].dataset["record"]).amount
      );
    }

    e.target.dataset["state"] = "checked";

    setState({
      ...state,
      checkStatus: "on",
      totalAmount,
      loansForDisbursement: [...state.loansForDisbursement, ...loans],
    });
  };
  // Open Modal
  const modalHandler = (
    e: any,
    tableName: string,
    modalRow: any,
    action: string,
    resetLoansForDisbursement?: any,
    subModal?: any
  ) => {
    if (subModal === "single") {
      const loans = [];
      const productId = modalRow.productId;
      const loanCount = modalRow.loanCount;
      loans.push({ productId: productId, loanCount: loanCount });

      dispatch(
        showModal({
          modalIdentifier: tableName,
          dataRecord: modalRow,
          eventTarget: e.target,
          action: action,
          subModal,
          loans: loans,
          resetLoansForDisbursement,
          disburseBatchLoans,
          setSuccess,
          setDisburseError,
          request: modalRow,
        })
      );
    } else {
      dispatch(
        showModal({
          modalIdentifier: tableName,
          dataRecord: modalRow,
          eventTarget: e.target,
          action: action,
          request: modalRow,
        })
      );
    }
  };
  // Single Checkbox
  const checkboxHandler = ({ dataRecord: { amount }, eventTarget }: any) => {
    let loans = [];
    const productId = Number(eventTarget.dataset["ref"]);
    const loanCount = Number(eventTarget.dataset["loancount"]);
    loans.push({ productId, loanCount });
    if (eventTarget.checked === false) {
      const loansForDisbursement = state.loansForDisbursement;
      const newLoansForDisbursement = loansForDisbursement.filter((loan) => {
        return Number(loan.productId) !== Number(productId);
      });

      return setState({
        ...state,
        loansForDisbursement: [...newLoansForDisbursement],
        totalAmount: state.totalAmount - parseFloat(amount),
      });
    }

    setState({
      ...state,
      loansForDisbursement: [...state.loansForDisbursement, ...loans],
      totalAmount: state.totalAmount + parseFloat(amount),
    });
  };

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error } = useBatchDisbursement();

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Batch Disbursement
            Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Batch Disbursement Loans..." />
    );
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Batch Disbursement Loans, Please Reload The Page
            </h4>
          </div>
        </div>
      );
    } else if (disburseError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {disburseError}
            </h4>
          </div>
        </div>
      );
    } else if (success) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {success}
            </h4>
          </div>
        </div>
      );
    } else if ((data && data.length === 0) || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <thead className="bg-lighter-grey">
              <tr>
                <th></th>
                <th>S/N</th>
                <th>Aggregator</th>
                <th>Product</th>
                <th>Amount</th>
                <th>No of Loans</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .slice(
                    (pageNumber - 1) * size,
                    size * (pageNumber - 1) + size
                  )
                  .map((batchDisbursementData: any, _id: number) => {
                    const {
                      productName,
                      aggregator,
                      amount,
                      loanCount,
                      productId,
                    } = batchDisbursementData;
                    return (
                      <tr key={_id}>
                        <td>
                          <input
                            type="checkbox"
                            data-record={JSON.stringify(batchDisbursementData)}
                            data-id={Number(productId)}
                            data-ref={Number(productId)}
                            data-loancount={Number(loanCount)}
                            defaultChecked={state.loansForDisbursement.includes(
                              Number(productId)
                            )}
                            onClick={(e) =>
                              checkboxHandler({
                                dataRecord: batchDisbursementData,
                                eventTarget: e.target,
                              })
                            }
                            className={`Loan loans_for_disbursement`}
                          />
                        </td>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{aggregator}</td>
                        <td>{productName}</td>
                        <td>{amount}</td>
                        <td>{loanCount}</td>
                        <td>
                          <span
                            data-id={_id}
                            data-table={"Loan"}
                            data-action={"getapprovalbatchdisbursementview"}
                            className="view btn-manage zoom-element btn-slim"
                            onClick={(e) => {
                              modalHandler(
                                e,
                                "Loan",
                                batchDisbursementData,
                                "batcheddetails"
                              );
                            }}
                          >
                            View
                          </span>
                          <span
                            id="0"
                            data-table="Notifications"
                            data-action="disburse"
                            className="Notifications disburse btn-manage zoom-element btn-slim"
                            onClick={(e) => {
                              modalHandler(
                                e,
                                "Loan",
                                batchDisbursementData,
                                "disburseapprovedbatchloan",
                                resetLoansForDisbursement,
                                "single"
                              );
                            }}
                          >
                            Disburse Batch
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </MDBTable>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <div className="m-l-1 m-r-1">
            <div className="block-header">
              <div className="flex-row">
                <h3 className="m-l-1 m-b-1 flex-row">Batch Disbursement</h3>
                <div className="flex-row m-b-1">
                  <div className="m-r-1">
                    <div className="rounded-border flex-row">
                      <div
                        data-state="unchecked"
                        onClick={checkAllLoansForDisbursement}
                      >
                        <i
                          className={`adv-icon fas fa-toggle-${state.checkStatus} fa-2x`}
                        />
                      </div>
                      &nbsp; <span>Select all</span>
                    </div>
                  </div>

                  <div className="m-r-1 rounded-border">
                    Selected: {state.loansForDisbursement.length}
                  </div>

                  <div className="rounded-border">
                    Total Amount: {state.totalAmount}
                  </div>
                </div>
                <div className="m-b-1">
                  <button
                    id="create"
                    data-id="create-role"
                    data-action="create"
                    className="create zoom-element btn login-btn"
                    onClick={disburseSelectedLoans}
                  >
                    <i className="far fa-credit-card" /> &nbsp; Disburse
                  </button>
                </div>
              </div>
            </div>
            {/* All Batch Loans Ready for Disbursement Datatable || Loader || Error Message*/}
            {RenderedComponent}
            <Paginator
              size={size}
              page={pageNumber}
              count={data && data.length}
              changeCurrentPage={(pageNumber: number) =>
                setPageNumber(pageNumber)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BatchDisbursement;
