import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
//import { updateAuthenticated } from "./../../actions/index";

// Custom UI Components

export class Notfound extends Component {
  // Methods
  toggleOverlay = () => {
    if (document.querySelector("#mySidebar").classList.contains("show")) {
      document.querySelector("#mySidebar").classList.remove("show");
    }

    document.querySelector("#overlay").classList.remove("show");
  };

  toggleSideNav = () => {
    document.querySelector("#mySidebar").classList.toggle("show");
    document.querySelector("#overlay").classList.toggle("show");
  };

  toggleModal = () => {
    // Get and display the modal
    document.querySelector("#displayModal").style.display = "block";
  };

  toggleNotification = () => {
    // Get and display notifications
    document.querySelector("#overlay").classList.toggle("show");
  };

  handleNavLinkClick = (link) => {
    this.toggleSideNav();
    if (link === "notifications") {
      this.toggleNotification();
    } else {
      this.setState({
        display: link,
      });
    }
  };

  // Life Cycle Hooks
  componentDidMount() {
    this.loginHandler();
  }

  // Methods
  loginHandler = () => {};

  //JSX Template
  render() {
    return (
      <div className="center-container">
        <div className="center-container-wrapper">
          <div className="center-element">
            <div className="normal-1x zoomIn">
              <div className="tfx-user-form-wrapper box-effect full-border">
                <div className="tfx-user-form-inner">
                  <div
                    id="adv-processing-signup-successful"
                    className="loading-overlay"
                  >
                    <div className="form-loader form-loader-login verify ">
                      <div className="centerLoader">
                        <div className="loading success-loader">
                          <i className="animated float-label webapp-ripple-notification error-text fab fa-grav" />
                        </div>

                        <div className="center-txt webapp-ripple-text">
                          404 Error | Page not Found
                        </div>

                        <div className="onboarding-response center-txt webapp-ripple-text">
                          We're really sorry but the page you're looking does
                          not exist. Kindly click use the menu above or go back.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, loggedInUser } = state.authReducer;

  return {
    isAuthenticated,
    loggedInUser,
  };
};

export default connect(mapStateToProps)(withRouter(Notfound));
