import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../config/appInsights";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "../../NewComponents/ToggleTab/ToggleTab.component";
import InvestmentDetails from "./Tabs/InvestmentDetails";
import InvestmentTransactions from "./Tabs/InvestmentTransactions";

export default function EachFunderInvestment() {
  const location = useLocation();
  const {
    data: {
      funder_name,
      principal_amount,
      currency,
      tenure,
      monthly_interest_repayment,
      effective_date,
      maturity_date,
      investment_product_name,
      interest_repayment_type,
      pub_date,
      outstanding_interest_amount,
      interest_amount,
      maturity_amount,
      outstanding_settlement,
      total_interest_paid,
      reference_number,
      maturityInstruction,
      parent_investment_reference,
      child_investment_reference,
      rollOverType,
      is_roll_over,
      interest_rate,
      id,
    },
  } = location?.state as {
    data: {
      funder_name: string;
      principal_amount: number;
      currency: string;
      tenure: string;
      monthly_interest_repayment: string;
      effective_date: string;
      maturity_date: string;
      investment_product_name: string;
      investor_type: string;
      interest_repayment_type: string;
      pub_date: string;
      outstanding_interest_amount: number;
      interest_amount: number;
      maturity_amount: number;
      outstanding_settlement: number;
      total_interest_paid: number;
      reference_number: string;
      maturityInstruction: string;
      parent_investment_reference: string;
      child_investment_reference: string;
      rollOverType: string;
      is_roll_over: boolean;
      interest_rate: string;
      id: number;
    };
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investment Details  - (EachFunderInvestment.jsx)",
      isLoggedIn: true,
    });
  }, []);
  const [activeTab, setActiveTab] = useState("investment-details");

  const getActiveScreen = () => {
    switch (activeTab) {
      case "investment-details":
        return (
          <InvestmentDetails
            principal_amount={principal_amount}
            currency={currency}
            tenure={tenure}
            monthly_interest_repayment={monthly_interest_repayment}
            effective_date={effective_date}
            maturity_date={maturity_date}
            investment_product_name={investment_product_name}
            interest_repayment_type={interest_repayment_type}
            pub_date={pub_date}
            outstanding_interest_amount={outstanding_interest_amount}
            interest_amount={interest_amount}
            maturity_amount={maturity_amount}
            outstanding_settlement={outstanding_settlement}
            total_interest_paid={total_interest_paid}
            maturityInstruction={maturityInstruction}
            parent_investment_reference={parent_investment_reference}
            child_investment_reference={child_investment_reference}
            rollOverType={rollOverType}
            is_roll_over={is_roll_over}
            interest_rate={interest_rate}
          />
        );
      case "investment-transactions":
        return <InvestmentTransactions currency={currency} id={id} />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> {funder_name}
          {` (REF: ${reference_number && reference_number})`}
        </h3>
      </div>
      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Investment Details"
          id="investment-details"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Investment Transactions"
          id="investment-transactions"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <div className="col-8">{getActiveScreen()}</div>
    </Fragment>
  );
}
