import React, { useState } from "react";

import ajaxMethods from "../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { Loader } from "../../../UI/Loaders/Loaders";
import { filterDataByColumns } from "../../../helpers/tableControllers";
import { CreditBureauTable } from "../CreditBureauTable/CreditBureauTable";
import { CrcBusinessData } from "../CreditBureauTable/CRC/CrcBusinessData";
import { appInsights } from "../../../config/appInsights";

export const CrcCorporate = (props) => {
  const [rcNumber, SetRcNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [newTableData, setNewTableData] = useState({});
  const [tableData, setTableData] = useState({});
  const getCreditReport = (e) => {
    e.preventDefault();
    props.clearToast();
    if (!rcNumber || !rcNumber.length) {
      return props.onSetToast("error", "Please enter your rc number");
    }
    setLoading(true);
    ajaxMethods
      .get(ajaxEndpoints.GET_CRC_CORPORATE + "?RcNumber=" + rcNumber)
      .then((response) => {
        setLoading(false);
        if (response) {
          if (response.commercialHitResponse.body) {
            setTableData(
              response.commercialHitResponse.body.consCommDetails
                .consCommDetails_Subject
            );
            if (response.commercialHitResponse.body.summaryOfPerformance) {
              props.onSetToast("success", "Request successful");
              sortTableData(
                response.commercialHitResponse.body.summaryOfPerformance
              );
            } else {
              props.onSetToast(
                "success",
                "Request successful but no available data"
              );
            }
          } else {
            props.onSetToast(
              "success",
              "Request successful but no available data"
            );
          }
        } else {
          props.onSetToast("error", "Unable to connect to advancly services.");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "CrcCorporate.js",
          },
        });
        setLoading(false);
        props.onSetToast("error", "Unable to connect to advancly services.");
      });
  };

  const sortTableData = (creditAgreementSummary) => {
    let rows = [];
    creditAgreementSummary.map((data, index) => {
      let row_data = {
        id: index + 1,
        institutioN_NAME: data.institutioN_NAME,
        facilitieS_COUNT: data.facilitieS_COUNT,
        performinG_FACILITY: data.performinG_FACILITY,
        nonperforminG_FACILITY: data.nonperforminG_FACILITY,
        approveD_AMOUNT: data.approveD_AMOUNT,
        accounT_BALANCE: data.accounT_BALANCE,
        overduE_AMOUNT: data.overduE_AMOUNT,
      };
      rows.push(row_data);
      return rows;
    });

    let tableHeader = {
      columns: [
        { field: "id", label: "id", sort: "asc", width: 370 },
        {
          field: "institutioN_NAME",
          label: "INSTITUTION",
          sort: "asc",
          width: 670,
        },
        {
          field: "facilitieS_COUNT",
          label: "FACILITY COUNT",
          sort: "asc",
          width: 700,
        },
        {
          field: "performinG_FACILITY",
          label: "PERFORMING FACILITY",
          sort: "asc",
          width: 700,
        },
        {
          field: "nonperforminG_FACILITY",
          label: "NON PERFORMING FACILITY",
          sort: "asc",
          width: 500,
        },
        {
          field: "approveD_AMOUNT",
          label: "APPROVED AMOUNT",
          sort: "asc",
          width: 600,
        },
        {
          field: "accounT_BALANCE",
          label: "ACCOUNT BALANCE",
          sort: "asc",
          width: 600,
        },

        {
          field: "overduE_AMOUNT",
          label: "OVERDUE AMOUNT",
          sort: "asc",
          width: 670,
        },
      ],
      rows: [],
    };

    const columnsToExclude = [];
    let filteredTableData = filterDataByColumns(
      tableHeader,
      "exclude",
      columnsToExclude
    );
    filteredTableData = {
      columns: [...tableHeader.columns],
      rows: [...rows],
    };
    setNewTableData(filteredTableData);
  };

  if (loading) return <Loader text="Loading..." centered={true} />;

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0">
              <i className="adv-icon fas fa-search" />
              &nbsp; CRC Corporate
            </h3>
            <p className="settings-block text-muted">
              View the Credit History for a Corporate Borrower
            </p>
            <form onSubmit={getCreditReport}>
              <label className="label-txt">RC Number</label>
              <div className="form-group inputWithIcon">
                <input
                  className="user__form form-control"
                  autoComplete="off"
                  name="treshold_amount"
                  value={rcNumber}
                  required
                  onChange={(e) => SetRcNumber(e.target.value)}
                />
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn login-btn"
                  data-form="investment"
                  disabled={loading}
                >
                  <i className="fas fa-share" />
                  &nbsp; Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>
          <CrcBusinessData tableData={tableData} />
        </div>
      </div>
      <CreditBureauTable newTableData={newTableData} loading={loading} />
    </div>
  );
};
