import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  id: number;
  currency: string;
  investmentProductId: number;
};

const getEachInvestorInvestmentSummary = async ({
  id,
  currency,
  investmentProductId,
}: TQuery) => {
  if (investmentProductId && currency && id) {
    const queryParams = `?funderId=${Number(id)}&investmentProductId=${Number(
      investmentProductId
    )}&currency=${currency}`;

    const data = await getDataWithDotNet(
      `${ajaxEndpoints.EACH_INVESTOR_INVESTMENT_SUMMARY}${queryParams}`
    );
    return data.data;
  }
};
export default function useEachInvestorInvestmentSummary({
  id,
  currency,
  investmentProductId,
}: TQuery) {
  return useQuery(
    [{ id, currency, investmentProductId }, "getEachInvestorInvestmentSummary"],
    getEachInvestorInvestmentSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
}
