import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

export const getAllProductTypes = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.GET_AGGREGATOR_TYPES);
  return data.data;
};

export default function useAllProductType() {
  return useQuery("allProductTypes", getAllProductTypes, {
    refetchOnWindowFocus: false,
  });
}
