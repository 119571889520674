import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postData } from "../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import * as yup from "yup";
import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import { appInsights } from "../../../../../config/appInsights";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("This is an invalid email")
    .required("Please enter new user's email")
    .min(5, "Email must have at least 5 characters")
    .max(256, "Email cannot be longer than 256 characters"),
});

const ValidateEmail = ({
  onCloseModal,
  setSuccessMessage,
  setErrorMessage,
  setActiveTab,
  setValidatedEmail,
}) => {
  const [isValidating, setIsValidating] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const validateNewUserEmail = async (val) => {
    setValidatedEmail(val?.email);
    setIsValidating(true);
    try {
      const response = await postData(
        `${ajaxEndpoints.VALIDATE_EMAIL}/${val?.email}`
      );
      if (response?.data?.status_code === 200) {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
          if (response?.data?.data?.existsOnlyOnSso) {
            setActiveTab("createUser");
          }
        }, 3000);
      }
    } catch (error) {
      if (error?.request?.status === 404) {
        setTimeout(() => {
          setActiveTab("createUser");
        }, 3000);
      } else {
        setErrorMessage(error?.response?.data?.message);
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.message);
        }, 5000);
      }

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Validate.component.jsx",
        },
      });
    }
    setIsValidating(false);
  };

  useEffect(() => {
    return () => {
      setSuccessMessage("");
      setErrorMessage("");
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(validateNewUserEmail)}>
        <CustomInputField
          type="email"
          maxLength="50"
          reference={register}
          name="email"
          placeholder="Email"
          label="Check if email already exists"
          errors={errors.email}
          iconType="fas fa-envelope"
        />
        <div className="Form__action-buttons m-t-1">
          <button
            type="button"
            className="btn zoom-element btn login-btn m-0 mr-auto"
            onClick={onCloseModal}
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Cancel
          </button>
          <button
            type="submit"
            className="btn zoom-element btn login-btn m-0 ml-auto"
          >
            <i className="fas fa-plus"></i>
            &nbsp; Validate {isValidating && <ButtonLoader />}
          </button>
        </div>
      </form>
    </>
  );
};

export default ValidateEmail;
