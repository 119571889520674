import React from "react";
import styles from "./UploadBankStatement.module.scss";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";

interface ViewBankStatementModalProps {
  setShowDocViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  documentRecord: any;
  isLoadingBankStatementView: boolean;
}

const ViewBankStatementModal = ({
  setShowDocViewModal,
  documentRecord,
  isLoadingBankStatementView,
}: ViewBankStatementModalProps) => {
  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoadingBankStatementView ? (
            <ButtonLoader />
          ) : (
            <>
              <object
                type={documentRecord?.type}
                data={documentRecord?.documentUrl}
                width="1000"
                height="800"
                style={{
                  objectFit: "contain",
                }}
              ></object>
            </>
          )}
        </div>
        <div className="Form__action-buttons m-t-2">
          <button
            type="button"
            className="btn zoom-element btn login-btn"
            onClick={() => setShowDocViewModal(false)}
            data-dismiss="modal"
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Close
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewBankStatementModal;
