import React, { Fragment } from "react";
import { formatMoney } from "../../../../helpers/formatter";

const PreviewLoan = ({ properties, onCloseModal }) => {
  const {
    RepaymentToCreate,
    resetAllFields,
    success,
    fileUploadError,
    loading,
    submitRepayment,
  } = properties;
  const uploadNewFile = () => {
    resetAllFields();
    onCloseModal();
  };
  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <h3 className="Modal__title p-b-2">Review Upload</h3>
        <hr />
        {/* If there is no file error and a valid file and has been selected */}
        {RepaymentToCreate && !fileUploadError && !success && (
          <Fragment>
            <div className="table-responsive">
              <table className="table table-white table-hover">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Reference Number</th>
                    <th>Loan Amount</th>
                    <th>Repayment Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {RepaymentToCreate.length ? (
                    <Fragment>
                      {RepaymentToCreate.map((repayment, _id) => {
                        const {
                          repaymentAmount,
                          loanRef,
                          repaymentDate,
                          rowStatus,
                        } = repayment;
                        return (
                          <tr key={_id}>
                            <td>{_id + 1}</td>
                            <td>{loanRef}</td>
                            <td>{formatMoney(repaymentAmount)}</td>
                            <td>{repaymentDate.substr(0, 10)}</td>
                            <td>
                              {rowStatus.length ? (
                                <ul className="list-unstyled">
                                  {[...new Set(rowStatus)].map((row, _id) => {
                                    return (
                                      <li className="text-danger" key={_id}>
                                        {`Error - ${row}`}
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <span className="text-success">
                                  Correct data format
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  ) : (
                    <div>"No Data Available"</div>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <span
                onClick={uploadNewFile}
                className="color-blue cursor-pointer"
              >
                Upload New File
              </span>
              <div>
                <button
                  className="btn btn-sm advancly-nobg-btn mr-2"
                  onClick={() => {
                    resetAllFields();
                    onCloseModal();
                  }}
                >
                  Cancel
                </button>

                <button
                  className="btn advancly-btn btn-sm transition-3d-hover"
                  disabled={loading}
                  type="submit"
                  onClick={submitRepayment}
                >
                  Request Repayment
                </button>
              </div>
            </div>
          </Fragment>
        )}

        {/* If there is no file error and a valid file and has been selected but there was an error while uploading */}
        {RepaymentToCreate &&
          !success &&
          fileUploadError &&
          fileUploadError.length && (
            <Fragment>
              <p className="text-left text-danger">
                The uploaded file contains some errors, see preview below.
              </p>
              <div className="table-responsive">
                <table className="table table-white table-hover">
                  <thead>
                    <tr>
                      <th>Error(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fileUploadError.length ? (
                      <Fragment>
                        {fileUploadError.map((error, _id) => {
                          return (
                            <tr key={_id}>
                              <td>{error}</td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <p className="text-center text-danger">
                        No Error Information available
                      </p>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex mt-3">
                <span
                  // onClick={uploadNewFile}
                  className="color-blue cursor-pointer"
                >
                  Upload New File
                </span>
              </div>
            </Fragment>
          )}

        {/* If there is no file error and a valid file and has been selected and the upload was succesful */}
        {RepaymentToCreate && !fileUploadError && success && (
          <Fragment>
            <h5 className="text-center text-success my-5 text-uppercase font-weight-bold">
              {success}{" "}
              <span
                // onClick={redirectToLoanStatusPage}
                className="color-blue"
              >
                Click here
              </span>{" "}
              to view the status of your upload
            </h5>
          </Fragment>
        )}

        {/* If there is no file error and a valid file has been selected and the upload was succesful but few roles have errors */}
        {RepaymentToCreate && fileUploadError && success && (
          <Fragment>
            <h5 className="text-center text-success my-5 text-uppercase font-weight-bold">
              {success}{" "}
              <span
                // onClick={redirectToLoanStatusPage}
                className="color-blue"
              >
                Click here
              </span>{" "}
              to view the status of your upload
            </h5>
            <p className="text-left text-danger">
              However, some rows contain errors, see preview below.
            </p>
            <div className="table-responsive">
              <table className="table table-white table-hover">
                <thead>
                  <tr>
                    <th>Error(s)</th>
                  </tr>
                </thead>
                <tbody>
                  {fileUploadError.length ? (
                    <Fragment>
                      {fileUploadError.map((error, _id) => {
                        return (
                          <tr key={_id}>
                            <td>{error}</td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  ) : (
                    "No Error Information available"
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex mt-3">
              <span
                //   onClick={uploadNewFile}
                className="color-blue cursor-pointer"
              >
                Upload New File
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default PreviewLoan;
