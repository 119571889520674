import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getPendingApprovalInvestors = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.GET_PENDING_INVESTOR_DETAILS
  );
  return data;
};

export default function usePendingApprovalInvestors() {
  return useQuery(
    ["getPendingApprovalInvestors"],
    getPendingApprovalInvestors,
    {
      refetchOnWindowFocus: false,
    }
  );
}
