import { MDBDataTable } from "mdbreact";
import React from "react";

export const CreditBureauTable = ({ newTableData, loading }) => {
  return (
    <div>
      {!loading && Object.keys(newTableData).length > 0 && (
        <div className="grid__padding animated speed-1x fadeInRight">
          <MDBDataTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            data={newTableData}
          />
        </div>
      )}
    </div>
  );
};
