import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

export const getAggregatorProductTypes = async (id: number) => {
  const response = await getData(
    `${ajaxEndpoints.GET_AGGREGATOR_PRODUCT_TYPES}?aggregatorId=${id}`
  );
  return response;
};
