import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getNotificationUserCategories = async () => {
  const response = await getData(ajaxEndpoints.GET_USER_CATEGORIES);
  return response?.data;
};

export default function useNotificationUserCategories() {
  return useQuery("notificationUserCategories", getNotificationUserCategories, {
    refetchOnWindowFocus: false,
  });
}
