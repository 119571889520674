import { useEffect, useState } from "react";

//Redux
import { connect } from "react-redux";
import { setToast, clearToast } from "./../../redux/actions/toast";

// Custom Components
import Tabs from "./../Tabs/Tabs";

// Component Settings
import { FirstCentralIndividual } from "./Tabs/FirstCentralIndividual";
import { FirstCentralCorporate } from "./Tabs/FirstCentralCorporate";
import { CrcIndividual } from "./Tabs/CrcIndividual";
import { CrcCorporate } from "./Tabs/CrcCorporate";
import { appInsights } from "../../config/appInsights";

const CreditBureau = (props) => {
  const [activeTab, setActiveTab] = useState("crc individual");

  const tabs = [
    {
      name: "CRC Individual",
      id: "crc individual",
      icon: "fas fa-credit-card",
      identifier: "settingsTab",
    },
    {
      name: "CRC Corporate",
      id: "crc corporate",
      icon: "fas fa-credit-card",
      identifier: "settingsTab",
    },
    {
      name: "First Central (Indiv)",
      id: "first central individual",
      icon: "fa fa-money-check",
      identifier: "settingsTab",
    },
    {
      name: "First Central (Corp)",
      id: "first central corporate",
      icon: "fa fa-money-check",
      identifier: "settingsTab",
    },
  ];

  const setToastHandler = (type, message) => {
    props.setToast(type, message);
  };
  const setClearToastHandler = (type, message) => {
    props.clearToast(type, message);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Credit Bureau - (CreditBureau.js)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="datatable-wrapper">
      <div className="table-view animated speed-1x fadeInRight">
        {/* Tabs */}
        <Tabs onContentToggle={(e) => setActiveTab(e)} tabs={tabs} />

        <div className="row clearfix grid__padding">
          {/*Datatables*/}
          {(() => {
            switch (activeTab) {
              case "crc individual":
                return (
                  <CrcIndividual
                    onSetToast={setToastHandler}
                    clearToast={setClearToastHandler}
                  />
                );

              case "crc corporate":
                return (
                  <CrcCorporate
                    onSetToast={setToastHandler}
                    clearToast={setClearToastHandler}
                  />
                );
              case "first central individual":
                return (
                  <FirstCentralIndividual
                    onSetToast={setToastHandler}
                    clearToast={setClearToastHandler}
                  />
                );
              case "first central corporate":
                return (
                  <FirstCentralCorporate
                    onSetToast={setToastHandler}
                    clearToast={setClearToastHandler}
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { setToast, clearToast })(CreditBureau);
