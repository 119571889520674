import { useState, ChangeEvent, SyntheticEvent, useEffect } from "react";

import { useDispatch } from "react-redux";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import useCompanyWalletTransactions from "../../custom-hooks/useCompanyWalletTransactions";
import { TCompanyWalletTransactionsParams } from "./types";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import { formatMoney } from "../../helpers/formatter";
import { showModal } from "../../redux/actions/modal";
import { appInsights } from "../../config/appInsights";

interface ICompanyWalletTransaction {
  debitAmount: number;
  creditAmount: number;
  transactionStatus: string;
  transactionRemarks: string;
  transactionDate: string;
}

const CompanyWalletTransactions = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] =
    useState<TCompanyWalletTransactionsParams>({
      AccountNumber: urlParams.get("accountNumber"),
      ConsumerType: "Company",
      StartDate: "",
      EndDate: "",
      Page: 1,
      PageSize: 10,
    });

  const [searchParams, setSearchParams] =
    useState<TCompanyWalletTransactionsParams>({
      AccountNumber: urlParams.get("accountNumber"),
      ConsumerType: "Company",
      StartDate: "",
      EndDate: "",
      Page: 1,
      PageSize: 10,
    });

  const {
    data: companyWalletsTransactions,
    status,
    error,
  } = useCompanyWalletTransactions(searchQuery);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Company Wallet Transactions - (CompanyWalletTransactions.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const diffTime =
      new Date(searchParams.EndDate).valueOf() -
      new Date(searchParams.StartDate).valueOf();

    if (diffTime < 0) {
      dispatch(
        showModal({
          modalIdentifier: "audittrail",
          action: "customrange",
          dataRecord: "",
          type: "alert",
        })
      );
      return;
    }
    setSearchQuery(searchParams);
  };

  let RenderedComponent = null;
  if (!companyWalletsTransactions && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to fetch Company Wallet
            Transactions
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Company Wallet Transactions..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Company Wallet Transactions, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (companyWalletsTransactions &&
        companyWalletsTransactions?.data.data &&
        companyWalletsTransactions?.data.data.transactions.length === 0) ||
      (companyWalletsTransactions?.data?.data &&
        !companyWalletsTransactions?.data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Transaction.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Transaction Date</th>
                  <th>Narration</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Transation Status</th>
                </tr>
              </thead>
              <tbody>
                {companyWalletsTransactions?.data.data &&
                  companyWalletsTransactions?.data.data.transactions.map(
                    (request: ICompanyWalletTransaction, _id: number) => {
                      const {
                        debitAmount,
                        creditAmount,
                        transactionStatus,
                        transactionRemarks,
                        transactionDate,
                      } = request;
                      return (
                        <tr key={_id}>
                          <td>
                            {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                              (_id + 1)}
                          </td>
                          <td>{transactionDate}</td>
                          <td>{transactionRemarks}</td>
                          <td>{debitAmount}</td>
                          <td>{creditAmount}</td>
                          <td>{transactionStatus}</td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-3">
            <span className="d-flex align-items-center">
              <BackButtonComponent />
            </span>
            <div className="d-flex flex-row flex-wrap">
              <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 mt-2 mr-3">
                <label htmlFor="table-fetch" className="form-group">
                  Number of results:{" "}
                  <select
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setSearchQuery((prev) => ({
                        ...prev,
                        PageSize: Number(e.target.value),
                      }));
                    }}
                    id="table-fetch"
                    className="table-fetch-select m-l-1"
                    style={{ height: "2.7rem" }}
                    value={searchQuery?.PageSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </label>
              </div>
              <form
                onSubmit={handleSubmit}
                className="d-flex flex-row flex-wrap"
              >
                <div className="form-group mr-3 mt-2">
                  <div className="input-group ">
                    <label className="mr-2">From </label>
                    <input
                      type="date"
                      name="StartDate"
                      value={searchParams?.StartDate}
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group mt-2 mr-3">
                  <div className="input-group">
                    <label className="mr-2">To </label>
                    <input
                      type="date"
                      className="form-control"
                      name="EndDate"
                      value={searchParams?.EndDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={{ marginBottom: "7px" }}>
                  <button
                    type="submit"
                    className="btn advancly-btn btn-md"
                    value="submit"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="d-flex flex-row flex-wrap mb-3">
            <h3 className="d-flex align-items-center">
              <span className="page-subtitle">Available Balance: </span>
              <span className="d-flex align-items-center">
                {formatMoney(
                  companyWalletsTransactions?.data.data?.availableBalance,
                  companyWalletsTransactions?.data.data?.currency
                ) || "N/A"}
              </span>
            </h3>
          </div>
          <div className="d-flex flex-row flex-wrap mb-3">
            <h3 className="d-flex align-items-center">
              <span className="page-subtitle">Ledger Balance: </span>
              <span className="d-flex align-items-center">
                {formatMoney(
                  companyWalletsTransactions?.data.data?.ledgerBalance,
                  companyWalletsTransactions?.data.data?.currency
                ) || "N/A"}
              </span>
            </h3>
          </div>

          {RenderedComponent}
          <Paginator
            size={searchQuery.PageSize}
            page={searchQuery.Page}
            count={companyWalletsTransactions?.data.data.total}
            changeCurrentPage={(pageNumber: number) =>
              setSearchQuery((prev) => {
                return {
                  ...prev,
                  Page: pageNumber,
                };
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyWalletTransactions;
