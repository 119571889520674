// Redux Action CONSTANTS
import * as actionTypes  from '../actions/actionTypes';

// Initial Application-wide Ecosystem State
const initialState = {
    allAggregators: {
        aggregators: null,
        loading: false,
        error: null
    },
    currentAggregator: {
        aggregatorDetails: {},
        funderTableData: [],
        partnerTableData: [],
        loading: false,
        error: null
    }
};



export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.GET_AGGREGATORS_START:
            return {
                ...state,
                allAggregators: {
                    ...state.allAggregators,
                    loading: true,
                    error: null
                }
            }

        case actionTypes.GET_AGGREGATORS_SUCCESS:
            return {
                ...state,
                allAggregators: {
                    ...state.allAggregators,
                    aggregators: [...action.aggregators],
                    loading: false,
                    error: null
                }
            }
        
        case actionTypes.GET_AGGREGATORS_FAILURE:
            return {
                ...state,
                allAggregators: {
                    ...state.allAggregators,
                    loading: false,
                    error: action.error
                }
            }

        case actionTypes.GET_DRILLDOWN_START:
            return {
                ...state,
                currentAggregator: {
                    ...state.currentAggregator,
                    error: null,
                    loading: true
                }
            }

        case actionTypes.GET_DRILLDOWN_SUCCESS:
            return {
                ...state,
                currentAggregator: {
                    ...state.currentAggregator,
                    ...action.payload,
                    loading: false,
                    error: null
                }
        }

        case actionTypes.GET_DRILLDOWN_FAILURE:
            return {
                ...state,
                currentAggregator: {
                    ...state.currentAggregator,
                    loading: false,
                    error: action.error
                }
        }

        default:
            return state;
    }
}

export default reducer;