import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getLiquidationChannels = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.ALL_LIQUIDATION_CHANNELS
  );
  return data;
};
export default function useLiquidationChannels() {
  return useQuery(["getLiquidationChannels"], getLiquidationChannels, {
    refetchOnWindowFocus: false,
  });
}
