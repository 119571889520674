import { combineReducers } from "redux";

// Application Reducers
import aggregatorsReducer from "./aggregatorsReducer";
import auditTrailReducer from "./auditTrailReducer";
import authReducer from "./authReducer";
import partnersReducer from "./partnersReducer";
import faqReducer from "./faqReducer";
import fundersReducer from "./fundersReducer";
import investmentsReducer from "./investmentsReducer";
import issuesReducer from "./issuesReducer";
import loansReducer from "./loansReducer";
import modalReducer from "./modalReducer";
import navigationReducer from "./navigationReducer";
import notificationsReducer from "./notificationsReducer";
import onboardingReducer from "./onboardingReducer";
import settingsReducer from "./settingsReducer";
import systemReducer from "./systemReducer";
import toastReducer from "./toastReducer";
import transactionsReducer from "./transactionsReducer";
import walletReducer from "./walletReducer";
import statsReducer from "./statsReducer";
import documentViewerReducer from "./documentViewerReducer";

//Combined Reducers
const rootReducer = combineReducers({
  auditTrailReducer,
  authReducer,
  partnersReducer,
  aggregatorsReducer,
  faqReducer,
  fundersReducer,
  investmentsReducer,
  issuesReducer,
  loansReducer,
  modalReducer,
  navigationReducer,
  notificationsReducer,
  onboardingReducer,
  settingsReducer,
  systemReducer,
  toastReducer,
  transactionsReducer,
  walletReducer,
  statsReducer,
  documentViewerReducer,
});

export default rootReducer;
