export const resolveDuration = (
  duration: number,
  period_type: string
): string => {
  if (period_type?.toLowerCase() === "monthly") {
    return `${duration} ${duration > 1 ? "months" : "month"}`;
  } else if (period_type?.toLowerCase() === "daily") {
    return `${duration} ${duration > 1 ? "days" : "day"}`;
  } else if (period_type?.toLowerCase() === "yearly") {
    return `${duration} ${duration > 1 ? "years" : "year"}`;
  } else {
    return `${duration} ${period_type}`;
  }
};

export const resolveDurationName = (period_type: string): string => {
  if (period_type?.toLowerCase() === "monthly") {
    return "months";
  } else if (period_type?.toLowerCase() === "daily") {
    return "days";
  } else if (period_type?.toLowerCase() === "yearly") {
    return "years";
  } else {
    return period_type;
  }
};
