import React from "react";

import { useHistory } from "react-router-dom";
import { messagingUrl } from "./urlConstants";

import Styles from "./messaging.module.scss";

const Messaging = () => {
  const history = useHistory();

  return (
    <div className="animated fadeInRight">
      <h3 className={`${Styles.Header} page-title`}>Messaging</h3>

      <div className={Styles.ActionContainer}>
        <div
          className={Styles.SendMessage}
          onClick={() =>
            history.push(`/messaging/${messagingUrl.SEND_MESSAGE}`)
          }
        >
          <span className={Styles.SendMessageIcon} />

          <h1>Send a message</h1>
          <p>
            Connect with Advancly customers directly by sending push
            notifications or in-app messages across our different products and
            customer segments.
          </p>
        </div>
        <div
          className={Styles.PreviousMessage}
          onClick={() =>
            history.push(`/messaging/${messagingUrl.PREVIOUS_MESSAGES}`)
          }
        >
          <span className={Styles.PreviousMessageIcon} />

          <h1>Previous Messages</h1>
          <p>
            Keep track of every message! Click here to view messaging history
            with audit log information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Messaging;
