// Valid Emails Only
const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Input Not Empty
export const exists = (value: string) => {
  let trimmedValue = value.trim();
  return trimmedValue !== "";
};

export const isEmail = (value: string) => {
  return REGEX_EMAIL.test(value);
};
