import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { setToast } from "../../../../redux/actions/toast";
import { removeDisbursedLoans } from "../../../../redux/actions/loans";

// Ajax Methods
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
// Helper Functions
import { errorHandler } from "../../../../helpers/errorHandler";
// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../config/appInsights";

class RetryFailedRepayment extends Component {
  state = {
    disbursementTag: "",
  };

  inputChangeHandler = (e) => {
    this.setState({
      disbursementTag: e.target.value.trim(),
    });
  };

  // repay Loan
  repayLoan = async (e) => {
    e.preventDefault();

    document.querySelector("#disbursement-confirm").classList.add("hidden");
    document.querySelector("#disbursement-loader").classList.remove("hidden");
    try {
      await postDataWithDotNet(
        `${ajaxEndpoints.REPAY_FAILED_LOAN}${this.props.properties.dataRecord.repayment_id}`
      );
      document.querySelector("#disbursement-loader").classList.add("hidden");
      document
        .querySelector("#disbursement-success")
        .classList.remove("hidden");
      setTimeout(() => {
        this.props.setToast("success", "Repayment Successful.");
        this.props.onCloseModal();
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "RetryFailedRepayment.js",
        },
      });
      document.querySelector("#disbursement-loader").classList.add("hidden");
      document.querySelector("#disbursement-error").classList.remove("hidden");
      setTimeout(() => {
        this.props.setToast("warning", errorHandler(error));
        return this.props.onCloseModal();
      }, 3000);
    }
  };

  render() {
    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <React.Fragment>
            {/* <h3 className="Modal__title p-b-1">Retry Summary</h3> */}
            {/* 2. Confirm Update Role Modal*/}
            <div id="disbursement-confirm">
              <div className="m-b-30">
                <h5>
                  Are you sure you want to retry the following failed repayment?{" "}
                </h5>

                <div className="Form__action-buttons m-t-2">
                  <button
                    type="button"
                    onClick={this.repayLoan}
                    className="btn zoom-element btn login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Proceed
                  </button>
                  <button
                    type="button"
                    onClick={this.props.onCloseModal}
                    className="btn zoom-element btn login-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </div>
            </div>

            {/* 2. Disbursement Loader*/}
            <div
              id="disbursement-loader"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <div className=" form-loader form-loader-login verify">
                <div className="centerLoader">
                  <div className="loading" id="adv-loader-alt" />
                  <div className="center-txt webapp-ripple-text">
                    <i className="notifier fas fa-user-cog" />
                    Processing Repayment ...
                  </div>
                </div>
              </div>
            </div>

            {/* 3. Disbursement Success Message*/}
            <div
              id="disbursement-success"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <div className="form-loader form-loader-login verify">
                <div className="centerLoader">
                  <div className="loading success-loader">
                    <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                  </div>
                  <div className="center-txt webapp-ripple-text">
                    <i className="notifier far fa-check-circle" />
                    Repayment Successful
                  </div>
                </div>
              </div>
            </div>

            {/* 4. Disbursement Error */}
            <div
              id="disbursement-error"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <ErrorLoader
                icon="fas fa-wifi"
                text="Network Issue"
                subText="Unable to connect to Advancly services."
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    disbursements: { records },
  } = state.loansReducer;

  return {
    records,
  };
};

export default connect(mapStateToProps, { setToast, removeDisbursedLoans })(
  RetryFailedRepayment
);
