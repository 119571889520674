import React, { ChangeEvent, Fragment, memo, useEffect, useState } from "react";
import Alert from "../../../../NewComponents/Alert/Alert.component";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import style from "../RollOverModal/RollOverModal.module.scss";
import { formatMoney } from "../../../../helpers/formatter";
import moment from "moment";
import {
  getDataWithDotNet,
  postDataWithDotNet,
} from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { handleFundInvestmentModalToggle } from "../../Tabs/EachInvestorInvestment/helper";

export interface ITopUpInfo {
  amount: number | undefined;
  funder_investment_id: number | undefined;
  transaction_date: string;
  base64_file_string: string;
  filename_with_extension: string;
  topup_type: number;
  wallet_id: string;
}

interface ISelectedRecord {
  selectedRecord: {
    id: number;
    funder_id: number;
    currency: string;
  };
}

const defaultTopUpInfo = {
  funder_investment_id: undefined,
  amount: undefined,
  transaction_date: "",
  base64_file_string: "",
  filename_with_extension: "",
  topup_type: 1,
  wallet_id: "",
};

const FundInvestmentModal = ({ selectedRecord }: ISelectedRecord) => {
  const { id, currency, funder_id } = selectedRecord;

  const [loadingWallet, setLoadingWallet] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [topUpInfo, setTopUpInfo] = useState<ITopUpInfo>(defaultTopUpInfo);
  const [walletBalance, setWalletBalance] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  function handleChangeAmount(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setTopUpInfo({ ...topUpInfo, amount: Number(target.value) });
  }

  function handleChangeEffectiveDate(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setTopUpInfo({
      ...topUpInfo,
      transaction_date: moment(target.value).format("YYYY-MM-DDTHH:mm:ss"),
    });
  }
  const handleFetchWalletList = async () => {
    setSuccessMessage("");
    setErrorMessage("");
    setLoadingWallet(true);
    try {
      const data = await getDataWithDotNet(
        `${ajaxEndpoints.GET_CUSTOMER_WALLETS}?customerId=${funder_id}`
      );
      const { wallets } = data.data;
      const wallet = wallets.find(
        (f: { currency: string; isExternal: boolean }) =>
          f.currency === currency && f.isExternal
      );
      console.log({
        topUpInfo,
      });
      setTopUpInfo({
        ...defaultTopUpInfo,
        wallet_id: wallet?.walletId,
      });
      setWalletBalance(wallet?.availableBalance);
    } catch (error) {
      setErrorMessage("An error occurred");
      console.log(error);
    } finally {
      console.log({
        topUpInfoFinally: topUpInfo,
      });
      setLoadingWallet(false);
    }
  };

  useEffect(() => {
    console.log({
      selectedRecord,
    });
    if (selectedRecord?.currency) {
      handleFetchWalletList();
    }
  }, [selectedRecord]);

  const handleSubmitTopUp = async () => {
    const reqBody = { ...topUpInfo, funder_investment_id: id };
    setIsLoading(true);
    try {
      await postDataWithDotNet(ajaxEndpoints.ADMIN_INITIATE_TOP_UP, reqBody);
      setSuccessMessage("Investment top-up request is processing.");
      setTimeout(() => {
        handleFundInvestmentModalToggle();
      }, 2000);
    } catch (error) {
      setErrorMessage("An error occurred");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type={null}
          place={null}
          autoDismiss={null}
          icon={null}
        />
      )}
      {successMessage && (
        <Alert
          message={successMessage}
          place={null}
          autoDismiss={null}
          icon={null}
          type="success"
        />
      )}
      <div
        className="modal fade"
        id="fundInvestmentModal"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="fundInvestmentModalLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="page-subtitle">Amount to Fund</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={handleClose}
                // ref={dismissModalRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loadingWallet ? (
                <div>
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-spinner fa-spin" />
                  </div>
                  <p className="text-center">Loading Wallet Balance...</p>
                </div>
              ) : (
                <>
                  <div>
                    <CustomInputField
                      type="number"
                      name="amount"
                      label={`Enter Amount ${formatMoney(
                        topUpInfo.amount,
                        currency
                      )}`}
                      placeholder="e.g. 1000"
                      onChange={handleChangeAmount}
                      value={topUpInfo.amount || ""}
                    />
                  </div>
                  <div>
                    <CustomInputField
                      type="date"
                      label="Effective Date"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      name="transaction_date"
                      onChange={handleChangeEffectiveDate}
                    />
                  </div>
                  <p>
                    <span className="font-weight-bold mr-3">
                      Wallet Balance:
                    </span>
                    {loadingWallet ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      <span>{formatMoney(walletBalance || 0, currency)}</span>
                    )}
                  </p>
                  <div className={style.finalSection}>
                    <button
                      className="btn advancly-btn m-0 btn-block"
                      onClick={handleSubmitTopUp}
                      disabled={
                        loadingWallet ||
                        isLoading ||
                        !topUpInfo?.wallet_id ||
                        !topUpInfo?.transaction_date
                      }
                    >
                      {isLoading ? (
                        <span className="spinner-border spinner-border-sm text-white" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FundInvestmentModal;
