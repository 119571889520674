import React from "react";
import styles from "../CreditBureau.module.scss";

const CreditBureauCheckTablesClassificationInsType = ({
  classificationInsType,
}: any) => {
  return (
    <div>
      <h5 className={` ${styles.creditBureauHeading}`}>
        {" "}
        Classification Of Active Accounts By Institution Type
      </h5>
      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Institution Type
            </th>
            <th className="th-td-style th-style" scope="col">
              No Of Accounts
            </th>
            <th className="th-td-style th-style" scope="col">
              Currency
            </th>
            <th className="th-td-style th-style" scope="col">
              Approved Amount
            </th>
            <th className="th-td-style th-style" scope="col">
              Outstanding Balance
            </th>
            <th className="th-td-style th-style" scope="col">
              Amount OverDue
            </th>
          </tr>
        </thead>
        <tbody>
          {classificationInsType?.map((classInsType: any, index: number) => {
            const {
              amounT_OVERDUE,
              approveD_CREDIT_SANCTIONED,
              currency,
              institutioN_TYPE,
              nO_OF_ACCOUNTS,
              oustandinG_BALANCE,
            } = classInsType;
            return (
              <tr key={index}>
                <td className="th-td-style ">{institutioN_TYPE}</td>
                <td className="th-td-style ">{nO_OF_ACCOUNTS}</td>
                <td className="th-td-style ">{currency}</td>
                <td className="th-td-style ">{approveD_CREDIT_SANCTIONED}</td>
                <td className="th-td-style ">{oustandinG_BALANCE}</td>
                <td className="th-td-style ">{amounT_OVERDUE}</td>
                <td className="th-td-style ">{amounT_OVERDUE}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauCheckTablesClassificationInsType;
