import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoint from "../api/ajax-endpoints";

type currencyType = {
  currentCurrencyValue: string;
  aggregator_id: string;
};

const getTotalOutstandingLoans = async ({
  currentCurrencyValue,
  aggregator_id,
}: currencyType) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoint.TOTAL_OUSTANDING_LOANS}?currency=${currentCurrencyValue}&aggregator_id=${aggregator_id}`,
  );
  return data.data;
};

const useTotalOutstandingLoans = ({
  currentCurrencyValue,
  aggregator_id,
}: currencyType) => {
  return useQuery(
    [{ currentCurrencyValue, aggregator_id }, "getTotalOutstanding"],
    getTotalOutstandingLoans,
    {
      enabled: currentCurrencyValue && aggregator_id ? true : false,
      retry: 4,
    },
  );
};

export default useTotalOutstandingLoans;
