import React, { useState } from "react";
import { Dropdown } from "antd";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { ItemType } from "antd/es/menu/interface";
import { postBlobData } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { showModal } from "../../../redux/actions/modal";
import moment from "moment";
import { appInsights } from "../../../config/appInsights";
import useDocumentViewer from "../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/icons/download_underline.svg";
import styles from "./MccApprovals.module.scss";
import usePendingLoanApplicationsById from "../../../custom-hooks/usePendingLoanApplicationById";
import { RootState } from "../../../redux/store";
import Alert from "../../../NewComponents/Alert/Alert.component";
const MccApprovalsFinalPage = () => {
  const [errorMccApprovals, setErrorsMccApprovals] = useState<string>("");

  const [isDownloadingCAM, setIsDownloadingCAM] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
  };
  const { data: application, refetch } = usePendingLoanApplicationsById(
    state?.id
  );
  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );
  // checking for existing signatories on current loan approval
  const mccApprovalsSignatories: any =
    application?.data?.data?.cam_for_mcc_approval?.signatories;

  const hasSignedLoan =
    mccApprovalsSignatories?.length > 0
      ? mccApprovalsSignatories?.every(
          ({ user_id }: { user_id: number }) =>
            loggedInUser?.user_id === user_id
        )
      : false;

  const { handleViewDocument } = useDocumentViewer();
  const handleApproveDeclineLoanApplication = (
    action: "approvemccapproval" | "declinemccapproval"
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "approvedeclinemccapproval",
        dataRecord: {
          refetch,
          action,
          loanApplicationId: state?.id,
          camId: application?.data?.data?.cam_for_mcc_approval?.cam_id,
        },
      })
    );
  };
  const handleDownloadCAM = async (documentFormat: string) => {
    setIsDownloadingCAM(true);
    const payload = {
      loan_application_id: state?.id,
      document_file_path:
        documentFormat === "pdf"
          ? application?.data?.data?.cam_for_mcc_approval?.cam_filepath_pdf
          : application?.data?.data?.cam_for_mcc_approval?.cam_filepath_docx,
    };
    try {
      const response = await postBlobData(ajaxEndpoints.DOWNLOAD_CAM, payload);

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `CAM_${state?.id}_${moment().format("l").replaceAll("/", "_")}.${
            documentFormat === "pdf"
              ? application?.data?.data?.cam_for_mcc_approval?.cam_filepath_pdf
              : application?.data?.data?.cam_for_mcc_approval?.cam_filepath_docx
          }`
        );
        link.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message:
                response?.data?.message ?? "Unable to download CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "MccApprovalsFinalPage.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message:
              error?.response?.data?.message ??
              "Unable to download CAM document",
          },
        })
      );
    }
    setIsDownloadingCAM(false);
  };

  const handleViewCAM = async () => {
    setIsDownloadingCAM(true);

    try {
      //   const response = await getData(
      //     `/account/loan_applications/${state?.id}/view_cam`
      //   );
      if (application?.data?.data?.cam_for_mcc_approval?.cam_filepath_pdf) {
        handleViewDocument(
          false,
          application?.data?.data?.cam_for_mcc_approval?.cam_filepath_pdf
        );
      } else {
        setErrorsMccApprovals("Unable to retrieve CAM document");
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreditAnalysis.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? "Server Error",
          },
        })
      );
    }
    setIsDownloadingCAM(false);
  };

  const camFormatItems: ItemType[] = [
    {
      key: "pdf",
      label: "PDF",
    },
    {
      key: "docx",
      label: "WORD",
    },
    {
      key: "view",
      label: "VIEW",
    },
  ];

  const actionItems: ItemType[] = [
    {
      key: "approve",
      label: <span className={styles.approve}>Approve</span>,
    },
    {
      key: "decline",
      label: <span className={styles.decline}>Decline</span>,
    },
  ];

  return (
    <div>
      {errorMccApprovals && <Alert message={errorMccApprovals} />}

      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> MCC Approval
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          <Dropdown
            trigger={["click"]}
            menu={{
              items: camFormatItems,
              onClick: ({ key }) => {
                if (key === "pdf") {
                  handleDownloadCAM("pdf");
                }
                if (key === "docx") {
                  handleDownloadCAM("docx");
                }
                if (key === "view") {
                  handleViewCAM();
                }
              },
            }}
          >
            <button className="btn advancly-btn btn-md mr-3">
              {isDownloadingCAM ? <ButtonLoader /> : "View CAM"}
              <DownloadIcon className={styles.DownloadIcon} />
            </button>
          </Dropdown>

          <Dropdown
            trigger={["click"]}
            menu={{
              items: actionItems,
              onClick: ({ key }) => {
                if (key === "approve") {
                  handleApproveDeclineLoanApplication("approvemccapproval");
                }

                if (key === "decline") {
                  handleApproveDeclineLoanApplication("declinemccapproval");
                }
              },
            }}
          >
            <button
              className="btn advancly-white-btn mr-2"
              disabled={hasSignedLoan}
            >
              Take Action
            </button>
          </Dropdown>
        </div>
      </div>

      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Name</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Email</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Reference:</div>
          <div className={styles.content}>
            {application?.data?.data?.ref_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Date</div>
          <div className={styles.content}>
            {application?.data?.data?.application_date.split("T")[0]}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Status</div>
          <div className={styles.content}>
            {application?.data?.data?.status}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Relationship Manager Name</div>
          <div className={styles.content}>
            {application?.data?.data?.relationship_manager.trim()
              ? application?.data?.data?.relationship_manager.trim()
              : "NIL"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MccApprovalsFinalPage;
