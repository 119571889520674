import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  FromDate: string;
  ToDate: string;
  page: number;
  size: number;
};

const getRepaymentReport = async ({ FromDate, ToDate, page, size }: TQuery) => {
  let queryParams = `page=${page}&PageSize=${size}`;
  if (FromDate) {
    queryParams = `${queryParams}&FromDate=${FromDate}`;
  }
  if (ToDate) {
    queryParams = `${queryParams}&ToDate=${ToDate}`;
  }
  const data = await getData(
    `${ajaxEndpoints.REPAYMENT_REPORTS}?${queryParams}`
  );
  return data;
};

export default function useRepaymentReport({
  FromDate,
  ToDate,
  page,
  size,
}: TQuery) {
  return useQuery(
    [{ FromDate, ToDate, page, size }, "getRepaymentReport"],
    getRepaymentReport
  );
}
