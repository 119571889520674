import React from "react";
import ModalDetailsRow from "../../../components/ModalDetailsRow/ModalDetailsRow.component";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";

const ManageProductModal = ({ properties: { dataRecord }, onCloseModal }) => {
  const {
    product_name,
    productcategory,
    maximum_amount,
    aggregator,
    bank_account_name,
    account_number,
    bank,
    interest_rate,
    maximum_tenor,
    repayment_type,
    repayertype,
    repaymentchannel,
    disbursementchannel,
    disbursementtype,
    reference,
    loan_type,
    core_banking_product_name,
    allow_batch_disbursement,
    maximum_running_loan,
    repay_principal_at_end_of_tenure,
    currency,
    require_aggregator_approval,
    require_deal_slip,
    amortization_type,
    require_bvn_verification,
    notification_type,
  } = dataRecord;

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h3 className="Modal__title p-b-2 text-center">
            Product Information
          </h3>
          {/* AuditTrail Modal */}
          <form>
            <div className="row">
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Product Name"
                  value={product_name}
                  icon="fas fa-user-circle"
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Product Category"
                  value={productcategory}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Currency" value={currency} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Amount" value={maximum_amount} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Aggregator" value={aggregator} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Bank " value={bank_account_name} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Account Number"
                  value={account_number}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Bank" value={bank} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Interest (%)" value={interest_rate} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Reference Number" value={reference} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Loan Tenure" value={maximum_tenor} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Repayment Type"
                  value={
                    repayment_type ? capitalizeFirstLetter(repayment_type) : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Loan Type" value={loan_type} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow label="Repayer Type" value={repayertype} />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Repayment Channel"
                  value={repaymentchannel}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Disbursement Type"
                  value={disbursementtype}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="CB Product Name"
                  value={core_banking_product_name}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Disbursement Channel"
                  value={disbursementchannel}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Batch Disbursement"
                  value={allow_batch_disbursement}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Max Runnng Loans"
                  value={maximum_running_loan}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="End Of Tenor Principal"
                  value={repay_principal_at_end_of_tenure}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Require Aggregator Approval"
                  value={require_aggregator_approval}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Amortization Type"
                  value={amortization_type}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Allow Sending of Deal Slip"
                  value={require_deal_slip}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Require BVN Verification"
                  value={require_bvn_verification}
                />
              </div>
              <div className="col-md-6">
                <ModalDetailsRow
                  label="Product Notification Type"
                  value={notification_type}
                />
              </div>
            </div>
            <div className="Form__action-buttons m-t-1">
              <button
                type="button"
                className="btn zoom-element btn login-btn m-0 ml-auto"
                onClick={onCloseModal}
                data-dismiss="modal"
              >
                <i className="fas fa-times-circle"></i>
                &nbsp; Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ManageProductModal;
