import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
interface IRepaymentSchedule {
  repay_schedule_id: number;
  pay_date: string;
  repay_amount: number;
}

interface IRepaidSchedule {
  id: number;
  repaymentDate: string;
  repaymentAmount: number;
  pub_date: string;
  modified_date: string;
  repayment_status_id: number;
  corebanking_status_id: number;
  repayment_channel_status_id: number;
  repayment_channel_id: number;
  xlenda_id: number;
  aggregator_id: number;
  beneficiary_account_name: string;
  beneficiary_account_number: string;
  beneficiary_bank_code: string;
  debit_account_name: string;
  debit_account_number: string;
  debit_bank_code: string;
  repayment_gateway_final: boolean;
  repayment_gateway_reference_no: string;
  repayment_gateway_request: string;
  repayment_gateway_response: string;
  repayment_gateway_retry_status: boolean;
  repayment_gateway_status_id: string;
  repayment_reference_no: string;
  repayment_request_date_time: string;
  repayment_response_date_time: string;
}

interface IBorrowerDetails {
  first_name: string;
  request_firstname: string;
  last_name: string;
  request_lastname: string;
  gender: string;
  request_gender: string;
  date_of_birth: string;
  request_dob: string;
  personal_phone: string;
  request_phone: string;
  kyc_score: number;
  debt_balance: number;
  available_balance: number;
  borrower_id: number;
  customer_id: number;
  customer_category: string;
  email: string;
  borrower_type: string;
  borrower_type_value: string;
  my_biz_name: string;
  my_biz_rc_num: string;
  total_loan_collected: number;
  total_loan_repaid: number;
}

interface ILoanData {
  data: {
    loan_id: number;
    corebanking_tenant_id: string;
    maker_checker_reference: string;
    decline_reason: string;
    loan_interest_amount: number;
    loan_interest_rate: number;
    loan_outstanding: number;
    loan_due_date: string;
    loan_status: string;
    loan_status_code: number;
    total_loan_collected: number;
    total_loan_repaid: number;
    is_active: boolean;
    loan_tenure: number;
    loan_amount: number;
    currency: string;
    loan_amount_repay: number;
    settlement_day: string;
    has_disburse: boolean;
    loan_repayment_balance: number;
    loan_ref: string;
    aggregator_loan_ref: string;
    parent_rollover_loan_reference: string;
    rollover_loan_reference: string;
    is_rollover: boolean;
    can_rollover: boolean;
    has_repaid: boolean;
    loan_transfer_status: string;
    disbursement_tag: string;
    loan_effective_date: string;
    loan_effective_date2: string;
    first_repayment_date: string;
    pub_date: string;
    modified_date: string;
    repayment_type: string;
    status: string;
    crc_status: string;
    crc_score: number;
    crc_message: string;
    xds_status: string;
    xds_score: number;
    xds_message: string;
    sf_status: string;
    sf_score: number;
    prediction: string;
    repay_principal_at_end_of_tenure: boolean;
    repayment_schedule: IRepaymentSchedule[];
    repaid_schedule: IRepaidSchedule[];
    borrower_details: IBorrowerDetails;
    product_name: string;
    funder_name: string;
    rollover_maker_checker_reference: string;
    preferred_Loan_tenure: number;
  }[];
}

export const getusePendingRolloverRequest = async () => {
  const { data } = await getData(`${ajaxEndpoints.PENDING_LOAN_ROLLOVER}`);
  return data;
};

export default function usePendingRolloverRequest() {
  return useQuery<ILoanData>(
    "getusePendingRolloverRequest",
    getusePendingRolloverRequest,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
}
