import { ROLE_TYPES, auditRoleList, isAudit } from "./roleTypes";

export const decideLandingAfterLogin = (loggedInUser) => {
  if (loggedInUser) {
    if (loggedInUser?.firstTimeLogin) {
      return "/change-password";
    } else {
      if (!loggedInUser?.roles || !loggedInUser?.roles.length) return "/";

      if (
        loggedInUser?.roles.includes(ROLE_TYPES.DISBURSEMENT_APPROVER) ||
        loggedInUser?.roles.includes(ROLE_TYPES.REPAYMENT_APPROVER)
      ) {
        return "/approve-loans";
      } else if (loggedInUser?.roles.includes(ROLE_TYPES.GENERAL_DASHBOARD)) {
        return "/main-dashboard";
      } else if (
        isAudit([...loggedInUser?.roles], [...auditRoleList]) ||
        loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_VIEWER)
      ) {
        return "/manage-loans";
      } else if (
        loggedInUser?.roles.includes(ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER)
      ) {
        return "/aggregator-onboarding";
      }

      return "/main-dashboard";
    }
  }

  return "";
};
