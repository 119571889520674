import {SHOW_NAVIGATION, HIDE_NAVIGATION} from '../actions/actionTypes';

let initialState = {
    showNav: false
};

const reducer = (state = initialState, action)=>{
    switch(action.type){
        case SHOW_NAVIGATION:
            return {
                ...state,
                showNav: action.visibility
            };

        case HIDE_NAVIGATION:
            return {
                ...state,
                showNav: action.visibility
            };

        default:
            return state;
    }
};

export default reducer;