import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../helpers/formatter";

export interface IWithdrawalData {
  currency_code: string;
  narration: string | null;
  reference: string;
  user_id: number;
  investment_product_name: string;
  deposit_type: string;
  investment_reference_number: string;
  internal_transaction_reference: string | null;
  funder_id: number;
  funder_type: number;
  firstname: string;
  lastname: string;
  email: string;
  phone_number: string;
  biz_name: string;
  biz_email: string;
  funder_investment_id: number;
  amount: number;
  transaction_date: string;
}

interface RequestsDataTableProps {
  data: IWithdrawalData[];
  showModal: any;
  hideModal: any;
  initiator: "investor" | "admin";
}

const WithdrawalRequestsDataTable: FunctionComponent<
  RequestsDataTableProps
> = ({ data, showModal, hideModal, initiator }) => {
  return (
    <div className="row m-x-sm m-y-2 grid__padding">
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>S/N</th>
              <th>Investment ID</th>
              <th>Amount</th>
              <th>Deposit Type</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Currency</th>
              <th>Phone Number</th>
              <th>Reference</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.funder_investment_id}>
                <td>{index + 1}</td>
                <td>{item.funder_investment_id}</td>
                <td>{formatMoney(item.amount, item.currency_code)}</td>
                <td>{item.deposit_type}</td>
                <td>{item.firstname}</td>
                <td>{item.lastname}</td>
                <td>{item?.currency_code}</td>
                <td>{item.phone_number}</td>
                <td>{item.reference}</td>
                <td>
                  <button
                    className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                    type="button"
                    data-toggle="modal"
                    data-target="#requestModal"
                    onClick={() => {
                      showModal({
                        modalIdentifier: "requests",
                        action: "admin-withdrawal-request",
                        item: { ...item, initiator },
                      });
                    }}
                  >
                    Request
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WithdrawalRequestsDataTable;
