import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

export const getRolesByCategoryId = async (categoryId: number | null) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.ROLES_BY_CATEGORY}/${categoryId}/roles`
  );
  return data;
};

export default function useAllRoleByCategories(categoryId: number | null) {
  return useQuery([categoryId, "allRolesByCategory"], getRolesByCategoryId, {
    refetchOnWindowFocus: false,
    enabled: categoryId ? true : false,
  });
}
