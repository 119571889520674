import { Fragment, useState } from "react";
import ButtonLoader from "../../../../../../../UI/Loaders/Loaders";
import useLiquidationAccounts from "../../../../../../../custom-hooks/useLiquidationAccounts";
import useLiquidationChannels from "../../../../../../../custom-hooks/useLiquidationChannels";
import useCountryCodes from "../../../../../../../custom-hooks/useCountryCodes";
import { postDataWithDotNet } from "../../../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../../../../helpers/errorHandler";
import Alert from "../../../../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../../../../../../config/appInsights";

const CountryLiquidationForm = () => {
  const [lcyLiquidationId, setLcyLiquidationId] = useState("");
  const { data: countryData } = useCountryCodes();
  const { data: usdliquidationAccount } =
    useLiquidationAccounts(lcyLiquidationId);
  const [state, setState] = useState({
    country_id: "",
    liquidation_channel_id: "",
    liquidation_channel_account_id: "",
    loading: false,
    error: "",
    success: "",
  });
  const { data: usdLiquidationChannel } = useLiquidationChannels();
  const handleOptionSelect = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      error: "",
      success: "",
    });
  };
  const create = async (e) => {
    e.preventDefault();
    setState({ ...state, loading: true, success: "", error: "" });
    const reqBody = {
      country_id: Number(state.country_id),
      liquidation_channel_id: Number(state.liquidation_channel_id),
      liquidation_channel_account_id: Number(
        state.liquidation_channel_account_id
      ),
    };
    try {
      const data = await postDataWithDotNet(
        ajaxEndpoints.COUNTRY_LIQUIDATION,
        reqBody
      );
      setState({
        ...state,
        loading: false,
        success: data.message,
        error: "",
        country_id: "",
        liquidation_channel_id: "",
        liquidation_channel_account_id: "",
      });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CountryLiquidationForm.jsx",
        },
      });
      setState({
        ...state,
        loading: false,
        success: "",
        error: errorHandler(error),
      });
    }
  };
  return (
    <Fragment>
      {state.success && <Alert message={state.success} type="success" />}
      {state.error && <Alert message={state.error} />}

      <div className="card">
        <div className="body text-center">
          <h3 className="m-t-0 m-b-3">
            <i className="adv-icon fas fa-cogs" />
            Liquidation mapping to countries
          </h3>

          <label className="label-txt"> Select a Country </label>
          <div className="form-group">
            <select
              data-form="liquidation"
              className="round user__form form-control"
              value={state.country_id}
              placeholder="Select a channel"
              name="country_id"
              onChange={(e) => handleOptionSelect(e)}
            >
              <option value=""> Select a Country</option>
              {countryData &&
                countryData.map((data) => {
                  const { id, name } = data;
                  return (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </div>

          <label className="label-txt">Liquidation Channel</label>
          <div className="form-group">
            <select
              onChange={(e) => {
                setLcyLiquidationId(e.target.value);
                handleOptionSelect(e);
              }}
              name="liquidation_channel_id"
              value={state.liquidation_channel_id}
              data-form="revenue"
              className="round user__form form-control"
            >
              <option value=""> Select a liquidation channel</option>
              {usdLiquidationChannel &&
                usdLiquidationChannel.map((data) => {
                  const { id, name } = data;
                  return (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </div>
          <label className="label-txt">Liquidation Channel Account</label>
          <div className="form-group">
            <select
              onChange={(e) => {
                handleOptionSelect(e);
              }}
              name="liquidation_channel_account_id"
              value={state.liquidation_channel_account_id}
              data-form="revenue"
              className="form-control mb-2"
            >
              <option value=""> Select a liquidation account</option>
              {usdliquidationAccount &&
                usdliquidationAccount.map((data) => {
                  const { id, account_name, account_number, bank_name } = data;
                  return (
                    <option value={id} key={id}>
                      {`${account_name} / ${account_number}/ ${bank_name}`}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="grid__full ">
            <button
              onClick={(e) => create(e)}
              type="button"
              data-form="repay"
              className="btn zoom-element advancly-btn "
              disabled={
                !state.country_id ||
                !state.liquidation_channel_id ||
                !state.liquidation_channel_account_id ||
                state.loading
              }
            >
              Create
              {state.loading && <ButtonLoader />}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CountryLiquidationForm;
