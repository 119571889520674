import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../UI/Loaders/Loaders";
import { showModal } from "../../../redux/actions/modal";
import { appInsights } from "../../../config/appInsights";
import { RootState } from "../../../redux/store";
import useFundingMakerCheckerRequests from "../../../custom-hooks/useFundingMakerCheckerRequests";
import { formatMoney } from "../../../helpers/formatter";

const FundingRequestApproval = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "FundingRequestApproval",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: pendingFundingRequests,
    status,
    error,
  } = useFundingMakerCheckerRequests();

  let RenderedComponent = null;
  if (!pendingFundingRequests && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Funding
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Pending Funding Requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              pending funding requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (pendingFundingRequests &&
        pendingFundingRequests.data &&
        pendingFundingRequests.data.length === 0) ||
      (pendingFundingRequests && !pendingFundingRequests.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              funding requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Reference</th>
                <th>Aggregator </th>
                <th>Amount</th>
                <th>Duration Type</th>
                <th>Duration</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {pendingFundingRequests &&
                pendingFundingRequests.data.map((data, _id: number) => {
                  const {
                    amount,
                    makerCheckRefNo,
                    tenor,
                    tenorType,
                    bizName,
                    createDateTime,
                    aggregatorId,
                    currency,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      {/* <td>
                        {" "}
                        {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                          (_id + 1)}
                      </td> */}
                      <td>{makerCheckRefNo}</td>
                      <td>{bizName}</td>
                      <td>{formatMoney(amount)}</td>
                      <td>{tenorType}</td>
                      <td>{tenor}</td>
                      <td>{createDateTime.split("T")[0]}</td>
                      <td>
                        <span
                          className="color-mid-blue cursor-pointer font-weight-bold"
                          onClick={() => {
                            dispatch(
                              showModal({
                                modalIdentifier: "approvedeclinefundingrequest",
                                dataRecord: {
                                  makerCheckRefNo,
                                  aggregatorId,
                                  currency,
                                  tenorType,
                                  tenor,
                                  amount,
                                },
                              })
                            );
                          }}
                        >
                          {" "}
                          View
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">Approvals</h3>
          </div>
          <h4 className="mb-4">Pending funding requests</h4>
          <div className="d-flex flex-row flex-wrap"></div>

          <div>{RenderedComponent}</div>

          {/* <Paginator
            size={preState.size}
            page={preState.pageNumber}
            count={count}
            changeCurrentPage={(pageNumber: number) =>
              setPreState({ ...state, pageNumber })
            }
          /> */}
        </div>
      </div>
    </div>
  );
};

export default FundingRequestApproval;
