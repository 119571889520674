import React, { FunctionComponent } from "react";
import { formatMoney } from "../../../helpers/formatter";

interface ICustomerBankDetails {
  currency: string;
  account_name: string;
  account_number: string;
  bank_name: string;
  bank_code: string;
  routing_number: string;
  swift_code: string;
}
export interface IWalletWithdrawalData {
  wallet_id: number;
  funder_name: string;
  amount: number;
  currencyCode: string;
  narration: string;
  reference: string;
  requestDate: string;
  internal_payment_ref: string;
  destination_account_number: string;
  destination_account_name: string;
  user_id: number;
  funder_id: number;
  funderType: number;
  firstname: string;
  lastname: string;
  email: string;
  phone_number: string;
  biz_name: string;
  biz_email: string;
  liquidation_channel_id: number;
  liquidation_channel_name: string;
  liquidation_channel_account_id: number;
  liquidation_channel_account_name: string;
  liquidation_channel_account_number: string;
  liquidation_channel_bank_code: string;
  withdrawal_type: string;
  bank_transfer_request: null; // Use the specific type if known
  withdrawal_request_type: number;
  consumerType: string;
  walletProviderId: number;
  accountNumber: string;
  transaction_date: string;
  customer_bank_details: ICustomerBankDetails;
}

interface RequestsDataTableProps {
  data: IWalletWithdrawalData[];
  showModal: any;
  hideModal: any;
  initiator: "investor" | "admin";
}

const WalletWithdrawalDataTable: FunctionComponent<RequestsDataTableProps> = ({
  data,
  showModal,
  hideModal,
  initiator,
}) => {
  return (
    <div className="row m-x-sm m-y-2 grid__padding">
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>S/N</th>
              <th>Wallet ID</th>
              <th>Amount</th>
              <th>Withdrawal Type</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Currency</th>
              <th>Phone Number</th>
              <th>Reference</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.wallet_id}>
                <td>{index + 1}</td>
                <td>{item.wallet_id}</td>
                <td>{formatMoney(item.amount, item.currencyCode)}</td>
                <td>{item.withdrawal_type}</td>
                <td>{item.firstname}</td>
                <td>{item.lastname}</td>
                <td>{item?.currencyCode}</td>
                <td>{item.phone_number}</td>
                <td>{item.reference}</td>
                <td>
                  <button
                    className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                    type="button"
                    data-toggle="modal"
                    data-target="#requestModal"
                    onClick={() => {
                      showModal({
                        modalIdentifier: "requests",
                        action: "admin-wallet-withdrawal",
                        item: { ...item, initiator },
                      });
                    }}
                  >
                    Request
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WalletWithdrawalDataTable;
