import React, { useState, ChangeEvent } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import useMobileVersionSettings from "../../../../../../custom-hooks/useMobileVersionSettings";
import { showModal } from "../../../../../../redux/actions/modal";
import { postData } from "../../../../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../../../../config/appInsights";

import Styles from "./mobileVersion.module.scss";

type MobileVersionPayload = {
  enforceMobileUpdate: string;
  mobileVersion: string;
  optionalMobileUpdateDaysNotification?: number;
};

const schema = yup.object().shape({
  enforceMobileUpdate: yup.string().required("Please select an update type"),
  mobileVersion: yup.string().required("Please enter mobile version"),
  // optionalMobileUpdateDaysNotification: yup
  //   .string()
  //   .required("Please enter frequency"),
});

const MobileVersion = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState<MobileVersionPayload>({
    enforceMobileUpdate: "",
    mobileVersion: "",
    optionalMobileUpdateDaysNotification: 0,
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const { data: mobileVersionSettings, refetch } = useMobileVersionSettings();

  const onSubmit = async (formData: MobileVersionPayload) => {
    let payload = {
      enforceMobileUpdate:
        formData.enforceMobileUpdate === "true" ? true : false,
      mobileVersion: formData.mobileVersion,
      optionalMobileUpdateDaysNotification: Number(
        formData.optionalMobileUpdateDaysNotification
      ),
      // optionalMobileUpdateDaysNotification:
      //   formData.enforceMobileUpdate === "true"
      //     ? 0
      //     : Number(formData.optionalMobileUpdateDaysNotification),
    };

    setIsSubmitting(true);
    try {
      const response = await postData(
        ajaxEndpoints.SET_MOBILE_VERSION_SETTINGS,
        payload
      );
      if (response?.data?.status === true) {
        refetch();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
      } else {
        dispatch(
          showModal({
            modalIndentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "mobileVersion.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Server Error",
          },
        })
      );
    }
    setIsSubmitting(false);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <div className={Styles.VersionContainer}>
      <div className="animated fadeInRight">
        <div className={`${Styles.FormContainer} mt-30`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={Styles.Form}>
              <div className={Styles.FormHeader}>
                <h3>Add New Version Number</h3>
                <span
                  className={Styles.FormCancelIcon}
                  onClick={() => history.goBack()}
                />
              </div>

              <p className={Styles.Instructions}>
                Enter the new version number of the latest update
              </p>

              <div className="row m-t-1">
                <div className="col-sm-12">
                  <div className={Styles.FormLabel}>
                    <label>Version Number</label>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className={Styles.Input}
                      name="mobileVersion"
                      defaultValue={mobileVersionSettings?.data?.mobileVersion}
                      onChange={handleChange}
                      ref={register}
                    />
                    <span className={Styles.errorMessage}>
                      {errors?.title?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row m-t-1">
                <div className="col-sm-12">
                  <label className={Styles.FormLabel}>Forced Update</label>
                  <div className="form-group">
                    <select
                      className={Styles.Input}
                      name="enforceMobileUpdate"
                      defaultValue={JSON.stringify(
                        mobileVersionSettings?.data?.enforceMobileUpdate
                      )}
                      ref={register}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected hidden>
                        Select Option
                      </option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </select>
                    <span className={Styles.errorMessage}>
                      {errors?.userCategory?.message}
                    </span>
                  </div>
                </div>
              </div>

              {/* {data?.enforceMobileUpdate === "false" && (
                <div className="row m-t-1">
                  <div className="col-sm-12">
                    <label className={Styles.FormLabel}>Frequency (days)</label>
                    <div className="form-group">
                      <input
                        type="number"
                        className={Styles.Input}
                        placeholder="e.g. 10"
                        name="optionalMobileUpdateDaysNotification"
                        defaultValue={
                          mobileVersionSettings?.data
                            ?.optionalMobileUpdateDaysNotification
                        }
                        maxLength={10}
                        onChange={handleChange}
                        ref={register}
                      />
                      <span className={Styles.errorMessage}>
                        {errors?.title?.message}
                      </span>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="row m-t-1">
                <div className="col-sm-12">
                  <label className={Styles.FormLabel}>Frequency (days)</label>
                  <div className="form-group">
                    <input
                      type="number"
                      className={Styles.Input}
                      placeholder="e.g. 10"
                      name="optionalMobileUpdateDaysNotification"
                      defaultValue={
                        mobileVersionSettings?.data
                          ?.optionalMobileUpdateDaysNotification
                      }
                      maxLength={10}
                      onChange={handleChange}
                      ref={register}
                    />
                    <span className={Styles.errorMessage}>
                      {errors?.title?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className={`m-t-1 ${Styles.FormButtonContainer}`}>
                <button type="submit" className="btn advancly-btn btn-md m-0">
                  {isSubmitting ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    <React.Fragment>Submit</React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MobileVersion;
