import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

export type TFundingWalletTransactions = {
  currency: string;
  accountNumber: string;
  walletId: string;
  accountName: string;
  bankName: string;
  availableBalance: number;
  ledgerBalance: number;
  transactions: {
    transactionDate: string;
    accountNumber: string;
    walletId: string;
    transactionType: string;
    bishopGateWalletTransactionTypeObject: string;
    transactionStatus: string;
    transactionCharge: number;
    transactionRemarks: string;
    transactionId: string;
    amount: number;
    debitAmount: number;
    creditAmount: number;
    recipientAccountNumber: string;
    recipientBankName: string;
    recipientBankCode: string;
    recipientAccountName: string;
    senderAccountNumber: string;
    senderBankName: string;
    senderAccountName: string;
    senderBankCode: string;
    dateCreated: string;
    description: string;
    initiatorUsername: string;
    transactionCategory: string;
    note: string;
    runningBalance: number;
  }[];
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
};

type TQuery = {
  accountNumber: string;
};

const getFundingWalletTransactions = async ({ accountNumber }: TQuery) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_FUNDING_WALLET_TRANSACTIONS}?AccountNumber=${accountNumber}`
  );

  return data;
};

export default function useFundingWalletTransactions({
  accountNumber,
}: TQuery) {
  return useQuery<IResponse<TFundingWalletTransactions>>(
    [{ accountNumber }, "getFundingWalletTransactions"],
    getFundingWalletTransactions,
    {
      refetchOnWindowFocus: false,
    }
  );
}
