import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import fuseWhiteLogo from "../../assets/svg/icons/fuse_white_logo.svg";

// Redux
import { useSelector } from "react-redux";

// Custom UI Components
import Hamburger from "../../UI/Hamburger/Hamburger";

// Styles
import "./Header.css";

const Header = ({ onToggleNavigation }) => {
  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);
  const isAuthenticated = useSelector(
    (state) => state?.authReducer?.isAuthenticated
  );
  const stickyHeader = () => {
    const targetTop = document.querySelector(".adv-sticky");
    const targetHeader = document.querySelector(".tfx-header");

    if (targetTop !== null || targetTop !== undefined) {
      let temp = targetTop.getBoundingClientRect().top,
        distance = Math.abs(temp);

      if (temp !== null) {
        const deviceWidth = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        );
        const baseWidth = 769;
        const baseHeight = 150;
        // For PC
        if (deviceWidth > baseWidth) {
          if (distance === 0) {
            targetHeader.classList.remove(
              "navigation--sticky",
              "navigation--pin"
            );
          } else if (distance > baseHeight) {
            targetHeader.classList.add("navigation--sticky", "navigation--pin");
          }
        }
        // For Mobile
        else {
          targetHeader.classList.add(
            "navigation--sticky",
            "navigation--pin",
            "white-bg"
          );
        }
      } else {
      }
    } else {
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickyHeader, true);
  }, []);

  return (
    <div id="AppHeader">
      <div className="adv-sticky" />
      <header className="Header tfx-header animated fadeInDown">
        <div className="navbar__hamburger normal-2x fadeInDown">
          {isAuthenticated && !loggedInUser?.firstTimeLogin && (
            <Hamburger onClick={onToggleNavigation} />
          )}
          <div className="fuse_white_logo ">
            <Link className="" to="/">
              <img src={fuseWhiteLogo} alt="fuse white logo" />
            </Link>
          </div>
        </div>

        <div className="navbar__account normal-1x fadeInDown">
          {isAuthenticated && (
            <React.Fragment>
              <div className="dropdown">
                <div
                  className="navbar__user  cursor-pointer "
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    &nbsp;&nbsp;
                    {loggedInUser
                      ? `Welcome ${loggedInUser?.first_name}`
                      : "Welcome User"}
                  </span>
                  <i
                    className="fa fa-caret-down user_class "
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div className="loggedIn-user">
                    <span className="loggedIn-user-name">
                      {loggedInUser?.first_name} {loggedInUser?.last_name}
                    </span>

                    <hr></hr>
                  </div>

                  <Link className="dropdown-item" to="/enable-2fa">
                    Enable 2FA
                  </Link>

                  {loggedInUser && (
                    <Fragment>
                      <Link className="dropdown-item" to="/change-password">
                        Change Password
                      </Link>
                    </Fragment>
                  )}
                  <div className="dropdown-item">
                    <Link to="/logout">Logout</Link>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
