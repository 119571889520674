import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";

import { getData } from "../newApis/dotNetApiMethods";

type TFunderKycParams = {
  funder_id: string;
};

const getFunderKycDocuments = async (queryParams: TFunderKycParams) => {
  const response = await getData(
    `${ajaxEndpoints.FUNDER_KYC_DOCUMENTS}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );
  return response?.data;
};

export default function useAllFunderKycDocuments({
  funder_id,
}: TFunderKycParams) {
  return useQuery(
    [{ funder_id }, "funderKycDocuments"],
    getFunderKycDocuments,
    {
      refetchOnWindowFocus: false,
    }
  );
}
