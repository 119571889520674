import React, { Fragment } from "react";

const LoanSuccessModal = ({ properties }) => {
  const { success, fileUploadError } = properties;

  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <h3 className="Modal__title p-b-2">Loan Request Success</h3>
        <hr />
        {success && (
          <h5 className="text-center text-success my-5 text-uppercase font-weight-bold">
            {success}
          </h5>
        )}
        {!success && (
          <h5 className="text-center text-danger my-5 text-uppercase font-weight-bold">
            No succesful repayment
          </h5>
        )}

        {fileUploadError && (
          <Fragment>
            <p className="text-left text-danger">
              However, some rows contain errors, see preview below.
            </p>
            <div className="table-responsive">
              <table className="table table-white table-hover">
                <thead>
                  <tr>
                    <th>Loan Id</th>
                    <th>Error(s)</th>
                  </tr>
                </thead>
                <tbody>
                  {fileUploadError.length ? (
                    <Fragment>
                      {fileUploadError.map((error, _id) => {
                        return (
                          <tr key={_id}>
                            <td>
                              {error?.data ||
                                error?.data?.data ||
                                error?.data?.data?.data}
                            </td>
                            <td>
                              {error?.message ||
                                error?.data?.message ||
                                error?.data?.data?.message ||
                                error?.data?.data?.data?.message}
                            </td>
                          </tr>
                        );
                      })}
                    </Fragment>
                  ) : (
                    "No Error Information available"
                  )}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default LoanSuccessModal;
