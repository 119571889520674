import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

export const getAllRoleCategories = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.ROLE_CATEGORIES);
  return data;
};

export default function useAllRoleCategories() {
  return useQuery("allRoleCategories", getAllRoleCategories, {
    refetchOnWindowFocus: false,
  });
}
