import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

export const getAllInvestors = async (queryParams: any) => {
  if (queryParams) {
    const data = await getDataWithDotNet(
      `${ajaxEndpoints.GET_ALL_INVESTORS}?${new URLSearchParams(
        queryParams
      ).toString()}`
    );
    return data.data;
  } else {
    const data = await getDataWithDotNet(ajaxEndpoints.GET_ALL_INVESTORS);
    return data.data;
  }
};
