import React, { Dispatch, SetStateAction, useState } from "react";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import Alert from "../../../../NewComponents/Alert/Alert.component";

const ApplyRolloverConfirmation = ({
  rolloverInputTenure,
  setRolloverInputTenure,
  setCurrentStep,
  onCloseModal,
  loanRef,
  aggregatorDetails,
}: {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  rolloverInputTenure: string | null;
  setRolloverInputTenure: Dispatch<SetStateAction<string | null>>;
  onCloseModal: () => void;
  loanRef: string;
  aggregatorDetails: {
    aggregator_id: number;
  };
}) => {
  const [rolloverLoading, setRolloverLoading] = useState(false);
  const [rolloverSuccess, setRolloverSuccess] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const handleApprove = async () => {
    setRolloverLoading(true);
    try {
      const payload = {
        loanRef: loanRef,
        preferredLoanTenure: Number(rolloverInputTenure),
        aggregatorId: Number(aggregatorDetails?.aggregator_id),
      };
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.INITIATE_LOAN_ROLLOVER}`,
        payload
      );

      if (response?.status_code === 200) {
        setRolloverLoading(false);

        setRolloverSuccess(response?.message);
      } else {
        setError(response?.message);
      }
      setTimeout(() => {
        setRolloverInputTenure(null);
        onCloseModal();
      }, 3000);
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setRolloverLoading(false);
      setTimeout(() => {
        setError(null);
        onCloseModal();
      }, 3000);
    }
  };
  return (
    <div>
      {rolloverSuccess && <Alert message={rolloverSuccess} type="success" />}
      {error && <Alert message={error} />}
      <div>
        <p
          className="cursor-pointer font-weight-bold mb-3"
          onClick={() => setCurrentStep(1)}
        >
          {" "}
          <i className="fas fa-arrow-left mr-2" /> Go Back
        </p>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p>Are you sure you want to proceed with Roll over?</p>
        <div className="d-flex justify-content-between">
          <div>
            <button
              type="button"
              className="btn advancly-btn btn-md"
              onClick={handleApprove}
            >
              {rolloverLoading ? "Loading..." : "Yes"}
            </button>
          </div>
          <div>
            <button
              className="btn      advancly-nobg-btn
 btn-md  ml-4"
              onClick={() => onCloseModal()}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyRolloverConfirmation;
