import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import DefaultWalletProvider from "./DefaultWalletProvider";
import { appInsights } from "../../config/appInsights";
import ToggleTab from "../../NewComponents/ToggleTab/ToggleTab.component";

const Wallet = () => {
  const [activeTab, setActiveTab] = useState("wallet-provider");

  useEffect(() => {
    appInsights.trackPageView({
      name: "Wallet Provider - (Wallet.jsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="datatable-wrapper">
      <div className="table-view animated speed-1x fadeInRight">
        <div className="alignToggleTabItems row m-x-1 mb-3">
          <ToggleTab
            text="Wallet Providers"
            id="wallet-provider"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        {(() => {
          switch (activeTab) {
            case "wallet-provider":
              return <DefaultWalletProvider />;

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default Wallet;
