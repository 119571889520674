import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getPendingAdminWithdrawalRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.ADMIN_PENDING_WITHDRAWAL
  );
  return data;
};

export function usePendingAdminWithdrawalRequests() {
  return useQuery(
    ["getPendingAdminWithdrawalRequests"],
    getPendingAdminWithdrawalRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
