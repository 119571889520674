import { useState } from "react";

//Redux
import { useDispatch } from "react-redux";
import { setToast } from "../../../../redux/actions/toast";

// Custom Components
import Tabs from "../../../Tabs/Tabs";

// Component Settings
import GlobalSettings from "./Tabs/Global/GlobalSettings";
import ChannelSettings from "./Tabs/Channel/ChannelSetting";
import RepaymentChannelSettings from "./Tabs/RepaymentChannel/RepaymentChannelSettings";
import LiquidationChannelSettings from "./Tabs/LiquidationChannel/LiquidationChannelSettings";
import CountryLiquidationMapping from "./Tabs/CountryLiquidationMapping/CountryLiquidationMapping";
import MobileVersion from "./Tabs/MobileVersion/mobileVersion";
import ProviderSettings from "./Tabs/ProviderSettings/ProviderSettings";
import TermsAndConditions from "./Tabs/TermsAndConditions/TermsAndConditions";

const AdminSettings = () => {
  const [state, setState] = useState({
    activeTab: "global",
    tabs: [
      {
        name: "Global",
        id: "global",
        icon: "fas fa-globe",
        identifier: "settingsTab",
      },
      {
        name: "Disburse Channel",
        id: "disbursement channel",
        icon: "fas fa-globe",
        identifier: "settingsTab",
      },
      {
        name: "Repay Channel",
        id: "repayment channel",
        icon: "fas fa-globe",
        identifier: "settingsTab",
      },
      {
        name: "Liquidation Channel",
        id: "liquidation channel",
        icon: "fas fa-globe",
        identifier: "settingsTab",
      },
      {
        name: "Local Liquidation Mapping",
        id: "localliquidationmapping",
        icon: "fas fa-money",
        identifier: "settingsTab",
      },
      {
        name: "Mobile Version",
        id: "mobileVersion",
        icon: "fas fa-mobile",
        identifier: "settingsTab",
      },
      {
        name: "Provider Switch",
        id: "providerSwitch",
        icon: "fas fa-globe",
        identifier: "settingsTab",
      },
      {
        name: "Terms and Conditions",
        id: "termsAndConditions",
        icon: "fas fa-book",
        identifier: "settingsTab",
      },
    ],
  });

  const dispatch = useDispatch();

  const toggleTabContent = (content: string) => {
    setState((prev) => {
      return {
        ...prev,
        activeTab: content,
      };
    });
  };

  const setToastHandler = (type: string, message: string) => {
    dispatch(setToast(type, message));
  };

  return (
    <div className="speed-1x fadeInRight">
      {/* Tabs */}
      <Tabs onContentToggle={toggleTabContent} tabs={state?.tabs} />

      <div className="row clearfix grid__padding">
        {/*Datatables*/}
        {(() => {
          switch (state?.activeTab) {
            case "global":
              return <GlobalSettings onSetToast={setToastHandler} />;
            case "disbursement channel":
              return <ChannelSettings onSetToast={setToastHandler} />;
            case "repayment channel":
              return <RepaymentChannelSettings onSetToast={setToastHandler} />;
            case "liquidation channel":
              return (
                <LiquidationChannelSettings onSetToast={setToastHandler} />
              );
            case "localliquidationmapping":
              return (
                <div className="col-sm-12 animated delay-3s slideInRight mt-20">
                  <CountryLiquidationMapping />
                </div>
              );
            case "mobileVersion":
              return <MobileVersion />;

            case "providerSwitch":
              return <ProviderSettings />;

            case "termsAndConditions":
              return <TermsAndConditions />;

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default AdminSettings;
