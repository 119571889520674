import React, { useEffect, useState } from "react";
import {
  createCustomerWallet,
  useWalletCurrencies,
  useWalletProviders,
} from "../../../../custom-hooks/useWallet";
import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import { getTokenFromLocalStorage } from "../../../../helpers/localStorage";
import { BASE_URL } from "../../../../appConstants";
import { appInsights } from "../../../../config/appInsights";
import { errorHandler } from "../../../../helpers/errorHandler";

interface IProps {
  funderId: number;
  goBack: () => void;
}

const CreateInvestorWallet = ({ funderId, goBack }: IProps) => {
  const today = new Date().toISOString().slice(0, 10);
  const lastMonth = new Date(new Date());
  lastMonth.setDate(lastMonth.getDate() - 30);
  const aMonthAgo = lastMonth.toISOString().slice(0, 10);
  const {
    data: walletCurrency,
    status: walletCurrencyStatus,
    error: walletCurrencyError,
  } = useWalletCurrencies();
  const {
    data: walletProvider,
    status: walletProviderStatus,
    error: walletProviderError,
  } = useWalletProviders();

  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [isExternal, setIsExternal] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(aMonthAgo);
  const [creatingWallet, setCreatingWallet] = useState(false);
  const [successResponse, setSuccessResponse] = useState("");
  const [errorResponse, setErrorResponse] = useState("");

  useEffect(() => {
    console.log({
      selectedCurrency,
      selectedProvider,
      effectiveDate,
      isExternal,
    });
  }, [selectedCurrency, selectedProvider, effectiveDate, isExternal]);

  const handleCreateWallet = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCreatingWallet(true);

    try {
      const response = await createCustomerWallet({
        customerId: funderId,
        currency: selectedCurrency,
        providerType: selectedProvider,
        EffectiveDate: effectiveDate,
        isExternalWallet: isExternal === "true",
      });
      console.log({
        response,
      });
      setSuccessResponse(response?.message);
      setTimeout(() => {
        goBack();
        setSuccessResponse("");
      }, 5000);
    } catch (error) {
      setErrorResponse(errorHandler(error));
      setTimeout(() => {
        setErrorResponse("");
      }, 10000);
      console.log(error);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreateInvestorWallet.tsx",
        },
      });
    } finally {
      setCreatingWallet(false);
    }
  };

  return (
    <div>
      <div className="row">
        {successResponse ? (
          <div className="col-md-6">
            <p className="alert alert-success">{successResponse}</p>
          </div>
        ) : errorResponse ? (
          <div className="col-md-6">
            <p className="alert alert-danger">{errorResponse}</p>
          </div>
        ) : null}
      </div>
      <form className="m-t-md" onSubmit={handleCreateWallet}>
        <div className="row">
          <div className="col-md-4 offset-md-1 mb-4">
            {/* @ts-ignore*/}
            <CustomSelectDropdown
              onChange={(e: any) => setSelectedCurrency(e.target.value)}
              name="currency"
              value={selectedCurrency}
              className="round user__form form-control w-100"
              label="Select Currency"
            >
              <option value="">Select Currency</option>
              {walletCurrency &&
                walletCurrency.map((item) => {
                  const { currency_name, currency_code } = item;
                  return (
                    <option value={currency_code} key={currency_code}>
                      {`${currency_name} : ${currency_code}`}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div>

          <div className="col-md-4 offset-md-1 mb-4">
            {/* @ts-ignore*/}
            <CustomSelectDropdown
              onChange={(e: any) => setSelectedProvider(e.target.value)}
              name="providerType"
              value={selectedProvider}
              className="round user__form form-control w-100"
              label="Select Provider"
            >
              <option value="">Select Provider</option>
              {walletProvider &&
                walletProvider.map((item) => {
                  const { name, id } = item;
                  return (
                    <option value={name} key={id}>
                      {name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div>

          <div className="col-md-4 offset-md-1 mb-4">
            {/* @ts-ignore*/}
            <CustomSelectDropdown
              onChange={(e: any) => setIsExternal(e.target.value)}
              name="isExternal"
              value={isExternal}
              className="round user__form form-control w-100"
              label="IsExternal"
            >
              <option value="">Select IsExternal</option>
              <option value="false">False</option>
              <option value="true">True</option>
            </CustomSelectDropdown>
          </div>

          <div className="col-md-4 offset-md-1 mb-4">
            {/* @ts-ignore*/}
            <label htmlFor="effectiveDate">Effective Date</label>
            <input
              type="date"
              className="form-control"
              defaultValue={today}
              onChange={(e) => setEffectiveDate(e.target.value)}
              id="effectiveDate"
            />
          </div>

          <div className="col-md-4 offset-md-1 mb-4">
            <button
              className="btn btn-black btn-primary w-100"
              style={{ marginTop: "35px" }}
              disabled={
                creatingWallet || !selectedProvider || !selectedCurrency
              }
            >
              {creatingWallet ? (
                <span className="spinner-border text-white" />
              ) : (
                "Create Wallet"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateInvestorWallet;
