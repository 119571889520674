import { useEffect, useRef } from "react";
import ReactNotificationAlert from "react-notification-alert";

// type IAlert = {
//   message?: string;
//   type?: any;
//   place?: any;
//   autoDismiss?: any;
//   icon?: any
// }

const Alert = ({ message, type, place, autoDismiss, icon }) => {
  useEffect(() => {
    notify({ message, type, place, autoDismiss, icon });
    // eslint-disable-next-line
  }, []);

  const alert = useRef();
  const notify = ({ message, type, place }) => {
    const options = {
      place: place || "tc",
      message,
      type: type || "danger",
      autoDismiss: 700,
      icon: "",
    };
    if (alert && alert.current) {
      alert.current.notificationAlert(options);
    }
  };
  return (
    <div>
      <ReactNotificationAlert ref={alert} />
    </div>
  );
};

export default Alert;
