import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { currencyFormatter } from "../../../../helpers/stringControllers";
interface IApplyRollover {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setRolloverInputTenure: Dispatch<SetStateAction<string | null>>;
  loanTenure: string;
  loanAmount: string;
  loanInterest: string;
  loanEffectiveDate: string;
  rolloverInputTenure: string | null;
  loanRepaymentBalance: string;
  aggregatorDetails: {
    currency: string;
  };
}
const ApplyRollover = ({
  setCurrentStep,
  setRolloverInputTenure,
  rolloverInputTenure,
  loanTenure,
  loanAmount,
  loanInterest,
  loanEffectiveDate,
  loanRepaymentBalance,
  aggregatorDetails,
}: IApplyRollover) => {
  const { register, handleSubmit, errors } = useForm({});

  const handleRolloverDetails = () => {
    setCurrentStep(2);
  };
  return (
    <div>
      <h1>Request for roll over </h1>

      <p>How Long Do You Want to Rollover For?</p>
      <form onSubmit={handleSubmit(handleRolloverDetails)}>
        <label className="label-txt">
          Loan Tenure <span>*(in days)</span>
        </label>
        <div className="form-group inputWithIcon">
          <input
            name="rolloverInputTenure"
            type="number"
            ref={register}
            max={365}
            className="user__form form-control"
            onChange={(e) => setRolloverInputTenure(e.target.value)}
          />
        </div>

        <div className="my-3">
          <button
            type="submit"
            className=" btn advancly-btn"
            disabled={rolloverInputTenure === null}
          >
            Proceed
          </button>
        </div>
      </form>

      <div>
        <p>
          Principal Amount:{" "}
          <span className="font-weight-bold">
            {currencyFormatter(Number(loanAmount), aggregatorDetails?.currency)}
          </span>
        </p>
        <p>
          Outstanding Interest:{" "}
          <span className="font-weight-bold">
            {currencyFormatter(
              Number(loanInterest),
              aggregatorDetails?.currency
            )}
          </span>
        </p>
        <p>
          Total Outstanding:{" "}
          <span className="font-weight-bold">
            {currencyFormatter(
              Number(loanRepaymentBalance),
              aggregatorDetails?.currency
            )}
          </span>
        </p>
        <p>
          Effective Date:{" "}
          <span className="font-weight-bold">
            {loanEffectiveDate.substring(0, 10)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ApplyRollover;
