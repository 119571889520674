import { ChangeEvent, useState } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postData } from "../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../NewComponents/CustomHTMLElements/CustomTextArea";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { showModal } from "../../../redux/actions/modal";
import Loader from "../../../NewComponents/Loader/Loader.component";
import { appInsights } from "../../../config/appInsights";

interface IApproveDeclineDealSlipProps {
  properties: {
    dataRecord: {
      modalTitle: string;
      approvalDecision: string;
      loanId: number;
    };
    modalIdentifier: string;
  };
  onCloseModal: () => void;
}

const ApproveDeclineDealSlip = ({
  properties,
  onCloseModal,
}: IApproveDeclineDealSlipProps) => {
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const possibleApprovalDecisions = {
    ACCEPTED: "Accepted",
    REJECTED: "Rejected",
  };

  const handleCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    return setComments(e.target.value);
  };

  const handleApprovalDecision = async () => {
    setIsLoading(true);
    try {
      const payload: {
        loan_id: number;
        comment: string;
        approval_status?: string;
      } = {
        loan_id: properties?.dataRecord?.loanId,
        comment: comments,
      };
      if (
        properties?.dataRecord?.approvalDecision ===
        possibleApprovalDecisions?.ACCEPTED
      ) {
        payload.approval_status = possibleApprovalDecisions?.ACCEPTED;
      } else {
        payload.approval_status = possibleApprovalDecisions?.REJECTED;
      }

      const response = await postData(ajaxEndpoints.APPROVAL_DECISION, payload);
      if (response?.data?.status === true) {
        onCloseModal();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "approveDeclineDealSlip.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsLoading(false);
    history.push(`/pending-slips`);
  };
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3
            className="Modal__title m-b-1 p-x-2 p-y-1"
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            {properties.dataRecord.modalTitle}
          </h3>

          <div className="mt-4">
            <CustomTextArea
              maxLength="256"
              placeholder="Enter message here..."
              label="Admin Comment"
              name="message"
              showRequiredIcon={true}
              onChange={handleCommentsChange}
            />
          </div>

          <div className="d-flex justify-content-end">
            <button
              className={`create zoom-element btn login-btn m-0`}
              onClick={handleApprovalDecision}
            >
              Submit
              {isLoading && <Loader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveDeclineDealSlip;
