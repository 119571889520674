import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    faqs: [],
    loading: false,
    error: null
};



export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.GET_FAQ_START:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.GET_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                faq: [...action.faqData]
        }

        case actionTypes.GET_FAQ_FAILURE:
            return {
                loading: false,
                error: action.error
        }

        default:
            return state;
    }
}

export default reducer;