import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    issuesTableData: null,
    issues: [],
    count: 0,
    next_page: '',
    prev_page: '',
    loading: false,
    error: null,

    allIssues: {
        allIssuesTableData: {
            columns: [],
            rows: []
        },
        records: [],
        count: 0,
        next_page: '',
        prev_page: '',
        loading: false,
        error: null
    },
    pendingIssues: {
        pendingIssuesTableData: {
            columns: [],
            rows: []
        },
        records: [],
        count: 0,
        next_page: '',
        prev_page: '',
        loading: false,
        error: null
    },
    closedIssues: {
        closedIssuesTableData: {
            columns: [],
            rows: []
        },
        records: [],
        count: 0,
        next_page: '',
        prev_page: '',
        loading: false,
        error: null
    },
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.ALL_ISSUES_START:
            return {
                ...state,
                allIssues: {
                    ...state.allIssues,
                    loading: true
                }
            };
        
        case actionTypes.ALL_ISSUES_SUCCESS:

            return {
                ...state,
                allIssues: {
                    ...state.allIssues,
                    ...action.payload,
                    loading: false,
                    error: null,
                }
            };

        case actionTypes.ALL_ISSUES_NETWORK_FAILURE:
            return {
                ...state,
                allIssues: {
                    ...state.allIssues,
                    loading: false,
                    error: action.error
                }
            };

        case actionTypes.ALL_ISSUES_ERROR:
            return {
                ...state,
                pendingIssues: {
                    ...state.pendingIssues,
                    loading: false,
                    error: action.error
                }
            };

        case actionTypes.PENDING_ISSUES_START:
            return {
                ...state,
                pendingIssues: {
                    ...state.pendingIssues,
                    loading: true
                }
            };
        
        case actionTypes.PENDING_ISSUES_SUCCESS:

            return {
                ...state,
                pendingIssues: {
                    ...state.pendingIssues,
                    ...action.payload,
                    loading: false,
                    error: null,
                }
            };

        case actionTypes.PENDING_ISSUES_NETWORK_FAILURE:
            return {
                ...state,
                pendingIssues: {
                    ...state.pendingIssues,
                    loading: false,
                    error: action.error
                }
            };

        case actionTypes.PENDING_ISSUES_ERROR:
            return {
                ...state,
                pendingIssues: {
                    ...state.pendingIssues,
                    loading: false,
                    error: action.error
                }
            };

        default:
            return state;
    }
};

export default reducer;