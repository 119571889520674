import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import { accurateQuantifier } from "./../../../../helpers/accurateQuantifier";

class FaileRepaymentDetails extends Component {
  state = {
    scene: "form",
    formScene: "account_details",
    tabs: [
      {
        name: "Account",
        id: "account_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      // {
      //     name: "Loan",
      //     id: "loan_details",
      //     icon: "fas fa-chart-bar",
      //     identifier: "loanInfoTab"
      // },
      {
        name: "Disbursement Gateway",
        id: "gateway_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
  };
  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      formScene: content,
    });
  };

  render() {
    // Get data from component state
    const { scene, tabs, formScene } = this.state;

    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            Loan ID: <span>{this.props.properties.dataRecord.id}</span>
          </h3>
          {/* Partner Form */}
          {(() => {
            switch (scene) {
              case "form":
                return (
                  <form>
                    <div className="row m-b-1">
                      <Tabs
                        onContentToggle={this.toggleTabContent}
                        tabs={tabs}
                      />
                    </div>
                    {(() => {
                      switch (formScene) {
                        case "account_details":
                          return (
                            <AccountDetails
                              details={this.props.properties.dataRecord}
                            />
                          );

                        // case 'loan_details':
                        //     return (
                        //         <LoanDetails details={
                        //             {
                        //                 loan_amount,
                        //                 loan_amount_repay,
                        //                 loan_ref,
                        //                 loan_tenure,
                        //                 pub_date,
                        //                 settlement_day
                        //             }
                        //         } />
                        //     );
                        case "gateway_details":
                          return (
                            <LoanGatewayDetails
                              details={this.props.properties.dataRecord}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                    <div className="Form__action-buttons m-t-2">
                      {/* <button type="button"
                                                    className="btn zoom-element btn login-btn m-0 mr-auto"
                                                    onClick={this.printHandler}>
                                                    <i className="fas fa-print"></i>
                                                    &nbsp; Print
                                                </button> */}
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn"
                        onClick={this.props.onCloseModal}
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        &nbsp; Close
                      </button>
                    </div>
                  </form>
                );

              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

// Account Details
const AccountDetails = (props) => {
  const {
    beneficiary_account_num,
    beneficiary_account_name,
    beneficiary_bank_name,
    beneficiary_bankcode,
    debit_account_num,
    debit_account_name,
    debit_bank_name,
    debit_bankcode,
  } = props.details;

  return (
    <div className="m-b-2">
      <h5>DEBIT ACCOUNT</h5>
      <label className="label-txt m-t-1">Bank Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_name || "Unavailable"}
        />
        <i className="fa fa-bank" />
      </div>
      <label className="label-txt">Account Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_account_name || "Unavailable"}
        />
        <i className="fas fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_account_num || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bankcode || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <br />
      <h5>Beneficiary ACCOUNT</h5>

      <label className="label-txt m-t-1">Bank Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={beneficiary_bank_name || "Unavailable"}
        />
        <i className="fa fa-bank " />
      </div>
      <label className="label-txt">Account Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={beneficiary_account_name || "Unavailable"}
        />
        <i className="fa fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={beneficiary_account_num || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={beneficiary_bankcode || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
    </div>
  );
};
// Loan Details
// eslint-disable-next-line
const LoanDetails = (props) => {
  const {
    loan_amount,
    loan_amount_repay,
    loan_ref,
    loan_tenure,
    pub_date,
    settlement_day,
  } = props.details;

  return (
    <React.Fragment>
      <label className="label-txt">Loan Reference</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          className="user__form form-control"
          readOnly
          value={loan_ref || "Unavailable"}
        />
        <i className="fas fa-user-circle" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={loan_amount || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Repayment Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_amount_repay || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <label className="label-txt">Loan Tenure</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={accurateQuantifier(loan_tenure, "day")}
        />
        <i className="fas fa-hourglass-start" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Date Created</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={new Date(pub_date)}
            />
            {/* <i className="far fa-calendar-alt" /> */}
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Settlement Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={settlement_day}
            />
            <i className="fas fa-calendar-alt" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// loan gateway details
const LoanGatewayDetails = (props) => {
  const {
    repayment_gateway_ref,
    repayment_gateway_response,
    repayment_status,
    repayment_channel_id,
    repayment_channel_status,
    repayment_ref,
    repayment_gateway_final,
    corebanking_status,
    repayment_date,
  } = props.details;

  return (
    <div className="m-b-2">
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Gateway Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={repayment_gateway_ref || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Repayment Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={repayment_ref || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Repayment Channel ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={repayment_channel_id || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Repayment Channel Status:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={repayment_channel_status || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <label className="label-txt">Repayment Gateway Final:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={repayment_gateway_final || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Response:</label>
        <div className="form-group">
          <textarea
            type="text"
            readOnly
            className="user__form form-control"
            value={repayment_gateway_response || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Core Banking Status:</label>
        <div className="form-group">
          <textarea
            type="text"
            readOnly
            className="user__form form-control"
            value={corebanking_status || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Repayment Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={new Date(repayment_date) || "Unavailable"}
          />
        </div>
      </div>

      <div className="row">
        <label className="label-txt">Repayment Status</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={repayment_status || "Unavailable"}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    disbursements: { records },
  } = state.loansReducer;
  return {
    records,
  };
};

export default connect(mapStateToProps)(FaileRepaymentDetails);
