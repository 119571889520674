import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

export type TCountryCode = {
  country_code: string;
  currency: string;
  dial_code: "string";
  id: number;
  is_active: number;
  name: string;
};

type TResponse = {
  data: TCountryCode[];
  message: string;
  status: boolean;
  status_code: number;
};

const getCountryCodes = async () => {
  const { data } = await getData(ajaxEndpoints.COUNTRY_CODES_URL);
  return data;
};

export default function useCountryCodes() {
  return useQuery<TResponse>(["getCountryCodes"], getCountryCodes, {
    refetchOnWindowFocus: false,
  });
}
