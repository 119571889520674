import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

export type TBank = {
  code: string;
  country_code: string;
  id: number;
  longcode: string;
  name: string;
  slug: string;
  vigipay_bank_id?: number;
};

type TResponse = {
  data: TBank[];
  message: string;
  status: boolean;
  status_code: number;
};

const getAllBanksByCountryCode = async (code: string) => {
  if (code) {
    const { data } = await getData(
      `${ajaxEndpoints.GET_BANK_LIST_BY_COUNTRY_CODE}?country_code=${code}`
    );
    return data;
  }
};

export default function useAllBanksByCountryCode(code: string) {
  return useQuery<TResponse>(
    [code, "getAllBanksByCountryCode"],
    getAllBanksByCountryCode,
    {
      refetchOnWindowFocus: false,
    }
  );
}
