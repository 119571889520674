import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TCoreBankingTenant = {
  created_at: string;
  full_name: string;
  id: number;
  tenant_id: string;
  updated_at: string;
};

const getCoreBankingTenantInfo = async () => {
  const { data } = await getData(ajaxEndpoints.GET_CORE_BANKING_TENANT_INFO);
  return data;
};

export default function useCoreBankingTenantInfo() {
  return useQuery<IResponse<TCoreBankingTenant[]>>(
    "corebankingtenantinfolist",
    getCoreBankingTenantInfo,
    {
      refetchOnWindowFocus: false,
    }
  );
}
