import React, { useState } from "react";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import PendingRolloverList from "./PendingRolloverList";

const LoanRolloverRequestCheckerMain = () => {
  const [activeTab, setActiveTab] = useState("pending-rollover");

  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view animated speed-1x fadeInRight">
          <div className="alignToggleTabItems row m-x-1 mb-3">
            <ToggleTab
              text="Pending Rollover Request"
              id="pending-rollover"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          {(() => {
            switch (activeTab) {
              case "pending-rollover":
                return <PendingRolloverList />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default LoanRolloverRequestCheckerMain;
