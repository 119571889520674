import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { GET_RECENT_DISBURSEMENTS_DATA } from "../api/ajax-endpoints";

interface IgetRecentDisbursement {
  currentCurrencyValue: string;
  aggregatorId: string;
}
const getRecentDisbursements = async ({
  currentCurrencyValue,
  aggregatorId,
}: IgetRecentDisbursement) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_RECENT_DISBURSEMENTS_DATA}?currency=${currentCurrencyValue}&aggregatorId=${aggregatorId}`,
  );
  return data.data;
};

export default function useRecentDisbursements({
  currentCurrencyValue,
  aggregatorId,
}: IgetRecentDisbursement) {
  return useQuery(
    [{ currentCurrencyValue, aggregatorId }, "getRecentDisbursements"],
    getRecentDisbursements,
    {
      enabled: currentCurrencyValue && aggregatorId ? true : false,

      refetchOnWindowFocus: false,
    },
  );
}
