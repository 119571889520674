import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import CustomInputField from "../../../NewComponents/CustomHTMLElements/CustomInputField";
import ButtonLoader from "../../../UI/Loaders/Loaders";

interface IApprovalInvestorModal {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  dismissModalRef: React.LegacyRef<HTMLButtonElement>;
  modalActionType: string;
  discardChanges: React.FormEventHandler<HTMLFormElement>;
  loading: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  modifier: string;
  onDecline: React.FormEventHandler<HTMLFormElement>;
}

export default function ApprovalInvestorModal({
  onSubmit,
  dismissModalRef,
  modalActionType,
  discardChanges,
  loading,
  handleChange,
  modifier,
  onDecline,
}: IApprovalInvestorModal) {
  const history = useHistory();
  return (
    <Fragment>
      <div
        className="modal fade"
        id="approvalRequestModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="approvalRequestModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            {(() => {
              switch (modalActionType) {
                case "update":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Update Documents</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Updates you make to the Investment funds will be
                          effected.
                        </p>
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                                disabled={loading}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? "Updating..." : "Confirm Update"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "cancel":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Discard Changes</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Are you sure you want to discard? All the information
                          provided so far will be discarded.
                        </p>
                        <form onSubmit={discardChanges}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                onClick={() => history.goBack()}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "goback":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Discard Changes</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Are you sure you want to discard? All the information
                          provided so far will be discarded.
                        </p>
                        <form onSubmit={discardChanges}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                onClick={() => history.goBack()}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "activate":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">
                          Two Factor Authentication
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Enter the generated 2FA to confirm this action
                        </p>
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <CustomInputField
                                label="Generated 2FA"
                                name="otp"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12">
                              <CustomInputField
                                label="Comment"
                                name="comment"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "approve":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">
                          {modifier === "investor"
                            ? "Approve Investor Request"
                            : modifier === "investment"
                            ? "Approve Investment Fund"
                            : ""}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12">
                              <CustomInputField
                                label="Comment"
                                name="comment"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                Approve {loading ? <ButtonLoader /> : ""}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "decline":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">
                          {modifier === "investor"
                            ? "Decline Investor Request"
                            : modifier === "investment"
                            ? "Decline Investment Fund"
                            : ""}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={onDecline}>
                          <div className="row">
                            <div className="col-12">
                              <CustomInputField
                                label="Comment"
                                name="comment"
                                onChange={handleChange}
                              ></CustomInputField>
                            </div>
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                Decline {loading && <ButtonLoader />}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
