import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function PasswordResetSuccess() {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/login");
    }, 5000);
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      {/* Password Reset Success */}
      <div id="adv-password-reset-success" className="speed-1x fadeInRight">
        <div className="tfx-step grid__full">
          <div className="tfx-user-form-wrapper box-effect full-border">
            <div className="tfx-user-form-inner">
              <div className="grid__full ">
                <div className="adv-form">
                  {/* 2. Password Reset Successful */}
                  <div className="loading-overlay">
                    <div className="form-loader form-loader-login verify">
                      <div className="centerLoader">
                        <div className="loading success-loader">
                          <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                        </div>

                        <div className="center-txt webapp-ripple-text">
                          <span className="resetPasswordText">
                            Password Reset Successfully
                          </span>
                        </div>

                        <div className="onboarding-response center-txt webapp-ripple-text">
                          You can now login with your new password. We've also
                          sent you a mail detailing your updated credentials.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
