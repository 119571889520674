import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getInvestmentProductDetailsById = async (id: number) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.INVESTMENT_PRODUCT_DETAIL}/${id}`
  );
  return data.data;
};

export default function useInvestmentProductDetail(id: number) {
  return useQuery(
    [id, "getInvestmentProductDetailsById"],
    getInvestmentProductDetailsById,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );
}

const getAnInvestmentTransactionsAndDetails = async (id: number) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.INVESTMENT}/${id}/transactions`
  );
  return data?.data;
};

export function useInvestmentTransactions(id: number) {
  return useQuery(
    [id, "getAnInvestmentTransactionsAndDetails"],
    getAnInvestmentTransactionsAndDetails,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );
}
