import { MDBTable } from "mdbreact";
import React, { useState } from "react";
import Paginator from "../../../../components/Pagination/Pagination.component";
import { formatMoney } from "../../../../helpers/formatter";

const ApproveBatchDisbursementView = ({ dataRecord, onCloseModal }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  const { loanDetails } = dataRecord;
  let RenderedComponent = null;

  if (!dataRecord) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            Batch Disbursement Loans, Please Reload The Page
          </h4>
        </div>
      </div>
    );
  } else if ((loanDetails && loanDetails.length === 0) || !dataRecord) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are currently no logged
            activities.
          </h4>
        </div>
      </div>
    );
  } else {
    RenderedComponent = (
      <div className="row m-x-sm m-y-2 grid__padding">
        <MDBTable responsive minimal="true" bordered hover>
          <thead className="bg-lighter-grey">
            <tr>
              <th>S/N</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Amount</th>
              <th>Interest Rate</th>
              <th>Loan ref</th>
              <th>Loan Tenure</th>
              <th>Batch Number</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            {loanDetails &&
              loanDetails
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((batchDisbursementData, _id) => {
                  const {
                    currencyCode,
                    firstName,
                    lastName,
                    loanAmount,
                    loanRef,
                    pubDate,
                    loanTenure,
                    batchLoanNumber,
                    interestRate,
                  } = batchDisbursementData;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{formatMoney(loanAmount, currencyCode)}</td>
                      <td>{formatMoney(interestRate, currencyCode)}</td>
                      <td>{loanRef}</td>
                      <td>{loanTenure}</td>
                      <td>{batchLoanNumber}</td>
                      <td>{pubDate && pubDate.substring(0, 10)}</td>
                    </tr>
                  );
                })}
          </tbody>
        </MDBTable>
      </div>
    );
  }

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h3 className="Modal__title p-b-2 text-center">
            Batch Loan Information
          </h3>
        </div>
        {/* All Batch Loans Ready for Disbursement Datatable || Loader || Error Message*/}
        {RenderedComponent}
        <Paginator
          size={size}
          page={pageNumber}
          count={loanDetails && loanDetails.length}
          changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
        />
      </div>
    </div>
  );
};
export default ApproveBatchDisbursementView;
