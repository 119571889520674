import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type paramProps = {
  investmentId: number;
};

export const getAnInvestmentTermSheet = async (params: paramProps) => {
  const { data } = await getData(
    `${ajaxEndpoints.INVESTMENT_TERM_SHEET}?detail_id=${params.investmentId}`
  );
  return data;
};

export default function useInvestmentTermSheet({ investmentId }: paramProps) {
  return useQuery(
    [{ investmentId }, "getAnInvestmentTermSheet"],
    getAnInvestmentTermSheet,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: investmentId ? true : false, // Only run when there is investmentId
    }
  );
}
