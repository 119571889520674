import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  borrower_id: number;
};

const getAllABorrowersLoans = async ({ borrower_id }: TQuery) => {
  const data = await getData(
    `${ajaxEndpoints.ALL_LOANS_TIED_TO_A_BORROWER}?borrower_id=${borrower_id}`
  );
  return data;
};

export default function useAllAborrowersLoans({ borrower_id }: TQuery) {
  return useQuery(
    [{ borrower_id }, "getAllABorrowersLoans"],
    getAllABorrowersLoans
  );
}
