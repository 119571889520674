import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAllAggregatorProducts = async () => {
  const data = await getDataWithDotNet(
    ajaxEndpoints.GET_ALL_AGGREGATORS_PRODUCT
  );
  return data.data;
};

export default function useAllAggregatorProducts() {
  return useQuery(["getAllAggregatorProducts"], getAllAggregatorProducts, {
    refetchOnWindowFocus: false,
  });
}
