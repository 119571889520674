import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getNotificationMessageClass = async () => {
  const response = await getData(ajaxEndpoints.GET_MESSAGE_CLASS);
  return response?.data;
};

export default function useNotificationMessageClass() {
  return useQuery("notificationMessageClass", getNotificationMessageClass, {
    refetchOnWindowFocus: false,
  });
}
