export const getMimeType = (fileName: string): string => {
  if (fileName.endsWith(".pdf")) {
    return "application/pdf";
  } else if (fileName.endsWith(".png")) {
    return "image/png";
  } else if (fileName.endsWith(".jpg")) {
    return "image/jpg";
  } else if (fileName.endsWith(".jpeg")) {
    return "image/jpeg";
  } else if (fileName.endsWith(".doc")) {
    return "application/msword";
  } else if (fileName.endsWith(".docx")) {
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else {
    return "";
  }
};

export const splitAtLastOccurrenceOfItem = (str: string, item: string) => {
  // Get the last occurrence of the item in question in the string
  const lastIndex = str.lastIndexOf(item);

  // Slice from the lastIndex of the item and return the result
  const result = str.slice(lastIndex);

  return result;
};
