import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  page: number;
  size: number;
  startDate?: string;
  endDate?: string;
  loanRef?: string;
};

const getAllRepayment = async ({
  page,
  size,
  startDate,
  endDate,
  loanRef,
}: TQuery) => {
  let queryParams = `Page=${page}&PageSize=${size}`;
  if (startDate && endDate) {
    queryParams = `${queryParams}&RepaymentStartDate=${startDate}&RepaymentToDate=${endDate}`;
  }
  if (loanRef) {
    queryParams = `${queryParams}&LoanRef=${loanRef}`;
  }

  const data = await getData(
    `${ajaxEndpoints.GET_ALL_REPAYMENTS}?${queryParams}`
  );
  return data;
};

export default function useAllRepayment({
  page,
  size,
  startDate,
  endDate,
  loanRef,
}: TQuery) {
  return useQuery(
    [{ page, size, startDate, endDate, loanRef }, "getAllRepayment "],
    getAllRepayment
  );
}
