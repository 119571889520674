// Extracts file extension and converts to mime type
import { ChangeEvent } from "react";

export const getMimeType = (filename: string): string => {
  if (!filename.includes(".")) {
    return "image/jpeg"; // default to "image/jpeg" if there's no extension
  }

  const extension = filename.split(".")[1].toLowerCase();

  switch (extension) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "pdf":
      return "application/pdf";
    default:
      return "image/jpeg"; // default to "image/jpeg" if the extension is not recognized
  }
};

export const onChangeFile = async (
  e: ChangeEvent<HTMLInputElement>,
  setInfoCallback: (file: File, filename: string) => void,
  errorCallback: (error: string | Error) => void,
) => {
  const files = e.target.files;

  // Since files can be null, we need to add an extra check here
  if (!files || files.length === 0) {
    return;
  }

  const file = files[0];
  const fileType = file.type;

  // Validate file size
  const fileSize = file.size; // size in bytes
  const maxSize = 25 * 1024 * 1024; // max size is 5MB
  if (fileSize > maxSize) {
    return errorCallback("File size exceeds the maximum limit of 25MB");
  }

  const isValidFile =
    fileType.startsWith("image/png") ||
    fileType.startsWith("image/jpg") ||
    fileType.startsWith("image/jpeg") ||
    fileType.startsWith("application/pdf");

  if (!isValidFile) {
    return errorCallback("Invalid file type");
  }
  setInfoCallback(file, file?.name);
};
