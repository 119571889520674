import React from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getLoansForDisbursement,
  setDisbursementPageSize,
  setDisbursementFilterStartDate,
  setDisbursementFilterEndDate,
  setDisbursementBorrowerName,
  setDisbursementLoanId,
  setDisbursementBvn,
  setDisbursementRcNumber,
  setDisbursementAggregatorId,
  setDisbursementLoanRef,
} from "../../../../redux/actions/loans";
// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import { BASE_URL } from "../../../../appConstants";
import { getTokenFromLocalStorage } from "../../../../helpers/localStorage";
import useAllAggregatorsTyped from "../../../../custom-hooks/useAllAggregatorsTyped";
import { useHistory } from "react-router-dom";
import { appInsights } from "../../../../config/appInsights";
import { RootState } from "../../../../redux/store";

const DisbursementSearch = ({ onCloseModal }: any) => {
  const { data: aggregatorData } = useAllAggregatorsTyped();

  const {
    disbursements: {
      startDate,
      endDate,
      borrowerName,
      loanId,
      bvn,
      rcNumber,
      aggregatorId,
      loanRef,
      disbursementSize,
      disbursementPageNumber,
    },
  } = useSelector((state: RootState) => state?.loansReducer);

  const dispatch = useDispatch();
  const history = useHistory();

  const searchLoan = (e: any) => {
    e.preventDefault();
    const params = {
      page: disbursementPageNumber,
      size: disbursementSize,
      endDate,
      startDate,
      borrowerName,
      loanId,
      bvn,
      rcNumber,
      aggregatorId,
      loanRef,
    };
    //   setButtonLoading(true);
    onCloseModal();
    dispatch(getLoansForDisbursement(params));
  };
  const getLoanRecordsModal = async (e: any) => {
    e.preventDefault();
    let queryParams = "";

    if (startDate && endDate) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }from_date=${startDate}&to_date=${endDate}`;
    }
    if (borrowerName) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }borrowername=${borrowerName}`;
    }
    if (bvn) {
      queryParams = `${queryParams ? queryParams + "&" : ""}bvn=${bvn}`;
    }
    if (rcNumber) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }rcnumber=${rcNumber}`;
    }
    if (aggregatorId) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }aggregatorId=${Number(aggregatorId)}`;
    }
    if (loanId) {
      queryParams = `${queryParams ? queryParams + "&" : ""}loanid=${Number(
        loanId
      )}`;
    }
    if (loanRef) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }loanreference=${loanRef}`;
    }
    let token = getTokenFromLocalStorage();
    fetch(BASE_URL + `/eco/ready_for_settlement/csv?${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `ready for settlement loans.csv`);
            link.click();
          });
        }
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "DisbursementSearcg.jsx",
          },
        });
      });

    onCloseModal();
    dispatch(setDisbursementPageSize(1));
    dispatch(
      getLoansForDisbursement({
        page: 1,
        size: disbursementSize,
        startDate,
        endDate,
        borrowerName,
        loanId,
        bvn,
        rcNumber,
        aggregatorId,
        loanRef,
      })
    );
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        <React.Fragment>
          <h3 className="Modal__title p-b-1">Search By:</h3>
          {/* 2. Confirm Update Role Modal*/}
          <div id="disbursement-confirm">
            <div className="m-b-30">
              <form>
                <div className="row">
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Borrower Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(setDisbursementBorrowerName(e.target.value));
                      }}
                      defaultValue={borrowerName}
                      name="borrowerName"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Loan Id:</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(setDisbursementLoanId(e.target.value));
                      }}
                      defaultValue={loanId}
                      name="loanId"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">BVN:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(setDisbursementBvn(e.target.value));
                      }}
                      defaultValue={bvn}
                      name="bvn"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Rc Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(setDisbursementRcNumber(e.target.value));
                      }}
                      defaultValue={rcNumber}
                      name="rcNumber"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Loan Reference:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(setDisbursementLoanRef(e.target.value));
                      }}
                      defaultValue={loanRef}
                      name="loanRef"
                    />
                  </div>

                  <div className="col-md-3 col-sm-12 mt-2">
                    <CustomSelectDropdown
                      label="Aggregator:"
                      name="aggregatorId"
                      onChange={(e) => {
                        dispatch(setDisbursementAggregatorId(e.target.value));
                      }}
                      value={Number(aggregatorId)}
                      className="round user__form form-control"
                    >
                      <option value=""></option>
                      {aggregatorData &&
                        aggregatorData?.data &&
                        aggregatorData?.data?.data.map((aggregator) => {
                          const { biz_name, aggregator_id } = aggregator;
                          return (
                            <option
                              value={Number(aggregator_id)}
                              key={aggregator_id}
                            >
                              {biz_name}
                            </option>
                          );
                        })}
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Created Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(
                          setDisbursementFilterStartDate(e.target.value)
                        );
                      }}
                      defaultValue={startDate}
                      name="startDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Created End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        dispatch(setDisbursementFilterEndDate(e.target.value));
                      }}
                      min={startDate}
                      defaultValue={endDate}
                      name="endDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                </div>
                <div className="Form__action-buttons m-t-2">
                  <button
                    type="submit"
                    onClick={(e) => searchLoan(e)}
                    className="btn zoom-element btn login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Filter
                  </button>
                  <button
                    id="create"
                    data-id="create-role"
                    data-action="create"
                    className="create zoom-element btn login-btn"
                    onClick={getLoanRecordsModal}
                  >
                    <i className="fas fa-download" /> &nbsp; Download
                  </button>
                  <button
                    type="button"
                    onClick={onCloseModal}
                    className="btn zoom-element btn login-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* 2. Disbursement Loader*/}
          <div
            id="disbursement-loader"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className=" form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier fas fa-user-cog" />
                  Searching ...
                </div>
              </div>
            </div>
          </div>

          {/* 3. Disbursement Success Message*/}
          <div
            id="disbursement-success"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className="form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading success-loader">
                  <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                </div>
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier far fa-check-circle" />
                  Search Successful
                </div>
              </div>
            </div>
          </div>

          {/* 4. Disbursement Error */}
          <div
            id="disbursement-error"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <ErrorLoader
              icon="fas fa-wifi"
              text="Network Issue"
              subText="Unable to connect to Advancly services."
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default DisbursementSearch;
