import React, { useState } from "react";

// Styles
import "./Recovery.css";

// Components
import PasswordResetSuccess from "./RecoveryComponents/PasswordResetSuccess.component";
import Stepper from "../Stepper/Stepper.component";
import RecoveryStepOne from "./RecoverySteps/RecoveryStepOne.component";
import RecoveryStepTwo from "./RecoverySteps/RecoveryStepTwo.component";
import RecoveryStepThree from "./RecoverySteps/RecoveryStepThree.component";

export default function Recovery() {
  // State
  const [stateToken, setStateToken] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [multiStepMenu, setMultiStepMenu] = useState("stepOne");

  //JSX Template
  return (
    <div className="center-container">
      <div className="center-container-wrapper">
        <div className="center-element">
          <div className="normal-1x zoomIn">
            {/*ALGORITHM FOR STEP UI*/}
            <div className="tfx-step grid__full">
              <div className="form-steps">
                <Stepper
                  id="stepOne"
                  step="1"
                  text="Verify Email"
                  active={multiStepMenu === "stepOne" ? true : false}
                />
                <Stepper
                  id="stepTwo"
                  step="2"
                  text="Verify Pin Code"
                  active={multiStepMenu === "stepTwo" ? true : false}
                />
                <Stepper
                  id="stepThree"
                  step="3"
                  text="Password Update"
                  active={multiStepMenu === "stepThree" ? true : false}
                />
                <Stepper
                  id="stepFour"
                  step="4"
                  text="Complete Setup"
                  active={multiStepMenu === "stepFour" ? true : false}
                  lastStep={true}
                />
              </div>
            </div>

            {/*RECOVER PASSWORD UI*/}
            {(() => {
              switch (multiStepMenu) {
                case "stepOne":
                  return (
                    <RecoveryStepOne
                      setStateToken={setStateToken}
                      setMultiStepMenu={setMultiStepMenu}
                      setEmail={setEmail}
                    />
                  );
                case "stepTwo":
                  return (
                    <RecoveryStepTwo
                      state_token={stateToken}
                      setMultiStepMenu={setMultiStepMenu}
                      setOtp={setOtp}
                    />
                  );
                case "stepThree":
                  return (
                    <RecoveryStepThree
                      state_token={stateToken}
                      setMultiStepMenu={setMultiStepMenu}
                      email={email}
                      otp={otp}
                    />
                  );
                case "stepFour":
                  return <PasswordResetSuccess />;
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
