import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";

import { getData } from "../newApis/dotNetApiMethods";

type TPendingSlips = {
  Page: string;
  PageSize: string;
};

const getPendingDealSlips = async (queryParams: TPendingSlips) => {
  const response = await getData(
    `${ajaxEndpoints.GET_PENDING_DEAL_SLIPS}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );
  return response?.data;
};

export default function usePendingDealSlips({ Page, PageSize }: TPendingSlips) {
  return useQuery(
    [{ Page, PageSize }, "pendingDealSlips"],
    getPendingDealSlips,
    {
      refetchOnWindowFocus: false,
    }
  );
}
