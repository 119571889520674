import { useState, useEffect, SyntheticEvent } from "react";

// Router
import { NavLink, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { showModal } from "../../redux/actions/modal";

// UI Components
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";

import { BASE_URL } from "../../appConstants";
import { getTokenFromLocalStorage } from "../../helpers/localStorage";
import { formatMoney } from "../../helpers/formatter";
import { appInsights } from "../../config/appInsights";
import useIncomeInterestReport from "../../custom-hooks/useIncomeInterestReport";
import { RootState } from "../../redux/store";

const today = new Date().toISOString().slice(0, 10);
const lastMonth = new Date(new Date());
lastMonth.setDate(lastMonth.getDate() - 30);
const aMonthAgo = lastMonth.toISOString().slice(0, 10);

const IncomeInterestReport = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(10);

  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [date, setDate] = useState({
    StartDate: aMonthAgo,
    EndDate: today,
  });

  const [downloadLoading, setDownloadLoading] = useState(false);

  const { isAuthenticated, loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Income Interest Report - (IncomeInterestReport.tsx)",
      isLoggedIn: true,
    });
  }, []);

  //  Methods
  const modalHandler = (modalData: any, type: any) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (startDate && endDate) {
      queryParams = `?StartDate=${startDate}&EndDate=${endDate}`;
    }

    fetch(BASE_URL + `/eco/gl_report/csv${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        setDownloadLoading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                startDate && endDate
                  ? `IncomeInterestReport from ${startDate} to ${endDate}.csv`
                  : `Income_Interest_Report.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "IncomeInterestReport.jsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  const filterData = (e: SyntheticEvent) => {
    e.preventDefault();

    const diffTime =
      new Date(endDate).valueOf() - new Date(startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    }

    resetTable();
    setDate({
      StartDate: startDate,
      EndDate: endDate,
    });
  };

  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  const { data, status, error } = useIncomeInterestReport({
    StartDate: date.StartDate,
    EndDate: date.EndDate,
  });

  let RenderedComponent = null;

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Repayment Reports
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Repayment Reports..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              interest income reports, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (!data?.data?.data.data.length || !data?.data?.data.data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive ">
            <h4>Summary</h4>
            <table className="table mt-4">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Currency Code</th>
                  <th>Total Debit</th>
                  <th>Total Credit</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.data.sum.map((request: any, _id: number) => {
                  const { currencyCode, totalDebit, totalCredit, balance } =
                    request;
                  return (
                    <tr key={_id}>
                      <td>{currencyCode}</td>
                      <td>
                        {totalDebit ? formatMoney(totalDebit) : totalDebit}
                      </td>
                      <td>
                        {totalCredit ? formatMoney(totalCredit) : totalCredit}
                      </td>
                      <td>{balance ? formatMoney(balance) : balance}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <h4 className="mt-4">GL Details</h4>
            <table className="table mt-4 ">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>GL Name</th>
                  <th>GL Code</th>
                  <th>Currency Code</th>
                  <th>Total Debit</th>
                  <th>Total Credit</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.data?.data.map((request, _id) => {
                  const {
                    glName,
                    glCode,
                    currencyCode,
                    totalDebit,
                    totalCredit,
                    balance,
                  } = request;
                  return (
                    <tr key={_id}>
                      <td>{glName}</td>
                      <td>{glCode}</td>
                      <td>{currencyCode}</td>
                      <td>
                        {totalDebit ? formatMoney(totalDebit) : totalDebit}
                      </td>
                      <td>
                        {totalCredit ? formatMoney(totalCredit) : totalCredit}
                      </td>
                      <td>{balance ? formatMoney(balance) : balance}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Repayment Reports Header*/}
        <div className="block-header">
          <div className="block-header">
            <h3 className="page-title mb-4">Loan Income Interest Report</h3>
          </div>
          <div className="d-flex flex-row flex-wrap ">
            <form className="d-flex flex-row m-t-md" onSubmit={filterData}>
              <div
                className="d-flex flex-row flex-wrap justify-content-start"
                style={{ alignItems: "flex-end" }}
              >
                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">Start Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={aMonthAgo}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">End Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={today}
                    max={today}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <button
                  className="m-l-1 m-r-1 zoom-element btn login-btn"
                  type="submit"
                  value="submit"
                >
                  Search
                </button>
                {/* Start of download button */}
                <button
                  type="button"
                  className="btn advancly-white-btn  "
                  onClick={handleDownload}
                  disabled={status === "loading" || downloadLoading}
                >
                  <i className="fas fa-download m-r-1" />
                  Download {downloadLoading && <ButtonLoader />}
                </button>

                {/* end of download button */}
              </div>
            </form>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={count}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </div>
  );
};

export default IncomeInterestReport;
