import { Fragment, useEffect, useRef, useState } from "react";

import Alert from "../../../../NewComponents/Alert/Alert.component";
import ValidateEmail from "./Steps/ValidateEmail.component";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import CreateUserComponent from "./Steps/CreateUser.component";

export default function AddUser({ dataRecord, onCloseModal, rolesData }) {
  const [modifiedRolesData, setModifiedRolesData] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("validateEmail");
  const [validatedEmail, setValidatedEmail] = useState(null);

  const topOfPage = useRef(null);

  const scrollToTop = () => {
    topOfPage.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const renderContent = () => {
    switch (activeTab) {
      case "validateEmail":
        return (
          <ValidateEmail
            onCloseModal={onCloseModal}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setActiveTab={setActiveTab}
            setValidatedEmail={setValidatedEmail}
          />
        );
      case "createUser":
        return (
          <CreateUserComponent
            onCloseModal={onCloseModal}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setActiveTab={setActiveTab}
            dataRecord={dataRecord}
            modifiedRolesData={modifiedRolesData}
            scrollToTop={scrollToTop}
            validatedEmail={validatedEmail}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (rolesData && rolesData.length > 0) {
      const result = rolesData.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.id,
        };
      });

      setModifiedRolesData(result);
    }
  }, [rolesData]);

  return (
    <Fragment>
      <span ref={topOfPage}></span>
      {errorMessage && <Alert message={errorMessage} />}
      {successMessage && <Alert message={successMessage} type="success" />}
      <div className="alignToggleTabItems animated fadeInRight m-x-1 m-t-md">
        <ToggleTab
          text="Step One"
          id="validateEmail"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={false}
        />
        <ToggleTab
          text="Step Two"
          id="createUser"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={false}
        />
      </div>

      <div>{renderContent()}</div>
    </Fragment>
  );
}
