import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getRepaymentsApprovalLog = async (trans_ref: string) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_REPAYMENTS_APPROVAL_LOG}?repayment_maker_checker_reference=${trans_ref}`
  );
  return data;
};

export default function useRepaymentApprovalLog(trans_ref: string) {
  return useQuery(
    [trans_ref, "getRepaymentsApprovalLog"],
    getRepaymentsApprovalLog,
    {
      refetchOnWindowFocus: false,
    }
  );
}
