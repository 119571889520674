import { useEffect, useState } from "react";
// import { settingsStart, getSettingsSuccess, settingsFailure } from '../../../../../../../src/redux/actions/settings'

// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";

// Forms
import {
  LiquidationAccountColumns,
  reOrderTableData,
} from "../../../../../../redux/actions/loans";
import LiquidationChannelForm from "./LiquidationChannelView/LiquidationChannelForm";
import LiquidationChannelView from "./LiquidationChannelView/LiquidationChannelView";
import { appInsights } from "../../../../../../config/appInsights";

const LiquidationChannelSettings = (props: any) => {
  const [state, setState] = useState<any>({
    loading: false,
    liquidationChannelOptions: [],
    selectedLiquidationId: "",
    bankId: "",
    accountNumber: "",
    bankOptionsList: [],
    repayEdit: false,
    bankName: "",
    error: "",
    accountName: "",
    accountLoading: false,
    selectedLiquidationIdSearch: "",
    LiquidationChannelList: [],
    channelAccountsDetails: [],
    reOrderedTableData: [],
    loadingAccount: false,
  });

  useEffect(() => {
    getLiquidationChannels();
    getBankList();
  }, []);

  // Component Methods
  const editModeHandler = (target: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        bankId: "",
        accountNumber: "",
      };
    });
    let selectedForm = target.dataset["form"];

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: true,
      };
    });
  };

  const cancelUpdateHandler = (target: any) => {
    let selectedForm = target.dataset["form"];

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: false,
        [`${selectedForm}FormCopy`]: state[`${selectedForm}Form`],
        accountName: "",
      };
    });
  };

  const handleRepaySelect = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        repayEdit: false,
      };
    });
  };

  const handleOptionSelect = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // fetch liquidation channel list
  const getLiquidationChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.ALL_LIQUIDATION_CHANNELS)
      .then((response) => {
        let LiquidationChannelLists = [] as any[];
        if (response.data.length > 0) {
          LiquidationChannelLists = [...response.data];
        } else {
          LiquidationChannelLists = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const LiquidationChannelListOption = LiquidationChannelLists.map(
          (ecosystem, index) => {
            const { id, name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {name}
              </option>
            );
          }
        );

        setState((prev: any) => {
          return {
            ...prev,
            liquidationChannelOptions: LiquidationChannelListOption,
            LiquidationChannelList: LiquidationChannelLists,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LiquidationChannelSettings.js",
          },
        });
      });
  };

  // fetch accounts for a specific disburse channel
  const getLiquidationChannelAccounts = () => {
    setState((prev: any) => {
      return {
        ...prev,
        loading: true,
        channelAccountsDetails: [],
        reOrderedTableData: [],
      };
    });
    ajaxMethods
      .get(
        ajaxEndpoints.ALL_LIQUIDATION_CHANNELS +
          `/${state.selectedLiquidationIdSearch}/accounts`
      )
      .then((response) => {
        setState((prev: any) => ({ ...prev, loading: false }));

        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            setState((prev: any) => ({
              ...prev,
              channelAccountsDetails: response.data,
            }));
            const reOrderedTableData = reOrderTableData(
              LiquidationAccountColumns,
              response.data
            );

            setState((prev: any) => ({
              ...prev,
              reOrderedTableData: reOrderedTableData,
            }));
          } else {
            setState((prev: any) => ({ ...prev, channelAccountsDetails: [] }));
          }
        } else {
          setState((prev: any) => ({ ...prev, channelAccountsDetails: [] }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LiquidationChannelSettings.js",
          },
        });
      });
  };

  //get bank list
  const getBankList = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let bankList = response.data;
          const bankOptionsList = bankList.map((data: any, index: number) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            );
          });
          setState((prev: any) => ({
            ...prev,
            bankOptionsList: bankOptionsList,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LiquidationChannelSettings.js",
          },
        });
      });
  };
  //create disbursement channel accounts
  const onCreateLiquidationChannelAccount = () => {
    setState({ loadingAccount: true });
    const { selectedLiquidationId, bankId, accountNumber, accountName } = state;
    const Repay = {
      channel_id: Number(selectedLiquidationId),
      bank_id: Number(bankId),
      account_number: accountNumber,
      account_name: accountName,
    };

    ajaxMethods
      .post(ajaxEndpoints.ADD_LIQUIDATION_CHANNEL_ACCOUNTS, Repay)
      .then((response) => {
        setState((prev: any) => ({ ...prev, loadingAccount: false }));
        let message = "";
        if (
          response.data.status_code === 200 ||
          response.status_code === 200 ||
          response.data.status_code === 201 ||
          response.status_code === 201
        ) {
          message = response.data.message;
          props.onSetToast("success", message);
          setState({ repayEdit: false });
        } else if (response.status === 400) {
          message = response.data.message;
          props.onSetToast("error", message);
        } else {
          props.onSetToast("warning", "Unable to connect to Advancly services");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LiquidationChannelSettings.js",
          },
        });
        props.onSetToast("warning", "Unable to connect to Advancly services");
      });
  };
  // validate account number
  const onAccountNumberInput = (e: any) => {
    setState({ error: false, accountName: "" });
    let number = e.target.value;
    if (state.bankId === "") {
      setState({ error: `Please select a bank` });
      return;
    }

    setState((prev: any) => ({ ...prev, accountNumber: number }));

    getBankCode();

    if (number.length === 10 && !state.accountLoading) {
      setState({ accountLoading: true });

      //validate account number and bank name
      let data = {
        bank_code: state.bankCode,
        bank_account_num: number,
      };
      validateAccount(data);
    }
  };
  // fetch product category list
  const getBankCode = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${state.bankId}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];
          setState({ bankCode: res.code });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LiquidationChannelSettings.js",
          },
        });
      });
  };
  const validateAccount = (data: any) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setState((prev: any) => {
            return {
              ...prev,
              accountName: response.data.bank_account_name,
              accountLoading: false,
            };
          });
        } else if (response.status === 400) {
          setState((prev: any) => {
            return {
              ...prev,
              error: response.data.message,
              accountLoading: false,
            };
          });
        }
        if (response.status >= 500) {
          setState((prev: any) => {
            return {
              ...prev,
              error: "Unable to connect to Advancly services.",
              accountLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LiquidationChannelSettings.js",
          },
        });
      });
  };

  const handleChange = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        error: false,
      };
    });
  };

  return (
    <div className="GlobalSettings col-sm-12 mt-30">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <LiquidationChannelForm
            inputs={state?.systemFormCopy}
            onEditMode={editModeHandler}
            onCancelUpdate={cancelUpdateHandler}
            onInputChange={handleChange}
            repayEdit={state.repayEdit}
            liquidationChannelOptions={state.liquidationChannelOptions}
            selectedLiquidationId={state.selectedLiquidationId}
            bankId={state.bankId}
            error={state.error}
            accountLoading={state.accountLoading}
            accountName={state.accountName}
            accountNumber={state.accountNumber}
            bankOptionsList={state.bankOptionsList}
            handleOptionSelect={handleOptionSelect}
            handleRepaySelect={handleRepaySelect}
            onCreateLiquidationChannelAccount={
              onCreateLiquidationChannelAccount
            }
            onAccountNumberInput={onAccountNumberInput}
            loadingAccount={state.loadingAccount}
          />
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          <LiquidationChannelView
            selectedLiquidationIdSearch={state.selectedLiquidationIdSearch}
            handleOptionSelect={handleOptionSelect}
            LiquidationChannelLists={state.LiquidationChannelList}
            channelAccountsDetails={state.channelAccountsDetails}
            reOrderedTableData={state.reOrderedTableData}
            loading={state.loading}
            onSearch={getLiquidationChannelAccounts}
          />
        </div>
      </div>
    </div>
  );
};

export default LiquidationChannelSettings;
