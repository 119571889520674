import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { getData } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
// import file from "../../../assets/UC-f1898e00-8f3d-40c6-b187-55b032825c08.pdf";
import { showModal } from "../../../redux/actions/modal";

import { dealSlipStatus } from "../constants";

import styles from "./viewDealSlip.module.scss";
import { appInsights } from "../../../config/appInsights";

const ViewDealSlip = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  const handleDownloadSignedSlip = async () => {
    setIsDownloading(true);
    try {
      const response = await getData(
        `${ajaxEndpoints.DOCUMENT_URL}?file_path=${location?.state?.document?.signed_slip_upload_path}`
      );

      if (response?.data?.status_code === 200) {
        const element = document.createElement("a");
        element.href = response?.data?.data?.file_url;
        element.download = response?.data?.data?.file_name;
        element.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.data?.message,
            },
          })
        );
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "viewDealSlip.jsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.data?.message,
          },
        })
      );
    }
    setIsDownloading(false);
  };

  const DownloadType = {
    Pdf: "PDF",
    Doc: "DOC",
  };

  const prepareDownload = (base64String, fileName) => {
    const contentType = "application/octet-stream";
    const linkSource = `data:${contentType};base64,${base64String}`;

    const element = document.createElement("a");
    element.href = linkSource;
    element.download = fileName;
    element.click();
    setIsDownloading(false);
  };

  const handleDownloadDealSlip = async (downloadType) => {
    setIsDownloading(true);

    const loanId = Number(location?.state?.loan_id);

    try {
      let response = null;

      if (location?.state?.document?.document_upload_type === "Automated") {
        if (downloadType === DownloadType.Doc) {
          response = await getData(
            `${ajaxEndpoints.DOWNLOA_GENERATED_SLIP}/${loanId}?formatType=docx`
          );
        } else {
          response = await getData(
            `${ajaxEndpoints.DOWNLOA_GENERATED_SLIP}/${loanId}?formatType=pdf`
          );
        }
      } else {
        response = await getData(
          `${ajaxEndpoints.DOWNLOAD_GENERATED_SLIP_AGGREGATOR}/${loanId}`
        );
      }

      if (response?.data?.status_code === 200) {
        const { file_name, base64_string } = response?.data?.data;
        prepareDownload(base64_string, file_name);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "viewDealSlip.jsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsDownloading(false);
  };

  const handleApprove = () => {
    dispatch(
      showModal({
        modalIdentifier: "dealslipapprovereject",
        dataRecord: {
          modalTitle: "Approve",
          approvalDecision: "Accepted",
          loanId: location?.state?.loan_id,
        },
      })
    );
  };

  const handleDecline = () => {
    dispatch(
      showModal({
        modalIdentifier: "dealslipapprovereject",
        dataRecord: {
          modalTitle: "Decline",
          approvalDecision: "Rejected",
          loanId: location?.state?.loan_id,
        },
      })
    );
  };

  const handleViewComments = () => {
    dispatch(
      showModal({
        modalIdentifier: "dealslipcomments",
        dataRecord: {
          comments: location?.state?.comments,
          dealSlipId: location?.state?.id,
        },
      })
    );
  };

  return (
    <div className="animated fadeInRight">
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="d-flex align-items-center page-title">
            <BackButtonComponent />
            Loan REF: {location?.state?.loan_ref}
          </h3>
          <div>
            <button
              className="btn advancly-white-btn  mr-2"
              onClick={handleViewComments}
            >
              Comments
            </button>
            <button
              className="btn advancly-red-btn mr-2"
              onClick={handleDecline}
              disabled={
                location?.state?.document.status ===
                dealSlipStatus?.PENDINGAGGREGATOR
              }
            >
              Decline
            </button>
            <button
              type="button"
              className="btn advancly-green-btn transition-3d-hover"
              onClick={handleApprove}
              disabled={
                location?.state?.document.status ===
                dealSlipStatus?.PENDINGAGGREGATOR
              }
            >
              Approve
            </button>
          </div>
        </div>

        <div className={styles.previewContainer}>
          {location?.state?.document?.document_upload_type === "Automated" &&
            !location?.state?.document?.signed_slip_upload_path && (
              <div className={`dropdown ${styles.dropdownButton}`}>
                <button
                  className="btn advancly-btn create zoom-element dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {isDownloading ? "Downloading..." : "Download"}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <span
                    className="dropdown-item cursor-pointer"
                    onClick={() => handleDownloadDealSlip(DownloadType.Pdf)}
                  >
                    PDF
                  </span>
                  <span
                    className="dropdown-item cursor-pointer"
                    onClick={() => handleDownloadDealSlip(DownloadType.Doc)}
                  >
                    DOCX
                  </span>
                </div>
              </div>
            )}

          {(location?.state?.document?.document_upload_type === "None" ||
            location?.state?.document?.document_upload_type === "Manual") &&
            !location?.state?.document?.signed_slip_upload_path && (
              <button
                className={`create zoom-element btn login-btn ${styles.download}`}
                onClick={() => handleDownloadDealSlip()}
              >
                {isDownloading ? "Downloading..." : "Download"}
              </button>
            )}

          {(location?.state?.document?.document_upload_type === "None" ||
            location?.state?.document?.document_upload_type === "Manual" ||
            location?.state?.document?.document_upload_type === "Automated") &&
            location?.state?.document?.signed_slip_upload_path && (
              <button
                className={`create zoom-element btn login-btn ${styles.download}`}
                onClick={() => handleDownloadSignedSlip()}
              >
                {isDownloading ? "Downloading..." : "Download"}
              </button>
            )}
        </div>
        {/* 
        {isDownloading ? (
          <Loader />
        ) : (
          <DocViewer
            documents={[
              {
                uri: "https://advanclystorage.blob.core.windows.net/advancly/documents%2Fadvancly_dealslip%2Fsomething_231120220647307408.pdf",
              },
            ]}
            pluginRenderers={DocViewerRenderers}
            config={{
              noRenderer: {
                overrideComponent: ({ document, fileName }) => {
                  const fileText = fileName || document?.fileType || "";

                  if (fileText) {
                    return <div>no renderer for {fileText}</div>;
                  }
                  return <div>no renderer</div>;
                },
              },
              loadingRenderer: {
                overrideComponent: ({ document, fileName }) => {
                  const fileText = fileName || document?.fileType || "";
                  if (fileText) {
                    return <div>loading ({fileText})</div>;
                  }
                  return <div>loading</div>;
                },
              },
              csvDelimiter: ",",
              pdfZoom: {
                defaultZoom: 1.1,
                zoomJump: 0.2,
              },
            }}
            language="pl"
          />
        )} */}
      </div>
    </div>
  );
};

export default ViewDealSlip;
