import React from "react";

const DefaultProductSettings = (props: any) => {
  const {
    statusOption,
    allproductStatusChoices,
    ProductCatergorySectors,
    productCategoryId,
  } = props;
  const ProductCatergorySectorsOptions = ProductCatergorySectors.map(
    (ecosystem: any, index: number) => {
      const { product_category_id, productCategoryName } = ecosystem;
      return (
        <option key={index} value={product_category_id}>
          {productCategoryName}
        </option>
      );
    }
  );

  return (
    <div className="card">
      <div className="body text-center">
        <h3 className="m-t-0">
          <i className="adv-icon fas fa-cogs" /> Default Product Settings
        </h3>
        <p className="settings-block text-muted">
          Advancly global product settings
        </p>
        <div className="grid__full m-b-1">
          <div className="p-l-0 grid__padding">
            <label className="label-txt">Product Category Name</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="productCategoryId"
                value={productCategoryId}
                onChange={(e) => props.handleOptionSelect(e)}
              >
                <option selected></option>
                {ProductCatergorySectorsOptions}
              </select>
              <i className="adv-aggregator-icon fas fa-building"></i>
            </div>
            <div className="errorHandler form-group text-left grid__full">
              <span id="email-msg" className="hidden error-element"></span>
            </div>
          </div>
        </div>

        <div className="grid__full settings-block">
          <div className="p-l-0 grid__padding">
            <label className="label-txt">Enable</label>
            <div className="form-group">
              <select
                className="round user__form form-control"
                value={statusOption}
                name="statusOption"
                onChange={(e) => props.handleOptionSelect(e)}
              >
                <option selected></option>
                {allproductStatusChoices}
              </select>
            </div>
          </div>
        </div>

        <div className="grid__full">
          <button
            type="button"
            className="zoom-element btn login-btn"
            data-form="defaultProduct"
            onClick={(e) => props.handleCreateDefaultProduct(e)}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default DefaultProductSettings;
