import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  borrower_id: number;
};

const getAllABorrowersLoansRepayments = async ({ borrower_id }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.ALL_REPAYMENTS_TIED_TO_A_BORROWER}?borrower_id=${borrower_id}`
  );
  return data;
};

export default function useAllAborrowersLoansRepayment({
  borrower_id,
}: TQuery) {
  return useQuery(
    [{ borrower_id }, "getAllABorrowersLoansRepayments"],
    getAllABorrowersLoansRepayments
  );
}
