import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { formatMoney } from "../../../helpers/formatter";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import Loader from "../../../NewComponents/Loader/Loader.component";
import { appInsights } from "../../../config/appInsights";
import { getData } from "../../../newApis/dotNetApiMethods";
import { showModal } from "../../../redux/actions/modal";

interface stateType {
  data: any;
}

interface IPreviewOfflineInvestment {}
const PreviewOfflineInvestment: React.FC<IPreviewOfflineInvestment> = () => {
  const location = useLocation();
  const { data } = location?.state as stateType;

  const [isDownloading, setIsDownloading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Preview Offline Investments - (PreviewOfflineInvestment.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const prepareDownloadLink = (content: string, fileName: string) => {
    // const appendSource = fileName.endsWith(".pdf")
    //   ? "application/pdf"
    //   : fileName.endsWith(".png")
    //   ? "image/png"
    //   : fileName.endsWith(".jpg")
    //   ? "image/jpg"
    //   : fileName.endsWith(".jpeg")
    //   ? "image/jpeg"
    //   : "application/pdf";

    const appendSource = "application/octet-stream";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFunderProofOfInvestment = async (id: number) => {
    setIsDownloading(true);
    try {
      const response = await getData(
        `${ajaxEndpoints.GET_FUNDER_INVESTMENT_PROOF_OF_PAYMENT}/${id}`
      );

      if (response?.data?.status_code === 200) {
        prepareDownloadLink(
          response?.data?.data?.base64_string,
          response?.data?.data?.file_name
        );
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewOfflineInvestment.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsDownloading(false);
  };

  const handleDownloadDocument = async (data: any) => {
    if (data.base64_file_string) {
      prepareDownloadLink(
        data?.base64_file_string,
        data?.filename_with_extension
      );
    } else {
      if (data.funderProofOfPaymentDocumentId) {
        await downloadFunderProofOfInvestment(
          data.funderProofOfPaymentDocumentId
        );
      }
    }
  };

  return (
    <Fragment>
      <div className="animated fadeInRight mt-2">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> {data?.firstname} {data?.lastname}
        </h3>
        <div className="row mt-4">
          <div className="col-8">
            <h3 className="page-subtitle">Investment Details</h3>
            <hr
              style={{
                width: "63%",
                marginLeft: 0,
                marginBottom: "30",
              }}
            ></hr>
            <div className="col-md-12 mb-3">
              <h6>Investment Name</h6>
              <p className="page-subtitle">
                {data?.investment_product_name || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Currency</h6>
              <p className="page-subtitle">{data?.currency || "N/A"}</p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Amount Invested</h6>
              <p className="page-subtitle">
                {formatMoney(data?.principal_amount, data?.currency) || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Interest Amount</h6>
              <p className="page-subtitle">
                {formatMoney(data?.interest_amount, data?.currency) || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Annual Interest Rate</h6>
              <p className="page-subtitle">
                {`${data?.interest_rate}%` || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Interest Repayment Type</h6>
              <p className="page-subtitle">
                {data?.interest_repayment_type || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Effective Date</h6>
              <p className="page-subtitle">
                {data?.effective_date?.substring(0, 10) || "N/A"}
              </p>
            </div>
            <div className="col-md-6 mb-3">
              <h6>Maturity Date</h6>
              <p className="page-subtitle">
                {data?.maturity_date?.substring(0, 10) || "N/A"}
              </p>
            </div>
            <div className="col-md-12 mb-3">
              {data?.isRollover ? (
                <span style={{ fontWeight: "bolder", color: "red" }}>
                  Roll Over Investment
                </span>
              ) : (
                <button
                  className={`${
                    isDownloading ? "disabled" : ""
                  } btn advancly-btn btn-sm transition-3d-hover`}
                  type="button"
                  onClick={() => handleDownloadDocument(data)}
                >
                  Download Document {isDownloading && <Loader />}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PreviewOfflineInvestment;
