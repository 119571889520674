import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  pageNumber: number;
  size: number;
  aggregatorId: string;
  LoanRef?: string;
  MinDateDue?: string;
  PastDue?: string;
  FromDateDue?: string;
  ToDateDue?: string;
};

const getAllDueRepayment = async ({
  pageNumber,
  size,
  aggregatorId,
  LoanRef,
  MinDateDue,
  PastDue,
  FromDateDue,
  ToDateDue,
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}&AggregatorId=${aggregatorId}`;
  if (PastDue) {
    queryParams = `${queryParams}&PastDue=${PastDue}`;
  }
  if (MinDateDue) {
    queryParams = `${queryParams}&MinDateDue=${MinDateDue}`;
  }
  if (LoanRef) {
    queryParams = `${queryParams}&LoanRef=${LoanRef}`;
  }
  if (ToDateDue && FromDateDue) {
    queryParams = `${queryParams}&ToDateDue=${ToDateDue}&FromDateDue=${FromDateDue}`;
  }

  const data = await getData(
    `${ajaxEndpoints.GET_ALL_DUE_LOAN_REPAYMENT}?${queryParams}`,
  );
  return data;
};

export default function useAllDueRepayment({
  pageNumber,
  size,
  aggregatorId,
  LoanRef,
  MinDateDue,
  PastDue,
  FromDateDue,
  ToDateDue,
}: TQuery) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        aggregatorId,
        LoanRef,
        MinDateDue,
        PastDue,
        FromDateDue,
        ToDateDue,
      },
      "getAllDueRepayment",
    ],
    getAllDueRepayment,
    {
      // enabled: aggregatorId ? true : false,
      refetchOnWindowFocus: false,
    },
  );
}
