import React, { ChangeEvent, useState } from "react";
import useBankAccountDetails from "../../../../../../custom-hooks/useBankAccountDetails";
import CustomSelectDropdown from "../../../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";

export default function BankStatementAnalysis({ dataRecord }: any) {
  const [bankStatementIdAnalysis, setBankStatementIdAnalysis] = useState<
    number | null
  >();
  const { data: bankAccountDetails } = useBankAccountDetails(
    dataRecord?.borrower_details?.customer_id
  );
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value } = e.target;
    setBankStatementIdAnalysis(Number(value));
  };
  return (
    <div>
      <div
        style={{ width: "300px", marginBottom: "10px" }}
        className="mr-3 p-2"
      >
        <CustomSelectDropdown
          label=""
          onChange={(e) => handleChange(e)}
          name="statementId"
          className="round user__form form-control"
        >
          <option value="">Select Bank Account</option>
          {bankAccountDetails &&
            bankAccountDetails.length > 0 &&
            bankAccountDetails?.map(
              (
                {
                  accountNo,
                  accountName,
                  bankName,
                  currency,
                  bankStatementId,
                }: {
                  accountNo: string;
                  accountName: string;
                  bankName: string;
                  currency: string;
                  bankStatementId: number;
                },
                _id: number
              ) => {
                return (
                  <option value={bankStatementId} key={_id}>
                    {`${accountNo}/${accountName}/${bankName}/${currency}`}
                  </option>
                );
              }
            )}
        </CustomSelectDropdown>
      </div>
      <div
        className=""
        style={{
          marginTop: "30px",
        }}
      >
        {bankStatementIdAnalysis ? (
          <iframe
            src={`
https://insights.advancly.com/public/dashboard/8cc77c1a-d21c-4dab-b5af-51c82cbe52c0?bank_statement_id=${bankStatementIdAnalysis}#hide_parameters=
bank_statement_i
d,ids&titled=false`}
            title="bankStatementAnalysis"
            width="100%"
            height="900px"
          ></iframe>
        ) : (
          <p className="d-flex justify-content-center align-items-center mt-4">
            {" "}
            Please Select Account on the Dropdown For Analysis
          </p>
        )}
      </div>
    </div>
  );
}
