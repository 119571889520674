import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    showToast: false,
    toastMessage: '',
    type: ''
};



export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.SET_TOAST:
            return {
                ...state,
                showToast: true,
                type: action.payload.type,
                toastMessage: action.payload.message
            }
        
        case actionTypes.CLEAR_TOAST:
            return {
                ...state,
                showToast: false,
                type: '',
                toastMessage: ''
        }

        default:
            return state;
    }
}

export default reducer;