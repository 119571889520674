const Loader = () => {
  return (
    <div>
      <div id="temp-loader" style={{ zIndex: 100 }}>
        <div id="temp-icon" className="normal-2x fadeInLeft tfx-loader">
          <div className="tfx-icon" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
