import React from "react";
import CountryLiquidationForm from "./Views/CountryLiquidationForm";
import CountryLiquidationView from "./Views/CountryLiquidationView";

export default function CountryLiquidationMapping() {
  return (
    <div className=" mt-30">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <CountryLiquidationForm />
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          <CountryLiquidationView />
        </div>
      </div>
    </div>
  );
}
