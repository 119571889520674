import React, { useState } from "react";

import { formatMoneyNoDecimal } from "../../../../helpers/formatter";
import LoanDashboardGraphs from "./LoanDashboardGraphs.component";
import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader from "../../../../UI/Loaders/Loaders";

import styles from "./aggregatorStats.module.scss";

const AggregatorFinancialStats = ({
  currentCurrency,
  transactionsOverview,
  trasactionOverviewStatus,
  loanCount,
  loanCountStatus,
  totalOutstandingLoans,
  totalOutstandingStatus,
  aggregatorCurrencies,
  setCurrentCurrency,
  isLoadingOutstandingLoans,
  isLoadingTransactions,
  isLoadingLoanCount,
  isLoadingCurrencies,
  aggregatorDetails,
}: {
  currentCurrency: any;
  transactionsOverview: {
    total_disbursement: number;
    currency: "NGN" | "USD" | "KES" | "#" | "$" | "KSh";
    total_repayment: number;
  };
  trasactionOverviewStatus: string;
  loanCount: number;
  loanCountStatus: string;
  totalOutstandingLoans: {
    totalAmount: number;
    currency: "NGN" | "USD" | "KES" | "#" | "$" | "KSh";
  };
  totalOutstandingStatus: string;
  aggregatorCurrencies: string[];
  setCurrentCurrency: React.Dispatch<any>;
  isLoadingOutstandingLoans: boolean;
  isLoadingTransactions: boolean;
  isLoadingLoanCount: boolean;
  isLoadingCurrencies: boolean;
  aggregatorDetails: any;
}) => {
  return (
    <div className={styles.AggregatorStats}>
      {/* DASHBOARD LOANS */}
      <div className={styles.AggregatorCurrencyContainer}>
        {aggregatorCurrencies && aggregatorCurrencies.length > 0 && (
          <CustomSelectDropdown
            onChange={(e) => {
              setCurrentCurrency(e.target.value);
            }}
            value={currentCurrency}
            className={styles.AggregatorCurrency}
          >
            {/*<option value="">Select Currency</option>*/}
            {aggregatorCurrencies.map((_currency) => (
              <option key={_currency} value={_currency}>
                {_currency}
              </option>
            ))}
          </CustomSelectDropdown>
        )}
      </div>

      <div className="row clearfix mt-30 grid__padding">
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">&#8358;14,500,000</h3> */}
              {isLoadingTransactions ? (
                <ButtonLoader />
              ) : (
                <h3 className="m-t-0">
                  {transactionsOverview
                    ? (formatMoneyNoDecimal(
                        Math.trunc(transactionsOverview?.total_disbursement),
                        transactionsOverview?.currency,
                      ) ?? 0)
                    : 0}
                </h3>
              )}

              <p className="text-muted">Total Amount Disbursed</p>
              {/* <small>(Amount)</small> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">{(count) ? count : 'unavailable'}</h3> */}
              {isLoadingTransactions ? (
                <ButtonLoader />
              ) : (
                <h3 className="m-t-0">
                  {transactionsOverview
                    ? (formatMoneyNoDecimal(
                        transactionsOverview?.total_repayment,
                        transactionsOverview?.currency,
                      ) ?? 0)
                    : 0}
                </h3>
              )}

              <p className="text-muted">Total Repayment</p>
              {/* <small>(Number)</small> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">75,273</h3> */}
              {isLoadingOutstandingLoans ? (
                <ButtonLoader />
              ) : (
                <h3 className="m-t-0">
                  {totalOutstandingLoans
                    ? (formatMoneyNoDecimal(
                        totalOutstandingLoans?.totalAmount,
                        totalOutstandingLoans?.currency,
                      ) ?? 0)
                    : 0}
                </h3>
              )}

              <p className="text-muted">Total Outstanding Loans</p>
              {/* <small>&nbsp;</small> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">3%</h3> */}
              {isLoadingLoanCount ? (
                <ButtonLoader />
              ) : (
                <h3 className="m-t-0">{loanCount >= 1 ? loanCount : 0}</h3>
              )}

              <p className="text-muted">Loan Counts</p>
              {/* <small>&nbsp;</small> */}
            </div>
          </div>
        </div>
      </div>

      {aggregatorDetails.aggregator_id && (
        <LoanDashboardGraphs
          currentCurrency={currentCurrency}
          aggregatorDetails={aggregatorDetails}
        />
      )}

      {/* DASHBOARD LOANS */}
    </div>
  );
};

export default AggregatorFinancialStats;
