import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IPaginatedResponse, IResponse } from "../interfaces/response";

type TProduct = {
  account_number: string;
  aggregator: string;
  aggregator_id: number;
  allow_batch_disbursement: boolean;
  amortization_type: number;
  bank: string;
  bank_account_name: string;
  bank_id: number;
  banking_tenant: string;
  core_banking_product_id: number;
  core_banking_product_name: number;
  currency: string;
  disbursement_channel_account_id: number;
  disbursement_channel_id: number;
  disbursement_type_id: number;
  disbursementchannel: string;
  disbursementtype: string;
  funder_amount: number;
  funder_id: number;
  interest_rate: number;
  loan_calculation_method: number;
  loan_type: string;
  loan_type_id: number;
  loancalculationmethod: string;
  maximum_amount: number;
  maximum_running_loan: string;
  maximum_tenor: number;
  mode: string;
  modified_ate: string;
  moratorium: string;
  moratorium_type: number;
  product_category_id: number;
  product_code: string;
  product_id: number;
  product_name: string;
  productcategory: string;
  pub_date: string;
  reference: string;
  repay_principal_at_end_of_tenure: boolean;
  repayer_type_id: number;
  repayertype: string;
  repayment_account_name: string;
  repayment_account_number: string;
  repayment_bank_id: number;
  repayment_channel_account_id: number;
  repayment_channel_id: number;
  repayment_type: string;
  repaymentchannel: string;
  require_aggregator_approval: boolean;
  require_bvn_verification: boolean;
  require_deal_slip: boolean;
  sf_model_id: number;
  sf_model_type_id: number;
  sf_model_type_name: string;
  use_okra: boolean;
  use_paystack: boolean;
};

const getProductsToManage = async () => {
  const { data } = await getData(ajaxEndpoints.PRODUCTS_PENDING_APPROVAL);
  return data;
};

export default function useManageProductsData() {
  return useQuery<IPaginatedResponse<TProduct[]>>(
    "getProductsToManage",
    getProductsToManage
  );
}
