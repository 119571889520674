import React from "react";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import styles from "./Pagination.module.scss";

const Paginator = ({ size, page, count, changeCurrentPage }) => {
  return (
    <div className={styles.pagination}>
      <Pagination
        currentPage={page}
        totalSize={count}
        sizePerPage={size}
        changeCurrentPage={changeCurrentPage}
        numberOfPagesNextToActivePage={2}
        theme="bootstrap"
      />
    </div>
  );
};

export default Paginator;
