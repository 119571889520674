import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type WalletSignatories = {
  email: string;
  levelNo: number;
  name: string;
};

export const getAggregatorWalletSignatories = async (id: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.AGGREGATOR_WALLET_SIGNATORIES}?aggregatorId=${id}`
  );
  return data;
};

export default function useAggregatorWalletSignatories(id: number) {
  return useQuery<IResponse<WalletSignatories[]>, any>(
    [id, "allAggregatorWalletSignatories"],
    getAggregatorWalletSignatories,
    {
      refetchOnWindowFocus: false,
    }
  );
}
