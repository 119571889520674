import { useState, useEffect } from "react";
// Router
import { useHistory } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";

// import { rolesDataSummary } from "./../../models/roles-data";
import Tabs from "../Tabs/Tabs";
import ManageProducts from "./ManageProducts/ManageProducts";
import ManageAggregatorSettings from "./ManageAggregatorSettings/ManageAggregatorSettings";
import ManageGlobalSettings from "./ManageGlobalSettings/ManageGlobalSettings";
import ManageLocalLiquidationSettings from "./ManageLocalLiquidationSettings/ManageLocalLiquidationSettings";
import { appInsights } from "../../config/appInsights";
import ManageChangeSignatoryContainer from "./ManageChangeApproval/ManageChangeSignatoryContainer";
import { RootState } from "../../redux/store";

const ManageApproval = () => {
  const [activeTab, setActiveTab] = useState("products");

  const history = useHistory();

  const { isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const tabs = [
    {
      name: "Manage Products ",
      id: "products",
      icon: "fas fa-users",
      identifier: "approvalTab",
    },
    {
      name: "Aggregator Settings",
      id: "settings",
      icon: "fas fa-users-cog",
      identifier: "approvalTab",
    },
    {
      name: "Global Settings",
      id: "global-settings",
      icon: "fas fa-globe",
      identifier: "approvalTab",
    },
    {
      name: "Local Liquidation Settings",
      id: "liquidation-settings",
      icon: "fas fa-money",
      identifier: "approvalTab",
    },
    {
      name: "Signatory Settings",
      id: "signatory-settings",
      icon: "fas fa-signature",
      identifier: "approvalTab",
    },
  ];

  // Life Cycle Hooks
  useEffect(() => {
    // If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Setup Approvals - (ManageApproval.jsx)",
      isLoggedIn: true,
    });
  }, []);

  //JSX Template
  return (
    <div className="ManageRoles datatable-wrapper">
      <div className="table-view">
        {/*User & roles Stats*/}
        <div className="row grid__padding animated fadeInRight">
          <div className="col-lg-12 col-md-12 col-sm-12 dashboard-title flex__row">
            <h3 className="page-title">Manage Approvals</h3>
          </div>
        </div>

        <div className="row m-x-1 m-t-md">
          <Tabs onContentToggle={(e: any) => setActiveTab(e)} tabs={tabs} />
        </div>

        {/*Datatables*/}
        {(() => {
          switch (activeTab) {
            case "products":
              return <ManageProducts />;
            case "settings":
              return <ManageAggregatorSettings />;
            case "global-settings":
              return <ManageGlobalSettings />;
            case "liquidation-settings":
              return <ManageLocalLiquidationSettings />;
            case "signatory-settings":
              return <ManageChangeSignatoryContainer />;

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default ManageApproval;
