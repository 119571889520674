import { useState } from "react";
import { Link } from "react-router-dom";
import Paginator from "../../Pagination/Pagination.component";
import { Loader } from "../../../UI/Loaders/Loaders";
import usePendingApprovalInvestors from "../../../custom-hooks/usePendingApprovalInvestors";
import CustomSelectDropdown from "../../../NewComponents/TypedComponents/CustomHTMLElements/CustomSelectDropdown";

export default function InvestorRequests() {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");

  const { data, status, error } = usePendingApprovalInvestors();

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Investor Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading pending investor requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investor requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if ((data && data.length === 0) || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no investor
              activation requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Business Name</th>
                  <th>Contact Email</th>
                  <th>Phone Number</th>
                  <th>Investor Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data
                    .filter(
                      (eachData: any) =>
                        (eachData.first_name &&
                          eachData.first_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.last_name &&
                          eachData.last_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.email &&
                          eachData.email
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.phone_number &&
                          eachData.phone_number
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toString().toLowerCase())) ||
                        (eachData.email &&
                          eachData.email
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.biz_name &&
                          eachData.biz_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()))
                    )
                    .slice(
                      (pageNumber - 1) * size,
                      size * (pageNumber - 1) + size
                    )
                    .map((request: any, _id: number) => {
                      const {
                        first_name,
                        last_name,
                        phone_number,
                        email,
                        biz_name,
                        user_id,
                        funder_type_id,
                      } = request;
                      return (
                        <tr key={_id}>
                          <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                          <td>
                            {first_name} {last_name}
                          </td>
                          <td>{biz_name || "N/A"}</td>
                          <td>{email}</td>
                          <td>{phone_number}</td>
                          <td>
                            {funder_type_id === 1
                              ? "Individual"
                              : funder_type_id === 2
                              ? "Corporate"
                              : ""}
                          </td>

                          <td>
                            <Link
                              to={{
                                pathname: `/approval-requests/${user_id}/investor`,
                                state: {
                                  data: request,
                                },
                              }}
                              className="color-blue"
                            >
                              Review Request
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div>
          {status === "success" && (
            <span>
              You have{" "}
              <span className="font-weight-bold">
                {data?.length ? data?.length : "No"} pending
              </span>{" "}
              requests
            </span>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-end align-items-center">
          <div className="input-group mr-3 mt-3" style={{ maxWidth: "200px" }}>
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div style={{ width: "150px", marginTop: "5px" }}>
            <CustomSelectDropdown
              onChange={(e: any) => {
                setSize(e.target.value);
              }}
              value={size}
            >
              <option value={10000}>All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>
      </div>
      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}

      <Paginator
        size={size}
        page={pageNumber}
        count={data ? data.length : 0}
        changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
      />
    </div>
  );
}
