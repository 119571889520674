import React, { FunctionComponent } from "react";

interface RequestsInfoProps {
  count?: number;
}

const RequestsInfo: FunctionComponent<RequestsInfoProps> = ({ count }) => (
  <div>
    <span>
      You have <span className="font-weight-bold">{count || "No"} pending</span>{" "}
      requests
    </span>
  </div>
);

export default RequestsInfo;
