import React from "react";
import styles from "../CreditBureau.module.scss";

const CreditBureauTableSummaryPerformance = ({ summaryOfPerformance }: any) => {
  return (
    <div>
      <h5 className={` ${styles.creditBureauHeading}`}>
        Summary Of Performance
      </h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Institution Name
            </th>
            <th className="th-td-style th-style" scope="col">
              Facilities Count{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Performing Facilities
            </th>
            <th className="th-td-style th-style" scope="col">
              Non-Performing Facilities
            </th>
            <th className="th-td-style th-style" scope="col">
              Approved Amount
            </th>
            <th className="th-td-style th-style" scope="col">
              Account Balance
            </th>
            <th className="th-td-style th-style" scope="col">
              OverDue Amount
            </th>
            <th className="th-td-style th-style" scope="col">
              Dishonored Checks
            </th>
          </tr>
        </thead>
        <tbody>
          {summaryOfPerformance?.map((sumperformance: any, index: number) => {
            const {
              accounT_BALANCE,
              approveD_AMOUNT,
              dishonoreD_CHEQUES_COUNT,
              facilitieS_COUNT,
              institutioN_NAME,
              nonperforminG_FACILITY,
              overduE_AMOUNT,
              performinG_FACILITY,
            } = sumperformance;
            return (
              <tr key={index}>
                <td className="th-td-style">{institutioN_NAME}</td>
                <td className="th-td-style">{facilitieS_COUNT}</td>
                <td className="th-td-style">{performinG_FACILITY}</td>
                <td className="th-td-style">{nonperforminG_FACILITY}</td>
                <td className="th-td-style">{approveD_AMOUNT}</td>
                <td className="th-td-style">{accounT_BALANCE}</td>
                <td className="th-td-style">{overduE_AMOUNT}</td>
                <td className="th-td-style">{dishonoreD_CHEQUES_COUNT}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableSummaryPerformance;
