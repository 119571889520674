import React, { FunctionComponent } from "react";
import { formatMoney } from "../../../helpers/formatter";
export interface IWalletTopUp {
  wallet_id: string;
  funder_name: string;
  amount: number;
  currency_code: string;
  narration: string;
  reference: string;
  transaction_date: string;
  user_id: number;
  initiated_by: string;
  funder_id: number;
  funder_type: number;
  wallet_provider_id: number;
  wallet_provider_name: string;
  proof_of_payment_upload_path: string;
  document_id: number;
}

interface RequestsDataTableProps {
  data: IWalletTopUp[];
  showModal: any;
  hideModal: any;
  initiator: "investor" | "admin";
}

const WalletTopUpDataTable: FunctionComponent<RequestsDataTableProps> = ({
  data,
  showModal,
  hideModal,
  initiator,
}) => {
  return (
    <div className="row m-x-sm m-y-2 grid__padding">
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>S/N</th>
              <th>Wallet ID</th>
              <th>Amount</th>
              <th>Funder Name</th>
              <th>Currency</th>
              <th>Reference</th>
              <th>Wallet Provider</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.wallet_id}>
                <td>{index + 1}</td>
                <td>{item.wallet_id}</td>
                <td>{formatMoney(item.amount, item.currency_code)}</td>
                <td>{item.funder_name}</td>
                <td>{item?.currency_code}</td>
                <td>{item.reference}</td>
                <td>{item.wallet_provider_name}</td>
                <td>
                  <button
                    className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                    type="button"
                    data-toggle="modal"
                    data-target="#requestModal"
                    onClick={() => {
                      showModal({
                        modalIdentifier: "requests",
                        action: "admin-wallet-topup",
                        item: { ...item, initiator },
                      });
                    }}
                  >
                    Request
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WalletTopUpDataTable;
