import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import { postData } from "../../../../../../newApis/apiMethods";
import { appInsights } from "../../../../../../config/appInsights";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { useQueryCache } from "react-query";
import { IMandatePreviewDetails } from "./DirectDebit";
import styles from "./DirectDebitStyles.module.scss";
interface ImandatePrompt {
  currentBankAccount: string | null;
  setCurrentBankAccount: Dispatch<SetStateAction<string | null>>;
  loan_id: number;
  loan_ref: string;
  setSuccess: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string | null>>;
  scrollToTop: () => void;
  mandatePreviewLoading: boolean;
  mandatePreviewError: string | null;
  mandatePreviewSuccess: string | null;
  mandatePreviewResponseData: IMandatePreviewDetails | null;
  createMandatePreview: () => void;
}
const DirectDebitMandateModalPrompt = ({
  currentBankAccount,
  setCurrentBankAccount,
  mandatePreviewLoading,
  mandatePreviewError,
  mandatePreviewSuccess,
  createMandatePreview,
  loan_ref,
  setSuccess,
  setError,
  scrollToTop,
  mandatePreviewResponseData,
}: ImandatePrompt) => {
  const [loading, setLoading] = useState(false);
  const dismissModalRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  useEffect(() => {
    if (currentBankAccount) {
      createMandatePreview();
    }
    //eslint-disable-next-line
  }, [currentBankAccount]);

  const sendMandate = async () => {
    setLoading(true);
    setError(null);
    setSuccess("");
    const reqBody = {
      ...mandatePreviewResponseData,
    };

    try {
      const response = await postData(
        ajaxEndpoints.DIRECT_DEBIT_EMANDATE_CREATE,
        reqBody
      );
      setLoading(false);
      setSuccess(response?.message);

      setTimeout(() => {
        setSuccess("");
      }, 6000);
      scrollToTop();
      queryCache.invalidateQueries("getDirectDebitAccountMandate");
      queryCache.invalidateQueries([
        {
          loan_ref: loan_ref,
          account_number: currentBankAccount || "",
        },
        "getDirectDebitMandateList",
      ]);

      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef?.current?.click();
      }
      setCurrentBankAccount(null);
      setTimeout(() => {
        setSuccess("");
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "DirectDebitMandateModalPrompt.tsx" },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      scrollToTop();

      setError(errorHandler(error));

      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div
      className="modal"
      id="directDebitMandateSubmit"
      tabIndex={-1}
      role="dialog"
    >
      <div className="mt-4  modal-dialog" role="document">
        <div className="modal-content p-3">
          {mandatePreviewSuccess && (
            <p className="alert alert-success text-align-center py-2">
              {mandatePreviewSuccess}
            </p>
          )}
          {mandatePreviewError && (
            <p className="alert alert-danger text-align-center py-2">
              {mandatePreviewError}
            </p>
          )}
          <div className="d-flex justify-content-between ">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissModalRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mt-3 text-center">
            {mandatePreviewLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <p className="text-danger">Loading details....</p>
              </div>
            ) : (
              <div>
                <p>Are you sure you want to submit the selected Mandate?</p>

                <div className={`${styles["accordion-content"]} my-2`}>
                  <h6>Mandate Information</h6>{" "}
                  <ul className="p-0 pl-2">
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>Payer Account Number:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.payerAccountNumber}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>Payer Bank:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.payerbankName}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>Amount:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.amount}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>Currency:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.currency}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>Start Date:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.startDate}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>End Date:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.endDate}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>reference:</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.reference}
                        </small>
                      </div>
                    </li>
                    <li className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <small>Frequency :</small>
                        <small className="mr-4">
                          {" "}
                          {mandatePreviewResponseData?.frequencyName}
                        </small>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn zoom-element btn login-btn "
                    disabled={loading}
                    onClick={sendMandate}
                  >
                    Submit Mandate
                    {loading && <ButtonLoader />}
                  </button>
                  <button
                    type="button"
                    className="btn zoom-element "
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectDebitMandateModalPrompt;
