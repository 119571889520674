import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type Tquery = {
  pageNumber: number;
  size: number;
  productId?: number;
  aggregatorId?: number;
  startDate?: string;
  endDate?: string;
  disbursed?: string;
};

const getBatchedLoansDetails = async ({
  pageNumber,
  size,
  productId,
  aggregatorId,
  startDate,
  endDate,
  disbursed,
}: Tquery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;
  if (productId) {
    queryParams = `${queryParams}&ProductId=${productId}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  if (disbursed) {
    queryParams = `${queryParams}&HasDisburse=${JSON.parse(disbursed)}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&FromDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&ToDate=${endDate}`;
  }
  const data = await getData(
    `${ajaxEndpoints.GET_DISBURSEMENT_BATCHED_DETAILS}?${queryParams}`
  );
  return data;
};

export default function useBatchedLoansDetails({
  pageNumber,
  size,
  productId,
  aggregatorId,
  startDate,
  endDate,
  disbursed,
}: Tquery) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        productId,
        aggregatorId,
        startDate,
        endDate,
        disbursed,
      },
      "getBatchedLoansDetails ",
    ],
    getBatchedLoansDetails
  );
}
