import React, { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import {
  getDataWithDotNet,
  postDataWithDotNet,
} from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import { formatMoney } from "../../../../helpers/formatter";
import style from "../../Components/RollOverModal/RollOverModal.module.scss";
import { appInsights } from "../../../../config/appInsights";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import { IBankAccount } from "../../../../interfaces/funderInvestment";
import { errorHandler } from "../../../../helpers/errorHandler";
import { Loader } from "../../../../UI/Loaders/Loaders";

interface IProps {
  funderId: number;
  wallet_id: number;
  currency: string;
  availableBalance: number;
  goBack: () => void;
}
interface IWithdrawalInfo {
  funder_id: number | undefined;
  amount: number | undefined;
  transaction_date: string;
  narration: string;
  wallet_id: string;
  customer_bank_account_number: string;
}

const InvestorWalletWithdrawal = ({
  funderId,
  wallet_id,
  currency,
  goBack,
  availableBalance,
}: IProps) => {
  const defaultWithdrawalInfo = {
    funder_id: funderId,
    amount: undefined,
    transaction_date: "",
    narration: "",
    customer_bank_account_number: "",
    wallet_id: String(wallet_id),
  };
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [withdrawalInfo, setWithdrawalInfo] = useState<IWithdrawalInfo>(
    defaultWithdrawalInfo
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<
    IBankAccount[] | undefined
  >();

  function handleChangeAmount(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setWithdrawalInfo({ ...withdrawalInfo, amount: Number(target.value) });
  }

  function handleChangeNarration(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setWithdrawalInfo({ ...withdrawalInfo, narration: target.value });
  }

  function handleChangeEffectiveDate(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setWithdrawalInfo({
      ...withdrawalInfo,
      transaction_date: moment(target.value).format("YYYY-MM-DDTHH:mm:ss"),
    });
  }

  const handleSubmitWithdrawal = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.ADMIN_INITIATE_WALLET_WITHDRAWAL,
        withdrawalInfo
      );
      console.log({ response });
      setSuccessMessage(response?.message);
      setTimeout(() => {
        goBack();
      }, 2000);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "An error occurred");
      console.log({ error: error?.response?.data?.message });
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchBankAccounts = async () => {
    setFetching(true);
    try {
      const response = await getDataWithDotNet(
        `${ajaxEndpoints.CUSTOMER_BANK_ACCOUNTS}/${funderId}/bank_accounts`
      );
      const { bank_accounts } = response.data;
      setBankAccounts(bank_accounts);
    } catch (error) {
      setErrorMessage(errorHandler(error));
      appInsights.trackException({
        exception: new Error(error),
        properties: {
          fileName: "InvestorWalletWithdrawal.tsx",
        },
      });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    handleFetchBankAccounts();
    appInsights.trackPageView({
      name: "InvestorWalletWithdrawal.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      <p className="cursor-pointer font-weight-bold mb-3" onClick={goBack}>
        <i className="fas fa-arrow-left mr-2" /> Back to Wallet
      </p>
      <div className="row">
        {successMessage ? (
          <div className="col-md-6">
            <p className="alert alert-success">{successMessage}</p>
          </div>
        ) : errorMessage ? (
          <div className="col-md-6">
            <p className="alert alert-danger">{errorMessage}</p>
          </div>
        ) : null}
      </div>
      <div className="col-md-6">
        <h3 className="page-subtitle">Wallet Withdrawal</h3>
        {fetching ? (
          <Loader text="Fetching customer bank accounts..." />
        ) : (
          <form className="m-t-md" onSubmit={handleSubmitWithdrawal}>
            <div>
              <CustomInputField
                type="number"
                name="amount"
                label={`Available Balance ${formatMoney(
                  availableBalance,
                  currency
                )}`}
                value={availableBalance || ""}
                readOnly
              />
            </div>
            <div>
              <CustomInputField
                type="number"
                name="amount"
                label={`Enter Amount ${formatMoney(
                  withdrawalInfo.amount,
                  currency
                )}`}
                placeholder="e.g. 1000"
                onChange={handleChangeAmount}
                value={withdrawalInfo.amount || ""}
              />
            </div>
            <div>
              <CustomInputField
                type="date"
                label="Effective Date"
                max={moment(new Date()).format("YYYY-MM-DD")}
                name="transaction_date"
                onChange={handleChangeEffectiveDate}
              />
            </div>

            <div>
              <CustomInputField
                name="narration"
                label="Narration"
                placeholder="Enter Narration"
                onChange={handleChangeNarration}
                value={withdrawalInfo.narration || ""}
              />
            </div>

            <div>
              <CustomSelectDropdown
                onChange={(e) => {
                  console.log({ e: e.target.value });
                  setWithdrawalInfo({
                    ...withdrawalInfo,
                    customer_bank_account_number: e.target.value,
                  });
                }}
                name="customer_bank_account_number"
                className="round user__form form-control"
                label="Destination Account"
              >
                <option value="">Select Bank Account</option>
                {bankAccounts &&
                  bankAccounts?.length > 0 &&
                  bankAccounts.map((item) => (
                    <option
                      value={item?.account_number}
                    >{`${item?.account_number} - ${item?.currency}`}</option>
                  ))}
              </CustomSelectDropdown>
            </div>
            <br />
            <br />
            <div className={style.finalSection}>
              <button
                className="btn advancly-btn m-0 btn-block"
                disabled={
                  !withdrawalInfo?.narration ||
                  !withdrawalInfo?.amount ||
                  !withdrawalInfo?.customer_bank_account_number ||
                  isLoading
                }
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm text-white" />
                ) : (
                  "Withdraw"
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default InvestorWalletWithdrawal;
