import { useEffect } from "react";

import { useSelector } from "react-redux";
import IdleTimer from "./idleTimer";
import { removeAllModals } from "./removeAllModals";

let Secure;

const protectPage = (WrappedComponent) => {
  return (Secure = () => {
    const loggedInUser = useSelector(
      (state) => state?.authReducer?.loggedInUser
    );

    useEffect(() => {
      if (loggedInUser?.sso_auth_token) {
        const timer = new IdleTimer({
          timeout: 300,
          onTimeout: () => {
            alert("Your session timed out.");
            removeAllModals();
            localStorage.clear();
            window.location.replace("/login");
          },
        });

        return () => {
          timer.cleanup();
        };
      }
    }, [loggedInUser?.sso_auth_token]);

    return (
      <div className="p-3" style={{ minHeight: "90vh" }}>
        <WrappedComponent />
      </div>
    );
  });
};

export default protectPage;
