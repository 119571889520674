export const updateAuthenticated = (isAuthenticated: any) => {
  return function (dispatch: any) {
    dispatch({ type: "UPDATE_AUTHENTICATED", payload: isAuthenticated });
  };
};

export const updateUser = (user: any) => {
  return function (dispatch: any) {
    dispatch({ type: "UPDATE_USER", payload: user });
  };
};
