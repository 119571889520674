import React from "react";

const SystemForm = (props: any) => {
  const { categoryList, category_name, category_code } = props.inputs;
  const { systemEdit } = props;

  const CategoryOptions = categoryList.map((ecosystem: any, index: number) => {
    const { category_id, category_name, category_slug } = ecosystem;
    return (
      <option key={index} id={category_id} value={category_slug}>
        {category_name}
      </option>
    );
  });

  return (
    <div className="card">
      <div className="body text-center">
        <h3 className="m-t-0">
          <i className="adv-icon fas fa-cogs" />
          System
        </h3>
        <p className="settings-block text-muted">
          Advancly global system settings
        </p>

        <div className="grid__full m-b-1">
          <div className="p-l-0 grid__padding">
            <label className="label-txt">List Sector</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                placeholder="Select an Ecosystem"
                title="Select an Ecosystem"
                id="roleType"
              >
                {CategoryOptions}
              </select>
              <i className="adv-aggregator-icon fas fa-building"></i>
            </div>
            <div className="errorHandler form-group text-left grid__full">
              <span id="email-msg" className="hidden error-element"></span>
            </div>
          </div>
        </div>

        <div className="grid__full settings-block">
          <label className="label-txt">Add a Sector</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              placeholder="Add a Sector"
              autoComplete="off"
              data-form="system"
              name="category_name"
              readOnly={!systemEdit}
              value={category_name}
              onChange={(e) => props.onInputChange(e.currentTarget)}
              required
            />
          </div>
        </div>
        <div className="grid__full settings-block">
          <label className="label-txt">Add Sector Code</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              autoComplete="off"
              data-form="system"
              name="category_code"
              readOnly={!systemEdit}
              value={category_code}
              onChange={(e) => props.onInputChange(e.currentTarget)}
              required
            />
          </div>
        </div>

        <div className="grid__full">
          {systemEdit ? (
            <div className="Form__action-buttons">
              <button
                type="button"
                className="btn zoom-element login-btn m-r-1"
                data-form="system"
                onClick={(e) => props.onCancelUpdate(e.currentTarget)}
              >
                <i className="fas fa-times-circle" />
                &nbsp; Cancel
              </button>
              <button
                type="button"
                data-form="system"
                className="btn zoom-element login-btn m-l-1"
                onClick={(e) => props.onAddCategory(e.currentTarget)}
              >
                <i className="fas fa-save" />
                &nbsp; Save
              </button>
            </div>
          ) : (
            <button
              type="submit"
              className="zoom-element btn login-btn"
              data-form="system"
              onClick={(e) => props.onEditMode(e.currentTarget)}
            >
              Create
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SystemForm;
