import { useState } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

import { useQueryCache } from "react-query";
// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { errorHandler } from "../../../helpers/errorHandler";
import {
  postDataWithDotNet,
  putDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import usePendingLocalLiquidationSettings from "../../../custom-hooks/usePendingLocalLiquidationSettings";
import { appInsights } from "../../../config/appInsights";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actions/modal";

const ManageLocalLiquidationSettings = () => {
  const [loading, setLoading] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(10);

  const dispatch = useDispatch();

  const queryCache = useQueryCache();
  //  Methods
  const dialogHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "managependingliquidationsettingsprompt",
        dataRecord: modalData,
      })
    );
  };

  const approveSettings = async (reference: string, comment: string) => {
    setLoading("Approving Settings...");
    setError("");
    const reqBody = {
      reference,
      comment,
    };
    try {
      const response = await putDataWithDotNet(
        ajaxEndpoints.COUNTRY_LIQUIDATION,
        reqBody
      );
      setLoading("");
      setSuccess(response.message);
      queryCache.invalidateQueries(["getPendingLocalLiquidationSettings"]);
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageLocalLiquidationSettings.jsx",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const declineSettings = async (reference: string, comment: string) => {
    setLoading("Declining Settings...");
    setError("");
    const reqBody = {
      reference,
      comment,
    };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.REJECT_REQUEST,
        reqBody
      );
      setLoading("");
      setSuccess(response.message);
      queryCache.invalidateQueries(["getPendingLocalLiquidationSettings"]);
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageLocalLiquidationSettings.jsx",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  //JSX Template
  let RenderedComponent = null;

  const {
    data,
    status,
    error: dataError,
  } = usePendingLocalLiquidationSettings();

  if (data) {
    if (count === null) {
      setCount(data.length);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Local
            Liquidation Settings
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading local liquidation settings..." />
    );
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else {
    if (dataError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue fetching local
              liquidation settings, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {error}
            </h4>
          </div>
        </div>
      );
    } else if (success) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {success}
            </h4>
          </div>
        </div>
      );
    } else if (data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending local
              liquidation settings to manage
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Modified Date</th>
                <th>Country</th>
                <th>Liquidation Channel Name</th>
                <th>Liquidation Channel Account Name</th>
                <th>Liquidation Channel Account No</th>
                <th>Bank Name</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((settings: any, _id: number) => {
                  const {
                    modified_date,
                    lcy_liquidation_channel_name,
                    lcy_liquidation_channel_account_name,
                    lcy_liquidation_channel_account_number,
                    bank_name,
                    name,
                  } = settings;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{modified_date?.substring(0, 10)}</td>
                      <td>{name}</td>
                      <td>{lcy_liquidation_channel_name}</td>
                      <td>{lcy_liquidation_channel_account_name}</td>
                      <td>{lcy_liquidation_channel_account_number}</td>
                      <td>{bank_name}</td>
                      <td className="d-flex flex-row">
                        <span
                          className="view btn-manage btn-success zoom-element btn-slim"
                          onClick={() =>
                            dialogHandler({
                              type: "approve",
                              settings,
                              action: approveSettings,
                            })
                          }
                        >
                          Approve
                        </span>
                        <span
                          className="view btn-manage btn-danger zoom-element btn-slim"
                          onClick={() =>
                            dialogHandler({
                              type: "decline",
                              settings,
                              action: declineSettings,
                            })
                          }
                        >
                          Decline
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.length : 0}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Manage aggregator settings Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-lg-9 col-md-6 col-sm-7 dashboard-title flex__row">
              <h3 className="m-l-1">
                <aside className="rounder">4</aside>
                &nbsp; Manage Local Liquidation Settings
              </h3>
            </div>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
          <label htmlFor="table-fetch" className="form-group">
            Number of results:
            <select
              onChange={(e) => {
                setSize(Number(e.target.value));
              }}
              id="table-fetch"
              className="table-fetch-select m-l-1"
              value={size}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
        {RenderedComponent}
      </div>
    </div>
  );
};

export default ManageLocalLiquidationSettings;
