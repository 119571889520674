import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../helpers/formatter";
import { useState } from "react";

const LoanRepaymentModal = (props) => {
  const {
    properties: { repayBatchLoans, dataRecord },
    onCloseModal,
  } = props;
  const [loading, setLoading] = useState(false);

  const { loansForRepayment, totalAmount } = dataRecord;
  const Repay = (e) => {
    setLoading(true);
    repayBatchLoans(e);
  };
  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h2 className="Modal__title p-b-2 text-center font-weight-bold">
            Total Repayment Amount
          </h2>
          <div className="modal-body">
            <div className="mb-4">
              <span className="form-label">
                Repayment Amount : {formatMoney(totalAmount.toFixed(2))}
              </span>
            </div>
            <div className="mb-5">
              <span className="form-label">
                Repayment for {loansForRepayment?.length} loan(s)
              </span>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
              <button
                type="button"
                className="btn btn-sm advancly-nobg-btn mr-3"
                onClick={onCloseModal}
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-sm advancly-btn"
                onClick={(e) => {
                  Repay(e);
                }}
              >
                Repay Loan(s){loading && <ButtonLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoanRepaymentModal;
