import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getTenantsDefault = async () => {
  const data = await getData(ajaxEndpoints.GET_TENANTS);
  return data?.data;
};

export default function useTenantsDefault() {
  return useQuery(["getTenantsDefault"], getTenantsDefault);
}
