import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TMifosProductQuery = {
  productType?: number;
  tenantId?: string;
};

export type TMisfoProduct = {
  currency_code: string;
  description: string;
  id: number;
  interest_rate_chart: string;
  maxDepositTerm: number;
  maximum_investment: number;
  minDepositTerm: number;
  minimum_investment: number;
  name: string;
  number_of_days_in_year: number;
  short_name: string;
};

const getMifosProduct = async ({
  productType,
  tenantId,
}: TMifosProductQuery) => {
  let query = `?producttype=${productType}`;

  if (tenantId) {
    query = `${query}&TenantId=${tenantId}`;
  }

  const { data } = await getData(
    `${ajaxEndpoints.GET_MIFOS_PRODUCTS_DETAILS}${query}`
  );

  return data;
};

export default function useMifosProduct({
  productType,
  tenantId,
}: TMifosProductQuery) {
  return useQuery<TMisfoProduct[]>(
    [{ productType, tenantId }, "getMifosProduct"],
    getMifosProduct,
    {
      refetchOnWindowFocus: false,
      enabled: tenantId && productType ? true : false,
    }
  );
}
