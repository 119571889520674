import "./Cards.css";

export const AggregatorCard = (props: any) => {
  const {
    record: {
      biz_name,
      biz_category,
      is_active,
      aggregator_photo_url,
      aggregator_id,
    },
  } = props;
  const capitalize = (value: string) => {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : null;
  };

  return (
    <div
      className={
        is_active === true
          ? "AggregatorCard text-center ecosystem card zoom-element aggSelect"
          : "text-center card opac-5 ecosystem"
      }
    >
      <div className="body text-center" title={biz_name}>
        <h4 className="biz_name m-t-0">{biz_name}</h4>
        <small className="biz_category m-t-0">{capitalize(biz_category)}</small>
        <small className="biz_category m-t-0">{aggregator_id}</small>
        <img
          className="adv-clients"
          src={
            aggregator_photo_url
              ? aggregator_photo_url
              : `/assets/images/aggregator.svg`
          }
          alt={biz_name}
        />
      </div>
    </div>
  );
};
