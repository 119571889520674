import React, { Dispatch, Fragment, SetStateAction } from "react";

const DisbursementConfirmationModal = ({
  disburseLoan,
  setshowDisbursementConfirmationModal,
  disbursementLoading,
}: {
  disbursementLoading: boolean;
  disburseLoan: (e?: any) => void;
  setshowDisbursementConfirmationModal: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", zIndex: 1055 }} // Increase zIndex for the second modal
    >
      <div className="Modal__dialog  animated slideInDown" role="document">
        <div className=" Modal__content">
          <div className="Modal__body" style={{ overflow: "hidden" }}>
            <p className="d-flex justify-content-center">
              Are you sure you want to Disburse this Loan?
            </p>
            <div className="d-flex justify-content-between">
              {
                <Fragment>
                  <button
                    type="button"
                    className="btn zoom-element btn login-btn"
                    disabled={disbursementLoading}
                    onClick={() => {
                      disburseLoan();
                    }}
                  >
                    {disbursementLoading ? "Loading..." : "Yes"}
                  </button>
                  <button
                    type="button"
                    className="btn zoom-element  reject_btn "
                    onClick={() => setshowDisbursementConfirmationModal(false)}
                  >
                    No
                  </button>
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisbursementConfirmationModal;
