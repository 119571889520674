import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { aMonthAgo, today } from "../../helpers/dateTimeControllers";
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import { useTrialBalanceReport } from "../../custom-hooks/useTrialBalance";
import { useQueueList, useQueueType } from "../../custom-hooks/useQueue";
import { IQueueErrorBucket, IQueueType } from "../../interfaces/queue";
import moment from "moment";
import Paginator from "../Pagination/Pagination.component";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { appInsights } from "../../config/appInsights";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { postData } from "../../newApis/dotNetApiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { deleteData } from "../../newApis/apiMethods";
import ConfirmationModal from "../../NewComponents/ConfirmationModal";
import { DELETE_QUEUE } from "../../api/ajax-endpoints";

const LoanCreationQueue = () => {
  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(10);
  const [selectedQueueType, setSelectedQueueType] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState<IQueueErrorBucket | null>(
    null
  );
  const [showQueueModal, setShowQueueModal] = useState(false);
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);

  useEffect(() => {
    console.log({
      error,
    });
  }, [error]);

  const {
    data: queueTypes,
    status: queueTypeStatus,
    error: queueTypeError,
  } = useQueueType();
  const {
    data: queueList,
    status: queueListStatus,
    error: queueListError,
  } = useQueueList({
    startDate,
    endDate,
    queueType: selectedQueueType,
    Page: pageNumber.toString(),
    PageSize: size.toString(),
  });

  async function handleRetryQueue(request: IQueueErrorBucket) {
    console.log({
      request,
    });
    setSelectedQueue(request);
    try {
      setIsLoading(true);
      const response: any = await postData(
        `${ajaxEndpoints.RETRY_QUEUE}?id=${request.id}`
      );
      setSuccess(response?.message || "Re-pushed item successfully");
      setTimeout(() => {
        setSuccess(null);
        window.location.reload();
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "LoanCreationQueue.tsx",
        },
      });
      console.log(errorHandler(error));
      setError(errorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDeleteQueue() {
    try {
      setDeleting(true);
      const response: any = await deleteData(
        `${ajaxEndpoints.DELETE_QUEUE}?id=${selectedQueue?.id}`
      );
      setSuccess(response?.message || "Deleted item successfully");
      setTimeout(() => {
        setSuccess(null);
        window.location.reload();
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "LoanCreationQueue.tsx",
        },
      });
      console.log(errorHandler(error));
      setError(errorHandler(error) || "Something went wrong please try again");
      setTimeout(() => {
        setError(null);
      }, 3000);
    } finally {
      setDeleting(false);
    }
  }

  if (queueList) {
    if (count === null) {
      setCount(queueList?.totalCount);
    }
  }

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  useEffect(() => {
    console.log({
      queueList,
    });
  }, [queueList]);

  if (queueTypeError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            queue types, Please Reload The Page
          </h4>
        </div>
      </div>
    );
  }

  //JSX Template
  let RenderedComponent = null;
  if (queueListStatus === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading queues" />;
  } else if (queueListError) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> {queueListError}
          </h4>
        </div>
      </div>
    );
  } else if (queueList && queueList?.data?.length === 0) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> No data available
          </h4>
        </div>
      </div>
    );
  } else {
    RenderedComponent = (
      <div className="grid__padding animated fadeInRight">
        <MDBTable responsive minimal="true" bordered hover>
          <MDBTableHead>
            <tr>
              <th>ID</th>
              <th>Queue Type</th>
              <th>Message</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {queueList &&
              queueList?.data?.length &&
              queueList?.data.map((item: IQueueErrorBucket, _id: number) => {
                const { id, queueType, message, errorDate } = item;
                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{queueType}</td>
                    <td>
                      {message?.length > 7
                        ? `${message.slice(0, 7)}...`
                        : message}
                    </td>
                    <td>{moment(errorDate).format("llll")}</td>
                    <td>
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => handleRetryQueue(item)}
                        disabled={isLoading}
                      >
                        Retry
                        {isLoading && selectedQueue?.id === id ? (
                          <ButtonLoader />
                        ) : null}
                      </button>
                      <button
                        className="btn btn-secondary mr-2"
                        onClick={() => {
                          setSelectedQueue(item);
                          setShowConfirmationPrompt(true);
                        }}
                        disabled={isLoading}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-light"
                        onClick={() => {
                          setSelectedQueue(item);
                          setShowQueueModal(true);
                        }}
                        disabled={isLoading}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
          </MDBTableBody>
        </MDBTable>
        <Paginator
          size={size}
          page={pageNumber}
          count={queueList?.totalCount}
          changeCurrentPage={(pageNumber: any) => setPageNumber(pageNumber)}
        />
      </div>
    );
  }

  return (
    <div className="datatable-wrapper container-fluid my-3">
      <div className="table-view speed-1x fadeInRight">
        <div className="block-header">
          <h3 className="page-title mb-4">Queue Error Bucket</h3>
        </div>
        <hr />
        <div
          className="row animated fadeInRight m-x-1 m-t-1"
          style={{
            display: "flex",
            justifyContent: "space-around",
            position: "relative",
            zIndex: 1,
          }}
        >
          <div className="m-l-1 m-r-1 m-b-1 form-group">
            <label htmlFor="tenant">Select Queue Type:</label>
            <br />
            <select
              onChange={(e) => {
                setSelectedQueueType(e.target.value);
              }}
              id="tenant"
              className="table-fetch-select"
              style={{ width: "100%" }}
              defaultValue="default"
            >
              <option value="">All Queue Types</option>
              {queueTypes &&
                queueTypes.map((item: IQueueType) => {
                  const { id, name } = item;
                  return (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="m-l-1 m-r-1 m-b-1 form-group">
            <label htmlFor="table-fetch">Start Date:</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
          </div>

          <div className="m-l-1 m-r-1 m-b-1 form-group">
            <label htmlFor="table-fetch">End Date:</label>
            <input
              type="date"
              className="form-control"
              max={today}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
          </div>

          <div className="m-l-1 m-r-1 m-b-1 form-group">
            <label htmlFor="table-fetch">Number of results:</label>
            <br />
            <select
              onChange={(e) => {
                setSize(Number(e.target.value));
                resetTable();
              }}
              id="table-fetch"
              className="table-fetch-select m-l-1 "
              value={size}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        {success && (
          <div className="col-md-4 alert alert-success">{success}</div>
        )}
        {error && <div className="col-md-4 alert alert-danger">{error}</div>}
        <div>{RenderedComponent}</div>
      </div>

      <ConfirmationModal
        isOpen={showConfirmationPrompt}
        onClose={() => {
          setSelectedQueue(null);
          setShowConfirmationPrompt(false);
        }}
        onConfirm={handleDeleteQueue}
        error={error}
        isLoading={deleting}
      />

      <Modal
        isOpen={showQueueModal}
        toggle={() => setShowQueueModal(false)}
        centered
      >
        <ModalBody>
          <p>
            <span className="font-weight-bold">ID:</span> {selectedQueue?.id}
          </p>
          <p>
            <span className="font-weight-bold">QUEUE TYPE:</span>{" "}
            {selectedQueue?.queueType}
          </p>

          <p>
            <span className="font-weight-bold">MESSAGE:</span>{" "}
            {selectedQueue?.message}
          </p>

          <p>
            <span className="font-weight-bold">DATE:</span>{" "}
            {moment(selectedQueue?.errorDate).format("lll")}
          </p>
          <div>
            <p className="font-weight-bold">PAYLOAD:</p>{" "}
            <p style={{ overflowWrap: "break-word" }}>
              {selectedQueue?.payload}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-light"
              onClick={() => setShowQueueModal(false)}
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LoanCreationQueue;
