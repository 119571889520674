interface ILoader {
  text?: string;
  background?: boolean;
  centered?: boolean;
}

export const Loader = ({ text, background, centered }: ILoader) => {
  return (
    <div className={centered ? "centerLoader" : ""}>
      <div
        className={
          background
            ? ""
            : "tfx-user-form-wrapper box-effect full-border white-bg"
        }
      >
        <div className="tfx-user-form-inner">
          {/* Loader */}
          <div
            id="adv-login-loader"
            className="loading-overlay speed-1x fadeInRight"
          >
            <div className="form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />
                <div className="center-txt webapp-ripple-text">
                  {/* <i className="notifier fas fa-user-circle"/> */}
                  &nbsp; {`${text}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ISuccessLoader {
  text: string;
  subText: string;
  className?: string;
}

export const SuccessLoader = ({ text, subText, className }: ISuccessLoader) => {
  const classList = className ? className : "speed-1x fadeInRight";

  return (
    <div className="tfx-user-form-inner">
      {/* Success response*/}
      <div id="adv-login-response" className={`loading-overlay ${classList}`}>
        <div className="form-loader form-loader-login verify">
          <div className="centerLoader">
            <div className="loading success-loader">
              <i className="webapp-ripple-notification success-text fa fa-check-circle" />
            </div>
            <div className="center-txt webapp-ripple-text">&nbsp; {text}</div>
            {subText && (
              <div className="onboarding-response center-txt webapp-ripple-text">
                &nbsp; {subText}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface IErrorLoader {
  text?: string;
  subText?: string;
  icon?: string;
  className?: string;
}

export const ErrorLoader = ({
  text,
  subText,
  icon,
  className,
}: IErrorLoader) => {
  const classList = className ? className : "speed-1x fadeInRight";

  return (
    <div className="tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
      <div className="tfx-user-form-inner">
        {/* Error Response */}
        <div id="adv-login-response" className={`loading-overlay ${classList}`}>
          <div className="form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="loading">
                {icon ? (
                  <i
                    className={`webapp-ripple-notification error-text ${icon}`}
                  />
                ) : (
                  <i className="webapp-ripple-notification error-text fa fa-times-circle" />
                )}
              </div>
              <div className="center-txt webapp-ripple-text">&nbsp; {text}</div>
              {subText && (
                <div className="onboarding-response center-txt webapp-ripple-text">
                  &nbsp; {subText}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IInfoLoader {
  background?: string;
  text?: string;
  subText?: string;
  className?: string;
}

export const InfoLoader = ({
  background,
  text,
  subText,
  className,
}: IInfoLoader) => {
  const classList = className ? className : "speed-1x fadeInRight";

  return (
    <div
      className={
        background
          ? `${classList}`
          : `tfx-user-form-wrapper box-effect full-border white-bg ${classList}`
      }
    >
      <div className="tfx-user-form-inner">
        {/* Information Response */}
        <div id="adv-login-response" className={`loading-overlay ${classList}`}>
          <div className="form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="">
                <i className="webapp-ripple-notification success-text fa fa-bell" />
              </div>
              <div className="center-txt webapp-ripple-text">&nbsp; {text}</div>
              {subText && (
                <div className="onboarding-response center-txt webapp-ripple-text">
                  &nbsp; {subText}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function ButtonLoader() {
  return <span className="spinner-border spinner-border-sm ml-2"></span>;
}
