import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";
import { TCurrencyName } from "../components/FunderInvestmentProduct/helper";

type TInvestmentSummary = {
  amount_due: number;
  amount_invested: number;
  effective_date: string;
  investor: string;
  investor_type: number;
  maturity_amount: number;
  maturity_date: string;
  monthly_yield: number;
  outstanding_settlement: number;
  reference_number: string;
  repayment_type: string;
  tenure: number;
  total_interest_at_maturity: number;
  total_settled: number;
};

export type TInvestmentSummariesCurrency = {
  amount_raised: number;
  currency: string;
  investment_size: number;
  investment_summary: TInvestmentSummary[];
  investmentment_product_detail_id: number;
  percentage_raised: number;
};

export type TEachInvestment = {
  currencies: TCurrencyName[];
  investmentSummaries: TInvestmentSummariesCurrency[];
};

const getEachInvestments = async (id: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.EACH_INVESTMENT}/${Number(id)}`
  );
  return data;
};

export default function useEachInvestments(id: number) {
  return useQuery<IResponse<TEachInvestment>>(
    [id, "getEachInvestments"],
    getEachInvestments
  );
}
