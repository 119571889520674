import React from "react";
import moment from "moment";
import styles from "../CreditBureau.module.scss";
const CreditbureauTableCustomerDetails = ({ consCommDetails }: any) => {
  return (
    <>
      <div>
        <h5 className={` ${styles.creditBureauHeading}`}>Customer Details</h5>
        <table className="table-style">
          <thead>
            <tr>
              <th className="th-td-style th-style" scope="col">
                Name
              </th>
              <th className="th-td-style th-style" scope="col">
                Gender
              </th>
              <th className="th-td-style th-style" scope="col">
                Nationality
              </th>
              <th className="th-td-style th-style" scope="col">
                Date Of Birth
              </th>
              <th className="th-td-style th-style" scope="col">
                Phone Number
              </th>
              <th className="th-td-style th-style" scope="col">
                Phone Number 2
              </th>
              <th className="th-td-style th-style" scope="col">
                Address
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="th-td-style">
                {consCommDetails?.consCommDetails_Subject?.name}
              </td>
              <td className="th-td-style ">
                {consCommDetails?.consCommDetails_Subject?.gender}
              </td>
              <td className="th-td-style ">
                {consCommDetails?.consCommDetails_Subject?.nationality}
              </td>
              <td className="th-td-style ">
                {moment(
                  consCommDetails?.consCommDetails_Subject?.datE_OF_BIRTH_M
                ).format("DD/MM/YYYY")}
              </td>
              <td className="th-td-style ">
                {consCommDetails?.consCommDetails_Subject?.phonE_NO1}
              </td>
              <td className="th-td-style ">
                {consCommDetails?.consCommDetails_Subject?.phonE_NO2}
              </td>
              <td className="th-td-style ">
                {consCommDetails?.consCommDetails_Subject?.address}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <h5 className={` ${styles.creditBureauHeading}`}>
          Customer Identity Details
        </h5>
        <table className="table-style">
          <thead>
            <tr>
              <th className="th-td-style th-style" scope="col">
                Identity Type
              </th>
              <th className="th-td-style th-style" scope="col">
                Identity Number
              </th>
              <th className="th-td-style th-style" scope="col">
                Expiry Number
              </th>
            </tr>
          </thead>
          <tbody>
            {consCommDetails?.consCommDetails_ID?.map(
              (
                identityDetails: {
                  iD_TYPE: string;
                  identifiernumber: string;
                  expirY_DATE: string;
                },
                index: number
              ) => {
                const { iD_TYPE, identifiernumber, expirY_DATE } =
                  identityDetails;
                return (
                  <tr key={index}>
                    <td className="th-td-style">{iD_TYPE}</td>
                    <td className="th-td-style">{identifiernumber}</td>
                    <td className="th-td-style">{expirY_DATE}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CreditbureauTableCustomerDetails;
