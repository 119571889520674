import { useEffect, useState } from "react";
import ToggleTab from "./../../NewComponents/ToggleTab/ToggleTab.component";
import WithdrawalRequests from "./Tabs/WithdrawalRequests";
import OfflineRequests from "./Tabs/OfflineRequests";
import FailedInvestments from "./Tabs/FailedInvestments";
import FailedWithdrawals from "./Tabs/FailedWithdrawals";
import DocumentationVerification from "./Tabs/DocumentationVerification";
import { appInsights } from "../../config/appInsights";
import InvestorTopUpRequests from "./Tabs/InvestorTopUpRequests";
import AdminTopUpRequests from "./Tabs/AdminTopUpRequests";
import { useSelector } from "react-redux";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import AdminWithdrawalRequests from "./Tabs/AdminWithdrawalRequests";

export default function Requests() {
  const loggedInUser = useSelector(
    (state: any) => state?.authReducer?.loggedInUser
  );
  const { roles } = loggedInUser;
  const [activeTab, setActiveTab] = useState("offline-requests");

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investor Approval Requests - (Requests.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Requests</h3>
        <div className="alignToggleTabItems mb-3">
          <ToggleTab
            text="Offline Requests"
            id="offline-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Investor Withdrawals"
            id="withdrawal-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Document Verification"
            id="document-verification-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Failed Investments"
            id="failed-investments"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Failed Withdrawals"
            id="failed-withdrawals"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Investor Top-up"
            id="investor-top-up-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case "offline-requests":
                return <OfflineRequests />;
              case "withdrawal-requests":
                return <WithdrawalRequests />;
              case "document-verification-requests":
                return <DocumentationVerification />;
              case "failed-investments":
                return <FailedInvestments />;
              case "failed-withdrawals":
                return <FailedWithdrawals />;
              case "investor-top-up-requests":
                return <InvestorTopUpRequests />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
}
