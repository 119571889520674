import React, { Fragment, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import CustomSelectDropdown from "../../../NewComponents/TypedComponents/CustomHTMLElements/CustomSelectDropdown";
import { formatMoney } from "../../../helpers/formatter";
import { Loader } from "../../../UI/Loaders/Loaders";
import { showModal, hideModal } from "../../../redux/actions/modal";
import { connect } from "react-redux";
import useFailedWithdrawals from "../../../custom-hooks/useFailedWithdrawals";

interface FailedWithdrawalsProps {}

const FailedWithdrawals: React.FC<FailedWithdrawalsProps> = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const { data, isLoading, error } = useFailedWithdrawals({
    page: pageNumber,
    size,
  });

  return (
    <Fragment>
      <div>
        {successMessage && <Alert message={successMessage} type="success" />}
        {submitError && <Alert message={submitError} />}
        <div className="d-flex align-items-center justify-content-end mb-3">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <div style={{ width: "150px", marginTop: "10px" }}>
              <CustomSelectDropdown
                onChange={(e: any) => {
                  setSize(e.target.value);
                }}
                value={size}
              >
                <option value={10000}>All</option>
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
        </div>
        {/*Datatable || Loader || Error Message*/}

        {isLoading ? (
          <Loader centered={true} text="Loading failed withdrawals..." />
        ) : error ? (
          <div>
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" /> There was an issue while fetching
                failed withdrawals, Please Reload The Page
              </h4>
            </div>
          </div>
        ) : !data?.data?.length ? (
          <div className="centerLoader">
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" /> There are currently no failed
                withdrawals.
              </h4>
            </div>
          </div>
        ) : (
          <div className="row m-x-sm m-y-2 grid__padding">
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>S/N</th>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Wallet ID</th>
                    <th>Liquidation Channel</th>
                    <th>Transaction Type</th>
                    <th>Amount</th>
                    <th>Internal Reference Number</th>
                    <th>Transaction Date</th>
                    <th>Approval Date</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((response: any, _id: number) => {
                    const {
                      id,
                      amount,
                      approvalDate,
                      liquidationChannelAccountName,
                      liquidationChannelAccountNumber,
                      liquidationChannelName,
                      transactionDate,
                      transactionType,
                      walletAccountId,
                      internalPaymentReference,
                      currency,
                    } = response;
                    return (
                      <tr key={id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{liquidationChannelAccountName}</td>
                        <td>{liquidationChannelAccountNumber}</td>
                        <td>{walletAccountId}</td>
                        <td>{liquidationChannelName}</td>
                        <td>{transactionType}</td>
                        <td>{formatMoney(amount, currency)}</td>
                        <td>{internalPaymentReference}</td>
                        <td>{transactionDate?.substring(0, 10)}</td>
                        <td>{approvalDate?.substring(0, 10)}</td>
                        <td className="flex-row mt-2">
                          <button
                            className="btn advancly-btn btn-sm transition-3d-hover mr-2"
                            type="button"
                            onClick={() =>
                              props.showModal({
                                modalIdentifier: "requests",
                                action: "failed_withdrawal",
                                transactionId: id,
                                setSuccessMessage,
                                setSubmitError,
                                pageNumber,
                                size,
                              })
                            }
                          >
                            Retry
                          </button>
                          <button
                            className="btn advancly-white-btn btn-sm transition-3d-hover"
                            type="button"
                            onClick={() =>
                              props.showModal({
                                modalIdentifier: "requests",
                                action: "approve_withdrawal",
                                transactionId: id,
                                setSuccessMessage,
                                setSubmitError,
                                pageNumber,
                                size,
                              })
                            }
                          >
                            Approve
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Paginator
          size={size}
          page={pageNumber}
          count={data ? data.totalCount : 0}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </Fragment>
  );
};
export default connect(null, { showModal, hideModal })(FailedWithdrawals);
