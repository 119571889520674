import React from "react";
import CreditBureauCheckTablesAddressHistory from "./CreditBureauCheckTableCategories/CreditBureauCheckTablesAddressHistory";
import CreditBureauCheckTablesClassificationInsType from "./CreditBureauCheckTableCategories/CreditBureauCheckTablesClassificationInsType";
import CreditBureauCheckTablesClassificationProdType from "./CreditBureauCheckTableCategories/CreditBureauCheckTablesClassificationProdType";
import CreditbureauTableCustomerDetails from "./CreditBureauCheckTableCategories/CreditbureauTableCustomerDetails";
import CreditBureauTableContactHistory from "./CreditBureauCheckTableCategories/CreditBureauTableContactHistory";
import CreditBureauTableCreditScoreDetails from "./CreditBureauCheckTableCategories/CreditBureauTableCreditScoreDetails";
import CreditBureauTableProfileOverview from "./CreditBureauCheckTableCategories/CreditBureauTableProfileOverview";
import CreditBureauTableInquiryHistoryDetails from "./CreditBureauCheckTableCategories/CreditBureauTableInquiryHistoryDetails";
import CreditBureauTableInquiryProduct from "./CreditBureauCheckTableCategories/CreditBureauTableInquiryProduct";
import CreditBureauTableReportDetailBvn from "./CreditBureauCheckTableCategories/CreditBureauTableReportDetailBvn";
import CreditBureauTableSummaryPerformance from "./CreditBureauCheckTableCategories/CreditBureauTableSummaryPerformance";
import CreditBureauTableFacilityHistory24 from "./CreditBureauCheckTableCategories/CreditBureauTableFacilityHistory24";

const CreditBureauCheckTableData = ({ creditBureauCheckData }: any) => {
  return (
    <div>
      <h3 className="d-flex justify-content-center mt-3">
        Customer Credit Bureau Data
      </h3>
      <CreditbureauTableCustomerDetails
        consCommDetails={creditBureauCheckData?.consCommDetails}
      />
      <CreditBureauTableContactHistory
        contactHistory={creditBureauCheckData?.contactHistory}
      />
      <CreditBureauCheckTablesAddressHistory
        addressHistory={creditBureauCheckData?.addressHistory}
      />

      <CreditBureauTableProfileOverview
        creditProfileOverview={creditBureauCheckData?.creditProfileOverview}
      />
      <CreditBureauTableSummaryPerformance
        summaryOfPerformance={creditBureauCheckData?.summaryOfPerformance}
      />
      {/* Classification Of Active Accounts By Institution Type */}

      <CreditBureauCheckTablesClassificationInsType
        classificationInsType={creditBureauCheckData?.classificationInsType}
      />

      {/* */}
      <CreditBureauCheckTablesClassificationProdType
        classificationProdType={creditBureauCheckData?.classificationProdType}
      />
      {/* Closed Accounts */}

      <CreditBureauTableCreditScoreDetails
        creditScoreDetails={creditBureauCheckData?.crediT_SCORE_DETAILS}
      />

      <CreditBureauTableInquiryProduct
        inquiryProduct={creditBureauCheckData?.inquiry_Product}
      />
      <CreditBureauTableInquiryHistoryDetails
        inquiryHistoryDetails={creditBureauCheckData?.inquiryHistoryDetails}
      />
      <CreditBureauTableReportDetailBvn
        reportDetailBVN={creditBureauCheckData?.reportDetailBVN}
      />
      <CreditBureauTableFacilityHistory24
        creditFacilityHistory24={creditBureauCheckData?.creditFacilityHistory24}
      />
    </div>
  );
};

export default CreditBureauCheckTableData;
