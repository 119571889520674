import React, { FunctionComponent, ChangeEvent } from "react";
import CustomSelectDropdown from "../../../NewComponents/TypedComponents/CustomHTMLElements/CustomSelectDropdown";

interface RequestsSearchAndSizeControlsProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  size: number;
  onSizeChange: (value: number) => void;
}

const RequestsSearchAndSizeControls: FunctionComponent<
  RequestsSearchAndSizeControlsProps
> = ({ searchValue, onSearchChange, size, onSizeChange }) => (
  <div className="d-flex align-items-center justify-content-between mb-3">
    <div className="d-flex flex-wrap justify-content-end align-items-center">
      <div className="input-group mr-3 mt-3" style={{ maxWidth: "200px" }}>
        <input
          type="search"
          className="form-control"
          placeholder="Search..."
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSearchChange(e.target.value)
          }
        />
      </div>
      <div style={{ width: "150px", marginTop: "10px" }}>
        <CustomSelectDropdown
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            onSizeChange(Number(e.target.value))
          }
          value={size}
        >
          <option value={10000}>All</option>
          <option value={10}>10 Entries</option>
          <option value={20}>20 Entries</option>
          <option value={50}>50 Entries</option>
          <option value={100}>100 Entries</option>
        </CustomSelectDropdown>
      </div>
    </div>
  </div>
);

export default RequestsSearchAndSizeControls;
