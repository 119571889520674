export const getMonthArray = (arr) => {
  return arr.map((item) => item.month);
};
export const getLineValuesArray = (arr) => {
  return arr.map((item) => parseInt(item.amount));
};

function snakeToCamelCase(str) {
  return str.replace(/[_]\w/g, (match) => match[1].toUpperCase());
}

export function convertObjToCamelCase(inputObj) {
  if (typeof inputObj !== "object" || inputObj === null) {
    return inputObj;
  }

  // handle arrays separately
  if (Array.isArray(inputObj)) {
    return inputObj.map((item) => convertObjToCamelCase(item));
  }

  return Object.keys(inputObj).reduce((accumulator, key) => {
    let camelCaseKey = snakeToCamelCase(key);
    accumulator[camelCaseKey] = convertObjToCamelCase(inputObj[key]);
    return accumulator;
  }, {});
}
