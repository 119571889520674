import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

interface IDashBoardDisbursementGraph {
  aggregatorId: number;
  filter: number;
  currentCurrencyValue: string;
}
const getDashboardDisbursementsGraph = async ({
  aggregatorId,
  filter,
  currentCurrencyValue,
}: IDashBoardDisbursementGraph) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.DASHBOARD_DISBURSEMENT_GRAPH}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&filter=${filter}`,
  );
  return data;
};

export default function useDashboardDisbursementGraph({
  aggregatorId,
  filter,
  currentCurrencyValue,
}: IDashBoardDisbursementGraph) {
  return useQuery(
    [
      { aggregatorId, filter, currentCurrencyValue },
      "getDashboardDisbursementsGraph",
    ],
    getDashboardDisbursementsGraph,
    {
      enabled: currentCurrencyValue && aggregatorId ? true : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    },
  );
}
