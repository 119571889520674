import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFunderDetails, { TParams } from "../../custom-hooks/useFunderDetails";
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../Pagination/Pagination.component";
import { showModal } from "../../redux/actions/modal";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import { appInsights } from "../../config/appInsights";
import { getBlobData } from "../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { RootState } from "../../redux/store";
import useAllAggregatorsTyped from "../../custom-hooks/useAllAggregatorsTyped";

const Investor = () => {
  const [preState, setPreState] = useState<TParams>({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
  });
  const [state, setState] = useState<TParams>({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    startDate: "",
    endDate: "",
    searchValue: "",
    aggregatorId: "",
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const [count, setCount] = useState(null);

  const dispatch = useDispatch();
  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investors  - (Investor.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data, status, error } = useFunderDetails(preState);
  const { data: aggregatorData } = useAllAggregatorsTyped();
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const resetTable = () => {
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  const modalHandler = (modalData: any, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate!).valueOf() - new Date(state.startDate!).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      let queryParams = `?Page=${preState?.pageNumber}&PageSize=${preState?.size}`;
      if (preState?.searchBy) {
        queryParams = `${queryParams}&FunderType=${preState?.searchBy}`;
      }
      if (preState?.startDate) {
        queryParams = `${queryParams}&from_date=${preState?.startDate}`;
      }
      if (preState?.endDate) {
        queryParams = `${queryParams}&to_date=${preState?.endDate}`;
      }
      if (preState?.searchValue) {
        queryParams = `${queryParams}&FunderName=${preState?.searchValue}`;
      }
      if (preState?.FunderId) {
        queryParams = `${queryParams}&FunderId=${preState?.FunderId}`;
      }
      if (preState?.aggregatorId) {
        queryParams = `${queryParams}&AggregatorId=${preState?.aggregatorId}`;
      }
      const response = await getBlobData(
        `${ajaxEndpoints.DOWLOAD_INVESTORS}${queryParams}`
      );
      if (response?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${
            preState.startDate && preState.endDate
              ? `Investor list from ${preState.startDate} to ${preState.endDate}.csv`
              : `investor_list.csv`
          }`
        );
        link.click();
      }
    } catch (error) {}
    setIsDownloading(false);
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Investors
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading investors..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investors, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no investor.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Business Name</th>
                  <th>Name</th>
                  <th>Aggregator</th>
                  <th>Investor Type</th>
                  <th>Contact Email</th>
                  <th>Gender</th>
                  <th>KYC Scores</th>
                  <th>Country</th>
                  <th>Created Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      email,
                      funder_id,
                      first_name,
                      last_name,
                      funder_type,
                      pub_date,
                      gender,
                      country,
                      biz_name,
                      aggregator_name,
                      kyc_score,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>
                          {(preState.pageNumber - 1) * preState.size +
                            (_id + 1)}
                        </td>
                        <td>{biz_name || "N/A"}</td>
                        <td>
                          {first_name} {last_name}
                        </td>
                        <td>{aggregator_name || "N/A"}</td>
                        <td>{funder_type}</td>
                        <td>{email}</td>
                        <td>{gender}</td>
                        <td>{kyc_score}</td>
                        <td>{country}</td>
                        <td>{pub_date?.substring(0, 10)}</td>
                        <td>
                          <Link
                            to={`/investor/${funder_id}`}
                            className="color-blue"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">Investors</h3>
            {loggedInUser?.roles.includes(
              ROLE_TYPES.CUSTOMER_CREATION_INITIATOR
            ) && (
              <button
                type="button"
                className="btn advancly-white-btn btn-md m-0"
                onClick={() =>
                  dispatch(
                    showModal({
                      modalIdentifier: "investors",
                      action: "signupinvestor",
                    })
                  )
                }
              >
                Create Investors
              </button>
            )}
          </div>
          <div className="d-flex flex-row flex-wrap">
            <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
              <div className="input-group mr-3" style={{ maxWidth: "200px" }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Funder Name..."
                  value={state.searchValue}
                  name="searchValue"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div
                style={{ width: "150px", marginBottom: "10px" }}
                className="mr-3"
              >
                <CustomSelectDropdown
                  onChange={(e) => handleChange(e)}
                  name="searchBy"
                  value={state.searchBy}
                  className="round user__form form-control"
                >
                  <option value={3}>All Investors</option>
                  <option value={1}>Individual Investors</option>
                  <option value={2}>Corporate Investors</option>
                </CustomSelectDropdown>
              </div>
              <div
                style={{ width: "150px", marginBottom: "10px" }}
                className="mr-3"
              >
                <CustomSelectDropdown
                  onChange={(e) => handleChange(e)}
                  name="aggregatorId"
                  value={state.aggregatorId}
                  className="round user__form form-control"
                >
                  <option value="">Search By Aggregator</option>
                  {aggregatorData &&
                    aggregatorData?.data?.data.map((aggregator) => {
                      const { biz_name, aggregator_id } = aggregator;
                      return (
                        <option
                          value={Number(aggregator_id)}
                          key={aggregator_id}
                        >
                          {biz_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>

              <div className="form-group mr-3 mt-2">
                <div className="input-group ">
                  <label className="mr-2">From </label>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={state.startDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-group mt-2 mr-3">
                <div className="input-group">
                  <label className="mr-2">To </label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "7px" }}>
                <button
                  type="submit"
                  className="btn advancly-btn btn-md"
                  value="submit"
                  // disabled={loading}
                >
                  Search
                </button>
              </div>
              <div className="ml-2" style={{ marginBottom: "7px" }}>
                <button
                  type="button"
                  className="btn advancly-btn btn-md"
                  onClick={handleDownload}
                  disabled={isDownloading}
                >
                  {isDownloading ? <ButtonLoader /> : "Download"}
                </button>
              </div>
            </form>
          </div>
          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          {data && data?.data && (
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber: number) =>
                setPreState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Investor;
