import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getApprovalTrail = async (trans_ref: string) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_APPROVAL_TRAIL}?transaction_reference=${trans_ref}`
  );
  return data;
};

export default function useApprovalTrail(trans_ref: string) {
  return useQuery([trans_ref, "getApprovalTrail"], getApprovalTrail, {
    refetchOnWindowFocus: false,
  });
}
