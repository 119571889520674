import { ChangeEvent, Fragment } from "react";

import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import { useDispatch } from "react-redux";
import moment from "moment";
import { showModal } from "../../../redux/actions/modal";
import useAllAdminUsers from "../../../custom-hooks/useAllAdminUsers";
import useAllRoles from "../../../custom-hooks/useAllRoles";
import { useState } from "react";
import {
  postDataWithDotNet,
  putDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import Paginator from "../../Pagination/Pagination.component";
import { appInsights } from "../../../config/appInsights";
import useUserTypes from "../../../custom-hooks/userUserTypes";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import useAllRoleCategories from "../../../custom-hooks/useRoleCategories";
import useAllRoleByCategories from "../../../custom-hooks/useRolesByCategory";

type TAddUser = {
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  roleIds: number[];
};

const ManageAdminUsers = () => {
  const [loading, setLoading] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [roleName, setRoleName] = useState("Super Admin");
  const [searchByEmailQuery, setSearchByEmailQuery] = useState("");
  const [userType, setUserType] = useState("");
  const [categoryId, setCategoryId] = useState<number | null>(null);

  const dispatch = useDispatch();

  const { data: userTypeObject } = useUserTypes();
  const { data: roleCategories } = useAllRoleCategories();
  const { data: rolesbyCategory, isLoading: isLoadingRoles } =
    useAllRoleByCategories(categoryId);

  const {
    data,
    error: dataError,
    status,
  } = useAllAdminUsers({
    role_id: roleId.toString(),
    Page: pageNumber.toString(),
    PageSize: size.toString(),
    search_value: searchByEmailQuery,
    user_type: userType,
  });

  const {
    data: rolesData,
    error: rolesError,
    status: rolesStatus,
  } = useAllRoles();

  const cleanupRoleList = (
    _roleList: { id: number; name: string }[] | undefined
  ) => {
    if (_roleList && _roleList.length > 0) {
      const result = _roleList.map((_item) => {
        return {
          label: _item?.name,
          value: _item?.id,
        };
      });

      return result;
    }
    return [];
  };

  const handleChangeRoleSelection = (_selectedRole: {
    label: string;
    value: number;
  }) => {
    return _selectedRole ? setRoleId(_selectedRole?.value) : setRoleId(0);
  };

  const cleanupCategoryList = (
    _categoryList: { id: number; name: string }[] | undefined
  ) => {
    if (_categoryList && _categoryList.length > 0) {
      const result = _categoryList.map((_item) => {
        return {
          label: _item?.name,
          value: _item?.id,
        };
      });

      return result;
    }
    return [];
  };

  const handleChangeCategorySelection = (_selectedCategory: {
    label: string;
    value: number;
  }) => {
    return setCategoryId(_selectedCategory?.value);
  };

  //  Methods
  const modalHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "createuser",
        dataRecord: modalData,
        action: "customrange",
      })
    );
  };

  const handleChangeUserType = (e: ChangeEvent<HTMLSelectElement>) => {
    setPageNumber(1);
    setUserType(e.target.value);
  };

  const handleSearch = () => {
    setPageNumber(1);
    setSearchByEmailQuery(searchValue);
  };

  const updateUser = async (reqBody: any) => {
    setLoading("Updating User...");
    setError(null);
    try {
      await putDataWithDotNet(ajaxEndpoints.MANAGE_ADMIN_USER, reqBody);
      setLoading(null);
      setSuccess("User has been updated.");

      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageAdminUsers.component.jsx",
        },
      });
      setLoading(null);
      setError(errorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const addUser = async (reqBody: TAddUser) => {
    setLoading("Adding new user...");
    setError(null);
    try {
      await postDataWithDotNet(ajaxEndpoints.MANAGE_ADMIN_USER, reqBody);
      setLoading(null);
      setSuccess("New user has been added.");
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageAdminUsers.component.jsx",
        },
      });
      setLoading(null);
      // setError(errorHandler(error));
      setError(error?.response?.data?.data[0]);
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  const updateUserRoles = async (reqBody: any) => {
    setLoading("Updating user roles...");
    setError(null);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.ASSIGN_ROLE,
        reqBody
      );

      setLoading(null);
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "success",
            message: "New roles have been added to this user.",
          },
        })
      );
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageAdminUsers.component.jsx",
        },
      });
      setLoading(null);
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data,
          },
        })
      );
    }
  };

  const resetTable = () => {
    setPageNumber(1);
  };

  //JSX Template
  let RenderedComponent = null;
  if (
    (!data && status !== "loading") ||
    (!rolesData && rolesStatus !== "loading")
  ) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch users who have{" "}
            {roleName} roles
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading" || rolesStatus === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading users" />;
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else if (error) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> {error}
          </h4>
        </div>
      </div>
    );
  } else {
    if (rolesError || dataError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              users who have {roleName} role, Please Reload The Page.
            </h4>
          </div>
        </div>
      );
    } else if (data && data?.data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No users available
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="grid__padding animated fadeInRight">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>User Type</th>
                <th>Phone Number</th>
                <th>Date Joined</th>
                <th colSpan={2}>Last Login</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                data?.data?.length &&
                data?.data.map((user: any, _id: number) => {
                  const {
                    email,
                    user_type,
                    date_joined,
                    first_name,
                    last_name,
                    last_login,
                    phone,
                  } = user;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{email}</td>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                      <td>{user_type}</td>
                      <td>{phone}</td>
                      <td>{moment(date_joined).format("llll")}</td>
                      <td colSpan={2}>{moment(last_login).format("llll")}</td>
                      <td className="space-items">
                        <div className="dropdown">
                          <button
                            className="btn zoom-element btn-slim dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span
                              className="cursor-pointer dropdown-item"
                              onClick={() => {
                                modalHandler({
                                  type: "view_user",
                                  user,
                                });
                              }}
                            >
                              VIEW
                            </span>
                            <span
                              className="cursor-pointer dropdown-item"
                              onClick={() => {
                                modalHandler({
                                  type: "update_user",
                                  user,
                                  action: updateUser,
                                });
                              }}
                            >
                              UPDATE
                            </span>
                            <span
                              className="cursor-pointer dropdown-item"
                              onClick={() => {
                                modalHandler({
                                  type: "update_user_role",
                                  data: {
                                    rolesData,
                                    user,
                                  },
                                  action: updateUserRoles,
                                });
                              }}
                            >
                              UPDATE ROLE(S)
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={data?.totalCount}
            changeCurrentPage={(pageNumber: any) => setPageNumber(pageNumber)}
          />
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-end m-b-1">
        <div className="row animated fadeInRight m-x-1">
          <div className="col-lg-12 col-md-12 col-sm-12 text-right">
            <button
              className="create zoom-element btn login-btn"
              onClick={() => {
                modalHandler({
                  type: "add_user",
                  rolesData,
                  action: addUser,
                });
              }}
            >
              <i className="fas fa-user-plus" /> &nbsp; Create User
            </button>
          </div>
        </div>
      </div>
      <div
        className="row animated fadeInRight m-x-1 m-t-1"
        style={{
          display: "flex",
          justifyContent: "space-around",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="mr-3">
            <CustomSearchDropDown
              placeholder="Select role category"
              defaultOptions={cleanupCategoryList(roleCategories)}
              inputWidth={100}
              onChange={handleChangeCategorySelection}
              isSearchable={false}
            />
          </div>

          {isLoadingRoles ? (
            <div style={{ marginTop: "0.5rem" }}>
              <ButtonLoader />
            </div>
          ) : (
            <div style={{ minWidth: "15rem", marginLeft: "3rem" }}>
              {categoryId && (
                <CustomSearchDropDown
                  placeholder="Filter By Role"
                  defaultOptions={cleanupRoleList(rolesbyCategory?.roles)}
                  inputWidth={100}
                  onChange={handleChangeRoleSelection}
                  isSearchable={false}
                />
              )}
            </div>
          )}
        </div>
        <div>
          <div className="d-flex flex-row flex-wrap justify-content-start">
            <label htmlFor="table-fetch" className="form-group">
              Number of results:{" "}
              <select
                onChange={(e) => {
                  setSize(Number(e.target.value));
                  resetTable();
                }}
                id="table-fetch"
                className="table-fetch-select m-l-1"
                value={size}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </label>
          </div>
        </div>
        <div>
          <div>
            <label htmlFor="table-fetch" className="form-group">
              User Type:{" "}
              <select
                onChange={handleChangeUserType}
                id="table-fetch"
                className="table-fetch-select"
              >
                <option value="">All</option>
                {userTypeObject &&
                  userTypeObject?.data.length > 0 &&
                  userTypeObject?.data.map(
                    (_userType: { id: number; name: string }) => {
                      const { name, id } = _userType;
                      return (
                        <option value={name} key={id}>
                          {name}
                        </option>
                      );
                    }
                  )}
              </select>
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center m-b-1">
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <input
            className="form-control mr-3"
            type="text"
            placeholder="Search user by email"
            aria-label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          ></input>
          <button
            className="create zoom-element btn m-0 login-btn"
            style={{ height: "2.2rem" }}
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>

      <div>{RenderedComponent}</div>
    </Fragment>
  );
};

export default ManageAdminUsers;
