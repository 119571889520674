import React from "react";

// Redux
import { useDispatch } from "react-redux";
import { setToast } from "../../../../redux/actions/toast";

// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../config/appInsights";

const Retry = (props: any) => {
  const { subModal } = props.properties;

  const dispatch = useDispatch();

  const disburseLoan = (e: any) => {
    e.preventDefault();

    const params = { loan_id: props?.properties.dataRecord.loan_id };
    const disbursementConfirm = document.querySelector("#disbursement-confirm");
    disbursementConfirm?.classList.add("hidden");

    const disbursementLoader = document.querySelector("#disbursement-loader");
    disbursementLoader?.classList.remove("hidden");

    const disbursementError = document.querySelector("#disbursement-error");

    ajaxMethods
      .post(ajaxEndpoints.RETRY_DISBURSEMENT, params)
      .then((response) => {
        if (response.status === 200) {
          disbursementLoader?.classList.add("hidden");

          const disbursementSuccess = document.querySelector(
            "#disbursement-success"
          );
          disbursementSuccess?.classList.remove("hidden");

          setTimeout(() => {
            dispatch(setToast("success", response.data.message));
            props.onCloseModal();
          }, 3000);
        } else if (response.status === 400) {
          disbursementLoader?.classList.add("hidden");

          disbursementError?.classList.remove("hidden");
          setTimeout(() => {
            dispatch(setToast("warning", response.data.message));
            return props.onCloseModal();
          }, 3000);
        } else {
          disbursementLoader?.classList.add("hidden");
          disbursementError?.classList.remove("hidden");
          setTimeout(() => {
            dispatch(setToast("warning", response.data.message));
            return props.onCloseModal();
          }, 3000);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Retry.js",
          },
        });
        disbursementLoader?.classList.add("hidden");
        disbursementError?.classList.remove("hidden");
        setTimeout(() => {
          dispatch(
            setToast("warning", "Unable to connect to Advancly services")
          );

          return props.onCloseModal();
        }, 3000);
      });
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        {(() => {
          switch (subModal) {
            case "single":
              return (
                <React.Fragment>
                  {/* <h3 className="Modal__title p-b-1">Retry Summary</h3> */}
                  {/* 2. Confirm Update Role Modal*/}
                  <div id="disbursement-confirm">
                    <div className="m-b-30">
                      <h5>
                        Are you sure you want to retry the following failed
                        disbursement?{" "}
                      </h5>
                      {/* <p>Borrower Name: {`${borrower_name}`}</p>
                                              <p>Loan Amount: {loan_amount}</p> */}

                      <div className="Form__action-buttons m-t-2">
                        <button
                          type="button"
                          onClick={disburseLoan}
                          className="btn zoom-element btn login-btn m-0 mr-auto"
                        >
                          <i className="fa fa-check-circle" /> Proceed
                        </button>
                        <button
                          type="button"
                          onClick={props.onCloseModal}
                          className="btn zoom-element btn login-btn m-0 ml-auto"
                        >
                          <i className="fa fa-times-circle" /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 2. Disbursement Loader*/}
                  <div
                    id="disbursement-loader"
                    className="hidden loading-overlay speed-1x fadeInRight"
                  >
                    <div className=" form-loader form-loader-login verify">
                      <div className="centerLoader">
                        <div className="loading" id="adv-loader-alt" />
                        <div className="center-txt webapp-ripple-text">
                          <i className="notifier fas fa-user-cog" />
                          Processing Disbursement ...
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3. Disbursement Success Message*/}
                  <div
                    id="disbursement-success"
                    className="hidden loading-overlay speed-1x fadeInRight"
                  >
                    <div className="form-loader form-loader-login verify">
                      <div className="centerLoader">
                        <div className="loading success-loader">
                          <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                        </div>
                        <div className="center-txt webapp-ripple-text">
                          <i className="notifier far fa-check-circle" />
                          Disbursement Successful
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 4. Disbursement Error */}
                  <div
                    id="disbursement-error"
                    className="hidden loading-overlay speed-1x fadeInRight"
                  >
                    <ErrorLoader
                      icon="fas fa-wifi"
                      text="Network Issue"
                      subText="Unable to connect to Advancly services."
                    />
                  </div>
                </React.Fragment>
              );

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default Retry;
