import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import "./PendingLoanModal.css";
// Select Action
import Approve from "./ApproveMakerChecker/ApproveMakerChecker";
import Reject from "./RejectMakerChecker/RejectMakerChecker";
import Detail from "./DetailMakerChecker/DetailMakerChecker";

class PendingLoansModal extends Component {
  render() {
    const { properties } = this.props;
    const { action } = properties;

    return (
      <div
        className="Modal__dialog lg-modal animated slideInDown"
        role="document"
      >
        {(() => {
          switch (action) {
            case "details":
              return (
                <Detail
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "approve":
              return (
                <Approve
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "deny":
              return (
                <Reject
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loansForDisbursement } = state.loansReducer;

  return {
    loansForDisbursement,
  };
};

export default connect(mapStateToProps)(PendingLoansModal);
