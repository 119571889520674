import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getPendingDisbursements = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.GET_PENDING_DISBURSEMENT
  );
  return data;
};

export default function usePendingDisbursements() {
  return useQuery(["getPendingDisbursements"], getPendingDisbursements, {
    refetchOnWindowFocus: false,
  });
}
