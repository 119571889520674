import { useState, Fragment } from "react";

import { useLocation } from "react-router-dom";
import InvestorInvestment from "./InvestorInvestment";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import PrincipalOrInterestModal from "../../Components/PrincipalOrInterestModal/PrincipalOrInterestModal";
import RollOverModal from "../../Components/RollOverModal/RollOverModal";
import CustomSearchDropDown from "../../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import FundInvestmentModal from "../../Components/FundInvestmentModal";
import WithdrawInvestmentModal from "../../Components/WithdrawInvestmentModal";
import { RollOverType } from "./types";

export interface ISelectedRecord {
  deposit_type: string;
  interest_repayment_type: string;
  internal_payment_reference: string;
  tenure: number;
  is_roll_over: boolean;
  currency: string;
  principal_amount: number;
  cba_product_id: number;
  id: number;
  funder_id: number;
  investment_product_id: number;
  investment_product_details_id: number;
}

interface ICurrencyCodes {
  currency_code: string;
}

const EachInvestorInvestment = () => {
  const [selectedRollOverType, setSelectedRollOverType] =
    useState<RollOverType | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<ISelectedRecord | undefined>();
  const [selectedFund, setSelectedFund] = useState("");

  const location = useLocation();
  const {
    id,
    data,
    investment_product_id,
    investment_product_name,
    currency_codes,
  } = location?.state as {
    id: string;
    data: any;
    investment_product_id: string;
    investment_product_name: string;
    currency_codes: ICurrencyCodes[];
  };

  const handleChangeFund = (item: { value: string }) => {
    setSelectedFund(item?.value);
  };

  const getDefaultOptions = (arrayInQuestion: ICurrencyCodes[]) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      let result = arrayInQuestion.map((item) => {
        return {
          label: item?.currency_code,
          value: item?.currency_code,
        };
      });

      return result;
    }

    return [];
  };

  return (
    <Fragment>
      <div className="animated fadeInRight">
        <div className="d-flex flex-row  mt-2 mb-1">
          <h3 className="page-title d-flex align-items-center">
            <BackButtonComponent /> {investment_product_name}
          </h3>
        </div>

        <div className="w-100">
          <div className="col-4 mb-3">
            <CustomSearchDropDown
              placeholder="Select Currency"
              onChange={handleChangeFund}
              defaultOptions={getDefaultOptions(currency_codes)}
              name="fund_currencies"
              isShowLabel={false}
              isMulti={false}
              isSearchable={false}
            />
          </div>
        </div>

        <hr />

        <div>
          {!selectedFund && (
            <div className="centerLoader">
              <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
                <h4 className="center-txt p-y-5">
                  <i className="fas fa-bell" /> No currency has been selected.
                  Please select currency
                </h4>
              </div>
            </div>
          )}

          {selectedFund && (
            <InvestorInvestment
              id={id}
              data={data}
              investment_product_id={investment_product_id}
              setSelectedRecord={setSelectedRecord}
              selectedFund={selectedFund}
            />
          )}
        </div>
      </div>

      <PrincipalOrInterestModal
        setSelectedRollOverType={setSelectedRollOverType}
      />
      {
        selectedRecord &&
          <>
            <RollOverModal
                selectedRollOverType={selectedRollOverType}
                selectedRecord={selectedRecord}
            />
            <FundInvestmentModal selectedRecord={selectedRecord} />
            <WithdrawInvestmentModal selectedRecord={selectedRecord} />
          </>
      }

    </Fragment>
  );
};

export default EachInvestorInvestment;
