import { Fragment, useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "./../../NewComponents/ToggleTab/ToggleTab.component";
import AggregatorOnboardingReviewModal from "./AggregatorOnboardingReviewModal.component";
import useAnAggregatorOnboardingParameters from "./../../custom-hooks/useAnAggregatorOnboardingParameters";
import { Loader } from "./../../UI/Loaders/Loaders";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { postData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "./../../NewComponents/Alert/Alert.component";
import { ReactComponent as ErrorIcon } from "../../assets/svg/icons/error-icon.svg";
import Toggler from "../../NewComponents/Toggler/Toggler.component";
import useAggregatorEnvironment from "../../custom-hooks/useAggregatorEnvironment";
import { useFilter } from "../LoanEligibilityQuestions/EligibilityQuestions/useFilter";
import { appInsights } from "../../config/appInsights";
import { onBoardingStatusCode } from "./util";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import BoardOfDirectors from "./Tabs/BoardOfDirectors/BoardOfDirectors";
import Shareholders from "./Tabs/Shareholders/Shareholders";
import SeniorManagement from "./Tabs/SeniorManagement/SeniorManagement";
import OnboardingDocuments from "./Tabs/Documents/OnboardingDocuments";
import BusinessInformation from "./Tabs/BusinessInformation/BusinessInformation";
import { getBlobData } from "../../newApis/dotNetApiMethods";
import { showModal } from "../../redux/actions/modal";

import styles from "./AggregatorOnboardingReview.module.scss";

export default function AggregatorOnboardingReview() {
  const [activeTab, setActiveTab] = useState("business-information");
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [filterOptions, handleChange, filterParam] = useFilter();
  const [documentOwnerType, setDocumentOwnerType] = useState(1);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const dismissModalRef = useRef();
  const match = useRouteMatch();
  const { id } = match.params;
  const { search, state } = useLocation();
  const { customerId } = state;
  const searchParams = new URLSearchParams(search);
  const approved = searchParams.get("approved");
  const declined = searchParams.get("declined");
  const history = useHistory();

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);
  const dispatch = useDispatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: "Review Onboarding Request  - (AggregatorOnboardingReview.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data,
    status,
    error: onboardingParametersError,
    refetch,
  } = useAnAggregatorOnboardingParameters({
    customer_id: Number(customerId),
    productType: filterParam,
    documentOwnerType: Number(documentOwnerType),
  });

  const {
    data: environmentData,
    status: environmentStatus,
    error: environmentError,
  } = useAggregatorEnvironment(Number(customerId));

  useEffect(() => {
    if (activeTab === "legal-compliance") {
      setDocumentOwnerType(1);
    } else if (activeTab === "credit-risk") {
      setDocumentOwnerType(2);
    }
  }, [activeTab]);

  const declineRequest = async ({ message }) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    const reqBody = {
      customer_id: Number(customerId),
      comment: message,
    };
    try {
      await postData(
        ajaxEndpoints.DECLINE_AGGREGATOR_ONBOARDING_REQUEST,
        reqBody
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccess("Onboarding request has been declined.");

      setTimeout(() => {
        history.push("/aggregator-onboarding");
      }, 2000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "AggregatorOnboardingReview.jsx" },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setError(errorHandler(error));
    }
  };

  if (status === "loading" || environmentStatus === "loading") {
    return <Loader centered={true} text="Loading onboarding information..." />;
  }
  if (onboardingParametersError || environmentError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            aggregator's onboarding information, please try again.
          </h4>
        </div>
      </div>
    );
  }

  const isDisableDeclineApprovalRequest = (_statusCode) => {
    if (
      _statusCode === onBoardingStatusCode.LEGAL_IN_PROGRESS &&
      loggedInUser?.roles.includes(ROLE_TYPES.LEGAL_ONBOARDING_APPROVER)
    ) {
      return false;
    } else return true;
  };

  const handleDownloadAggregatorOnboardingRequest = async () => {
    setIsDownloadingReport(true);
    try {
      const response = await getBlobData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_ONBOARDING_REPORT}?customer_id=${customerId}`
      );

      if (response?.status === 200) {
        let blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "aggregator_onboarding_report.pdf");
        link.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: "Unable to download document. Contact admin",
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Server error: Unable to download document. Contact admin",
          },
        })
      );
    }
    setIsDownloadingReport(false);
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Review Request
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {loggedInUser?.roles.includes(
            ROLE_TYPES.LEGAL_ONBOARDING_APPROVER
          ) && (
            <button
              className="btn advancly-green-btn btn-md mr-3"
              onClick={handleDownloadAggregatorOnboardingRequest}
            >
              {isDownloadingReport ? "Downloading..." : "Download"}
            </button>
          )}

          {declined && declined === "true" ? (
            <button className="btn advancly-red-btn btn-md" type="button">
              <span className="d-flex align-items-center">
                Declined{" "}
                <span className="color-red ml-2">
                  <ErrorIcon color="red" />
                </span>
              </span>
            </button>
          ) : approved && approved === "true" ? (
            <Toggler aggregatorId={id} />
          ) : (
            <div>
              <button
                className="btn advancly-red-btn btn-md mr-2"
                data-toggle="modal"
                data-target="#addOnboardingReviewModal"
                onClick={() => setModalActionType("decline")}
                disabled={
                  loading ||
                  isDisableDeclineApprovalRequest(environmentData?.statusCode)
                }
              >
                {loading && modalActionType === "decline"
                  ? "Declining..."
                  : "Decline"}
              </button>
              <button
                className="btn advancly-btn btn-md"
                data-toggle="modal"
                data-target="#addOnboardingReviewModal"
                onClick={() => setModalActionType("approve")}
                disabled={
                  loading ||
                  isDisableDeclineApprovalRequest(environmentData?.statusCode)
                }
              >
                {loading && modalActionType === "approve"
                  ? "Approving..."
                  : "Approve Request"}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Aggregator:</div>
          <div className={styles.content}>
            {data?.basicinformation?.biz_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Contact Person:</div>
          <div className={styles.content}>
            {data?.basicinformation?.first_name}{" "}
            {data?.basicinformation?.last_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Contact Email:</div>
          <div className={styles.content}>
            {data?.basicinformation?.personal_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Start Date</div>
          <div className={styles.content}>
            {environmentData?.onboardingStartDate?.substring(0, 10)}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>End Date</div>
          <div className={styles.content}>
            {environmentData?.onboardingEndDate?.substring(0, 10)}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Onboarding Status</div>
          <div className={styles.content}>
            {data?.basicinformation?.onboarding_status}
          </div>
        </div>
      </div>

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Business Information"
          id="business-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Shareholders/Owners"
          id="shareholders"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Board of Directors"
          id="board-of-directors"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Senior Management"
          id="senior-management"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <ToggleTab
          text="Documents"
          id="onboarding-documents"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>
        {(() => {
          switch (activeTab) {
            case "business-information":
              return (
                <BusinessInformation
                  businessInfo={data && data?.basicinformation}
                  refetch={refetch}
                />
              );
            case "shareholders":
              return (
                <Shareholders
                  shareholders={data && data?.shareholders}
                  businessType={
                    data && data?.basicinformation?.business_type_id
                  }
                />
              );
            case "board-of-directors":
              return (
                <BoardOfDirectors
                  boardOfDirectors={data && data?.board_of_directors}
                  refetch={refetch}
                />
              );
            case "senior-management":
              return (
                <SeniorManagement
                  seniorManagement={data && data?.senior_managements}
                />
              );

            case "onboarding-documents":
              return (
                <OnboardingDocuments
                  uploadedDocuments={data && data?.documents}
                  handleChange={handleChange}
                  filterOptions={filterOptions}
                  activeTab={activeTab}
                  refetch={refetch}
                  environmentData={environmentData}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
      <AggregatorOnboardingReviewModal
        dismissModalRef={dismissModalRef}
        declineRequest={declineRequest}
        modalActionType={modalActionType}
        loading={loading}
        initialOnboardingCode={environmentData?.statusCode}
      />
    </Fragment>
  );
}
