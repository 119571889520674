import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getUserTypes = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.USER_TYPES);

  return data;
};

export default function useUserTypes() {
  return useQuery("getUserTypes", getUserTypes, {
    refetchOnWindowFocus: false,
  });
}
