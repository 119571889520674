import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getPendingLocalLiquidationSettings = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.COUNTRY_LIQUIDATION);
  return data.data;
};

export default function usePendingLocalLiquidationSettings() {
  return useQuery(
    ["getPendingLocalLiquidationSettings"],
    getPendingLocalLiquidationSettings
  );
}
