import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";

const ManageGlobalSettingsPromptModal = ({
  properties: { dataRecord },
  onCloseModal,
}) => {
  const {
    action,
    type,
    settings: { reference },
  } = dataRecord;

  const [comment, setComment] = useState("");
  const manageSettings = () => {
    onCloseModal();
    setTimeout(() => {
      action(reference, comment);
    }, 100);
  };
  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h2 className="Modal__title p-b-2 text-center font-weight-bold">
            {capitalizeFirstLetter(type)} Global Settings
          </h2>
          <h5 className="text-center">
            Are you sure you want to {type} this Global Settings?
          </h5>
          <hr />
          <form>
            <div className="row">
              <div className="col-12 mb-1">
                <label>Reference</label>
                <input
                  type="text"
                  className="form-control"
                  name="reference"
                  defaultValue={reference}
                  disabled
                ></input>
              </div>
              <div className="col-12 mb-1">
                <label>Comment</label>
                <input
                  type="text"
                  className="form-control"
                  name="comment"
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></input>
              </div>
              <div className="col-12 d-flex justify-content-end flex-wrap">
                <button
                  className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                  type="button"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                  type="submit"
                  disabled={!comment || !reference}
                  onClick={manageSettings}
                >
                  {capitalizeFirstLetter(type)}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ManageGlobalSettingsPromptModal;
