import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./assets/icons/fontawesome/css/all.css";
import "./assets/css/style.scss";
import "./assets/css/style-flex.css";
import "./assets/css/animate.min.css";
import "./assets/css/new-styles.scss";
import { version } from "../package.json";

import App from "./App";
import store from "./redux/store";
import CacheBuster from "react-cache-buster";
const isProduction = process.env.NODE_ENV === "production";

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction}
    isVerboseMode={true}
    loadingComponent={null}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </CacheBuster>,

  document.getElementById("root"),
);
