import { MDBTable } from "mdbreact";
import React, { Component, useState } from "react";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import Paginator from "../../../../components/Pagination/Pagination.component";

// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import { appInsights } from "../../../../config/appInsights";
import { errorHandler } from "../../../../helpers/errorHandler";
import { formatMoney } from "../../../../helpers/formatter";
import { getDataWithDotNet } from "../../../../newApis/dotNetApiMethods";

class FailedBatchDisbursementDetails extends Component {
  state = {
    scene: "form",
    formScene: "account_details",
    allLoanDetails: [],
    allLoanDetailsError: false,
    tabs: [
      {
        name: "Account",
        id: "account_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Loan",
        id: "loan_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Disbursement Gateway",
        id: "gateway_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
  };
  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      formScene: content,
    });
  };
  componentDidMount() {
    const key = this.props.properties.dataRecord.batchLoanDisbursementKey;
    if (key) {
      this.getAllLoanByKey(key);
    }
  }
  getAllLoanByKey = async (reference) => {
    try {
      const response = await getDataWithDotNet(
        ajaxEndpoints.GET_ALL_BATCH_DISBURSEMENT_DETAILS +
          "?batchdisbursementKey=" +
          reference
      );
      this.setState({
        allLoanDetailsError: false,
        allLoanDetails: response,
      });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FailedBatchDisbursement.jsx",
        },
      });
      this.setState({ allLoanDetailsError: errorHandler(error) });
    }
  };

  render() {
    // Get data from component state
    const { scene, tabs, formScene } = this.state;

    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            Failed Batch Disbursement Details
          </h3>
          {/* Partner Form */}
          {(() => {
            switch (scene) {
              case "form":
                return (
                  <form>
                    <div className="row m-b-1">
                      <Tabs
                        onContentToggle={this.toggleTabContent}
                        tabs={tabs}
                      />
                    </div>
                    {(() => {
                      switch (formScene) {
                        case "account_details":
                          return (
                            <AccountDetails
                              details={this.props.properties.dataRecord}
                            />
                          );

                        case "loan_details":
                          return (
                            <LoanDetails details={this.state.allLoanDetails} />
                          );
                        case "gateway_details":
                          return (
                            <LoanGatewayDetails
                              details={this.props.properties.dataRecord}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                    <div className="Form__action-buttons m-t-2">
                      {/* <button type="button"
                                                    className="btn zoom-element btn login-btn m-0 mr-auto"
                                                    onClick={this.printHandler}>
                                                    <i className="fas fa-print"></i>
                                                    &nbsp; Print
                                                </button> */}
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn"
                        onClick={this.props.onCloseModal}
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        &nbsp; Close
                      </button>
                    </div>
                  </form>
                );

              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

// Account Details
const AccountDetails = (props) => {
  const {
    creditAccountNumber,
    creditAccountName,
    creditBankName,
    debitAccountNumber,
    debitAccountName,
    debitBankName,
  } = props.details;

  return (
    <div className="m-b-2">
      <h5>DEBIT ACCOUNT</h5>
      <label className="label-txt m-t-1">Bank Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitBankName || "Unavailable"}
        />
        <i className="fa fa-bank" />
      </div>
      <label className="label-txt">Account Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitAccountName || "Unavailable"}
        />
        <i className="fas fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debitAccountNumber || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <br />
      <h5>CREDIT ACCOUNT</h5>

      <label className="label-txt m-t-1">Bank Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditBankName || "Unavailable"}
        />
        <i className="fa fa-bank " />
      </div>
      <label className="label-txt">Account Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditAccountName || "Unavailable"}
        />
        <i className="fa fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={creditAccountNumber || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
    </div>
  );
};
// Loan Details
const LoanDetails = (props) => {
  const loanDetails = props?.details?.loans;
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  let RenderedComponent = null;

  if (!loanDetails) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are currently no logged
            activities.
          </h4>
        </div>
      </div>
    );
  } else if ((loanDetails && loanDetails.length === 0) || !loanDetails) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are currently no logged
            activities.
          </h4>
        </div>
      </div>
    );
  } else {
    RenderedComponent = (
      <div className="row m-x-sm m-y-2 grid__padding">
        <MDBTable responsive minimal="true" bordered hover>
          <thead className="bg-lighter-grey">
            <tr>
              <th>S/N</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Amount</th>
              <th>Interest Rate</th>
              <th>Loan ref</th>
              <th>Loan Tenure</th>
              <th>Batch Number</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            {loanDetails &&
              loanDetails
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((batchDisbursementData, _id) => {
                  const {
                    currencyCode,
                    firstName,
                    lastName,
                    loanAmount,
                    loanRef,
                    pubDate,
                    loanTenure,
                    batchLoanNumber,
                    interestRate,
                  } = batchDisbursementData;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{formatMoney(loanAmount, currencyCode)}</td>
                      <td>{formatMoney(interestRate, currencyCode)}</td>
                      <td>{loanRef}</td>
                      <td>{loanTenure}</td>
                      <td>{batchLoanNumber}</td>
                      <td>{pubDate && pubDate.substring(0, 10)}</td>
                    </tr>
                  );
                })}
          </tbody>
        </MDBTable>
      </div>
    );
  }
  return (
    <div className="m-t-1">
      {/* All Batch Loans Ready for Disbursement Datatable || Loader || Error Message*/}
      {RenderedComponent}
      {loanDetails && loanDetails.length && (
        <Paginator
          size={size}
          page={pageNumber}
          count={loanDetails && loanDetails.length}
          changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
        />
      )}
    </div>
  );
};

// loan gateway details
const LoanGatewayDetails = (props) => {
  const {
    disbursementReferenceNo,
    disbursementGatewayResponse,
    disbursementGatewayStatusId,
    disbursementResponseDateTime,
    disbursementGatewayRequest,
    disbursementGatewayReferenceNo,
    disbursementChannelId,
    disbursementRequestDateTime,
    disbursementGatewayFinal,
  } = props.details;

  return (
    <div className="m-b-2">
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Gateway Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursementGatewayReferenceNo || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Gateway Status ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementGatewayStatusId || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Disbursement Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursementReferenceNo || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Channel ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementChannelId || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-12">
          <label className="label-txt">Disbursement Gateway Final:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementGatewayFinal || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-12">
          <label className="label-txt">Gateway Response:</label>
          <div className="form-group">
            <textarea
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementGatewayResponse || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-12">
          <label className="label-txt">Gateway Request:</label>
          <div className="form-group">
            <textarea
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursementGatewayRequest || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-12">
          <label className="label-txt">Response Date/Time</label>
          <div className="form-group">
            <input
              readOnly
              className="user__form form-control"
              value={new Date(disbursementResponseDateTime) || "Unavailable"}
            />
          </div>
        </div>

        <div className="col-md-12">
          <label className="label-txt">Request Date/Time</label>
          <div className="form-group">
            <input
              readOnly
              className="user__form form-control"
              value={new Date(disbursementRequestDateTime) || "Unavailable"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FailedBatchDisbursementDetails;
