import React, { ChangeEvent } from "react";
import styles from "./CustomSelect.module.scss";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { renderBadge } from "./helpers";

interface ICustomSelectDropdown {
  name?: string;
  reference?: React.LegacyRef<HTMLSelectElement> | undefined;
  children: React.ReactNode;
  label?: string;
  errors?: object;
  value?: any;
  onChange?: (arg: ChangeEvent<HTMLSelectElement>) => void;
  iconType?: string;
  className?: string;
  disabled?: boolean;
  isShowBadge?: boolean;
  badgeText?: string | null;
  defaultValue?: any;
  labelClass?: string;
}

const CustomSelectDropdown = ({
  name,
  reference,
  children,
  label,
  errors,
  value,
  onChange,
  iconType,
  className,
  disabled,
  isShowBadge = false,
  badgeText = null,
  defaultValue,
  labelClass,
}: ICustomSelectDropdown) => {
  return (
    <>
      <div className={`form-group ${styles.customSelect}`}>
        {!isShowBadge ? (
          <label className={labelClass}>
            {iconType ? <i className={iconType} /> : ""}
            {label}
          </label>
        ) : (
          <label className={styles.customLabelWithBadge}>
            <span>
              {iconType ? <i className={iconType} /> : ""}
              {label}
            </span>
            {badgeText && renderBadge(badgeText)}
          </label>
        )}

        <select
          className={`${styles.selectBox} ${className}`}
          name={name}
          ref={reference}
          value={value}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {children}
        </select>
      </div>
      <ErrorHandler errors={errors} />
    </>
  );
};

export default CustomSelectDropdown;
