import { Fragment } from "react";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import { TCountryCode } from "../../../../custom-hooks/useCountryCodes";
import { TBank } from "../../../../custom-hooks/useAllBanksByCountryCode";
import { ISubmitState } from "./SignUpInvestor";
import { TGender } from "../../../../custom-hooks/useAllGender";

interface ISignUpIndiv {
  countryCodes: TCountryCode[];
  allBanks: TBank[];
  handleSubmitChange:
    | ((e: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined
    | any;
  submitState: ISubmitState;
  genderData: TGender[];
}

export default function SignUpIndiv({
  countryCodes,
  allBanks,
  handleSubmitChange,
  genderData,
  submitState,
}: ISignUpIndiv) {
  return (
    <Fragment>
      <div className="col-md-6">
        <CustomInputField
          type="text"
          maxLength={30}
          label="Investor's First Name"
          placeholder="Enter First Name"
          name="first_name"
          onChange={(e) => handleSubmitChange(e)}
        />
      </div>
      <div className="col-md-6">
        <CustomInputField
          type="text"
          maxLength={30}
          label="Investor's Last Name"
          placeholder="Enter Last Name"
          name="last_name"
          onChange={(e) => handleSubmitChange(e)}
        />
      </div>
      <div className="col-12">
        <CustomInputField
          type="email"
          maxLength={128}
          name="email"
          placeholder="Enter Email Address"
          label="Email address"
          onChange={(e) => handleSubmitChange(e)}
        />
      </div>
      <div className="col-md-6 mb-1">
        <CustomSelectDropdown
          name="country_code"
          label="Country"
          className="round user__form form-control"
          onChange={handleSubmitChange}
        >
          <option value="">Choose Country</option>
          {countryCodes &&
            countryCodes.map((country, index) => {
              const { country_code, name } = country;
              return (
                <option value={country_code} key={index}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-6 mb-1">
        <CustomSelectDropdown
          name="gender"
          label="Gender"
          className="round user__form form-control"
          onChange={handleSubmitChange}
        >
          <option value="">Choose Gender</option>
          {genderData &&
            genderData.map((gender, index: number) => {
              const { name } = gender;
              return (
                <option value={name} key={index}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-6">
        <CustomInputField
          type="number"
          maxLength={20}
          name="phone_number"
          placeholder="07011111111"
          label="Investor Phone Number"
          onChange={(e) => handleSubmitChange(e)}
        />
      </div>
      <div className="col-md-6">
        <CustomSelectDropdown
          name="bank_id"
          label="Bank"
          className="round user__form form-control"
          onChange={handleSubmitChange}
        >
          <option value="">Choose Bank</option>
          {allBanks &&
            allBanks.map((bank, index) => {
              const { id, name } = bank;
              return (
                <option value={id} key={index}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-6">
        <CustomInputField
          type="number"
          maxLength={20}
          name="bank_account_number"
          placeholder=""
          label="Account Number"
          onChange={(e) => handleSubmitChange(e)}
        />
      </div>

      <div className="col-md-6">
        <CustomInputField
          type="number"
          maxLength={20}
          name="identification_number"
          placeholder=""
          label={`Identification Number ${
            submitState?.country_code === "NG"
              ? "(BVN)"
              : submitState?.country_code === "KE"
              ? "(National ID)"
              : ""
          }`}
          onChange={(e) => handleSubmitChange(e)}
        />
      </div>
    </Fragment>
  );
}
