import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import {
  subscribeToAggregator,
  subscriptionClose,
} from "./../../../redux/actions/funders";
import { setToast } from "./../../../redux/actions/toast";

// Custom UI Component
import { InfoLoader } from "../../../UI/Loaders/Loaders";

import "./EcosystemModal.css";

class EcosystemModal extends Component {
  subscribeHandler = (aggregatorId) => {
    const aggregatorIds = {
      mass_aggregator_id: aggregatorId,
    };

    this.props.subscribeToAggregator(aggregatorIds);
  };

  renderComponent = (
    { aggregator_id, biz_name, biz_category },
    { loading, error, message }
  ) => {
    if (loading) {
      return (
        <div className="loader">
          <div className="loading" id="adv-loader-alt" />
          <p className="">Processing subscription ....</p>
        </div>
      );
    } else if (error) {
      return (
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <InfoLoader
            text="There was an issue fetching your transactions."
            subText="Kindly reload the page."
          />
        </div>
      );
    } else if (message.trim() !== "") {
      setTimeout(() => {
        this.props.subscriptionClose();
        this.props.setToast("success", message);
        this.props.onCloseModal();
      }, 3000);
      return (
        <div className="animated fadeInRight loader">
          <i className="webapp-ripple-notification success-text fa fa-check-circle" />
          <p className="">Subscription successful</p>
        </div>
      );
    } else {
      return (
        <div className="p-y-2 subscribe">
          <div>
            <h3 className="m-t-0">{biz_name}</h3>
            <span className="muted">{biz_category}</span>
          </div>
          <div className="m-r-1">
            <button
              type="button"
              className="btn zoom-element btn login-btn m-0 mr-auto"
              onClick={() => this.subscribeHandler(aggregator_id)}
            >
              <i className="far fa-credit-card" />
              &nbsp; Subscribe
            </button>
          </div>
        </div>
      );
    }
  };

  componentDidMount() {}

  render() {
    // From props
    const { dataId, funderSubscription } = this.props;

    return (
      <div
        className="EcosystemModal Modal__dialog animated slideInDown"
        role="document"
      >
        <div className="Modal__content flex-row">
          <div className="left">
            <i className="fas fa-building fa-2x" />
          </div>
          <div className="right m-l-2">
            {this.renderComponent(dataId, funderSubscription)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ fundersReducer }) => {
  const { funderSubscription } = fundersReducer;
  return {
    funderSubscription,
  };
};

export default connect(mapStateToProps, {
  subscribeToAggregator,
  subscriptionClose,
  setToast,
})(EcosystemModal);
