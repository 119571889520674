import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { schemaWithOutPassword } from "./helper";
import CustomPhoneInput from "../../../../../components/CustomHTMLElements/CustomPhoneInput/CustomPhoneInput";
import styles from "./CreateUser.module.scss";
import CustomCheckBox from "../CustomCheckBox";
import useAllRoleCategories from "../../../../../custom-hooks/useRoleCategories";
import useAllRoleByCategories from "../../../../../custom-hooks/useRolesByCategory";
import DismissableBadge from "../DismissableBadge/DIsmissableBadge";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";
import CustomDropDownCard from "../../../../../components/CustomDropDownCard/CustomDropDownCard";

const CreateUserComponent = ({
  setErrorMessage,
  onCloseModal,
  dataRecord,
  scrollToTop,
  validatedEmail,
}) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const [preselectedPermissions, setPreSelectedPermissions] = useState([]);
  const [categoryRoleStore, setCategoryRoleStore] = useState([]);

  const { action } = dataRecord;

  const { register, handleSubmit, errors, setError, setValue } = useForm({
    resolver: yupResolver(schemaWithOutPassword),
  });

  const { data: roleCategories } = useAllRoleCategories();
  const { data: rolesbyCategory, isLoading: isLoadingRoles } =
    useAllRoleByCategories(selectedCategory?.value);

  useEffect(() => {
    setValue("email", validatedEmail);
  }, []);

  const handleDropdownChange = (data) => {
    return setSelectedCategory({ label: data?.label, value: data?.value });
  };

  const getRolesForRequest = (dataInQuestion) => {
    const roleArray = [];
    dataInQuestion.forEach((item) => {
      item.roles.forEach((_role) => {
        roleArray.push(_role);
      });
    });

    return roleArray;
  };

  const manageUser = (data) => {
    const payload = { ...data };

    const _roles = getRolesForRequest(categoryRoleStore);
    payload.roleIds = _roles;

    if (_roles.length < 1) {
      scrollToTop();

      setErrorMessage("You have not selected any role");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }

    onCloseModal();
    setTimeout(() => {
      delete data.confirmPassword;
      action(payload);
    }, 100);
  };

  const onChange = (e, id) => {
    if (e.target.checked) {
      const newRoles = [...preselectedPermissions, id];
      setPreSelectedPermissions(newRoles);
    } else {
      const newRoles = preselectedPermissions.filter(
        (_permissionId) => _permissionId !== id
      );
      setPreSelectedPermissions(newRoles);
    }
  };

  const getCheckedAndUnchecked = () => {
    let result = [];

    if (rolesbyCategory?.roles && rolesbyCategory?.roles.length > 0) {
      rolesbyCategory?.roles.forEach((_item) => {
        const isAlreadySelected = preselectedPermissions.some(
          (_permissionId) => _permissionId === _item?.id
        );

        if (isAlreadySelected) {
          result.push({ ..._item, isSelected: isAlreadySelected });
        } else {
          result.push({ ..._item, isSelected: false });
        }
      });

      return result;
    }

    return [];
  };

  const handleClose = (categoryDataInQuestion) => {
    const result = categoryRoleStore.filter(
      (_item) => _item.categoryId !== categoryDataInQuestion?.categoryId
    );
    const newPreselectedPermissions = [...preselectedPermissions];

    const filteredPreselectedPermissions = [];

    // Also remove roles from preselectedPermissions.
    // This way the user doesn't pick a category that isn't in the store and see selected roles.
    for (let i = 0; i < categoryDataInQuestion?.roles.length; i++) {
      const element = categoryDataInQuestion?.roles[i];

      const _result = newPreselectedPermissions.filter(
        (_item) => _item !== element
      );
      filteredPreselectedPermissions.push(_result);
    }

    setPreSelectedPermissions(filteredPreselectedPermissions);

    setCategoryRoleStore(result);
  };

  const handleSaveSelection = () => {
    const currentRoleByCategoryList = getCheckedAndUnchecked();
    const _selectedCategoryId = selectedCategory?.value;

    const result = currentRoleByCategoryList
      .filter((x) => x?.isSelected === true)
      .map((y) => y.id);

    //check if selected catefory already exists in roleCategory store.
    const categoryRoleObject = categoryRoleStore.find(
      (_item) => _item?.categoryId === _selectedCategoryId
    );
    if (categoryRoleObject) {
      const newCategoryRoleStore = [...categoryRoleStore];
      const indexOfResult = newCategoryRoleStore.indexOf(categoryRoleObject);
      newCategoryRoleStore[indexOfResult] = {
        categoryId: _selectedCategoryId,
        categoryName: selectedCategory?.label,
        roles: result,
      };

      setCategoryRoleStore(newCategoryRoleStore);
    } else {
      setCategoryRoleStore((prev) => {
        return [
          ...prev,
          {
            categoryId: _selectedCategoryId,
            categoryName: selectedCategory?.label,
            roles: result,
          },
        ];
      });
    }

    setSelectedCategory({});
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <small className="text-left">
        Please enter new user information below.
      </small>
      <form autoComplete="off" onSubmit={handleSubmit(manageUser)}>
        <div className={styles.DoubleInputContainer}>
          <div className="m-t-1">
            <CustomInputField
              type="text"
              maxLength="50"
              reference={register}
              name="firstName"
              placeholder="First Name"
              label="First Name"
              errors={errors.firstName}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "3rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className="m-t-1">
            <CustomInputField
              type="text"
              maxLength="50"
              reference={register}
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              errors={errors.lastName}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "3rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className={styles.InputContainer}>
          <CustomInputField
            type="email"
            maxLength="50"
            readOnly
            reference={register}
            name="email"
            placeholder="example@mail.com"
            label="Email"
            errors={errors.email}
            labelStyle={{ color: "#8B8699" }}
            customInputStyle={{
              height: "3rem",
              paddingLeft: "1rem",
              borderRadius: "10px",
            }}
          />
        </div>

        <div className={styles.InputContainer}>
          <CustomPhoneInput
            type="number"
            maxLength="20"
            reference={register}
            name="phone"
            placeholder="Phone Number"
            label="Phone Number"
            errors={errors.phone}
            labelStyle={{ color: "#8B8699" }}
            customInputStyle={{
              height: "3rem",
              paddingLeft: "3rem",
              borderRadius: "10px",
              width: "31.4rem",
            }}
          />
        </div>

        <div>
          {categoryRoleStore.map((_item, index) => (
            <DismissableBadge
              key={index}
              title={_item?.categoryName}
              badgeCount={_item?.roles.length}
              handleClose={() => handleClose(_item)}
            />
          ))}
        </div>

        <div className="m-t-2 m-b-2">
          <CustomSearchDropDown
            label="Select Role Categories"
            onChange={handleDropdownChange}
            defaultOptions={
              roleCategories &&
              roleCategories.map((_roleCategory) => ({
                label: _roleCategory?.name,
                value: _roleCategory?.id,
              }))
            }
            iconType={null}
            name="roleId"
            isSearchable={false}
            register={register}
            isShowLabel
            isMulti={false}
            inputHeight={47}
            labelStyle={{ color: "#8B8699" }}
          />
        </div>
        {selectedCategory?.value && (
          <CustomDropDownCard cardTitle="You can select multiple roles">
            {isLoadingRoles ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ButtonLoader />
              </div>
            ) : (
              <>
                {getCheckedAndUnchecked().map((_item, index) => (
                  <CustomCheckBox
                    isSelected={_item?.isSelected}
                    key={index}
                    id={_item?.id}
                    name={_item?.name}
                    onChange={(e) => onChange(e, _item?.id)}
                    isCheckBackground
                  />
                ))}
              </>
            )}
          </CustomDropDownCard>
        )}

        {selectedCategory?.value && (
          <button
            className="m-t-1 create btn advancly-white-btn w-100"
            style={{ borderRadius: "10px" }}
            type="button"
            onClick={handleSaveSelection}
          >
            Save Selection
          </button>
        )}

        <div className="Form__action-buttons m-t-1">
          <button
            type="button"
            className="btn zoom-element btn login-btn m-0 mr-auto"
            onClick={onCloseModal}
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Cancel
          </button>
          <button
            type="submit"
            className="btn zoom-element btn login-btn m-0 ml-auto"
            data-dismiss="modal"
            disabled={categoryRoleStore.length < 1}
          >
            <i className="fas fa-plus"></i>
            &nbsp; Create User
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUserComponent;
