import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  id: number;
  tenantId: string;
};

const getInvestmentInterest = async ({ id, tenantId }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_INTEREST}/${id}/tenant/${tenantId}`
  );
  return data.data;
};

export default function useInvestmentInterest({ id, tenantId }: TQuery) {
  return useQuery(
    [{ id, tenantId }, "getInvestmentInterest"],
    getInvestmentInterest,
    {
      refetchOnWindowFocus: false,
    }
  );
}
