import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TUserQuery = {
  role_id: string;
  search_value?: string;
  Page: string;
  PageSize: string;
  user_type: string;
};

const getAllAdminUsers = async (query: TUserQuery) => {
  // const query = "";
  // if (role_id) {
  //   query = `?role_id=${role_id}`;
  // }
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.ADMIN_USERS}?${new URLSearchParams(query).toString()}`
  );
  return data;
};

export default function useAllAdminUsers(query: TUserQuery) {
  return useQuery([query, "getAllAdminUsers"], getAllAdminUsers, {
    refetchOnWindowFocus: false,
  });
}
