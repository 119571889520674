import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import Alert from "../../../../NewComponents/Alert/Alert.component";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import style from "../RollOverModal/RollOverModal.module.scss";
import { formatMoney } from "../../../../helpers/formatter";
import moment from "moment";
import {
  getDataWithDotNet,
  postDataWithDotNet,
} from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { useInvestmentTransactions } from "../../../../custom-hooks/useInvestmentProductsDetails";

export interface IWithdrawalInfo {
  funder_investment_id: number | undefined;
  amount: number | undefined;
  transaction_date: string;
}

interface ISelectedRecord {
  selectedRecord: {
    id: number;
    funder_id: number;
    currency: string;
    investment_product_id: number;
    deposit_type: string;
  };
}

const WithdrawInvestmentModal = ({ selectedRecord }: ISelectedRecord) => {
  const { id, currency } = selectedRecord;
  const {
    data: transactionData,
    error: transactionError,
    isLoading: transactionIsLoading,
  } = useInvestmentTransactions(Number(id));
  const defaultWithdrawalInfo = {
    funder_investment_id: undefined,
    amount: undefined,
    transaction_date: "",
  };

  const [fetchingWithdrawalCharge, setFetchingWithdrawalCharge] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [withdrawalInfo, setWithdrawalInfo] = useState<IWithdrawalInfo>(
    defaultWithdrawalInfo
  );
  const [withdrawalCharge, setWithdrawalCharge] = useState<
    string | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log({ selectedRecord });
    if (selectedRecord?.deposit_type === "FixedDeposit") {
      setWithdrawalInfo({
        ...withdrawalInfo,
        amount: Number(transactionData?.investment_details?.account_balance),
      });
      handleFetchWithdrawalCharge();
    }
  }, [selectedRecord, transactionData]);

  function handleChangeAmount(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setWithdrawalInfo({ ...withdrawalInfo, amount: Number(target.value) });
  }

  function handleChangeEffectiveDate(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setWithdrawalInfo({
      ...withdrawalInfo,
      transaction_date: moment(target.value).format("YYYY-MM-DDTHH:mm:ss"),
    });
  }

  const handleSubmitWithdrawal = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const reqBody = { ...withdrawalInfo, funder_investment_id: id };
    setIsLoading(true);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.ADMIN_INITIATE_WITHDRAWAL,
        reqBody
      );
      console.log({ response });
      //TODO : - Confirm which of the two targets the message from api and remove the invalid one
      setSuccessMessage(response?.data?.message || response?.message);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setErrorMessage("An error occurred");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchWithdrawalCharge = async () => {
    setErrorMessage("");
    setFetchingWithdrawalCharge(true);
    try {
      const response = await getDataWithDotNet(
        `${ajaxEndpoints.INVESTMENT}/${id}/calculate_charges`
      );
      console.log({ response });
      if (response?.status_code !== 200) {
        throw new Error(response["response"]?.data?.message);
      } else {
        setWithdrawalCharge(
          formatMoney(response?.data?.charge_penalty || 0, currency)
        );
      }
    } catch (error) {
      setErrorMessage(errorHandler(error));
      console.log(error);
    } finally {
      setFetchingWithdrawalCharge(false);
    }
  };

  return (
    <Fragment>
      {successMessage && (
        <Alert
          message={successMessage}
          place={null}
          autoDismiss={null}
          icon={null}
          type="success"
        />
      )}
      <div
        className="modal fade"
        id="withdrawInvestmentModal"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="withdrawInvestmentModalLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="page-subtitle">Amount to Withdraw</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={handleClose}
                // ref={dismissModalRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <>
                {errorMessage && (
                  <p className="alert alert-danger">{errorMessage}</p>
                )}
                <form className="m-t-md" onSubmit={handleSubmitWithdrawal}>
                  <div>
                    <CustomInputField
                      label="Investment Balance"
                      value={formatMoney(
                        transactionData?.investment_details?.account_balance,
                        currency
                      )}
                      readOnly
                    />
                  </div>
                  <div>
                    <CustomInputField
                      type="number"
                      name="amount"
                      label={`Enter Amount ${formatMoney(
                        withdrawalInfo.amount,
                        currency
                      )}`}
                      placeholder="e.g. 1000"
                      onChange={handleChangeAmount}
                      value={withdrawalInfo.amount || ""}
                      onBlur={handleFetchWithdrawalCharge}
                      readOnly={selectedRecord?.deposit_type === "FixedDeposit"}
                    />
                  </div>

                  <div>
                    <CustomInputField
                      type="date"
                      label="Effective Date"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      name="transaction_date"
                      onChange={handleChangeEffectiveDate}
                    />
                  </div>
                  <p>
                    <span className="font-weight-bold mr-3">
                      Withdrawal Charge:
                    </span>
                    {fetchingWithdrawalCharge ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      <span>{withdrawalCharge}</span>
                    )}
                  </p>
                  <div className={style.finalSection}>
                    <button
                      className="btn advancly-btn m-0 btn-block"
                      disabled={
                        !withdrawalInfo?.amount ||
                        fetchingWithdrawalCharge ||
                        isLoading ||
                        errorMessage !== "" ||
                        transactionIsLoading
                      }
                    >
                      {isLoading ? (
                        <span className="spinner-border spinner-border-sm text-white" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WithdrawInvestmentModal;
