import { useState, useEffect } from "react";

import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ManageAdminUsers from "./components/ManageAdminUsers.component";
import { appInsights } from "../../config/appInsights";
import ToggleTab from "../../NewComponents/ToggleTab/ToggleTab.component";
import { RootState } from "../../redux/store";

const ManageRolesAndUsers = () => {
  const [activeTab, setActiveTab] = useState("users");

  const history = useHistory();

  const isAuthenticated = useSelector(
    (state: RootState) => state?.authReducer.isAuthenticated
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "User Management - (ManageRolesAndUsers.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "users":
        return <ManageAdminUsers />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated]);

  //JSX Template
  return (
    <div className="ManageRoles datatable-wrapper">
      <div className="table-view">
        {/*User & roles Stats*/}
        {/* <div className="row grid__padding animated fadeInRight">
          <div className="col-lg-12 col-md-12 col-sm-12 dashboard-title flex__row">
            <h3 className="m-l-1 relax-left">
              <aside className="rounder">
                <i className="fas fa-users-cog" />
              </aside>
              &nbsp; Manage Roles & Users
            </h3>

            <div className="breadcrumb__location">
              <NavLink to="dashboard">
                <h3 className="breadcrumb__location__title">Dashboard</h3>
              </NavLink>
              <span className="breadcrumb__location__icon">
                <i className="fas fa-angle-double-right" />
              </span>
              <NavLink to="manage-roles-and-users">
                <h3 className="breadcrumb__location__title">
                  Manage Roles & Users
                </h3>
              </NavLink>
            </div>
          </div>
        </div> */}

        <div className="alignToggleTabItems animated fadeInRight m-x-1 m-t-md">
          <ToggleTab
            text="Users"
            id="users"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default ManageRolesAndUsers;
