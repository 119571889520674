import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  productId: number;
};
export const getLoanProductAggregatorProductTypes = async ({
  productId,
}: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_LOAN_PRODUCT_AGGREGATOR_PRODUCT_TYPES}?productId=${productId}`
  );
  return data;
};
