import { useEffect, useState } from "react";

const CustomCheckBox = ({
  isSelected,
  name,
  onChange,
  id,
  isCheckBackground = false,
}: {
  isSelected?: boolean;
  name?: string;
  onChange: (arg1: any, arg2: number) => void;
  id: number;
  isCheckBackground?: boolean;
}) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isSelected === true) {
      setIsChecked(isSelected);
    } else {
      setIsChecked(false);
    }
  }, [isSelected]);

  if (!isCheckBackground) {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={isChecked}
          onClick={(e) => onChange(e, id)} // Please keep this as onClick, don't change to onChange
        />
        <label className="form-check-label">{name}</label>
      </div>
    );
  } else {
    return (
      <div
        className="form-check"
        style={{
          height: "2.4rem",
          padding: "0.5rem 2rem",
          backgroundColor: isChecked ? "#F2F5F9" : "#ffffff",
          borderRadius: "10px",
          marginBottom: "1rem",
        }}
      >
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={isChecked}
          onClick={(e) => onChange(e, id)} // Please keep this as onClick, don't change to onChange
        />
        <label className="form-check-label" style={{ paddingLeft: 0 }}>
          {name}
        </label>
      </div>
    );
  }
};

export default CustomCheckBox;
