import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useAllInvestment from "../../custom-hooks/useAllInvestment";
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../Pagination/Pagination.component";
import { showModal } from "../../redux/actions/modal";
import useAllAggregatorsTyped from "../../custom-hooks/useAllAggregatorsTyped";
import { formatMoney } from "../../helpers/formatter";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { getTokenFromLocalStorage } from "../../helpers/localStorage";
import { BASE_URL } from "../../appConstants";
import { appInsights } from "../../config/appInsights";
import useInvestmentCurrencies from "../../custom-hooks/useInvestmentCurrencies";

const AllInvestments = () => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    currency: "",
    startDate: "",
    endDate: "",
    searchValue: "",
    aggregatorId: "",
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investment Info  - (AllInvestments.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  const { data: investmentCurrencies } = useInvestmentCurrencies();

  const handleDownload = () => {
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (state.startDate && state.endDate) {
      queryParams = `?FromDate=${state.startDate}&ToDate=${state.endDate}`;
    }
    if (state.aggregatorId) {
      queryParams = queryParams
        ? `${queryParams}&AggregatorId=${state.aggregatorId}`
        : `?AggregatorId=${state.aggregatorId}`;
    }
    if (state.searchValue) {
      queryParams = queryParams
        ? `${queryParams}&FunderName=${state.searchValue}`
        : `?FunderName=${state.searchValue}`;
    }
    if (state.currency) {
      queryParams = queryParams
        ? `${queryParams}&Currency=${state.currency}`
        : `?Currency=${state.currency}`;
    }
    setPreState(state);
    fetch(BASE_URL + `/account/download_investments${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                state.startDate && state.endDate
                  ? `Investments from ${state.startDate} to ${state.endDate}.csv`
                  : `Investments.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AllInvestments.jsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  const [count, setCount] = useState(null);

  const { data, status, error } = useAllInvestment(preState);
  const { data: aggregatorData } = useAllAggregatorsTyped();
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const resetTable = () => {
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };

  const modalHandler = (modalData: string, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate).valueOf() - new Date(state.startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Investments
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Investments..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investments, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Investment.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Investor</th>
                  <th>Amount Invested</th>
                  <th>Aggregator</th>
                  <th>Tenor</th>
                  <th>Monthly Interest</th>
                  <th>Status</th>
                  <th>Date Invested</th>
                  <th>Maturity Date</th>
                  <th>Amount Due</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      funder_name,
                      principal_amount,
                      currency,
                      aggregator_name,
                      tenure,
                      monthly_interest_repayment,
                      effective_date,
                      maturity_date,
                      investment_status,
                      funder_id,
                      settlement_due,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{funder_name}</td>
                        <td>{formatMoney(principal_amount, currency)}</td>
                        <td>{aggregator_name || "N/A"}</td>
                        <td>{tenure}</td>
                        <td>
                          {formatMoney(monthly_interest_repayment, currency)}
                        </td>
                        <td>
                          <span
                            className={attachClassNameToTag(investment_status)}
                          >
                            {attachClassNameToTag(investment_status)}
                          </span>
                        </td>
                        <td>
                          {effective_date
                            ? effective_date.substring(0, 10)
                            : "N/A"}
                        </td>
                        <td>
                          {maturity_date
                            ? maturity_date.substring(0, 10)
                            : "N/A"}
                        </td>
                        <td>{formatMoney(settlement_due, currency)}</td>
                        <td>
                          <Link
                            to={{
                              pathname: `/investment-details/${funder_id}`,
                              state: {
                                data: request,
                              },
                            }}
                            className="color-blue"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">All Investments</h3>
            <button
              type="button"
              className="btn advancly-white-btn m-0"
              onClick={handleDownload}
              disabled={status === "loading" || downloadLoading}
            >
              <i className="fas fa-download m-r-1" />
              Download {downloadLoading && <ButtonLoader />}
            </button>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
              <div className="input-group mr-3" style={{ maxWidth: "200px" }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Funder Name..."
                  value={state.searchValue}
                  name="searchValue"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div
                style={{ width: "100px", marginBottom: "10px" }}
                className="mr-3"
              >
                <CustomSelectDropdown
                  onChange={(e) => handleChange(e)}
                  name="currency"
                  value={state.currency}
                  className="round user__form form-control"
                >
                  {investmentCurrencies &&
                    investmentCurrencies?.data.map(
                      (_currency, index: number) => {
                        return (
                          <option key={index} value={_currency?.currency_code}>
                            {_currency?.currency_code}
                          </option>
                        );
                      }
                    )}
                </CustomSelectDropdown>
              </div>
              <div
                style={{ width: "150px", marginBottom: "10px" }}
                className="mr-3"
              >
                <CustomSelectDropdown
                  onChange={(e) => handleChange(e)}
                  name="aggregatorId"
                  value={state.aggregatorId}
                  className="round user__form form-control"
                >
                  <option value="">Search By Aggregator</option>
                  {aggregatorData &&
                    aggregatorData?.data?.data?.map((aggregator) => {
                      const { biz_name, aggregator_id } = aggregator;
                      return (
                        <option
                          value={Number(aggregator_id)}
                          key={aggregator_id}
                        >
                          {biz_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>

              <div className="form-group mr-3 mt-2">
                <div className="input-group ">
                  <label className="mr-2">From </label>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={state.startDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-group mt-2 mr-3">
                <div className="input-group">
                  <label className="mr-2">To </label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "7px" }}>
                <button
                  type="submit"
                  className="btn advancly-btn btn-md"
                  value="submit"
                  disabled={status === "loading" || downloadLoading}
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          {data && data?.data && (
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber: number) =>
                setPreState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllInvestments;
