import { useEffect, useState } from "react";
import { IBankAccount } from "../../../interfaces/funderInvestment";
import { getDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { Loader } from "../../../UI/Loaders/Loaders";

const InvestorBankAccounts = ({ id }: { id: number }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);

  const handleFetchBankAccounts = async () => {
    setIsLoading(true);
    try {
      const data = await getDataWithDotNet(
        `${ajaxEndpoints.GET_CUSTOMER_BANK_ACCOUNTS}/${id}/bank_accounts`
      );
      const { bank_accounts } = data.data;
      setBankAccounts(bank_accounts);
    } catch (error) {
      setApiError("An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchBankAccounts();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader centered text="Loading ..." />
      ) : apiError ? (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> An error occurred please reload
            </h4>
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Currency</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Bank Code</th>
                <th>Swift Code</th>
                <th>Routing Number</th>
              </tr>
            </thead>
            <tbody>
              {bankAccounts &&
                bankAccounts?.length > 0 &&
                bankAccounts.map(
                  (
                    {
                      account_number,
                      bank_name,
                      bank_code,
                      account_name,
                      routing_number,
                      swift_code,
                      currency,
                    }: IBankAccount,
                    index
                  ) => (
                    <tr key={index}>
                      <td>{currency}</td>
                      <td>{account_name}</td>
                      <td>{account_number}</td>
                      <td>{bank_name}</td>
                      <td>{bank_code}</td>
                      <td>{swift_code || "NIL"}</td>
                      <td>{routing_number || "NIL"}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InvestorBankAccounts;
