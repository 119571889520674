import { useState } from "react";

export const useFilter = () => {
  const [filterParam, setFilterParam] = useState("");

  const filterOptions = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Spark",
      value: "spark",
    },
    {
      name: "Embed",
      value: "embed",
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    setFilterParam(value);
  };

  return [filterOptions, handleChange, filterParam];
};
