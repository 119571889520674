import { useState, Fragment, useRef, useEffect } from "react";
import moment from "moment";
import { putDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import Alert from "../../../../NewComponents/Alert/Alert.component";
import { errorHandler } from "../../../../helpers/errorHandler";
import CreateInvestmentModal from "./../CreateInvestmentModal/CreateInvestmentModal";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { ROLE_TYPES } from "./../../../../helpers/roleTypes";
// Redux
import { connect } from "react-redux";
import ButtonLoader, { Loader } from "../../../../UI/Loaders/Loaders";
import useAnInvestmentProduct from "../../../../custom-hooks/useAnInvestmentProduct";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { formatMoney } from "../../../../helpers/formatter";
import { appInsights } from "../../../../config/appInsights";
import { getCurrencyInfo } from "../../helper";

const PreviewInvesmentFunds = (props) => {
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [saveDraftError, setSaveDraftError] = useState(null);
  const [activateState, setActivateState] = useState({
    otp: "",
    comment: "",
  });
  const history = useHistory();
  const location = useLocation();
  const { data: locationData } = location?.state;
  const dismissModalRef = useRef();
  const { data, status, error } = useAnInvestmentProduct(locationData.id);

  const ADVANCLY_STORAGE_URL =
    "https://advanclystorage.blob.core.windows.net/advancly/";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setActivateState({ ...activateState, [name]: value });
  };

  const handleActivate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await putDataWithDotNet(
        `${ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT}/${Number(
          locationData.id,
        )}/activate`,
        activateState,
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push("/investments");
      }, 4000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewInvestmentFunds.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveDraftError(errorHandler(error));
    }
  };

  const discardChanges = (e) => {
    e.preventDefault();
    if (dismissModalRef && dismissModalRef.current) {
      dismissModalRef.current.click();
    }
  };
  if (error) {
  }
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt py-5">
            <i className="fas fa-bell" /> Unable to Preview Investment Funds
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading investment funds requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              drafted investment funds requests, Please Reload The Page.
            </h4>
          </div>
        </div>
      );
    } else if ((data && data.length === 0) || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    }
  }
  return (
    <Fragment>
      <div className="animated fadeInRight mt-2">
        <span className="d-flex align-items-center">
          <BackButtonComponent />
        </span>
        <div className="d-flex flex-row flex-wrap mt-3 mb-1">
          {saveDraftError && <Alert message={saveDraftError} />}
          {successMessage && <Alert message={successMessage} type="success" />}
          <h3 className="page-title">{data?.name || "N/A"}</h3>

          <div className="d-flex flex-row flex-wrap justify-content-end">
            {props.loggedInUser?.roles.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_SETUP,
            ) && (
              <div>
                <Link
                  to={{
                    pathname: `/investments/${locationData.id}/edit`,
                    state: {
                      editType: "drafted",
                      id: locationData.id,
                    },
                  }}
                >
                  <button
                    type="submit"
                    className="btn advancly-btn btn-md m-0"
                    disabled={loading}
                  >
                    Edit
                  </button>
                </Link>
              </div>
            )}
            {props.loggedInUser?.roles.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
            ) && (
              <div className="ml-3">
                <button
                  className="btn advancly-btn btn-md m-0"
                  disabled={loading}
                  data-toggle="modal"
                  data-target="#createInvestmentModal"
                  onClick={() => {
                    setModalActionType("activate");
                  }}
                >
                  Activate Fund {loading ? <ButtonLoader /> : ""}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          {data &&
            data?.investment_product_details.map((item, index) => (
              <div key={index} className="col-8">
                <hr
                  style={{
                    width: "50vw",
                    marginLeft: 0,
                  }}
                ></hr>
                <h3 className="page-subtitle">Investment Details</h3>
                <hr
                  style={{
                    width: "50vw",
                    marginLeft: 0,
                  }}
                ></hr>
                <div className="col-md-6 mb-3">
                  <h6>Currency</h6>
                  <p className="page-subtitle">
                    {getCurrencyInfo(item?.currency_code)?.name}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Investment Type</h6>
                  <p className="page-subtitle">
                    {item?.investment_type || "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Subscription Start Date</h6>
                  <p className="page-subtitle">
                    {data?.start_date
                      ? moment(data?.start_date).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Subscription End Date</h6>
                  <p className="page-subtitle">
                    {data?.end_date
                      ? moment(data?.end_date).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Investment Objective</h6>
                  <p className="page-subtitle">
                    {data?.objective ? data?.objective : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Description</h6>
                  <p className="page-subtitle">
                    {data?.description ? data?.description : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Issuer</h6>
                  <p className="page-subtitle">
                    {data?.issuers ? data?.issuers : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Target Investor</h6>
                  <p className="page-subtitle">
                    {data?.target_investors ? data?.target_investors : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Deposit Type</h6>
                  <p className="page-subtitle">{item?.deposit_type}</p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Monthly Premature Liquidation Rate</h6>
                  <p className="page-subtitle">
                    {item?.monthly_premature_liquidation_rate}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>End Of Tenure Premature Liquidation Rate</h6>
                  <p className="page-subtitle">
                    {item?.end_of_tenure_premature_liquidation_rate}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Product Type</h6>
                  <p className="page-subtitle">
                    {data?.investment_product_type_name}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Product Subtype</h6>
                  <p className="page-subtitle">
                    {data?.investment_product_sub_type_name}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Product Subtype Detail</h6>
                  <p className="page-subtitle">
                    {data?.investment_product_sub_type_detail_name}
                  </p>
                </div>
                <div>
                  <div className="col-md-12 mb-3">
                    <hr
                      style={{
                        width: "50vw",
                        marginLeft: 0,
                      }}
                    ></hr>
                    <h3 className="page-subtitle">Currencies</h3>
                    <hr
                      style={{
                        width: "50vw",
                        marginLeft: 0,
                      }}
                    ></hr>
                    <br />
                    <h6>
                      {getCurrencyInfo(item?.currency_code)?.name} (Min - Max
                      Investment)
                    </h6>
                    <p className="page-subtitle">
                      {formatMoney(
                        item?.minimum_investment,
                        getCurrencyInfo(item?.currency_code)?.symbol,
                      )}{" "}
                      -{" "}
                      {formatMoney(
                        item?.maximum_investment,
                        getCurrencyInfo(item?.currency_code)?.symbol,
                      )}
                    </p>
                  </div>

                  {/* <div className="col-md-6 mb-3">
                    <h6>

                      {getCurrencyInfo(item?.currencyCode)?.name} Core Banking
                      Product
                    </h6>
                    <p className="page-subtitle">
                      {item?.currencyCode !== "USD"
                        ? data?.lcy_product?.name || "N/A"
                        : data?.usd_product?.name || "N/A"}
                    </p>
                  </div> */}

                  <div className="col-md-12 mb-3">
                    <h6>
                      {getCurrencyInfo(item?.currency_code)?.name} Investment
                      Size
                    </h6>
                    <p className="page-subtitle">
                      {formatMoney(
                        item?.investment_size,
                        getCurrencyInfo(item?.currency_code)?.symbol,
                      ) || "N/A"}
                    </p>
                  </div>

                  <div className="col-md-12 mb-3">
                    <h6>Investment Termsheet</h6>
                    <p className="page-subtitle">
                      {data?.term_sheet_filename || "N/A"}
                    </p>
                    <button
                      disabled={!data?.term_sheet_filename}
                      className="btn advancly-btn mr-3"
                      onClick={() =>
                        window.open(
                          `${ADVANCLY_STORAGE_URL}${data?.term_sheet_url}`,
                          "_blank",
                        )
                      }
                    >
                      Download Term Sheet
                    </button>
                    {/*  <button className="btn advancly-btn">
                      View Term Sheet
                    </button>*/}
                  </div>
                </div>

                {RenderedComponent}
              </div>
            ))}
        </div>
      </div>

      <CreateInvestmentModal
        onSubmit={handleActivate}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
        successMessage={successMessage}
        saveDraftError={saveDraftError}
        handleChange={handleChange}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { loggedInUser } = state.authReducer;

  return {
    loggedInUser,
  };
};

export default withRouter(connect(mapStateToProps)(PreviewInvesmentFunds));
