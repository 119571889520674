import React from "react";
import "../../Issues/IssuesModal.css";
// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import ApprovalTrail from "../../../../components/ApprovalTrail/ApprovalTrail.component";

export default function ViewApprovalTrail(props: any) {
  const tabs = [
    {
      name: "Approval Trail",
      id: "approval_trail",
      icon: "fas fa-comments",
      identifier: "loanInfoTab",
    },
  ];

  const toggleTabContent = () => {};

  const { dataRecord, onCloseModal } = props;

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3 className="Modal__title p-b-2">
            Borrower id: <span>{dataRecord.borrower_details.borrower_id}</span>
          </h3>

          <div className="row m-b-1">
            <Tabs onContentToggle={toggleTabContent} tabs={tabs} />
          </div>

          <ApprovalTrail trans_ref={props.dataRecord.maker_checker_reference} />

          <div className="Form__action-buttons">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={onCloseModal}
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
