import { useState } from "react";

import { useHistory } from "react-router-dom";

import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { postData } from "../../../newApis/dotNetApiMethods";
import Tabs from "../../../components/Tabs/Tabs";
import CustomTextArea from "../../../NewComponents/CustomHTMLElements/CustomTextArea";
import ViewComments from "./viewComments";
import style from "./commentsModal.module.scss";
import { appInsights } from "../../../config/appInsights";

const CommentsModal = (props) => {
  const [comments, setComments] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [scene, setScene] = useState("view_comments");
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([
    {
      name: "Aggregator Comments",
      id: "view_comments",
      icon: "far fa-comments",
      identifier: "loanInfoTab",
    },
    {
      name: "Add Comment",
      id: "add_comments",
      icon: "fas fa-plus",
      identifier: "loanInfoTab",
    },
  ]);

  const history = useHistory();

  const toggleTabContent = (content) => {
    return setScene(content);
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const handleAddComments = async () => {
    setIsLoading(true);
    try {
      const payload = {
        deal_slip_id: props?.dataRecord?.dealSlipId,
        comment: comments,
      };
      const response = await postData(ajaxEndpoints.POST_COMMENTS, payload);
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
          history.push(`/pending-slips`);
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "commentsModal.jsx",
        },
      });
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
    setIsLoading(false);
  };
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            <div className="row m-b-1">
              <Tabs onContentToggle={toggleTabContent} tabs={tabs} />
            </div>
          </h3>

          {(() => {
            switch (scene) {
              case "view_comments":
                return <ViewComments comments={props.dataRecord.comments} />;
              case "add_comments":
                return (
                  <div>
                    <CustomTextArea
                      maxLength={256}
                      placeholder="Enter message here..."
                      label="Admin Comments"
                      name="message"
                      onChange={handleCommentsChange}
                    />
                    <div></div>
                    <button
                      className={`create zoom-element btn login-btn ${style.addComment}`}
                      onClick={handleAddComments}
                    >
                      {isLoading ? "Loading..." : "Add Comment"}
                    </button>
                  </div>
                );

              default:
                return null;
            }
          })()}

          <div className="Form__action-buttons m-t-2">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={props.onCloseModal}
              data-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              &nbsp; Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
