import React, { Component } from "react";

export default class Profile extends Component {
  // State
  state = {
    loginUser: {
      email: "",
      password: "",
    },
    loginStep: "1",
    multiStepMode: "aggregator",
    multiStepMenu: "off",
    multiStepBorrower: "1",

    advFunder: {
      companyName: "",
      annualGrossIncome: "",
      investmentAmount: "",
      investmentTenure: "",
    },

    advAggregator: {
      companyName: "Fund me",
      annualGrossIncome: "N10,000,000",
      ecosytemPartners: "2,000",
    },

    advBorrower: {
      fullName: "",
      bvn: "",
      nationalId: "",
      address: "",
      phone: "",
      bank: "",
      accountNo: "",
      email: "",
    },
    advBorrowerTypes: [
      { id: 1, label: "Taxify", value: "Taxify" },
      { id: 2, label: "Uber", value: "Uber" },
      { id: 3, label: "Konga", value: "Konga" },
      { id: 4, label: "Jumia", value: "Jumia" },
    ],
    selectedBorrower: "",
    taxifyBorrower: {
      fullName: "Dika Abuka",
      bvn: "45900CZ3XJ",
      nationalId: "68J77198P",
      address: "10 Pepple Street, Victoria Island",
      phone: "07060987705",
      bank: "Diamond Bank",
      accountNo: "0170085471",
      email: "dika.abuka@taxify.ng",
    },
    uberBorrower: {
      fullName: "Theofilus Ighalo",
      bvn: "7605VM4SF",
      nationalId: "12C62579T",
      address: "15 St Anthony Street, Ikoyi",
      phone: "08062100403",
      bank: "Guarantee Trust Bank",
      accountNo: "0299011790",
      email: "theo.ighalo@uber.ng",
    },
    kongaBorrower: {
      fullName: "Ameen Alade",
      bvn: "0914ZZ2LA",
      nationalId: "79Z90086K",
      address: "98 Mc.Donald Close, Lekki",
      phone: "08161148125",
      bank: "Polaris Bank",
      accountNo: "0941118350",
      email: "ameen.alade@konga.com.ng",
    },
    jumiaBorrower: {
      fullName: "Lola Odunsi",
      bvn: "1458GH0YU",
      nationalId: "31V37400D",
      address: "Block 74 Gbagada Phase II Close",
      phone: "08161148125",
      bank: "Union Bank PLC",
      accountNo: "0668290741",
      email: "lola.odunsi@jumia.com.ng",
    },
  };

  // Life Cycle Hooks
  componentDidMount() {}

  // Methods
  profileHandler = (e) => {
    e.preventDefault();

    if (this.state.multiStepMode) {
      document
        .querySelector("#adv-login-form")
        .classList.add("speed-1x", "fadeOutLeft");
      setTimeout(() => {
        document
          .querySelector("#adv-login-form")
          .classList.remove("speed-1x", "fadeOutLeft");
        document
          .querySelector("#adv-login-form")
          .classList.add("speed-1x", "fadeInRight");
        this.setState({ multiStepMode: "saving" });
      }, 500);
      setTimeout(() => {
        document
          .querySelector("#adv-login-loader")
          .classList.remove("speed-1x", "fadeInRight");
        document
          .querySelector("#adv-login-loader")
          .classList.add("speed-1x", "fadeOutLeft");

        setTimeout(() => {
          document
            .querySelector("#adv-login-loader")
            .classList.remove("speed-1x", "fadeOutLeft");
          this.setState({ multiStepMode: "successful" });
          document
            .querySelector("#adv-login-response")
            .classList.add("speed-1x", "fadeInRight");

          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        }, 500);
      }, 5000);
    }
  };

  borrowerDataHandler = (e) => {
    const target = e.target.name;

    (() => {
      switch (target) {
        case "fullName":
          return this.setState({
            advBorrower: {
              fullName: e.target.value,
            },
          });
        case "bvn":
          return this.setState({
            advBorrower: {
              bvn: e.target.value,
            },
          });
        case "nationalId":
          return this.setState({
            advBorrower: {
              nationalId: e.target.value,
            },
          });

        default:
      }
    })();
  };

  //JSX Template
  render() {
    return (
      <div className="center-container  normal-1x zoomIn">
        <div className="center-container-wrapper">
          <div className="center-element">
            <div className="">
              <div className="user-profile-wrapper tfx-user-form-wrapper box-effect full-border">
                <div className="tfx-user-form-inner">
                  {/*ALGORITHM FOR STEP UI*/}
                  {(() => {
                    switch (this.state.multiStepMode) {
                      case "borrower":
                        return (
                          <div className="adv-form-bg">
                            {/* 1. login Form */}
                            <div id="adv-login-form">
                              <div className="grid__full">
                                <div className="adv-avatar">
                                  <img
                                    src="/assets/adv-avatar.png"
                                    alt="Avatar"
                                  />
                                </div>

                                <div className="text-color">
                                  {" "}
                                  Borrower profile{" "}
                                </div>
                              </div>
                              <div className="grid__full ">
                                <div className="adv-form">
                                  <form name="form">
                                    <div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="BVN"
                                          name="bvn"
                                          value={
                                            this.state.advBorrower.bvn || ""
                                          }
                                        />
                                        <i className="fas fa-fingerprint" />
                                      </div>

                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Full Name"
                                          name="fullName"
                                          value={
                                            this.state.advBorrower.fullName ||
                                            ""
                                          }
                                        />
                                        <i className="fas fa-user-circle" />
                                      </div>

                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="National ID"
                                          name="nationalId"
                                          value={
                                            this.state.advBorrower.nationalId ||
                                            ""
                                          }
                                        />
                                        <i className="fas fa-address-card" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          placeholder="Phone Number"
                                          name="address"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          value={
                                            this.state.advBorrower.address || ""
                                          }
                                        />
                                        <i className="fas fa-map-marked-alt" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Address"
                                          required=""
                                          value={
                                            this.state.advBorrower.phone || ""
                                          }
                                        />
                                        <i className="fas fa-mobile" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Bank Name"
                                          name="bank"
                                          value={
                                            this.state.advBorrower.bank || ""
                                          }
                                        />
                                        <i className="fas fa-bank" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          placeholder="Account Number"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          name="accountNo"
                                          value={
                                            this.state.advBorrower.accountNo ||
                                            ""
                                          }
                                        />
                                        <i className="fas fa-credit-card" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="email"
                                          className="user__form form-control"
                                          placeholder="Email"
                                          name="email"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          value={
                                            this.state.advBorrower.email || ""
                                          }
                                        />
                                        <i className="fas fa-envelope" />
                                      </div>
                                    </div>
                                  </form>

                                  <div className="grid__full">
                                    <button
                                      type="submit"
                                      className="zoom-element btn login-btn"
                                      onClick={(e) => this.profileHandler(e)}
                                    >
                                      <i className="fas fa-save" />
                                      &nbsp; Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      case "saving":
                        return (
                          <div>
                            {/* 2. login Form Loader */}
                            <div
                              id="adv-login-loader"
                              className="loading-overlay speed-1x fadeInRight"
                            >
                              <div className="form-loader form-loader-login verify">
                                <div className="centerLoader">
                                  <div
                                    className="loading"
                                    id="adv-loader-alt"
                                  />

                                  <div className="center-txt webapp-ripple-text">
                                    <i className="notifier fas fa-user-circle" />
                                    &nbsp; Saving your profile
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      case "successful":
                        return (
                          <div>
                            {/* 3. Login Response */}
                            <div
                              id="adv-login-response"
                              className="loading-overlay speed-1x fadeInRight"
                            >
                              <div className="form-loader form-loader-login verify">
                                <div className="centerLoader">
                                  <div className="loading success-loader">
                                    <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                                  </div>
                                  <div className="center-txt webapp-ripple-text">
                                    Profile saved successfully
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      case "funder":
                        return (
                          <div className="adv-form-bg">
                            {/* 1. login Form */}
                            <div id="adv-login-form">
                              <div className="grid__full">
                                <div className="adv-avatar">
                                  <img
                                    src="/assets/adv-avatar.png"
                                    alt="Avatar"
                                  />
                                </div>

                                <div className="text-color">
                                  {" "}
                                  Funder profile{" "}
                                </div>
                              </div>
                              <div className="grid__full ">
                                <div className="adv-form">
                                  <form name="form">
                                    <div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Company Name"
                                          name="company name"
                                          value={
                                            this.state.advFunder.companyName ||
                                            ""
                                          }
                                        />
                                        <i className="fas fa-building" />
                                      </div>

                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Annual Gross Income"
                                          name="annualGrossIncome"
                                          value={
                                            this.state.advFunder
                                              .annualGrossIncome || ""
                                          }
                                        />
                                        <i className="fas fa-money" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Investment Amount"
                                          name="investmentAmount"
                                          value={
                                            this.state.advFunder
                                              .investmentAmount || ""
                                          }
                                        />
                                        <i className="fas fa-money-bill-alt" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Investment Tenure"
                                          name="investmentTenure"
                                          value={
                                            this.state.advFunder
                                              .investmentTenure || ""
                                          }
                                        />
                                        <i className="fas fa-calendar" />
                                      </div>
                                    </div>
                                  </form>
                                  <div className="grid__full">
                                    <button
                                      type="submit"
                                      className="zoom-element btn login-btn"
                                      onClick={(e) => this.profileHandler(e)}
                                    >
                                      <i className="fas fa-save" />
                                      &nbsp; Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );

                      case "aggregator":
                        return (
                          <div className="adv-form-bg">
                            {/* 1. login Form */}
                            <div id="adv-login-form">
                              <div className="grid__full">
                                <div className="adv-avatar">
                                  <img
                                    src="/assets/adv-avatar.png"
                                    alt="Avatar"
                                  />
                                </div>
                                <div className="text-color">
                                  {" "}
                                  Aggregator profile{" "}
                                </div>
                              </div>
                              <div className="grid__full ">
                                <div className="adv-form">
                                  <form name="form">
                                    <div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Company Name"
                                          name="company name"
                                          value={
                                            this.state.advFunder.companyName ||
                                            ""
                                          }
                                        />
                                        <i className="fas fa-building" />
                                      </div>

                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="Annual Gross Income"
                                          name="annualGrossIncome"
                                          value={
                                            this.state.advFunder
                                              .annualGrossIncome || ""
                                          }
                                        />
                                        <i className="fas fa-money" />
                                      </div>
                                      <div className="form-group inputWithIcon">
                                        <input
                                          type="text"
                                          className="user__form form-control"
                                          onChange={(e) => {
                                            this.borrowerDataHandler(e);
                                          }}
                                          placeholder="CAC document"
                                          name="cacDocument"
                                          value={
                                            this.state.advFunder
                                              .investmentAmount || ""
                                          }
                                        />
                                        <i className="fas fa-file" />
                                      </div>
                                    </div>
                                  </form>
                                  <div className="grid__full">
                                    <button
                                      type="submit"
                                      className="zoom-element btn login-btn"
                                      onClick={(e) => this.profileHandler(e)}
                                    >
                                      <i className="fas fa-save" />
                                      &nbsp; Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
