import React, { useEffect, useState } from "react";
import styles from "./TermsAndConditions.module.scss";
import { TextEditor } from "../../../../helpers/TextEditor";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { putData } from "../../../../newApis/dotNetApiMethods";
import * as apiEndPoints from "../../../../api/ajax-endpoints"

function TermsAndConditions({aggregator_id}: {aggregator_id: number}) {
  const [id, setId] = useState(String(aggregator_id))
  const [termsAndConditions, setTermsAndConditions] = useState<any>("")
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setId(String(aggregator_id))
  },[aggregator_id])

  const handleTextBoxChange = (values: any) => {
    setTermsAndConditions(values);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setIsLoading(true)

    const reqBody = {
      aggregatorId: Number(id),
      termsAndConditions: termsAndConditions,
      "generalTerms": false
    }
    try {
      const response = await putData(apiEndPoints.TERMS_AND_CONDITIONS, reqBody)
      if(response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoading(false)
    }
    
  }

  return (
    <div className={`animated fadeInRight ${styles.container}`}>
      <div className={styles.formContainer}>
        <form className={`${styles.form} mt-30`} onSubmit={handleSubmit}>
          <h3 style={{ textAlign: "center" }}>
            Embed Lending Terms and Conditions
          </h3>

          <div className={styles.textBox}>
            <div className={styles.field}>
              <label>Aggregator ID</label>
              <input placeholder="E.g 29" value={id} onChange={(e) => setId(e.target.value)} readOnly={!!id} />
            </div>
            <div className={styles.field}>
              <label>Terms and Conditions</label>
              <TextEditor onChange={handleTextBoxChange} defaultValue={""} />
            </div>
          </div>

          <button type="submit" className="btn advancly-btn btn-md m-0" disabled={isLoading || termsAndConditions === "<p></p>\n" || termsAndConditions === " " || !termsAndConditions || termsAndConditions === "<h6></h6>\n" || termsAndConditions === "<h5></h5>\n" || termsAndConditions === "<h4></h4>\n" || termsAndConditions === "<h3></h3>\n" || termsAndConditions === "<h2></h2>\n" || termsAndConditions === "<h1></h1>\n" || termsAndConditions === "<ul>\n<li></li>\n</ul>\n" || termsAndConditions === "<ol>\n<li></li>\n</ol>\n" || !id}>
          {isLoading ? "Processing..." : "Save Terms & Conditions"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default TermsAndConditions;
