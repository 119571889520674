import { MDBDataTable } from "mdbreact";
import { Loader } from "../../../../../../../UI/Loaders/Loaders";

const RepaymentForm = (props: any) => {
  const {
    RepaymentChannelLists,
    selectedRepaymentIdSearch,
    reOrderedTableData,
    channelAccountsDetails,
    loading,
  } = props;
  const repaymentChannelOptions = RepaymentChannelLists.map(
    (ecosystem: { id: string; channel_name: string }, index: number) => {
      const { id, channel_name } = ecosystem;
      return (
        <option key={index} id={id} value={id}>
          {channel_name}
        </option>
      );
    }
  );
  return (
    <div className="card">
      <div className="body text-center">
        <h3 className="m-t-0 m-b-3">
          <i className="adv-icon fas fa-eye" />
          Repayment Channel Accounts
        </h3>
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-6">
            <form>
              <label className="label-txt"> Repayment channel</label>
              <div className="form-group">
                <select
                  data-form="repaymentSearch"
                  className="round user__form form-control"
                  value={selectedRepaymentIdSearch}
                  placeholder="Select a channel"
                  name="selectedRepaymentIdSearch"
                  onChange={(e) => props.handleOptionSelect(e)}
                >
                  <option selected>Select a channel</option>
                  {repaymentChannelOptions}
                </select>
              </div>
            </form>
          </div>

          <div className="col-lg-4  col-md-6 col-sm-6 m-t-2">
            <div className="Form__action-buttons">
              <button
                type="submit"
                className="zoom-element btn login-btn"
                data-form="repaymentSearch"
                disabled={!selectedRepaymentIdSearch}
                onClick={(e) => props.onSearch(e.currentTarget)}
              >
                <i className="fas fa-search" />
                &nbsp; Search
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {!loading && Object.keys(channelAccountsDetails).length === 0 && (
            <div className="centerLoader m-b-3">
              <div className="grid__padding animated fadeInRight">
                <h4 className="center-txt p-y-5">
                  <i className="fas fa-bell" /> No record found
                </h4>
              </div>
            </div>
          )}
          {!loading && Object.keys(channelAccountsDetails).length === null && (
            <div className="centerLoader">
              <div className="grid__padding animated fadeInRight">
                <h4 className="center-txt p-y-5">
                  <i className="fas fa-bell" />
                  Unable to Fetch Records
                </h4>
              </div>
            </div>
          )}
          {loading && <Loader text="Loading....." background={true} />}
          {!loading &&
            Object.keys(channelAccountsDetails).length > 0 &&
            channelAccountsDetails && (
              <div className="grid__padding animated speed-1x fadeInRight">
                <MDBDataTable
                  responsive
                  minimal="true"
                  striped
                  bordered
                  hover
                  data={reOrderedTableData}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default RepaymentForm;
