import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEnpoints from "../api/ajax-endpoints";

type TQuery = {
  aggregatorId: number;
};

const getAggregatorProductDetails = async ({ aggregatorId }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEnpoints.AGGREGATOR_PRODUCT_DETAILS}?aggregatorId=${aggregatorId}`
  );
  return data.data;
};

export default function useAggregatorProductDetails({ aggregatorId }: TQuery) {
  return useQuery(
    [{ aggregatorId }, "getAggregatorProductDetails"],
    getAggregatorProductDetails,
    {
      refetchOnWindowFocus: false,
      enabled: aggregatorId ? true : false,
    }
  );
}
