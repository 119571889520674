import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

export type TFundingRequestsForApproval = {
  aggregatorId: number;
  fundingWalletId: string;
  bizName: string;
  currency: string;
  amount: number;
  tenor: number;
  tenorType: string;
  makerCheckRefNo: string;
  createDateTime: string;
  walletProvider: number;
};

const getPendingFundingMakerCheckerRequests = async () => {
  const { data } = await getData(
    `${ajaxEndpoints.FUNDING_MAKER_CHECKER_REQUESTS}`
  );

  return data;
};

export default function useFundingMakerCheckerRequests() {
  return useQuery<IResponse<TFundingRequestsForApproval[]>>(
    "getPendingFundingMakerCheckerRequests",
    getPendingFundingMakerCheckerRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
