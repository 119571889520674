import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { QueryResult, useQuery } from "react-query";
import { handleSwalErrorAlert, logAppInsightErrors } from "../helpers";
import {
  ICommentDetail, IInvestmentProspect,
  IPaginatedInvestmentProspects,
  IPaginatedReferralProspectiveInvestmentResponse,
  IPaginatedReferralRealInvestmentResponse,
} from "../interfaces/investmentProspect";
import { IPaginatedReferralSignupResponse } from "../interfaces/referral";

interface IInvestmentProductTypes {
  id: number;
  name: string;
}

interface IInvestmentProductSubTypes {
  subTypeId: number;
  subTypeName: string;
}

interface IInvestmentProductSubTypesDetail {
  subTypeDetailId: number;
  subTypeDetail: string;
}

interface IPagination {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  email?: string;
  phoneNumber?: string;
  id?: string;
}

const getInvestmentProductTypes = async () => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_PRODUCT_TYPES}`,
  );
  return data.data;
};

const getInvestmentProductSubTypesByInvestmentProductTypeId = async (
  id: number,
) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_PRODUCT_TYPES}/${id}/subtypes`,
  );
  return data.data;
};

const getInvestmentProductSubTypesDetails = async (id: number) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_PRODUCT_SUBTYPES}/${id}/subtype_details`,
  );
  return data.data;
};

export function useInvestmentProductTypes(): QueryResult<
  IInvestmentProductTypes[]
> {
  return useQuery(["getInvestmentProductTypes"], getInvestmentProductTypes, {
    refetchOnWindowFocus: false,
  });
}

export function useInvestmentProductSubtypes(
  id: number | null,
): QueryResult<IInvestmentProductSubTypes[]> {
  return useQuery(
    [id, "getInvestmentProductSubTypesByInvestmentProductTypeId"],
    getInvestmentProductSubTypesByInvestmentProductTypeId,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    },
  );
}

export function useInvestmentProductSubtypeDetails(
  id: number | null,
): QueryResult<IInvestmentProductSubTypesDetail[]> {
  return useQuery(
    [id, "getInvestmentProductSubTypesDetails"],
    getInvestmentProductSubTypesDetails,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    },
  );
}

const getAllProspectiveInvestmentStatuses = async () => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_PROSPECTIVE_INVESTMENT_STATUS}`,
  );
  return data.data;
};

export function useProspectiveInvestmentStatuses(): QueryResult<
  { id: number; name: string }[]
> {
  return useQuery(
    ["getAllProspectiveInvestmentStatuses"],
    getAllProspectiveInvestmentStatuses,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        logAppInsightErrors(
          `Failed to fetch prospective investment statues:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch prospective investment statues`);
      },
    },
  );
}
const getInvestmentProspect = async ({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
}: IPagination) => {
  // Initialize URLSearchParams
  const queryParams = new URLSearchParams({
    Page: pageNumber.toString(), // Convert pageNumber to string
    PageSize: size.toString(), // Convert size to string
  });
  // Add optional parameters only if they are defined
  if (phoneNumber) {
    queryParams.append("PhoneNumber", phoneNumber);
  }
  if (email) {
    queryParams.append("Email", email);
  }
  if (startDate) {
    queryParams.append("StartDate", startDate);
  }
  if (endDate) {
    queryParams.append("EndDate", endDate);
  }
  const response = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_PROSPECT}?${queryParams}`,
  );
  return response;
};

const getInvestmentProspectDetailsById = async ({
                                      id
                                     }: {id : number}) => {

  const response = await getDataWithDotNet(
      `${ajaxEndpoints.GET_INVESTMENT_PROSPECT}/${id}`,
  );
  return response.data;
};

export function useInvestmentProspects({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
}: IPagination): QueryResult<IPaginatedInvestmentProspects> {
  return useQuery(
    [
      { size, endDate, startDate, email, phoneNumber, pageNumber },
      "getInvestmentProspect",
    ],
    getInvestmentProspect,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        logAppInsightErrors(
          `Failed to fetch investment prospects:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch investment prospects`);
      },
    },
  );
}

export function useInvestmentProspectsDetailsById({
  id
}: {id : number}): QueryResult<IInvestmentProspect> {
  return useQuery(
    [
      { id },
      "getInvestmentProspectDetailsById",
    ],
      getInvestmentProspectDetailsById,
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        logAppInsightErrors(
          `Failed to fetch investment prospects:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch investment prospects details`);
      },
    },
  );
}

const getInvestmentProspectComments = async ({
  pageNumber,
  startDate,
  endDate,
  size,
  id,
}: IPagination) => {
  // Initialize URLSearchParams
  const queryParams = new URLSearchParams({
    Page: pageNumber.toString(), // Convert pageNumber to string
    PageSize: size.toString(), // Convert size to string
  });
  // Add optional parameters only if they are defined
  if (startDate) {
    queryParams.append("StartDate", startDate);
  }
  if (endDate) {
    queryParams.append("EndDate", endDate);
  }
  const response = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_PROSPECT}/${id}/comment?${queryParams}`,
  );
  return response.data;
};

export function useInvestmentProspectComments({
  pageNumber,
  startDate,
  endDate,
  size,
  id,
}: IPagination): QueryResult<ICommentDetail[]> {
  return useQuery(
    [
      { size, endDate, startDate, pageNumber, id },
      "getInvestmentProspectComments",
    ],
    getInvestmentProspectComments,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
      onError: (error) => {
        logAppInsightErrors(
          `Failed to fetch investment prospects:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch investment prospect comments`);
      },
    },
  );
}

const getInvestorsReferrals = async ({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
  id,
}: IPagination) => {
  // Initialize URLSearchParams
  const queryParams = new URLSearchParams({
    Page: pageNumber.toString(), // Convert pageNumber to string
    PageSize: size.toString(), // Convert size to string
  });
  // Add optional parameters only if they are defined
  if (phoneNumber) {
    queryParams.append("PhoneNumber", phoneNumber);
  }
  if (email) {
    queryParams.append("Email", email);
  }
  if (startDate) {
    queryParams.append("StartDate", startDate);
  }
  if (endDate) {
    queryParams.append("EndDate", endDate);
  }
  const response = await getDataWithDotNet(
    `${ajaxEndpoints.GET_REFERRAL}/${id}/customer?${queryParams}`,
  );
  return response;
};

export function useInvestorsReferrals({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
  id,
}: IPagination): QueryResult<IPaginatedReferralSignupResponse> {
  return useQuery(
    [
      { size, endDate, startDate, email, phoneNumber, pageNumber, id },
      "getInvestorsReferrals",
    ],
    getInvestorsReferrals,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
      onError: (error) => {
        console.log({ error });
        logAppInsightErrors(
          `Failed to fetch referrals:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch referrals`);
      },
    },
  );
}

/*Function - get a customer referred real investments*/
const getReferredCustomerRealInvestments = async ({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
  id,
}: IPagination) => {
  // Initialize URLSearchParams
  const queryParams = new URLSearchParams({
    Page: pageNumber.toString(), // Convert pageNumber to string
    PageSize: size.toString(), // Convert size to string
  });
  // Add optional parameters only if they are defined
  if (phoneNumber) {
    queryParams.append("PhoneNumber", phoneNumber);
  }
  if (email) {
    queryParams.append("Email", email);
  }
  if (startDate) {
    queryParams.append("StartDate", startDate);
  }
  if (endDate) {
    queryParams.append("EndDate", endDate);
  }
  return await getDataWithDotNet(
    `${ajaxEndpoints.GET_REFERRAL}/${id}/investment?${queryParams}`,
  );
};

/*Function - get a customer referred real investments*/
export function useReferredCustomerRealInvestments({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
  id,
}: IPagination): QueryResult<IPaginatedReferralRealInvestmentResponse> {
  return useQuery(
    [
      { size, endDate, startDate, email, phoneNumber, pageNumber, id },
      "getReferredCustomerRealInvestments",
    ],
    getReferredCustomerRealInvestments,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
      onError: (error) => {
        console.log({ error });
        logAppInsightErrors(
          `Failed to fetch investments:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch investments`);
      },
    },
  );
}

/*Function - get a customer prospective real investments*/
const getReferredCustomerProspectiveInvestments = async ({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
  id,
}: IPagination) => {
  // Initialize URLSearchParams
  const queryParams = new URLSearchParams({
    Page: pageNumber.toString(), // Convert pageNumber to string
    PageSize: size.toString(), // Convert size to string
  });
  // Add optional parameters only if they are defined
  if (phoneNumber) {
    queryParams.append("PhoneNumber", phoneNumber);
  }
  if (email) {
    queryParams.append("Email", email);
  }
  if (startDate) {
    queryParams.append("StartDate", startDate);
  }
  if (endDate) {
    queryParams.append("EndDate", endDate);
  }
  return await getDataWithDotNet(
    `${ajaxEndpoints.GET_REFERRAL}/${id}/investment_prospect?${queryParams}`,
  );
};

/*Function - get a customer referred prospective investments*/
export function useReferredCustomerProspectiveInvestments({
  pageNumber,
  phoneNumber,
  email,
  startDate,
  endDate,
  size,
  id,
}: IPagination): QueryResult<IPaginatedReferralProspectiveInvestmentResponse> {
  return useQuery(
    [
      { size, endDate, startDate, email, phoneNumber, pageNumber, id },
      "getReferredCustomerProspectiveInvestments",
    ],
    getReferredCustomerProspectiveInvestments,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
      onError: (error) => {
        console.log({ error });
        logAppInsightErrors(
          `Failed to fetch investments:, ${error}`,
          "useDebtFund.tsx",
        );
        handleSwalErrorAlert(`Failed to fetch investments`);
      },
    },
  );
}
