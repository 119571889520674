import { useState } from "react";
import { useHistory } from "react-router-dom";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../Pagination/Pagination.component";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import useAllUploadedBatchLoans from "../../../custom-hooks/useAllUploadedBatchLoans";
import { useDispatch } from "react-redux";
import useAllAggregatorsTyped from "../../../custom-hooks/useAllAggregatorsTyped";
import useAllAggregatorProducts from "../../../custom-hooks/useAllAggregatorProducts";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { useQueryCache } from "react-query";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { appInsights } from "../../../config/appInsights";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";
import { BASE_URL } from "../../../appConstants";
import { hideModal, showModal } from "../../../redux/actions/modal";

const UploadedBatchLoans = () => {
  const [count, setCount] = useState(null);
  const [submitError, setSubmitError] = useState<string>("");
  const [submitSuccess, setSubmitSuccess] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    productId: "",
    startDate: "",
    endDate: "",
    aggregatorId: "",
  });
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    productId: "",
    startDate: "",
    endDate: "",
    aggregatorId: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { data, status, error } = useAllUploadedBatchLoans(preState);

  const { data: aggregatorData } = useAllAggregatorsTyped();
  const { data: productData } = useAllAggregatorProducts();
  const queryCache = useQueryCache();
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const handleEditRepayment = async (loans: any) => {
    setLoading(true);

    try {
      await postDataWithDotNet(ajaxEndpoints.UPDATE_BATCH_REPAYMENT, loans);
      dispatch(hideModal());
      setLoading(false);
      setSubmitSuccess("Loan Edit was Successful");
      queryCache.invalidateQueries("getAllPendingRepayment");
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedBatchLoans.jsx",
        },
      });
      dispatch(hideModal());
      setLoading(false);
      setSubmitError(errorHandler(error));
    }
  };

  const handleDownload = (e: any) => {
    e.preventDefault();
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();

    let queryParams = `?Page=${preState?.pageNumber}&PageSize=${preState?.size}`;

    if (preState?.productId) {
      queryParams = `${queryParams}&ProductId=${preState?.productId}&`;
    }

    if (preState?.aggregatorId) {
      queryParams = `${queryParams}&AggregatorId=${preState?.aggregatorId}`;
    }

    if (preState?.startDate && preState?.endDate) {
      queryParams = `${queryParams}&FromDate=${preState?.startDate}&ToDate=${preState?.endDate}`;
    }

    fetch(
      BASE_URL +
        `${ajaxEndpoints.DOWNLOAD_UPLOADED_BATCH_LOAN_REPAYMENT}${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${`UploadedBatchLoanRepayment.csv`}`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "UploadedBatchLoans.jsx (Download)",
          },
        });

        setDownloadLoading(false);
      });
  };

  // repay Selected Loans
  const editSelectedLoans = (e: any, request: any) => {
    e.preventDefault();

    dispatch(
      showModal({
        modalIdentifier: "batchloanrepayment",
        dataId: e.target.dataset["ref"],
        action: "editbatchrepayment",
        subModal: "multiple",
        request,
        handleEditRepayment,
      })
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const modalHandler = (modalData: any, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };
  const filterData = (e: any) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate).valueOf() - new Date(state.startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    // resetTable();
  };

  const handleClearSearch = (e: any) => {
    e.preventDefault();
    setState({
      pageNumber: 1,
      size: 20,
      aggregatorId: "",
      productId: "",
      startDate: "",
      endDate: "",
    });
    return setPreState({
      pageNumber: 1,
      size: 20,
      aggregatorId: "",
      productId: "",
      startDate: "",
      endDate: "",
    });
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Loan
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Aggregator Name</th>
                  <th>Batch Number</th>
                  <th>Product Name</th>
                  <th>Loan Ref</th>
                  <th>Repayment Amount</th>
                  <th>Repayment Date</th>
                  <th>Status</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      aggregatorName,
                      batchNumber,
                      productName,
                      loanRef,
                      repaymentAmount,
                      repaymentDate,
                      statusName,
                      message,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>
                          {(preState.pageNumber - 1) * preState.size +
                            (_id + 1)}
                        </td>
                        <td>{aggregatorName}</td>
                        <td>{batchNumber}</td>
                        <td>{productName}</td>
                        <td>{loanRef}</td>
                        <td>{repaymentAmount}</td>
                        <td>{repaymentDate?.substring(0, 10)}</td>
                        <td>{statusName}</td>
                        <td>{message}</td>
                        <td>
                          <button
                            className="advancly-white-btn"
                            disabled={loading}
                            onClick={(e) => editSelectedLoans(e, request)}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="d-flex flex-row flex-wrap mb-3 ">
        {submitError && <Alert message={submitError} />}
        {submitSuccess && <Alert message={submitSuccess} type="success" />}
        <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
          <div
            style={{ width: "200px", marginBottom: "10px" }}
            className="mr-3"
          >
            <CustomSelectDropdown
              onChange={(e) => handleChange(e)}
              name="aggregatorId"
              value={state.aggregatorId}
              className="round user__form form-control"
            >
              <option value="">search by aggregator</option>
              {aggregatorData &&
                aggregatorData?.data?.data.map((aggregator) => {
                  const { biz_name, aggregator_id } = aggregator;
                  return (
                    <option value={Number(aggregator_id)} key={aggregator_id}>
                      {biz_name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div>
          <div
            style={{ width: "200px", marginBottom: "10px" }}
            className="mr-3"
          >
            <CustomSelectDropdown
              onChange={(e) => handleChange(e)}
              name="productId"
              value={state.productId}
              className="round user__form form-control"
            >
              <option value="">search by product</option>
              {productData &&
                productData.map((aggregator: any) => {
                  const { product_name, id } = aggregator;
                  return (
                    <option value={Number(id)} key={id}>
                      {product_name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div>

          <div className="form-group mr-3 mt-2">
            <div className="input-group ">
              <label className="mr-2">From </label>
              <input
                type="date"
                name="startDate"
                className="form-control"
                value={state.startDate}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="form-group mt-2 mr-3">
            <div className="input-group">
              <label className="mr-2">To </label>
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={state.endDate}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div style={{ marginBottom: "7px" }}>
            <button
              type="submit"
              className="btn advancly-btn btn-md"
              value="submit"
              disabled={status === "loading" || !data}
            >
              Search
            </button>
            <button
              className="btn advancly-red-btn btn-md ml-2"
              onClick={handleClearSearch}
            >
              Clear
            </button>
          </div>
          <div style={{ marginBottom: "7px", marginLeft: "1rem" }}>
            <button
              className="btn advancly-btn btn-md"
              onClick={handleDownload}
              disabled={status === "loading" || !data}
            >
              <i className="fas fa-download m-r-1" />
              Download {downloadLoading && <ButtonLoader />}
            </button>
          </div>
        </form>
      </div>

      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={preState.size}
        page={preState.pageNumber}
        count={count || 0}
        changeCurrentPage={(pageNumber: number) =>
          setPreState({ ...preState, pageNumber })
        }
      />
    </div>
  );
};

export default UploadedBatchLoans;
