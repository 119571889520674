import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import { WORK_FLOW_CODE } from "../appConstants";

type TQuery = {
  startDate: string;
  endDate: string;
  email: string;
};

const getDisbursementApproverApprovedLoans = async ({
  startDate,
  endDate,
  email,
}: TQuery) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_APPROVED_LOANS}?workflowCode=${WORK_FLOW_CODE}&email=${email}&startDate=${startDate}&endDate=${endDate}`
  );
  return data;
};

export default function useDisbursementApproverApprovedLoans({
  startDate,
  endDate,
  email,
}: TQuery) {
  return useQuery(
    [{ startDate, endDate, email }, "getDisbursementApproverApprovedLoans"],
    getDisbursementApproverApprovedLoans,
    {
      refetchOnWindowFocus: false,
    }
  );
}
