import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { postData } from "../../../newApis/apiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import VerificationLoader from "../RecoveryComponents/VerificationLoader.component";
import { yupValidators } from "../../../helpers/yupValidators";
import { appInsights } from "../../../config/appInsights";

const schema = yup.object().shape({
  email: yupValidators.email,
});

export default function RecoveryStepOne({
  setStateToken,
  setMultiStepMenu,
  setEmail,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { email } = data;
    setLoading(true);
    setError(null);
    const reqBody = {
      email,
      reset_step: "first_step",
    };
    try {
      const res = await postData(ajaxEndpoints.RECOVERY, reqBody);
      setStateToken(res.state_token);
      setMultiStepMenu("stepTwo");
      setEmail(email);
      setLoading(false);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "RecoveryStepOne.component.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  if (loading) {
    return (
      <VerificationLoader
        text="Verifying Your Email"
        iconType="fas fa-envelope"
      />
    );
  }
  return (
    <div>
      <div className="tfx-step grid__full">
        <div className="tfx-user-form-wrapper box-effect full-border white-bg">
          <div className="tfx-user-form-inner">
            <div id="adv-verify-email">
              <div className="grid__full ">
                <div className="adv-form">
                  <form name="form" onSubmit={handleSubmit(onSubmit)}>
                    <label className="label-txt center-txt">
                      Enter Your Email Address{" "}
                    </label>
                    {error && (
                      <p className="alert alert-danger mb-4 small">{error}</p>
                    )}

                    <CustomInputField
                      type="email"
                      maxLength="256"
                      reference={register}
                      name="email"
                      placeholder="Email"
                      label=""
                      errors={errors.email}
                      iconType="fas fa-envelope"
                    />
                    <div className="grid__full mt-20">
                      <div className="grid__full">
                        <button
                          type="submit"
                          className="zoom-element btn login-btn"
                        >
                          <i className="fas fa-envelope" />
                          &nbsp; Request Reset Code
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
