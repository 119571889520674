// Redux Action CONSTANTS
import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "./../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "./../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";

export const auditAjaxStart = () => {
  return { type: actionTypes.AUDIT_AJAX_START };
};

export const getAuditFailure = (error) => {
  return { type: actionTypes.GET_AUDIT_FAILURE, error };
};

export const getAuditTrail = (queryString) => {
  return function (dispatch) {
    dispatch(auditAjaxStart());

    let endpoint = `${ajaxEndpoints.AUDITS}`;
    if (queryString) {
      endpoint = `${endpoint}?${queryString}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch(getAuditSuccess(response));
        } else {
          dispatch(
            getAuditFailure({
              errorMsg: "other error",
            })
          );
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "auditTrail.js",
          },
        });
        //
        dispatch(getAuditFailure(error));
      });
  };
};

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns, responseDataRecords) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row, index) => {
        let newRow = {};
        tableColumns.forEach((column) => {
          if (column.field === "id") {
            newRow[column.field] = index + 1;
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};

export const getAuditSuccess = (response) => {
  const tableColumns = [
    { label: "#", field: "id", sort: "asc", width: 150 },
    { label: "AMOUNT", field: "amount", sort: "asc", width: 150 },
    { label: "DESCRIPTION", field: "description", sort: "asc", width: 270 },
    { label: "TIMESTAMP", field: "timestamp", sort: "asc", width: 100 },
    { label: "MANAGE", field: "manage", sort: "asc", width: 100 },
  ];

  const reOrderedTableData = reOrderRows(tableColumns, response.data);

  return {
    type: actionTypes.GET_AUDIT_SUCCESS,
    payload: {
      auditTrailTableData: reOrderedTableData,
      auditTrail: [...response.data],
      count: response.count,
      next_page: response.next_page,
      prev_page: response.prev_page,
    },
  };
};
