import { useEffect, useState } from "react";

import { appInsights } from "../../../../config/appInsights";
import CustomInputField from "../../../../components/CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ButtonLoader, { Loader } from "../../../../UI/Loaders/Loaders";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postData } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { showModal } from "../../../../redux/actions/modal";
import { TFundingRequestsForApproval } from "../../../../custom-hooks/useFundingMakerCheckerRequests";
import useFundingReceiverWalletDetails from "../../../../custom-hooks/useFundingReceiverWalletDetails";
import { TFundingWalletDetail } from "../../../../custom-hooks/useFundingWalletDetails";

import styles from "./fundwithdrawal.module.scss";

type TFormPayload = {
  amount: string;
  narration: string;
  accountName: string;
  accountNumber: string;
  bank: string;
};

const schema = yup.object().shape({
  amount: yup.string().required("Amount is required"),
  narration: yup.string(),
  accountName: yup.string(),
  accountNumber: yup.string(),
  bank: yup.string(),
});

const FundWithdrawal = ({ properties: { dataRecord }, onCloseModal }: any) => {
  const [fundingWalletDetail, setFundingWalletDetail] =
    useState<TFundingWalletDetail>();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleFormatAmount = (amount: string) => {
    return Number(amount.replace(/,/g, ""));
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "FundWithdrawal",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: fundingReceiverWalletDetails,
    isLoading: isLoadingFundingReceiverDetails,
  } = useFundingReceiverWalletDetails();

  useEffect(() => {
    if (dataRecord) {
      setFundingWalletDetail(dataRecord);
    }
  }, [dataRecord]);

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: TFormPayload) => {
    setIsLoading(true);
    const payload = {
      sender_account_number: fundingWalletDetail?.accountNumber,
      sender_wallet_id: fundingWalletDetail?.walletId,
      aggregator_id: fundingWalletDetail?.aggregatorId,
      recipient_account_number:
        fundingReceiverWalletDetails?.data.accountNumber,
      recipient_bank_code: fundingReceiverWalletDetails?.data?.bankCode,
      amount: handleFormatAmount(values?.amount),
      //   pin: "string",
      comment: values?.narration,
      wallet_provider: fundingWalletDetail?.walletProviderId,
    };

    try {
      const response = await postData(ajaxEndpoints.FUND_WITHDRAWAL, payload);

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "success",
              message: response?.data?.message,
            },
          })
        );
        setTimeout(() => {
          history.push("/funding-wallets");
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message,
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FundWithdrawal",
        },
      });

      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setValue("bank", fundingReceiverWalletDetails?.data?.bankName);
    setValue(
      "accountNumber",
      fundingReceiverWalletDetails?.data?.accountNumber
    );
    setValue("accountName", fundingReceiverWalletDetails?.data?.accountName);
  }, []);

  if (isLoadingFundingReceiverDetails) {
    return <Loader text="Loading..." />;
  }

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className={`Modal__title p-b-1 ${styles.HeaderTitle}`}>
            Withdraw Fund
          </h3>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomInputField
                  type="number"
                  label="Amount"
                  enableSeparator
                  reference={register}
                  name="amount"
                  errors={errors.amount}
                  placeholder="0.00"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomInputField
                  type="text"
                  label="Narration"
                  reference={register}
                  name="narration"
                  errors={errors.narration}
                  placeholder="What is this transfer for?"
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomInputField
                  type="text"
                  label="Bank"
                  reference={register}
                  name="bank"
                  errors={errors.bank}
                  defaultValue={fundingReceiverWalletDetails?.data?.bankName}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomInputField
                  type="text"
                  label="Account Number"
                  reference={register}
                  name="accountNumber"
                  errors={errors.accountNumber}
                  defaultValue={
                    fundingReceiverWalletDetails?.data?.accountNumber
                  }
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomInputField
                  type="text"
                  label="Account Name"
                  reference={register}
                  name="accountName"
                  errors={errors.accountName}
                  defaultValue={fundingReceiverWalletDetails?.data?.accountName}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <button className="ml-3 btn advancly-btn">
                {isLoading ? <ButtonLoader /> : "Proceed"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FundWithdrawal;
