import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";

const getDirectDebitMandateList = async ({
  account_number,
  loan_ref,
}: {
  loan_ref: string;
  account_number: string;
}) => {
  const data = await getData(
    `${
      ajaxEndpoints.GET_DIRECT_DEBIT_MANDATE_LIST
    }?loan_ref=${loan_ref}&account_number=${account_number || ""}`
  );
  return data.data;
};
export default function useDirectDebitMandateList({
  account_number,
  loan_ref,
}: {
  loan_ref: string;
  account_number: string;
}) {
  return useQuery(
    [{ account_number, loan_ref }, "getDirectDebitMandateList"],
    getDirectDebitMandateList,
    {
      refetchOnWindowFocus: false,

      enabled: loan_ref ? true : false,
    }
  );
}
