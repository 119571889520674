// Redux Action CONSTANTS
import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";

// Helpers
import { dateFormatter } from "../../helpers/stringControllers";
import { appInsights } from "../../config/appInsights";

export const showNotifications = () => {
  return function (dispatch: any) {
    dispatch({ type: actionTypes.SHOW_NOTIFICATION });
  };
};

// Ajax call to get Funder Notifications
export const getNotifications = (queryString?: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.GET_NOTIFICATIONS_START,
    });

    let endpoint = `${ajaxEndpoints.NOTIFICATIONS}`;
    if (queryString) {
      endpoint = `${endpoint}?${queryString}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          const { count, data, next_page, prev_page } = response;

          const tableColumns = [
            { label: "#", field: "id", sort: "asc", width: 150 },
            { label: "AMOUNT", field: "amount", sort: "asc", width: 150 },
            {
              label: "DESCRIPTION",
              field: "description",
              sort: "asc",
              width: 270,
            },
            { label: "TIMESTAMP", field: "timestamp", sort: "asc", width: 100 },
            { label: "VERB", field: "verb", sort: "asc", width: 100 },
            { label: "MANAGE", field: "manage", sort: "asc", width: 100 },
          ];

          const reOrderedTableData = reOrderRows(tableColumns, data);

          dispatch({
            type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
            payload: {
              notificationsTableData: reOrderedTableData,
              notifications: data,
              count,
              next_page,
              prev_page,
            },
          });
        } else {
          dispatch({
            type: actionTypes.GET_NOTIFICATIONS_ERROR,
            payload: {
              message: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "notifications.js",
          },
        });
        dispatch({
          type: actionTypes.GET_NOTIFICATIONS_ERROR,
          payload: {
            error,
          },
        });
      });
  };
};

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns: any, responseDataRecords: any) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row: any, index: number) => {
        let newRow = {} as any;
        tableColumns.forEach((column: any) => {
          if (column.field === "id") {
            newRow[column.field] = index + 1;
            return;
          } else if (column.field === "pub_date") {
            newRow[column.field] = dateFormatter(row[column.field]);
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};
