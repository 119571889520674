import React, { Component } from 'react';

// Router
import { withRouter, Redirect } from 'react-router-dom';

// Personas
import OnboardingForm from "./Personas/Funder/Funder";

// Redux
import { connect } from "react-redux";
import { onboardPersona, updateOnboarded } from "../../redux/actions/onboarding";

// UI Components
import {ErrorLoader, Loader, SuccessLoader} from '../../UI/Loaders/Loaders';
class Onboarding extends Component {

    // Component State
    state = {
        // multiStepMode: "investor",
        // multiStepMenu: "on",
        // multiStepBorrower: "1",
        // advBorrower: {
        //     fullName: "",
        //     bvn: "",
        //     nationalId: "",
        //     address: "",
        //     phone: "",
        //     bank: "",
        //     accountNo: "",
        //     email: ""
        // },
        // advBorrowerTypes: [
        //     {id: 1, label: "Taxify", value: "Taxify"},
        //     {id: 2, label: "Uber", value: "Uber"},
        //     {id: 3, label: "Konga", value: "Konga",},
        //     {id: 4, label: "Jumia", value: "Jumia"}
        // ],
        // selectedBorrower: "",
        // loginForm: {
        //     password: "",
        //     email: "",
        //     address: "",
        //     firstName: "",
        //     lastName: "",
        //     phoneNumber: "",
        //     birthDate: "",
        //     gender: ""
        // },

        loginStep: "form"
    };

    // Life Cycle Hooks
    componentDidMount() {
        // this.updateDefaultBorrower();
    };

    // // Methods
    // setupBorrowers = () => {
    //     if (this.state.advBorrowerTypes) {
    //         return this.state.advBorrowerTypes.map((borrowerType) => {
    //             return (
    //                 <option
    //                     key={borrowerType.id}
    //                     value={borrowerType.label}>
    //                     {borrowerType.label}
    //                 </option>
    //             )
    //         });
    //     }
    // };

    // loginHandler = (e) => {
    //     e.preventDefault();

    //     let response = this.validateLoginCredentials(this.state.loginForm);
    //     if (!response) {

    //         return false;
    //     }

    //     this.setState({
    //         loginStep: 'loader'
    //     });

    //     setTimeout(() => {

    //         if (!res) {
    //             this.setState({
    //                 loginStep: 'failure'
    //             });

    //             setTimeout(() => {
    //                 this.setState({
    //                     loginStep: 'form'
    //                 });
    //             }, 3000);

    //         } else {
    //             this.setState({
    //                 loginStep: 'success'
    //             });

    //             setTimeout(() => {
    //                 this.props.updateAuthenticated(true);
    //                 this.props.updateUser(res);
    //                 this.props.history.push('/dashboard');
    //             }, 3000);
    //         }
    //     }, 5000);
    // };


    // custSelect = () => {
    //     if (this.state.advBorrowerTypes) {
    //         return this.state.advBorrowerTypes.map(opt => ({label: opt, value: opt}));
    //     }
    // };

    // updateDefaultBorrower = () => {
    //     if (this.state.advBorrowerTypes) {
    //         if (this.state.advBorrowerTypes && this.state.multiStepMode === "borrower") {
    //             this.setState({
    //                 selectedBorrower: this.state.advBorrowerTypes[0].label,
    //                 multiStepMenu: "on"
    //             });

    //         } else {
    //             this.setState({
    //                 selectedBorrower: this.state.advBorrowerTypes[0].label,
    //                 multiStepMenu: "off"
    //             });
    //         }
    //     }

    // };

    // updateSelectedBorrower = (e) => {
    //     if (e) {
    //         this.setState({
    //             selectedBorrower: e.target.value
    //         });
    //     }
    // };

    // borrowerDataHandler = (e) => {

    //     const target = e.target.name;

    //     (() => {
    //         switch (target) {
    //             case 'fullName':
    //                 return (
    //                     this.setState({
    //                         advBorrower: {
    //                             fullName: e.target.value
    //                         }
    //                     })
    //                 );
    //             case 'bvn':
    //                 return (
    //                     this.setState({
    //                         advBorrower: {
    //                             bvn: e.target.value
    //                         }
    //                     })
    //                 );
    //             case 'nationalId':
    //                 return (
    //                     this.setState({
    //                         advBorrower: {
    //                             nationalId: e.target.value
    //                         }
    //                     })
    //                 );

    //             default:

    //                 // console.log("Invalid Form Selection");

    //         }

    //     })();

    // };

    // importBorrowerData = (e) => {
    //     e.preventDefault();

    //     if (this.state.selectedBorrower) {

    //         if (this.state.selectedBorrower === "Taxify") {

    //             this.setState({
    //                 advBorrower: this.state.taxifyBorrower
    //             });
    //         } else if (this.state.selectedBorrower === "Uber") {
    //             this.setState({
    //                 advBorrower: this.state.uberBorrower
    //             });
    //         } else if (this.state.selectedBorrower === "Konga") {
    //             this.setState({
    //                 advBorrower: this.state.kongaBorrower
    //             });
    //         } else if (this.state.selectedBorrower === "Jumia") {
    //             this.setState({
    //                 advBorrower: this.state.jumiaBorrower
    //             });
    //         } else {

    //             // console.log('Invalid Avancly User Selected')

    //         }

    //         if (this.state.selectedBorrower) {
    //             document.querySelector('#adv-borrower-step1').classList.add('speed-1x', 'fadeOutLeft');
    //             setTimeout(() => {
    //                 document.querySelector('#adv-borrower-step1').classList.remove('speed-1x', 'fadeOutLeft');
    //                 document.querySelector('#adv-borrower-step1').classList.add('speed-1x', 'fadeInRight');
    //                 this.setState({multiStepBorrower: "2"});
    //             }, 500);
    //             setTimeout(() => {
    //                 document.querySelector('#stepOne').classList.remove('form-steps__step--active');
    //                 document.querySelector('#stepOne').classList.add('form-steps__step--completed');

    //                 document.querySelector('#stepTwo').classList.remove('form-steps__step--inactive');
    //                 document.querySelector('#stepTwo').classList.add('form-steps__step--active');

    //                 document.querySelector('#adv-importing').classList.remove('speed-1x', 'fadeInRight');
    //                 document.querySelector('#adv-importing').classList.add('speed-1x', 'fadeOutLeft');

    //                 setTimeout(() => {
    //                     document.querySelector('#adv-importing').classList.add('hidden');
    //                     document.querySelector('#adv-importing').classList.remove('speed-1x', 'fadeOutLeft');
    //                     document.querySelector('#adv-credentials').classList.remove('hidden');
    //                 }, 500)

    //             }, 3000);
    //         }

    //     }

    // };

    // pinCodeHandler = (e) => {
    //     e.preventDefault();
    //     if (this.state.selectedBorrower) {
    //         document.querySelector('#adv-borrower-step2').classList.add('speed-1x', 'fadeOutLeft');
    //         setTimeout(() => {
    //             document.querySelector('#adv-borrower-step2').classList.remove('speed-1x', 'fadeOutLeft');
    //             document.querySelector('#adv-borrower-step2').classList.add('speed-1x', 'fadeInRight');
    //             this.setState({multiStepBorrower: "3"});
    //         }, 500);
    //         setTimeout(() => {
    //             document.querySelector('#stepTwo').classList.remove('form-steps__step--active');
    //             document.querySelector('#stepTwo').classList.add('form-steps__step--completed');

    //             document.querySelector('#stepThree').classList.remove('form-steps__step--inactive');
    //             document.querySelector('#stepThree').classList.add('form-steps__step--active');

    //             document.querySelector('#adv-processing').classList.remove('speed-1x', 'fadeInRight');
    //             document.querySelector('#adv-processing').classList.add('speed-1x', 'fadeOutLeft');

    //             setTimeout(() => {
    //                 document.querySelector('#adv-processing').classList.add('hidden');
    //                 document.querySelector('#adv-processing').classList.remove('speed-1x', 'fadeOutLeft');
    //                 document.querySelector('#adv-tokenDialog').classList.remove('hidden');
    //             }, 500)

    //         }, 3000);
    //     }
    // };

    // pinCodeValidation = (e) => {
    //     e.preventDefault();
    //     let selection = e.target.id;

    //     // console.log("Token Delivery Method:", selection);

    //     if (this.state.selectedBorrower) {
    //         setTimeout(() => {

    //             document.querySelector('#adv-tokenDialog').classList.remove('speed-1x', 'fadeInRight');
    //             document.querySelector('#adv-tokenDialog').classList.add('speed-1x', 'fadeOutLeft');

    //             setTimeout(() => {
    //                 document.querySelector('#adv-tokenDialog').classList.add('hidden');
    //                 document.querySelector('#adv-tokenDialog').classList.remove('speed-1x', 'fadeOutLeft');
    //                 document.querySelector('#adv-pinCode').classList.remove('hidden');
    //             }, 500)

    //         }, 500);
    //     }
    // };

    onboardingHandler = (onboardingData) => {
        this.props.onboardPersona(onboardingData);
    };

    // onboardingHandler = (e) => {
    //     e.preventDefault();
    //     if (this.state.selectedBorrower) {
    //         // Verify Pin Code Init
    //         document.querySelector('#adv-borrower-step3').classList.add('speed-1x', 'fadeOutLeft');
    //         setTimeout(() => {
    //             document.querySelector('#adv-borrower-step3').classList.remove('speed-1x', 'fadeOutLeft');
    //             document.querySelector('#adv-borrower-step3').classList.add('speed-1x', 'fadeInRight');
    //             this.setState({multiStepBorrower: "4"});
    //         }, 500);
    //         setTimeout(() => {
    //             // Process Pin Code for a bit then Return Successful
    //             document.querySelector('#adv-processing-pin').classList.remove('speed-1x', 'fadeInRight');
    //             document.querySelector('#adv-processing-pin').classList.add('speed-1x', 'fadeOutLeft');
    //             setTimeout(() => {
    //                 document.querySelector('#adv-processing-pin').classList.add('hidden');
    //                 document.querySelector('#adv-processing-pin').classList.remove('speed-1x', 'fadeOutLeft');
    //                 document.querySelector('#adv-pin-success').classList.remove('hidden');
    //             }, 500);

    //             setTimeout(() => {
    //                 // Terminate Pin success Message & Process Account Creation
    //                 document.querySelector('#adv-pin-success').classList.remove('speed-1x', 'fadeInRight');
    //                 document.querySelector('#adv-pin-success').classList.add('speed-1x', 'fadeOutLeft');
    //                 setTimeout(() => {
    //                     document.querySelector('#adv-pin-success').classList.add('hidden');
    //                     document.querySelector('#adv-pin-success').classList.remove('speed-1x', 'fadeOutLeft');
    //                     document.querySelector('#adv-processing-signup').classList.remove('hidden');
    //                 }, 500);

    //                 setTimeout(() => {
    //                     // Update steps and Terminate Account creation

    //                     document.querySelector('#stepThree').classList.remove('form-steps__step--active');
    //                     document.querySelector('#stepThree').classList.add('form-steps__step--completed');

    //                     document.querySelector('#stepFour').classList.remove('form-steps__step--inactive');
    //                     document.querySelector('#stepFour').classList.add('form-steps__step--active');

    //                     document.querySelector('#adv-processing-signup').classList.remove('speed-1x', 'fadeInRight');
    //                     document.querySelector('#adv-processing-signup').classList.add('speed-1x', 'fadeOutLeft');
    //                     setTimeout(() => {

    //                         // Update steps and Display success message

    //                         document.querySelector('#stepFour').classList.remove('form-steps__step--active');
    //                         document.querySelector('#stepFour').classList.add('form-steps__step--completed');


    //                         document.querySelector('#adv-processing-signup').classList.add('hidden');
    //                         document.querySelector('#adv-processing-signup').classList.remove('speed-1x', 'fadeOutLeft');
    //                         document.querySelector('#adv-processing-signup-successful').classList.remove('hidden');

    //                         // Redirect to ReportIssue Page after 10 seconds
    //                         setTimeout(() => {
    //                             this.props.history.push('/login')
    //                         }, 3000);

    //                     }, 500);

    //                 }, 7000)

    //             }, 4000)

    //         }, 3000)
    //     }
    // };

    // outdatedRecordHandler = (e) => {
    //     e.preventDefault();
    //     if (this.state.selectedBorrower) {
    //         document.querySelector('#adv-credentials').classList.remove('speed-1x', 'fadeInRight');
    //         document.querySelector('#adv-credentials').classList.add('speed-1x', 'fadeOutLeft');
    //         setTimeout(() => {
    //             document.querySelector('#adv-credentials').classList.add('hidden');
    //             document.querySelector('#adv-outdated-credentials').classList.remove('hidden');
    //             document.querySelector('#adv-outdated-credentials').classList.add('speed-1x', 'fadeInRight');

    //         }, 700);

    //     }
    // };

    //JSX Template
    render() {

        const { loading, error, success, isOnboarded } = this.props.onboardingReducer;

        let renderedComponent = null;

        if (loading) {

            renderedComponent = <Loader text="Processing Onboarding ..."/>;

        }else if (error) {
            if(error.error_code){
                if(error.error_code === 400){
                    renderedComponent = (
                        <ErrorLoader 
                            text="Wrong Login Credentials"
                            subText="Kindly verify your email address, password and
                                    try again. Many Thanks."/>
                    );
                }
            }else{
                renderedComponent = (
                    <ErrorLoader 
                        text="Network Issue"
                        icon='fas fa-wifi'
                        subText="Unable to connect to Advancly services."/>
                );
            }

            setTimeout(() => {
                renderedComponent = <OnboardingForm onBoard={this.onboardingHandler} /> ; 
                this.props.updateOnboarded(false);
            }, 3000);

        } else if (success) {
            renderedComponent = (
                <div className="tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
                    <SuccessLoader 
                        text="Onboarding Successfully"
                        subText="You will be redirected to your dashboard shortly."/>
                </div>
            );

            setTimeout(() => {
                this.props.updateOnboarded(true);
            }, 3000);

        } else if (isOnboarded) {
            this.props.updateOnboarded(false);
            renderedComponent = <Redirect to="/login"/>;

        } else {
            renderedComponent = <OnboardingForm onBoard={this.onboardingHandler} /> ;  
        }

        return (
            <div className="center-container">
                <div className="center-container-wrapper">
                    <div className="center-element">
                        <div className="normal-1x zoomIn">
                            {/*ALGORITHM FOR STEP UI*/}
                            {
                                (() => {
                                    switch (this.state.multiStepMenu) {
                                        case 'on':
                                            return (
                                                <div className="tfx-step grid__full">
                                                    <div className="form-steps">
                                                        <div id="stepOne"
                                                             className="form-steps__step form-steps__step--active">
                                                            <div className="form-steps__step-circle">
                                                                <span className="form-steps__step-number">1</span>
                                                                <span className="form-steps__step-check"><i
                                                                    className="fas fa-check-circle"/></span>
                                                            </div>
                                                            <span
                                                                className="form-step__step-name"> Confirm Identity</span>
                                                        </div>

                                                        <div id="stepTwo"
                                                             className="form-steps__step form-steps__step--inactive">
                                                            <div className="form-steps__step-circle">
                                                                <span className="form-steps__step-number">2</span>
                                                                <span className="form-steps__step-check"><i
                                                                    className="fas fa-check-circle"/></span>
                                                            </div>
                                                            <span
                                                                className="form-step__step-name">Verify Credentials</span>
                                                        </div>

                                                        <div id="stepThree"
                                                             className="form-steps__step form-steps__step--inactive">
                                                            <div className="form-steps__step-circle">
                                                                <span className="form-steps__step-number">3</span>
                                                                <span className="form-steps__step-check"><i
                                                                    className="fas fa-check-circle"/></span>
                                                            </div>
                                                            <span
                                                                className="form-step__step-name">Verify PIN Code</span>
                                                        </div>

                                                        <div id="stepFour"
                                                             className="form-steps__step form-steps__step--last">
                                                            <div className="form-steps__step-circle">
                                                                <span className="form-steps__step-number">4</span>
                                                                <span className="form-steps__step-check"><i
                                                                    className="fas fa-check-circle"/></span>
                                                            </div>
                                                            <span className="form-step__step-name">Complete Setup</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }

                                })()
                            }

                            {/*ALGORITHM FOR ONBOARDING UI*/}
                            <div className="grid__full">
                                <div className="center-txt  m-b-40">
                                    {renderedComponent}   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ onboardingReducer }) => {

    return {
        onboardingReducer
    };
};

const mapDispatchToProps = {
    onboardPersona,
    updateOnboarded
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Onboarding));