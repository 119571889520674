import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    transactionsTableData: {
        columns: [],
        rows: []
    },
    transactions: [],
    count: 0,
    next_page: '',
    prev_page: '',
    loading: false,
    error: null
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.TRANSACTION_AJAX_START:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.ALL_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ...action.payload
            }

        case actionTypes.TRANSACTION_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                transactions: {
                    ...state.transactions,
                    columns: [...action.payload.columns],
                    rows: [...action.payload.rows],
                }  
        }

        case actionTypes.GET_TRANSACTIONS_FAILURE:
            return {
                loading: false,
                error: action.error
        }

        default:
            return state;
    }
}

export default reducer;