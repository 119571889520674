import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";

// Redux
import { connect } from "react-redux";
import { updateAuthenticated } from "./../../redux/actions/index";
import { postData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { errorHandler } from "../../helpers/errorHandler";
import { Loader } from "../../UI/Loaders/Loaders";
import { appInsights } from "../../config/appInsights";

const schema = yup.object().shape({
  currentPassword: yup.string().required("Please enter your current password"),
  newPassword: yup.string().required("Please enter your new password"),
  newPasswordAgain: yup
    .string()
    .required("Please enter your new password again"),
});

const ChangePassword = ({ isAuthenticated, history, loggedInUser }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  // Life Cycle Hooks
  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    const { currentPassword, newPassword, newPasswordAgain } = data;
    setLoading(true);
    setError(null);
    const reqBody = {
      old_password: currentPassword,
      password1: newPassword,
      password2: newPasswordAgain,
    };
    try {
      const response = await postData(ajaxEndpoints.CHANGE_PASSWORD, reqBody);
      setLoading(false);
      if (response.status && response.status === true) {
        setSuccess(
          "You password has been changed successfully. You will be required to log in again"
        );
        setTimeout(() => {
          history.push("/logout");
        }, 5000);
      } else {
        setError(response.message);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ChangePassword.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  //JSX Template
  if (loading) return <Loader text="Changing Password..." centered={true} />;

  return (
    <div className="animated slideInRight mt-30">
      <div className="datatable-wrapper">
        <div className="col-lg-3 col-md-3 col-sm-12"></div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card mt-5">
            <div className="body text-center">
              <h3 className="m-t-0 mb-5">
                <i className="adv-icon fas fa-lock" /> Change Password
              </h3>
              {loggedInUser && loggedInUser?.ForceChangeOfPassword && (
                <p className="text-center">
                  <small className="text-danger font-weight-bold ">
                    We have improved security on all accounts as part of our
                    commitment to you. Kindly update your password.
                  </small>
                </p>
              )}
              {error && (
                <p className="alert alert-danger mb-4 small">{error}</p>
              )}
              {success && (
                <p className="alert alert-success mb-4 small">{success}</p>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInputField
                  type="password"
                  maxLength="128"
                  reference={register}
                  name="currentPassword"
                  placeholder="Current Password"
                  label="Current Password"
                  errors={errors.currentPassword}
                  iconType="fas fa-lock"
                />

                <CustomInputField
                  type="password"
                  maxLength="128"
                  reference={register}
                  name="newPassword"
                  placeholder="New Password"
                  label="New Password"
                  errors={errors.newPassword}
                  iconType="fas fa-lock"
                />
                <CustomInputField
                  type="password"
                  maxLength="128"
                  reference={register}
                  name="newPasswordAgain"
                  placeholder="New Password Again"
                  label="New Password Again"
                  errors={errors.newPasswordAgain}
                  iconType="fas fa-lock"
                />

                <button
                  className="zoom-element btn login-btn"
                  disabled={loading}
                  type="submit"
                >
                  <i className="fas fa-lock" />
                  &nbsp; Change Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated, loggedInUser } = state.authReducer;
  // const {getSettings } = state.settingsReducer;

  return {
    isAuthenticated,
    loggedInUser,
  };
};

export default connect(mapStateToProps, { updateAuthenticated })(
  withRouter(ChangePassword)
);
