import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAggregatorDocuments = async ({ ProductType }) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.DOCUMENT}?ProductType=${ProductType}`
  );
  return data;
};

export default function useEligibilityDocument({ ProductType }) {
  // The id is a query identifier here
  return useQuery(
    [{ ProductType }, "getAggregatorDocuments"],
    getAggregatorDocuments
  );
}
