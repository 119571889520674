import React from "react";
import moment from "moment";
import styles from "../CreditBureau.module.scss";
const CreditBureauTableFacilityHistory24 = ({
  creditFacilityHistory24,
}: any) => {
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <h5 className={` ${styles.creditBureauHeading}`}>
        Credit Facility Details - Open Accounts
      </h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              S/N
            </th>
            <th className="th-td-style th-style" scope="col">
              Institution Name
            </th>
            <th className="th-td-style th-style" scope="col">
              Account Number
            </th>
            <th className="th-td-style th-style" scope="col">
              Credit Facility Type{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Credit Facility Status
            </th>
            <th className="th-td-style th-style" scope="col">
              Currency
            </th>
            <th className="th-td-style th-style" scope="col">
              Approved Amount{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Current Balance{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Amount Overdue{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Amount Written Off{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Credit Facility Opened Date{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Credit Facility Maturity Date{" "}
            </th>

            <th className="th-td-style th-style" scope="col">
              Repayment Frequency
            </th>
            <th className="th-td-style th-style" scope="col">
              Last Payment Date
            </th>
            <th className="th-td-style th-style" scope="col">
              NDIA{" "}
            </th>
            <th className="th-td-style th-style" scope="col">
              Date Restructured
            </th>
            <th className="th-td-style th-style" scope="col">
              Asset Classification
            </th>

            <th className="th-td-style th-style" scope="col">
              Security Coverage
            </th>
            <th className="th-td-style th-style" scope="col">
              Reported Date
            </th>
          </tr>
        </thead>
        <tbody>
          {creditFacilityHistory24?.map((history24: any, index: number) => {
            const {
              accounT_NUMBER,

              amounT_OVERDUE_CAL,

              asseT_CLASSIFICATION_CAL,

              currenT_BALANCE_CAL,

              currency,

              datE_REPORTED,

              institutioN_NAME,
              reporteD_DT_TEXT,

              loaN_TYPE_VALUE,

              maturitY_DT,

              reasoN_CODE_VALUE,

              repaymenT_FREQUENCY,

              sanctioneD_AMOUNT_CAL,

              securitY_VALUE,
              lastrepayamT_DT_CAL,
              sno,
              nuM_OF_DAYS_IN_ARREARS_CAL,
            } = history24;
            return (
              <tr key={sno}>
                <td className="th-td-style">{sno}</td>
                <td className="th-td-style">{institutioN_NAME}</td>
                <td className="th-td-style">{accounT_NUMBER}</td>
                <td className="th-td-style">{loaN_TYPE_VALUE}</td>
                <td className="th-td-style">{reasoN_CODE_VALUE}</td>
                <td className="th-td-style">{currency}</td>
                <td className="th-td-style">{sanctioneD_AMOUNT_CAL}</td>
                <td className="th-td-style">{currenT_BALANCE_CAL}</td>
                <td className="th-td-style">{amounT_OVERDUE_CAL}</td>
                <td className="th-td-style">{}</td>
                <td className="th-td-style">{datE_REPORTED}</td>
                <td className="th-td-style">
                  {" "}
                  {moment(maturitY_DT).format("DD/MM/YYYY")}
                </td>
                <td className="th-td-style">{repaymenT_FREQUENCY}</td>
                <td className="th-td-style">{lastrepayamT_DT_CAL}</td>
                <td className="th-td-style">{nuM_OF_DAYS_IN_ARREARS_CAL}</td>
                <td className="th-td-style">N/A</td>
                <td className="th-td-style">{asseT_CLASSIFICATION_CAL}</td>

                <td className="th-td-style">{securitY_VALUE}</td>
                <td className="th-td-style">{reporteD_DT_TEXT}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableFacilityHistory24;
