import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    investments: null,
    subscription: null,
    loading: false,
    error: null
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.GET_INVESTMENTS_START:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.GET_INVESTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                investments: {...action.payload}
        }

        case actionTypes.GET_INVESTMENTS_FAILURE:
            return {
                loading: false,
                error: action.error
        }

        default:
            return state;
    }
}

export default reducer;