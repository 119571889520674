import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  email: string;
};

const getRolesByEmail = async ({ email }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.MANAGE_ADMIN_USER}?email=${email}`
  );
  return data;
};

export default function useRolesByEmail({ email }: TQuery) {
  return useQuery([{ email }, "getRolesByEmail"], getRolesByEmail, {
    refetchOnWindowFocus: false,
    cacheTime: 2000, // This is to ensure that there is always a requery
  });
}
