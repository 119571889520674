import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  audit_type_id: string;
  start_date: string;
  end_date: string;
  page: number;
  size: number;
};

type TResponse = {
  data: {
    data: {
      action_date: string;
      audit_trail_type_id: number;
      audit_type: string;
      client_ip_address: string;
      current_data: string;
      id: number;
      object_id: number;
      object_reference_no: string;
      previous_data: string;
      user_id: number;
      username: string;
    }[];
  };
};

const getAuditTrail = async ({
  audit_type_id,
  start_date,
  end_date,
  page,
  size,
}: TQuery) => {
  let queryParams = `page=${page}&page_size=${size}`;
  if (audit_type_id) {
    queryParams = `${queryParams}&audit_type_id=${audit_type_id}`;
  }
  if (start_date) {
    queryParams = `${queryParams}&start_date=${start_date}`;
  }
  if (end_date) {
    queryParams = `${queryParams}&end_date=${end_date}`;
  }
  const data = await getData(`${ajaxEndpoints.PRODUCT_AUDITS}?${queryParams}`);
  return data;
};

export default function useAuditTrail({
  audit_type_id,
  start_date,
  end_date,
  page,
  size,
}: TQuery) {
  return useQuery<TResponse>(
    [{ audit_type_id, start_date, end_date, page, size }, "getAuditTrail"],
    getAuditTrail
  );
}
