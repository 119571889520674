import React, { useState } from "react";

// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import { accurateQuantifier } from "../../../../helpers/accurateQuantifier";

const Details = (props: any) => {
  const [state, setState] = useState({
    scene: "form",
    formScene: "account_details",
    tabs: [
      {
        name: "Account",
        id: "account_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Loan",
        id: "loan_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Disbursement Gateway",
        id: "gateway_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
  });

  const {
    // is_active,
    loan_amount,
    loan_amount_repay,
    loan_id,
    // loan_interest,
    loan_ref,
    // loan_repayment_balance,
    loan_tenure,
    // modified_date
    pub_date,
    settlement_day,
  } = props.properties.dataRecord;

  // Toggle Tab & Tab content for Data Table
  const toggleTabContent = (content: any) => {
    setState((prev) => {
      return {
        ...prev,
        formScene: content,
      };
    });
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        <h3 className="Modal__title p-b-1">
          Loan ID: <span>{loan_id}</span>
        </h3>
        {/* Partner Form */}
        {(() => {
          switch (state?.scene) {
            case "form":
              return (
                <form>
                  <div className="row m-b-1">
                    <Tabs
                      onContentToggle={toggleTabContent}
                      tabs={state?.tabs}
                    />
                  </div>
                  {(() => {
                    switch (state?.formScene) {
                      case "account_details":
                        return (
                          <AccountDetails
                            details={props.properties.dataRecord}
                          />
                        );

                      case "loan_details":
                        return (
                          <LoanDetails
                            details={{
                              loan_amount,
                              loan_amount_repay,
                              loan_ref,
                              loan_tenure,
                              pub_date,
                              settlement_day,
                            }}
                          />
                        );
                      case "gateway_details":
                        return (
                          <LoanGatewayDetails
                            details={props.properties.dataRecord}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                  <div className="Form__action-buttons m-t-2">
                    {/* <button type="button"
                                                  className="btn zoom-element btn login-btn m-0 mr-auto"
                                                  onClick={this.printHandler}>
                                                  <i className="fas fa-print"></i>
                                                  &nbsp; Print
                                              </button> */}
                    <button
                      type="button"
                      className="btn zoom-element btn login-btn"
                      onClick={props.onCloseModal}
                      data-dismiss="modal"
                    >
                      <i className="fas fa-times-circle"></i>
                      &nbsp; Close
                    </button>
                  </div>
                </form>
              );

            case "loader":
              return (
                <div>
                  {/* 2. login Form Loader */}
                  <div
                    id="adv-login-loader"
                    className="loading-overlay speed-1x fadeInRight"
                  >
                    <div className="form-loader form-loader-login verify">
                      <div className="centerLoader">
                        <div className="loading" id="adv-loader-alt" />
                        <div className="center-txt webapp-ripple-text">
                          <i className="notifier fas fa-user-circle" />
                          &nbsp; Processing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case "success":
              return (
                <div>
                  {/* 3. Login Response */}
                  <div
                    id="adv-login-response"
                    className="loading-overlay speed-1x fadeInRight"
                  >
                    <div className="form-loader form-loader-login verify">
                      <div className="centerLoader">
                        <div className="loading success-loader">
                          <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                        </div>
                        <div className="center-txt webapp-ripple-text">
                          Logged In Successfully
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case "fail":
              return (
                <div>
                  {/* 3. Login Response */}
                  <div
                    id="adv-login-response"
                    className="loading-overlay speed-1x fadeInRight"
                  >
                    <div className="form-loader form-loader-login verify">
                      <div className="centerLoader">
                        <div className="loading success-loader">
                          <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                        </div>
                        <div className="center-txt webapp-ripple-text">
                          Login Failed, try again later
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

// Account Details
const AccountDetails = (props: any) => {
  const { credit_bank_details, debit_bank_details } = props.details;

  return (
    <div className="m-b-2">
      <h5>DEBIT ACCOUNT</h5>
      <label className="label-txt m-t-1">Bank Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_details?.debit_bank_name || "Unavailable"}
        />
        <i className="fa fa-bank" />
      </div>
      <label className="label-txt">Account Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_details?.debit_bank_account_name || "Unavailable"}
        />
        <i className="fas fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_details?.debit_bank_account_num || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_details?.debit_bank_code || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <br />
      <h5>CREDIT ACCOUNT</h5>

      <label className="label-txt m-t-1">Bank Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_details?.credit_bank_name || "Unavailable"}
        />
        <i className="fa fa-bank " />
      </div>
      <label className="label-txt">Account Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_details?.credit_bank_account_name || "Unavailable"}
        />
        <i className="fa fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_details?.credit_bank_account_num || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_details?.credit_bank_code || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
    </div>
  );
};
// Loan Details
const LoanDetails = (props: any) => {
  const {
    loan_amount,
    loan_amount_repay,
    loan_ref,
    loan_tenure,
    pub_date,
    settlement_day,
  } = props.details;

  return (
    <React.Fragment>
      <label className="label-txt">Loan Reference</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          className="user__form form-control"
          readOnly
          value={loan_ref || "Unavailable"}
        />
        <i className="fas fa-user-circle" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={loan_amount || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Repayment Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_amount_repay || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <label className="label-txt">Loan Tenure</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={accurateQuantifier(loan_tenure, "day")}
        />
        <i className="fas fa-hourglass-start" />
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Date Created</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={new Date(pub_date).toString()}
            />
            {/* <i className="far fa-calendar-alt" /> */}
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Settlement Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={settlement_day}
            />
            <i className="fas fa-calendar-alt" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// loan gateway details
const LoanGatewayDetails = (props: any) => {
  const {
    disbursement_gateway_reference_no,
    disbursement_gateway_response,
    disbursement_gateway_status_id,
    disbursement_response_date_time,
    disbursement_gateway_request,
    disbursement_reference_no,
    disbursement_channel_id,
    disbursement_request_date_time,
    disbursement_gateway_final,
  } = props.details;

  return (
    <div className="m-b-2">
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Gateway Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursement_gateway_reference_no || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Gateway Status ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursement_gateway_status_id || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Disbursement Ref No:</label>
          <div className="form-group">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={disbursement_reference_no || "Unavailable"}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Channel ID:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursement_channel_id || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <label className="label-txt">Disbursement Gateway Final:</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={disbursement_gateway_final || "Unavailable"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Response:</label>
        <div className="form-group">
          <textarea
            readOnly
            className="user__form form-control"
            value={disbursement_gateway_response || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Gateway Request:</label>
        <div className="form-group">
          <textarea
            readOnly
            className="user__form form-control"
            value={disbursement_gateway_request || "Unavailable"}
          />
        </div>
      </div>
      <div className="row">
        <label className="label-txt">Response Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={
              new Date(disbursement_response_date_time).toString() ||
              "Unavailable"
            }
          />
        </div>
      </div>

      <div className="row">
        <label className="label-txt">Request Date/Time</label>
        <div className="form-group">
          <input
            readOnly
            className="user__form form-control"
            value={
              new Date(disbursement_request_date_time).toString() ||
              "Unavailable"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
