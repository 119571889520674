import React, {ChangeEvent, useEffect, useState} from "react";
import CustomInputField from "../../../../CustomHTMLElements/CustomInputField";
import {
  useInvestmentProspectsDetailsById,
  useProspectiveInvestmentStatuses
} from "../../../../../custom-hooks/useDebtFund";
import CustomSelectDropdown from "../../../../CustomHTMLElements/CustomSelectDropdown";
import { putDataWithDotNet } from "../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import {
  handleSwalErrorAlert,
  handleSwalSuccessAlert,
  logAppInsightErrors,
} from "../../../../../helpers";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { useRouteMatch } from "react-router-dom";
import { IInvestmentProspect } from "../../../../../interfaces/investmentProspect";
import { useQueryCache } from 'react-query';
import Loader from "../../../../../NewComponents/Loader/Loader.component";



const InvestmentProspectGeneralTab = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };
  const { data: investorData, isLoading : isLoadingInvestorData } = useInvestmentProspectsDetailsById({id : Number(id)})


  const [selectedStatus, setSelectedStatus] = useState<number | undefined>(
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleChangeStatus = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(Number(event?.target?.value));
  };



  const { data, isLoading: isLoadingData } = useProspectiveInvestmentStatuses();
  const queryClient = useQueryCache();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await putDataWithDotNet(
        `${ajaxEndpoints.GET_INVESTMENT_PROSPECT}/${id}`,
        {
          status: selectedStatus,
        },
      );
      handleSwalSuccessAlert(response.data?.message ?? response?.message)
      await queryClient.invalidateQueries(['getInvestmentProspectDetailsById', {id: Number(id)}]);
    } catch (error) {
      handleSwalErrorAlert(errorHandler(error));
      logAppInsightErrors(errorHandler(error), "InvestmentProspectGeneralTab");
    } finally {
      setIsLoading(false);
    }
  };

    useEffect(() => {
        if (investorData){
          setSelectedStatus(Number(investorData.status));
        }
      }, [investorData]);

  if (isLoadingInvestorData){
    return (
        <div className="d-flex justify-content-center">
        <Loader />
        </div>
    )
  }
  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <CustomSelectDropdown
                onChange={handleChangeStatus}
                name="status"
                value={selectedStatus}
                label={`${isLoadingData ? "Loading Statues..." : "Status"}`}
                className="round user__form form-control mb-3"
              >
                <option value=""> Select Status</option>
                {data &&
                  data?.map((data) => {
                    const { name, id } = data;
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Investment Product"
                defaultValue={investorData?.investmentProductName ?? "N/A"}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Investment Product type"
                defaultValue={investorData?.investmentProductTypeName ?? "N/A"}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Investment Product Subtype"
                defaultValue={investorData?.investmentProductSubTypeName ?? "N/A"}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Investment Product Subtype Detail"
                defaultValue={investorData?.investmentProductSubTypeDetailName ?? "N/A"}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Phone Number"
                defaultValue={investorData?.phoneNumber}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Email Address"
                defaultValue={investorData?.emailAddress}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Date Indicated"
                defaultValue={investorData?.dateIndicated}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Currency"
                defaultValue={investorData?.currency}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Amount Indicated"
                defaultValue={investorData?.amount}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Tenor"
                defaultValue={investorData?.tenor}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Interest Level"
                defaultValue={investorData?.readinessLevelName}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                label="Referred by"
                defaultValue={investorData?.referredBy ?? "N/A"}
                readOnly={true}
              />
            </div>
          </div>
          <button
            className="btn advancly-btn"
            type="submit"
            disabled={!selectedStatus}
          >
            {isLoading ? (
              <span className="spinner-border text-white" />
            ) : (
              "Update Status"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default InvestmentProspectGeneralTab;
