import React from "react";

export const FirstCentralPersonalData = ({ tableData, scoreData }) => {
  const {
    firstName,
    surname,
    birthDate,
    gender,
    nationality,
    bankVerificationNo,
    homeTelephoneNo,
  } = tableData;
  const {
    totalConsumerScore,
    description,
    repaymentHistoryScore,
    scoreDate,
  } = scoreData;
  return (
    <div>
      {Object.keys(tableData).length > 0 && (
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0 mb-3">
              <i className="adv-icon fas fa-cogs" />
              &nbsp;Details
            </h3>

            <div className="row col-md-12">
              <div>
                <div className="col-md-6">
                  <label className="label-txt">Name</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        firstName || surname
                          ? `${firstName}${surname}`
                          : "Name not found"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Phone Number</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        homeTelephoneNo
                          ? homeTelephoneNo
                          : "Phone number not found"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="col-md-6">
                  <label className="label-txt">Date of Birth</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={birthDate ? birthDate : "Date of Birth not found"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Gender</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={gender ? gender : "Gender not found"}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="col-md-6">
                  <label className="label-txt">BVN</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        bankVerificationNo
                          ? bankVerificationNo
                          : "BVN not found"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Nationality</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        nationality ? nationality : "Nationality not found"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-6">
                  <label className="label-txt">Total Consumer Score</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      value={totalConsumerScore ? totalConsumerScore : "N/A"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Score Description</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      value={description ? description : "N/A"}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-6">
                  <label className="label-txt">Repayment History Score</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      value={
                        repaymentHistoryScore ? repaymentHistoryScore : "N/A"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Score Date</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      value={scoreDate ? scoreDate : "N/A"}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
