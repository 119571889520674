import ModalDetailsRow from "../../ModalDetailsRow/ModalDetailsRow.component";

const NotificationsModal = ({
  properties: { dataRecord },
  onCloseModal,
}: any) => {
  const printHandler = () => {};
  const { timestamp, amount, description, manage } = dataRecord;

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h3 className="Modal__title p-b-2">Notification Details</h3>
          {/* AuditTrail Modal */}
          <form>
            <ModalDetailsRow
              label="Amount"
              value={amount ? amount : ""}
              icon="fas fa-user-circle"
            />
            <ModalDetailsRow
              label="Description"
              value={description}
              icon="far fa-calendar-alt"
              type="textarea"
            />
            <ModalDetailsRow
              label="Verb"
              value={manage ? manage : ""}
              icon="fas fa-user-circle"
            />
            <ModalDetailsRow
              label="Date/Time"
              value={`${timestamp.substring(0, 10)} / ${timestamp.substring(
                11,
                19
              )}`}
              icon="fas fa-user-circle"
            />

            <div className="Form__action-buttons m-t-1">
              <button
                type="button"
                className="btn zoom-element btn login-btn m-0 mr-auto"
                onClick={printHandler}
              >
                <i className="fas fa-print"></i>
                &nbsp; Print
              </button>
              <button
                type="button"
                className="btn zoom-element btn login-btn m-0 ml-auto"
                onClick={onCloseModal}
                data-dismiss="modal"
              >
                <i className="fas fa-times-circle"></i>
                &nbsp; Go Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NotificationsModal;
