import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAllRoles = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.ROLES);
  return data;
};

export default function useAllRoles() {
  return useQuery(["getAllRoles"], getAllRoles, {
    refetchOnWindowFocus: false,
  });
}
