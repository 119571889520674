import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { QueryResult, useQuery } from "react-query";
import { IQueueType } from "../interfaces/queue";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getQueueTypes = async () => {
  const data = await getData(ajaxEndpoints.GET_QUEUE_TYPES);
  return data.data;
};

export function useQueueType(): QueryResult<IQueueType[]> {
  return useQuery(["getQueueTypes"], getQueueTypes);
}

const getQueueList = async (query: any) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_QUEUES_ERROR_BUCKET_LIST}?${new URLSearchParams(
      query
    ).toString()}`
  );
  return data;
};

export function useQueueList(query: any) {
  return useQuery([query, "getQueueList"], getQueueList, {
    refetchOnWindowFocus: true,
  });
}
