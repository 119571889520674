import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

export type TInvestmentCurrency = {
  currency_code: string;
  currency_name: string;
  minimum_repayment_amount: number;
  minimum_wallet_topup_amount: number;
  tenantInfo: { tenantId: string; fullName: string };
};

export const getInvestmentCurrencies = async () => {
  const { data } = await getData(`${ajaxEndpoints.INVESTMENT_CURRENCIES}`);
  return data;
};

export default function useInvestmentCurrencies() {
  return useQuery<IResponse<TInvestmentCurrency[]>>(
    "investmentCurrencies",
    getInvestmentCurrencies,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
}
