import React, { Component } from "react";

// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";

class RetryFailedBatchDisbursement extends Component {
  render() {
    const { properties, onCloseModal } = this.props;
    const { subModal, retryFailedBatchLoanDibursement, dataRecord } =
      properties;
    const manageLoan = () => {
      onCloseModal();
      setTimeout(() => {
        retryFailedBatchLoanDibursement(dataRecord.batchLoanDisbursementKey);
      }, 100);
    };
    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          {(() => {
            switch (subModal) {
              case "single":
                return (
                  <React.Fragment>
                    {/* <h3 className="Modal__title p-b-1">Retry Summary</h3> */}
                    {/* 2. Confirm Update Role Modal*/}
                    <div id="disbursement-confirm">
                      <div className="m-b-30">
                        <h5>
                          Are you sure you want to retry the failed
                          disbursement?
                        </h5>

                        <div className="Form__action-buttons m-t-2">
                          <button
                            type="button"
                            onClick={manageLoan}
                            className="btn zoom-element btn login-btn m-0 mr-auto"
                          >
                            <i className="fa fa-check-circle" /> Proceed
                          </button>
                          <button
                            type="button"
                            onClick={this.props.onCloseModal}
                            className="btn zoom-element btn login-btn m-0 ml-auto"
                          >
                            <i className="fa fa-times-circle" /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* 2. Disbursement Loader*/}
                    <div
                      id="disbursement-loader"
                      className="hidden loading-overlay speed-1x fadeInRight"
                    >
                      <div className=" form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-cog" />
                            Processing Disbursement ...
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 3. Disbursement Success Message*/}
                    <div
                      id="disbursement-success"
                      className="hidden loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier far fa-check-circle" />
                            Disbursement Successful
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 4. Disbursement Error */}
                    <div
                      id="disbursement-error"
                      className="hidden loading-overlay speed-1x fadeInRight"
                    >
                      <ErrorLoader
                        icon="fas fa-wifi"
                        text="Network Issue"
                        subText="Unable to connect to Advancly services."
                      />
                    </div>
                  </React.Fragment>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

export default RetryFailedBatchDisbursement;
