import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  parent_rollover_loan_reference: string;
};

const getRolledOverLoans = async ({
  parent_rollover_loan_reference,
}: TQuery) => {
  const data = await getData(
    `${ajaxEndpoints.ROLLED_OVER_LOANS}?parent_rollover_loan_reference=${parent_rollover_loan_reference}`
  );
  return data;
};

export default function useRolledOverLoans({
  parent_rollover_loan_reference,
}: TQuery) {
  return useQuery(
    [{ parent_rollover_loan_reference }, "getRolledOverLoans"],
    getRolledOverLoans,
    { enabled: parent_rollover_loan_reference ? true : false }
  );
}
