import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IPaginatedResponse } from "../interfaces/response";

type TQuery = {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  aggregatorId?: number;
  referenceNumber?: string;
};

export type TTransactions = {
  advanclyAggregatorId: number;
  advanclyAggregatorName: string;
  amount: number;
  currencyCode: string;
  displayName: string;
  funderFirstName: string;
  funderLastName: string;
  funderMiddleName: string;
  transactionDate: string;
  transactionID: number;
  transactionType: string;
  transactionTypeId: number;
};

const getAllAggregatorTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  aggregatorId,
  referenceNumber,
}: TQuery) => {
  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&FromDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&ToDate=${endDate}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  if (referenceNumber) {
    queryParams = `${queryParams}&ReferenceNumber=${referenceNumber}`;
  }
  const { data } = await getData(
    `${ajaxEndpoints.GET_AGGREGATOR_INVESMENT_TRANSACTIONS}${queryParams}`
  );

  return data;
};

export default function useAllAggregatorTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  aggregatorId,
  referenceNumber,
}: TQuery) {
  return useQuery<IPaginatedResponse<TTransactions[]>>(
    [
      {
        pageNumber,
        size,
        startDate,
        endDate,
        aggregatorId,
        referenceNumber,
      },
      "getAllAggregatorTransactions",
    ],
    getAllAggregatorTransactions
  );
}
