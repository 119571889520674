import { getData, getReportData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getRegulatoryReportTypes = async () => {
  const data = await getReportData(ajaxEndpoints.GET_REGULATORY_REPORT_TYPES);
  return data.data;
};

export default function useRegulatoryReports() {
  return useQuery(["getRegulatoryReports"], getRegulatoryReportTypes);
}
