import { FormEvent, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { Loader } from "../../../../UI/Loaders/Loaders";
import useAllPermissionsByRole from "../../../../custom-hooks/useAllPermissionsByRole";
import { postData } from "../../../../newApis/apiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { showModal } from "../../../../redux/actions/modal";
import CustomCheckBox from "./CustomCheckBox";

interface IUpdateRolePermissions {
  dataRecord: {
    name: string;
    id: number;
    allPermissions: {
      action_name: string;
      category: string;
      id: number;
      name: string;
    }[];
  };
  onCloseModal: () => void;
}

export default function UpdateRolePermissions({
  dataRecord,
  onCloseModal,
}: IUpdateRolePermissions) {
  const [preselectedPermissions, setPreSelectedPermissions] = useState<
    number[]
  >([]);
  const [error, setError] = useState<null | string>("");
  const [isUpdating, setIsUpdating] = useState(false);

  const { id: roleId, allPermissions } = dataRecord;

  const { data: currentRoleData, status } = useAllPermissionsByRole(roleId);

  const dispatch = useDispatch();

  useEffect(() => {
    // This section sets the default roles state to the roles the person currently has.
    let permissionsTobeAssigned = [] as number[];
    currentRoleData &&
      currentRoleData?.data &&
      currentRoleData?.data?.permissions.forEach(
        (permission: { id: number; name: string }) => {
          permissionsTobeAssigned.push(permission?.id);
        }
      );
    setPreSelectedPermissions(permissionsTobeAssigned);
  }, [currentRoleData]);

  const someFunctionThatWillBeRenamed = (
    allPermissions: { name: string; id: number }[]
  ) => {
    let result = [] as { name: string; id: number; isSelected: boolean }[];

    if (allPermissions && allPermissions.length > 0) {
      allPermissions.forEach((_item) => {
        const isAlreadySelected = preselectedPermissions.some(
          (_permissionId) => _permissionId === _item?.id
        );

        if (isAlreadySelected) {
          result.push({ ..._item, isSelected: isAlreadySelected });
        } else {
          result.push({ ..._item, isSelected: false });
        }
      });

      return result;
    }

    return [];
  };

  const handleMapPermissionToRoles = async (payload: {
    permissions: number[];
  }) => {
    setIsUpdating(true);
    try {
      const response = await postData(
        `${ajaxEndpoints.ROLES}/${roleId}/permissions`,
        payload
      );

      if (response?.status_code === 200) {
        onCloseModal();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.message },
          })
        );
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.message || "Something went wrong",
            },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.message || "Something went wrong",
          },
        })
      );
    }
    setIsUpdating(false);
  };

  const updateRolePermissions = async (e: FormEvent) => {
    e.preventDefault();
    if (!preselectedPermissions.length) {
      setError("You have to select at least one permission!");
      const element = document.getElementById("permissions-error-element");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        return;
      }

      setTimeout(() => {
        setError(null);
      }, 3000);
    }

    await handleMapPermissionToRoles({ permissions: preselectedPermissions });
  };

  const onChange = (e: any, id: number) => {
    setError("");
    if (e.target.checked) {
      const newRoles = [...preselectedPermissions, id];
      setPreSelectedPermissions(newRoles);
    } else {
      const newRoles = preselectedPermissions.filter(
        (_permissionId) => _permissionId !== id
      );
      setPreSelectedPermissions(newRoles);
    }
  };

  if (status === "loading")
    return (
      <Loader
        centered={true}
        text={`Loading user's current permission(s)...`}
      />
    );

  return (
    <div className="" style={{ height: "70vh", overflowY: "scroll" }}>
      <h3 className="Modal__title p-b-2 text-center">
        Update Role Permissions: ({currentRoleData?.data?.name})
      </h3>

      {error && <Alert message={error} />}

      <form onSubmit={updateRolePermissions} className="p-l-2">
        <small className="error-element p-t-2">
          Tick all the permissions to be assigned to this role.
        </small>
        {someFunctionThatWillBeRenamed(allPermissions).map((item, index) => (
          <CustomCheckBox
            isSelected={item?.isSelected}
            key={index}
            name={item?.name}
            id={item?.id}
            onChange={onChange}
          />
        ))}

        <div className="Form__action-buttons m-t-1">
          <button
            type="button"
            className="btn zoom-element btn login-btn m-0 mr-auto"
            onClick={onCloseModal}
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Cancel
          </button>
          <button
            type="submit"
            className="btn zoom-element btn login-btn m-0 ml-auto"
            data-dismiss="modal"
          >
            <i className="fas fa-arrow-right"></i>
            &nbsp; Update Role {isUpdating && <Loader />}
          </button>
        </div>
      </form>
    </div>
  );
}
