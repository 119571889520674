import closeIcon from "../../../../../assets/svg/icons/Close.svg";

interface IDismissableBadge {
  title: string;
  badgeCount: number;
  handleClose: () => void;
}

const DismissableBadge = ({
  title,
  badgeCount,
  handleClose,
}: IDismissableBadge) => {
  return (
    <div
      style={{
        height: "2.4rem",
        padding: "0.4rem 0.625rem",
        borderRadius: "10px",
        backgroundColor: "#F8F5FF",
        // position: "relative",
        minWidth: "5rem",
        display: "inline-block",
        marginRight: "1rem",
        marginBottom: "0.5rem",
      }}
    >
      {title}
      <span
        style={{
          display: "inline-block",
          height: "1.5rem",
          width: "1.5rem",
          borderRadius: "50%",
          backgroundColor: "#200865",
          marginLeft: "0.75rem",
          // position: "absolute",
          bottom: 7,
          color: "#ffffff",
          textAlign: "center",
        }}
      >
        {badgeCount}
      </span>
      <img
        src={closeIcon}
        width={24}
        height={24}
        style={{ marginLeft: "0.75rem", cursor: "pointer" }}
        onClick={handleClose}
      />
    </div>
  );
};

export default DismissableBadge;
