import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const fetchBankStatementUploads = async ({
  customerId,
}: {
  customerId: number;
}) => {
  const response = await getData(
    `${ajaxEndpoints.BANK_STATEMENT_UPLOAD_HISTORY}?customerId=${customerId}`
  );

  return response.data;
};

export default function useBankStatementUploadHistory({
  customerId,
}: {
  customerId: number;
}) {
  return useQuery(
    [{ customerId }, "fetchBankStatementUploads"],
    fetchBankStatementUploads,
    {
      refetchOnWindowFocus: true,
    }
  );
}
