import { ChangeEvent, Fragment, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import useAllDueRepayment from "../../../custom-hooks/useAllDueRepayment";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import useAllAggregatorsTyped from "../../../custom-hooks/useAllAggregatorsTyped";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showModal, hideModal } from "../../../redux/actions/modal";
import { setToast } from "../../../redux/actions/toast";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { useQueryCache } from "react-query";
import { errorHandler } from "../../../helpers/errorHandler";
import { formatMoney } from "../../../helpers/formatter";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import useWalletDetails from "../../../custom-hooks/useWalletDetails";
import { appInsights } from "../../../config/appInsights";
import { BASE_URL } from "../../../appConstants";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";

const DueLoanRepayment = () => {
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [repayError, setRepayError] = useState("");
  const [repaySuccess, setRepaySuccess] = useState("");
  // Component State
  const [state, setState] = useState<{
    checkStatus: string;
    loansForRepayment: any[];
    totalAmount: number;
  }>({
    checkStatus: "off",
    loansForRepayment: [],
    totalAmount: 0,
  });
  const [query, setQuery] = useState({
    PastDue: "",
    MinDateDue: "",
    ToDateDue: "",
    FromDateDue: "",
    LoanRef: "",
    size: 20,
    aggregatorId: "",
    pageNumber: 1,
  });
  const [search, setSearch] = useState({
    PastDue: "",
    MinDateDue: "",
    ToDateDue: "",
    FromDateDue: "",
    LoanRef: "",
    size: 20,
    aggregatorId: "",
    pageNumber: 1,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { data: aggregatorData } = useAllAggregatorsTyped();
  const { data, status, error } = useAllDueRepayment(search);
  const { data: walletDetails, status: walletDetailsStatus } = useWalletDetails(
    search.aggregatorId,
  );

  const queryCache = useQueryCache();
  const resetLoansForRepayment = () => {
    setState({
      ...state,
      loansForRepayment: [],
      totalAmount: 0,
      checkStatus: "off",
    });
  };
  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value, pageNumber: 1, size: 20 });
  };

  const handleSearch = () => {
    const diffTime =
      new Date(query.ToDateDue).valueOf() -
      new Date(query.FromDateDue).valueOf();
    if (diffTime < 0) {
      dispatch(
        showModal({
          modalIdentifier: "audittrail",
          dataRecord: "",
          action: "customrange",
          type: "alert",
        }),
      );
      return;
    } else {
      setSearch(query);
    }
  };

  // Repay Selected Loans
  const repaySelectedLoans = (e: any) => {
    e.preventDefault();
    const loansForRepayment = state.loansForRepayment;
    if (loansForRepayment.length === 0)
      return dispatch(setToast("warning", "You have not selected any loans"));
    dispatch(
      showModal({
        modalIdentifier: "Loan",
        dataId: e.target.dataset["ref"],
        action: "repayapprovedbatchloan",
        subModal: "multiple",
        loans: state.loansForRepayment,
        totalAmount: state.totalAmount,
        repayBatchLoans,
      }),
    );
  };

  const repayBatchLoans = async () => {
    setLoading(true);
    setRepayError("");
    setRepaySuccess("");

    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.AUTO_REPAY_LOAN}?AggregatorId=${search.aggregatorId}`,
        state.loansForRepayment,
      );
      queryCache.invalidateQueries([search, "getAllDueRepayment"]);
      queryCache.invalidateQueries([search.aggregatorId, "getWalletDetails"]);
      setLoading(false);
      setRepaySuccess(response.message);
      dispatch(hideModal());
      setSearch({ ...search, pageNumber: 1 });
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DueLoanRepayment.jsx",
        },
      });
      queryCache.invalidateQueries([search, "getAllDueRepayment"]);
      queryCache.invalidateQueries([search.aggregatorId, "getWalletDetails"]);
      setLoading(false);
      setRepayError(errorHandler(error));
      dispatch(hideModal());
      setSearch({ ...search, pageNumber: 1 });
    }
    setState({
      ...state,
      checkStatus: "off",
      loansForRepayment: [],
      totalAmount: 0,
    });
  };
  // Multiple Checkbox
  const checkAllLoansForRepayment = (e: any) => {
    e.preventDefault();
    if (e.target.dataset["state"] === "checked") {
      const allLoans = document.querySelectorAll(".loans_for_repayment") as any;
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }

      e.target.dataset["state"] = "unchecked";

      setState({
        ...state,
        checkStatus: "off",
        totalAmount: 0,
        loansForRepayment: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans = document.querySelectorAll(".loans_for_repayment") as any;
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loan_Id = Number(allLoans[i].dataset["ref"]);
      const amount = Number(allLoans[i].dataset["amounttorepay"]);
      const duedate = Number(allLoans[i].dataset["duedate"]);
      loans.push({ loan_Id, amount, duedate });
      totalAmount += Number(
        JSON.parse(allLoans[i].dataset["record"]).amountToRepay,
      );
    }

    e.target.dataset["state"] = "checked";

    setState({
      ...state,
      checkStatus: "on",
      totalAmount,
      loansForRepayment: [...state.loansForRepayment, ...loans],
    });
  };

  const handleDownload = () => {
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();

    const query = { ...search };

    Object.keys(query).map((item) => {
      if (
        query[item as keyof typeof query] === null ||
        query[item as keyof typeof query] === undefined ||
        query[item as keyof typeof query] === ""
      ) {
        delete query[item as keyof typeof query];
      }
    });

    fetch(
      BASE_URL +
        `${ajaxEndpoints.DOWNLOAD_ALL_DUE_LOAN_REPAYMENT}?${new URLSearchParams(
          {
            ...query,
            pageNumber: String(query?.pageNumber),
            size: String(query?.size),
          },
        )}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      },
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${`DueLoanRepayments.csv`}`);
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "DueLoanRepayment.jsx (Download)",
          },
        });

        setDownloadLoading(false);
      });
  };

  // Open Modal
  const modalHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    tableName: string,
    modalRow: any,
    action: string,
    resetLoansForRepayment: any,
    subModal: any,
  ) => {
    if (subModal === "single") {
      dispatch(
        showModal({
          modalIdentifier: tableName,
          dataRecord: modalRow,
          eventTarget: e.target,
          action: action,
          resetLoansForRepayment,
          repayBatchLoans,
          loading,
          request: modalRow,
        }),
      );
    } else {
      dispatch(
        showModal({
          modalIdentifier: tableName,
          dataRecord: modalRow,
          eventTarget: e.target,
          action: action,
          request: modalRow,
          repayBatchLoans,
          loading,
        }),
      );
    }
  };
  // Single Checkbox
  const checkboxHandler = ({
    dataRecord: { amountToRepay },
    eventTarget,
  }: any) => {
    let loans = [] as { loan_Id: number; amount: number; duedate: number }[];
    const loan_Id = Number(eventTarget.dataset["ref"]);
    const amount = Number(eventTarget.dataset["amounttorepay"]);
    const duedate = Number(eventTarget.dataset["duedate"]);
    loans.push({ loan_Id, amount, duedate });
    if (eventTarget.checked === false) {
      const loansForRepayment = state.loansForRepayment;
      const newLoansForRepayment = loansForRepayment.filter((loan) => {
        return (
          Number(loan.duedate) !== Number(duedate) ||
          Number(loan.loan_Id) !== Number(loan_Id)
        );
      });
      const totalamount = state.totalAmount
        ? Number(state.totalAmount) - Number(amountToRepay)
        : 0;
      return setState((prev) => {
        return {
          ...prev,
          loansForRepayment: [...newLoansForRepayment],
          totalAmount: totalamount,
        };
      });
    }

    setState((prev) => {
      return {
        ...prev,
        loansForRepayment: [...state.loansForRepayment, ...loans],
        totalAmount: state.totalAmount + Number(amountToRepay),
      };
    });
  };

  let RenderedComponent = null;
  if (!data && !search.aggregatorId && status !== "loading" && !error) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> No aggregator is selected
          </h4>
        </div>
      </div>
    );
  } else if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Due Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading Due Loans..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              due loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th></th>
                  <th>Loan Id</th>
                  <th>Reference</th>
                  <th>Customer</th>
                  <th>Aggregator</th>
                  <th>Product</th>
                  <th>Outstanding Amount</th>
                  <th>Principal Amount</th>
                  <th>Interest</th>
                  <th>Overdue Days</th>
                  <th>Due Date</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((request: any, id: number) => {
                  const {
                    loanRef,
                    customerName,
                    aggregatorName,
                    productName,
                    amountToRepay,
                    dateDue,
                    principal,
                    currency,
                    interest,
                    daysOverdue,
                    loanId,
                  } = request;
                  return (
                    <tr key={id}>
                      <td>
                        <input
                          type="checkbox"
                          data-record={JSON.stringify(request)}
                          data-id={loanId}
                          data-ref={loanId}
                          data-amounttorepay={Number(amountToRepay)}
                          data-duedate={Number(daysOverdue)}
                          defaultChecked={state.loansForRepayment.includes(
                            loanId,
                          )}
                          onClick={(e) =>
                            checkboxHandler({
                              dataRecord: request,
                              eventTarget: e.target,
                            })
                          }
                          className={`Loan loans_for_repayment`}
                        />
                      </td>
                      <td>{loanId}</td>
                      <td>{loanRef}</td>
                      <td className="text-uppercase" style={{ width: "10%" }}>
                        {customerName}
                      </td>
                      <td className="text-uppercase" style={{ width: "10%" }}>
                        {aggregatorName}
                      </td>
                      <td className="font-weight-semi-normal">{productName}</td>
                      <td>{formatMoney(amountToRepay, currency)}</td>
                      <td>
                        {principal ? formatMoney(principal, currency) : null}
                      </td>
                      <td>
                        {interest ? formatMoney(interest, currency) : null}
                      </td>
                      <td style={{ minWidth: "120px" }}>{daysOverdue}</td>
                      <td style={{ minWidth: "120px" }}>
                        {dateDue?.substring(0, 10)}
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn zoom-element btn-slim dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span
                              key="0"
                              id="0"
                              data-id={loanId}
                              data-table={"DueLoanRepayment"}
                              data-action="View-Transaction-History"
                              className="Loan cursor-pointer dropdown-item"
                              onClick={(e) => {
                                dispatch(
                                  showModal({
                                    modalIdentifier: "loantransactionhistory",
                                    dataRecord: loanRef,
                                  }),
                                );
                              }}
                            >
                              TRANSACTION HISTORY
                            </span>
                            <span
                              key="0"
                              id="0"
                              data-id={loanId}
                              data-table={"Loan"}
                              data-action="Mifos"
                              className="Loan cursor-pointer dropdown-item"
                              onClick={(e) => {
                                dispatch(
                                  showModal({
                                    modalIdentifier: "mifos",
                                    dataRecord: { loan_ref: loanRef },
                                  }),
                                );
                              }}
                            >
                              CORE BANKING
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        {repayError && <Alert message={repayError} />}
        {repaySuccess && <Alert message={repaySuccess} type="success" />}
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <div className="block-header">
            <div className="flex-row m-b-2 m-t-1">
              {walletDetailsStatus === "loading" && search.aggregatorId ? (
                <ButtonLoader />
              ) : (
                <h5 className="page-subtitle">
                  {walletDetails
                    ? `Wallet Balance: 
                  ${
                    walletDetails?.summary?.availableBalance
                      ? formatMoney(
                          walletDetails?.summary?.availableBalance,
                          walletDetails?.summary?.currency?.code,
                        )
                      : "N/A"
                  }`
                    : ""}
                </h5>
              )}

              <div className="flex-row m-b-1">
                <div className="m-r-1">
                  <div className="rounded-border flex-row">
                    <div
                      data-state="unchecked"
                      onClick={checkAllLoansForRepayment}
                    >
                      <i
                        className={`adv-icon fas fa-toggle-${state.checkStatus} fa-2x`}
                      />
                    </div>
                    &nbsp; <span>Select all</span>
                  </div>
                </div>
                <div className="m-r-1 rounded-border">
                  Selected: {state?.loansForRepayment?.length}
                </div>

                <div className="rounded-border">
                  Total Amount: {state?.totalAmount?.toFixed(2)}
                </div>
              </div>

              <div className="m-b-1">
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="create zoom-element btn advancly-btn"
                  onClick={(e) => {
                    modalHandler(
                      e,
                      "Loan",
                      state,
                      "loanrepayment",
                      resetLoansForRepayment,
                      repaySelectedLoans,
                    );
                  }}
                  disabled={!state.loansForRepayment?.length}
                >
                  <i className="far fa-credit-card" /> Repay Loan
                  {loading && <ButtonLoader />}
                </button>
                <button
                  id="create"
                  className="create zoom-element btn advancly-btn ml-2"
                  onClick={handleDownload}
                >
                  <i className="fas fa-download m-r-1" /> Download
                  {downloadLoading && <ButtonLoader />}
                </button>
              </div>
            </div>
            <form className="d-flex flex-row flex-wrap">
              <div>
                <CustomSelectDropdown
                  onChange={(e) => handleChange(e)}
                  name="aggregatorId"
                  value={query.aggregatorId}
                  className="round"
                >
                  <option value={""}>All Aggregators</option>
                  {aggregatorData &&
                    aggregatorData?.data?.data.map((aggregator) => {
                      const { biz_name, aggregator_id } = aggregator;
                      return (
                        <option
                          value={Number(aggregator_id)}
                          key={aggregator_id}
                        >
                          {biz_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="round user__form form-control"
                  placeholder="Loan Ref"
                  name="LoanRef"
                  onChange={(e) => handleChange(e)}
                  value={query.LoanRef}
                />
              </div>
              <div className="form-group inputWithIcon">
                <input
                  type="number"
                  placeholder="Due Days"
                  className="round user__form form-control"
                  name="PastDue"
                  value={query.PastDue}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="d-flex justify-content-center">
                <div className="form-group mr-3 mt-1">
                  <div className="input-group">
                    <label className="mr-2">From</label>
                    <input
                      type="date"
                      name="FromDateDue"
                      className="form-control"
                      value={query.FromDateDue}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group mr-3 mt-1">
                  <div className="input-group ">
                    <label className="mr-2">To </label>
                    <input
                      type="date"
                      name="ToDateDue"
                      className="form-control"
                      value={query.ToDateDue}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-start">
            <label htmlFor="table-fetch" className="form-group">
              Number of results:{" "}
              <select
                onChange={(e) => {
                  setQuery({
                    ...query,
                    size: Number(e.target.value),
                    pageNumber: 1,
                  });
                }}
                id="table-fetch"
                className="table-fetch-select m-l-1"
                value={query.size}
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={100}>150</option>
                <option value={100}>200</option>
              </select>
            </label>
            <div className="m-0 p-0">
              <button
                type="button"
                className="btn advancly-btn"
                style={{ marginTop: "-0.8rem", marginLeft: "1rem" }}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>

          {RenderedComponent}
          <Paginator
            size={search.size}
            page={search.pageNumber}
            count={data?.totalCount}
            changeCurrentPage={(pageNumber: number) =>
              setSearch({ ...search, pageNumber })
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DueLoanRepayment;
