import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// UI Components
import Search from "./Search";
import Results from "./Results";
import { Loader, InfoLoader } from "../../../UI/Loaders/Loaders";

export class Filter extends Component {
  // State
  state = {
    searchTerm: "",
    noResult: [
      {
        mode: "view",
        name: "No Result Found",
        id: 1,
        status: true,
        img: "no-result.png",
      },
    ],
  };

  // Methods
  updateSearchTerm = (searchTerm) => {
    this.setState({ searchTerm });
  };

  populateEcosystemList = (searchTerm, aggregators) => {
    const { noResult } = this.state;

    if (aggregators) {
      let availableList,
        ecoSystems = aggregators.filter((item) =>
          // Filter Item Gotten from search form
          item.biz_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

      if (ecoSystems.length > 0) {
        availableList = [...ecoSystems];
        return availableList.map((item) => {
          item.mode = "view";

          return (
            <Results
              key={item.aggregator_id}
              miniView={this.props.view}
              item={item}
            />
          );
        });
      } else {
        availableList = [...noResult];
        return availableList.map((item, index) => {
          item.mode = "view";

          return (
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 animated fadeInDown">
              {/*Result*/}
              <div
                className={
                  item.is_active === true
                    ? "ecosystem card zoom-element aggSelect"
                    : "card opac-5 ecosystem"
                }
              >
                <div className="body text-center">
                  <h4 className="biz_name m-t-0">{item.name}</h4>
                  <img
                    className="adv-clients"
                    src={`/assets/clients/${item.img}`}
                    alt={item.name}
                  />
                </div>
              </div>
            </div>
          );
        });
      }
    }
    return false;
  };

  // Life Cycle Hooks
  componentDidMount() {}

  render() {
    // State
    const { searchTerm } = this.state;

    // Props
    const { loggedInUser, dataRecords } = this.props;
    const { aggregators, loading, error } = dataRecords;
    const userType = loggedInUser?.user_type;
    let RenderedComponent = null;

    if (loading) {
      RenderedComponent = <Loader text="Loading available Ecosystems ..." />;
    } else if (error || aggregators === undefined) {
      RenderedComponent = (
        <InfoLoader
          text="There was issue fetching Ecosystems."
          subText="Check your network connection, then reload the page."
        />
      );
    } else if (aggregators) {
      RenderedComponent = this.populateEcosystemList(searchTerm, aggregators);
    }

    return (
      <div>
        <Search
          searchTerm={searchTerm}
          onChange={this.updateSearchTerm}
          userType={userType}
        />
        <div id="resultSet" className="clearfix">
          {RenderedComponent}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedInUser } = state.authReducer;
  const { aggregators, loading, error } = state.aggregatorsReducer;

  return {
    loggedInUser,
    aggregators,
    error,
    loading,
  };
};

export default connect(mapStateToProps)(Filter);
