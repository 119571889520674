export const handleRollOverModalToggle = () => {
  const rollOverToggler = document.getElementById("custom-rollover-button");

  rollOverToggler.dataset.toggle = "modal";
  rollOverToggler.dataset.target = "#principalOrInterestModal";
  rollOverToggler.click();
};

export const handleFundInvestmentModalToggle = () => {
  const fundInvestmentToggler = document.getElementById(
    "custom-fund-investment-button"
  );

  fundInvestmentToggler.dataset.toggle = "modal";
  fundInvestmentToggler.dataset.target = "#fundInvestmentModal";
  fundInvestmentToggler.click();
};

export const handleInvestmentWithdrawalModalToggle = () => {
  const withdrawInvestmentToggler = document.getElementById(
    "custom-withdraw-investment-button"
  );

  withdrawInvestmentToggler.dataset.toggle = "modal";
  withdrawInvestmentToggler.dataset.target = "#withdrawInvestmentModal";
  withdrawInvestmentToggler.click();
};

export const InvestmentStatus = {
  ACTIVE: "Active",
  CLOSED: "Closed",
};
