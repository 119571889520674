import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAnInvestmentProduct = async (id: number) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT}/${id}`
  );
  return data.data;
};

export default function useAnInvestmentProduct(id: number) {
  return useQuery([id, "getAnInvestmentProduct"], getAnInvestmentProduct, {
    refetchOnWindowFocus: false,
  });
}
