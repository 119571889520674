import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import useAllFunderKycDocuments from "../../../custom-hooks/useAllFunderKycDocuments";
import { DownloadUploadedDocument } from "../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import useDocumentViewer from "../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

import styles from "./InvestorsDocument.module.scss";

interface IInvestorDocuments {
  id: number;
}

const InvestorsDocuments = ({ id }: IInvestorDocuments) => {
  const { handleViewDocument } = useDocumentViewer();
  const {
    data: FunderKycDocuments,
    isLoading,
    error,
  } = useAllFunderKycDocuments({
    funder_id: id.toString(),
  });

  return (
    <div className={styles.uploadedDocsContainer}>
      {FunderKycDocuments && FunderKycDocuments?.data.length ? (
        <div>
          {FunderKycDocuments?.data.map((uploadedDocument: any, _id: any) => (
            <div className="row mb-5" key={_id}>
              <div className="col-12 d-flex justify-content-between">
                <label className="form-label">
                  {_id + 1}. {uploadedDocument.document_field_Name}
                </label>
              </div>
              <div className="col-12 d-flex">
                <div className="col-12">
                  {uploadedDocument.funder_documents &&
                    uploadedDocument.funder_documents.map((item: any) => (
                      <div className="d-flex" key={uuidv4()}>
                        <div className={styles.downloadUploadDocContainer}>
                          <DownloadUploadedDocument
                            name={item.file_name}
                            pathname={item.upload_path}
                            handleView={() =>
                              handleViewDocument(false, item?.upload_path)
                            }
                          />
                        </div>
                        <div className="d-flex ml-3">
                          <div className="d-flex">
                            <span
                              className={`${attachClassNameToTag(
                                item?.approval_status
                              )} ${styles.aprovalStatus}`}
                            >
                              {attachClassNameToTag(item?.approval_status)}
                            </span>
                          </div>

                          <span className={styles.documentTimeStamp}>
                            {`Upload Date: ${moment(item?.uploaded_at).format(
                              "LLL"
                            )}`}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">Funder has no kyc documents</h3>
        </div>
      )}
    </div>
  );
};

export default InvestorsDocuments;
