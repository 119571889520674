import AddUser from "./Components/AddUser.component";
import UpdateRolePermissions from "./Components/UpdateRolePermissions";
import UpdateUser from "./Components/UpdateUser.component";
import UpdateUserRole from "./Components/UpdateUserRole";
import ViewUser from "./Components/ViewUser.component";

const UserCreationModal = ({ properties: { dataRecord }, onCloseModal }) => {
  const { type, rolesData } = dataRecord;
  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          {(() => {
            switch (type) {
              case "update_user":
                return (
                  <UpdateUser
                    dataRecord={dataRecord}
                    onCloseModal={onCloseModal}
                  />
                );
              case "view_user":
                return (
                  <ViewUser
                    dataRecord={dataRecord}
                    onCloseModal={onCloseModal}
                  />
                );
              case "add_user":
                return (
                  <AddUser
                    dataRecord={dataRecord}
                    onCloseModal={onCloseModal}
                    rolesData={rolesData}
                  />
                );
              case "update_user_role":
                return (
                  <UpdateUserRole
                    dataRecord={dataRecord}
                    onCloseModal={onCloseModal}
                  />
                );
              case "update_role_permissions":
                return (
                  <UpdateRolePermissions
                    dataRecord={dataRecord}
                    onCloseModal={onCloseModal}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default UserCreationModal;
