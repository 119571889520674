import { useState } from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomInputField from "../../../../../../components/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSearchDropDown from "../../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import useAllScoringRuleTypes from "../../../../../../custom-hooks/useAllScoringRuleTypes";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import CreditScoreResultsAccordion from "./Components/CreditScoreResultsAccordion";
import { postData } from "../../../../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../../../../config/appInsights";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import Alert from "../../../../../../NewComponents/Alert/Alert.component";

import styles from "./CreditScore.module.scss";
import useBankAccountDetails from "../../../../../../custom-hooks/useBankAccountDetails";
import useCoreBankingTenantInfo from "../../../../../../custom-hooks/useCoreBankingTenant";

export const schema = yup.object().shape({
  originalAmountRequested: yup
    .string()
    .trim()
    .required("Please enter Original amount requested"),
  amountToBeRepaid: yup
    .string()
    .trim()
    .required("Please enter Amount to be repaid"),
  requestedDays: yup.string().trim().required("Please enter Requested Days"),
  repaymentPeriodDays: yup
    .string()
    .trim()
    .required("Please enter Repayment Period Days"),
  currency: yup.string().trim().required("Please enter Currency"),
  minTransactionAmount: yup
    .string()
    .trim()
    .required("Please enter Minimum transaction amount"),
});

const CreditScore = ({ properties, loanInfoFromSchedule }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [scoringRules, setScoringRules] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [creditScoreResults, setCreditScoreResults] = useState({});
  const [accountNo, setAccountNo] = useState("");
  const [accountError, setAccountError] = useState("");
  const [tenantId, setTenantId] = useState(properties?.dataRecord.loan_tenant);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const { data } = useAllScoringRuleTypes();
  
  const {
    data: tenants,
    isLoading: isLoadingTenants,
    error: tenantsError,
  } = useCoreBankingTenantInfo();

  const handleRunCreditPolicy = async (values) => {
    setIsRunning(true);
    setErrorMessage("");
    if (scoringRules.length < 1) {
      setIsRunning(false);
      return setErrorMessage("You have not selected any rules");
    }
    try {
      const _scoringRules = scoringRules.map((_rule) => _rule?.label).flat();
      const payload = {
        customer_id: properties?.dataRecord?.borrower_details?.customer_id,
        rules_type: _scoringRules,
        original_amount_requested: Number(values?.originalAmountRequested),
        amount_to_be_repaid: Number(values?.amountToBeRepaid),
        requested_days: Number(values?.requestedDays),
        repayment_period_days: Number(values?.repaymentPeriodDays),
        currency: values?.currency,
        min_transaction_amount: Number(values.minTransactionAmount),
        account_no: accountNo,
        tenant: tenantId,
      };
      const response = await postData(ajaxEndpoints.RUN_CREDIT_POLICY, payload);
      if (response?.data?.status_code === 200) {
        setCreditScoreResults(response?.data?.data);
      } else {
        setErrorMessage(response?.data?.message);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreditScore.jsx",
        },
      });
      setErrorMessage(error?.response?.data?.message);
    }
    setIsRunning(false);
  };

  const getDefaultOptions = () => {
    if (data) {
      let result = data?.data.map((_item) => {
        return {
          label: _item?.name,
          value: _item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const handleRuleChange = (rules) => {
    return setScoringRules(rules);
  };

  const handleGetScore = async (values) => {
    if (accountNo) {
      await handleRunCreditPolicy({ ...values, accountNo });
    } else {
      setAccountError("Please select an account number");
    }
  };

  const { data: bankAccountDetails } = useBankAccountDetails(
    properties?.dataRecord?.borrower_details?.customer_id
  );

  const cleanupBankAccountList = (_bankAccountDetails) => {
    if (_bankAccountDetails && _bankAccountDetails.length > 0) {
      let result = _bankAccountDetails.map((_item) => {
        return {
          label: `${_item?.accountNo}/${_item?.accountName}/${_item?.bankName}/${_item?.currency}`,
          value: _item?.accountNo,
          currency: _item?.currency,
          bankName: _item?.bankName,
          accountName: _item?.accountName,
        };
      });

      return result;
    }

    return [];
  };

  const handleChangeBankAccount = (data) => {
    if (data?.value) {
      setAccountError("");
    }
    setAccountNo(data?.value);
  };

  if (tenantsError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            tenants, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.CreditScoreContainer}>
      {errorMessage && <Alert message={errorMessage} />}

      <form onSubmit={handleSubmit(handleGetScore)}>
        <div className="row">
          <div className="col-sm-6">
            <CustomInputField
              type="text"
              maxLength="50"
              defaultValue={loanInfoFromSchedule?.totalPrincipal}
              reference={register}
              name="originalAmountRequested"
              readOnly
              label="Amount Requested"
              errors={errors.originalAmountRequested}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "2.5rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className="col-sm-6">
            <CustomInputField
              type="text"
              maxLength="50"
              defaultValue={loanInfoFromSchedule?.totalRepayment}
              reference={register}
              name="amountToBeRepaid"
              readOnly
              label="Amount to be Paid"
              errors={errors.amountToBeRepaid}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "2.5rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <CustomInputField
              type="text"
              maxLength="50"
              reference={register}
              name="requestedDays"
              readOnly
              defaultValue={properties?.dataRecord?.loan_tenure}
              label="Tenure"
              errors={errors.requestedDays}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "2.5rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className="col-sm-6">
            <CustomInputField
              type="text"
              maxLength="50"
              reference={register}
              name="repaymentPeriodDays"
              readOnly
              defaultValue={
                properties?.dataRecord?.product_detail?.repayment_interval
              }
              label="Repayment Interval"
              errors={errors.repaymentPeriodDays}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "2.5rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <CustomInputField
              type="text"
              reference={register}
              name="currency"
              readOnly
              defaultValue={
                properties?.dataRecord?.aggregator_details?.currency
              }
              label="Currency"
              errors={errors.currency}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "2.5rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>

          <div className="col-sm-6">
            <CustomInputField
              type="text"
              maxLength="50"
              reference={register}
              name="minTransactionAmount"
              label="Minimum Transaction Amount"
              errors={errors.minTransactionAmount}
              labelStyle={{ color: "#8B8699" }}
              customInputStyle={{
                height: "2.5rem",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
          </div>

          <div className="col-sm-6" style={{ position: "relative" }}>
            <CustomSearchDropDown
              label="Select Account Number"
              defaultOptions={cleanupBankAccountList(bankAccountDetails)}
              onChange={handleChangeBankAccount}
              isShowLabel
              name="accountNo"
              inputHeight={40}
              isSearchable={false}
              labelStyle={{ color: "#8B8699" }}
            />

            {accountError && (
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  position: "absolute",
                  top: "70px",
                }}
              >
                {accountError}
              </span>
            )}
          </div>

          <div className="col-sm-6">
            <label
              htmlFor="tenant"
              style={{ fontSize: "90%", color: "#8b8699", lineHeight: "12px", marginBottom: "" }}
            >
              Select Tenant:
            </label>
            <br />
            <select
              onChange={(e) => {
                setTenantId(e.target.value);
              }}
              id="tenant"
              className={styles.tenantFieldStyles}
              style={{ width: "100%" }}
              defaultValue="default"
              placeholder={isLoadingTenants ? "Loading tenants" : ""}
            >
              <option value=""></option>
              {tenants &&
                tenants.data?.map((item) => {
                  const { full_name, tenant_id } = item;
                  return (
                    <option
                      selected={tenant_id === tenantId}
                      value={tenant_id}
                      key={tenant_id}
                    >
                      {full_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className={accountError ? styles.rules : ""}>
          <CustomSearchDropDown
            label="Select Rules"
            onChange={handleRuleChange}
            defaultOptions={getDefaultOptions()}
            iconType={null}
            name="roleId"
            isSearchable={false}
            isShowLabel
            isMulti
            inputHeight={45}
            labelStyle={{ color: "#8B8699" }}
          />
        </div>

        <button className="btn advancly-btn btn-md mb-3">
          Get Credit Decision
          {isRunning && <ButtonLoader />}
        </button>
      </form>

      {creditScoreResults?.credit_decision_status && (
        <div className={styles.CreditDecision}>
          Credit Decision:{" "}
          <span
            className={
              creditScoreResults?.credit_decision_status === "Accepted"
                ? styles.Accepted
                : styles.Rejected
            }
          >
            {creditScoreResults?.credit_decision_status}
          </span>
        </div>
      )}

      <div className={styles.CreditResultContainer}>
        {creditScoreResults?.bank_statement_decision && (
          <CreditScoreResultsAccordion
            title="Bank Statement"
            data={creditScoreResults?.bank_statement_decision}
          />
        )}

        {creditScoreResults?.loan_history_decision && (
          <CreditScoreResultsAccordion
            title="Loan History"
            data={creditScoreResults?.loan_history_decision}
          />
        )}
        {creditScoreResults?.orders_decision && (
          <CreditScoreResultsAccordion
            title="Orders"
            data={creditScoreResults?.orders_decision}
          />
        )}
        {creditScoreResults?.stocks_decision && (
          <CreditScoreResultsAccordion
            title="Stock Information"
            data={creditScoreResults?.stocks_decision}
          />
        )}
      </div>
    </div>
  );
};

export default CreditScore;
