import React, { useState } from "react";
import styles from "./UploadedDocument.module.scss";
import { ReactComponent as Download } from "../../../../assets/svg/icons/document-download-icon.svg";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { getData } from "./../../../../newApis/apiMethods";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import { appInsights } from "../../../../config/appInsights";

export const DownloadUploadedDocument = ({
  name,
  pathname,
  id,
  setDocumentIdToBeProcessed,
  setShowSingleDeclineComment,
  setShowSingleApproveComment,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [loadingApproval, setLoadingApproval] = useState(false);

  const prepareDownloadLink = (content, fileName) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (fileName) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_INVESTOR_DOCUMENT}?file_path=${fileName}`
      );
      prepareDownloadLink(data.base64_string, fileName);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DownloadUploadedDocument.jsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <span>{name}</span>
      </div>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <Loader /> : <Download />}
      </div>
      <div>
        <button
          type="button"
          className="advancly-red-decline-btn btn btn-sm"
          onClick={(e) => {
            setDocumentIdToBeProcessed(id);
            setLoadingDecline(true);
            setShowSingleDeclineComment(true);
          }}
          disabled={loadingDecline}
        >
          {loadingDecline ? <Loader /> : "Decline"}
        </button>

        <button
          type="button"
          className=" advancly-red-approve-btn btn btn-sm"
          onClick={(e) => {
            setDocumentIdToBeProcessed(id);
            setLoadingApproval(true);
            setShowSingleApproveComment(true);
          }}
          disabled={loadingApproval}
        >
          {loadingApproval ? <Loader /> : "Approve"}
        </button>
      </div>
    </div>
  );
};
