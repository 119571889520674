import React, { Fragment } from "react";
import styles from "./AggregatorOnboardingEligiblityQuestions.module.scss";
import { ReactComponent as Bin } from "../../../assets/svg/icons/bin.svg";
import { ReactComponent as Edit } from "../../../assets/svg/icons/check-line.svg";
import CustomInputField from "./../../../NewComponents/CustomHTMLElements/CustomInputField";

export const SingleMultiEdit = ({
  questionAnswers,
  questionId,
  onQuestionAnswerValueChange,
  onQuestionAnswerWeightChange,
  onQuestionAnswerAdd,
  onQuestionAnswerDelete,
  onQuestionAnswerUpdate,
  editing,
  deleting,
  onAddNewQuestionAnswer,
}) => {
  return (
    <Fragment>
      {questionAnswers &&
        questionAnswers.sort((a, b) => a.id - b.id) &&
        questionAnswers.map((questionAnswer, _id) => (
          <div
            className={`row ${styles.answerWeighting}`}
            key={questionAnswer.id}
          >
            <div className="col-7">
              <CustomInputField
                type="text"
                label={`Answer ${_id + 1}`}
                placeholder="Text Answer"
                value={questionAnswer.value}
                defaultValue={questionAnswer.value}
                onChange={(e) =>
                  onQuestionAnswerValueChange(e, questionId, questionAnswer.id)
                }
              />
            </div>
            <div className="col-3">
              <CustomInputField
                type="text"
                label="Weighting"
                placeholder="Weighting"
                value={questionAnswer.weight}
                defaultValue={questionAnswer.weight}
                onChange={(e) =>
                  onQuestionAnswerWeightChange(e, questionId, questionAnswer.id)
                }
              />
            </div>
            <div
              className={`col-1 cursor-pointer ${
                deleting && "cursor-disabled"
              }`}
              onClick={() => onQuestionAnswerDelete(questionId, questionAnswer)}
            >
              <Bin />
            </div>
            {!questionAnswer.newAnswer ? (
              <div
                className={`col-1 cursor-pointer ${
                  editing && "cursor-disabled"
                }`}
                onClick={() => onQuestionAnswerUpdate(questionAnswer)}
              >
                <Edit />
              </div>
            ) : (
              <div
                className={`col-1 cursor-pointer ${
                  editing && "cursor-disabled"
                }`}
                onClick={() =>
                  onAddNewQuestionAnswer(questionAnswer, questionId)
                }
              >
                Add
              </div>
            )}
          </div>
        ))}
      <p
        className="color-blue text-underline cursor-pointer mt-3"
        onClick={() => onQuestionAnswerAdd(questionId)}
      >
        Add Option
      </p>
    </Fragment>
  );
};
