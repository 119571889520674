import React, { useState } from "react";
// Redux
import { useDispatch } from "react-redux";
// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { getAggregatorDrilldown } from "../../../../redux/actions/aggregators";

const AdvanceSearch = (props) => {
  const [searchParams, setSearchParams] = useState({
    borrower_name: "",
    bvn: "",
    rc_number: "",
    from_date: "",
    to_date: "",
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const removeEmptyParams = (queryParams) => {
    Object.keys(queryParams).forEach(
      (param) => queryParams[param] === "" && delete queryParams[param]
    );

    return queryParams;
  };

  const searchLoan = (e) => {
    let aggregator_id = props.dataRecord;
    e.preventDefault();

    props.onCloseModal();
    dispatch(
      getAggregatorDrilldown(aggregator_id, removeEmptyParams(searchParams))
    );
  };

  const resetFields = () => {
    setSearchParams({
      borrower_name: "",
      bvn: "",
      rc_number: "",
      from_date: "",
      to_date: "",
    });
  };

  return (
    <div>
      <React.Fragment>
        <h3 className="Modal__title p-b-1">Search By:</h3>
        <div id="disbursement-confirm">
          <div className="m-b-30">
            <form>
              <div className="row">
                <div className="col-md-4 col-sm-12 mt-2">
                  <label htmlFor="table-fetch">Borrower Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={searchParams?.borrower_name}
                    name="borrower_name"
                  />
                </div>

                <div className="col-md-4 col-sm-12 mt-2">
                  <label htmlFor="table-fetch">BVN:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={searchParams?.bvn}
                    name="bvn"
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <label htmlFor="table-fetch">Rc Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={searchParams?.rc_number}
                    name="rc_number"
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <label htmlFor="table-fetch">Created Start Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={handleChange}
                    value={searchParams?.from_date}
                    name="from_date"
                    placeholder="YYYY/MM/DD"
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-2">
                  <label htmlFor="table-fetch">Created End Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={handleChange}
                    min={searchParams?.from_date}
                    value={searchParams?.to_date}
                    name="to_date"
                    placeholder="YYYY/MM/DD"
                  />
                </div>
              </div>
              <div className="Form__action-buttons mt-4 d-flex justify-content-between">
                <button
                  type="submit"
                  onClick={(e) => searchLoan(e)}
                  className="btn zoom-element btn login-btn"
                >
                  <i className="fa fa-check-circle" /> Filter
                </button>

                <button
                  type="button"
                  onClick={resetFields}
                  className="btn zoom-element btn login-btn"
                >
                  <i className="fa fa-refresh" /> Reset
                </button>

                <button
                  type="button"
                  onClick={props.onCloseModal}
                  className="btn zoom-element btn login-btn"
                >
                  <i className="fa fa-times-circle" /> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* 2. Disbursement Loader*/}
        <div
          id="disbursement-loader"
          className="hidden loading-overlay speed-1x fadeInRight"
        >
          <div className=" form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="loading" id="adv-loader-alt" />
              <div className="center-txt webapp-ripple-text">
                <i className="notifier fas fa-user-cog" />
                Searching ...
              </div>
            </div>
          </div>
        </div>

        {/* 3. Disbursement Success Message*/}
        <div
          id="disbursement-success"
          className="hidden loading-overlay speed-1x fadeInRight"
        >
          <div className="form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="loading success-loader">
                <i className="webapp-ripple-notification success-text fa fa-check-circle" />
              </div>
              <div className="center-txt webapp-ripple-text">
                <i className="notifier far fa-check-circle" />
                Search Successful
              </div>
            </div>
          </div>
        </div>

        {/* 4. Disbursement Error */}
        <div
          id="disbursement-error"
          className="hidden loading-overlay speed-1x fadeInRight"
        >
          <ErrorLoader
            icon="fas fa-wifi"
            text="Network Issue"
            subText="Unable to connect to Advancly services."
          />
        </div>
      </React.Fragment>
    </div>
  );
};

export default AdvanceSearch;
