import React, { useState } from "react";

import ajaxMethods from "../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { Loader } from "../../../UI/Loaders/Loaders";
import { filterDataByColumns } from "../../../helpers/tableControllers";
import { CreditBureauTable } from "../CreditBureauTable/CreditBureauTable";
import { FirstCentralBusinessData } from "../CreditBureauTable/FirstCentral/FirstCentralBusinessData";
import { appInsights } from "../../../config/appInsights";

export const FirstCentralCorporate = (props) => {
  const [rcNumber, SetRcNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [newTableData, setNewTableData] = useState({});
  const [tableData, setTableData] = useState({});
  const getCreditReport = (e) => {
    e.preventDefault();
    props.clearToast();
    if (!rcNumber || !rcNumber.length) {
      return props.onSetToast("error", "Please enter your RcNumber");
    }
    setLoading(true);
    ajaxMethods
      .get(ajaxEndpoints.GET_FIRST_CENTRAL_CORPORATE + "?RcNumber=" + rcNumber)
      .then((response) => {
        setLoading(false);
        if (response) {
          setTableData(response.businessData);
          if (response.creditAgreementSummary) {
            props.onSetToast("success", "Request successful");
            sortTableData(response.creditAgreementSummary);
          }
        } else {
          props.onSetToast("error", "Unable to connect to advancly services.");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "FirstCentralCorporate.js",
          },
        });
        setLoading(false);
        props.onSetToast("error", "Unable to connect to advancly services.");
      });
  };

  const sortTableData = (creditAgreementSummary) => {
    let rows = [];
    creditAgreementSummary.map((data, index) => {
      let row_data = {
        id: index + 1,
        subscriberName: data.subscriberName,
        dateAccountOpened: data.dateAccountOpened,
        indicatorDescription: data.indicatorDescription,
        openingBalanceAmt: data.openingBalanceAmt,
        currency: data.currency,
        currentBalanceAmt: data.currentBalanceAmt,
        amountOverdue: data.amountOverdue,
        lastUpdatedDate: data.lastUpdatedDate,
        closedDate: data.closedDate,
        performanceStatus: data.performanceStatus,
        accountStatus: data.accountStatus,
        instalmentAmount: data.instalmentAmount,
      };
      rows.push(row_data);
      return rows;
    });

    let tableHeader = {
      columns: [
        { field: "id", label: "id", sort: "asc", width: 370 },
        {
          field: "subscriberName",
          label: "INSTITUTION",
          sort: "asc",
          width: 670,
        },
        {
          field: "dateAccountOpened",
          label: "ACCOUNT OPENED DATE",
          sort: "asc",
          width: 700,
        },
        {
          field: "indicatorDescription",
          label: "DESCRIPTION",
          sort: "asc",
          width: 700,
        },
        {
          field: "instalmentAmount",
          label: "INSTALLMENT Amount",
          sort: "asc",
          width: 700,
        },
        {
          field: "openingBalanceAmt",
          label: "Opening Account AMOUNT",
          sort: "asc",
          width: 500,
        },
        {
          field: "currency",
          label: "CURRENCY",
          sort: "asc",
          width: 600,
        },
        {
          field: "currentBalanceAmt",
          label: "CURRENT ACCOUNT BALANCE",
          sort: "asc",
          width: 600,
        },

        {
          field: "amountOverdue",
          label: "OVERDUE AMOUNT",
          sort: "asc",
          width: 670,
        },

        {
          field: "closedDate",
          label: "CLOSED DATE",
          sort: "asc",
          width: 670,
        },
        {
          field: "lastUpdatedDate",
          label: "LAST UPDATED DATE",
          sort: "asc",
          width: 670,
        },
        {
          field: "performanceStatus",
          label: "PERFORMANCE STATUS",
          sort: "asc",
          width: 670,
        },
        {
          field: "accountStatus",
          label: "ACCOUNT STATUS",
          sort: "asc",
          width: 670,
        },
      ],
      rows: [],
    };

    const columnsToExclude = [];
    let filteredTableData = filterDataByColumns(
      tableHeader,
      "exclude",
      columnsToExclude
    );
    filteredTableData = {
      columns: [...tableHeader.columns],
      rows: [...rows],
    };
    setNewTableData(filteredTableData);
  };

  if (loading) return <Loader text="Loading..." centered={true} />;

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0">
              <i className="adv-icon fas fa-search" />
              &nbsp; First Central Corporate
            </h3>
            <p className="settings-block text-muted">
              View the Credit History for a Corporate Borrower
            </p>
            <form onSubmit={getCreditReport}>
              <label className="label-txt">RC Number</label>
              <div className="form-group inputWithIcon">
                <input
                  className="user__form form-control"
                  autoComplete="off"
                  name="treshold_amount"
                  value={rcNumber}
                  required
                  onChange={(e) => SetRcNumber(e.target.value)}
                />
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn login-btn"
                  data-form="investment"
                  disabled={loading}
                >
                  {/* {loading &&
                                    <span className="spinner-border spinner-border-sm ml-3"></span>
                                } */}
                  <i className="fas fa-share" />
                  &nbsp; Send
                </button>
              </div>
            </form>
          </div>
        </div>

        <FirstCentralBusinessData tableData={tableData} />
      </div>

      <CreditBureauTable newTableData={newTableData} loading={loading} />
    </div>
  );
};
