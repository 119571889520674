import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type WaitListParams = {
  Page: string;
  PageSize: string;
};

const getGlobalWaitlist = async (queryParams: WaitListParams) => {
  const response = await getData(
    `${ajaxEndpoints.GLOBAL_WAITLIST}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );
  return response?.data;
};

export default function useGlobalWaitlist({ Page, PageSize }: WaitListParams) {
  return useQuery([{ Page, PageSize }, "globalWaitlist"], getGlobalWaitlist, {
    refetchOnWindowFocus: false,
  });
}
