// Redux Action CONSTANTS
import * as actionTypes  from '../actions/actionTypes';

// Initial Application-wide Borrower State
const initialState = {
    partnersTableData: {
        columns: [],
        rows: []
    },
    partners: [],
    count: 0,
    next_page: '',
    prev_page: '',
    loading: false,
    error: null
};

export const reducer = (state = initialState, action)=>{
    const { type } = action

    switch(type){
        case actionTypes.PARTNERS_AJAX__START:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.ALL_PARTNERS_SUCCESS:
            const { 
                partnersTableData,
                partners,
                count,
                next_page,
                prev_page
            } = action.payload;
                
            return {
                ...state,
                loading: false,
                error: null,
                partnersTableData: { 
                    ...partnersTableData
                },
                partners,
                count,
                next_page,
                prev_page
            }

        case actionTypes.GET_PARTNERS_FAILURE:
            return {
                loading: false,
                error: action.error
        }

        default:
            return state;
    }
}

export default reducer;