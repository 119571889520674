import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { postData } from "../../../newApis/apiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { yupValidators } from "../../../helpers/yupValidators";
import VerificationLoader from "../RecoveryComponents/VerificationLoader.component";
import { appInsights } from "../../../config/appInsights";

const schema = yup.object().shape({
  password: yupValidators.password,
  confirmPassword: yupValidators.password,
});

export default function RecoveryStepThree({
  state_token,
  setMultiStepMenu,
  email,
  otp,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const savedHeaders = JSON.parse(localStorage.getItem("savedHeaders")) || {};

  const onSubmit = async (data) => {
    const { password, confirmPassword } = data;
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
    setLoading(true);
    setError(null);
    const reqBody = {
      otp,
      password1: password,
      password2: confirmPassword,
      email,
      reset_step: "third_step",
      state_token,
    };

    try {
      await postData(ajaxEndpoints.RECOVERY, reqBody, savedHeaders);
      setMultiStepMenu("stepFour");
      setLoading(false);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "RecoveryStepThree.component.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  if (loading) {
    return (
      <VerificationLoader
        text="Please wait while we reset your password"
        iconType="fas fa-shield-alt"
      />
    );
  }
  return (
    <div>
      <div className="tfx-step grid__full">
        <div className="tfx-user-form-wrapper box-effect full-border white-bg">
          <div className="tfx-user-form-inner">
            <div id="adv-verify-email">
              <div className="grid__full ">
                <div className="adv-form">
                  <form name="form" onSubmit={handleSubmit(onSubmit)}>
                    {error && (
                      <p className="alert alert-danger mb-4 small">{error}</p>
                    )}

                    <label className="label-txt center-txt">Password</label>
                    <CustomInputField
                      type="password"
                      maxLength="256"
                      reference={register}
                      name="password"
                      placeholder="Enter New Password"
                      label=""
                      errors={errors.password}
                      iconType="fas fa-key"
                    />
                    <label className="label-txt center-txt">
                      Confirm Password
                    </label>
                    <CustomInputField
                      type="password"
                      maxLength="256"
                      reference={register}
                      name="confirmPassword"
                      placeholder="Enter New Password Again"
                      label=""
                      errors={errors.confirmPassword}
                      iconType="fas fa-key"
                    />
                    <div className="grid__full mt-20">
                      <div className="grid__full">
                        <button
                          type="submit"
                          className="zoom-element btn login-btn"
                        >
                          <i className="fas fa-envelope" />
                          &nbsp; Reset Password
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
