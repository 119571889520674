import { useState } from "react";

import CustomCheckBox from "../CustomCheckBox";
import CustomDropDownCard from "../../../../../components/CustomDropDownCard/CustomDropDownCard";
import addIcon from "../../../../../assets/svg/icons/add.svg";
import minusIcon from "../../../../../assets/svg/icons/minus.svg";

import styles from "./CustomAccordion.module.scss";

const CustomAccordion = ({
  title,
  roles,
  preselectedPermissions,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const getCheckedAndUnchecked = (_roles) => {
    let result = [];

    if (_roles && _roles.length > 0) {
      _roles.forEach((_item) => {
        const isAlreadySelected = preselectedPermissions.some(
          (_permissionId) => _permissionId === _item?.name
        );

        if (isAlreadySelected) {
          result.push({ ..._item, isSelected: isAlreadySelected });
        } else {
          result.push({ ..._item, isSelected: false });
        }
      });

      return result;
    }

    return [];
  };

  const getSelectedCount = () => {
    const result = getCheckedAndUnchecked(roles);
    const isSelectedCount = result.filter(
      (_item) => _item?.isSelected === true
    );

    return isSelectedCount.length;
  };

  return (
    <div className={styles.CustomAccordionContainer}>
      <div className={styles.Trigger}>
        {title}
        <span className={styles.Count}>{getSelectedCount()}</span>
        <img
          src={isOpen ? minusIcon : addIcon}
          width={24}
          height={24}
          style={{ marginLeft: "0.75rem", cursor: "pointer" }}
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </div>

      {isOpen && (
        <CustomDropDownCard>
          {getCheckedAndUnchecked(roles).map((_item, index) => (
            <CustomCheckBox
              isSelected={_item?.isSelected}
              key={index}
              id={_item?.id}
              name={_item?.name}
              onChange={(e) => onChange(e, _item?.name)}
              isCheckBackground
            />
          ))}
        </CustomDropDownCard>
      )}
    </div>
  );
};

export default CustomAccordion;
