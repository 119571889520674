import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoint from "../api/ajax-endpoints";

type currencyType = {
  currentCurrencyValue: string;
  aggregatorId: number;
};
const getLoanCount = async ({
  currentCurrencyValue,
  aggregatorId,
}: currencyType) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoint.LOAN_COUNT}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}`,
  );
  return data.data;
};

export default function useLoanCount({
  currentCurrencyValue,
  aggregatorId,
}: currencyType) {
  return useQuery(
    [{ currentCurrencyValue, aggregatorId }, "getLoanCount"],
    getLoanCount,
    {
      enabled: currentCurrencyValue && aggregatorId ? true : false,
      retry: 4,
    },
  );
}
