import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  pageNumber: number;
  size: number;
  consumerType?: string;
  customerId?: string;
};

const getAllCustomerWallet = async ({
  pageNumber,
  size,
  consumerType,
  customerId,
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (customerId) {
    queryParams = `${queryParams}&TargetId=${customerId}`;
  }
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_ALL_WALLET}?${queryParams}`
  );

  return data;
};

export const useAllCustomerWallet = ({
  pageNumber,
  size,
  consumerType,
  customerId,
}: TQuery) => {
  return useQuery(
    [{ pageNumber, size, consumerType, customerId }, "getAllCustomerWallet"],
    getAllCustomerWallet
  );
};
