import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getChangeSignatorySettings = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.PENDING_SIGNATORIES);
  return data.data;
};

export default function useChangeSignatorySettings() {
  return useQuery(["getChangeSignatorySettings"], getChangeSignatorySettings);
}
