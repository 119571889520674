import React, { Fragment, useState } from "react";

const EditBatchRepayment = ({ properties, onCloseModal }) => {
  const { handleEditRepayment, request } = properties;
  const [repayAmount, setRepayAmount] = useState(
    request?.repaymentAmount || ""
  );
  const [repaymentDate, setRepaymentDate] = useState(
    request?.repaymentDate.substring(0, 10) || ""
  );
  const handleEdit = (e) => {
    e.preventDefault();
    const loans = {
      batchLoanRepaymentId: request?.id,
      repaymentAmount: Number(repayAmount),
      repaymentDate: repaymentDate,
    };
    handleEditRepayment(loans);
  };

  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          <h3 className="Modal__title p-b-1">Edit Repayment</h3>
          <hr />
          {/* 1. Confirm Disbursement*/}
          <div id="disbursement-confirm">
            <div className="m-b-6 m-r-6">
              <form>
                <label style={{ width: "100%" }}>
                  Batch Loan Repayment
                  <input
                    className="form-control"
                    name="batchLoanRepaymentId"
                    value={request?.batchNumber || ""}
                    disabled
                  />
                </label>
                <label style={{ width: "100%" }}>
                  Repayment Amount
                  <input
                    type="number"
                    className="form-control"
                    name="repaymentAmount"
                    value={repayAmount}
                    onChange={(e) => setRepayAmount(e.target.value)}
                  />
                </label>
                <label style={{ width: "100%" }}>
                  Repayment Date
                  <input
                    type="date"
                    className="form-control"
                    name="repaymentAmount"
                    value={repaymentDate}
                    onChange={(e) => setRepaymentDate(e.target.value)}
                  />
                </label>
                <div className="Form__action-buttons m-t-2">
                  <button
                    type="submit"
                    onClick={(e) => handleEdit(e)}
                    className="btn zoom-element btn login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Edit
                  </button>
                  <button
                    type="button"
                    onClick={onCloseModal}
                    className="btn zoom-element btn login-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};
export default EditBatchRepayment;
