import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  transaction_ref: string;
};

type TResponse = {
  data: {
    data: {
      data: {
        approvalComment: string;
        createdAt: string;
        email: string;
      }[];
    };
  };
};

const getWalletTransactionLog = async ({ transaction_ref }: TQuery) => {
  let queryParams = `?transaction_reference=${transaction_ref}`;

  const data = await getData(
    `${ajaxEndpoints.WALLET_TRANSACTION_LOG}${queryParams} `
  );

  return data;
};

export default function useWalletTransactionLog({ transaction_ref }: TQuery) {
  return useQuery<TResponse>(
    [
      {
        transaction_ref,
      },
      "getWalletTransactionLog",
    ],
    getWalletTransactionLog,
    {
      enabled: transaction_ref ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
