import {Fragment, useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import useFunderDetails from "../../../../custom-hooks/useFunderDetails";
import {appInsights} from "../../../../config/appInsights";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import InvestmentProspectGeneralTab from "../InvestmentProspect/component/InvestmentProspectGeneralTab";
import InvestmentProspectCommentsTab from "../InvestmentProspect/component/InvestmentProspectCommentsTab";
import InvestmentProspectMessageTab from "../InvestmentProspect/component/InvestmentProspectMessageTab";
import ReferralSignUp from "./components/ReferralSignUp";
import ReferralsProspectiveInvestment from "./components/ReferralsProspectiveInvestment";
import ReferralRealInvestment from "./components/ReferralsRealInvestment";

export default function ReferralTabs() {
    const [activeTab, setActiveTab] = useState("sign-up");

    const match = useRouteMatch();
    const { id } = match?.params as { id: string };

    const {
        data: investorData,
        status,
        error,
    } = useFunderDetails({
        pageNumber: 1,
        size: 20,
        FunderId: Number(id),
    });

    useEffect(() => {
        appInsights.trackPageView({
            name: "ReferralTabs.tsx",
            isLoggedIn: true,
        });
    }, []);

    return (
        <Fragment>

            <div className="alignToggleTabItems mb-3">
                <ToggleTab
                    text="Referral Sign Up"
                    id="sign-up"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <ToggleTab
                    text="Prospective Investment"
                    id="prospective-investment"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <ToggleTab
                    text="Real Investment"
                    id="real-investment"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>

            <div>
                {(() => {
                    switch (activeTab) {
                        case "sign-up":
                            return (
                                <ReferralSignUp />
                            );

                        case "prospective-investment":
                            return <ReferralsProspectiveInvestment  />;

                        case "real-investment":
                            return <ReferralRealInvestment />;

                        default:
                            return null;
                    }
                })()}
            </div>
        </Fragment>
    );
}
