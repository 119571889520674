import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { setToast } from "./../../../../redux/actions/toast";
import moment from "moment";

import { getAllLoans } from "../../../../redux/actions/loans";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
// Helper Functions
import { errorHandler } from "../../../../helpers/errorHandler";
// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../config/appInsights";

class RepayLoans extends Component {
  state = {
    error: false,
    payMode: "",
    payReference: "",
    payDate: "",
    repayAmount: "",
  };

  handleOptionSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      error: false,
    });
  };

  repayLoan = async (e) => {
    e.preventDefault();
    const { payMode, payReference, payDate, repayAmount } = this.state;
    const { personal_phone, loan_ref } = this.props.dataRecord;
    if (payMode && payReference && payDate && repayAmount) {
      const params = {
        borrower_phone: personal_phone,
        repayment_amount: Number(repayAmount),
        loan_reference: loan_ref,
        pay_mode: payMode,
        pay_reference: payReference,
        repayment_date: payDate,
      };
      document.querySelector("#disbursement-confirm").classList.add("hidden");
      document.querySelector("#disbursement-loader").classList.remove("hidden");
      try {
        const result = await postDataWithDotNet(
          ajaxEndpoints.REPAYMENTS_LOAN,
          params
        );

        document.querySelector("#disbursement-loader").classList.add("hidden");
        document
          .querySelector("#disbursement-success")
          .classList.remove("hidden");
        setTimeout(() => {
          this.props.setToast("success", result.message);
          // this.props.getAllLoans()
          this.props.onCloseModal();
        }, 3000);
      } catch (error) {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepayLoan.js",
          },
        });
        document.querySelector("#disbursement-loader").classList.add("hidden");
        document
          .querySelector("#disbursement-error")
          .classList.remove("hidden");
        setTimeout(() => {
          this.props.setToast("warning", errorHandler(error));
          // this.props.getAllLoans()
          return this.props.onCloseModal();
        }, 3000);
      }
    }
  };

  render() {
    const {
      first_name,
      last_name,
      personal_phone,
      loan_id,
      loan_amount_repay,
      loan_ref,
    } = this.props.dataRecord;

    const { payMode, error, payReference, payDate, repayAmount } = this.state;

    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <React.Fragment>
            <h3 className="Modal__title p-b-1">Repayment Summary</h3>
            {/* 2. Confirm Update Role Modal*/}
            <div id="disbursement-confirm">
              <div className="m-b-30">
                <h5>Are you sure you want to repay the following loan? </h5>
                <p>Borrower Name: {`${first_name} ${last_name}`}</p>
                <p>Loan Repay Amount: {loan_amount_repay}</p>
                <form>
                  {error && (
                    <p className="text-danger">Field cannot be blank</p>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <label style={{ width: "100%" }}>
                        Phone Number
                        <input
                          name="personal_phone"
                          placeholder="Enter your phone number"
                          className="form-control"
                          readOnly
                          value={personal_phone}
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label style={{ width: "100%" }}>
                        Loan Reference
                        <input
                          className="form-control"
                          name="loan_ref"
                          value={loan_ref}
                          readOnly
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label style={{ width: "100%" }}>
                        Repayment Amount
                        <input
                          className="form-control"
                          name="repayAmount"
                          value={repayAmount}
                          onChange={(e) => this.handleOptionSelect(e)}
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label style={{ width: "100%" }}>
                        Payment Mode
                        <input
                          name="payMode"
                          className="form-control"
                          onChange={(e) => this.handleOptionSelect(e)}
                          value={payMode}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label style={{ width: "100%" }}>
                        Payment Reference
                        <input
                          name="payReference"
                          className="form-control"
                          onChange={(e) => this.handleOptionSelect(e)}
                          value={payReference}
                        />
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label style={{ width: "100%" }}>
                        Repayment Date
                        <input
                          type="date"
                          name="payDate"
                          className="form-control"
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => this.handleOptionSelect(e)}
                          value={payDate}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="Form__action-buttons m-t-2">
                    <button
                      type="submit"
                      data-id={loan_id}
                      onClick={(e) => this.repayLoan(e)}
                      disabled={
                        !(payMode && payReference && payDate && repayAmount)
                      }
                      className="btn zoom-element btn login-btn m-0 mr-auto"
                    >
                      <i className="fa fa-check-circle" /> Proceed
                    </button>
                    <button
                      type="button"
                      onClick={this.props.onCloseModal}
                      className="btn zoom-element btn login-btn m-0 ml-auto"
                    >
                      <i className="fa fa-times-circle" /> Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* 2. Disbursement Loader*/}
            <div
              id="disbursement-loader"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <div className=" form-loader form-loader-login verify">
                <div className="centerLoader">
                  <div className="loading" id="adv-loader-alt" />
                  <div className="center-txt webapp-ripple-text">
                    <i className="notifier fas fa-user-cog" />
                    Processing Repayment ...
                  </div>
                </div>
              </div>
            </div>

            {/* 3. Disbursement Success Message*/}
            <div
              id="disbursement-success"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <div className="form-loader form-loader-login verify">
                <div className="centerLoader">
                  <div className="loading success-loader">
                    <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                  </div>
                  <div className="center-txt webapp-ripple-text">
                    <i className="notifier far fa-check-circle" />
                    Repayment Successful
                  </div>
                </div>
              </div>
            </div>

            {/* 4. Disbursement Error */}
            <div
              id="disbursement-error"
              className="hidden loading-overlay speed-1x fadeInRight"
            >
              <ErrorLoader
                icon="fas fa-wifi"
                text="Network Issue"
                subText="Unable to connect to Advancly services."
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

export default connect(mapStateToProps, { setToast, getAllLoans })(RepayLoans);
