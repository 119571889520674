import { getData } from "../newApis/dotNetApiMethods";
import * as apiEndPoints from "../api/ajax-endpoints"
import { useQuery } from "react-query";

const fetchCards = async (customer_id: number) => {
    const response = await getData(`${apiEndPoints.GET_LINKED_CARDS}?customer_id=${customer_id}`)

    return response.data
}

export default function useGetLinkedCards(customer_id: number) {
    return useQuery([customer_id, "fetchCards"], fetchCards, {
        refetchOnWindowFocus: false
    })
}