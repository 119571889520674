import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TRepaymentSchedule = {
  repaymentSchedule: {
    currency: {
      code: string;
      name: string;
      decimalPlaces: number;
      inMultiplesOf: number;
      nameCode: string;
      displayLabel: string;
    };
    loanTermInDays: number;
    totalPrincipalDisbursed: number;
    totalPrincipalExpected: number;
    totalPrincipalPaid: number;
    totalInterestCharged: number;
    totalFeeChargesCharged: number;
    totalPenaltyChargesCharged: number;
    totalWaived: number;
    totalWrittenOff: number;
    totalRepaymentExpected: number;
    totalRepayment: number;
    totalPaidInAdvance: number;
    totalPaidLate: number;
    totalOutstanding: number;
    totalOverdueInterest: number;
    periods: {
      dueDate: number[];
      principalDisbursed: number;
      principalLoanBalanceOutstanding: number;
      feeChargesDue: number;
      feeChargesPaid: number;
      totalOriginalDueForPeriod: number;
      totalDueForPeriod: number;
      totalPaidForPeriod: number;
      totalActualCostOfLoanForPeriod: number;
      period: string;
      fromDate: string;
      complete: string;
      daysInPeriod: string;
      principalOriginalDue: number;
      principalDue: number;
      principalPaid: number;
      principalWrittenOff: number;
      principalOutstanding: number;
      interestOriginalDue: number;
      interestDue: number;
      interestPaid: number;
      interestWaived: number;
      interestWrittenOff: number;
      interestOutstanding: number;
      feeChargesWaived: number;
      feeChargesWrittenOff: number;
      feeChargesOutstanding: number;
      penaltyChargesDue: number;
      penaltyChargesPaid: number;
      penaltyChargesWaived: number;
      penaltyChargesWrittenOff: number;
      penaltyChargesOutstanding: number;
      overdueInterest: number;
      totalPaidInAdvanceForPeriod: number;
      totalPaidLateForPeriod: number;
      totalWaivedForPeriod: number;
      totalWrittenOffForPeriod: number;
      totalOutstandingForPeriod: number;
      totalOverdue: number;
      totalInstallmentAmountForPeriod: number;
    }[];
  };
};

type TQuery = {
  loanRef: string;
};

const getFundingRequestRepaymentSchedule = async ({ loanRef }: TQuery) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_FUNDING_REQUEST_REPAYMENT_SCHEDULE}?loanRef=${loanRef}`
  );

  return data;
};

export default function useFundingRequestRepaymentSchedule({
  loanRef,
}: TQuery) {
  return useQuery<IResponse<TRepaymentSchedule>>(
    [{ loanRef }, "getFundingRequestRepaymentSchedule"],
    getFundingRequestRepaymentSchedule,
    {
      refetchOnWindowFocus: false,
    }
  );
}
