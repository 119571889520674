import React from "react";
import ApproveWithdrawalRequests from "./AllRequests/ApproveWithdrawalRequests";
import OfflineRequest from "./AllRequests/OfflineRequest";
import FailedInvestmentModal from "./AllRequests/FailedInvestmentModal";
import FailedWithdrawalModal from "./AllRequests/FailedWithdrawalModal";
import ManualWithdrawalApprovalModal from "./AllRequests/ManualWithdrawalApprovalModal";
import DocumentRequests from "./AllRequests/DocumentRequests";
import InvestmentTopUpRequestModal from "./AllRequests/InvestmentTopUpRequestModal";
import ApproveAdminWithdrawalRequestsModal from "./AllRequests/ApproveAdminWithdrawalRequestsModal";
import ApproveAdminWalletWithdrawalModal from "./AllRequests/ApproveAdminWalletWithdrawalModal";
import ApproveAdminWalletTopUpmodal from "./AllRequests/ApproveAdminWalletTopUpmodal";
// Select Action
const Request = ({ properties, onCloseModal }) => {
  const { action } = properties;
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      {(() => {
        switch (action) {
          case "approvewithdrawalrequest":
            return (
              <ApproveWithdrawalRequests
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "offlinerequest":
            return (
              <OfflineRequest
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "failed_investment":
            return (
              <FailedInvestmentModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "failed_withdrawal":
            return (
              <FailedWithdrawalModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "approve_withdrawal":
            return (
              <ManualWithdrawalApprovalModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "document_request":
            return (
              <DocumentRequests
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "top_up_request":
            return (
              <InvestmentTopUpRequestModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "admin-withdrawal-request":
            return (
              <ApproveAdminWithdrawalRequestsModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "admin-wallet-withdrawal":
            return (
              <ApproveAdminWalletWithdrawalModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "admin-wallet-topup":
            return (
              <ApproveAdminWalletTopUpmodal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Request;
