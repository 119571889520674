import { FC } from "react";
import { errorHandler } from "../../../../../helpers/errorHandler";
import Alert from "../../../../../NewComponents/TypedComponents/Alert/Alert.component";
import TableDataLoading from "../../../../../NewComponents/TableDataLoading/TableDataLoading.component";
import { resolveDuration } from "../../../../../helpers/resolveDuration";
import { formatMoney } from "../../../../../helpers/formatter";
import NoTableDataAvailable from "../../../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";

interface InterestRate {
  duration: number;
  period_type: string;
  rate: number;
}

interface InterestRange {
  interest_rates: InterestRate[];
  upper_amount: number;
  lower_amount: number;
}

interface TermSheetDetails {
  data?: {
    data?: {
      interest_ranges?: InterestRange[];
      currency_code?: string;
    };
  };
  isLoading: boolean;
  error?: any;
}

interface IInvestmentTermSheet {
  termSheetDetails: TermSheetDetails;
}

export const InvestmentTermSheet: FC<IInvestmentTermSheet> = ({
  termSheetDetails,
}) => {
  const { data, isLoading, error } = termSheetDetails;

  if (error) {
    return <Alert message={errorHandler(error)} />;
  }

  if (isLoading) {
    return <TableDataLoading />;
  }

  const interestRanges = data?.data?.interest_ranges;

  if (!interestRanges?.length) {
    return <NoTableDataAvailable text="There is no data available" />;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="bg-lighter-grey">
          <tr>
            <th>Range</th>
            {interestRanges[0].interest_rates.map((interest_rate, index) => (
              <th key={index}>
                {resolveDuration(
                  interest_rate.duration,
                  interest_rate.period_type
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {interestRanges.map((interest_range, i) => (
            <tr key={i}>
              <td>
                {formatMoney(
                  interest_range.lower_amount,
                  data?.data?.currency_code
                )}{" "}
                -
                {formatMoney(
                  interest_range.upper_amount,
                  data?.data?.currency_code
                )}
              </td>
              {interest_range.interest_rates.map((interest_rate, index) => (
                <td key={index}>{interest_rate.rate}%</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
