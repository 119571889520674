import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { formatMoney } from "../../../../helpers/formatter";

const CustomerOrderItemsModal = ({ orderItems }: any) => {
  return (
    <div
      className="modal fade "
      id="customerOrderItems"
      data-backdrop="static"
      data-keyboard="false"
      // @ts-ignore
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Items Ordered{" "}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <MDBTable responsive minimal="true" bordered striped hover>
              <MDBTableHead>
                <tr>
                  <th>S/N</th>
                  <th>Product Id</th>
                  <th>Product Category</th>
                  <th>Product Rating</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                </tr>
              </MDBTableHead>

              <MDBTableBody>
                {orderItems ? (
                  orderItems?.map((items: any, _id: any) => {
                    const {
                      productId,
                      productCategory,
                      productRating,
                      unitPrice,
                      quantity,
                      amount,
                    } = items;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{productId}</td>
                        <td>{productCategory}</td>
                        <td>{productRating}</td>
                        <td>{quantity}</td>
                        <td>{formatMoney(unitPrice)}</td>
                        <td>{formatMoney(amount)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <div>
                    <p>No Data Available</p>
                  </div>
                )}
              </MDBTableBody>
            </MDBTable>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn advancly-grey-btn"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderItemsModal;
