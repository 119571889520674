import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TQuery = {
  aggregatorId: string;
};

const getRecentBorrowers = async ({ aggregatorId }: TQuery) => {
  const data = await getData(
    `${ajaxEndpoints.GET_RECENT_BORROWERS}?aggregatorId=${aggregatorId}`
  );
  return data.data;
};

export default function useRecentBorrowers({ aggregatorId }: TQuery) {
  return useQuery(
    [{ aggregatorId }, "getRecentBorrowers"],
    getRecentBorrowers,
    {
      refetchOnWindowFocus: false,
      enabled: aggregatorId ? true : false,
    }
  );
}
