import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";
import { useQuery } from "react-query";
interface IcurrentAllChecks {
  customerId: number;
  currentDateGenerated: string;
}
const getCreditBureauAllChecks = async ({
  customerId,
  currentDateGenerated,
}: IcurrentAllChecks) => {
  const { data } = await getData(
    `${ajaxEndpoints.CREDIT_BUREAU_ALL_CHECKS}?customerId=${customerId}&dateGenerated=${currentDateGenerated}`
  );
  return data;
};

export default function useCreditBureauAllChecks({
  customerId,
  currentDateGenerated,
}: IcurrentAllChecks) {
  return useQuery(
    [{ customerId, currentDateGenerated }, "getCreditBureauAllChecks"],
    getCreditBureauAllChecks,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );
}
