import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export const getBankStatementProviderCountries = async () => {
  const { data } = await getData(
    `${ajaxEndpoints.CURRENT_BANK_STATEMENT_PROVIDER_COUNTRY}`
  );
  return data?.data;
};

export default function useBankStatementProviderCountries() {
  return useQuery(
    ["getBankStatementProviderCountries"],
    getBankStatementProviderCountries,
    {
      refetchOnWindowFocus: false,
    }
  );
}
