import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getScoringRuleTypes = async () => {
  const response = await getData(ajaxEndpoints.GET_SCORING_RULE_TYPES);

  return response?.data;
};

export default function useAllScoringRuleTypes() {
  return useQuery("scoringRuleTypes", getScoringRuleTypes, {
    refetchOnWindowFocus: false,
  });
}
