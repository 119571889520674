import { useHistory } from "react-router-dom";
import { ReactComponent as BackButtonIcon } from "../../assets/svg/icons/back-button.svg";

import styles from "./BackButton.module.scss";

interface IBackButton {
  customGoBack?: any;
}

const BackButton = ({ customGoBack }: IBackButton) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (customGoBack) {
      customGoBack();
    } else {
      history.goBack();
    }
  };

  return (
    <span className={styles.backButton} onClick={handleGoBack}>
      <BackButtonIcon />
    </span>
  );
};
export default BackButton;
