import { Fragment, useRef, useState } from "react";
// @ts-ignore
import readXlsxFile from "read-excel-file";
import { errorHandler } from "../../../helpers/errorHandler";
import styles from "../BatchLoanRepayment.module.scss";
import FileUploadIcon from "../../../assets/svg/icons/file-upload-fill.svg";
import SampleExcelSheet from "../../../files/sample_batch_repayment_upload_sheet.xlsx";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import moment from "moment";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../../redux/actions/modal";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { Loader } from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";

type FileData = {
  rowStatus: (string | null)[];
  repaymentDate?: string | null;
};

const BatchLoanRepaymentUpload = (props: any) => {
  const [RepaymentToCreate, setRepaymentToCreate] = useState<FileData[]>();
  const [fileToUpload, setFileToUpload] = useState<any>(null);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadButton, setUploadButton] = useState(false);

  const scrollToTop = () => {
    const _topOfPage = document.getElementById("topOfPage");
    _topOfPage?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const schema = {
    "Repayment Amount": {
      prop: "repaymentAmount",
      type: Number,
      required: true,
    },

    "Reference Number": {
      prop: "loanRef",
      type: String,
    },
    "Repayment Date": {
      prop: "repaymentDate",
      type: String,
      required: true,
    },
  };

  const fileInput = useRef<HTMLInputElement>(null);
  const dismissPreviewModal = useRef();
  const dialogHandler = ({ action, data }: any) => {
    props.showModal({
      action,
      dataRecord: data,
      modalIdentifier: "batchloanrepayment",
      RepaymentToCreate: RepaymentToCreate,
      resetAllFields,
      success,
      fileUploadError,
      loading,
      submitRepayment,
      dismissPreviewModal,
    });
  };
  const validateFileToUpload = (file: any) => {
    setError("");
    if (file && file.length) {
      if (file[0].name.endsWith(".xls") || file[0].name.endsWith(".xlsx")) {
        setFileToUpload(file);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
        scrollToTop();
      }
    }
  };

  const reviewFileToBeUploaded = async () => {
    setUploadButton(true);
    // CHeck if a file has been selected
    if (!fileToUpload) {
      setError("Please choose a file");
      setFileToUpload(null);
      return scrollToTop();
    }

    // Upload only one item. This is in a case where the aggregator uploaded more than one file
    const file = fileToUpload[0];

    // Read the uploaded file and do some data manipulations on them
    await readXlsxFile(file, { schema }).then(({ rows, errors }: any) => {
      setUploadSuccess(false);
      // Slice the length of the array to maximum of 500 amd initializa each row with an error object
      let fileData: {
        rowStatus: (string | null)[];
        repaymentDate?: string | null;
      }[] = rows.slice(0, 500).map((row :  any) => ({
        ...row,
        rowStatus: [],
      }));

      // Check if there is an error in the excel sheet that was uploaded, update each row of data with the error information
      if (errors && errors.length) {
        fileData = fileData.map((row, _id) => {
          const id = _id + 1;

          // Check if each row has en error by checking the error array returned by the "read-excel-file" Library
          const errorInfo = errors.find((errorRow : any) => errorRow.row === id);
          return {
            ...row,
            rowStatus: [
              ...row.rowStatus,

              // If there is an error in the row, update the errorInfo object in each row with the error information
              errorInfo ? `${errorInfo.column}: ${errorInfo.error}` : null,
            ],
          };
        });
      }

      fileData = fileData
        // Format Date Objects to DD/MM/YY format
        .map((row) => {
          return {
            ...row,
            repaymentDate: row.repaymentDate
              ? moment(row.repaymentDate, "DD/MM/YYYY").format()
              : null,
          };
        })

        // Check if repayment date is valid date objects.
        // If not valid, update the errorInfo object in each row with the error information
        .map((row) => {
          const rowError =
            row &&
            row?.repaymentDate &&
            row.repaymentDate.toLowerCase().startsWith("invalid")
              ? "Invalid Repayment Date. Expected a DD-MM-YYYY format"
              : null;

          return {
            ...row,
            rowStatus: [...row.rowStatus, rowError ? rowError : null],
          };
        });

      fileData = fileData

        // Check the errorInfo object of each row to filter out every null value in the array
        .map((file) => {
          return {
            ...file,
            rowStatus:
              file.rowStatus && file.rowStatus.length
                ? file.rowStatus.filter((status) => status !== null)
                : file.rowStatus,
          };
        });

      setRepaymentToCreate(fileData);
      setUploadSuccess(true);
    });
  };

  const resetAllFields = () => {
    setFileToUpload(null);
    setRepaymentToCreate([
      {
        rowStatus: [],
      },
    ]);

    const batchUploadInput = document.getElementById(
      "batch-upload-input"
    ) as HTMLInputElement;
    batchUploadInput.value = "";

    setFileUploadError(null);
    setSuccess("");
    setUploadSuccess(false);
    setUploadButton(false);
  };

  const submitRepayment = async () => {
    props.hideModal();
    setLoading(true);
    setError("");
    try {
      const data = await postDataWithDotNet(
        ajaxEndpoints.ADD_BATCH_LOAN_REPAYMENT,
        RepaymentToCreate
      );
      if (data.error && data.error.length) {
        setFileUploadError(data.error);
      }
      setLoading(false);
      setTimeout(() => {
        props.showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          success: data?.statusMessages
            ? "The uploaded file has gone for approval maker to initialiaze."
            : "",
          fileUploadError: data.error ? data.error : "",
        });
        setTimeout(() => {
          props.hideModal();
          resetAllFields();
        }, 9000);
      });
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "BatchLoanRepaymentUpload.jsx",
        },
      });
      if (error.response && error.response.data.error) {
        setFileUploadError(error.response.data.error);
        props.showModal({
          action: "previewloan",
          modalIdentifier: "batchloanrepayment",
          RepaymentToCreate: RepaymentToCreate,
          resetAllFields,
          success,
          fileUploadError: error.response.data.error,
          loading,
          submitRepayment,
          dismissPreviewModal,
        });
        setTimeout(() => {
          props.hideModal();
          resetAllFields();
        }, 9000);
      } else {
        setError(errorHandler(error));
      }
      setLoading(false);
    }
  };

  return (
    <div id="topOfPage">
      {error && (
        <h5 className="text-danger font-weight-bold text-center ">{error}</h5>
      )}
      {loading && <Loader centered={true} text="Sending Uploaded Data..." />}
      {uploadSuccess && (
        <Alert
          message={
            "Uploaded. Click on preview upload to preview Data uploaded and send data for repayment"
          }
          type="success"
        />
      )}
      <input
        type="file"
        className="d-none"
        ref={fileInput}
        id="batch-upload-input"
        accept=".xlsx, .xls" // Accept Excel files only
        onChange={(e) => {
          validateFileToUpload(e.target.files);
        }}
      />

      <div className="">
        <Fragment>
          <div className="upload-box-section">
            <div
              className="upload-box-icon-border"
              onDrop={(e) => {
                e.preventDefault();
                validateFileToUpload(e.dataTransfer.files);
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <img src={FileUploadIcon} alt="Upload File" />
              {!fileToUpload ? (
                <Fragment>
                  <div className="color-blue font-weight-bold">
                    Upload Excel File
                  </div>
                  <div>
                    Drag & drop your file here or{" "}
                    <span
                      className="color-light-blue"
                      onClick={() => fileInput.current?.click()}
                    >
                      browse
                    </span>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="color-blue font-weight-bold">
                    {fileToUpload[0].name}
                  </div>
                  <div>
                    {(parseFloat(fileToUpload[0].size) / 1024).toFixed(2)}kb
                  </div>
                  <div className="color-light-blue" onClick={resetAllFields}>
                    <u>Remove</u>
                  </div>
                </Fragment>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              Don&apos;t have the recommended template? <br />
              <a
                className={styles.downloadButton}
                href={SampleExcelSheet}
                download="Sample Batch Loan Repayment Upload Sheet.xlsx"
                title="Download Bulk Upload Template"
              >
                Download here
              </a>
            </div>
            <div className="flex-row">
              <div className="mr-4">
                <button
                  onClick={reviewFileToBeUploaded}
                  className={`btn btn-sm ${
                    fileToUpload
                      ? "advancly-btn"
                      : uploadButton
                      ? "advancly-grey-btn"
                      : "advancly-grey-btn"
                  }`}
                  disabled={!fileToUpload || uploadButton}
                >
                  Upload
                </button>
              </div>
              <div>
                <button
                  onClick={() =>
                    dialogHandler({
                      action: "previewloan",
                      data: RepaymentToCreate,
                    })
                  }
                  className={`btn btn-sm ${
                    uploadButton ? "advancly-btn" : "advancly-grey-btn"
                  }`}
                  disabled={!uploadButton}
                >
                  Review Upload
                </button>
              </div>
            </div>
          </div>

          <hr />
        </Fragment>
      </div>
    </div>
  );
};

export default connect(null, { showModal, hideModal })(
  BatchLoanRepaymentUpload
);
