import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = () => {
    const d = new Date();
    return d.getFullYear();
  };

  return (
    <div className=" grid__full animated fadeInUp">
      <div className="subfooter">
        <ul className="list-unstyled list-inline relax-left  normal-2x fadeInUp">
          <li>
            <Link to="/">© Copyright {currentYear()}. Advancly</Link>
          </li>
        </ul>
        <ul className="list-unstyled list-inline relax-right  for-pc normal-2x fadeInUp">
          <li>
            <Link to="/terms-of-use">Terms</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
