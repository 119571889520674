import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Settings Components
import AdminSettings from "./components/Admin/AdminSettings";
import { appInsights } from "../../config/appInsights";
import { useEffect } from "react";
import { RootState } from "../../redux/store";

const Settings = () => {
  const { isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }

    appInsights.trackPageView({
      name: "Settings - (Settings.js)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="Settings datatable-wrapper">
      <div className="table-view">
        <div className="block-header">
          <h3 className="page-title mb-4">Settings</h3>

          <AdminSettings />
        </div>
      </div>
    </div>
  );
};

export default Settings;
