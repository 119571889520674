import React from "react";

export default function VerificationLoader({ text, iconType }) {
  return (
    <div id="adv-resetCode" className="tfx-step grid__full">
      <div className="tfx-user-form-wrapper box-effect full-border white-bg">
        <div className="tfx-user-form-inner">
          <div
            id="adv-email-verification-loader"
            className="loading-overlay speed-1x fadeInRight"
          >
            <div className="form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />

                <div className="center-txt webapp-ripple-text">
                  <i className={`notifier ${iconType}`} />
                  &nbsp; {text}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
