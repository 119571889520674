import { useEffect } from "react";
import { enforceMaxLength } from "../../../helpers/enforceMaxLength";
import ErrorHandler from "../../ErrorHandler/ErrorHandler.component";

interface ICustomPhoneInput {
  type: string;
  name: string;
  placeholder?: string;
  reference?: any;
  label?: string;
  errors?: any;
  maxLength?: number;
  minLength?: number;
  max?: string | number;
  defaultValue?: string | number | readonly string[];
  enforceMaxLength: (e: React.KeyboardEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  isShowBadge?: boolean;
  customInputStyle?: object;
  labelStyle?: object;
}
const CustomPhoneInput = ({
  type,
  name,
  placeholder,
  reference,
  label,
  errors,
  maxLength,
  max,
  defaultValue,
  minLength,
  onChange,
  readOnly,
  isShowBadge = false,
  customInputStyle,
  labelStyle,
}: ICustomPhoneInput) => {
  useEffect(() => {
    const input = document.querySelector("#custom-phone-input");

    (window as any)?.intlTelInput(input, {
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
      separateDialCode: true,
      initialCountry: "ng",
    });
  }, []);
  return (
    <div className="mb-5">
      {!isShowBadge && (
        <label className="label-txt" style={{ ...labelStyle }}>
          {label}
        </label>
      )}

      <div className="form-group">
        <input
          id="custom-phone-input"
          className="user__form form-control"
          type={type}
          name={name}
          max={max}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue}
          onChange={onChange}
          readOnly={readOnly}
          style={{ ...customInputStyle }}
        />
      </div>
      <div className="text-left mb-2 ml-3" style={{ marginTop: "-10px" }}>
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
};

export default CustomPhoneInput;
