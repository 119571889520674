import React, { Fragment, useState } from "react";

const BatchRepayLoan = ({ properties, onCloseModal }) => {
  const { loans, totalAmount, handleApproveRepayment, handleDeclineRepayment } =
    properties;
  const [comment, setComment] = useState("");
  const handleApprove = (e) => {
    e.preventDefault();
    handleApproveRepayment(loans, comment);
  };
  const handleDecline = (e) => {
    e.preventDefault();
    handleDeclineRepayment(loans, comment);
  };
  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          <h3 className="Modal__title p-b-1">Loan Repayment Summary</h3>
          <hr />
          {/* 1. Confirm Disbursement*/}
          <div id="disbursement-confirm">
            <div className="m-b-6 m-r-6">
              <p>
                You have selected {loans.length} loans for approval with Id{" "}
                {Object.keys(loans)
                  .map(function (loan) {
                    return loans[loan];
                  })
                  .join(", ")}{" "}
              </p>
              <p>Total Amount is {totalAmount} .</p>
              <p>Are you sure you want to repay the following loans?</p>
              <form>
                <label style={{ width: "100%" }}>
                  Comment
                  <input
                    className="form-control"
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </label>
                <div className="Form__action-buttons m-t-2">
                  <button
                    type="submit"
                    onClick={(e) => handleApprove(e)}
                    disabled={!comment}
                    className="btn zoom-element login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Approve
                  </button>
                  <button
                    type="button"
                    onClick={(e) => handleDecline(e)}
                    disabled={!comment}
                    className="btn zoom-element advancly-dimred-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Decline
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};
export default BatchRepayLoan;
