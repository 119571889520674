import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getRepaymentChannel = async () => {
  const data = await getData(ajaxEndpoints.GET_REPAYMENT_CHANNEL_LIST);
  return data.data;
};

export default function useAllRepaymentChannel() {
  return useQuery(["getRepaymentChannel"], getRepaymentChannel);
}
