import { Loader } from "../../UI/Loaders/Loaders";
import useDefaultWalletProvider from "../../custom-hooks/useDefaultWalletProvider";

const DefaultWalletProvider = () => {
  const { data, status, error } = useDefaultWalletProvider();

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Default Wallet
            Provider
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading wallet providers..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              wallet providers, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no default
              wallet provider.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>id</th>
                  <th>Provider Type</th>
                  <th>Currency</th>
                  <th>Consumer Type</th>
                  <th>Bank Code</th>
                </tr>
              </thead>
              <tbody>
                {data?.data &&
                  data?.data?.map((request: any, _id: number) => {
                    const {
                      id,
                      provider_type,
                      currency,
                      consumer_type,
                      bank_code,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{provider_type}</td>
                        <td>{currency || "N/A"}</td>
                        <td>{consumer_type}</td>
                        <td>{bank_code}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="dataTables_wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title">Default Wallet Providers</h3>
          </div>

          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default DefaultWalletProvider;
