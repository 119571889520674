import React from "react";
import moment from "moment";
import styles from "../CreditBureau.module.scss";
const CreditBureauCheckTablesClassificationProdType = ({
  classificationProdType,
}: any) => {
  return (
    <div>
      <h5 className={` ${styles.creditBureauHeading}`}>
        {" "}
        Classification of Active Accounts by Product Type
      </h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Product Type
            </th>
            <th className="th-td-style th-style" scope="col">
              No. of Accounts
            </th>
            <th className="th-td-style th-style" scope="col">
              Accounts Opened Last 6 Months
            </th>
            <th className="th-td-style th-style" scope="col">
              Currency
            </th>

            <th className="th-td-style th-style" scope="col">
              Approved Amount
            </th>
            <th className="th-td-style th-style" scope="col">
              Total Outstanding Balance
            </th>
            <th className="th-td-style th-style" scope="col">
              OverDue Amount
            </th>

            <th className="th-td-style th-style" scope="col">
              Recent Overdue Date
            </th>
          </tr>
        </thead>
        <tbody>
          {classificationProdType?.map((classProdType: any, index: number) => {
            const {
              producT_TYPE,
              amounT_OVERDUE,
              currency,
              sanctioneD_AMOUNT,
              totaL_OUTSTANDING_BALANCE,
              nO_OF_ACCOUNTS,
              nO_ACC_LAST_SIX_MON,
              recenT_OVERDUE_DATE,
            } = classProdType;

            return (
              <tr key={index}>
                <td className="th-td-style ">{producT_TYPE}</td>
                <td className="th-td-style ">{nO_OF_ACCOUNTS}</td>
                <td className="th-td-style ">{nO_ACC_LAST_SIX_MON}</td>
                <td className="th-td-style ">{currency}</td>
                <td className="th-td-style ">{sanctioneD_AMOUNT}</td>
                <td className="th-td-style ">{totaL_OUTSTANDING_BALANCE}</td>

                <td className="th-td-style ">{amounT_OVERDUE}</td>
                <td className="th-td-style ">
                  {recenT_OVERDUE_DATE
                    ? moment(recenT_OVERDUE_DATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauCheckTablesClassificationProdType;
