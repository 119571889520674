import { useSelector } from "react-redux";

import { ROLE_TYPES } from "../../../helpers/roleTypes";
import ManageChangeSignatory from "./ManageChangeSignatory";
import { RootState } from "../../../redux/store";

const ManageChangeSignatoryContainer = () => {
  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );

  return (
    <div>
      {loggedInUser?.roles.includes(ROLE_TYPES.FINANCE) && (
        <ManageChangeSignatory />
      )}
    </div>
  );
};

export default ManageChangeSignatoryContainer;
