import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  consumerType?: string;
  accountNumber?: string;
};

const getAllWalletTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&StartDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&EndDate=${endDate}`;
  }
  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (accountNumber) {
    queryParams = `${queryParams}&AccountNumber=${accountNumber}`;
  }
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_ALL_WALLET_TRANSACTIONS}?${queryParams}`
  );

  return data?.data;
};

export default function useAllWalletTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}: TQuery) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, consumerType, accountNumber },
      "getAllWalletTransactions",
    ],
    getAllWalletTransactions
  );
}
