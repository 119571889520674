import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TFundingPartner = {
  name: string;
  walletProvider: number;
  id: number;
};

const getFundingPartners = async () => {
  const { data } = await getData(`${ajaxEndpoints.GET_FUNDING_PARTNERS}`);

  return data;
};

export default function useFundingPartners() {
  return useQuery<IResponse<TFundingPartner[]>>(
    "getFundingPartners",
    getFundingPartners,
    {
      refetchOnWindowFocus: false,
    }
  );
}
