import { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// User Dashboards
import PowerBiDashboard from "../PowerBiDasboard/PowerBiDashboard";

import { RootState } from "../../redux/store";

// Styles
import "./Dashboard.css";

const Dashboard = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state?.authReducer.isAuthenticated
  );

  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, []);

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="Dashboard">
      <PowerBiDashboard />
    </div>
  );
};

export default Dashboard;
