import { Fragment, useState } from "react";
import { Loader } from "../../../UI/Loaders/Loaders";
import useInvestorsInvestmentWallet from "../../../custom-hooks/useInvestorsInvestmentWallet";
import { useLocation } from "react-router-dom";
import Paginator from "../../Pagination/Pagination.component";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { formatMoney } from "../../../helpers/formatter";
import moment from "moment";

const InvestorsInvestment = () => {
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const location = useLocation();
  const { id, savingsWalletId, currency } = location?.state as {
    id: string;
    savingsWalletId: string;
    currency: string;
  };

  const { data, status, error } = useInvestorsInvestmentWallet({
    FunderId: Number(id),
    page,
    size,
    savingsWalletId: Number(savingsWalletId),
  });
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  let RenderedComponent = null;

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to fetch Investors wallet
            Details
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Investor Wallet..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              audit trails, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Transaction.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Note</th>
                  <th>Type</th>
                  <th>Category</th>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th>Initiator</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((request: any, _id: number) => {
                  const {
                    amount,
                    initiatorUsername,
                    note,
                    runningBalance,
                    transactionDate,
                    transactionId,
                    transactionType,
                    transactioncategory,
                  } = request;
                  return (
                    <tr>
                      <td>{transactionId}</td>
                      <td>{moment(transactionDate).format("ll")}</td>
                      <td>{note}</td>
                      <td>{transactionType}</td>
                      <td>{transactioncategory}</td>
                      <td>{formatMoney(amount, currency)}</td>
                      <td>{formatMoney(runningBalance, currency)}</td>
                      <td>{initiatorUsername}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={page}
            count={count}
            changeCurrentPage={(page: number) => setPage(page)}
          />
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
        </h3>
      </div>
      {RenderedComponent}
    </Fragment>
  );
};

export default InvestorsInvestment;
