import { useEffect } from "react";
import { appInsights } from "../../config/appInsights";
import { POWERBI_STANDARD_LOAN_REPORT_TOKEN } from "../../appConstants";

const PowerBiReport = () => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Standard Loan Report - (PowerBiReport.js)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      <iframe
        style={{ width: "100%", height: "100vh" }}
        title="Standard Loan Report"
        allow="fullscreen"
        src={`https://app.powerbi.com/view?r=${POWERBI_STANDARD_LOAN_REPORT_TOKEN}`}
      ></iframe>
    </div>
  );
};

export default PowerBiReport;
