// Redux Action CONSTANTS
import * as actionTypes from "./../actions/actionTypes";

export const setToast = (type, message) => {
  return {
    type: actionTypes.SET_TOAST,
    payload: {
      type,
      message,
    },
  };
};

export const clearToast = () => {
  return {
    type: actionTypes.CLEAR_TOAST,
  };
};
