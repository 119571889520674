import React, { useEffect } from "react";
import { IWallet, TWalletLink } from "../../../../interfaces/funderInvestment";
import style from "../../../DealSlip/uploadDealSlip.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export interface IProps {
  wallets: IWallet[] | undefined;
  funderId: number;
  showWalletDetails: () => void;
  handleSetSelectedWalletDetails: (
    walletId: string,
    currency: string,
    availableBalance: number
  ) => void;
  setShowWalletWithdrawal: (show: boolean) => void;
  setShowWalletTopUp: (show: boolean) => void;
  localWalletLink?: TWalletLink;
  foreignWalletLink?: TWalletLink;
}

const InvestorWalletTable = ({
  wallets,
  showWalletDetails,
  localWalletLink,
  foreignWalletLink,
  funderId,
  handleSetSelectedWalletDetails,
  setShowWalletWithdrawal,
  setShowWalletTopUp,
}: IProps) => {
  const history = useHistory();
  const user = useSelector((state: any) => state.authReducer?.loggedInUser);
  useEffect(() => {
    console.log({
      user,
    });
  }, [user]);

  const ActionDropDown = ({
    walletId,
    currency,
    clientId,
    availableBalance,
  }: {
    walletId: string;
    currency: string;
    clientId: string;
    availableBalance: number;
  }) => {
    return (
      <div className={`dropdown ${style.dropdownButton}`}>
        <button
          className="btn advancly-btn create zoom-element dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Wallet Actions
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <span
            className="dropdown-item cursor-pointer"
            onClick={() => {
              handleSetSelectedWalletDetails(
                walletId,
                currency,
                availableBalance
              );
              showWalletDetails();
            }}
          >
            Wallet Details
          </span>
          <span
            className="dropdown-item cursor-pointer"
            onClick={() => {
              if (localWalletLink && currency === "NGN") {
                history.push(localWalletLink.pathname as any, {
                  id: funderId,
                  savingsWalletId: walletId,
                  currency: currency,
                });
              } else if (foreignWalletLink && currency !== "NGN") {
                history.push(foreignWalletLink.pathname as any, {
                  id: funderId,
                  savingsWalletId: walletId,
                  currency: currency,
                });
              }
            }}
          >
            Wallet Transactions
          </span>
          <span
            className="dropdown-item cursor-pointer"
            onClick={() => {
              handleSetSelectedWalletDetails(
                walletId,
                currency,
                availableBalance
              );
              setShowWalletWithdrawal(true);
            }}
          >
            Withdraw
          </span>

          <span
            className="dropdown-item cursor-pointer"
            onClick={() => {
              handleSetSelectedWalletDetails(
                walletId,
                currency,
                availableBalance
              );
              setShowWalletTopUp(true);
            }}
          >
            Top-up
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Wallet ID</th>
              <th>Currency</th>
              <th>Available Balance</th>
              <th>Ledger Balance</th>
              <th>Account Number</th>
              <th>IsDefault</th>
              <th>IsExternal</th>
              <th>Client ID</th>
              <th>Wallet Provider Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {wallets &&
              wallets &&
              wallets?.length > 0 &&
              wallets.map(
                ({
                  walletId,
                  currency,
                  availableBalance,
                  ledgerBalance,
                  accountNumber,
                  clientId,
                  walletProviderType,
                  isExternal,
                  isDefault,
                }: IWallet) => (
                  <tr
                    key={walletId}
                    className="pointer"
                    // onClick={() => showWalletDetails(walletId)}
                  >
                    <td>{walletId}</td>
                    <td>{currency}</td>
                    <td>{availableBalance}</td>
                    <td>{ledgerBalance}</td>
                    <td>{accountNumber}</td>
                    <td>{isDefault ? "true" : "false"}</td>
                    <td>{isExternal ? "true" : "false"}</td>
                    <td>{clientId}</td>
                    <td>{walletProviderType}</td>
                    <td>
                      <ActionDropDown
                        walletId={walletId}
                        currency={currency}
                        clientId={clientId}
                        availableBalance={availableBalance}
                      />
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvestorWalletTable;
