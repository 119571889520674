import { Fragment, useEffect, useState } from "react";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import InvestorInfo from "./InvestorInfo";
import InvestorsInvestment from "./InvestorsInvestment";
import { useRouteMatch } from "react-router-dom";
import useFunderDetails from "../../../custom-hooks/useFunderDetails";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";
import InvestorsDocuments from "./InvestorsDocument";
import InvestorWallet from "./InvestorWallet";
import InvestorBankAccounts from "./InvestorBankAccounts";
import ReferralTabs from "./Referrals/ReferralTabs";

export default function InvestorTabs() {
  const [activeTab, setActiveTab] = useState("investor-information");

  const match = useRouteMatch();
  const { id } = match?.params as { id: string };

  const {
    data: investorData,
    status,
    error,
  } = useFunderDetails({
    pageNumber: 1,
    size: 20,
    FunderId: Number(id),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investor Details  - (InvestorTabs.jsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />{" "}
          {status === "loading" ? (
            <ButtonLoader />
          ) : !error && investorData ? (
            investorData?.data[0]?.first_name +
            " " +
            investorData?.data[0]?.last_name
          ) : (
            ""
          )}
        </h3>
      </div>
      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Investor Details"
          id="investor-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Investments"
          id="investment-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Investor KYC Document"
          id="investor-document"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Wallet"
          id="wallet"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Other Bank Accounts"
          id="other-bank-accounts"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
            text="Referrals"
            id="referral-accounts"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
      </div>

      <div>
        {(() => {
          switch (activeTab) {
            case "investor-information":
              return (
                <InvestorInfo
                  investorData={investorData?.data[0]}
                  status={status}
                  error={error}
                />
              );

            case "investment-information":
              return <InvestorsInvestment id={Number(id)} />;

            case "investor-document":
              return <InvestorsDocuments id={Number(id)} />;
            case "wallet":
              return <InvestorWallet id={Number(id)} />;
            case "other-bank-accounts":
              return <InvestorBankAccounts id={Number(id)} />;
            case "referral-accounts":
              return <ReferralTabs />;
            default:
              return null;
          }
        })()}
      </div>
    </Fragment>
  );
}
