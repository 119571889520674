import { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

// Router
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import useManageAggregatorSettingsData from "../../../custom-hooks/useManageAggregatorSettingsData";
import { showModal } from "../../../redux/actions/modal";
import { useQueryCache } from "react-query";
// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../config/appInsights";
import { RootState } from "../../../redux/store";

const ManageAggregatorSettings = () => {
  const [loading, setLoading] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(10);

  const history = useHistory();
  const dispatch = useDispatch();

  const { loggedInUser, isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const queryCache = useQueryCache();
  //  Methods
  const dialogHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "manageaggregatorsettingsprompt",
        dataRecord: modalData,
      })
    );
  };

  const approveProduct = async (reference: string) => {
    setLoading("");
    setError("");
    const reqBody = {
      reference,
      comment: "Okay",
    };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.APPROVE_AGGREGATOR_SETTINGS,
        reqBody
      );
      setLoading("");
      setSuccess(response.message);
      queryCache.invalidateQueries([
        { email: loggedInUser?.email },
        "getAggregatorSettingsToManage",
      ]); // setSuccess("Product has been approved");
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageAggregatorSettings.js",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const declineProduct = async (reference: string) => {
    setLoading("Declining Settings...");
    setError("");
    const reqBody = {
      reference,
      comment: "Okay",
    };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.REJECT_AGGREGATOR_SETTINGS,
        reqBody
      );
      setLoading("");
      setSuccess(response.message);
      queryCache.invalidateQueries([
        { email: loggedInUser?.email },
        "getAggregatorSettingsToManage",
      ]);
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageAggregatorSettings.js",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  //  Life Cycle Hooks
  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error: dataError } = useManageAggregatorSettingsData();

  if (data) {
    if (count === null) {
      setCount(data.length);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Aggregator
            Settings
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading aggregator settings..." />
    );
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else {
    if (dataError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue fetching
              aggregator settings, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {error}
            </h4>
          </div>
        </div>
      );
    } else if (success) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {success}
            </h4>
          </div>
        </div>
      );
    } else if (data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no aggregator
              settings to manage
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Business Phone Number</th>
                <th>Aggregator Name</th>
                <th>Country Code</th>
                <th>Short Number</th>
                <th>Aggregator Domain name</th>
                <th>Allow Bank Account Validation</th>
                <th>Allow Bulk Loan</th>
                <th>Allow BVN Validation</th>
                <th>Allow Widget Edit</th>
                <th>Business Address</th>
                <th>Business Category</th>
                <th>Business Email</th>
                <th>Manage</th>
                <th>Aggregator Type</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((settings: any, _id: number) => {
                  const {
                    first_name,
                    biz_phone,
                    last_name,
                    country_code,
                    biz_name,
                    short_name,
                    aggregator_domain,
                    allow_bank_account_validation,
                    allow_bulk_loan_application_via_ui,
                    allow_bvn_validation,
                    allow_widget_edit,
                    biz_address,
                    biz_category,
                    biz_email,
                    maximum_running_loan,
                    aggregator_type,
                  } = settings;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                      <td>{biz_phone}</td>
                      <td>{biz_name}</td>
                      <td>{country_code}</td>
                      <td>{short_name}</td>
                      <td>{aggregator_domain}</td>
                      <td>
                        {allow_bank_account_validation ? "True" : "False"}
                      </td>
                      <td>
                        {allow_bulk_loan_application_via_ui ? "True" : "False"}
                      </td>
                      <td>{allow_bvn_validation ? "True" : "False"}</td>
                      <td>{allow_widget_edit ? "True" : "False"}</td>
                      <td>{biz_address}</td>
                      <td>{biz_category}</td>
                      <td>{biz_email}</td>
                      <td>{maximum_running_loan}</td>
                      <td>{aggregator_type}</td>
                      <td className="d-flex flex-row">
                        <span
                          className="view btn-manage btn-success zoom-element btn-slim"
                          onClick={() =>
                            dialogHandler({
                              type: "approve",
                              settings,
                              action: approveProduct,
                            })
                          }
                        >
                          Approve
                        </span>
                        <span
                          className="view btn-manage btn-danger zoom-element btn-slim"
                          onClick={() =>
                            dialogHandler({
                              type: "decline",
                              settings,
                              action: declineProduct,
                            })
                          }
                        >
                          Decline
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={count}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Manage aggregator settings Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-lg-9 col-md-6 col-sm-7 dashboard-title flex__row">
              <h3 className="m-l-1">
                <aside className="rounder">2</aside>
                &nbsp; Manage Aggregator Settings
              </h3>
            </div>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
          <label htmlFor="table-fetch" className="form-group">
            Number of results:{" "}
            <select
              onChange={(e) => {
                setSize(Number(e.target.value));
              }}
              id="table-fetch"
              className="table-fetch-select m-l-1"
              value={size}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
        {RenderedComponent}
      </div>
    </div>
  );
};

export default ManageAggregatorSettings;
