import React, { useState } from "react";

import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  getAllLoans,
  getAllRepayments,
  getLoansForDisbursement,
} from "../../../../redux/actions/loans";
import { connect } from "react-redux";
import { getAggregatorDrilldown } from "../../../../redux/actions/aggregators";
import { getAllTransactions } from "../../../../redux/actions/transactions";
import { getAuditTrail } from "../../../../redux/actions/auditTrail";
import { getNotifications } from "../../../../redux/actions/notifications";
import {
  getAllIssues,
  getPendingIssues,
} from "../../../../redux/actions/issues";

const CustomRange = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const onDateChange = (e) => {
    const { name } = e.target;
    switch (name) {
      case "startDate":
        setEndDate("");
        return setStartDate(e.target.value);
      case "endDate":
        return setEndDate(e.target.value);
      default:
        return;
    }
  };

  const handleSubmit = () => {
    setButtonLoading(true);

    props.onCloseModal();

    const query = `searchvalue=custom_range&fromdate=${startDate}&todate=${endDate}`;
    const loanquery = `search_by=customrange&search_value=&from_date=${startDate}&to_date=${endDate}&page=10&page_size=10000000000000000000000000000000000000000000000000000000000000`;

    switch (props.dataRecord.endpointFn) {
      case "getAllLoans":
        return props.getAllLoans(loanquery);
      case "getAllRepayments":
        return props.getAllRepayments(query);
      case "getLoansForDisbursement":
        return props.getLoansForDisbursement(query);
      case "getAllTransactions":
        return props.getAllTransactions(query);
      case "getAggregatorDrilldown":
        return props.getAggregatorDrilldown(
          [...props.dataRecord.endpointFnArguments],
          query
        );
      case "getAuditTrail":
        return props.getAuditTrail(query);
      case "getNotifications":
        return props.getNotifications(query);
      case "getAllIssues":
        return props.getAllIssues(query);
      case "getPendingIssues":
        return props.getPendingIssues(query);
      default:
        return;
    }
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "scroll" }}>
        <h3 className="Modal__title p-b-1">Select Custom Range</h3>
        <label>Start Date</label>
        <input
          className="form-control"
          style={{ marginBottom: "20px" }}
          type="date"
          id="start"
          name="startDate"
          onChange={onDateChange}
          value={startDate}
          // min={moment(new Date()).subtract(2,'d').format('YYYY-MM-DD')}
          max={moment(new Date()).format("YYYY-MM-DD")}
        />
        <label>End Date</label>
        <input
          className="form-control"
          style={{ marginBottom: "20px" }}
          type="date"
          id="end"
          name="endDate"
          onChange={onDateChange}
          value={endDate}
          min={startDate}
          max={moment(new Date()).format("YYYY-MM-DD")}
          disabled={!startDate}
        />
        <button
          type="button"
          className="btn login-btn"
          disabled={buttonLoading || !startDate || !endDate}
          onClick={handleSubmit}
        >
          {/* {loading &&
                                    <span className="spinner-border spinner-border-sm ml-3"></span>
                                } */}
          {/* <i className="fas fa-download" />&nbsp;  */}
          Done
        </button>
      </div>
    </div>
  );
};

export default withRouter(
  connect(null, {
    getAllLoans,
    getAggregatorDrilldown,
    getAllRepayments,
    getLoansForDisbursement,
    getAllTransactions,
    getAuditTrail,
    getNotifications,
    getAllIssues,
    getPendingIssues,
  })(CustomRange)
);
