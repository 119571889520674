// Router
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { authLogout } from "../../redux/actions/auth";
import { useEffect } from "react";
import { RootState } from "../../redux/store";

const Logout = () => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  useEffect(() => {
    dispatch(authLogout());
  }, []);

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return null;
};

export default Logout;
