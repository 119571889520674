import React, { useState } from "react";

import CustomerWallet from "../Loans/CustomerWallet/CustomerWallet";
import AllBorrowerLoansRepayments from "../Loans/Details/AllBorrowerLoansRepayments";
import AllLoansTiedToABorrower from "../Loans/Details/AllLoansTiedToABorrower";
import Tabs from "./../../../components/Tabs/Tabs";
import AdvanceSearch from "./AdvanceSearch/AdvanceSearch";
import "./PartnerModal.css";

const PartnerModal = (props) => {
  const [formScene, setFormScene] = useState("profile");
  const [state, setState] = useState({
    tabs: [
      {
        name: "Profile",
        id: "profile",
        icon: "fas fa-bug",
        identifier: "partnerInfoTab",
      },
      {
        name: "Financials",
        id: "financials",
        icon: "fas fa-hourglass-start",
        identifier: "partnerInfoTab",
      },
      {
        name: "Customer Wallet",
        id: "customer-wallet-details",
        icon: "fas fa-hourglass-start",
        identifier: "partnerInfoTab",
      },
      {
        name: "All Loans",
        id: "all-loans",
        icon: "fas fa-hand-holding",
        identifier: "partnerInfoTab",
      },
      {
        name: "All Repayments",
        id: "all-repayments",
        icon: "fas fa-thumbs-up",
        identifier: "partnerInfoTab",
      },
    ],
  });

  // Toggle Tab & Tab content for Data Table
  const toggleTabContent = (content) => {
    setFormScene(content);
  };

  const printHandler = () => {};

  const { tabs } = state;
  const { dataRecord, action } = props.properties;

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="PartnerModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          {(() => {
            switch (action) {
              case "form":
                return (
                  <form>
                    <h3 className="Modal__title p-b-2">
                      Borrower ID: <span>{dataRecord?.borrower_id}</span>
                    </h3>
                    <div className="row m-b-1">
                      <Tabs onContentToggle={toggleTabContent} tabs={tabs} />
                    </div>
                    {(() => {
                      switch (formScene) {
                        case "profile":
                          return (
                            <React.Fragment>
                              <label className="label-txt">Full Name</label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  className="user__form form-control"
                                  readOnly
                                  value={`${dataRecord?.first_name} ${dataRecord?.last_name}`}
                                />
                                <i className="fas fa-user-circle" />
                              </div>
                              <label className="label-txt">Email</label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  readOnly
                                  className="user__form form-control"
                                  value={dataRecord?.email || "Unavailable"}
                                />
                                <i class="far fa-envelope"></i>
                              </div>
                              <label className="label-txt">Phone</label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  readOnly
                                  className="user__form form-control"
                                  value={
                                    dataRecord?.borrower_phone || "Unavailable"
                                  }
                                />
                                <i class="fas fa-phone"></i>
                              </div>
                              <label className="label-txt">
                                Customer Category
                              </label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  readOnly
                                  className="user__form form-control"
                                  value={
                                    dataRecord?.customer_category ||
                                    "Unavailable"
                                  }
                                />
                                <i class="fas fa-users"></i>
                              </div>
                              <label className="label-txt">Borrower Type</label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  readOnly
                                  className="user__form form-control"
                                  value={
                                    dataRecord?.borrower_type || "Unavailable"
                                  }
                                />
                                <i class="fas fa-phone"></i>
                              </div>
                              <div className="row">
                                <div className="col-xs-6">
                                  <label className="label-txt">
                                    Company Name
                                  </label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      readOnly
                                      className="user__form form-control"
                                      value={dataRecord?.my_biz_name || "N/A"}
                                    />
                                    <i className="far fa-user" />
                                  </div>
                                </div>
                                <div className="col-xs-6">
                                  <label className="label-txt">RC Number</label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      className="user__form form-control"
                                      readOnly
                                      value={dataRecord?.my_biz_rc_num || "N/A"}
                                    />
                                    <i className="fas fa-calendar" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-xs-6">
                                  <label className="label-txt">Gender</label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      readOnly
                                      className="user__form form-control"
                                      value={dataRecord?.gender}
                                    />
                                    <i className="far fa-user" />
                                  </div>
                                </div>
                                <div className="col-xs-6">
                                  <label className="label-txt">
                                    Date of Birth
                                  </label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      className="user__form form-control"
                                      readOnly
                                      value={dataRecord?.date_of_birth}
                                    />
                                    <i className="fas fa-calendar" />
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        case "financials":
                          return (
                            <React.Fragment>
                              <label className="label-txt">BVN</label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  className="user__form form-control"
                                  readOnly
                                  value={dataRecord?.bvn_number}
                                />
                                <i className="fas fa-user-circle" />
                              </div>
                              <div className="row">
                                <div className="col-xs-6">
                                  <label className="label-txt">
                                    Total Loan Collected
                                  </label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      readOnly
                                      className="user__form form-control"
                                      value={dataRecord?.total_loan_collected}
                                    />
                                    <i className="far fa-credit-card" />
                                  </div>
                                </div>
                                <div className="col-xs-6">
                                  <label className="label-txt">
                                    Total Loan Repaid
                                  </label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      className="user__form form-control"
                                      readOnly
                                      value={dataRecord?.total_loan_repaid}
                                    />
                                    <i className="fas fa-coins" />
                                  </div>
                                </div>
                              </div>
                              <label className="label-txt">Email</label>
                              <div className="form-group inputWithIcon">
                                <input
                                  type="text"
                                  readOnly
                                  className="user__form form-control"
                                  value={dataRecord?.bvn_number}
                                />
                                <i class="far fa-envelope"></i>
                              </div>
                              <div className="row">
                                <div className="col-xs-6">
                                  <label className="label-txt">
                                    Modified Date
                                  </label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      readOnly
                                      className="user__form form-control"
                                      value={dataRecord?.modified_date}
                                    />
                                    <i className="far fa-calendar" />
                                  </div>
                                </div>
                                <div className="col-xs-6">
                                  <label className="label-txt">Pub Date</label>
                                  <div className="form-group inputWithIcon">
                                    <input
                                      type="text"
                                      className="user__form form-control"
                                      readOnly
                                      value={dataRecord?.pub_date}
                                    />
                                    <i className="fas fa-calendar-alt" />
                                  </div>
                                </div>
                              </div>

                              <div className="row"></div>
                            </React.Fragment>
                          );
                        case "all-loans":
                          return (
                            <AllLoansTiedToABorrower dataRecord={dataRecord} />
                          );
                        case "customer-wallet-details":
                          return (
                            <CustomerWallet
                              dataRecord={props.properties.dataRecord}
                            />
                          );
                        case "all-repayments":
                          return (
                            <AllBorrowerLoansRepayments
                              dataRecord={dataRecord}
                            />
                          );

                        default:
                          return null;
                      }
                    })()}
                    <div className="Form__action-buttons m-t-1">
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn m-0 mr-auto"
                        onClick={printHandler}
                      >
                        <i class="fas fa-print"></i>
                        &nbsp; Print
                      </button>
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn m-0 ml-auto"
                        onClick={props.onCloseModal}
                        data-dismiss="modal"
                      >
                        <i class="fas fa-times-circle"></i>
                        &nbsp; Go Back
                      </button>
                    </div>
                  </form>
                );

              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />

                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing your Partner
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              case "advanceSearch":
                return (
                  <AdvanceSearch
                    onCloseModal={props.onCloseModal}
                    dataRecord={dataRecord}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default PartnerModal;
