import { useEffect, useState } from "react";

import { formatMoney } from "../../../../../helpers/formatter";
import Paginator from "../../../../Pagination/Pagination.component";
import { TCurrencyName, getCurrencyInfo } from "../../../helper";
import {
  TEachInvestment,
  TInvestmentSummariesCurrency,
} from "../../../../../custom-hooks/useEachInvestments";

import styles from "./investmentsdetails.module.scss";
import ToggleTab from "../../../../../NewComponents/ToggleTab/ToggleTab.component";
import useInvestmentTermSheet from "../../../../../custom-hooks/useInvestmentTermSheet";
import { InvestmentTermSheet } from "./InvestmentTermSheet";

interface IInvestmentFundDetails {
  investmentData: TEachInvestment;
  selectedFundCurrency: string;
  deposit_type: string;
}

const InvestmentFundDetails = ({
  investmentData,
  selectedFundCurrency,
  deposit_type,
}: IInvestmentFundDetails) => {
  const [fundDetails, setFundDetails] =
    useState<TInvestmentSummariesCurrency>();
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  const [activeTab, setActiveTab] = useState(
    investmentData?.investmentSummaries[0]?.currency || ""
  );

  const resolveDataToMap = () => {
    return investmentData?.investmentSummaries.find(
      (summary) => summary.currency === activeTab
    );
  };

  const dataToMap: TInvestmentSummariesCurrency | undefined = resolveDataToMap();

  const termSheetDetails = useInvestmentTermSheet({
    investmentId: dataToMap?.investmentment_product_detail_id || 0,
  });

  const getFundInvestmentSummaryAndDetails = (
    _currency: TCurrencyName | string
  ) => {
    let result = investmentData?.investmentSummaries.find(
      (item) => item?.currency === _currency
    );

    setFundDetails(result);
  };

  useEffect(() => {
    if (selectedFundCurrency) {
      getFundInvestmentSummaryAndDetails(selectedFundCurrency);
    }
  }, [selectedFundCurrency]);

  return (
    <div>
      <div className="datatable-wrapper">
        <div className=" speed-1x animated fadeInRight">
          {/*Datatable || Loader || Error Message*/}
          {investmentData && (
            <div>
              <div className="row mt-4">
                <div className="col-md-4 d-flex flex-column">
                  <div className={styles.investmentTitle}>Amount Raised</div>
                  <div className="mb-3">
                    <span className={styles.investmentSubTitleTitle}>
                      {formatMoney(
                        fundDetails?.amount_raised,
                        getCurrencyInfo(selectedFundCurrency)?.symbol
                      )}
                    </span>
                    <span className={styles.investmentSubTitle}> / </span>
                    <span className={styles.subTitle}>
                      {formatMoney(
                        fundDetails?.investment_size,
                        getCurrencyInfo(selectedFundCurrency)?.symbol
                      )}
                    </span>
                  </div>

                  <div className={styles.investmentTitle}>
                    <span className="text-primary">
                      {fundDetails?.percentage_raised}%
                    </span>{" "}
                    of Investment size raised
                  </div>
                  <div className="d-flex">
                    <p className="font-weight-bold mr-3">Deposit Type:</p>
                    <p>{deposit_type}</p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="alignToggleTabItems mb-3">
                  {investmentData &&
                    investmentData?.investmentSummaries.length > 0 &&
                    investmentData?.investmentSummaries?.map(
                      (item: { currency: string }, i: number) => (
                        <ToggleTab
                          text={`${item.currency} Investment`}
                          id={item.currency}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          key={i}
                        />
                      )
                    )}
                </div>
              </div>
              {/* Term Sheet Table  */}
              <InvestmentTermSheet termSheetDetails={termSheetDetails} />

              <h5 className={`${styles.investmentSubTitle} mt-5`}>
                Investments
              </h5>
              {fundDetails?.investment_summary &&
              fundDetails?.investment_summary?.length > 0 ? (
                <div className="row m-x-sm m-y-2 grid__padding">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="bg-lighter-grey">
                        <tr>
                          <th>Investor</th>
                          <th>Amount Invested</th>
                          <th>Tenor</th>
                          <th>Interest Repayment Type</th>
                          <th>Total Interest At Maturity</th>
                          <th>Total Repayment At Maturity</th>
                          <th>Monthly Yield</th>
                          <th>Reference No</th>
                          <th>Investment Date</th>
                          <th>Maturity Date</th>
                          <th>Amount Due</th>
                          <th>Total Paid</th>
                          <th>Outstanding Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fundDetails?.investment_summary &&
                          fundDetails?.investment_summary
                            .slice(
                              (pageNumber - 1) * size,
                              size * (pageNumber - 1) + size
                            )
                            .map((request: any, _id: number) => {
                              const {
                                amount_invested,
                                tenure,
                                investor,
                                repayment_type,
                                monthly_yield,
                                reference_number,
                                effective_date,
                                maturity_date,
                                total_interest_at_maturity,
                                maturity_amount,
                                total_settled,
                                outstanding_settlement,
                                amount_due,
                              } = request;
                              return (
                                <tr key={_id}>
                                  <td>{investor}</td>
                                  <td>
                                    {formatMoney(
                                      amount_invested,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                  <td>{tenure} days</td>
                                  <td>{repayment_type}</td>
                                  <td>
                                    {formatMoney(
                                      total_interest_at_maturity,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                  <td>
                                    {formatMoney(
                                      maturity_amount,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                  <td>
                                    {formatMoney(
                                      monthly_yield,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                  <td>{reference_number}</td>
                                  <td>{effective_date?.substring(0, 10)}</td>
                                  <td>{maturity_date?.substring(0, 10)}</td>
                                  <td>
                                    {formatMoney(
                                      amount_due,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                  <td>
                                    {formatMoney(
                                      total_settled,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                  <td>
                                    {formatMoney(
                                      outstanding_settlement,
                                      getCurrencyInfo(fundDetails?.currency)
                                        ?.symbol
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                    <Paginator
                      size={size}
                      page={pageNumber}
                      count={
                        fundDetails?.investment_summary
                          ? fundDetails?.investment_summary?.length
                          : 0
                      }
                      changeCurrentPage={(pageNumber: number) =>
                        setPageNumber(pageNumber)
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="centerLoader">
                  <div className="grid__padding animated fadeInRight">
                    <h4 className="center-txt p-y-5">
                      <i className="fas fa-bell" /> There are currently no{" "}
                      {selectedFundCurrency} investment.
                    </h4>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentFundDetails;
