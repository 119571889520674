import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getPendingInvestorTopUpRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.GET_PENDING_INVESTOR_TOP_UPS
  );
  return data;
};

const getPendingAdminTopUpRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.GET_PENDING_ADMIN_TOP_UPS
  );
  return data;
};

export function usePendingInvestorTopUpRequests() {
  return useQuery(
    ["getPendingInvestorTopUpRequests"],
    getPendingInvestorTopUpRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function usePendingAdminTopUpRequests() {
  return useQuery(
    ["getPendingAdminTopUpRequests"],
    getPendingAdminTopUpRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
