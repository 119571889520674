import styles from "./CustomDropDownCard.module.scss";

interface ICustomDropDownCard {
  cardTitle: string;
  children: any;
  isShowTitle?: boolean;
}

const CustomDropDownCard = ({
  cardTitle,
  children,
  isShowTitle = false,
}: ICustomDropDownCard) => {
  return (
    <div className={styles.CardContainer}>
      <div className={styles.Card}>
        {isShowTitle && <p className={styles.Title}>{cardTitle}</p>}
        {children}
      </div>
    </div>
  );
};

export default CustomDropDownCard;
