import moment from "moment";
import { postObjectToLocalStorage, postToLocalStorage } from "./localStorage";
import ajaxApi from "./../api/axios-register";
import { appInsights } from "../config/appInsights";

export const setUserInfo = (res) => {
  try {
    const { sso_auth_token } = res;
    postToLocalStorage("isAuthenticated", true);
    postToLocalStorage("token", sso_auth_token);
    postToLocalStorage("sso_auth_token", sso_auth_token);
    postObjectToLocalStorage("loggedInUser", res);
    ajaxApi.defaults.headers.common["Authorization"] =
      "Token " + sso_auth_token;

    // Set Expiry Date
    // Add certain seconds to the current time
    const currentTime = moment().format();
    const expiryTime = moment(currentTime)
      .add(Number(res.expiresIn), "s")
      .format();
    postToLocalStorage("expiryTime", expiryTime);
  } catch (error) {
    appInsights.trackException({
      exception: error,
      properties: {
        fileName: "setUserInfo.js",
      },
    });
    return "An error occured, please try again.";
  }
};
