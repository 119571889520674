import React, { Component } from "react";

import "./Tabs.css";
class Tabs extends Component {
  // Methods
  toggleTabHandler = (e) => {
    let selectedTab = e.currentTarget.id,
      identifier = document
        .getElementById(selectedTab)
        .getAttribute("data-group"),
      tabs = document.querySelectorAll(String("." + identifier));

    for (let i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }

    document.getElementById(selectedTab).classList.add("active");
    this.props.onContentToggle(selectedTab);
  };

  enableActiveTab = (identifier) => {
    let tabs = document.querySelectorAll(String("." + identifier));
    tabs[0]?.classList.add("active");
  };

  // Life Cycle Hooks
  componentDidMount() {
    const { tabs } = this.props;
    const identifier = tabs[0]?.identifier;
    this.enableActiveTab(identifier);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tabs !== this.props.tabs) {
      const identifier = this.props.tabs[0]?.identifier;
      this.enableActiveTab(identifier);
    }
  }

  render() {
    const { tabs } = this.props;

    return (
      <div className="Tabs animated speed-1x fadeInRight">
        <div className="tabs__wrapper">
          {tabs.map((tab) => {
            let TabSymbol;

            if (tab.icon) {
              TabSymbol = <i className={tab.icon} />;
            } else if (tab.number) {
              TabSymbol = <span className="round">{tab.number}</span>;
            }

            return (
              <span
                className={tab.identifier + " filterItem"}
                data-group={tab.identifier}
                key={tab.id}
                id={tab.id}
                onClick={(e) => this.toggleTabHandler(e)}
              >
                {TabSymbol}
                {tab.name}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
