export const truncateString = (
  stringInQuestion: string,
  num: number
): string => {
  if (stringInQuestion.length <= num) {
    return stringInQuestion;
  }

  return `${stringInQuestion.slice(0, num)}...`;
};

export const getInitial = (stringInQuestion: string) => {
  return stringInQuestion[0].toUpperCase();
};
