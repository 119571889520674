import React from "react";

export default function Stepper({ id, step, text, active, lastStep }) {
  return (
    <div
      id={id}
      className={`form-steps__step form-steps__step--${
        active ? "active" : "inactive"
      } ${lastStep ? "form-steps__step--last" : ""}`}
    >
      <div className="form-steps__step-circle">
        <span className="form-steps__step-number">{step}</span>
        <span className="form-steps__step-check">
          <i className="fas fa-check-circle" />
        </span>
      </div>
      <span className="form-step__step-name">{text}</span>
    </div>
  );
}
