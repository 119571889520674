import React, { useEffect, useState, FunctionComponent } from "react";
import Paginator from "../../Pagination/Pagination.component";
import { usePendingAdminTopUpRequests } from "../../../custom-hooks/usePendingTopUps";
import RequestsErrorMessage from "../components/RequestsErrorMessage";
import RequestsInfo from "../components/RequestsInfo";
import RequestsAlert from "../components/RequestsAlert";
import RequestsSearchAndSizeControls from "../components/RequestsSearchAndSizeControls";
import { Loader } from "../../../UI/Loaders/Loaders";
import TopUpRequestsTopUpDataTable from "../components/TopUpRequestsTopUpDataTable";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../../redux/actions/modal";

const AdminTopUpRequests: FunctionComponent = (props: any) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [size, setSize] = useState<number>(20);
  const [searchValue, setSearchValue] = useState<string>("");
  const {
    data: pendingTopData,
    status,
    error,
  } = usePendingAdminTopUpRequests();

  const renderContent = (): JSX.Element | null => {
    switch (status) {
      case "loading":
        return <Loader centered={true} text="Loading top up requests..." />;
      case "error":
        return (
          <RequestsErrorMessage message="There was an issue while fetching pending top up requests, Please Reload The Page" />
        );
      case "success":
        if (!pendingTopData || pendingTopData.length === 0) {
          return (
            <RequestsErrorMessage message="There are currently no pending top up requests." />
          );
        }
        return (
          <TopUpRequestsTopUpDataTable
            data={pendingTopData}
            showModal={props.showModal}
            hideModal={props.hideModal}
            initiator="admin"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <RequestsAlert />
      {status !== "loading" && (
        <div className="d-flex justify-content-between">
          <RequestsInfo />
          <RequestsSearchAndSizeControls
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            size={size}
            onSizeChange={setSize}
          />
        </div>
      )}
      {renderContent()}
      <Paginator
        size={size}
        page={pageNumber}
        count={pendingTopData ? pendingTopData.length : 0}
        changeCurrentPage={setPageNumber}
      />
    </div>
  );
};

export default connect(null, { showModal, hideModal })(AdminTopUpRequests);
