import React, {
  ChangeEvent,
  Fragment,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import { formatMoney } from "../../../../helpers/formatter";
import { ReactComponent as NairaIcon } from "../../../../assets/svg/icons/naira.svg";
import { ReactComponent as DollarIcon } from "../../../../assets/svg/icons/dollar.svg";
import { ReactComponent as KenyaIcon } from "../../../../assets/svg/icons/KSH.svg";
import Loader from "../../../../NewComponents/Loader/Loader.component";

import useCoreBankingTenantInfo from "../../../../custom-hooks/useCoreBankingTenant";
import useMifosProduct from "../../../../custom-hooks/useMifosProduct";
import { useWalletProviders } from "../../../../custom-hooks/useWallet";
import {TCreateInvestmentProductState, TInvestmentProductDetails} from "../CreateInvestmentProduct";

import styles from "./../../Investment.module.scss";
import { useDepositTypes } from "../../../../custom-hooks/useDepositTypes";
import { convertToBase64 } from "../../../../helpers/convertToBase64";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import { appInsights } from "../../../../config/appInsights";
import { onChangeFile } from "../../../../helpers/convertToFormData";
import { string } from "yup";

interface IFinancials {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  state: TCreateInvestmentProductState;
  setState: React.Dispatch<React.SetStateAction<TCreateInvestmentProductState>>;
  selectedProductDetails: TInvestmentProductDetails;
  updateParentState: (details: TInvestmentProductDetails) => void;
}

export default function Financials({
  handleChange,
  state,
  setState,
  selectedProductDetails,
    updateParentState,
}: IFinancials) {
  const [checkStatus, setCheckStatus] = useState("off");
  const [selectedCoreBankingTenant, setSelectedCoreBankingTenant] =
    useState("");
  const [selectedExternalWalletProvider, setSelectedExternalWalletProvider] =
    useState("");
  const [
    monthlyPrematureLiquidationRate,
    setMonthly_premature_liquidation_rate,
  ] = useState("");
  const [
    endOfTenurePrematureLiquidationRate,
    setEnd_of_tenure_premature_liquidation_rate,
  ] = useState("");
  const [selectedDepositType, setSelectedDepositType] = useState();


  const { data: coreBankingTenant } = useCoreBankingTenantInfo();

  const { data: depositTypes } = useDepositTypes();

  useEffect(() => {
    console.log({ depositTypes });
  }, [depositTypes]);

  const {
    data: walletProvider,
    status: walletProviderStatus,
    error: walletProviderError,
  } = useWalletProviders();

  const {
    data: mifosProduct,
    isError: isMifosProductError,
    error: mifosProductError,
    isLoading: isMifosProductLoding,
  } = useMifosProduct({
    productType: Number(selectedProductDetails?.depositType),
    tenantId: selectedProductDetails?.coreBankingTenantId,
  });

  useEffect(() => {
    console.log({
      selectedDepositType , selectedCoreBankingTenant, selectedProductDetails
    })
  }, [selectedDepositType , selectedCoreBankingTenant, selectedProductDetails]);

  const getAppropriateCurrencyIcon = (currencyInQuestion: any) => {
    switch (currencyInQuestion) {
      case "NGN":
        return {
          icon: <NairaIcon />,
          symbol: "\u20A6",
        };
      case "USD":
        return {
          icon: <DollarIcon />,
          symbol: "\u0024",
        };
      case "KES":
        return {
          icon: <KenyaIcon />,
          symbol: "KES",
        };

      default:
        break;
    }
  };

  const checkInvestmentCap = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (checkStatus === "on") {
      setCheckStatus("off");
    } else {
      setCheckStatus("on");
    }
  };

  const handleChangeTenant = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      setSelectedCoreBankingTenant("");
    } else {
      setSelectedCoreBankingTenant(e.target.value);

      const currenState = { ...state };

      const result = currenState.investmentProductDetails.find(
          (item) => item.value === selectedProductDetails?.value,
      );

      const indexOfResult = currenState?.investmentProductDetails.findIndex(
          (item) => item === result,
      );

      const newProductDetailsArray = currenState?.investmentProductDetails;
      newProductDetailsArray[indexOfResult] = {
        ...newProductDetailsArray[indexOfResult],
        coreBankingTenantId: e.target.value,
      };

      setState((prev) => ({
        ...prev,
        investmentProductDetails: newProductDetailsArray,
      }));
    }
  };

  const handleSearchBy = (e: ChangeEvent<HTMLSelectElement>) => {
    const eachData =
      mifosProduct &&
      mifosProduct.find((data) => data.id === Number(e.target.value));

    if (eachData) {
      const { minimum_investment, maximum_investment, currency_code } =
        eachData;

      const currenState = { ...state };

      const result = currenState.investmentProductDetails.find(
        (item) => item.value === selectedProductDetails?.value,
      );

      const indexOfResult = currenState?.investmentProductDetails.findIndex(
        (item) => item === result,
      );

      const newProductDetailsArray = currenState?.investmentProductDetails;
      newProductDetailsArray[indexOfResult] = {
        ...newProductDetailsArray[indexOfResult],
        currencyCode: currency_code,
        coreBankingProductId: Number(e.target.value),
        // coreBankingTenantId: selectedCoreBankingTenant,
        minimumInvestment: minimum_investment,
        maximumInvestment: maximum_investment,
        endOfTenurePrematureLiquidationRate: Number(
          endOfTenurePrematureLiquidationRate,
        ),
        monthlyPrematureLiquidationRate: Number(
          monthlyPrematureLiquidationRate,
        ),
        // depositType: selectedDepositType,
      };

      setState((prev) => ({
        ...prev,
        investmentProductDetails: newProductDetailsArray,
      }));
    }
  };

/*  useEffect(() => {
    if (state.investmentProductDetails?.length > 0){
      const product = state.investmentProductDetails.find(product => product.currencyCode === selectedProductDetails?.currencyCode);
      console.log({product})
      if (product){
        setSelectedExternalWalletProvider(product?.externalWalletProvider);
        setSelectedCoreBankingTenant(product?.coreBankingTenantId)
      }
    }
  }, [state.investmentProductDetails]);*/

  const handleChangeExternalWalletProvider = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedExternalWalletProvider(e.target.value);

    const currenState = { ...state };

    const result = currenState.investmentProductDetails.find(
      (item) => item.value === selectedProductDetails?.value,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
      (item) => item === result,
    );

    const newProductDetailsArray = currenState?.investmentProductDetails;
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      externalWalletProvider: e.target.value,
    };

    setState((prev) => ({
      ...prev,
      investmentProductDetails: newProductDetailsArray,
    }));
  };
  const handleChangeInvestmentSize = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const currenState = { ...state };

    const result = currenState.investmentProductDetails.find(
      (item) => item.value === selectedProductDetails?.value,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
      (item) => item === result,
    );

    const newProductDetailsArray = currenState?.investmentProductDetails;
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      // investment_size: e.target.value,
    };

    setState((prev) => ({
      ...prev,
      investmentProductDetails: newProductDetailsArray,
    }));
  };

  function handleChangeDepositType(e: any) {
    setSelectedDepositType(e.target?.value);


    const currenState = { ...state };

    const result = currenState.investmentProductDetails.find(
        (item) => item.value === selectedProductDetails?.value,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
        (item) => item === result,
    );

    const newProductDetailsArray = currenState?.investmentProductDetails;
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      depositType: e.target.value,
    };

    setState((prev) => ({
      ...prev,
      investmentProductDetails: newProductDetailsArray,
    }));
  }


  function handleChangeMonthlyLiquidation(e: any) {
    const currenState = { ...state };

    const result = currenState.investmentProductDetails.find(
        (item) => item.value === selectedProductDetails?.value,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
        (item) => item === result,
    );

    const newProductDetailsArray = currenState?.investmentProductDetails;
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      monthlyPrematureLiquidationRate: e.target.value,
    };

    setState((prev) => ({
      ...prev,
      investmentProductDetails: newProductDetailsArray,
    }));
  }




  return (
    <Fragment>
      <div className="page-subtitle col-md-12 mt-3">
        <h3
          className="mb-3"
          style={{
            backgroundColor: "#F4F5F6",
            fontSize: "16px",
            padding: "10px",
          }}
        >
          {selectedProductDetails?.label}
        </h3>
      </div>
      <div className="col-md-12 mb-3">
        <CustomSelectDropdown
          onChange={handleChangeExternalWalletProvider}
          name="external_wallet_provider"
          value={selectedProductDetails?.externalWalletProvider}
          className="round user__form form-control w-100"
          label="Select External Wallet Provider (Investor)"
        >
          <option value="">Select Provider</option>
          {walletProvider &&
            walletProvider.map((item) => {
              const { name, id } = item;
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-12">
        <CustomSelectDropdown
          onChange={handleChangeTenant}
          name="core_banking_tenant_id"
          value={selectedProductDetails?.coreBankingTenantId}
          label="Core Banking Tenant"
          className="round user__form form-control mb-3"
        >
          <option value=""> Select Core Banking Tenant</option>
          {coreBankingTenant &&
            coreBankingTenant?.data.map((data) => {
              const { full_name, id, tenant_id } = data;
              return (
                <option value={tenant_id} key={id}>
                  {full_name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-12">
        <CustomSelectDropdown
          onChange={handleChangeDepositType}
          name="depositType"
          value={selectedProductDetails?.depositType}
          label="Deposit Type"
          className="round user__form form-control mb-3"
        >
          <option value="">Select Deposit Type</option>
          {depositTypes &&
            depositTypes?.map((data) => {
              const { name, id } = data;
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      {selectedProductDetails?.coreBankingTenantId && (
          <div className="col-md-12">
            {isMifosProductLoding ? (
                <Loader />
            ) : (
                <CustomSelectDropdown
                    onChange={(e) => {
                      handleSearchBy(e);
                    }}
                    name="core_banking_product_id"
                    defaultValue={selectedProductDetails?.coreBankingProductId}
                    label="Mifos Product"
                    className="round user__form form-control mb-3"
                >
                  <option value=""> Select a mifos product</option>
                  {selectedProductDetails?.value &&
                      mifosProduct &&
                      mifosProduct
                          ?.filter(
                              (m: any) =>
                                  m.currency_code === selectedProductDetails?.value,
                          )
                          .map((data: any) => {
                            const { id, name, currency_code } = data;
                            return (
                                <option value={id} key={id}>
                                  {currency_code} - {name}
                                </option>
                            );
                          })}
                </CustomSelectDropdown>
            )}
          </div>
      )}
      <div className="col-md-6 mb-3 mt-3">
        <label className="label-txt">Monthly Premature Liquidation Rate</label>
        <input
          type="number"
          placeholder="Enter a rate from 0 - 100"
          className="form-control"
          value={monthlyPrematureLiquidationRate}
          name="monthlyPrematureLiquidationRate"
          onChange={(e) => {
            setMonthly_premature_liquidation_rate(e.target.value)
            updateParentState({
              ...selectedProductDetails,
              monthlyPrematureLiquidationRate:Number( e.target.value),
            });
          }
          }
        />
        {(Number(monthlyPrematureLiquidationRate) < 0 ||
          Number(monthlyPrematureLiquidationRate) > 100) && (
          <p className="text-danger">
            Monthly Premature Liquidation rate must be between 0 and 100
          </p>
        )}
      </div>
      <div className="col-md-6 mb-3 mt-3">
        <label className="label-txt">
          End Of Tenure Premature Liquidation Rate
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter a rate from 0 - 100"
          value={endOfTenurePrematureLiquidationRate}
          name="endOfTenurePrematureLiquidationRate"
          onChange={(e) => {
            setEnd_of_tenure_premature_liquidation_rate(e.target.value)
            updateParentState({
              ...selectedProductDetails,
              endOfTenurePrematureLiquidationRate:Number( e.target.value),
            });
          }
          }
        />
        {(Number(endOfTenurePrematureLiquidationRate) < 0 ||
          Number(endOfTenurePrematureLiquidationRate) > 100) && (
          <p className="text-danger">
            End Of Tenor Premature Liquidation rate must be between 0 and 100
          </p>
        )}
      </div>



      <div className="col-md-6">
        <label>
          Minimum Investment{" "}
          {formatMoney(
            selectedProductDetails?.minimumInvestment,
            getAppropriateCurrencyIcon(selectedProductDetails?.value)?.symbol,
          )}
        </label>
        <div className={styles.inputIcons}>
          <div className={`${styles.icon}`}>
            {getAppropriateCurrencyIcon(selectedProductDetails?.value)?.icon}
          </div>
          <input
            type="number"
            name="minimumInvestment"
            value={selectedProductDetails?.minimumInvestment}
            disabled
            onChange={(e) => handleChange(e)}
            className={`${styles.inputField}`}
          />
        </div>
      </div>
      <div className="col-md-6">
        <label>
          Maximum Investment{" "}
          {formatMoney(
            selectedProductDetails?.maximumInvestment,
            getAppropriateCurrencyIcon(selectedProductDetails?.value)?.symbol,
          )}
        </label>
        <div className={styles.inputIcons}>
          <div className={`${styles.icon}`}>
            {getAppropriateCurrencyIcon(selectedProductDetails?.value)?.icon}
          </div>
          <input
            type="number"
            name="maximumInvestment"
            value={selectedProductDetails?.maximumInvestment}
            disabled
            onChange={(e) => handleChange(e)}
            className={`${styles.inputField}`}
          />
        </div>
      </div>

      <div className="col-md-12 mb-3 mt-3">
        <div className="rounded-border d-flex ">
          <span>Turn on automatic investment cap:</span>
          <div onClick={(e) => checkInvestmentCap(e)}>
            <i
              className={`investment-adv-icon  fas fa-toggle-${checkStatus} fa-lg ml-2`}
            />
          </div>
        </div>
      </div>
      {/*{checkStatus === "on" && (
        <div className="col-md-12 mb-3">
          <label className="label-txt">
            Investment Size{" "}
            {formatMoney(
              selectedProductDetails?.investment_size,
              getAppropriateCurrencyIcon(selectedProductDetails?.value)?.symbol,
            )}
          </label>
          <input
            type="number"
            className="form-control"
            value={selectedProductDetails?.investment_size}
            name="investment_size"
            onChange={(e) => handleChangeInvestmentSize(e)}
          />
        </div>
      )}*/}
    </Fragment>
  );
}
