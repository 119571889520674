import React from "react";

interface ErrorMessageProps {
  message: string;
}

const RequestsErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <div className="centerLoader">
      <div className="grid__padding animated fadeInRight">
        <h4 className="center-txt p-y-5">
          <i className="fas fa-bell" /> {message}
        </h4>
      </div>
    </div>
  );
};

export default RequestsErrorMessage;
