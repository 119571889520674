import { ENCRYPTION_KEY } from "../appConstants";

const key = ENCRYPTION_KEY;
const encryptData = require("simple-encryptor")(key);

export const encrypt = (data) => {
  const encrypted = encryptData.encrypt(data);
  return encrypted;
};
export const decrypt = (data) => {
  const decrypted = encryptData.decrypt(data);
  return decrypted;
};
