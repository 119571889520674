import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  borrowerName?: string;
  loanId?: string;
  bvn?: string;
  rcNumber?: string;
  aggregatorId?: string;
  loanRef?: string;
};

const getLoansPendingApproval = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  borrowerName,
  loanId,
  bvn,
  rcNumber,
  aggregatorId,
  loanRef,
}: TQuery) => {
  let queryParams = `?page=${pageNumber}&pageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&from_date=${startDate}`;
  }
  if (startDate && endDate) {
    queryParams = `${queryParams}&from_date=${startDate}&to_date=${endDate}`;
  }
  if (borrowerName) {
    queryParams = `${queryParams}&borrowername=${borrowerName}`;
  }
  if (bvn) {
    queryParams = `${queryParams}&bvn=${bvn}`;
  }
  if (rcNumber) {
    queryParams = `${queryParams}&rcnumber=${rcNumber}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&aggregatorId=${Number(aggregatorId)}`;
  }
  if (loanId) {
    queryParams = `${queryParams}&loanid=${Number(loanId)}`;
  }
  if (loanRef) {
    queryParams = `${queryParams}&loanreference=${loanRef}`;
  }

  const data = await getDataWithDotNet(
    `${ajaxEndpoints.LOANS_PENDING_APPROVAL}${queryParams}`
  );
  return data;
};

export default function useLoansPendingApproval({
  pageNumber,
  size,
  startDate,
  endDate,
  borrowerName,
  loanId,
  bvn,
  rcNumber,
  aggregatorId,
  loanRef,
}: TQuery) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        startDate,
        endDate,
        borrowerName,
        loanId,
        bvn,
        rcNumber,
        aggregatorId,
        loanRef,
      },
      "getLoansPendingApproval",
    ],
    getLoansPendingApproval,
    {
      refetchOnWindowFocus: false,
    }
  );
}
