import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getPermissionsByRoleId = async (roleId: number) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.ROLES}/${roleId}/permissions`
  );

  return data;
};

export default function useAllPermissionsByRole(roleId: number) {
  return useQuery([roleId, "getPermissionsByRoleId"], getPermissionsByRoleId, {
    refetchOnWindowFocus: false,
  });
}
