import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";

import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import {
  putDataWithDotNet,
  putFormDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import CreateInvestmentModal from "./CreateInvestmentModal/CreateInvestmentModal";
import { ROLE_TYPES } from "../../../helpers/roleTypes";
import { useSelector } from "react-redux";
import { appInsights } from "../../../config/appInsights";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import {
  getInvestmentCurrencies,
  TInvestmentCurrency,
} from "../../../custom-hooks/useInvestmentCurrencies";
import { getCurrencyInfo } from "../helper";
import EditFinancials from "./CreateInvestmentsViews/EditFinancials";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import {
  TCreateInvestmentProductState,
  TInvestmentProductDetails,
} from "./CreateInvestmentProduct";

import styles from "./InvestmentProduct.module.scss";
import useAnInvestmentProduct from "../../../custom-hooks/useAnInvestmentProduct";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import {
  useInvestmentProductSubtypeDetails,
  useInvestmentProductSubtypes,
  useInvestmentProductTypes,
} from "../../../custom-hooks/useDebtFund";
import {convertKeysToCamelCase, handleSwalErrorAlert} from "../../../helpers";
import { convertObjToCamelCase } from "../../../helpers/statsControllers";
import Loader from "../../../NewComponents/Loader/Loader.component";
import CustomInputField from "../../../NewComponents/CustomHTMLElements/CustomInputField";
import {string} from "yup";
import {onChangeFile} from "../../../helpers/convertToFormData";

interface ILocationState {
  editType: string;
  id: number;
}

const EditInvestmentProduct = () => {
  const user = useSelector((state: any) => state.authReducer?.loggedInUser);
  const location = useLocation();
  const { editType, id } = location.state as ILocationState;
  const dismissModalRef = useRef<HTMLElement | null>(null);
  const history = useHistory();
  const [modalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [saveDraftError, setSaveDraftError] = useState("");
  const [state, setState] = useState<TCreateInvestmentProductState>({
    name: "",
    description: "",
    endDate: "",
    investmentProductDetails: [],
    issuers: "",
    startDate: "",
    objective: "",
    targetInvestors: "",
    investmentProductTypeId: null,
    investmentProductSubTypeId: null,
    investmentProductSubTypeDetailId: null,
    termSheetFile: undefined,
  });
  const [isPickingDocument, setIsPickingDocument] = useState(false);

  const { data: dataToEdit, isLoading: dataToEditIsLoading } =
    useAnInvestmentProduct(id);

  useEffect(() => {
    console.log({
      dataToEdit,
    });
    if (dataToEdit) {
      setState({
        ...state,
        name: dataToEdit?.name,
        objective: dataToEdit?.objective,
        issuers: dataToEdit?.issuers,
        description: dataToEdit?.description,
        targetInvestors: dataToEdit?.target_investors,
        investmentProductTypeId: dataToEdit?.investment_product_type_id,
        investmentProductSubTypeId: dataToEdit?.investment_product_sub_type_id,
        investmentProductSubTypeDetailId:
          dataToEdit?.investment_product_sub_type_detail_id,
        startDate: dataToEdit?.start_date.substring(0, 10),
        endDate: dataToEdit?.end_date.substring(0, 10),
        investmentProductDetails: dataToEdit?.investment_product_details,
      });
    }
  }, [dataToEdit, dataToEditIsLoading]);

  const handleSubmitDraft = async () => {
    if (state) {
      setSaveDraftError("");
      setLoading(true);
      const { startDate, endDate } = state;
      const reqBody = { ...state };

      reqBody.startDate = moment(startDate).format("DD/MM/YYYY");
      reqBody.endDate = moment(endDate).format("DD/MM/YYYY");

      const camelCaseReqBody = convertObjToCamelCase(reqBody);
      const formData: any = new FormData();
      formData.append("InvestmentProductDto", JSON.stringify(camelCaseReqBody)); // Stringify the state

      if (state.termSheetFile?.file) {
        formData.append(
          "TermSheet",
          state.termSheetFile.file,
          state.termSheetFile.fileName,
        );
      }

      if (editType === "drafted") {
        try {
          const response = await putFormDataWithDotNet(
            `${ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT}/${dataToEdit.id}/update_pending`,
            formData,
          );
          setLoading(false);
          setSuccessMessage(response.message);
          setTimeout(() => {
            history.push("/investments");
          }, 3000);
        } catch (error: any) {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "EditInvestmentProduct.jsx",
            },
          });
          setLoading(false);
          setSaveDraftError(errorHandler(error));
        } finally {
          setLoading(false);
        }
      } else if (editType === "active") {
        let activeReqBody;

        console.log({
          activeReqBody: state?.investmentProductDetails,
        });

        const newInvestmentProductDetails =
          state?.investmentProductDetails?.map((item: any) => {
            console.log({item})
            return {
              ...item,
              // isLocked: JSON.parse(item?.is_locked!),
            };
          });

        const formData: any = new FormData();

        if (state.termSheetFile?.file) {
          formData.append(
            "TermSheet",
            state.termSheetFile.file,
            state.termSheetFile.fileName,
          );
        }

        activeReqBody = {
          ...state,
          termSheetFile: undefined,
          endDate: moment(endDate).format("DD/MM/YYYY"),
          startDate: moment(startDate).format("DD/MM/YYYY"),
          investmentProductDetails: newInvestmentProductDetails,
        };

        const camelCaseReqBody = convertObjToCamelCase(activeReqBody);

        formData.append(
          "InvestmentProductDto",
          JSON.stringify(camelCaseReqBody),
        ); // Stringify the state

        try {
          const response = await putFormDataWithDotNet(
            `${ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT}/${dataToEdit.id}/update_active`,
            formData,
          );
          setLoading(false);
          setSuccessMessage(response.message);
          setTimeout(() => {
            history.push("/investments");
          }, 3000);
        } catch (error: any) {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "EditInvestmentProduct.jsx",
            },
          });
          setSaveDraftError(errorHandler(error));
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    id?: number,
  ) => {
    const { name, value } = e.currentTarget;
    if (id) {
      const currentState = { ...state };

      const result = currentState?.investmentProductDetails?.find(
        (item) => item?.id === id,
      );
      const indexOfResult = currentState?.investmentProductDetails?.findIndex(
        (item: any) => item === result,
      );

      const newProductDetailsArray = currentState?.investmentProductDetails;
      if (
        indexOfResult &&
        newProductDetailsArray &&
        newProductDetailsArray?.length > 0
      ) {
        if (name.includes("is_locked")) {
          newProductDetailsArray[indexOfResult] = {
            ...newProductDetailsArray[indexOfResult],
            isLocked: JSON.parse(value),
          };
        } else {
          newProductDetailsArray[indexOfResult] = {
            ...newProductDetailsArray[indexOfResult],
            [name]: value,
          };
        }
      }

      setState((prev) => ({
        ...prev,
        investmentProductDetails: newProductDetailsArray,
      }));
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const getOptions = () => {
    return new Promise((resolve, reject) => {
      getInvestmentCurrencies()
        .then((data) => {
          resolve(
            data?.data.map((detail: TInvestmentCurrency) => ({
              value: detail?.currency_code,
              label: detail?.currency_name,
            })),
          );
        })
        .catch(reject);
    });
  };

  const handleDropdownChange = (data: any) => {
    return setState((prev) => ({
      ...prev,
      investmentProductDetails: [...data],
    }));
  };

  const getInvestmentFundCurrency = (
    listInQuestion: TInvestmentProductDetails[],
  ) => {
    if (listInQuestion && listInQuestion.length > 0) {
      return listInQuestion.map((item) => {
        return {
          label: getCurrencyInfo(item?.currencyCode)?.name!,
          value: item?.currencyCode,
        };
      });
    }

    return [];
  };

  const discardChanges = (e: FormEvent) => {
    e.preventDefault();
    if (dismissModalRef && dismissModalRef.current) {
      dismissModalRef.current.click();
    }
    setState({
      ...state,
      name: dataToEdit?.name || "",
      objective: dataToEdit?.objective || "",
      issuers: dataToEdit?.issuers || "",
      description: dataToEdit?.description || "",
      targetInvestors: dataToEdit?.target_investors || "",
      // investmentProductTypeId: dataToEdit?.investmentProductTypeId || 1,
      startDate: dataToEdit?.start_date.substring(0, 10) || "",
      endDate: dataToEdit?.end_date.substring(0, 10) || "",
      investmentProductDetails: dataToEdit?.investment_product_details,
    });
  };

  const handleChangeInvestmentTypeParams = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    if (name === "investmentProductTypeId") {
      setState({
        ...state,
        [name]: Number(value),
        investmentProductSubTypeId: null,
        investmentProductSubTypeDetailId: null,
      });
    } else {
      setState({ ...state, [name]: Number(value) });
    }
  };

  const {
    data: investmentProductTypes,
    isError: investmentProductTypeError,
    isLoading: investmentProductTypeLoading,
  } = useInvestmentProductTypes();

  const {
    data: investmentProductSubTypes,
    isError: investmentProductSubTypeError,
    isLoading: investmentProductSubTypeLoading,
  } = useInvestmentProductSubtypes(state.investmentProductTypeId);

  const {
    data: investmentProductSubTypeDetails,
    isError: investmentProductSubTypeDetailError,
    isLoading: investmentProductSubTypeDetailLoading,
  } = useInvestmentProductSubtypeDetails(state.investmentProductSubTypeId);

  /*  const {
    data: mifosData,
    status: mifosStatus,
    error: mifosError,
  } = useMifosProduct({
    productType : 3,
  });

  if (mifosStatus === "loading" || !state) {
    return <Loader centered={true} text="Loading mifos information..." />;
  }
  if (mifosError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            mifos products, please try again.
          </h4>
        </div>
      </div>
    );
  }*/

  async function onChangeTermSheet(e: any) {
    if (isPickingDocument) return; // Prevent multiple invocations
    setIsPickingDocument(true); // Set document picking status to true
    const errorCallback = (error: any) => {
      handleSwalErrorAlert(error)
      appInsights.trackException({
        exception: new Error(error),
        properties: { fileName: "Financials.tsx" },
      });
      setIsPickingDocument(false); // Reset document picking status
    };

    const setInfoCallback = (file: File, fileName: string) => {
      setState({
        ...state,
        termSheetFile: {
          file,
          fileName,
        },
      });
      setIsPickingDocument(false); // Reset document picking status
    };

    await onChangeFile(e, setInfoCallback, errorCallback);
  }


  const updateInvestmentProductDetails = (updatedDetails: TInvestmentProductDetails) => {
    const updatedList = state.investmentProductDetails.map(item =>
        item.id === updatedDetails.id ? updatedDetails : item
    );

    setState({
      ...state,
      investmentProductDetails: updatedList,
    })
  };


  return (
    <Fragment>
      <div
        className={`animated fadeInRight mt-2 ${styles.createInvestmentProduct}`}
      >
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Back
        </h3>

        <div className="d-flex flex-row flex-wrap mt-3 mb-1">
          {(investmentProductTypeError ||
            investmentProductSubTypeError ||
            investmentProductSubTypeDetailError) && (
            <Alert message={"Error fetching investment type details"} />
          )}
          {saveDraftError && <Alert message={saveDraftError} />}
          {successMessage && <Alert message={successMessage} type="success" />}
          <h3 className="page-title">Edit Product</h3>

          <div className="d-flex flex-row flex-wrap justify-content-end">
            {user?.roles.includes(ROLE_TYPES.INVESTMENT_PRODUCT_SETUP) ? (
              <div>
                <button
                  type="submit"
                  className="btn advancly-white-btn btn-md m-0"
                  onClick={handleSubmitDraft}
                  disabled={loading}
                >
                  {loading ? <Loader /> : "Update"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <h3 className="page-subtitle mb-3 ml-3">Investment Details</h3>
        <form>
          <div className="row col-8">
            <div className="col-md-12 mt-1">
              <label className="label-txt">Investment Name</label>
              <input
                className="form-control"
                value={state?.name}
                name="name"
                onChange={(e) => handleChange(e)}
                disabled={editType === "active"}
              />
            </div>

            <div className="col-md-12 mt-3">
              <CustomSelectDropdown
                onChange={(e) => handleChangeInvestmentTypeParams(e)}
                name="investmentProductTypeId"
                value={state.investmentProductTypeId}
                className="round user__form form-control w-100"
                label={
                  investmentProductTypeLoading
                    ? "Loading...."
                    : "Investment Type"
                }
              >
                <option value="">Select investment type</option>
                {investmentProductTypes &&
                  investmentProductTypes.map((item) => {
                    const { name, id } = item;
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>

            <div className="col-md-12 mt-3">
              <CustomSelectDropdown
                onChange={(e) => handleChangeInvestmentTypeParams(e)}
                name="investmentProductSubTypeId"
                value={state.investmentProductSubTypeId}
                className="round user__form form-control w-100"
                label={
                  investmentProductSubTypeLoading
                    ? "Loading...."
                    : "Investment Subtype"
                }
              >
                <option value="">Select investment Subtype</option>
                {investmentProductSubTypes &&
                  investmentProductSubTypes.map((item) => {
                    const { subTypeId, subTypeName } = item;
                    return (
                      <option value={subTypeId} key={subTypeId}>
                        {subTypeName}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>

            <div className="col-md-12 mt-3">
              <CustomSelectDropdown
                onChange={(e) => handleChangeInvestmentTypeParams(e)}
                name="investmentProductSubTypeDetailId"
                value={state.investmentProductSubTypeDetailId}
                className="round user__form form-control w-100"
                label={
                  investmentProductSubTypeDetailLoading
                    ? "Loading...."
                    : "Investment Subtype Details"
                }
              >
                <option value="">Select investment Subtype details</option>
                {investmentProductSubTypeDetails &&
                  investmentProductSubTypeDetails.map((item) => {
                    const { subTypeDetailId, subTypeDetail } = item;
                    return (
                      <option value={subTypeDetailId} key={subTypeDetailId}>
                        {subTypeDetail}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>

            <div className={styles.productBasicDetails}>
              <div className="col-md-6 pl-2">
                <label>Subscription Start date: </label>
                <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={state?.startDate}
                    disabled={editType === "active"}
                    onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6">
                <div>
                  <label>Subscription End date: </label>
                  <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={state?.endDate}
                      onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3 pl-2">
                <label className="label-txt">About this Fund</label>
                <input
                    className="form-control"
                    value={state?.description}
                    name="description"
                    disabled={editType === "active"}
                    onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label className="label-txt">Investment Objective</label>
                <input
                    className="form-control"
                    value={state?.objective}
                    name="objective"
                    disabled={editType === "active"}
                    onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6 mt-3 pl-2">
                <label className="label-txt">Issuer</label>
                <input
                    className="form-control"
                    value={state?.issuers}
                    disabled={editType === "active"}
                    name="issuers"
                    onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label className="label-txt">Target Investors</label>
                <input
                    className="form-control"
                    value={state?.targetInvestors}
                    disabled={editType === "active"}
                    name="targetInvestors"
                    onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="col-md-12 mb-3 mt-3">
                <label className="label-txt">Supporting Term sheet (optional)</label>
                <CustomInputField
                    type="file"
                    placeholder=""
                    label=""
                    onChange={onChangeTermSheet}
                    name="termSheet"
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    styles={{paddingBottom: "2rem"}}
                />
              </div>
            </div>

            <hr className={styles.divider}></hr>
            <h3 className="page-subtitle mb-3 ml-3">
              Investment Product Details
            </h3>
            <div className="col-md-12 my-3">
              <label className="label-txt">
                What currencies are you accepting for this fund:
              </label>

              <div className="col-md-12 p-0">
                <CustomSearchDropDown
                    label="Select Currency"
                    onChange={handleDropdownChange}
                    loadOptions={getOptions}
                  disabled={true}
                  defaultValue={getInvestmentFundCurrency(
                    state?.investmentProductDetails || [],
                  )}
                  defaultOptions={true}
                  name="user"
                  isShowLabel={false}
                  isMulti={true}
                  isSearchable={false}
                />
              </div>
            </div>

            <div className={styles.financialsContainer}>
              <div className="col-md-12">
                <h3 className="page-subtitle mb-2">Currencies:</h3>
              </div>

              {state?.investmentProductDetails?.length < 1 && (
                <div className={styles.noCurrencyErrorContainer}>
                  You have not selected any currencies
                </div>
              )}

              {state?.investmentProductDetails?.map(
                (item: TInvestmentProductDetails, index: number) => {
                  // Convert keys of each item to camelCase
                  const camelCaseItem = convertKeysToCamelCase(item);
                  return (
                    <EditFinancials
                      key={index}
                      handleChange={handleChange}
                      state={state}
                      setState={setState}
                      editType={editType}
                      selectedProductDetails={camelCaseItem}
                      updateParentState={updateInvestmentProductDetails}
                    />
                  );
                },
              )}


            </div>
          </div>
        </form>
      </div>
      <CreateInvestmentModal
        onSubmit={handleSubmitDraft}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
      />
    </Fragment>
  );
};

export default EditInvestmentProduct;
