import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getPendingOfflineRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.PENDING_OFFLINE_INVESTMENT_APPROVAL
  );
  return data;
};

export default function usePendingOfflineRequests() {
  return useQuery(["getPendingOfflineRequests"], getPendingOfflineRequests, {
    refetchOnWindowFocus: false,
  });
}
