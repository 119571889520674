import React from "react";

// Styles
import "./Toast.css";

const ToastSlim = ({
  toastState: { toastMessage, type, showToast },
  onClearToast,
}) => {
  if (showToast) {
    setTimeout(() => {
      onClearToast();
    }, 5000);
  }

  return (
    <div>
      <div
        id="toast"
        className={showToast ? "show" : ""}
        style={{
          backgroundColor:
            type === "info"
              ? "#2992D0"
              : type === "warning"
              ? "#F26527"
              : type === "error"
              ? "crimson"
              : "#51B74B",
        }}
      >
        <i className="fa fa-bell" /> &nbsp;
        {toastMessage}
      </div>
    </div>
  );
};

const ToastBox = (prop) => {
  return (
    <div>
      <div id="toast-box"></div>
    </div>
  );
};

export { ToastSlim, ToastBox };
