import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  page: number;
  size: number;
};

const getFailedWithdrawals = async ({ page, size }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.FAILED_WITHDRAWALS}?page=${page}&PageSize=${size}`
  );
  return data;
};

export default function useFailedWithdrawals({ page, size }: TQuery) {
  return useQuery(
    [{ page, size }, "getFailedWithdrawals"],
    getFailedWithdrawals,
    {
      refetchOnWindowFocus: false,
    }
  );
}
