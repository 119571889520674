export type TCurrencyName = "NGN" | "USD" | "KES";

export const getCurrencyInfo = (currencyCode: TCurrencyName | string) => {
  switch (currencyCode) {
    case "NGN":
      return {
        name: "Naira",
        icon: require("../../assets/svg/icons/naira.svg"),
        symbol: "\u20A6",
      };

    case "USD":
      return {
        name: "Dollar",
        icon: require("../../assets/svg/icons/dollar.svg"),
        symbol: "\u0024",
      };

    case "KES":
      return {
        name: "Kenya Shilling",
        icon: require("../../assets/svg/icons/KSH.svg"),
        symbol: "KES",
      };

    default:
      return {};
  }
};
