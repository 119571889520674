import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getEachLoansBatched = async (id) => {
  const data = await getData(
    `${ajaxEndpoints.GET_ALL_BATCH_DISBURSEMENT_DETAILS}?batchdisbursementKey=${id}`
  );
  return data;
};
export default function useEachLoansBatched(id) {
  return useQuery([id, "getEachLoansBatched"], getEachLoansBatched);
}
