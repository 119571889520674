import styles from "./Card.module.scss";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { ReactComponent as UserIcon } from "../../../../../../assets/svg/icons/user-no-fill.svg";
import useGetLinkedCards from "../../../../../../custom-hooks/useGetLinkedCards";

type ICards = {
  card_id: number;
  customer_id: number;
  last_four_digits: string;
  card_type: string;
  expiry_month: string;
  expiry_year: string;
  account_name: string | null;
  bank: string;
  country_code: string;
};

function Card({
  dataRecord,
  first_name,
  last_name,
}: {
  dataRecord: any;
  first_name: string;
  last_name: string;
}) {
  const {
    data: linkedCards,
    isLoading: isLoadingLinkedCards,
    isError: isLinkedCardsError,
  } = useGetLinkedCards(dataRecord?.borrower_details?.customer_id);

  return (
    <div className="speed-1x animated fadeInRight">
      <div className={styles.cards_container}>
        <header className={styles.cards_header}>
          <div className={styles.user_icon}>
            <UserIcon />
            User: <span>{`${first_name}  ${last_name}`}</span>
          </div>
          <h2>Card Details</h2>
        </header>
        {isLoadingLinkedCards ? (
          <Loader
            text="Loading available Bank statement uploads.."
            centered={true}
          />
        ) : linkedCards?.data?.length > 0 ? (
          <div className="row m-x-sm m-y-2 grid__padding">
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>S/N</th>
                    <th>Customer ID</th>
                    <th>Bank Name</th>
                    <th>Account Name</th>
                    <th>Country Code</th>
                    <th>Card Type</th>
                    <th>Expiry Date</th>
                    <th>Last Four Digits</th>
                  </tr>
                </thead>
                {linkedCards?.data?.map((card: ICards, index: number) => (
                  <tbody key={card.card_id}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{card.customer_id}</td>
                      <td>{card.bank}</td>
                      <td>{card?.account_name ?? "NIL"}</td>
                      <td>{card.country_code}</td>
                      <td>{card.card_type}</td>
                      <td>{`${card.expiry_month}/${card.expiry_year}`}</td>
                      <td>{card.last_four_digits}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        ) : isLinkedCardsError ? (
          <p style={{ marginTop: "20px" }}>
            An error occurred, unable to fetch available cards
          </p>
        ) : (
          <p style={{ marginTop: "20px" }}>
            No Card has been linked to this account
          </p>
        )}
      </div>
    </div>
  );
}

export default Card;
