import React from "react";

export const CrcPersonalData = ({ tableData, scoreData }) => {
  const { name, datE_OF_BIRTH, gender, nationality, phonE_NO1 } = tableData;
  const { crediT_SCORE, crediT_RATING } = scoreData;
  return (
    <div>
      {Object.keys(tableData).length > 0 && (
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0 mb-3">
              <i className="adv-icon fas fa-cogs" />
              &nbsp;Details
            </h3>

            <div className="row col-md-12">
              <div>
                <div className="col-md-6">
                  <label className="label-txt">Name</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={name ? name : "Name not found"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Phone Number</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={phonE_NO1 ? phonE_NO1 : "Phone number not found"}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="col-md-6">
                  <label className="label-txt">Date of Birth</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        datE_OF_BIRTH
                          ? datE_OF_BIRTH
                          : "Date of Birth not found"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Gender</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={gender ? gender : "Gender not found"}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="col-md-6">
                  <label className="label-txt">Nationality</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="user__form form-control"
                      value={
                        nationality ? nationality : "Nationality not found"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="label-txt">Credit Score</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      value={crediT_SCORE ? crediT_SCORE : "N/A"}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="col-md-6">
                  <label className="label-txt">Credit Rating</label>
                  <div className="form-group inputWithIcon">
                    <input
                      className="user__form form-control"
                      value={crediT_RATING ? crediT_RATING : "N/A"}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
