import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";

const getDirectDebitQueryStatus = async ({
  mandate_reference_code,
  loan_ref,
}: {
  loan_ref: string;
  mandate_reference_code: string;
}) => {
  const data = await getData(
    `${ajaxEndpoints.GET_DIRECT_DEBIT_MANDATE_QUERY}?loan_ref=${loan_ref}&mandate_reference_code=${mandate_reference_code}`
  );
  return data.data;
};
export default function useDirectDebitQueryStatus({
  mandate_reference_code,
  loan_ref,
}: {
  loan_ref: string;
  mandate_reference_code: string;
}) {
  return useQuery(
    [{ mandate_reference_code, loan_ref }, "getDirectDebitQueryStatus"],
    getDirectDebitQueryStatus,
    {
      refetchOnWindowFocus: false,

      enabled: false,
    }
  );
}
