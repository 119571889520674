import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "./../Alert/Alert.component";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { postData } from "../../newApis/apiMethods";
import useAggregatorEnvironment from "./../../custom-hooks/useAggregatorEnvironment";
import { appInsights } from "../../config/appInsights";
import { ROLE_TYPES } from "../../helpers/roleTypes";

export default function Toggler({ aggregatorId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLiveEnvironment, setIsLiveEnvironment] = useState(false);
  const [isInitialSet, setIsInitialSet] = useState(false);

  const {
    data,
    status,
    error: envError,
  } = useAggregatorEnvironment(aggregatorId);

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);

  if (data && status === "success" && !isInitialSet) {
    if (data?.statusCode === 9) {
      setIsLiveEnvironment(true);
    }
    setIsInitialSet(true);
  }

  const toggleEnvironment = async (e) => {
    setError(null);
    setLoading(true);
    const isChecked = e.target.checked;
    try {
      const reqBody = {
        aggregator_id: Number(aggregatorId),
      };
      const url = isChecked
        ? ajaxEndpoints.ACTIVATE_AGGREGATOR
        : ajaxEndpoints.DEACTIVATE_AGGREGATOR;
      await postData(url, reqBody);
      setLoading(false);
      setIsLiveEnvironment(!isLiveEnvironment);
      setSuccess(
        `Aggregator has been ${isChecked ? "Activated" : "Deactivated"}`
      );
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Toggler.component.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const isValidOnboardingStatus = data && data.statusCode >= 8;
  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex align-items-center justofy-content-center px-3">
        <span>Sandbox</span>
        <div className="custom-control custom-switch ml-3 mr-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="enviromentSwitch"
            disabled={
              loading ||
              !isValidOnboardingStatus ||
              status === "loading" ||
              envError ||
              (loggedInUser?.roles.includes(
                ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER
              ) &&
                loggedInUser?.roles.includes(ROLE_TYPES.INVESTMENT_VIEWER) &&
                loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_VIEWER) &&
                loggedInUser?.roles.includes(ROLE_TYPES.AGGREGATOR_VIEWER))
            }
            onChange={toggleEnvironment}
            checked={isLiveEnvironment}
          />
          <label
            className="custom-control-label cursor-pointer"
            htmlFor="enviromentSwitch"
          />
        </div>
        <span className="d-flex align-items-center">
          <span className="mr-2">Live</span>{" "}
        </span>
      </div>
    </Fragment>
  );
}
