import React, { useEffect, useState } from "react";
import { appInsights } from "../../../config/appInsights";
import {
  IInvestmentDetails,
  IInvestmentTransaction,
} from "../../../interfaces/funderInvestment";
import { formatMoney } from "../../../helpers/formatter";
import { getDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import moment from "moment";

const InvestmentTransactions = ({
  currency,
  id,
}: {
  currency: string;
  id: number;
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<IInvestmentTransaction[]>(
    []
  );
  const [details, setDetails] = useState<IInvestmentDetails | undefined>();
  useEffect(() => {
    appInsights.trackPageView({
      name: "Investment Details  - (InvestmentTransactions.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleFetchTransactions = async () => {
    setIsLoading(true);
    try {
      const response = await getDataWithDotNet(
        `${ajaxEndpoints.GET_INVESTMENT_TRANSACTIONS}/${id}/transactions`
      );
      if (response?.status_code !== 200) {
        throw new Error(response);
      } else {
        setTransactions(response?.data?.transactions);
        setDetails(response?.data?.investment_details);
      }
    } catch (error) {
      setErrorMessage(errorHandler(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchTransactions();
  }, []);

  return (
    <div>
      <h6 className="my-4 page-subtitle">Investment Transactions</h6>
      {errorMessage ? (
        <p className="alert alert-danger col-md-6">
          Error fetching investment transactions. Please reload
        </p>
      ) : isLoading ? (
        <div className="d-flex justify-content-center">
          <span className="spinner-border advancly-primary-text" />
        </div>
      ) : (
        <>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label className="label-txt d-flex justify-content-between">
                  Ledger Balance
                </label>
                <input
                  className="user__form form-control"
                  defaultValue={formatMoney(details?.ledger_balance, currency)}
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label className="label-txt d-flex justify-content-between">
                  Available Balance
                </label>
                <input
                  className="user__form form-control"
                  defaultValue={formatMoney(details?.account_balance, currency)}
                  readOnly
                />
              </div>
            </div>
          </form>
          <br />

          <div className="row m-x-sm m-y-2 grid__padding">
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>ID</th>
                    <th>Transaction Date</th>
                    <th>Transaction Type</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions &&
                    transactions.map((item, index) => {
                      const {
                        id,
                        currency,
                        amount,
                        transaction_category,
                        transaction_date,
                        transaction_type,
                        running_balance,
                      } = item;
                      return (
                        <tr key={id}>
                          <td>{id}</td>
                          <td>
                            {moment(transaction_date).format("DD MMMM YYYY")}
                          </td>
                          <td>{transaction_type}</td>
                          <td>
                            {transaction_category?.toLowerCase() === "debit"
                              ? formatMoney(amount, currency)
                              : ""}
                          </td>
                          <td>
                            {transaction_category?.toLowerCase() === "credit"
                              ? formatMoney(amount, currency)
                              : ""}
                          </td>
                          <td>{formatMoney(running_balance, currency)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvestmentTransactions;
