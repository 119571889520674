import React, { useState } from "react";
import { Loader } from "../../../../../../../UI/Loaders/Loaders";
import useCountryCodes from "../../../../../../../custom-hooks/useCountryCodes";
import useCountryById from "../../../../../../../custom-hooks/useCountryById";

const CountryLiquidationView = () => {
  const [countryId, setCountryId] = useState("");
  const { data: countryData } = useCountryCodes();

  const { data, status, error } = useCountryById(countryId);
  return (
    <div className="card">
      <div className="body text-center">
        <h3 className="m-t-0 m-b-3">
          <i className="adv-icon fas fa-eye" />
          Country and its Liquidation Channel Settings
        </h3>
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-6">
            <form>
              <div className="form-group">
                <select
                  data-form="liquidationSearch"
                  className="round user__form form-control"
                  value={countryId}
                  placeholder="Select a channel"
                  name="countryId"
                  onChange={(e) => setCountryId(e.target.value)}
                >
                  <option selected>Select a country</option>
                  {countryData &&
                    countryData.map((data) => {
                      const { id, name } = data;
                      return (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          {status !== "loading" && data?.length === 0 && (
            <div className="centerLoader m-b-3">
              <div className="grid__padding animated fadeInRight">
                <h4 className="center-txt p-y-5">
                  <i className="fas fa-bell" /> No record found
                </h4>
              </div>
            </div>
          )}
          {error && (
            <div className="centerLoader m-b-3">
              <div className="grid__padding animated fadeInRight">
                <h4 className="center-txt p-y-5">
                  <i className="fas fa-bell" /> Unable to Fetch Records
                </h4>
              </div>
            </div>
          )}
          {status !== "loading" && data?.length === null && (
            <div className="centerLoader">
              <div className="grid__padding animated fadeInRight">
                <h4 className="center-txt p-y-5">
                  <i className="fas fa-bell" />
                  Unable to Fetch Records
                </h4>
              </div>
            </div>
          )}
          {status === "loading" && (
            <div className="centerLoader">
              <div className="grid__padding animated fadeInRight">
                <Loader text="Loading....." background={true} />
              </div>
            </div>
          )}
          {status !== "loading" && data && (
            <div className="grid__padding animated speed-1x fadeInRight mt-4">
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>Country</th>
                      <th>Liquidation Channel</th>
                      <th>Liquidation Channel Account Name</th>
                      <th>Liquidation Channel Account Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.name}</td>
                      <td>{data.channel_name}</td>
                      <td>{data.account_name}</td>
                      <td>{data.account_number}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountryLiquidationView;
