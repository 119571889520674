import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getAllPendingRepayment = async () => {
  const data = await getData(`${ajaxEndpoints.GET_PENDING_BATCH_REPAYMENT}`);
  return data;
};

export default function useAllPendingRepayment() {
  return useQuery(["getAllPendingRepayment "], getAllPendingRepayment);
}
