import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
type paramProps = { searchValue: string; pageNumber: number; size: number };

type TResponse = {
  data: {
    approval_status: string;
    biz_name: string;
    code: string;
    document_id: number;
    document_type: string;
    files: {
      file_name: string;
      funder_kyc_document_id: number;
      upload_path: string;
    }[];
    funder_id: number;
    funder_name: string;
    funder_user_id: number;
    request_date: string;
    type: string;
    user_type: string;
  }[];
  totalCount: number;
  totalPages: number;
};

const getAllInvestorsPendingDocuments = async ({
  searchValue,
  pageNumber,
  size,
}: any) => {
  let queryParams = `?page=${pageNumber}&pageSize=${size}`;
  if (searchValue) {
    queryParams = `${queryParams}&queryValue=${searchValue}`;
  }
  const response = await getData(
    `${ajaxEndpoints.PENDING_INVESTORS_DOCUMENTS}${queryParams}`
  );

  return response?.data;
};

export default function useAllInvestorsPendingDocuments({
  searchValue,
  pageNumber,
  size,
}: paramProps) {
  return useQuery<TResponse>(
    [{ searchValue, pageNumber, size }, "getAllInvestorsPendingDocuments"],
    getAllInvestorsPendingDocuments,
    {
      refetchOnWindowFocus: false,
    }
  );
}
