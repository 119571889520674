import React, { Component } from "react";

// Router
import { BrowserRouter as Router } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { showNavigation } from "./redux/actions/navigation";
import { showNotifications } from "./redux/actions/notifications";
import { clearToast } from "./redux/actions/toast";
import Loader from "./shared/Loader/Loader";
import Header from "./shared/Header/Header";
import AppRouter from "./shared/AppRouter/AppRouter";
import Footer from "./shared/Footer/Footer";
import { ToastSlim } from "./UI/Toast/Toast";
import SideNav from "./UI/SideNav/SideNav";
import Overlay from "./UI/Overlay/Overlay";
import { hideModal } from "./redux/actions/modal";
import ModalSelector from "./modals/ModalSelector";
// import ScrollToTop from "./components/ScrollToTop/ScrollToTop.component";

class App extends Component {
  // State
  state = {
    display: "dashboard",
  };

  // Methods
  toggleSideNav = () => {
    // Get and display the modal
    document.querySelector("#mySidebar").classList.toggle("show");
    document.querySelector("#overlay").classList.toggle("show");
  };

  closeModal = (e) => {
    this.props.hideModal();
  };

  toggleModal = () => {
    // Get and display the modal
    document.querySelector("#displayModal").style.display = "block";
  };

  toggleNavigation = () => {
    document.querySelectorAll(".side-nav-links li").forEach((link) => {
      link.classList.remove("active");
    });

    let temp = window.location.pathname;
    const currentRoute = temp.replace("/", "");

    if (
      currentRoute === "page-not-found" ||
      currentRoute === "privacy" ||
      currentRoute === "terms-of-use"
    ) {
      // do nothing to sidebar
    } else {
      // document.getElementById(currentRoute).classList.add("active");
    }

    document.querySelector("#mySidebar").classList.toggle("show");
    document.querySelector("#overlay").classList.toggle("show");
  };

  toggleOverlay = () => {
    if (document.querySelector("#mySidebar").classList.contains("show")) {
      document.querySelector("#mySidebar").classList.remove("show");
    }
    document.querySelector("#overlay").classList.remove("show");
  };

  loaderHandler = () => {
    setTimeout(() => {
      document
        .querySelector("#adv-loader")
        .classList.add("normal-2x", "fadeOutRight");
    }, 4000);

    // Animate Header Content & Footer to View
    setTimeout(() => {
      document
        .querySelector("#adv-header")
        .classList.add("normal-3x", "fadeInDown");
      document.querySelector("#adv-header").classList.remove("hidden");
      document
        .querySelector("#adv-router")
        .classList.add("normal-2x", "fadeInLeft");
      document.querySelector("#adv-router").classList.remove("hidden");
      document
        .querySelector("#adv-footer")
        .classList.add("normal-3x", "fadeInUp");
      document.querySelector("#adv-footer").classList.remove("hidden");

      setTimeout(() => {
        document
          .querySelector("#adv-header")
          .classList.remove("normal-3x", "fadeInDown");
        document
          .querySelector("#adv-router")
          .classList.remove("normal-2x", "fadeInLeft");
        document
          .querySelector("#adv-footer")
          .classList.remove("normal-3x", "fadeInUp");
      }, 2000);
    }, 4400);
  };

  // Life Cycle Hooks
  componentDidMount() {
    this.loaderHandler();
  }

  // JSX Template
  render() {
    let { isAuthenticated, loggedInUser, toastState } = this.props;

    return (
      <Router>
        <div className="tfx-wrapper" id="app">
          <div id="adv-loader">
            <Loader />
          </div>

          <div id="adv-header" className="hidden">
            <Header
              onToggleNavigation={this.toggleNavigation}
              onToggleNotifications={this.toggleNotifications}
            />
          </div>

          <div id="adv-router" className="hidden">
            <AppRouter
              isAuthenticated={isAuthenticated}
              loggedInUser={loggedInUser}
            />
          </div>

          <div id="adv-overlay">
            {isAuthenticated && <Overlay onClick={this.toggleOverlay} />}
          </div>

          <div id="adv-sidenav">
            {isAuthenticated && !loggedInUser?.firstTimeLogin && (
              <SideNav
                isAuthenticated={isAuthenticated}
                userCategory={loggedInUser?.user_type}
                handleNavLinkClick={this.handleNavLinkClick}
                onCloseSideNav={this.toggleNavigation}
              />
            )}
          </div>

          <div id="adv-modal">
            <ModalSelector />
          </div>

          <div id="adv-toast">
            {/* Toast */}
            <ToastSlim
              toastState={toastState}
              onClearToast={() => this.props.clearToast()}
            />
          </div>

          <div id="adv-footer" className="hidden">
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, loggedInUser, sideNavActive } = state.authReducer;
  const { showNavigation } = state.navigationReducer;
  const { showNotifications } = state.notificationsReducer;
  const toastState = state.toastReducer;

  return {
    isAuthenticated,
    loggedInUser,
    sideNavActive,
    showNavigation,
    showNotifications,
    toastState,
  };
};

export default connect(mapStateToProps, {
  showNavigation,
  showNotifications,
  hideModal,
  clearToast,
})(App);
