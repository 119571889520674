import * as actionTypes  from '../actions/actionTypes';

let initialState = {
    showNotifications: false,
    //notifications: [],
    allNotifications: {
        notificationsTableData: null,
        notifications: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
};

const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.SHOW_NOTIFICATION:
            return {
                ...state,
                showNotifications: !state.showNotifications
            }

        case actionTypes.HIDE_NOTIFICATION:
            return {
                ...state,
                showNotifications: !state.showNotifications
            }

        case actionTypes.GET_NOTIFICATIONS_START:
                return {
                    ...state,
                    allNotifications: {
                        ...state.allNotifications,
                        loading: true,
                        error: null
                    }
                }
            
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                allNotifications: {
                    ...state.allNotifications,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }
    
        case actionTypes.GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                allNotifications: {
                    ...state.allNotifications,
                    loading: false,
                    error: { ...action.payload }
                }
            }

        default:
            return state;
    }
}

export default reducer;