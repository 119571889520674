import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../Pagination/Pagination.component";
import { showModal } from "../../redux/actions/modal";
import useAllAggregatorsTyped from "../../custom-hooks/useAllAggregatorsTyped";
import { formatMoney } from "../../helpers/formatter";
import useAllAggregatorTransactions from "../../custom-hooks/useAllAggregatorTransactions";
import { getTokenFromLocalStorage } from "../../helpers/localStorage";
import { BASE_URL } from "../../appConstants";
import { appInsights } from "../../config/appInsights";

const AllAggregatorTransactions = () => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    aggregatorId: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investment Transactions  - (AllAggregatorTransactions.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleDownload = () => {
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (state.startDate && state.endDate) {
      queryParams = `?FromDate=${state.startDate}&ToDate=${state.endDate}`;
    }
    if (state.aggregatorId) {
      queryParams = queryParams
        ? `${queryParams}&AggregatorId=${state.aggregatorId}`
        : `?AggregatorId=${state.aggregatorId}`;
    }
    setPreState(state);
    fetch(
      BASE_URL + `/account/download_aggregator_transactions${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                state.startDate && state.endDate
                  ? `Transactions from ${state.startDate} to ${state.endDate}.csv`
                  : `Transactions.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AllAggregatorTransactions.jsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  const [count, setCount] = useState(0);

  const { data, status, error } = useAllAggregatorTransactions(preState);
  const { data: aggregatorData } = useAllAggregatorsTyped();

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const resetTable = () => {
    setCount(0);
    setPreState({ ...state, pageNumber: 1 });
  };
  const modalHandler = (modalData: string, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate).valueOf() - new Date(state.startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Transactions
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Transactions..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              transactions, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Transaction.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Investor</th>
                  <th>Amount</th>
                  <th>Aggregator</th>
                  <th>Transation Type</th>
                  <th>Transation Date</th>
                  <th>Transation Id</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      funderFirstName,
                      funderLastName,
                      amount,
                      transactionType,
                      transactionID,
                      advanclyAggregatorName,
                      currency,
                      transactionDate,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>
                          {funderFirstName} {funderLastName}
                        </td>
                        <td>{formatMoney(amount, currency)}</td>
                        <td>{advanclyAggregatorName || "N/A"}</td>
                        <td>{transactionType}</td>
                        <td>{transactionDate.substring(0, 10)}</td>
                        <td>{transactionID}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex justify-content-between mb-4">
            <h3 className="page-title">Transactions</h3>
            <button
              type="button"
              className="btn advancly-white-btn m-0"
              onClick={handleDownload}
              disabled={status === "loading" || downloadLoading}
            >
              <i className="fas fa-download m-r-1" />
              Download {downloadLoading && <ButtonLoader />}
            </button>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
              {/* <div className="input-group mr-3" style={{ maxWidth: "200px" }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Reference Number..."
                  value={state.referenceNumber}
                  name="referenceNumber"
                  onChange={(e) => handleChange(e)}
                />
              </div> */}

              <div
                style={{ width: "170px", marginBottom: "10px" }}
                className="mr-3"
              >
                <CustomSelectDropdown
                  onChange={(e) => handleChange(e)}
                  name="aggregatorId"
                  value={state.aggregatorId}
                  className="round user__form form-control"
                >
                  <option value="">Search By Aggregator</option>
                  {aggregatorData &&
                    aggregatorData?.data?.data.map((aggregator) => {
                      const { biz_name, aggregator_id } = aggregator;
                      return (
                        <option
                          value={Number(aggregator_id)}
                          key={aggregator_id}
                        >
                          {biz_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>

              <div className="form-group mr-3 mt-2">
                <div className="input-group ">
                  <label className="mr-2">From </label>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={state.startDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-group mt-2 mr-3">
                <div className="input-group">
                  <label className="mr-2">To </label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "7px" }}>
                <button
                  type="submit"
                  className="btn advancly-btn btn-md"
                  value="submit"
                  disabled={status === "loading" || downloadLoading}
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          {data && data?.data && (
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber: number) =>
                setPreState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllAggregatorTransactions;
