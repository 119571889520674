import * as actionTypes from "../actions/actionTypes";

let initialState = {
  loading: false,
  success: false,
  error: false,
  onboardedUser: null,
  isOnboarded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ONBOARDED:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        isOnboarded: action.payload,
      };

    case actionTypes.ONBOARDING_START:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case actionTypes.ONBOARDING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        onboardedUser: { ...action.payload },
      };

    case actionTypes.ONBOARDING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.ONBOARDING_NETWORK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
