import * as actionTypes from "../actions/actionTypes";

let initialState = {
  documentUrl: "",
  isLoading: true,
  type: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DOCUMENT_VIEWER_RECORD:
      return {
        ...state,
        documentUrl: action?.payload?.documentUrl,
        isLoading: false,
        type: action?.payload?.type,
      };
    case actionTypes.CLEAR_DOCUMENT_VIEWER_RECORD:
      return {
        ...state,
        documentUrl: action?.payload?.documentUrl,
        isLoading: false,
        type: action?.payload?.type,
      };
    default:
      return state;
  }
};

export default reducer;
