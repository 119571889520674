import { FormEvent, useState } from "react";
import { Loader } from "../../../UI/Loaders/Loaders";
import ProgressBar from "../../../NewComponents/ProgressBar/ProgressBar";
import useAllInvestmentsProducts from "../../../custom-hooks/useInvestmentsProducts";
import { Link } from "react-router-dom";
import { getCurrencyInfo } from "../helper";

import styles from "./../Investment.module.scss";

const InvestmentProducts = () => {
  const [searchBy, setSearchBy] = useState("");
  const { data, status, error } = useAllInvestmentsProducts(1);

  const filterData = (e: FormEvent) => {
    e.preventDefault();
    setSearchBy(searchBy);
  };
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt py-5">
            <i className="fas fa-bell" /> Unable to Fetch Investment Funds
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading investment funds requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investment funds requests, Please Reload The Page.
            </h4>
          </div>
        </div>
      );
    } else if ((data && data.data.length === 0) || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5 ">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          {data &&
            data.data
              .filter(
                (eachData) =>
                  eachData &&
                  eachData.name
                    .toString()
                    .toLowerCase()
                    .includes(searchBy.toString().toLowerCase())
              )
              .map((request, _id: number) => {
                const { name, id, investment_product_details } = request;
                return (
                  <div
                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                    key={_id}
                  >
                    <div
                      className={`${styles.investmentLayoutCard} zoom-element`}
                    >
                      <div className={styles.title}>{name}</div>
                      <div className={styles.content}>
                        {investment_product_details.map(
                          (item: any, index: number) => (
                            <small key={index} className="color-grey mb-2">
                              {`${
                                getCurrencyInfo(item?.currency_code)?.name
                              } Fund `}
                            </small>
                          )
                        )}
                      </div>
                      {investment_product_details.map(
                        (item: any, index: number) => (
                          <div key={index} className="mb-3">
                            <div className="mb-2">
                              <ProgressBar
                                bgcolor="#713fff"
                                progress={item?.percentage_raised}
                                height={12}
                              />
                            </div>
                            <span className={styles.percent}>
                              {`${item?.percentage_raised}%`}
                            </span>
                            <span className={styles.content}>
                              {` raised in ${
                                getCurrencyInfo(item?.currency_code)?.name
                              }`}
                            </span>
                          </div>
                        )
                      )}

                      <div className={styles.link}>
                        <Link
                          to={{
                            pathname: `/investments/${id}`,
                            state: {
                              id: id,
                              from: "active",
                            },
                          }}
                          className="color-blue"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
        </>
      );
    }
  }
  return (
    <div>
      {/* {signUpError && <Alert message={signUpError} />}
        {successMessage && <Alert message={successMessage} type="success" />} */}
      <div className="row mx-1 my-3">
        <form className="" onSubmit={filterData}>
          <div className="input-group mr-3" style={{ maxWidth: "250px" }}>
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
              value={searchBy}
              name="searchBy"
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </div>
        </form>
      </div>

      <div className={`${styles.investmentLayout} row`}>
        {RenderedComponent}
      </div>
    </div>
  );
};

export default InvestmentProducts;
