import { Component } from "react";
import { withRouter } from "react-router-dom";

import { appInsights } from "../../config/appInsights";

// Redux
import { connect } from "react-redux";
import { updateAuthenticated } from "../../redux/actions/index";

// UI Component
import BreadCrumbs from "../../UI/BreadCrumbs/BreadCrumbs";

// Settings Components
import AdminSettings from "./Admin/AdminSettings";

export class ProductSetup extends Component {
  // State
  state = {};

  initTabs = () => {
    // letiables
    const tabLinks = document.querySelectorAll(".services-tab__link-item");
    const tabContents = document.querySelectorAll(".services-tab__tab-item");

    // Loop through the tab link
    for (let i = 0; i < tabLinks.length; i++) {
      tabLinks[i].addEventListener("click", function (e) {
        e.preventDefault();
        let id = this.hash.replace("#", "");

        // Loop through the tab content
        for (let j = 0; j < tabContents.length; j++) {
          let tabContent = tabContents[j];
          tabContent.classList.remove("is-visible");
          tabLinks[j].classList.remove("is-active");
          if (tabContent.id === id) {
            tabContent.classList.add("is-visible");
          }
        }

        this.classList.add("is-active");
      });
    }
  };

  // Life Cycle Hooks
  componentDidMount() {
    // If user is not authenticates, redirect to login
    if (!this.props.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.initTabs();
    appInsights.trackPageView({
      name: "Product Setup - (productSetup.jsx)",
      isLoggedIn: true,
    });
  }

  //JSX Template
  render() {
    return (
      <div className="Settings datatable-wrapper">
        <div className="table-view">
          {/*Welcome Block*/}
          <div className="block-header">
            {/* Bread Crumbs */}
            <h3 className="page-title mb-4">Product Setup</h3>
            <AdminSettings />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, loggedInUser } = state.authReducer;
  // const {getSettings } = state.settingsReducer;

  return {
    isAuthenticated,
    loggedInUser,
  };
};

export default connect(mapStateToProps, { updateAuthenticated })(
  withRouter(ProductSetup)
);
