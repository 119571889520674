import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useBankStatementProvider from "../../../../../../custom-hooks/useBankStatementProvider";
import useBankStatementProviderCountries from "../../../../../../custom-hooks/useBankStatementProviderCountries";
import { putDataWithDotNet } from "../../../../../../newApis/dotNetApiMethods";
import Loader from "../../../../../../NewComponents/Loader/Loader.component";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../../config/appInsights";
import { queryCache } from "react-query";
const ProviderSettings = () => {
  const [isLoading, SetIsLoading] = useState<boolean | null>(false);
  const [providerErrors, SetProviderErrors] = useState<string | null>(null);
  const [bankStatementCountryCode, setBankStatementCountryCode] = useState<
    string | null
  >(null);
  const [bankStatementCountrySucess, setBankStatementCountrySuccess] = useState<
    string | boolean | null
  >(null);
  const [changeProvider, setChangeProvider] = useState<string | null>(null);
  // useEffect(() => {
  //     appInsights.trackPageView({
  //       name: "Dashboard.jsx",
  //       isLoggedIn: true,
  //     });
  //   }, []);

  const { register, handleSubmit } = useForm({});
  interface bankStatementCountryType {
    id: number;

    country_code: string;

    bank_statement_provider_id: number | null;

    bank_statement_provider: string | null;

    country_name: string | null;
  }

  const { data: bankStatementProviderData, error: bankStatementProviderError } =
    useBankStatementProvider();
  const {
    data: bankStatementProviderCountriesData,
    error: bankStatementProviderCountriesError,
    status: bankStatementProviderCountriesStatus,
  } = useBankStatementProviderCountries();
  const onSubmitHandler = async () => {
    const reqBody = {
      country_code: bankStatementCountryCode,
      bank_statement_provider_id: changeProvider,
    };
    SetIsLoading(true);
    SetProviderErrors(null);
    try {
      const bankStatementProvider = await putDataWithDotNet(
        ajaxEndpoints.UPDATE_BANK_STATEMENT_PROVIDER_BY_COUNTRY,
        reqBody
      );
      SetIsLoading(false);
      setBankStatementCountrySuccess(bankStatementProvider?.message);
      queryCache.invalidateQueries("getBankStatementProviderCountries");
      setTimeout(() => {
        setBankStatementCountrySuccess(null);
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        // @ts-ignore
        exception: error,
        properties: {
          fileName: "ProviderSettings.tsx",
        },
      });
      SetIsLoading(false);
      SetProviderErrors(errorHandler(error));

      setTimeout(() => {
        SetProviderErrors(null);
      }, 3000);
    }
  };
  return (
    <div className="w-100 mt-4">
      {bankStatementCountrySucess && (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {bankStatementCountrySucess}
            </h4>
          </div>
        </div>
      )}

      {providerErrors && (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {providerErrors}
            </h4>
          </div>
        </div>
      )}

      {bankStatementProviderError && (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {bankStatementProviderError}
            </h4>
          </div>
        </div>
      )}
      {bankStatementProviderCountriesError && (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" />{" "}
              {bankStatementProviderCountriesError}
            </h4>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="table-responsive">
          {bankStatementProviderCountriesStatus === "loading" ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Country</th>
                  <th> Current Bank Statement Provider</th>
                  <th>Change Bank Statement Provider</th>
                </tr>
              </thead>
              <tbody>
                {bankStatementProviderCountriesData?.map(
                  ({
                    country_name,
                    bank_statement_provider,
                    country_code,
                    id,
                  }: bankStatementCountryType) => {
                    return (
                      <tr key={id} className="">
                        <td className="mt-2">{country_name}</td>
                        <td className="mt-2">{bank_statement_provider}</td>
                        <td className="mt-2">
                          {
                            <div className="d-flex  justify-content-center ml-2 ">
                              <select
                                className=" round user__form form-control ml-4 "
                                name="changeBankProvider"
                                ref={register}
                                style={{ width: "50%" }}
                                // the onchange is used to extract country code
                                onChange={(e) => {
                                  setChangeProvider(e.target.value);
                                  setBankStatementCountryCode(country_code);
                                }}
                              >
                                <option>Select another provider</option>
                                {bankStatementProviderData?.map(
                                  ({
                                    id: statementId,
                                    name,
                                  }: {
                                    id: number;
                                    name: string | null;
                                  }) => {
                                    return (
                                      <option
                                        value={statementId}
                                        key={statementId}
                                      >
                                        {name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <button
                                type="submit"
                                className="advancly-small-btn ml-4 "
                                style={{ width: "20%" }}
                              >
                                Submit new provider
                              </button>
                            </div>
                          }
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
      </form>
    </div>
  );
};

export default ProviderSettings;
