import React, { Fragment } from "react";

const InitiateBatchRepayment = ({ properties, onCloseModal }) => {
  const { loans, totalAmount, handleApproveRepayment } = properties;
  const handleApprove = (e) => {
    e.preventDefault();
    handleApproveRepayment(loans);
  };
  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          <h3 className="Modal__title p-b-1">Loan Repayment Summary</h3>
          <hr />
          {/* 1. Confirm Disbursement*/}
          <div id="disbursement-confirm">
            <div className="m-b-6 m-r-6">
              <p>
                You have selected {loans.length} loans for approval with Id{" "}
                {Object.keys(loans)
                  .map(function (loan) {
                    return loans[loan];
                  })
                  .join(", ")}{" "}
              </p>
              <p>Total Amount is {totalAmount} .</p>
              <p>Are you sure you want to repay the following loans?</p>
              <form>
                <div className="Form__action-buttons m-t-2">
                  <button
                    type="submit"
                    onClick={(e) => handleApprove(e)}
                    className="btn zoom-element btn login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Initiate
                  </button>
                  <button
                    type="button"
                    onClick={onCloseModal}
                    className="btn zoom-element btn login-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};
export default InitiateBatchRepayment;
