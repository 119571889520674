import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import "./FailedRepayment.css";

// Select Action
import FailedRepaymentDetails from "./FailedRepaymentDetails/FailedRepaymentDetails";
import RetryFailedRepayment from "./RetryFailedRepayment/RetryFailedRepayment";

class FailedRepaymentModal extends Component {
  render() {
    const { properties } = this.props;
    const { action } = properties;

    return (
      <div className="Modal__dialog animated slideInDown" role="document">
        {(() => {
          switch (action) {
            case "details":
              return (
                <FailedRepaymentDetails
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "retry":
              return (
                <RetryFailedRepayment
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loansForDisbursement } = state.loansReducer;

  return {
    loansForDisbursement,
  };
};

export default connect(mapStateToProps)(FailedRepaymentModal);
