import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  StartDate: string;
  EndDate: string;
  GLParentId?: number;
};

type TResponse = {
  data: {
    data: {
      data: {
        balance: Number;
        currencyCode: string;
        glCode: string;
        glName: string;
        totalCredit: Number;
        totalDebit: number;
      }[];
      sum: {}[];
    };
  };
};

const getIncomeInterestReport = async ({
  StartDate,
  EndDate,
  GLParentId,
}: TQuery) => {
  let queryParams = `?StartDate=${StartDate}&EndDate=${EndDate}`;

  if (GLParentId) {
    queryParams = `${queryParams}&GLParentId=${GLParentId}`;
  }
  const data = await getData(
    `${ajaxEndpoints.INTEREST_INCOME_REPORT}${queryParams}`
  );
  return data;
};

export default function useIncomeInterestReport({
  StartDate,
  EndDate,
  GLParentId,
}: TQuery) {
  return useQuery<TResponse>(
    [
      {
        StartDate,
        EndDate,
        GLParentId,
      },
      "getIncomeInterestReport",
    ],
    getIncomeInterestReport
  );
}
