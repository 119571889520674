import "../../Issues/IssuesModal.css";
import RepaymentsApprovalLog from "../../../../components/RepaymentsApprovalLog/RepaymentsApprovalLog.component";

export default function ViewRepaymentApprovalTrail(props) {
  const { dataRecord, onCloseModal } = props;

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3 className="Modal__title p-b-2">
            Repayment id: <span>{dataRecord?.repayment_id}</span>
          </h3>

          <RepaymentsApprovalLog
            trans_ref={dataRecord?.makercheker_reference ?? 0}
          />

          <div className="Form__action-buttons mt-3">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={onCloseModal}
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
