import React, { useEffect, useState } from "react";
import { getCurrencyInfo } from "../../FunderInvestmentProduct/helper";
import { formatMoney } from "../../../helpers/formatter";
import useInvestorInvestmentSummary from "../../../custom-hooks/useInvestorInvestmentSummary";
import { Loader } from "../../../UI/Loaders/Loaders";
import {
  IFunderInvestmentSummaryResponse,
  InvestorWalletData,
  IWallet,
} from "../../../interfaces/funderInvestment";
import InvestorWalletView from "./Wallet/InvestorWalletView";
import { getDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { IResponse } from "../../../interfaces/response";

interface InvestorWalletProps {
  id: number;
}

// {
//   data: IFunderInvestmentSummaryResponse;
//   status: string;
//   error: string;
// }

const InvestorWallet: React.FC<InvestorWalletProps> = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletError, setWalletError] = useState<string | null>(null);
  const [walletList, setWalletList] = useState<IWallet[]>([]);

  const { data, status, error } = useInvestorInvestmentSummary(id);

  console.log("data: ", data);

  const getInvestorWalletData = (
    data: IResponse<IFunderInvestmentSummaryResponse> | undefined
  ): InvestorWalletData | null => {
    if (
      data &&
      data?.data?.investment_wallet_summary_details &&
      data?.data?.investment_wallet_summary_details?.length > 0
    ) {
      return {
        data: data?.data,
        localWalletBalance: formatMoney(
          data?.data?.investment_wallet_summary_details[0]?.walletBalance || 0,
          getCurrencyInfo(
            data?.data?.investment_wallet_summary_details[0]?.currencyCode
          )?.symbol || ""
        ),
        localWalletLink: {
          pathname: data?.data?.investment_wallet_summary_details[0]?.walletId
            ? `/investor/${data?.data?.funder_details.funder_id}/wallet`
            : undefined,
          state: {
            savingsWalletId:
              data?.data?.investment_wallet_summary_details[0]?.walletId,
            id,
            currency: "₦",
          },
        },
        foreignWalletBalance: formatMoney(
          data?.data?.investment_wallet_summary_details[1]?.walletBalance || 0,
          getCurrencyInfo(
            data?.data?.investment_wallet_summary_details[1]?.currencyCode
          )?.symbol || ""
        ),
        foreignWalletLink: {
          pathname: data?.data?.investment_wallet_summary_details[1]?.walletId
            ? `/investor/${data?.data?.funder_details.funder_id}/wallet`
            : undefined,
          state: {
            savingsWalletId:
              data?.data?.investment_wallet_summary_details[1]?.walletId,
            id,
            currency: "$",
          },
        },
      };
    } else {
      return null;
    }
  };

  const investorWalletData = getInvestorWalletData(data);

  const handleFetchWalletList = async () => {
    setIsLoading(true);
    try {
      const data = await getDataWithDotNet(
        `${ajaxEndpoints.GET_CUSTOMER_WALLETS}?customerId=${id}`
      );
      const { wallets } = data.data;

      setWalletList(wallets);
    } catch (error) {
      setWalletError("An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchWalletList();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader centered text="Loading ..." />
      ) : error || walletError ? (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> An error occurred please reload
            </h4>
          </div>
        </div>
      ) : investorWalletData ? (
        <InvestorWalletView
          data={investorWalletData.data}
          localWalletBalance={investorWalletData.localWalletBalance}
          foreignWalletBalance={investorWalletData.foreignWalletBalance}
          localWalletLink={investorWalletData.localWalletLink}
          foreignWalletLink={investorWalletData.foreignWalletLink}
          wallets={walletList}
          funderId={id}
          handleFetchWalletList={handleFetchWalletList}
        />
      ) : null}
    </div>
  );
};

export default InvestorWallet;
