import React, { Fragment, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import CustomSelectDropdown from "../../../NewComponents/TypedComponents/CustomHTMLElements/CustomSelectDropdown";
import { formatMoney } from "../../../helpers/formatter";
import useFailedInvestments from "../../../custom-hooks/useFailedInvestments";
import { Loader } from "../../../UI/Loaders/Loaders";
import { showModal, hideModal } from "../../../redux/actions/modal";
import { connect } from "react-redux";

interface FailedInvestmentsProps {}

const FailedInvestments: React.FC<FailedInvestmentsProps> = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const { data, isLoading, error } = useFailedInvestments({
    page: pageNumber,
    size,
  });

  return (
    <Fragment>
      <div>
        {successMessage && <Alert message={successMessage} type="success" />}
        {submitError && <Alert message={submitError} />}
        <div className="d-flex align-items-center justify-content-end mb-3">
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <div style={{ width: "150px", marginTop: "10px" }}>
              <CustomSelectDropdown
                onChange={(e: any) => {
                  setSize(e.target.value);
                }}
                value={size}
              >
                <option value={10000}>All</option>
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
        </div>
        {/*Datatable || Loader || Error Message*/}

        {isLoading ? (
          <Loader centered={true} text="Loading failed investments..." />
        ) : error ? (
          <div>
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" /> There was an issue while fetching
                failed investments, Please Reload The Page
              </h4>
            </div>
          </div>
        ) : !data?.data?.length && isLoading ? (
          <div className="centerLoader">
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" /> There are currently no failed
                investments.
              </h4>
            </div>
          </div>
        ) : (
          <div className="row m-x-sm m-y-2 grid__padding">
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>S/N</th>
                    <th>Funder ID</th>
                    <th>Amount</th>
                    <th>Interest Rate</th>
                    <th>Date</th>
                    <th>Tenor</th>
                    <th>Funding Channel</th>
                    <th>Repayment Type</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((response: any, _id: number) => {
                    const { id, investment } = response;
                    return (
                      <tr key={id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{investment?.funder_id}</td>
                        <td>
                          {formatMoney(
                            investment?.principal_amount,
                            investment?.currency
                          )}
                        </td>
                        <td>{investment?.interest_rate}</td>
                        <td>{investment?.effective_date?.substring(0, 10)}</td>
                        <td>
                          {investment?.tenure}{" "}
                          {investment?.tenure ? "days" : ""}
                        </td>
                        <td>{investment?.funding_channel}</td>
                        <td>{investment?.interest_repayment_type}</td>
                        <td className="flex-row">
                          <button
                            className="btn advancly-btn btn-sm transition-3d-hover"
                            type="button"
                            onClick={() =>
                              props.showModal({
                                modalIdentifier: "requests",
                                action: "failed_investment",
                                investmentId: id,
                                setSuccessMessage,
                                setSubmitError,
                                pageNumber,
                                size,
                              })
                            }
                          >
                            Retry
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Paginator
          size={size}
          page={pageNumber}
          count={data ? data.totalCount : 0}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </Fragment>
  );
};

export default connect(null, { showModal, hideModal })(FailedInvestments);
