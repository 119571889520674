import React, { Component } from "react";

class Search extends Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const value = event.target.value;
    onChange(value);
  };

  render() {
    let title = "";
    const { searchTerm, userType } = this.props;
    userType === "superadmin" ? (title = "Manage") : (title = "View");
    return (
      <div className="block-header center-txt m-b-30">
        {/*Search*/}
        <div className="row">
          <div className="col-lg-12 search-title">
            <h3 className="center-txt">
              <span className="rounder">
                <i className="fas fa-building" />
              </span>
              {title} Aggregators
            </h3>
          </div>
        </div>

        <div className="row m-b-30">
          <div className="col-md-4 center-elem">
            <div className="grid__full ">
              <div className="adv-form">
                <form name="form">
                  <div>
                    <div className="form-group inputWithIcon">
                      <input
                        type="text"
                        id="ecosystem"
                        name="ecosystem"
                        className="user__form form-control"
                        placeholder="Filter By Aggregator"
                        autoComplete="off"
                        required
                        value={searchTerm}
                        onChange={this.handleChange}
                      />
                      <i className="fas fa-search" aria-hidden="true" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
