import "./PageHeaders.css";

export const HeaderWithButton = ({ headerText, buttonText, onClick }: any) => {
  return (
    <div className="HeaderWithButton">
      <div className="d-flex justify-content-between mb-4">
        <h3 className="page-title">{headerText}</h3>
      </div>

      <div>
        <div className="relax-right animated fadeInRight">
          <button
            data-mode="close"
            className="create zoom-element btn login-btn"
            onClick={onClick}
          >
            <i className="fas fa-chevron-left"></i> &nbsp; {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
