// Loan Details
import React from "react";
import { formatMifosDate } from "../../../../../helpers/formatMifosDate";

export const LoanDetails = (props: any) => {
  const { details, loanDates } = props;
  return (
    // <div className="m-b-2">
    <React.Fragment>
      {props.loader && (
        <div
          id="adv-login-loader"
          className="loading-overlay speed-1x fadeInRight"
        >
          <div className="form-loader form-loader-login verify">
            <div className="centerLoader">
              <div className="loading" id="adv-loader-alt" />
              <div className="center-txt webapp-ripple-text">
                <i className="notifier fas fa-user-circle" />
                &nbsp; Loading mifos Details
              </div>
            </div>
          </div>
        </div>
      )}
      {!props.loader && (
        <div>
          <div className="row">
            <div className="col-xs-4">
              <label className="label-txt">Business Name</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    !props.loader ? props?.details?.clientName || "n/a" : ""
                  }
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Product Name</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  name="cardNumber"
                  className="user__form form-control"
                  value={
                    !props.loader
                      ? props?.details?.loanProductName || "n/a"
                      : ""
                  }
                />
                <i className="far fa-credit-card" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Interest (% per annum)</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    !props.loader
                      ? props?.details?.annualInterestRate || "n/a"
                      : ""
                  }
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <label className="label-txt">Loan Amount (Principal)</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    !props.loader
                      ? props?.mifosAmountDetails?.principalDisbursed || "n/a"
                      : ""
                  }
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Interest Amount</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    !props.loader
                      ? props?.mifosAmountDetails?.interestCharged || "n/a"
                      : ""
                  }
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Loan Repay Amount</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    !props.loader
                      ? props?.mifosAmountDetails?.totalExpectedRepayment ||
                        "n/a"
                      : ""
                  }
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-4">
              <label className="label-txt">Outstanding Principal</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    !props.loader
                      ? props?.mifosAmountDetails?.principalOutstanding || "n/a"
                      : ""
                  }
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Outstanding Interest</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    !props.loader
                      ? props?.mifosAmountDetails?.interestOutstanding || "n/a"
                      : ""
                  }
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Current Debt</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    !props.loader
                      ? props?.mifosAmountDetails?.totalOutstanding || "n/a"
                      : ""
                  }
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <label className="label-txt">Loan Tenure</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    !props.loader ? props?.details?.termFrequency || "n/a" : ""
                  }
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>

            <div className="col-xs-4">
              <label className="label-txt">Repayment Frequency </label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={details?.termPeriodFrequencyType?.value || "n/a"}
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Schedule Type</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={details?.interestType?.value || "n/a"}
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-4">
              <label className="label-txt">Disbursed Date</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  readOnly
                  value={
                    !props.loader
                      ? formatMifosDate(props?.loanDates?.approvedOnDate) ||
                        "n/a"
                      : ""
                  }
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Expected Maturity Date</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  name="cardNumber"
                  className="user__form form-control"
                  value={
                    formatMifosDate(loanDates?.expectedMaturityDate) || "n/a"
                  }
                />
                <i className="far fa-calendar-alt" />
              </div>
            </div>
            <div className="col-xs-4">
              <label className="label-txt">Status</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  readOnly
                  className="user__form form-control"
                  value={
                    !props.loader
                      ? props.details?.status?.active === true
                        ? "ACTIVE"
                        : props.details?.status?.pendingApproval === true
                        ? "Pending"
                        : props.details?.status?.pendingApproval &&
                          props.details?.status?.active !== true
                        ? "Denied"
                        : "n/a"
                      : ""
                  }
                />
                <i className="fas fa-user-circle" />
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
