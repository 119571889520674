import { TCurrencies, TCurrencyCodes } from "../interfaces/currencies";

type TCURRENCIES = {
  name: TCurrencies;
  code: TCurrencyCodes;
  symbol: string;
};

export const LOCAL_CURRENCY_CODE = "NGN";
export const USD_CURRENCY_CODE = "USD";
export const LOCAL_CURRENCY_NAME = "naira";
export const USD_CURRENCY_NAME = "dollar";

export const CURRENCIES: TCURRENCIES[] = [
  { name: LOCAL_CURRENCY_NAME, code: LOCAL_CURRENCY_CODE, symbol: "₦" }, // Always leave this as the first item in this array as it is the local currency
  { name: USD_CURRENCY_NAME, code: USD_CURRENCY_CODE, symbol: "$" },
];

export const getCurrencySymbolByCode = (code: TCurrencyCodes): string => {
  const match = CURRENCIES.find(
    (currency: TCURRENCIES) => currency.code === code
  );
  return match?.symbol || "";
};

export const getCurrencyNameByCode = (code: TCurrencyCodes): string => {
  const match = CURRENCIES.find(
    (currency: TCURRENCIES) => currency.code === code
  );
  return match?.name || "";
};
