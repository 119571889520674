import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  aggregator_id: string;
};

const getAggregatorActiveCurrencies = async ({ aggregator_id }: TQuery) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.AGGREGATOR_CURRENCIES}?aggregator_id=${aggregator_id}`
  );
  return data;
};

export default function useAggregatorActiveCurrencies({
  aggregator_id,
}: TQuery) {
  return useQuery(
    [{ aggregator_id }, "getAggregatorActiveCurrencies"],
    getAggregatorActiveCurrencies,
    {
      enabled: aggregator_id ? true : false,
    }
  );
}
