import { Link } from "react-router-dom";

import { Loader } from "../../../UI/Loaders/Loaders";
import useInvestorInvestmentSummary from "../../../custom-hooks/useInvestorInvestmentSummary";
import { formatMoney } from "../../../helpers/formatter";
import { resolveCurrencyCodeBadges } from "../../../helpers/resolveBadges";
import { getCurrencyInfo } from "../../FunderInvestmentProduct/helper";

import styles from "./InvestorsInvestment.module.scss";

interface IInvestorsInvestment {
  id: number;
}

const InvestorsInvestment = ({ id }: IInvestorsInvestment) => {
  const { data, status, error } = useInvestorInvestmentSummary(id);

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Investor's Investment
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading ..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investors's investment, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data &&
        data?.data &&
        data?.data?.funder_investment_products &&
        !data?.data?.funder_investment_products) ||
      (data &&
        data?.data &&
        data?.data?.funder_investment_products &&
        data?.data?.funder_investment_products.length === 0 &&
        data?.data?.investment_wallet_summary_details.length === 0)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Investment
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="datatable-wrapper">
          <div className=" speed-1x animated fadeInRight">
            <div className="row mt-4 px-3">
              {data &&
                data?.data?.investment_wallet_summary_details.length > 0 &&
                data?.data?.investment_wallet_summary_details.map(
                  (_summary_detail: any, index: number) => (
                    <div key={index} className="col-md-3 d-flex flex-column">
                      <div className={styles.rightBorder}>
                        <div className={styles.investmentTitle}>
                          Total Amount Invested{" "}
                          {getCurrencyInfo(_summary_detail?.currencyCode)?.name}
                        </div>
                        <div className={styles.investmentSubTitle}>
                          {formatMoney(
                            _summary_detail?.totalAmountInvested,
                            getCurrencyInfo(_summary_detail?.currencyCode)
                              ?.symbol
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
            <h5 className={`${styles.portfolio} mb-3 px-3`}>Portfolio</h5>
            {data &&
              data?.data?.funder_investment_products &&
              data?.data?.funder_investment_products.length > 0 &&
              data?.data?.funder_investment_products?.map(
                (request: any, _id: number) => {
                  const {
                    investment_product_name,
                    investment_product_id,
                    investor_lcy_currency_code,
                    investor_usd_currency_code,
                    currency_codes,
                  } = request;
                  const badges = resolveCurrencyCodeBadges([
                    { code: investor_usd_currency_code },
                    { code: investor_lcy_currency_code },
                  ]);
                  return (
                    <div
                      className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                      key={investment_product_id}
                    >
                      <div
                        className={`${styles.investmentLayoutCard} zoom-element`}
                      >
                        <div className={styles.title}>
                          {investment_product_name}
                        </div>
                        <div className={styles.content}>
                          {/* <small className="color-grey mb-2"></small> */}
                        </div>
                        <div className="currency-badges">
                          {badges.map((item, i) => (
                            <div style={{ zIndex: badges.length - i }} key={i}>
                              {item}
                            </div>
                          ))}
                        </div>
                        <hr />
                        <div className={styles.link}>
                          <Link
                            to={{
                              pathname: `/investor/${id}/investment`,
                              state: {
                                id,
                                data,
                                investment_product_id,
                                investment_product_name,
                                currency_codes,
                                investment_wallet_summary_details:
                                  data?.data?.investment_wallet_summary_details,
                              },
                            }}
                            className="color-blue"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      );
    }
  }

  return <div>{RenderedComponent}</div>;
};

export default InvestorsInvestment;
