import React, { useState } from "react";
import ApplyRolloverConfirmation from "./ApplyRolloverConfirmation";
import ApplyRollover from "./ApplyRollover";

const ApplyRolloverMain = (props: any) => {
  const {
    loan_tenure,
    loan_amount,
    loan_interest,
    loan_effective_date,
    loan_repayment_balance,
    aggregator_details,
    loan_ref,
  } = props?.properties?.dataRecord?.loanDetails;
  const { onCloseModal } = props;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [rolloverInputTenure, setRolloverInputTenure] = useState<string | null>(
    null
  );
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="PartnerModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          {(() => {
            switch (currentStep) {
              case 1:
                return (
                  <ApplyRollover
                    setCurrentStep={setCurrentStep}
                    rolloverInputTenure={rolloverInputTenure}
                    setRolloverInputTenure={setRolloverInputTenure}
                    loanTenure={loan_tenure}
                    loanAmount={loan_amount}
                    loanInterest={loan_interest}
                    loanEffectiveDate={loan_effective_date}
                    loanRepaymentBalance={loan_repayment_balance}
                    aggregatorDetails={aggregator_details}
                  />
                );

              case 2:
                return (
                  <ApplyRolloverConfirmation
                    setCurrentStep={setCurrentStep}
                    rolloverInputTenure={rolloverInputTenure}
                    setRolloverInputTenure={setRolloverInputTenure}
                    onCloseModal={onCloseModal}
                    loanRef={loan_ref}
                    aggregatorDetails={aggregator_details}
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default ApplyRolloverMain;
