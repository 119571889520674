import {SHOW_MODAL, HIDE_MODAL} from './../actions/actionTypes';

export const showModal = (modalProperties) => { 
    
    return function(dispatch) {
        dispatch({  type: SHOW_MODAL, 
                    payload: modalProperties
                });
    };
}

export const hideModal  = () => {
    return function(dispatch) {
        dispatch({ type: HIDE_MODAL});
    };
}