import React, { useState } from "react";

// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { getAllAggregators } from "../../../../custom-hooks/useAllAggregators";
import CustomSearchDropDown from "../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";

const PendingApproval = (props: any) => {
  const {
    onCloseModal,
    properties: { setPreState, preState },
  } = props;

  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    borrowerName: "",
    loanId: "",
    bvn: "",
    rcNumber: "",
    aggregatorId: "",
    loanRef: "",
  });

  const setSearchParams = (e: any) => {
    e.preventDefault();
    onCloseModal();
    setPreState(state);
  };

  const handleInvestorChange = (record: any) => {
    setState((prevState) => {
      return {
        ...prevState,
        aggregatorId: record.value,
      };
    });
  };

  const getOptions = (query: any) => {
    return new Promise((resolve, reject) => {
      getAllAggregators({ searchValue: query })
        .then((data) => {
          resolve(
            data.map(
              ({
                aggregator_id,
                biz_name,
              }: {
                aggregator_id: number;
                biz_name: string;
              }) => ({
                value: aggregator_id,
                label: biz_name,
              })
            )
          );
        })
        .catch(reject);
    });
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        <React.Fragment>
          <h3 className="Modal__title p-b-1">Search By:</h3>
          {/* 2. Confirm Update Role Modal*/}
          <div id="disbursement-confirm">
            <div className="m-b-30">
              <form>
                <div className="row">
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Borrower Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, borrowerName: e.target.value });
                      }}
                      defaultValue={state.borrowerName || preState.borrowerName}
                      name="borrowerName"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Loan Id:</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, loanId: e.target.value });
                      }}
                      defaultValue={state.loanId || preState.loanId}
                      name="loanId"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">BVN:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, bvn: e.target.value });
                      }}
                      defaultValue={state.bvn || preState.bvn}
                      name="bvn"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Rc Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, rcNumber: e.target.value });
                      }}
                      defaultValue={state.rcNumber || preState.rcNumber}
                      name="rcNumber"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Loan Reference:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, loanRef: e.target.value });
                      }}
                      defaultValue={state.loanRef || preState.loanRef}
                      name="loanRef"
                    />
                  </div>

                  <div className="col-md-3 col-sm-12 mt-2">
                    <CustomSearchDropDown
                      isShowLabel
                      label="Aggregator:"
                      loadOptions={getOptions}
                      onChange={handleInvestorChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Created Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, startDate: e.target.value });
                      }}
                      defaultValue={state.startDate || preState.startDate}
                      name="startDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Created End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setState({ ...state, endDate: e.target.value });
                      }}
                      min={state.startDate || preState.startDate}
                      defaultValue={state.endDate || preState.endDate}
                      name="endDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                </div>
                <div className="Form__action-buttons m-t-2">
                  <button
                    type="submit"
                    onClick={(e) => setSearchParams(e)}
                    className="btn zoom-element btn login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Filter
                  </button>

                  <button
                    type="button"
                    onClick={onCloseModal}
                    className="btn zoom-element btn login-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* 2. Disbursement Loader*/}
          <div
            id="disbursement-loader"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className=" form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier fas fa-user-cog" />
                  Searching ...
                </div>
              </div>
            </div>
          </div>

          {/* 3. Disbursement Success Message*/}
          <div
            id="disbursement-success"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className="form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading success-loader">
                  <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                </div>
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier far fa-check-circle" />
                  Search Successful
                </div>
              </div>
            </div>
          </div>

          {/* 4. Disbursement Error */}
          <div
            id="disbursement-error"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <ErrorLoader
              icon="fas fa-wifi"
              text="Network Issue"
              subText="Unable to connect to Advancly services."
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default PendingApproval;
