import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getAuditTrailTypes = async () => {
  const data = await getData(ajaxEndpoints.PRODUCT_AUDITS_TYPE);
  return data.data;
};

export default function useAuditTrailTypes() {
  return useQuery(["getAuditTrailTypes"], getAuditTrailTypes);
}
