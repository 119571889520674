import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";

// Ajax End Points
import ajaxApi from "../../api/axios-register";

// Local Storage Helpers
import { setUserInfo } from "../../helpers/setUserInfo";
import {
  deleteFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../helpers/localStorage";
import { appInsights } from "../../config/appInsights";

//Ajax Auth Handler
export const authAjax = (loginData: any) => {
  return (dispatch: any) => {
    //Ajax Auth Loader Init
    dispatch({
      type: actionTypes.AUTH_START,
    });

    ajaxMethods
      .post(ajaxEndpoints.AUTH, loginData)
      .then((response) => {
        // const response = res.data;
        // let {expiresIn, token, userData} = response;
        // const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
        if (response) {
          if (response.status === 400 || response.status === 401) {
            deleteFromLocalStorage();
            dispatch({
              type: actionTypes.AUTH_ERROR,
              payload: {
                error_code: response.data.status_code,
                message: response.data.message,
              },
            });
          } else if (response.status === 200) {
            const { token } = response.data;
            setUserInfo(response.data);
            dispatch({
              type: actionTypes.AUTH_SUCCESS,
              payload: {
                token,
                response: response.data,
              },
            });
          } else {
            deleteFromLocalStorage();
            dispatch({
              type: actionTypes.AUTH_NETWORK_FAILURE,
              payload: {
                message: "Network Failure",
              },
            });
          }
        } else {
          deleteFromLocalStorage();
          dispatch({
            type: actionTypes.AUTH_NETWORK_FAILURE,
            payload: {
              message: "Network Failure",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "auth.js",
          },
        });
        deleteFromLocalStorage();
        // Failed Onboarding API call
        dispatch({
          type: actionTypes.AUTH_ERROR,
          payload: error,
        });
      });
  };
};

//Ajax Auth Logout Handler
export const authLogout = () => {
  let currentUserToken = getTokenFromLocalStorage();

  return (dispatch: any) => {
    // Log User Out from Client
    deleteFromLocalStorage();
    dispatch({ type: actionTypes.AUTH_LOGOUT });

    // Log User Out From Server
    if (currentUserToken) {
      ajaxMethods
        .post(
          ajaxEndpoints.LOGOUT,
          {},
          { headers: { Authorization: "Token " + currentUserToken } }
        )
        .then((response) => {
          if (JSON.stringify(response.message) === "Logout successfully") {
            deleteFromLocalStorage();
            dispatch({ type: actionTypes.AUTH_LOGOUT });
          } else {
            deleteFromLocalStorage();
            dispatch({ type: actionTypes.AUTH_LOGOUT });
          }
        })
        .then(() => delete ajaxApi.defaults.headers.common["Authorization"])
        .catch((error) => {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "auth.js",
            },
          });
          //Log User Our from Client
          deleteFromLocalStorage();
          if (window) {
            window.location.href = "/login";
          }

          dispatch({ type: actionTypes.AUTH_LOGOUT });
        });
    }
  };
};

//Ajax Auth Update Handler
export const updateAuthenticated = (isAuthenticated: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.UPDATE_AUTHENTICATED,
      payload: isAuthenticated,
    });
  };
};
