import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TQuery = {
  investRateId: number;
  enteredPrincipalAmount: number;
  tenure: number;
  tenantId: number;
  depositType: any;
};

export const getInvestmentProductRate = async ({
  investRateId,
  enteredPrincipalAmount,
  tenure,
  tenantId,
  depositType,
}: TQuery) => {
  let queryParam = `?ProductId=${investRateId}&Amount=${enteredPrincipalAmount}&Tenure=${tenure}&TenantId=${tenantId}&depositType=${depositType}`;
  const data = await getData(
    `${ajaxEndpoints.INVESTMENT_PRODUCT_RATE}${queryParam}`
  );
  return data?.data;
};

export default function useInvestmentProductRate({
  investRateId,
  enteredPrincipalAmount,
  tenure,
  tenantId,
  depositType,
}: TQuery) {
  return useQuery(
    [
      {
        investRateId,
        enteredPrincipalAmount,
        tenure,
        tenantId,
        depositType,
      },
      "getInvestmentProductRate",
    ],
    getInvestmentProductRate,
    {
      enabled:
        investRateId !== 0 && enteredPrincipalAmount !== 0 && tenure !== 0,
    }
  );
}
