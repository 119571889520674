import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getWalletDetails = async (id: string) => {
  const data = await getData(
    `${ajaxEndpoints.GET_AGGREGATOR_WALLET_DETAILS}?aggregatorid=${id}`
  );
  return data.data;
};

export default function useWalletDetails(id: string) {
  return useQuery([id, "getWalletDetails"], getWalletDetails, {
    enabled: id ? true : false,
    refetchOnWindowFocus: false,
  });
}
