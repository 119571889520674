import React, { Fragment } from "react";
import ModalDetailsRow from "../../../../components/ModalDetailsRow/ModalDetailsRow.component";

export default function ViewUser({ dataRecord, onCloseModal }) {
  const printHandler = () => {};
  const {
    user: {
      dateJoined,
      email,
      firstName,
      lastLogin,
      lastName,
      phone,
      userType,
      username,
    },
  } = dataRecord;
  return (
    <Fragment>
      <h3 className="Modal__title p-b-2 text-center">User Information</h3>
      {/* AuditTrail Modal */}
      <form>
        <ModalDetailsRow
          label="Email"
          value={email}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="First Name"
          value={firstName}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="Last Name"
          value={lastName}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="User Name"
          value={username}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="User Type"
          value={userType}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="Phone Number"
          value={phone}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="Date Joined"
          value={dateJoined && dateJoined.substring(0, 10)}
          icon="fas fa-user-circle"
        />
        <ModalDetailsRow
          label="Last Login"
          value={`${lastLogin && lastLogin.substring(0, 10)} / ${
            lastLogin && lastLogin.substring(11, 19)
          }`}
          icon="fas fa-user-circle"
        />

        <div className="Form__action-buttons m-t-1">
          <button
            type="button"
            className="btn zoom-element btn login-btn m-0 mr-auto"
            onClick={printHandler}
          >
            <i className="fas fa-print"></i>
            &nbsp; Print
          </button>
          <button
            type="button"
            className="btn zoom-element btn login-btn m-0 ml-auto"
            onClick={onCloseModal}
            data-dismiss="modal"
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Go Back
          </button>
        </div>
      </form>
    </Fragment>
  );
}
