import CustomBadge from "../Badge/badge";

export const renderBadge = (item) => {
  if (typeof item === "string") {
    return <CustomBadge text={item} />;
  } else if (Array.isArray(item)) {
    return (
      item &&
      item.length &&
      item.map((value, index) => <CustomBadge key={index} text={value} />)
    );
  } else {
    return null;
  }
};
