import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

export const getAllAggregators = async (queryParams) => {
  let data = null;
  if (queryParams) {
    data = await getDataWithDotNet(
      `${ajaxEndpoints.ECOSYSTEMS}?${new URLSearchParams(queryParams)}`
    );
  } else {
    data = await getDataWithDotNet(ajaxEndpoints.ECOSYSTEMS);
  }

  return data.data;
};

export default function useAllAggregators(queryParams) {
  return useQuery([queryParams, "getAllAggregators"], getAllAggregators, {
    refetchOnWindowFocus: false,
  });
}
