import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { appInsights } from "../../../../../config/appInsights";
import useFunderDetails from "../../../../../custom-hooks/useFunderDetails";
import { showModal } from "../../../../../redux/actions/modal";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../../helpers/formatter";
import { Link, useRouteMatch } from "react-router-dom";
import Paginator from "../../../../Pagination/Pagination.component";
import {
  useReferredCustomerProspectiveInvestments,
  useReferredCustomerRealInvestments,
} from "../../../../../custom-hooks/useDebtFund";

const ReferralsProspectiveInvestment = () => {
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    startDate: "",
    endDate: "",
    searchValue: "",
    aggregatorId: "",
  });

  const [count, setCount] = useState<number | undefined>(0);

  const dispatch = useDispatch();
  useSelector((state: RootState) => state?.authReducer?.loggedInUser);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investors  - (ReferralsProspectiveInvestment.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: paginatedData,
    status,
    error,
  } = useReferredCustomerProspectiveInvestments({
    ...preState,
    id,
  });

  const { data = [], totalCount = 0 } = paginatedData || {};

  if (data) {
    if (count === null) {
      setCount(totalCount);
    }
  }
  const resetTable = () => {
    setCount(0);
    setPreState({ ...state, pageNumber: 1 });
  };
  const modalHandler = (modalData: any, type: string) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      }),
    );
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate).valueOf() - new Date(state.startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Investors
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Investments..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              investment prospect, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (data && data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no investment
              prospect.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone Number</th>
                  <th>Email Address</th>
                  <th>Country</th>
                  <th>Date Indicated</th>
                  <th>Interest Level</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((request, index) => {
                    const {
                      email,
                      dateCreated,
                      phoneNumber,
                      lastname,
                      firstname,
                      currencyCode,
                      readinessLevel,
                      amount,
                    } = request;
                    return (
                      <tr key={index}>
                        <td>
                          {(preState.pageNumber - 1) * preState.size +
                            (index + 1)}
                        </td>
                        <td>{firstname || "N/A"}</td>
                        <td>{lastname}</td>
                        <td>{phoneNumber}</td>
                        <td>{email}</td>
                        <td>{currencyCode}</td>
                        <td>{dateCreated?.substring(0, 10)}</td>
                        <td>{readinessLevel}</td>
                        <td>{formatMoney(Number(amount) || Number(100000))}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          {RenderedComponent}
          {data?.length > 0 && (
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber: number) =>
                setPreState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralsProspectiveInvestment;
