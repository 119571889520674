import React from "react";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";

interface AlertsProps {
  successMessage?: string;
  submitError?: string;
}
const RequestsAlert = ({ successMessage, submitError }: AlertsProps) => {
  return (
    <>
      {successMessage && <Alert message={successMessage} type="success" />}
      {submitError && <Alert message={submitError} />}
    </>
  );
};

export default RequestsAlert;
