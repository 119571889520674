import { useEffect, useState } from "react";
// import { settingsStart, getSettingsSuccess, settingsFailure } from '../../../../../../../src/redux/actions/settings'

// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";

import SystemForm from "./Forms/SystemForm";
import RevenueForm from "./Forms/RevenueForm";
import { productStatus } from "../../../../../../models/product-data-model";

import DefaultProductSettings from "./Forms/DefaultProductSettings";
import { appInsights } from "../../../../../../config/appInsights";

// Style
import "./GlobalSettings.css";

const GlobalSettings = ({ onSetToast }: { onSetToast: any }) => {
  const [state, setState] = useState<any>({
    revenueEdit: false,
    systemEdit: false,
    loading: false,
    revenueForm: {
      aggregator_share: 0,
      funder_share: 0,
      platform_share: 0,
      default_bvn_provider: 1,
      default_non_nigerian_id_provider: 0,
      first_repayment_NotificationDays: 0,
      maximum_number_of_rollover: 0,
      maximum_rollover_percentage: 0,
      principal_repayment_percentage: 0,
      second_repayment_NotificationDays: 0,
      emails: "",
      loan_notification_recipients: "",
      usd_liquidation_channel_account_id: "",
      usd_liquidation_channel_id: "",
      funder_usd_wallet_product_id: "",
      funder_lcy_wallet_product_id: "",
    },
    revenueFormCopy: {
      aggregator_share: 0,
      funder_share: 0,
      platform_share: 0,
      default_bvn_provider: 1,
      default_non_nigerian_id_provider: 0,
      first_repayment_NotificationDays: 0,
      maximum_number_of_rollover: 0,
      maximum_rollover_percentage: 0,
      principal_repayment_percentage: 0,
      second_repayment_NotificationDays: 0,
      emails: "",
      loan_notification_recipients: "",
      usd_liquidation_channel_account_id: "",
      usd_liquidation_channel_id: "",
      funder_usd_wallet_product_id: "",
      funder_lcy_wallet_product_id: "",
    },
    systemForm: {
      categoryList: [],
      category_name: "",
      category_code: "",
    },
    systemFormCopy: {
      categoryList: [],
      category_name: "",
      category_code: "",
    },
    disbursementChannelOptions: [],
    selectedDisbursementId: "",
    bankId: "",
    accountNumber: "",
    bankOptionsList: [],
    disburseEdit: false,
    bankName: "",
    allproductStatusChoices: [],
    statusOption: "",
    ProductCatergorySectors: [],
    productCategoryId: "",
  });

  useEffect(() => {
    populateCategories();
    getRevenueSettings();
    getProductStatusChoices();
    getProductSector();
  }, []);

  // Component Methods
  const getRevenueSettings = () => {
    ajaxMethods
      .get(ajaxEndpoints.GLOBAL_REVENUE_SETTINGS)
      .then((response) => {
        if (response) {
          setState((prev: any) => {
            return {
              ...prev,
              revenueForm: response.data,
              revenueFormCopy: response.data,
            };
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GlobalSettings.js",
          },
        });
      });
  };

  const editModeHandler = (target: any) => {
    setState((prev: any) => {
      return { ...prev, bankId: "", accountNumber: "" };
    });
    let selectedForm = target.dataset["form"];
    if (state.selectedDisbursementId) {
      getDisbursementChannelDetails();
    }

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: true,
      };
    });
  };

  const inputChangeHandler = (target: any) => {
    const { name, value, dataset, type } = target;
    let selectedForm = dataset["form"];
    if (name === "default_bvn_provider") {
      setState((prev: any) => {
        return {
          ...prev,
          [`revenueFormCopy`]: {
            ...state[`revenueFormCopy`],
            [name]: parseInt(value),
          },
        };
      });
    }
    if (name === "secondary_bvn_provider") {
      setState((prev: any) => {
        return {
          ...prev,
          [`revenueFormCopy`]: {
            ...state[`revenueFormCopy`],
            [name]: parseInt(value),
          },
        };
      });
    }
    if (name === "funder_lcy_wallet_product_id") {
      setState((prev: any) => {
        return {
          ...prev,
          [`revenueFormCopy`]: {
            ...state[`revenueFormCopy`],
            [name]: parseInt(value),
          },
        };
      });
    }
    if (name === "funder_usd_wallet_product_id") {
      setState((prev: any) => {
        return {
          ...prev,
          [`revenueFormCopy`]: {
            ...state[`revenueFormCopy`],
            [name]: parseInt(value),
          },
        };
      });
    } else if (
      type === "radio" &&
      name === "default_non_nigerian_id_provider"
    ) {
      setState((prev: any) => {
        return {
          ...prev,
          [`${selectedForm}FormCopy`]: {
            ...state[`${selectedForm}FormCopy`],
            [name]: parseInt(value),
          },
        };
      });
    } else {
      setState((prev: any) => {
        return {
          ...prev,
          [`${selectedForm}FormCopy`]: {
            ...state[`${selectedForm}FormCopy`],
            [name]: value,
          },
        };
      });
    }
  };

  const cancelUpdateHandler = (target: any) => {
    let selectedForm = target.dataset["form"];

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: false,
        [`${selectedForm}FormCopy`]: state[`${selectedForm}Form`],
      };
    });
  };

  const onSettingsUpdate = (target: any) => {
    let selectedForm = target.dataset["form"];
    const { ...data } = state[`${selectedForm}FormCopy`];
    const newData = {
      ...data,
      first_repayment_NotificationDays: Number(
        data.first_repayment_NotificationDays
      ),
      principal_repayment_percentage: Number(
        data.principal_repayment_percentage
      ),
      maximum_number_of_rollover: Number(data.maximum_number_of_rollover),
      maximum_rollover_percentage: Number(data.maximum_rollover_percentage),
      second_repayment_NotificationDays: Number(
        data.second_repayment_NotificationDays
      ),
      usd_liquidation_channel_account_id: Number(
        data.usd_liquidation_channel_account_id
      ),
      usd_liquidation_channel_id: Number(data.usd_liquidation_channel_id),
    };
    if (selectedForm === "revenue") {
      ajaxMethods
        .post(ajaxEndpoints.GLOBAL_REVENUE_SETTINGS_UPDATE, newData)
        .then((response) => {
          if (response.status === 200) {
            return onSetToast("success", response?.data?.message);
          } else if (response.status === 400) {
            return onSetToast(
              "error",
              `There was an issue updating revenue settings: ${response.data.message}`
            );
          } else {
            return onSetToast(
              "warning",
              "There was an issue updating revenue settings"
            );
          }
        })
        .catch((error) => {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "GlobalSettings.js",
            },
          });
          return onSetToast(
            "error",
            "There was an issue updating revenue settings"
          );
        });

      setState((prev: any) => {
        return {
          ...prev,
          [`${selectedForm}Edit`]: false,
        };
      });
    }
  };

  const populateCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.CATEGORY_LIST)
      .then((response) => {
        let categoryList = [] as any[];

        if (response.data.length > 0) {
          categoryList = [...response.data];
        } else {
          categoryList = [
            {
              category_id: 0,
              category_name: "No Categories Yet",
              category_slug: "No Categories Yet",
            },
          ];
        }

        setState((prev: any) => {
          return {
            ...prev,
            systemEdit: false,
            systemForm: {
              ...state.systemForm,
              categoryList: [...categoryList],
            },
            systemFormCopy: {
              ...state.systemFormCopy,
              categoryList: [...categoryList],
            },
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GlobalSettings.js",
          },
        });
      });
  };

  const addCategoryHandler = () => {
    const category = {
      name: state.systemFormCopy.category_name,
      code: state.systemFormCopy.category_code,
    };

    ajaxMethods
      .post(ajaxEndpoints.CATEGORY_ADD, category)
      .then((response) => {
        let message = "";
        if (response.status === 201 || response.status === 200) {
          message = response.data.message;
          onSetToast("success", message);
          populateCategories();
        } else if (response.status === 400) {
          message = response.data.message;
          onSetToast("error", message);
        } else {
          onSetToast("warning", "Unable to connect to Advancly services");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GlobalSettings.js",
          },
        });
        onSetToast("warning", "Unable to connect to Advancly services");
      });
  };

  // fetch Disbursement channel details
  const getDisbursementChannelDetails = () => {
    ajaxMethods
      .get(
        ajaxEndpoints.GET_DISBURSEMENT_CHANNEL_LIST +
          `?disbursement_channel_id=${state.selectedDisbursementId}`
      )
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];
          setState((prev: any) => {
            return {
              ...prev,
              bankId: res.bank_id,
              accountNumber: res.account_number,
              bankName: res.bank_name,
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GlobalSettings.js",
          },
        });
      });
  };

  const handleOptionSelect = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  //get product Status choices
  const getProductStatusChoices = () => {
    const allproductStatusChoices = productStatus.map((ecosystem, index) => {
      const { productStatusName, status } = ecosystem;
      return (
        <option key={index} id={status} value={status}>
          {productStatusName}
        </option>
      );
    });
    setState((prev: any) => {
      return {
        ...prev,
        allproductStatusChoices: allproductStatusChoices,
      };
    });
  };

  // fetch product category list
  const getProductSector = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_PRODUCT_CATEGORY_SECTORS)
      .then((response) => {
        if (response.status_code === 200) {
          setState((prev: any) => {
            return { ...prev, ProductCatergorySectors: response.data };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GlobalSettings.js",
          },
        });
      });
  };
  // handle create default products
  const handleCreateDefaultProduct = () => {
    const { statusOption, productCategoryId } = state;
    const data = {
      status: statusOption,
      product_category_id: productCategoryId,
    };
    ajaxMethods
      .post(ajaxEndpoints.CREATE_DEFAULT_PRODUCT, data)
      .then((response) => {
        if (response.status === 200) {
          return onSetToast(
            "success",
            `You have succefully updated your revenue settings`
          );
        } else if (response.status === 400) {
          return onSetToast(
            "error",
            `There was an issue updating revenue settings: ${response.data.message}`
          );
        } else {
          return onSetToast(
            "warning",
            "There was an issue updating revenue settings"
          );
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GlobalSettings.js",
          },
        });
        return onSetToast(
          "error",
          "There was an issue updating revenue settings"
        );
      });
  };

  return (
    <div className="GlobalSettings mt-30">
      <div className="row col-12">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <RevenueForm
            inputs={state.revenueFormCopy}
            onEditMode={editModeHandler}
            onCancelUpdate={cancelUpdateHandler}
            onInputChange={inputChangeHandler}
            revenueEdit={state.revenueEdit}
            onRevenueUpdate={onSettingsUpdate}
          />
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12">
          <SystemForm
            inputs={state.systemFormCopy}
            onEditMode={editModeHandler}
            onCancelUpdate={cancelUpdateHandler}
            onInputChange={inputChangeHandler}
            onAddCategory={addCategoryHandler}
            systemEdit={state.systemEdit}
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <DefaultProductSettings
            handleOptionSelect={handleOptionSelect}
            handleCreateDefaultProduct={handleCreateDefaultProduct}
            allproductStatusChoices={state.allproductStatusChoices}
            ProductCatergorySectors={state.ProductCatergorySectors}
            productCategoryId={state.productCategoryId}
            statusOption={state.statusOption}
          />
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
