import React, { Component } from 'react';
import { AggregatorCard } from './../../../UI/Cards/Cards';

class Results extends Component {
    render() {
        const { item, miniView} = this.props;
        return (
            <div 
                data-id={item.pool_id} 
                className="col-xl-2 col-lg-2 col-md-3 col-sm-6 animated fadeInDown"
                data-mode = 'view'
                data-name = {item.biz_name}
                onClick={()=> item.is_active === true ?  miniView(item): ''}>
                        
                {/*Result*/}
                <AggregatorCard record={ item }/>
            </div>
        );
    }
}

export default Results;
