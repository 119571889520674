import { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// Router
import { NavLink, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import useLoanRepaymentReport from "../../custom-hooks/useLoanRepaymentReport";
import { showModal } from "../../redux/actions/modal";

// UI Components
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { formatMoney } from "../../helpers/formatter";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import useAllAggregators from "../../custom-hooks/useAllAggregators";
import { BASE_URL } from "../../appConstants";
import { getTokenFromLocalStorage } from "../../helpers/localStorage";
import { appInsights } from "../../config/appInsights";
import { RootState } from "../../redux/store";

const today = new Date().toISOString().slice(0, 10);
const lastMonth = new Date(new Date());
lastMonth.setDate(lastMonth.getDate() - 30);
const aMonthAgo = lastMonth.toISOString().slice(0, 10);

const LoanRepaymentReport = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(10);

  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [date, setDate] = useState({
    FromDate: aMonthAgo,
    ToDate: today,
  });
  const [aggregatorState, setAggregatorState] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { isAuthenticated, loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Repayment Report - (LoanRepaymentReport.js)",
      isLoggedIn: true,
    });
  }, []);

  //  Methods
  const modalHandler = (modalData: any, type: any) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };
  const handleDownload = () => {
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (startDate && endDate) {
      queryParams = `?FromDate=${startDate}&ToDate=${endDate}`;
    }
    if (aggregatorState) {
      queryParams = queryParams
        ? `${queryParams}&AggregatorId=${aggregatorState}`
        : `?AggregatorId=${aggregatorState}`;
    }

    fetch(BASE_URL + `/eco/repayment_schedule_report/csv${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        setDownloadLoading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                startDate && endDate
                  ? `LoanInvestmentReport from ${startDate} to ${endDate}.csv`
                  : `Loan_Investment_Report.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LoanRepaymentReport.js",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  const resetTable = () => {
    setPageNumber(1);
  };

  const filterData = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const diffTime =
      new Date(endDate).valueOf() - new Date(startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    }

    resetTable();
    setDate({
      FromDate: startDate,
      ToDate: endDate,
    });
  };

  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error } = useLoanRepaymentReport({
    FromDate: date.FromDate,
    ToDate: date.ToDate,
    Page: pageNumber,
    PageSize: size,
    AggregatorId: Number(aggregatorState),
  });
  const { data: aggregatorData } = useAllAggregators();

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Repayment Reports
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Repayment Reports..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              repayment reports, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data.data && data.data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Aggregator Name</th>
                <th>Loan Id</th>
                <th>Loan Reference</th>
                <th>Product Name</th>
                <th>Borrower Name</th>
                {/* <th>Currency</th> */}
                <th>Principal</th>
                <th>Interest</th>
                <th>Penalty</th>
                <th>Principal Paid</th>
                <th>Interest Paid</th>
                <th>Penalty Paid</th>
                <th>Amount Due</th>
                <th>Installment</th>
                {/* <th>Date Made</th> */}
                <th>Due Date</th>
                <th>Days Past Due</th>
                {/* <th>Fees</th> */}
                {/* <th>Fees Paid</th> */}
                <th>Is Paid</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                data?.data &&
                data?.data?.data.length &&
                data.data.data.map((loanRepaymentReport, _id: number) => {
                  const {
                    aggregatorName,
                    loanId,
                    loanRef,
                    productName,
                    borrowerName,
                    currency,
                    principal,
                    interest,
                    penalty,
                    principalPaid,
                    interestPaid,
                    penaltyPaid,
                    amountToRepay,
                    installment,
                    // dateMade,
                    dateDue,
                    daysOverdue,
                    // fee,
                    // feesPaid,
                    isPaid,
                  } = loanRepaymentReport;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{aggregatorName}</td>
                      <td>{loanId}</td>
                      <td>{loanRef}</td>
                      <td>{productName}</td>
                      <td>{borrowerName}</td>
                      {/* <td>{currency}</td> */}
                      <td>
                        {principal
                          ? formatMoney(principal, currency)
                          : principal}
                      </td>
                      <td>
                        {interest ? formatMoney(interest, currency) : interest}
                      </td>
                      <td>{penalty}</td>
                      <td>
                        {principalPaid
                          ? formatMoney(principalPaid, currency)
                          : principalPaid}
                      </td>

                      <td>
                        {interestPaid
                          ? formatMoney(interestPaid, currency)
                          : interestPaid}
                      </td>
                      <td>{penaltyPaid}</td>
                      <td>
                        {amountToRepay
                          ? formatMoney(amountToRepay, currency)
                          : amountToRepay}
                      </td>
                      {/* amount to Repay is changed on table to amount Due */}
                      <td>{installment}</td>
                      {/* <td>{dateMade}</td> */}
                      <td>{dateDue.substring(0, 10)}</td>
                      <td>{daysOverdue}</td>
                      {/* Days overdue is changed on table head to Days past due */}
                      {/* <td>{fee}</td> */}
                      {/* <td>{feesPaid}</td> */}
                      <td>{!isPaid ? "Not Paid" : " Paid"}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }
  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Repayment Reports Header*/}
        <div className="block-header">
          <h3 className={`page-title`}>Loan Repayment Reports</h3>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
              <label htmlFor="table-fetch" className="form-group">
                Number of results:{" "}
                <select
                  onChange={(e) => {
                    resetTable();
                    setSize(Number(e.target.value));
                  }}
                  id="table-fetch"
                  className="table-fetch-select m-l-1"
                  value={size}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>

            <form className="d-flex flex-row m-t-md" onSubmit={filterData}>
              <div>
                <CustomSelectDropdown
                  onChange={(e) => setAggregatorState(e.target.value)}
                  name="AggregatorId"
                  value={aggregatorState}
                  className="round user__form form-control"
                >
                  <option value="">search by aggregator</option>
                  {aggregatorData &&
                    aggregatorData.map((aggregator: any) => {
                      const { biz_name, aggregator_id } = aggregator;
                      return (
                        <option
                          value={Number(aggregator_id)}
                          key={aggregator_id}
                        >
                          {biz_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>
              <div
                className="d-flex flex-row flex-wrap justify-content-start"
                style={{ alignItems: "flex-end" }}
              >
                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">Start Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={aMonthAgo}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">End Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={today}
                    max={today}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <button
                  className="m-l-1 m-r-1 zoom-element btn login-btn"
                  type="submit"
                  value="submit"
                >
                  Search
                </button>
                {/* Start of download button */}
                <button
                  type="button"
                  className="btn advancly-white-btn  "
                  onClick={handleDownload}
                  disabled={status === "loading" || downloadLoading}
                >
                  <i className="fas fa-download m-r-1" />
                  Download {downloadLoading && <ButtonLoader />}
                </button>

                {/* end of download button */}
              </div>
            </form>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={data?.data?.totalCount}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </div>
  );
};

export default LoanRepaymentReport;
