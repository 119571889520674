import React from "react";
import styles from "../CreditBureau.module.scss";

const CreditBureauTableCreditScoreDetails = ({ creditScoreDetails }: any) => {
  return (
    <div>
      <h5 className={` ${styles.creditBureauHeading}`}>Credit Score Details</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Credit Rating
            </th>
            <th className="th-td-style th-style" scope="col">
              Credit Score
            </th>
            <th className="th-td-style th-style" scope="col">
              Reason 1
            </th>
            <th className="th-td-style th-style" scope="col">
              Reason 2
            </th>
            <th className="th-td-style th-style" scope="col">
              Reason 3
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="th-td-style ">
              {creditScoreDetails?.crediT_SCORE_SUMMARY?.crediT_RATING}
            </td>
            <td className="th-td-style ">
              {creditScoreDetails?.crediT_SCORE_SUMMARY?.crediT_SCORE}
            </td>
            <td className="th-td-style ">
              {creditScoreDetails?.crediT_SCORE_SUMMARY?.reasoN_CODE1}
            </td>
            <td className="th-td-style ">
              {creditScoreDetails?.crediT_SCORE_SUMMARY?.reasoN_CODE2}
            </td>
            <td className="th-td-style ">
              {creditScoreDetails?.crediT_SCORE_SUMMARY?.reasoN_CODE3}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableCreditScoreDetails;
