import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData, getDataWithDotNet } from "../newApis/dotNetApiMethods";
import { IPaginatedResponse, IResponse } from "../interfaces/response";

type TQuery = {
  pageNumber: number;
  size: number;
  consumerType?: string;
  aggregatorId?: number;
};

type TWallet = {
  accountName: string;
  accountNumber: string;
  bankName: string;
  consumerType: string;
  currency: string;
  id: number;
  isDefault: boolean;
  targetId: number;
  userId: number;
  walletId: string;
  walletProvider: string;
  walletProviderId: number;
};

const getAllWallet = async ({
  pageNumber,
  size,
  consumerType,
  aggregatorId,
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&TargetId=${aggregatorId}`;
  }
  const { data } = await getData(
    `${ajaxEndpoints.GET_ALL_WALLET}?${queryParams}`
  );

  return data;
};

export default function useAllWallet({
  pageNumber,
  size,
  consumerType,
  aggregatorId,
}: TQuery) {
  return useQuery<IPaginatedResponse<TWallet[]>>(
    [{ pageNumber, size, consumerType, aggregatorId }, "getAllWallet"],
    getAllWallet
  );
}
