import { useLayoutEffect, useState } from "react";

import useRolesByEmail from "../../../../custom-hooks/useRolesByEmail";
import { Loader } from "../../../../UI/Loaders/Loaders";
import CustomAccordion from "./CustomAccordion/CustomAccordion";

export default function UpdateUserRole({ dataRecord, onCloseModal }) {
  const [preselectedPermissions, setPreSelectedPermissions] = useState([]);
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);

  const {
    action,
    data: {
      user: { id, email },
      rolesData,
    },
  } = dataRecord;

  const {
    data: currentlyAssignedRoles,
    status,
    error: rolesError,
  } = useRolesByEmail({
    email,
    refreshData: Date.now(),
  });

  useLayoutEffect(() => {
    if (currentlyAssignedRoles && currentlyAssignedRoles?.roles.length > 0) {
      setPreSelectedPermissions(currentlyAssignedRoles?.roles);
    }
  }, [currentlyAssignedRoles]);

  useLayoutEffect(() => {
    const _categories = Object.keys(rolesData).map((_item) => {
      return {
        category: _item,
        roles: rolesData[_item],
      };
    });

    setCategories(_categories);
  }, [rolesData]);

  const updateUserRoles = (e) => {
    e.preventDefault();
    if (!preselectedPermissions.length) {
      setError("You have to select at least one role!");
      const element = document.getElementById("roles-error-element");
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      userID: id,
      roleName: preselectedPermissions,
    };
    onCloseModal();
    setTimeout(() => {
      action(data);
    }, 100);
  };

  const onChange = (e, name) => {
    if (e.target.checked) {
      const newRoles = [...preselectedPermissions, name];
      setPreSelectedPermissions(newRoles);
    } else {
      const newRoles = preselectedPermissions.filter((role) => role !== name);
      setPreSelectedPermissions(newRoles);
    }
  };

  if (status === "loading")
    return (
      <Loader centered={true} text={`Loading user's current role(s)...`} />
    );

  return (
    <div className="" style={{ height: "70vh", overflowY: "scroll" }}>
      <h3 className="Modal__title p-b-2">Update User's Roles</h3>

      <span id="roles-error-element">
        {error && (
          <h5 className="error-element p-b-2 text-center font-weight-bold">
            {error || JSON.stringify(rolesError)}
          </h5>
        )}
      </span>

      {categories &&
        categories.length > 0 &&
        categories.map((_item, index) => (
          <CustomAccordion
            key={index}
            onChange={onChange}
            preselectedPermissions={preselectedPermissions}
            count={10}
            title={_item?.category}
            roles={_item?.roles}
          />
        ))}

      <div className=" m-t-1">
        <button
          type="submit"
          className="btn zoom-element btn login-btn m-0 ml-auto"
          onClick={updateUserRoles}
          data-dismiss="modal"
        >
          <i className="fas fa-arrow-right"></i>
          &nbsp; Update User
        </button>
      </div>
    </div>
  );
}
