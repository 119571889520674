export const attachClassNameToTag = (element) => {
  const item = element ? element.toLowerCase() : "";
  if (item === "closed") {
    return "closed";
  } else if (item === "approved") {
    return "approved";
  } else if (item === "submitted_and_awaiting_approval") {
    return "pending";
  } else if (item === "withdrawnbyclient") {
    return "rejected";
  } else if (item === "writtenoff") {
    return "writtenoff";
  } else if (item === "rescheduled ") {
    return "rescheduled";
  } else if (item === "overpaid") {
    return "overpaid";
  } else if (item === "active") {
    return "active";
  } else if (item === "inactive") {
    return "inactive";
  } else if (item === "repaid") {
    return "repaid";
  } else if (item === "success") {
    return "success";
  } else if (item === "pending") {
    return "pending";
  } else if (item === "due") {
    return "due";
  } else if (item === "overdue") {
    return "overdue";
  } else if (item === "error") {
    return "error";
  } else if (item === "failed") {
    return "failed";
  } else if (item === "rejected") {
    return "rejected";
  } else if (item === "matured") {
    return "matured";
  } else if (item === "declined") {
    return "declined";
  } else if (item === "creditofficer") {
    return "creditofficer";
  } else if (item === "customer") {
    return "customer";
  } else {
    return "";
  }
};
