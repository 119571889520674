import React, { ChangeEvent, ReactNode, Ref } from "react";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler.component";

interface ICustomSelectDropdown {
  name: string;
  reference: React.LegacyRef<HTMLSelectElement>;
  children: any;
  label: string;
  errors: any;
  value: any;
  onChange: any;
  showRequiredIcon: boolean;
  extraLabel: string;
  disabled: boolean;
  defaultValue: string | number | undefined;
  className: string;
}

interface CustomSelectDropdownProps {
  name: string;
  reference?: Ref<HTMLSelectElement>;
  children: ReactNode;
  label: string;
  errors?: any; // Replace 'any' with a more specific error type if available
  value?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  showRequiredIcon?: boolean;
  extraLabel?: string;
  disabled?: boolean;
  defaultValue?: string;
  className?: string;
}

const CustomSelectDropdown: React.FC<CustomSelectDropdownProps> = ({
  name,
  reference,
  children,
  label,
  errors,
  value,
  onChange,
  showRequiredIcon,
  extraLabel,
  disabled,
  defaultValue,
  className,
}: Partial<ICustomSelectDropdown>) => {
  return (
    <div className="mb-4">
      <div className="form-group">
        <div className="d-flex align-items-center">
          <label className="form-label">{label}</label>
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel && extraLabel.length > 0 && (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          )}
        </div>
        <select
          className={`form-control ${className || ""}`}
          name={name}
          ref={reference}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
        >
          {children}
        </select>
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
};

export default CustomSelectDropdown;
