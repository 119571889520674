import React, { useEffect, useState } from "react";
import { errorHandler } from "../../../../../helpers/errorHandler";
import {
  handleSwalErrorAlert,
  handleSwalSuccessAlert,
  logAppInsightErrors,
} from "../../../../../helpers";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { postDataWithDotNet } from "../../../../../newApis/dotNetApiMethods";

interface Props {
  id: number;
  setShowAddCommentUI?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvestmentProspectMessageTab = ({ id, setShowAddCommentUI }: Props) => {
  const [message, setMessage] = useState("");
  const [isFormValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Validate form whenever the message changes
  useEffect(() => {
    // Basic validation: check that message is not empty
    // and that message is no longer than 300 words
    const messageWords = message.trim().split(/\s+/); // split by whitespace
    const isValid = message.length > 0 && messageWords.length <= 300;
    setFormValid(isValid);
  }, [message]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.GET_INVESTMENT_PROSPECT}/${id}/comment`,
        {
          comment: message,
        },
      );
      handleSwalSuccessAlert(
        response.data?.message ?? response?.message,
        setShowAddCommentUI ? () => setShowAddCommentUI(false) : undefined,
      );
    } catch (error) {
      handleSwalErrorAlert(errorHandler(error));
      logAppInsightErrors(errorHandler(error), "InvestmentProspectMessageTab");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="col-md-6">
      <form onSubmit={handleSubmit}>
        {/*<div>
          <label htmlFor="title">Title</label>
          <input
            className="user__form form-control"
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <br />*/}
        <div>
          <label htmlFor="message">Message</label>
          <textarea
            className="user__form form-control"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter Message here"
            required
          />
        </div>
        <div>
          <small>This field is limited to 300 words</small>
        </div>
        <br />

        <button
          className="btn advancly-btn"
          type="submit"
          disabled={!isFormValid}
        >
          {isLoading ? (
            <span className="spinner-border text-white" />
          ) : (
            "Send Message"
          )}
        </button>
      </form>
    </div>
  );
};

export default InvestmentProspectMessageTab;
