import React from "react";

// import Dropzone from 'react-dropzone';
import { MDBDataTable } from "mdbreact";

// // Ajax End Points
// import * as ajaxEndpoints from "../../../../../../../api/ajax-endpoints";

// import ajaxApi from '../../../../../../../api/axios-register';

// import { AggregatorsProduct, reOrderRows, modifyProductTableRows, filterDataByColumns } from '../../../../../../../redux/actions/Product';
// import { setToast } from "../../../../../../../redux/actions/toast";
import { Loader } from "../../../../../../UI/Loaders/Loaders";

export const ProductsTable = (props) => {
  const { loading, tableData, recordName, error } = props;

  return (
    <div>
      {loading && <Loader text="Loading....." background={true} />}
      {!loading && Object.keys(tableData).length === 0 && (
        <div className="centerLoader m-b-3">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No {recordName} found
            </h4>
          </div>
        </div>
      )}
      {!loading && Object.keys(tableData).length === null && (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" />
              Unable to Fetch Records
            </h4>
          </div>
        </div>
      )}
      {!loading &&
        (Object.keys(tableData).length === null ||
          Object.keys(tableData).length === undefined) && (
          <div className="centerLoader">
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" />
                There was an issue fetching {recordName} records, Please Reload
                The Page
              </h4>
            </div>
          </div>
        )}
      {error && (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" />
              {error}
            </h4>
          </div>
        </div>
      )}
      {!loading && Object.keys(tableData).length > 0 && (
        <div>
          {/*Product category sector*/}

          <div className="grid__padding animated speed-1x fadeInRight">
            <MDBDataTable
              responsive
              minimal="true"
              striped
              bordered
              hover
              data={tableData}
            />
          </div>
        </div>
      )}
    </div>
  );
};
