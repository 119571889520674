import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getBankAccountDetails = async (customerId: number) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_BANK_ACCOUNT_DETAILS}?customerId=${customerId}`
  );
  return data;
};

export default function useBankAccountDetails(customerId: number) {
  return useQuery(
    [customerId, "getBankAccountDetails"],
    getBankAccountDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
