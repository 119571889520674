import React, { useEffect, useState } from "react";
import { appInsights } from "../../../../../config/appInsights";
import Tabs from "../../../../../components/Tabs/Tabs";
import GenerateCreditBureauReport from "./GenerateCreditBureauReport";

const CreditBureauReportMain = () => {
  const [activeTab, setActiveTab] = useState("generatecreditreport");

  const tabs = [
    {
      name: "Generate Credit Bureau Report",
      id: "generatecreditreport",
      icon: "fas fa-credit-card",
      identifier: "settingsTab",
    },
  ];

  useEffect(() => {
    appInsights.trackPageView({
      name: "Credit Bureau Report Page - (CreditBureauReportMain.tsx)",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className="datatable-wrapper">
      <div className="table-view animated speed-1x fadeInRight">
        {/* Tabs */}
        <Tabs onContentToggle={(e: any) => setActiveTab(e)} tabs={tabs} />

        <div className="row clearfix grid__padding">
          {/*Datatables*/}
          {(() => {
            switch (activeTab) {
              case "generatecreditreport":
                return <GenerateCreditBureauReport />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default CreditBureauReportMain;
