import React, { Component } from 'react';

import './IssuesModal.css';

export default class IssueModal extends Component {
    state={ scene: 'form' };

    printHandler = () =>{
    }

    cancelHandler = () =>{ 
    }

    componentDidMount(){}

    render() {

        const {scene} = this.state;

        const { dataRecord } = this.props.properties;

        const {
            issue_id, 
            creator,  
            issue_type, 
            issue_msg, 
            user_type,
            pub_date,
            issue_status
        } = dataRecord;

        return (
            <div className="Modal__dialog animated slideInDown" role="document">
                <div className="IssueModal Modal__content" style={{overflow: 'hidden'}}>
                    <div className="Modal__body">
                        <h3 className="Modal__title p-b-2">Issue id: <span>{issue_id}</span></h3>
                        {/* Investment Form */}
                        {
                            (() => {
                                switch(scene){
                                    case 'form':
                                        return(
                                            <form>
                                                <label className="label-txt">Reported by</label>
                                                <div className="form-group inputWithIcon">
                                                    <input                                    
                                                        type="text"
                                                        className="user__form form-control"
                                                        readOnly
                                                        value={creator}/>
                                                    <i className="far fa-calendar-alt"/>
                                                </div>

                                                <label className="label-txt">Role</label>
                                                <div className="form-group inputWithIcon">
                                                    <input                                    
                                                        type="text"
                                                        readOnly
                                                        name="cardNumber"
                                                        className="user__form form-control"
                                                        value={user_type}/>
                                                    <i className="far fa-credit-card"/>
                                                </div>

                                                <label className="label-txt">Issue Title</label>
                                                <div className="form-group inputWithIcon">
                                                    <input                                    
                                                        type="text"
                                                        className="user__form form-control"
                                                        readOnly                
                                                        value={issue_type}/>
                                                        <i className="fas fa-user-circle"/>
                                                </div>

                                                <label className="label-txt">description</label>
                                                <div className="form-group inputWithIcon">
                                                    <input                                    
                                                        type="text"
                                                        className="user__form form-control"
                                                        readOnly
                                                        value={issue_msg}/>
                                                        <i className="far fa-calendar-alt"/>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xs-6">
                                                        <label className="label-txt">Reported Date</label>
                                                        <div className="form-group inputWithIcon">
                                                            <input                                    
                                                                type="text"
                                                                className="user__form form-control"
                                                                readOnly
                                                                value={pub_date}/>
                                                            <i className="far fa-calendar-alt"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-6">
                                                        <label className="label-txt">Status</label>
                                                        <div className="form-group inputWithIcon">
                                                            <input                                    
                                                                type="text"
                                                                readOnly
                                                                className="user__form form-control"
                                                                value={issue_status}/>
                                                            <i className="fas fa-user-circle"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="Form__action-buttons">
                                                        <button type="button" 
                                                                className="btn zoom-element btn login-btn m-0 mr-auto" 
                                                                onClick={this.proceedHandler}>Print</button>
                                                        <button type="button" 
                                                                className="btn zoom-element btn login-btn m-0 ml-auto" 
                                                                onClick={this.props.onCloseModal} 
                                                                data-dismiss="modal">Go Back</button>
                                                    </div>
                                            </form>
                                        );
                                    case 'loader':
                                        return(                  
                                            <div>
                                            {/* 2. login Form Loader */}
                                            <div id="adv-login-loader"
                                                className="loading-overlay speed-1x fadeInRight">
                                                <div className="form-loader form-loader-login verify">
                                                    <div className="centerLoader">
                                                        <div className="loading" id="adv-loader-alt"/>

                                                        <div className="center-txt webapp-ripple-text">
                                                            <i className="notifier fas fa-user-circle"/>
                                                            &nbsp; Processing your transaction
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        );

                                    case 'success':
                                        return (
                                            <div>
                                                {/* 3. Login Response */}
                                                <div id="adv-login-response" className="loading-overlay speed-1x fadeInRight">
                                                    <div className="form-loader form-loader-login verify">
                                                        <div className="centerLoader">
                                                            <div className="loading success-loader">
                                                                <i className="webapp-ripple-notification success-text fa fa-check-circle"/>
                                                            </div>
                                                            <div className="center-txt webapp-ripple-text">
                                                                Logged In Successfully
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                    case 'fail':
                                        return (
                                            <div>
                                                {/* 3. Login Response */}
                                                <div id="adv-login-response" 
                                                        className="loading-overlay speed-1x fadeInRight">
                                                    <div className="form-loader form-loader-login verify">
                                                        <div className="centerLoader">
                                                            <div className="loading success-loader">
                                                                <i className="webapp-ripple-notification success-text fa fa-close-circle"/>
                                                            </div>
                                                            <div className="center-txt webapp-ripple-text">
                                                                Login Failed, try again later
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                    default:
                                        return null;
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
        )
    }
}