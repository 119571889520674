import { useQuery } from "react-query";

import * as ajaxEndpoints from "../api/ajax-endpoints";
import { TCompanyWalletTransactionsParams } from "../components/Wallet/types";
import { getData } from "../newApis/dotNetApiMethods";

const getCompanyWalletTransactions = async ({
  AccountNumber,
  ConsumerType,
  StartDate,
  EndDate,
  Page,
  PageSize,
}: TCompanyWalletTransactionsParams) => {
  let queryParams = `Page=${Page}&PageSize=${PageSize}&ConsumerType=${ConsumerType}&AccountNumber=${AccountNumber}`;

  if (StartDate) {
    queryParams = `${queryParams}&StartDate=${StartDate}`;
  }
  if (EndDate) {
    queryParams = `${queryParams}&EndDate=${EndDate}`;
  }

  const data = await getData(
    `${ajaxEndpoints.GET_COMPANY_WALLET_TRANSACTIONS}?${queryParams}`
  );
  return data;
};

export default function useCompanyWalletTransactions({
  AccountNumber,
  ConsumerType,
  StartDate,
  EndDate,
  Page,
  PageSize,
}: TCompanyWalletTransactionsParams) {
  return useQuery(
    [
      { AccountNumber, ConsumerType, StartDate, EndDate, Page, PageSize },
      "getCompanyWalletTransactions",
    ],
    getCompanyWalletTransactions
  );
}
