import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { setToast } from "../../../../redux/actions/toast";
import { removeDeniedLoans } from "../../../../redux/actions/loans";

// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { appInsights } from "../../../../config/appInsights";

class Reject extends Component {
  state = {
    comment: "",
    auth: "",
  };

  onCommentChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // Deny Single Loans
  denyLoan = (e) => {
    e.preventDefault();
    const params = {
      loan_refs: [this.props.properties.dataRecord.loan_ref],
      comment: this.state.comment,
      otp: this.state.auth,
    };

    ajaxMethods
      .post(ajaxEndpoints.REJECT_MULTIPLE_PENDING_DISBURSEMENT, params)
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            this.props.setToast("success", response.data.data.message);
            this.props.properties.result();
            this.props.onCloseModal();
          } else if (response.status === 400 || response.status === 401) {
            this.props.setToast("warning", response.data.message);
            this.props.properties.result();
            this.props.onCloseModal();
          } else {
            this.props.setToast(
              "warning",
              "Unable to connect to Advancly services"
            );
            this.props.properties.result();
            this.props.onCloseModal();
          }
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RejectMakerChecker.js",
          },
        });
        this.props.setToast(
          "warning",
          "Unable to connect to Advancly services"
        );
        this.props.properties.result();
        this.props.onCloseModal();
      });
  };

  render() {
    const { borrower_name, loan_amount, loan_id } =
      this.props.properties.dataRecord;

    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            Loan ID: <span>{loan_id}</span>
          </h3>
          {/* 2. Confirm Update Role Modal*/}
          <div id="update-role-confirm">
            <div className="m-b-30">
              <h5>Are you sure you want to deny the following loan? </h5>
              <p>Borrower Name: {`${borrower_name}`}</p>
              <p>Loan Amount: {loan_amount}</p>
              <form>
                <label style={{ width: "100%" }}>
                  Comment
                  <input
                    className="form-control"
                    name="comment"
                    value={this.state.comment}
                    onChange={this.onCommentChange}
                    placeholder="Reason for denying this loan"
                  />
                </label>
                <label style={{ width: "50%" }}>
                  Generated 2FA code
                  <input
                    className="form-control"
                    name="auth"
                    value={this.state.auth}
                    onChange={this.onCommentChange}
                  />
                </label>
                <div className="Form__action-buttons m-t-2">
                  <button
                    type="submit"
                    data-mode="disburse"
                    data-id={loan_id}
                    disabled={!this.state.comment}
                    onClick={this.denyLoan}
                    className="btn zoom-element btn login-btn m-0 mr-auto"
                  >
                    <i className="fa fa-check-circle" /> Proceed
                  </button>
                  <button
                    type="button"
                    onClick={this.props.onCloseModal}
                    className="btn zoom-element btn login-btn m-0 ml-auto"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* 3. Updating Role Loader*/}
          <div
            id="update-role"
            style={{ display: "none" }}
            className=" loading-overlay speed-1x fadeInRight"
          >
            <div className=" form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier fas fa-user-cog" />
                  Denying Loan Request ...
                </div>
              </div>
            </div>
          </div>

          {/* 4. Update Role Success Message*/}
          <div
            id="update-role-success"
            style={{ display: "none" }}
            className="loading-overlay speed-1x fadeInRight"
          >
            <div className="form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading success-loader">
                  <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                </div>
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier far fa-check-circle" />
                  Loan Request Denied
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    disbursements: { records },
  } = state.loansReducer;

  return {
    records,
  };
};

export default connect(mapStateToProps, { setToast, removeDeniedLoans })(
  Reject
);
