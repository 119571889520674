import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./GraphSubTitle.module.scss";

interface IGraphSubTitle {
  text: string;
  to: string;
}

const GraphSubTitle: FC<IGraphSubTitle> = ({ text, to }) => {
  return (
    <div className={styles.alignGraphSubTitle}>
      <div className={styles.leftSection}>{text}</div>
      <div className={styles.rightSection}>
        {/* <Link to={to}>See More</Link> */}
      </div>
    </div>
  );
};

export default GraphSubTitle;
