import React, { Fragment } from "react";

export default function ToggleTab({
  text,
  id,
  activeTab,
  setActiveTab,
  isClickable = true,
}) {
  if (isClickable) {
    return (
      <Fragment>
        <div
          className={`tabItem ${activeTab === id && "activeTab"}`}
          onClick={() => setActiveTab(id)}
        >
          {text}
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div
          className={`tabItem ${activeTab === id && "activeTab"}`}
          style={{ cursor: "default" }}
        >
          {text}
        </div>
      </Fragment>
    );
  }
}
