import { getReportData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

interface ITrialBalanceReport {
  startDate: string;
  endDate: string;
  tenantId: string;
}
const getTenants = async () => {
  const data = await getReportData(ajaxEndpoints.GET_TENANTS);
  return data.data;
};

const getTrialBalanceReport = async ({
  startDate,
  endDate,
  tenantId,
}: ITrialBalanceReport) => {
  let queryParams = `?startDate=${startDate}&endDate=${endDate}&tenantId=${tenantId}`;
  const data = await getReportData(
    `${ajaxEndpoints.GET_TRIAL_BALANCE}${queryParams}`
  );
  return data.data;
};

export default function useTenants() {
  return useQuery(["getTenants"], getTenants);
}

export function useTrialBalanceReport({
  startDate,
  endDate,
  tenantId,
}: ITrialBalanceReport) {
  return useQuery(
    [
      {
        startDate,
        endDate,
        tenantId,
      },
      "getTrialBalanceReport",
    ],
    getTrialBalanceReport
  );
}
