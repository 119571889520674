import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getPendingInvestmentProducts = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.GET_PENDING_INVESTMENT_DETAILS
  );
  return data;
};

export default function usePendingInvestmentProducts() {
  return useQuery(
    ["getPendingInvestmentProducts"],
    getPendingInvestmentProducts,
    {
      refetchOnWindowFocus: false,
    }
  );
}
