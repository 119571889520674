import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";
import { IFunderInvestmentSummaryResponse } from "../interfaces/funderInvestment";

const getInvestorInvestmentSummary = async (id: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.INVESTORS_INVESTMENT_SUMMARY}?funderId=${Number(id)}`
  );

  return data;
};

export default function useInvestorInvestmentSummary(id: number) {
  return useQuery<IResponse<IFunderInvestmentSummaryResponse>>(
    [id, "getInvestorInvestmentSummary"],
    getInvestorInvestmentSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
}
