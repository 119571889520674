import React, { Component, useEffect, useState } from "react";
// import { settingsStart, getSettingsSuccess, settingsFailure } from '../../../../../../../src/redux/actions/settings'

// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";

// Forms
import ChannelForm from "./Form/ChannelForm";
import DisbursementChannelAccountForm from "./Form/DisbursementChannelAccountForm";
import {
  disbursedAccountColumns,
  reOrderTableData,
} from "../../../../../../redux/actions/loans";
import { appInsights } from "../../../../../../config/appInsights";

const ChannelSettings = (props: any) => {
  const [state, setState] = useState<any>({
    loading: false,
    disbursementChannelOptions: [],
    selectedDisbursementId: "",
    bankId: "",
    currency: "",
    currencyOptionsList: [],
    accountNumber: "",
    bankOptionsList: [],
    disburseEdit: false,
    bankName: "",
    error: "",
    accountName: "",
    accountLoading: false,
    selectedDisbursementIdSearch: "",
    disbursementChannelList: [],
    channelAccountsDetails: [],
    reOrderedTableData: [],
    isDursementChanelDojah: false,
  });

  useEffect(() => {
    getDisbursementChannels();
    getBankList();
    getCurrency();
  }, []);

  // Component Methods
  const editModeHandler = (target: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        bankId: "",
        accountNumber: "",
      };
    });
    let selectedForm = target.dataset["form"];
    // if(state.selectedDisbursementId){
    //     getDisbursementChannelDetails()

    // }

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: true,
      };
    });
  };

  const cancelUpdateHandler = (target: any) => {
    let selectedForm = target.dataset["form"] as string;

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: false,
        [`${selectedForm}FormCopy`]: state[`${selectedForm}Form`],
        accountName: "",
      };
    });
  };

  const handleDisburseSelect = (e: any) => {
    if (e.target.value === "5") {
      setState((prev: any) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          disburseEdit: false,
          isDursementChanelDojah: true,
        };
      });
    } else {
      setState((prev: any) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          disburseEdit: false,
          isDursementChanelDojah: false,
        };
      });
    }
  };

  const handleOptionSelect = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // fetch Disbursement channel list
  const getDisbursementChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_DISBURSEMENT_CHANNEL_LIST)
      .then((response) => {
        let disbursementChannelList = [] as any[];

        if (response.data.length > 0) {
          disbursementChannelList = [...response.data];
        } else {
          disbursementChannelList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const disbursementChannelListOptions = disbursementChannelList.map(
          (ecosystem, index) => {
            const { id, channel_name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {channel_name}
              </option>
            );
          }
        );

        setState((prev: any) => {
          return {
            ...prev,
            disbursementChannelOptions: disbursementChannelListOptions,
            disbursementChannelList: disbursementChannelList,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
      });
  };

  // fetch accounts for a specific disburse channel
  const getDisbursementChannelAccounts = () => {
    setState((prev: any) => {
      return {
        ...prev,
        loading: true,
        channelAccountsDetails: [],
        reOrderedTableData: [],
      };
    });
    ajaxMethods
      .get(
        ajaxEndpoints.GET_DISBURSEMENT_ACCOUNTS +
          `?disbursement_channel_id=${state.selectedDisbursementIdSearch}`
      )
      .then((response) => {
        setState((prev: any) => {
          return {
            ...prev,
            loading: false,
          };
        });
        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            setState((prev: any) => {
              return {
                ...prev,
                channelAccountsDetails: response.data,
              };
            });
            const reOrderedTableData = reOrderTableData(
              disbursedAccountColumns,
              response.data
            );
            setState((prev: any) => {
              return {
                ...prev,
                reOrderedTableData: reOrderedTableData as any,
              };
            });
          } else {
            setState((prev: any) => {
              return {
                ...prev,
                channelAccountsDetails: [],
              };
            });
          }
        } else {
          setState((prev: any) => {
            return {
              ...prev,
              channelAccountsDetails: [],
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
      });
  };

  //get bank list
  const getBankList = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let bankList = response.data;
          const bankOptionsList = bankList.map((data: any, index: number) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            );
          });
          setState((prev: any) => {
            return {
              ...prev,
              bankOptionsList: bankOptionsList,
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
      });
  };

  const getCurrency = () => {
    ajaxMethods
      .get(ajaxEndpoints.CURRENCIES)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let currencyList = response.data;

          const currencyOptions = currencyList.map(
            (data: any, index: number) => {
              return (
                <option value={data.name} key={index}>
                  {data.name}
                </option>
              );
            }
          );
          setState((prev: any) => {
            return {
              ...prev,
              currencyOptionsList: currencyOptions,
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
      });
  };
  //create disbursement channel accounts
  const onCreateDisbursementChannelAccount = () => {
    const {
      selectedDisbursementId,
      bankId,
      accountNumber,
      accountName,
      currency,
    } = state;
    let disburse;
    if (selectedDisbursementId === "5") {
      disburse = {
        channel_id: Number(selectedDisbursementId),
        currency: currency,
      };
    } else {
      disburse = {
        channel_id: Number(selectedDisbursementId),
        bank_id: Number(bankId),
        account_number: accountNumber,
        account_name: accountName,
      };
    }

    ajaxMethods
      .post(ajaxEndpoints.ADD_DISBURSEMENT_CHANNEL_ACCOUNT, disburse)
      .then((response) => {
        let message = "";
        if (
          response.status_code === 200 ||
          response.status === 200 ||
          response.data.status === 200 ||
          response?.data?.status_code === 201 ||
          response?.status_code === 201 ||
          response?.status === 201 ||
          response?.data?.status === 201
        ) {
          message = response.data.message;
          props.onSetToast("success", message);
          setState({ disburseEdit: false });
        } else if (response.status === 400) {
          message = response.data.message;
          props.onSetToast("error", message);
        } else {
          props.onSetToast("warning", "Unable to connect to Advancly services");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
        //
        props.onSetToast("warning", "Unable to connect to Advancly services");
      });
  };
  // validate account number
  const onAccountNumberInput = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        error: "",
        accountName: "",
      };
    });
    let number = e.target.value;
    if (state.bankId === "") {
      setState((prev: any) => {
        return {
          ...prev,
          error: `Please select a bank`,
        };
      });
      return;
    }

    setState({ accountNumber: number });
  };

  useEffect(() => {
    if (state?.accountNumber) {
      getBankCode();
      if (state?.accountNumber === 10 && !state?.accountLoading) {
        setState((prev: any) => {
          return {
            ...prev,
            accountLoading: true,
          };
        });

        //validate account number and bank name
        let data = {
          bank_code: state.bankCode,
          bank_account_num: state?.accountNumber,
        };
        validateAccount(data);
      }
    }
  }, [state?.accountNumber]);
  // fetch product category list
  const getBankCode = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${state.bankId}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];

          setState((prev: any) => {
            return {
              ...prev,
              bankCode: res.code,
            };
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
      });
  };

  const validateAccount = (data: {
    bank_code: string;
    bank_account_num: string;
    country_code?: string;
  }) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setState((prev: any) => {
            return {
              ...prev,
              accountName: response.data.bank_account_name,
              accountLoading: false,
            };
          });
          // response.json()
          //   .then(res => {
          //   })
        } else if (response.status === 400) {
          setState((prev: any) => {
            return {
              ...prev,
              error: response.data.message,
              accountLoading: false,
            };
          });
        }
        if (response.status >= 500) {
          setState((prev: any) => {
            return {
              ...prev,
              error: "Unable to connect to Advancly services.",
              accountLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ChannelSettings.js",
          },
        });
      });
  };

  const handleChange = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        error: false,
      };
    });
  };

  return (
    <div className="GlobalSettings col-sm-12 mt-30">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <ChannelForm
            inputs={state.systemFormCopy}
            onEditMode={editModeHandler}
            onCancelUpdate={cancelUpdateHandler}
            onInputChange={handleChange}
            disburseEdit={state.disburseEdit}
            disbursementChannelOptions={state.disbursementChannelOptions}
            selectedDisbursementId={state.selectedDisbursementId}
            bankId={state.bankId}
            error={state.error}
            accountLoading={state.accountLoading}
            accountName={state.accountName}
            accountNumber={state.accountNumber}
            bankOptionsList={state.bankOptionsList}
            handleOptionSelect={handleOptionSelect}
            handleDisburseSelect={handleDisburseSelect}
            onCreateDisbursementChannelAccount={
              onCreateDisbursementChannelAccount
            }
            onAccountNumberInput={onAccountNumberInput}
            isDursementChanelDojah={state.isDursementChanelDojah}
            currency={state.currency}
            currencyOptionsList={state.currencyOptionsList}
          />
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          <DisbursementChannelAccountForm
            disbursementChannelOptions={state.disbursementChannelOptions}
            selectedDisbursementIdSearch={state.selectedDisbursementIdSearch}
            handleOptionSelect={handleOptionSelect}
            disbursementChannelList={state.disbursementChannelList}
            channelAccountsDetails={state.channelAccountsDetails}
            reOrderedTableData={state.reOrderedTableData}
            loading={state.loading}
            onSearch={getDisbursementChannelAccounts}
          />
        </div>
      </div>
    </div>
  );
};

export default ChannelSettings;
