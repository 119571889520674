import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// Select Action
import Details from "./Details/Details";
import Deny from "./Deny/Deny";
import Disburse from "./Disburse/Disburse";
import ViewAllLoans from "./View/ViewAllLoans";
import ViewMifosLoans from "./Mifos/ViewMifosLoans";

import "./LoanModal.css";
import GetLoanRecordsModal from "./GetLoanRecords/GetLoanRecordsModal";
import CustomRange from "./CustomRange/CustomRange";
import RepayLoan from "./Repay/RepayLoan";
import GetPendingDisbursementRecordsModal from "./GetPendingDisbursementRecordsModal/GetPendingDisbursementRecordsModal";
import ViewApprovalTrail from "./View/ViewApprovalTrail";
import AdvanceSearch from "./AdvanceSearch/AdvanceSearch";
import DisbursementSearch from "./DisbursementSearch/DisbursementSearch";
import PendingApproval from "./PendingApproval/PendingApproval";
import ApproveBatchDisbursementView from "./ApproveBatchDIsbursement/ApproveBatchDisbursementView";
import DisburseApprovedBatchedLoans from "./ApproveBatchDIsbursement/DisburseApprovedBatchedLoans";
import BatchedDetails from "./ApproveBatchDIsbursement/BatchedDetails";
import LoanRepaymentModal from "./Repayments/LoanRepaymentModal";

class LoanModal extends Component {
  render() {
    const { properties } = this.props;
    const { dataRecord, action } = properties;

    return (
      <div
        className="Modal__dialog lg-modal animated slideInDown"
        role="document"
      >
        {(() => {
          switch (action) {
            case "details":
              return (
                <Details
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "approval_trail":
              return (
                <ViewApprovalTrail
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "customrange":
              return (
                <CustomRange
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "View":
              return (
                <ViewAllLoans
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "Mifos":
              return (
                <ViewMifosLoans
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "advance search":
              return (
                <AdvanceSearch
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "disbursementsearch":
              return (
                <DisbursementSearch
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "pendingapproval":
              return (
                <PendingApproval
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "Repay":
              return (
                <RepayLoan
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "disburse":
              return (
                <Disburse
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "deny":
              return (
                <Deny
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            case "getloanrecords":
              return (
                <GetLoanRecordsModal onCloseModal={this.props.onCloseModal} />
              );
            case "getpendingdisbursementrecords":
              return (
                <GetPendingDisbursementRecordsModal
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "getapprovalbatchdisbursementview":
              return (
                <ApproveBatchDisbursementView
                  dataRecord={dataRecord}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "disburseapprovedbatchloan":
              return (
                <DisburseApprovedBatchedLoans
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "batcheddetails":
              return (
                <BatchedDetails
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );
            case "loanrepayment":
              return (
                <LoanRepaymentModal
                  properties={properties}
                  onCloseModal={this.props.onCloseModal}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loansForDisbursement } = state.loansReducer;

  return {
    loansForDisbursement,
  };
};

export default connect(mapStateToProps)(LoanModal);
