import { Loader } from "../../../UI/Loaders/Loaders";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";

interface IInvestorInfo {
  investorData: any;
  status: string;
  error: unknown;
}

export default function InvestorInfo({
  investorData,
  status,
  error,
}: IInvestorInfo) {
  if (status === "loading") {
    return <Loader centered={true} text="Loading Investor's Information..." />;
  }
  if (error) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            Investor's Information, please try again.
          </h4>
        </div>
      </div>
    );
  }
  const {
    funder_type_id,
    funder_type,
    country,
    account_name,
    account_number,
    bank_name,
    biz_name,
    bvn,
    first_name,
    email,
    gender,
    last_name,
    phone_number,
    pub_date,
    biz_email,
    rc_number,
    referred_by_name,
    referred_by_email
  } = investorData;
  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <form>
          <div className="row">
            <div className="col-md-6">
              <CustomInputField
                  label="Investor Type"
                  defaultValue={funder_type}
                  readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Investor's First Name"
                  defaultValue={first_name}
                  readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Investor's Last Name"
                  defaultValue={last_name}
                  readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Email address"
                  defaultValue={email}
                  readOnly={true}
              />
            </div>
            {(funder_type_id === 2 || funder_type_id === "2") && (
                <>
                  <div className="col-md-6">
                    <CustomInputField
                        label="Business Email"
                        defaultValue={biz_email}
                        readOnly={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomInputField
                        label="Business name"
                        defaultValue={biz_name}
                        readOnly={true}
                    />
                  </div>

                  <div className="col-md-6">
                    <CustomInputField
                        label="Registration Number"
                        defaultValue={rc_number}
                        readOnly={true}
                    />
                  </div>
                </>
            )}
            <div className="col-md-6">
              <CustomInputField
                  label="Country"
                  defaultValue={country}
                  readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Gender"
                  defaultValue={gender}
                  readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Investor Phone Number"
                  defaultValue={phone_number}
                  readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Bank"
                  defaultValue={bank_name}
                  readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Account"
                  defaultValue={account_name}
                  readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Account Number"
                  defaultValue={account_number}
                  readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                  label="Created Date"
                  defaultValue={pub_date?.substring(0, 10)}
                  readOnly={true}
              />
            </div>

            {(funder_type_id === 1 || funder_type_id === "1") && (
                <div className="col-md-6">
                  <CustomInputField
                      label="BVN"
                      defaultValue={bvn}
                      readOnly={true}
                  />
                </div>
            )}

            <div className="col-md-6">
              <CustomInputField
                  label="Referrer's Name"
                  defaultValue={referred_by_name}
                  readOnly={true}
              />
            </div>  <div className="col-md-6">
              <CustomInputField
                  label="Referrer's Email"
                  defaultValue={referred_by_email}
                  readOnly={true}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
