import { useEffect, useState } from "react";
import { appInsights } from "../../config/appInsights";
import ToggleTab from "./../../NewComponents/ToggleTab/ToggleTab.component";
import InvestmentProductRequest from "./Tabs/InvestmentProductRequest";
import InvestorRequests from "./Tabs/InvestorRequests";
import LoggedOfflineInvestment from "./Tabs/LoggedOfflineInvestment";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import { useSelector } from "react-redux";
import AdminTopUpRequests from "../Requests/Tabs/AdminTopUpRequests";
import AdminWithdrawalRequests from "../Requests/Tabs/AdminWithdrawalRequests";
import AdminWalletWithdrawal from "../Requests/Tabs/AdminWalletWithdrawal";
import AdminWalletTopUp from "../Requests/Tabs/AdminWalletTopUp";

export default function ApprovalRequest() {
  const loggedInUser = useSelector(
    (state: any) => state?.authReducer?.loggedInUser
  );
  const { roles } = loggedInUser;
  const [activeTab, setActiveTab] = useState("activation-requests");

  useEffect(() => {
    appInsights.trackPageView({
      name: "Admin Investment Approvals - (ApprovalRequest.jsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Approval Requests (Investments)</h3>
        <div className="alignToggleTabItems mb-3">
          <ToggleTab
            text="Fund Activation Requests"
            id="activation-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {roles &&
            roles?.length > 0 &&
            roles?.includes(ROLE_TYPES.INVESTMENT_WITHDRAWAL_VIEWER) && (
              <ToggleTab
                text="Admin Withdrawals"
                id="admin-withdrawal-requests"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}
          {roles &&
            roles?.length > 0 &&
            roles?.includes(ROLE_TYPES.INVESTMENT_TOP_UP_VIEWER) && (
              <>
                <ToggleTab
                  text="Admin Top-up"
                  id="admin-top-up-requests"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </>
            )}
          <ToggleTab
            text="Wallet Withdrawal"
            id="admin-wallet-withdrawal"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Wallet TopUp"
            id="admin-wallet-topup"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Investor Creation Requests"
            id="investor-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Logged Offline Requests"
            id="logged-offline"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case "activation-requests":
                return <InvestmentProductRequest />;
              case "investor-requests":
                return <InvestorRequests />;
              case "logged-offline":
                return <LoggedOfflineInvestment />;
              case "admin-top-up-requests":
                return <AdminTopUpRequests />;
              case "admin-withdrawal-requests":
                return <AdminWithdrawalRequests />;
              case "admin-wallet-withdrawal":
                return <AdminWalletWithdrawal />;
              case "admin-wallet-topup":
                return <AdminWalletTopUp />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
}
