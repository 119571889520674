import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getPendingWithdrawalRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.PENDING_WITHDRAWAL_APPROVAL
  );
  return data;
};

export default function usePendingWithdrawalRequests() {
  return useQuery(
    ["getPendingWithdrawalRequests"],
    getPendingWithdrawalRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
