import React, { Fragment } from "react";

export default function AggregatorOnboardingDocumentModal({
  onSubmit,
  dismissModalRef,
  modalActionType,
  discardChanges,
  loading,
}) {
  return (
    <Fragment>
      <div
        className="modal fade"
        id="documentOnboardingModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="documentOnboardingModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            {(() => {
              switch (modalActionType) {
                case "update":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Update Documents</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Updates you make to the Required Documents will be
                          effected for all Aggregators that have not requested
                          Onboarding.
                        </p>
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                                disabled={loading}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? "Updating..." : "Confirm Update"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                case "cancel":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="font-weight-bold">Discard Changes</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="tex-justify">
                          Updates you make to the Required Documents will be
                          effected for all Aggregators that have not requested
                          Onboarding.
                        </p>
                        <form onSubmit={discardChanges}>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
