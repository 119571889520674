import { useState, ChangeEvent, MouseEvent, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import Initials from "../Components/Initials/initials";
import Badge from "../Components/Badge/badge";
import useNotificationList from "../../../custom-hooks/useNotificationList";
import useNotificationMessageClass from "../../../custom-hooks/useNotificationMessageTypes";
import { MessageType, NotificationListParam } from "../types";
import moment from "moment";
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { messagingUrl } from "../urlConstants";
import { getInitial, truncateString } from "../helper";
import { appInsights } from "../../../config/appInsights";

import Styles from "./previousMessages.module.scss";

interface PreviousMessage {
  id: string;
  message: string;
  messageClass: string;
  title: string;
  userCategory: string;
  createdAt: string;
  user: { firstName: string; lastName: string };
}

const PreviousMessages = () => {
  const [searchQuery, setSearchQuery] = useState<NotificationListParam>({
    Page: "1",
    PageSize: "10",
    Search: "",
    MessageClass: "",
  });
  const [params, setParams] = useState<NotificationListParam>({
    Page: "1",
    PageSize: "10",
    Search: "",
    MessageClass: "",
  });

  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Previous Messages - (previousMessages.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: previousMessages,
    status,
    error,
  } = useNotificationList({
    Page: searchQuery.Page,
    PageSize: searchQuery.PageSize,
    Search: searchQuery.Search,
    Created: searchQuery.Created,
    MessageClass: searchQuery.MessageClass,
  });

  const { data: messageTypes } = useNotificationMessageClass();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (e.target.name === "search") {
      setParams((prev) => {
        return {
          ...prev,
          Search: value,
        };
      });
    } else if (e.target.name === "messageType") {
      setParams((prev) => ({
        ...prev,
        MessageClass: value,
      }));
    } else if (e.target.name === "createdAt") {
      setParams((prev) => ({
        ...prev,
        Created: value,
      }));
    }
  };

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    return setSearchQuery((prev) => ({
      ...prev,
      ...params,
    }));
  };

  let RenderedComponent = null;

  if (!previousMessages && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Previous Messages
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Previous Messages..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              previous messages, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (previousMessages &&
        previousMessages?.data &&
        previousMessages?.data.length === 0) ||
      (previousMessages && !previousMessages?.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Messages.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className={`table-responsive ${Styles.TableResponsive} `}>
            <table className={`table ${Styles.Table}`}>
              <tbody>
                {previousMessages?.data.map(
                  (request: PreviousMessage, _id: number) => {
                    return (
                      <tr key={_id}>
                        <td className={Styles.TableCell}>
                          <span>
                            {" "}
                            <Initials
                              initial={getInitial(request?.user?.firstName)}
                            />{" "}
                          </span>
                        </td>
                        <td className={Styles.TableCell}>
                          <div className={Styles.NotificationTitle}>
                            {truncateString(request?.title, 75)}
                          </div>
                          <div className={Styles.NotificationMessage}>
                            {truncateString(request?.message, 100)}
                          </div>
                        </td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          Sent By: {request?.user.firstName}{" "}
                          {request?.user?.lastName}
                        </td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {moment(request?.createdAt).format("YYYY-MM-DD")}
                        </td>
                        <td className={Styles.TableCell}>
                          <Badge message={request.messageClass} />
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: `/messaging/${messagingUrl.VIEW_PREVIOUS_MESSAGE}`,
                              state: request,
                            }}
                            className={`color-blue ${Styles.ViewDetails}`}
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="animated fadeInRight">
      <div>
        <h3 className={`${Styles.Header} page-title`}>Messaging</h3>
      </div>

      <div className={Styles.SendMessageContainer}>
        <button
          type="submit"
          className={`btn advancly-btn btn-md ${Styles.SendNewMessage}`}
          onClick={() =>
            history.push(`/messaging/${messagingUrl?.SEND_MESSAGE}`)
          }
        >
          Send a new message
        </button>
      </div>

      <div className={Styles.ActionContainer}>
        <div className={Styles.FilterOptions}>
          <div className={`form-group ${Styles.FormGroup}`}>
            <input
              type="text"
              className={Styles.SearchInput}
              placeholder="Search"
              name="search"
              onChange={handleChange}
            />
            <i className={`fas fa-search ${Styles.Icon}`}></i>
          </div>
          <div className={`form-group ${Styles.DateGroup}`}>
            <div>
              <span className={Styles.DateLabel}>From:</span>
              <input
                type="date"
                className={`${Styles.FilterInput} ${Styles.DateInput}`}
                onChange={handleChange}
                name="createdAt"
                max={moment(new Date()).format("YYYY-MM-DD")}
                placeholder="YYYY/MM/DD"
              />
            </div>
          </div>
          <div className={`form-group ${Styles.FormGroup}`}>
            <select
              className={`${Styles.FilterInput} ${Styles.FilterMessageType}`}
              onChange={handleChange}
              name="messageType"
            >
              <option value="" disabled selected hidden>
                Message Type
              </option>
              {messageTypes?.data.map((type: MessageType, index: number) => (
                <option key={index} value={type?.id}>
                  {type?.name}
                </option>
              ))}
            </select>
            <i className={`fas fa-solid fa-angle-down ${Styles.Icon}`}></i>
          </div>
        </div>
        <div className={Styles.FormButtonContainer}>
          <button
            type="submit"
            className={`btn advancly-btn btn-md ${Styles.SearchActionButton}`}
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>

      <div>{RenderedComponent}</div>

      <Paginator
        size={Number(params.PageSize)}
        page={Number(params.Page)}
        count={previousMessages?.totalCount}
        changeCurrentPage={(pageNumber: number) =>
          setSearchQuery((prev) => ({ ...prev, Page: pageNumber.toString() }))
        }
      />
    </div>
  );
};

export default PreviousMessages;
