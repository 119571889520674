import { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";

// Router
import { useHistory } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../redux/actions/notifications";
import { showModal } from "../../redux/actions/modal";

//Table Helpers
import {
  // filterDataByColumns,
  attachActionButtons,
  TableFetchSelect,
  onTableFetchSelectChange,
  tableFetchSelectOptions,
} from "../../helpers/tableControllers";

// UI Components
import { Loader, InfoLoader } from "../../UI/Loaders/Loaders";
// import { InfoLoader } from '../../UI/Loaders';
import BreadCrumbs from "../../UI/BreadCrumbs/BreadCrumbs";
import { RootState } from "../../redux/store";

const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const { allNotifications } = useSelector(
    (state: RootState) => state?.notificationsReducer
  );

  // Open Modal
  const modalHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "notifications",
        dataRecord: modalData.dataRecord,
        action: "customrange",
      })
    );
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }

    dispatch(getNotifications());
  }, []);

  let { notificationsTableData, loading, error } = allNotifications;

  let RenderedComponent = null;

  if (loading) {
    RenderedComponent = <Loader text="Loading Notifications" centered={true} />;
    RenderedComponent = <Loader text="Loading Notifications" />;
  } else if (error) {
    RenderedComponent = (
      <div className="centerLoader">
        <InfoLoader
          text="There was an issue fetching your notification."
          subText="Kindly reload the page."
        />
      </div>
    );
  } else if (notificationsTableData) {
    if (notificationsTableData.rows.length !== 0) {
      const tableName = "Notifications";
      const actions = ["view"];
      let rowsWithActionButton = attachActionButtons(
        notificationsTableData.rows,
        tableName,
        actions,
        modalHandler
      );
      notificationsTableData = {
        ...notificationsTableData,
        rows: [...rowsWithActionButton],
      };
    }
    RenderedComponent = (
      <div className="grid__padding">
        <MDBDataTable
          responsive
          minimal="true"
          striped
          bordered
          hover
          data={notificationsTableData}
        />
      </div>
    );
  } else {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There are Currently No Notifications
            to View
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="FunderNotifications datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        <div className="col-lg-9 col-md-6 col-sm-7 dashboard-title flex__row">
          {/* Bread Crumbs */}
          <BreadCrumbs
            name="Notifications"
            currentPath={window.location.pathname}
          />
        </div>
        <div
          className="m-l-1 m-r-1 m-b-1"
          style={{
            display: "flex",
            justifyContent: "center",
            height: "30px",
          }}
        >
          <TableFetchSelect
            // style={{ marginRight: '20px' }}
            label="Showing results for: "
            id="table-fetch"
            className="table-fetch-select"
            onChange={(e: any) =>
              onTableFetchSelectChange(
                e,
                showModal,
                getNotifications,
                "getNotifications",
                `searchvalue=`
              )
            }
            options={[...tableFetchSelectOptions]}
          />
        </div>

        {/* All Notifications Datatable || Loader || Error Message*/}
        {RenderedComponent}
      </div>
    </div>
  );
};

export default Notifications;
