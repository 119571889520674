import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAggregatorSettingsToManage = async () => {
  const data = await getDataWithDotNet(
    ajaxEndpoints.AGGREGATOR_SETTINGS_PENDING_APPROVAL
  );
  return data.data;
};

export default function useManageAggregatorSettingsData() {
  return useQuery(
    "getAggregatorSettingsToManage",
    getAggregatorSettingsToManage
  );
}
