import React from "react";

const MandatePayload = ({ responsePayLoad, requestPayLoad }: any) => {
  const responseParsedData = responsePayLoad
    ? JSON.parse(responsePayLoad)
    : null;
  const requestParsedData = requestPayLoad ? JSON.parse(requestPayLoad) : null;

  return (
    <div className="modal fade" id="directDebitMandatePayload" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content" style={{ height: "100vh" }}>
          <div
            className="modal-header "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <h3 className="modal-title">Payload</h3>
            <button
              type="button"
              className="close "
              data-dismiss="modal"
              aria-label="Close"
              style={{ paddingRight: "5px" }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="py-2">
              <h4>Request Payload</h4>
              {requestParsedData
                ? Object.entries(requestParsedData).map(([key, value]) => (
                    <div key={key}>{`${key}: ${value}`}</div>
                  ))
                : ""}
            </div>
            <div className="py-2">
              <h4>Response Payload</h4>
              {responseParsedData?.data
                ? Object.entries(responseParsedData?.data).map(
                    ([key, value]) => <div key={key}>{`${key}: ${value}`}</div>
                  )
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MandatePayload;
