import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TQuery = {
  id: number;
  currency: string;
  investmentProductId: number;
};

const getEachInvestorsInvestment = async ({
  id,
  currency,
  investmentProductId,
}: TQuery) => {
  if (investmentProductId && currency && id) {
    const queryParams = `?funderId=${Number(id)}&investmentProductId=${Number(
      investmentProductId
    )}&currency=${currency}`;

    const data = await getData(
      `${ajaxEndpoints.INVESTORS_INVESTMENT}${queryParams}`
    );
    return data.data;
  }
};
export default function useEachInvestorsInvestment({
  id,
  currency,
  investmentProductId,
}: TQuery) {
  return useQuery(
    [{ id, currency, investmentProductId }, "getEachInvestorsInvestment"],
    getEachInvestorsInvestment,
    {
      refetchOnWindowFocus: false,
    }
  );
}
