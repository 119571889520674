import { useQuery } from "react-query";

import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TQuery = {
  batchLoanNumber: string;
};

const getBatchLoans = async ({ batchLoanNumber }: TQuery) => {
  let queryParams = `batchLoanNumber=${batchLoanNumber}`;

  const data = await getData(`${ajaxEndpoints.GET_BATCH_LOANS}?${queryParams}`);
  return data;
};

export default function useBatchLoans({ batchLoanNumber }: TQuery) {
  return useQuery([{ batchLoanNumber }, "batchLoansDetails"], getBatchLoans);
}
