import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  pageNumber: number;
  size: number;
  currency?: string;
  startDate?: string;
  endDate?: string;
  searchValue?: string;
  FunderId?: number;
  aggregatorId?: number;
};

const getAllInvestment = async ({
  pageNumber,
  size,
  currency,
  startDate,
  endDate,
  searchValue,
  FunderId,
  aggregatorId,
}: TQuery) => {
  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;

  if (currency) {
    queryParams = `${queryParams}&Currency=${currency}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&from_date=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to_date=${endDate}`;
  }
  if (searchValue) {
    queryParams = `${queryParams}&FunderName=${searchValue}`;
  }
  if (FunderId) {
    queryParams = `${queryParams}&FunderId=${FunderId}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_ALL_INVESTMENTS}${queryParams}`
  );

  return data;
};

export default function useAllInvestment({
  pageNumber,
  size,
  startDate,
  endDate,
  searchValue,
  FunderId,
  aggregatorId,
  currency,
}: TQuery) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        startDate,
        endDate,
        searchValue,
        FunderId,
        aggregatorId,
        currency,
      },
      "getAllInvestment",
    ],
    getAllInvestment
  );
}
