import { Fragment, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import { Loader } from "../../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../../../NewComponents/TypedComponents/CustomHTMLElements/CustomSelectDropdown";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { useQueryCache } from "react-query";
import { useDispatch } from "react-redux";
import { showModal, hideModal } from "../../../redux/actions/modal";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import useAllInvestorsPendingDocuments from "../../../custom-hooks/useAllInvestorsPendingDocuments";
import { appInsights } from "../../../config/appInsights";

const DocumentationVerification = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const queryCache = useQueryCache();
  const dispatch = useDispatch();

  const {
    data: pendingDocuments,
    status,
    error,
  } = useAllInvestorsPendingDocuments({
    searchValue,
    pageNumber,
    size,
  });

  const handleSubmit = async ({
    funder_kyc_documents,
    funder_id,
    comment,
  }: {
    funder_kyc_documents: number;
    funder_id: number;
    comment: string;
  }) => {
    setLoading(true);
    setSuccessMessage("");
    setSubmitError("");
    const reqBoday = {
      funder_id,
      funder_kyc_documents,
      comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.APPROVE_FUNDER_DOCUMENT}`,
        reqBoday
      );
      setLoading(false);
      setSuccessMessage(response.message);
      queryCache.invalidateQueries([
        { searchValue, pageNumber: 1, size },
        "getAllInvestorsPendingDocuments",
      ]);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DocumentationVerification.tsx",
        },
      });
      setLoading(false);
      setSubmitError(errorHandler(error));
      queryCache.invalidateQueries([
        { searchValue, pageNumber: 1, size },
        "getAllInvestorsPendingDocuments",
      ]);
      dispatch(hideModal());
    }
  };
  const handleReject = async ({
    funder_kyc_documents,
    funder_id,
    comment,
  }: {
    funder_kyc_documents: number;
    funder_id: number;
    comment: string;
  }) => {
    setSuccessMessage("");
    setSubmitError("");
    setLoading(true);
    const reqBoday = {
      funder_id,
      funder_kyc_documents,
      comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.REJECT_FUNDER_DOCUMENT}`,
        reqBoday
      );
      setLoading(false);
      setSuccessMessage(response.message);
      queryCache.invalidateQueries([
        { searchValue, pageNumber: 1, size },
        "getAllInvestorsPendingDocuments",
      ]);
      dispatch(hideModal());
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DocumentationVerification.tsx",
        },
      });
      setLoading(false);
      setSubmitError(errorHandler(error));
      queryCache.invalidateQueries([
        { searchValue, pageNumber: 1, size },
        "getAllInvestorsPendingDocuments",
      ]);
      dispatch(hideModal());
    }
  };
  let RenderedComponent = null;
  if (!pendingDocuments && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch documents
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading documents..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              pending documents, Please Reload The Page
            </h4>
          </div>
        </div>
      );
    } else if (
      (pendingDocuments && pendingDocuments?.data.length === 0) ||
      !pendingDocuments
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              documents.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Type</th>
                  <th>Request Date</th>
                  <th>Investor Name</th>
                  <th>User Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {pendingDocuments?.data.map((request: any, _id: number) => {
                  const { document_type, request_date, biz_name, user_type } =
                    request;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{document_type}</td>
                      <td>{request_date.substring(0, 10)}</td>
                      <td>{biz_name}</td>
                      <td>{user_type}</td>
                      <td className="flex-row">
                        <button
                          className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                          type="button"
                          data-toggle="modal"
                          data-target="#requestModal"
                          onClick={() =>
                            dispatch(
                              showModal({
                                modalIdentifier: "requests",
                                action: "document_request",
                                request,
                                loading,
                                handleSubmit,
                                handleReject,
                              })
                            )
                          }
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  if (loading) {
    RenderedComponent = (
      <Loader centered={true} text="Loading pending documents..." />
    );
  }
  return (
    <Fragment>
      <div>
        {successMessage && <Alert message={successMessage} type="success" />}
        {submitError && <Alert message={submitError} />}
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            {status === "success" && (
              <span>
                You have{" "}
                <span className="font-weight-bold">
                  {pendingDocuments?.data.length
                    ? pendingDocuments?.data.length
                    : "No"}{" "}
                  pending
                </span>{" "}
                requests
              </span>
            )}
          </div>
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <div
              className="input-group mr-3 mt-3"
              style={{ maxWidth: "200px" }}
            >
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div style={{ width: "150px", marginTop: "10px" }}>
              <CustomSelectDropdown
                onChange={(e: any) => {
                  setSize(e.target.value);
                }}
                value={size}
              >
                <option value={10000}>All</option>
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
        </div>
        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={pendingDocuments?.totalCount}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </Fragment>
  );
};
export default DocumentationVerification;
