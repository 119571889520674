import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";
export interface IcreditBureauAPI {
  customerId: number;
  page: number;
  pageSize: number;
}
const getCreditBureauCheckHistory = async ({
  customerId,
  page,
  pageSize,
}: IcreditBureauAPI) => {
  const { data } = await getData(
    `${ajaxEndpoints.CREDIT_BUREAU_CHECK_HISTORY}?customerId=${customerId}&page=${page}&pageSize=${pageSize}`
  );
  return data;
};

export default function useCreditBureauCheckHistory({
  customerId,
  page,
  pageSize,
}: IcreditBureauAPI) {
  return useQuery(
    [{ customerId, page, pageSize }, "getCreditBureauCheckHistory"],
    getCreditBureauCheckHistory,
    {
      refetchOnWindowFocus: false,
    }
  );
}
