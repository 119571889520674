import { clearDocumentViewModalRecord } from "../../redux/actions/documenViewer";
import { useDispatch } from "react-redux";

import "./ModalSimple.css";

export const ModalSimple = (props) => {
  const { children } = props;

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    props.onCloseModal();
    if (props.modalIdentifier === "documentviewer") {
      dispatch(clearDocumentViewModalRecord());
    }
  };
  return (
    <div
      className={(props.showModal ? "show" : "") + " Modal fade"}
      id="myModal"
      tabIndex="-1"
      role="dialog"
      style={{
        paddingRight: "17px",
        display: props.showModal ? "block" : "none",
      }}
    >
      <button
        id="Modal__close"
        type="button"
        className="close"
        onClick={handleCloseModal}
        data-dismiss="modal"
        aria-label="Close"
      >
        <i className="fa fa-times-circle fa-2x"></i>
      </button>

      {children}
    </div>
  );
};
