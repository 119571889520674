import { getData } from "../newApis/apiMethods";
import { useQuery } from "react-query";

const getDirectDebitListAccounts = async (customerId: string) => {
  const data = await getData(
    `/customer/${customerId}/direct_debit_accounts
    `
  );
  return data.data;
};

export default function useDirectDebitListAccounts(customerId: string) {
  return useQuery(
    [customerId, "getDirectDebitAccountsList"],
    getDirectDebitListAccounts,
    { refetchOnWindowFocus: false }
  );
}
