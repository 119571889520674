import { NavLink } from "react-router-dom";

// Component Styles
import "./BreadCrumbs.css";

interface IBreadCrumbs {
  name: string;
  currentPath: string;
}

const BreadCrumbs = ({ name, currentPath }: IBreadCrumbs) => {
  return (
    <div className="BreadCrumbs">
      <h3 className="m-l-1">
        <aside className="rounder">
          <i className="fa fa-bar-chart" />
        </aside>
        &nbsp;
        {name}
      </h3>
      <div className="breadcrumb__location">
        <NavLink to="dashboard">
          <h3 className="breadcrumb__location__title">Dashboard</h3>
        </NavLink>
        <span className="breadcrumb__location__icon">
          <i className="fas fa-angle-double-right" />
        </span>
        <NavLink to={currentPath}>
          <h3 className="breadcrumb__location__title">{name}</h3>
        </NavLink>
      </div>
    </div>
  );
};

export default BreadCrumbs;
