export const removeAllModals = () => {
  const modals = Array.from(document.querySelectorAll(".modal-backdrop"));

  // Close all boostrap modals
  if (modals.length) {
    modals.map((modal) => (modal.style.display = "none"));
  }

  // Close major modal
  const majorModal = document.querySelector("#Modal__close");
  const majorModalContainer = document.querySelector("#myModal");
  if (majorModal) {
    majorModal.click();
  }
  if (majorModalContainer) {
    majorModalContainer.style.display = "none";
  }
};
