export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const currencyFormatter = (value: number, currency?: string) => {
  return value.toLocaleString(undefined, {
    style: "currency",
    currency: currency ? currency : "NGN",
  });
};

export const currency = (value: string) => {
  return Number(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dateFormatter = (timeStamp: string) => {
  const value = new Date(timeStamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const month = months[value.getMonth()];
  const date = value.getDate();
  const year = value.getFullYear();
  return `${month} ${date}, ${year}`;
};

export const str2bool = (value: string) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};
