import React, { useEffect } from "react";
import {
  useWalletDetailsByInvestmentProduct,
  useWalletList,
} from "../../../../custom-hooks/useWallet";
import { object } from "yup";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../helpers/formatter";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";

interface IProps {
  currency: string;
  funderId: number;
  investmentProductId: number;
  goBack: () => void;
}

const InvestorWalletDetails = ({
  currency,
  funderId,
  goBack,
  investmentProductId,
}: IProps) => {
  const { data, status, error } = useWalletDetailsByInvestmentProduct(
    investmentProductId,
    funderId,
    currency
  );

  useEffect(() => {
    console.log({
      data,
      status,
      error,
    });
  }, [data, status, error]);

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>
      {status === "loading" ? (
        <Loader centered text="Loading Wallet Details..." />
      ) : !data ? (
        <p className="alert alert-danger col-md-6">
          Error fetching wallet, please reload
        </p>
      ) : (
        <>
          <p className="cursor-pointer font-weight-bold mb-3" onClick={goBack}>
            {" "}
            <i className="fas fa-arrow-left mr-2" /> Back to Wallet
          </p>
          <div className="row">
            {data &&
              Object.entries(data).map(([key, value]) => (
                <div className="col-md-4 offset-md-1 mb-4">
                  {/*@ts-ignore*/}
                  <CustomInputField
                    label={capitalize(key)}
                    defaultValue={
                      key === "availableBalance"
                        ? formatMoney(value as number, data.currencyCode)
                        : (value as string | number | undefined)
                    }
                    readOnly={true}
                  ></CustomInputField>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default InvestorWalletDetails;
