import * as actionTypes from "./../actions/actionTypes";

const initialState = {
  wallet: {
    interest: 30000,
    balance: 1000000,
    wallet_transactions: [],
  },
  loading: false,
  success: false,
  complete: false,
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FUND_WALLET_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          balance: action.payload.balance,
          wallet_transactions: [...action.payload.transactions],
        },
        loading: false,
        success: true,
      };

    case actionTypes.FUND_WALLET_SUCCESS:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          balance: action.payload.balance,
        },
        loading: false,
        success: true,
      };

    case actionTypes.FUND_WALLET_COMPLETE:
      return {
        ...state,
        loading: false,
        success: false,
        complete: true,
      };

    case actionTypes.FUND_WALLET_FAILURE:
      return {
        loading: false,
        success: false,
        complete: false,
        error: action.error,
        wallet: null,
      };

    default:
      return state;
  }
};

export default reducer;
