import {
  ChangeEvent,
  Fragment,
  useState,
  memo,
  useEffect,
  useRef,
} from "react";

import { useHistory } from "react-router-dom";
import useInterestCalculation from "../../../../custom-hooks/useInterestCalculation";
import { formatMoney } from "../../../../helpers/formatter";
import { postData } from "../../../../newApis/dotNetApiMethods";
import Alert from "../../../../NewComponents/Alert/Alert.component";
import { RollOverType } from "../../Tabs/EachInvestorInvestment/types";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import { getInvestmentProductRate } from "../../../../custom-hooks/useInvestmentProductRate";
import useInvestmentProductDetail from "../../../../custom-hooks/useInvestmentProductsDetails";
import { ISelectedRecord } from "../../Tabs/EachInvestorInvestment/EachInvestorInvestment";

import style from "./RollOverModal.module.scss";

type TRepaymentPlan = "Monthly" | "EndOfTenure";
interface IRollOverModal {
  selectedRollOverType: RollOverType | null;
  selectedRecord: ISelectedRecord;
}

type TRates = {
  duration?: number;
  period_type?: string;
  rate?: number;
};

type TProductRateDetails = {
  numberOfDaysInYear: number;
  interest: number;
};

const RollOverModal = ({
  selectedRollOverType,
  selectedRecord,
}: IRollOverModal) => {
  const [isRollingOver, setIsRollingOver] = useState(false);
  const [isFetchingProductRate, setIsFetchingProductRate] = useState(false);
  const [activeDuration, setActiveDuration] = useState<TRates>({
    duration: undefined,
    period_type: "",
    rate: undefined,
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [repaymentPlanType, setRepaymentPlanType] = useState<
    TRepaymentPlan | ""
  >("");
  const [productRateDetails, setProductRateDetails] =
    useState<TProductRateDetails | null>(null);

  const history = useHistory();

  const dismissModalRef = useRef<HTMLButtonElement>(null);

  const { principal_amount, currency, investment_product_details_id } =
    selectedRecord;

  const { data: investmentProductDetails } = useInvestmentProductDetail(
    investment_product_details_id
  );

  const handleGetProductRate = async () => {
    setIsFetchingProductRate(true);
    try {
      const response = await getInvestmentProductRate({
        investRateId: investmentProductDetails?.core_banking_product_id,
        enteredPrincipalAmount: principal_amount,
        tenure: activeDuration?.duration!,
        tenantId: investmentProductDetails?.core_banking_tenant_id,
        depositType:
          investmentProductDetails[0]?.deposit_type === "RecurringDeposit"
            ? 4
            : 3,
      });

      if (response?.status_code === 200) {
        setProductRateDetails({
          numberOfDaysInYear: response?.data?.numberOfDaysInYear,
          interest: response?.data?.fixedDepositInterestRate?.interest,
        });
      } else {
        setErrorMessage(response?.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.message);
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
    setIsFetchingProductRate(false);
  };

  useEffect(() => {
    if (activeDuration?.duration) {
      handleGetProductRate();
    }
  }, [activeDuration?.duration]);

  const {
    data: calculatedResult,
    isError,
    isLoading,
  } = useInterestCalculation({
    principalAmount: Number(principal_amount),
    interestRate: productRateDetails?.interest,
    tenure: activeDuration?.duration,
    repaymentPlan: repaymentPlanType,
    numberOfDaysInYear: productRateDetails?.numberOfDaysInYear,
  });

  const handleRepaymentPlanTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as TRepaymentPlan;
    setRepaymentPlanType(value);
  };

  const handleChangeTenure = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setActiveDuration((prev: TRates) => {
      return {
        ...prev,
        duration: Number(target.value),
      };
    });
  };

  const handleClose = () => {
    setRepaymentPlanType("");
    setActiveDuration({
      duration: undefined,
      period_type: "",
      rate: undefined,
    });
    setProductRateDetails(null);
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const handleRollOver = async () => {
    setIsRollingOver(true);
    try {
      if (!activeDuration) {
        setIsRollingOver(false);
        return setErrorMessage("You have not selected roll over duration");
      }
      if (!repaymentPlanType) {
        setIsRollingOver(false);
        return setErrorMessage("You have not selected repayment type");
      }
      const payload = {
        investment_id: selectedRecord?.id,
        roll_over_type: selectedRollOverType,
        tenure: activeDuration?.duration,
        internal_payment_reference: selectedRecord?.internal_payment_reference,
        interest_repayment_type: repaymentPlanType,
        interest_rate: productRateDetails?.interest,
        interest_calculation_days_in_year_type:
          productRateDetails?.numberOfDaysInYear,
      };
      const response = await postData(
        ajaxEndpoints.ROLLOVER_FUNDER_INVESTMENT,
        payload
      );
      if (response?.data?.status_code === 200) {
        setSuccessMessage(response?.data?.message);
        if (dismissModalRef && dismissModalRef.current) {
          dismissModalRef.current.click();
        }
        setTimeout(() => {
          history.goBack();
        }, 3000);
        handleClose();
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
    setIsRollingOver(false);
  };

  return (
    <Fragment>
      {(errorMessage || isError) && (
        <Alert
          message={errorMessage || isError}
          type={null}
          place={null}
          autoDismiss={null}
          icon={null}
        />
      )}
      {successMessage && (
        <Alert
          message={successMessage}
          place={null}
          autoDismiss={null}
          icon={null}
          type="success"
        />
      )}
      <div
        className="modal fade"
        id="rollOverModal"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="rollOverModalLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="page-subtitle">Roll over</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                ref={dismissModalRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>How long do you want to rollover for?</p>

              <div>
                <CustomInputField
                  type="number"
                  name="tenure"
                  label="Tenure (Days)"
                  placeholder="Enter Tenure"
                  max="365"
                  min="10"
                  onChange={(e: ChangeEvent) => {
                    handleChangeTenure(e);
                  }}
                  value={activeDuration?.duration}
                  id="tenure-element"
                />
              </div>
              <div>
                {isFetchingProductRate && (
                  <small className="text-danger">Loading...</small>
                )}

                <CustomInputField
                  type="number"
                  label="Interest Rate (%)"
                  readOnly
                  value={productRateDetails?.interest}
                />
              </div>

              <div className={style.repaymentPlanContainer}>
                <p>Select a repayment plan:</p>

                <div className={style.repaymentPlanActionContainer}>
                  <div className={style.action}>
                    <div>
                      <input
                        type="radio"
                        id="monthly"
                        name="repayment_plan_type"
                        value={"Monthly" || ""}
                        className={style.principalRadio}
                        onChange={handleRepaymentPlanTypeChange}
                        checked={repaymentPlanType === "Monthly"}
                      />
                      <label htmlFor="principal">Monthly</label>
                    </div>
                    <p className={style.repaymentTypeText}>
                      Interest will be paid monthly and principal will be paid
                      at maturity
                    </p>
                  </div>

                  <div className={style.action}>
                    <div>
                      <input
                        type="radio"
                        id="endOfTenure"
                        name="repayment_plan_type"
                        className={style.principalRadio}
                        value={"EndOfTenure" || ""}
                        onChange={handleRepaymentPlanTypeChange}
                        checked={repaymentPlanType === "EndOfTenure"}
                      />
                      <label htmlFor="principal">End of Tenure</label>
                    </div>
                    <p className={style.repaymentTypeText}>
                      Both interest and principal will be paid at maturity
                    </p>
                  </div>
                </div>
              </div>

              <div className={style.result}>
                <div className={style.estimatedReturns}>
                  <span className={style.title}>Estimated Returns</span>
                  {isLoading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    <span className={style.value}>
                      {formatMoney(
                        calculatedResult?.data?.data?.maturity_amount,
                        currency
                      )}
                    </span>
                  )}
                </div>
                <div className={style.monthlyRepayments}>
                  <span className={style.title}>Monthly Repayments</span>
                  {isLoading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    <span className={style.value}>
                      {formatMoney(
                        calculatedResult?.data?.data?.monthly_interest_payable,
                        currency
                      )}
                    </span>
                  )}
                </div>
              </div>

              <div className={style.finalSection}>
                <button
                  className="btn advancly-white-btn m-0"
                  onClick={handleRollOver}
                  disabled={isRollingOver}
                >
                  {isRollingOver ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    "Roll Over"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(RollOverModal);
