import { phoneRegExp } from "../../../../../helpers/yupValidators";
import * as yup from "yup";

export const schemaWithPassword = yup.object().shape({
  phone: yup
    .string()
    .required("Please enter new user's phone number")
    .trim()
    .matches(phoneRegExp, "Invalid Phone Number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits"),
  firstName: yup
    .string()
    .trim()
    .required("Please enter new user's first name")
    .min(2, "First name must have at least 2 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  lastName: yup
    .string()
    .required("Please enter new user's last name")
    .min(2, "First name must have at least 2 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  email: yup
    .string()
    .trim()
    .email("This is an invalid email")
    .required("Please enter new user's email")
    .min(5, "Email must have at least 5 characters")
    .max(256, "Email cannot be longer than 256 characters"),
  confirmPassword: yup
    .string()
    .required("Please enter new user's password again")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(/[0-9]/, "Password must contain a number"),
});

export const schemaWithOutPassword = yup.object().shape({
  phone: yup
    .string()
    .required("Please enter new user's phone number")
    .trim()
    .matches(phoneRegExp, "Invalid Phone Number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits"),
  firstName: yup
    .string()
    .trim()
    .required("Please enter new user's first name")
    .min(2, "First name must have at least 2 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  lastName: yup
    .string()
    .required("Please enter new user's last name")
    .min(2, "First name must have at least 2 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  email: yup
    .string()
    .trim()
    .email("This is an invalid email")
    .required("Please enter new user's email")
    .min(5, "Email must have at least 5 characters")
    .max(256, "Email cannot be longer than 256 characters"),
});
