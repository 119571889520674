import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { phoneRegExp } from "../../../../helpers/yupValidators";
import CustomInputField from "../../../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";

const schema = yup.object().shape({
  phone: yup
    .string()
    .required("Please enter new phone number")
    .trim()
    .matches(phoneRegExp, "Invalid Phone Number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits"),
  firstName: yup
    .string()
    .trim()
    .required("Please enter a new first name")
    .min(2, "First name must have at least 2 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  lastName: yup
    .string()
    .required("Please enter a new last name")
    .min(2, "First name must have at least 2 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  isActive: yup.boolean().required("Please select user active status"),
});
export default function UpdateUser({ dataRecord, onCloseModal }) {
  const [isActive, setIsActive] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const manageUser = (data) => {
    onCloseModal();
    setTimeout(() => {
      action({
        ...data,
        id,
      });
    }, 100);
  };
  const {
    action,
    user: { first_name, last_name, phone, is_active, id },
  } = dataRecord;

  const handleChange = (e) => {
    const value = e.target.value;
    setIsActive(value);
  };

  useEffect(() => {
    setIsActive(is_active);
  }, [is_active]);

  return (
    <Fragment>
      <h3 className="Modal__title p-b-2 text-center">
        Update User Information
      </h3>
      <form onSubmit={handleSubmit(manageUser)}>
        <div className="m-t-2 m-b-2">
          <CustomInputField
            type="text"
            maxLength="50"
            reference={register}
            defaultValue={first_name}
            name="firstName"
            placeholder="First Name"
            label="First Name"
            errors={errors.firstName}
            iconType="fas fa-user"
          />
        </div>
        <div className="m-t-2 m-b-2">
          <CustomInputField
            type="text"
            maxLength="50"
            reference={register}
            defaultValue={last_name}
            name="lastName"
            placeholder="Last Name"
            label="Last Name"
            errors={errors.lastName}
            iconType="fas fa-user"
          />
        </div>
        <div className="m-t-2 m-b-2">
          <CustomInputField
            type="number"
            maxLength="20"
            reference={register}
            defaultValue={phone}
            name="phone"
            placeholder="Phone Number"
            label="Phone Number"
            errors={errors.phone}
            iconType="fas fa-phone"
          />
        </div>
        <div className="m-t-2 m-b-2">
          <CustomSelectDropdown
            reference={register}
            value={isActive}
            name="isActive"
            label="User Active Status"
            errors={errors.isActive}
            iconType="fas fa-star-half-alt"
            onChange={handleChange}
          >
            <option></option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </CustomSelectDropdown>
        </div>
        <div className="Form__action-buttons m-t-1">
          <button
            type="button"
            className="btn zoom-element btn login-btn m-0 mr-auto"
            onClick={onCloseModal}
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Cancel
          </button>
          <button
            type="submit"
            className="btn zoom-element btn login-btn m-0 ml-auto"
            data-dismiss="modal"
          >
            <i className="fas fa-arrow-right"></i>
            &nbsp; Update User
          </button>
        </div>
      </form>
    </Fragment>
  );
}
