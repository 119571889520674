import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

interface IgetRecentRepayment {
  aggregatorId: number;
  currentCurrencyValue: string;
}

const getRecentRepayments = async ({
  aggregatorId,
  currentCurrencyValue,
}: IgetRecentRepayment) => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_RECENT_PAYMENTS}?aggregator_id=${aggregatorId}&currency=${currentCurrencyValue}`,
  );
  return data.data;
};

export default function useRecentRepayments({
  aggregatorId,
  currentCurrencyValue,
}: IgetRecentRepayment) {
  return useQuery(
    [{ aggregatorId, currentCurrencyValue }, "getRecentRepayments"],
    getRecentRepayments,
    {
      enabled: currentCurrencyValue && aggregatorId ? true : false,

      refetchOnWindowFocus: false,
    },
  );
}
