import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";

// Successful Onboarding API call
export const onboardingSuccess = (response) => {
  return {
    type: actionTypes.ONBOARDING_SUCCESS,
    payload: response.data,
  };
};

// Onboarding Handler
// @params { onboardingData: Object}
export const onboardPersona = (onboardingData) => {
  return (dispatch) => {
    // Initialize Onboarding API call
    dispatch({
      type: actionTypes.ONBOARDING_START,
    });

    // const response = true;

    // setTimeout(() => {
    //     if (response) {
    //         const newResponse = {
    //             data: {
    //                 email: onboardingData.personal_email
    //             }
    //         }
    //         dispatch(onboardingSuccess(newResponse));

    //     }else {
    //         dispatch({
    //             type: actionTypes.ONBOARDING_NETWORK_FAILURE,
    //             payload: {
    //                 msg: 'Network Failure'
    //             }
    //         });
    //     }
    // }, 3000);

    ajaxMethods
      .post(ajaxEndpoints.ONBOARDING, onboardingData)
      .then((response) => {
        if (response) {
          //const {data} = response;
          // Failed Onboarding API call - Incorrect Credentials
          if (response.data.status_code === 400) {
            dispatch({
              type: actionTypes.ONBOARDING_ERROR,
              payload: {
                error_code: response.data.status_code,
                message: response.data.message,
              },
            });
            // Successful Onboarding API call
          } else if (response.data.status_code === 201) {
            dispatch(onboardingSuccess(response));
            // Failed Onboarding API call - General
          } else {
            dispatch({
              type: actionTypes.ONBOARDING_NETWORK_FAILURE,
              payload: {
                message: "Network Failure",
              },
            });
          }
        } else {
          // Failed Onboarding API call - Network Failure
          dispatch({
            type: actionTypes.ONBOARDING_NETWORK_FAILURE,
            payload: {
              message: "Network Failure",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "onboarding.js",
          },
        });
        // Failed Onboarding API call

        dispatch({
          type: actionTypes.ONBOARDING_ERROR,
          payload: error,
        });
      });
  };
};

// Onboarded Status Handler
export const updateOnboarded = (isOnboarded) => {
  return function (dispatch) {
    dispatch({
      type: actionTypes.UPDATE_ONBOARDED,
      payload: isOnboarded,
    });
  };
};
