import { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import ToggleTab from "../../NewComponents/ToggleTab/ToggleTab.component";
import DraftedInvestment from "./Tabs/DraftedInvestment";
import InactiveInvestment from "./Tabs/InactiveInvestment";
import InvestmentProducts from "./Tabs/InvestmentProduct";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import { appInsights } from "../../config/appInsights";
// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Investments = () => {
  const [activeTab, setActiveTab] = useState("active-investments-products");

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Investment Funds  - (FunderInvestmentProduct.jsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <Fragment>
      <div className="animated fadeInRight">
        <div className="d-flex flex-row flex-wrap mt-2 mb-1">
          <h3 className="page-title">Investments</h3>
          {loggedInUser?.roles.includes(
            ROLE_TYPES.INVESTMENT_PRODUCT_SETUP
          ) && (
            <Link to={`/investments/create`}>
              <button type="button" className="btn advancly-btn btn-md m-0">
                Create New Fund
              </button>
            </Link>
          )}
        </div>
        <h4 className="mb-4">
          These are the available investment opportunities at the moment.
        </h4>
        <div className="alignToggleTabItems mb-3">
          <ToggleTab
            text="Active"
            id="active-investments-products"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Inactive"
            id="inactive-investments-products"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Draft Investments"
            id="drafted-investments-products"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case "active-investments-products":
                return <InvestmentProducts />;
              case "inactive-investments-products":
                return <InactiveInvestment />;
              case "drafted-investments-products":
                return <DraftedInvestment />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Fragment>
  );
};

export default Investments;
