// Redux Action CONSTANTS
import * as actionTypes from "./../actions/actionTypes";

// Ajax Methods
import ajaxMethods from "./../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "./../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns, responseDataRecords) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row, index) => {
        let newRow = {};
        tableColumns.forEach((column) => {
          if (column.field === "transaction_id") {
            newRow[column.field] = index + 1;
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};

// Start asynchronous ajax call with UI loader display
export const transactionAjaxStart = () => {
  return { type: actionTypes.TRANSACTION_AJAX_START };
};

export const getTransactionsFailure = (error) => {
  return {
    type: actionTypes.GET_TRANSACTIONS_FAILURE,
    error,
  };
};

// Get all Transactions and handle success payload
export const getAllTransactions = (queryString) => {
  return function (dispatch) {
    dispatch(transactionAjaxStart());

    let endpoint = ajaxEndpoints.ALL_TRANSACTIONS;
    if (queryString) {
      endpoint = `${ajaxEndpoints.ALL_TRANSACTIONS}?${queryString}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch(getTransactionsSuccess(response));
        } else {
          dispatch(
            getTransactionsFailure({
              errorMsg: "other error",
            })
          );
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "transactions.js",
          },
        });
        dispatch(getTransactionsFailure(error));
      });
  };
};

export const getTransactionsSuccess = (response) => {
  // Columns for DataTable
  const tableColumns = [
    { label: "#", field: "index", sort: "asc" },
    { label: " ID", field: "transaction_id", sort: "asc" },
    { label: " DEBITS", field: "trans_amoun", sort: "asc" },
    { label: " CREDITS", field: "trans_amount", sort: "asc" },
    { label: "INITIATOR", field: "transaction_initiator", sort: "asc" },
    { label: " TYPE", field: "tx_action", sort: "asc" },
    { label: "NOTE", field: "tx_note", sort: "asc" },
    { label: "REFERENCE NO", field: "tx_ref", sort: "asc" },
    { label: "MODIFIED_DATE", field: "modified_date", sort: "asc" },
    { label: "DATE", field: "pub_date", sort: "asc" },
    { label: "STATUS", field: "is_done", sort: "asc" },
    { label: "MANAGE", field: "manage", sort: "asc" },
  ];

  const reOrderedTableData = reOrderRows(tableColumns, response.data);

  return {
    type: actionTypes.ALL_TRANSACTIONS_SUCCESS,
    payload: {
      transactionsTableData: reOrderedTableData,
      transactions: [...response.data],
      count: response.count,
      next_page: response.next_page,
      prev_page: response.prev_page,
    },
  };
};

// Get Transaction detail and handle success payload
export const getTransactionByID = (transaction_id) => {
  return function (dispatch) {
    dispatch(transactionAjaxStart());
    ajaxMethods
      .get(`ajaxEndpoints.TRANSACTION_DETAILS${transaction_id}`)
      .then((response) => {
        dispatch(getTransactionByIDSuccess(response));
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "transactions.js",
          },
        });
        dispatch(getTransactionsFailure(error));
      });
  };
};

export const getTransactionByIDSuccess = (payload) => {
  return {
    type: actionTypes.TRANSACTION_BY_ID_SUCCESS,
    payload: payload.data,
  };
};
