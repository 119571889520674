import {
  useState,
  Fragment,
  useRef,
  useEffect,
  ChangeEvent,
  FormEvent,
} from "react";
import moment from "moment";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { useHistory, useLocation } from "react-router-dom";
import ApprovalInvestorModal from "../ApprovalRequests/ApprovalInvestorModal";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { formatMoney } from "../../../helpers/formatter";
import { appInsights } from "../../../config/appInsights";
import { getCurrencyInfo } from "../../FunderInvestmentProduct/helper";
import { TMisfoProduct } from "../../../custom-hooks/useMifosProduct";

export default function PreviewInvesmentFundDetails() {
  const [modalActionType, setModalActionType] = useState("");
  const [modifier, setModifier] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [saveError, setSaveError] = useState("");
  const [activateState, setActivateState] = useState({
    comment: "",
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Preview Investment Fund Details - (PreviewInvesmentFundDetails.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const location = useLocation();
  const { data, mifosData } = location?.state as {
    data: any;
    mifosData: TMisfoProduct[];
  };

  const ADVANCLY_STORAGE_URL =
    "https://advanclystorage.blob.core.windows.net/advancly/";

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setActivateState({ ...activateState, [name]: value });
  };

  const dismissModalRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();

  useEffect(() => {
    console.log(data, data?.termSheetFileName);
  }, [data]);

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const reqBody = {
      reference: data?.reference,
      comment: activateState.comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.APPROVE_PENDING_INVESTMENT_PRODUCT}`,
        reqBody,
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewInvesmentFundDetails.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveError(errorHandler(error));
      setActivateState({ comment: "" });
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    }
  };
  const handleDecline = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const reqBody = {
      reference: data?.reference,
      comment: activateState.comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.REJECT_REQUEST}`,
        reqBody,
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewInvesmentFundDetails.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveError(errorHandler(error));
      setActivateState({ comment: "" });
      setTimeout(() => {
        history.push("/approval-requests");
      }, 3000);
    }
  };

  const discardChanges = (e: FormEvent) => {
    e.preventDefault();
    if (dismissModalRef && dismissModalRef.current) {
      dismissModalRef.current.click();
    }
  };
  let RenderedComponent: JSX.Element | null = null;
  if (
    (mifosData && data && data.lcyCorebankingId) ||
    (mifosData && data && data.usdCorebankingId)
  ) {
    if (mifosData && data && data.lcyCorebankingId) {
      const lcyData = mifosData.filter(
        (eachData) => eachData.id === Number(data.lcyCorebankingId),
      );
      RenderedComponent = (
        <div className="col-md-12 mb-3">
          <h6>Naira Core Banking Product</h6>
          <p className="page-subtitle">{lcyData[0]?.name || "N/A"}</p>
        </div>
      );
    }
    if (mifosData && data && data.usdCorebankingId) {
      const lcyData = mifosData.filter(
        (eachData) => eachData.id === Number(data.usdCorebankingId),
      );
      RenderedComponent = (
        <div className="col-md-12 mb-3">
          <h6>Dollar Core Banking Product</h6>
          <p className="page-subtitle">{lcyData[0]?.name || "N/A"}</p>
        </div>
      );
    }
  }
  return (
    <Fragment>
      <div className="animated fadeInRight mt-2">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
        </h3>
        <div className="d-flex flex-row flex-wrap  mt-3 mb-1">
          {saveError && <Alert message={saveError} />}
          {successMessage && <Alert message={successMessage} type="success" />}
          <h3 className="page-title">{data?.name}</h3>

          <div className="d-flex flex-row flex-wrap ">
            <button
              className="btn advancly-btn btn-md mr-3"
              disabled={loading}
              data-toggle="modal"
              data-target="#approvalRequestModal"
              onClick={() => {
                setModalActionType("approve");
                setModifier("investment");
              }}
            >
              Approve
            </button>
            <button
              className="btn advancly-dimred-btn btn-md m-0"
              disabled={loading}
              data-toggle="modal"
              data-target="#approvalRequestModal"
              onClick={() => {
                setModifier("investment");
                setModalActionType("decline");
              }}
            >
              Decline
            </button>
          </div>
        </div>
        <div className="row">
          {data &&
            data?.investmentProductDetails.map((_productDetails: any) => (
              <div className="col-8">
                <hr
                  style={{
                    width: "50vw",
                    marginLeft: 0,
                  }}
                ></hr>
                <h3 className="page-subtitle">Investment Details</h3>
                <hr
                  style={{
                    width: "50vw",
                    marginLeft: 0,
                  }}
                ></hr>
                <div className="col-md-6 mb-3">
                  <h6>Currency</h6>
                  <p className="page-subtitle">
                    {getCurrencyInfo(_productDetails?.currencyCode)?.name}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>is Investment Locked?</h6>
                  <p className="page-subtitle">
                    {_productDetails?.isLocked === true ? "YES" : "NO"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Start Date</h6>
                  <p className="page-subtitle">
                    {data?.startDate
                      ? moment(data?.startDate).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>End Date</h6>
                  <p className="page-subtitle">
                    {data?.endDate
                      ? moment(data?.endDate).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Objective</h6>
                  <p className="page-subtitle">
                    {data?.objective ? data?.objective : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Description</h6>
                  <p className="page-subtitle">
                    {data?.description ? data?.description : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Issuer</h6>
                  <p className="page-subtitle">
                    {data?.issuers ? data?.issuers : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Target Investor</h6>
                  <p className="page-subtitle">
                    {data?.targetInvestors ? data?.targetInvestors : "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Deposit Type</h6>
                  <p className="page-subtitle">
                    {_productDetails?.depositType || "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>Monthly Premature Liquidation Rate</h6>
                  <p className="page-subtitle">
                    {_productDetails?.monthlyPrematureLiquidationRate || "N/A"}
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <h6>End Of Tenure Premature Liquidation Rate</h6>
                  <p className="page-subtitle">
                    {_productDetails?.endOfTenurePrematureLiquidationRate ||
                      "N/A"}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Size</h6>
                  <p className="page-subtitle">
                    {formatMoney(_productDetails?.investmentSize)}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Product Type</h6>
                  <p className="page-subtitle">
                    {data?.investmentProductTypeName}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Product Subtype</h6>
                  <p className="page-subtitle">
                    {data?.investmentProductSubTypeName}
                  </p>
                </div>

                <div className="col-md-6 mb-3">
                  <h6>Investment Product Subtype Detail</h6>
                  <p className="page-subtitle">
                    {data?.investmentProductSubTypeDetailName}
                  </p>
                </div>
                <>
                  <div className="col-md-12 mb-3">
                    <hr
                      style={{
                        width: "50vw",
                        marginLeft: 0,
                      }}
                    ></hr>
                    <h3 className="page-subtitle mt-4">Financials</h3>
                    <hr
                      style={{
                        width: "50vw",
                        marginLeft: 0,
                      }}
                    ></hr>
                    <h6>
                      {getCurrencyInfo(_productDetails?.currencyCode)?.name}{" "}
                      (Min - Max Investment)
                    </h6>
                    <p className="page-subtitle">
                      {formatMoney(
                        _productDetails?.minimumInvestment,
                        getCurrencyInfo(_productDetails?.currencyCode)?.symbol,
                      )}{" "}
                      -{" "}
                      {formatMoney(
                        _productDetails?.maximumInvestment,
                        getCurrencyInfo(_productDetails?.currencyCode)?.symbol,
                      )}
                    </p>
                  </div>
                  <div className="col-md-12 mb-3">
                    <h6>Investment Size</h6>
                    <p className="page-subtitle">
                      {formatMoney(
                        _productDetails?.investmentSize,
                        getCurrencyInfo(_productDetails?.currencyCode)?.symbol,
                      ) || "N/A"}
                    </p>
                  </div>

                  <div className="col-md-12 mb-3">
                    <h6>Investment Termsheet</h6>
                    <p className="page-subtitle">
                      {data?.termSheetFileName || "N/A"}
                    </p>
                    <button
                      disabled={!data?.termSheetFileName}
                      className="btn advancly-btn mr-3"
                      onClick={() =>
                        window.open(
                          `${ADVANCLY_STORAGE_URL}${data?.termSheetUrl}`,
                          "_blank",
                        )
                      }
                    >
                      Download Term Sheet
                    </button>
                    {/*  <button className="btn advancly-btn">
                      View Term Sheet
                    </button>*/}
                  </div>
                </>

                {RenderedComponent}
              </div>
            ))}
        </div>
      </div>

      <ApprovalInvestorModal
        onSubmit={handleSubmit}
        onDecline={handleDecline}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
        handleChange={handleChange}
        modifier={modifier}
      />
    </Fragment>
  );
}
