import React, { useState } from "react";
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

const DisburseApprovedBatchedLoans = (props) => {
  const {
    properties: { loans, disburseBatchLoans },
    onCloseModal,
  } = props;
  const submitDisbursedBatchLoans = (e) => {
    e.preventDefault();
    disburseBatchLoans(loans);
    onCloseModal();
  };
  const [state] = useState({
    disbursementTag: "",
    comment: "",
    error: false,
    funderOptions: [],
    selectedFunder: "",
    selectedDate: "",
    failedApprovedBatchLoans: false,
    successfulApprovedBatchLoans: false,
    failedRejectedBatchLoans: false,
    successfulRejectedBatchLoans: false,
  });

  // const disburseBatchLoans = async () => {
  //   document.querySelector("#disbursement-confirm").classList.add("hidden");
  //   document.querySelector("#disbursement-loader").classList.remove("hidden");

  //   try {
  //     const response = await postDataWithDotNet(
  //       ajaxEndpoints.DISBURSEMENT_APPROVED_BATCH_DISBURSEMENT,
  //       loans
  //     );

  //     console.log("object", response);
  //     queryCache.invalidateQueries(["getBatchDisbursement"]);
  //     resetLoansForDisbursement();
  //     document.querySelector("#disbursement-loader").classList.add("hidden");
  //     document
  //       .querySelector("#disbursement-success")
  //       .classList.remove("hidden");
  //     if (response?.data?.data?.success.length > 0) {
  //       setState({
  //         ...state,
  //         successfulApprovedBatchLoans: response.data.success,
  //       });
  //     }
  //     if (response?.data?.data?.failure.length > 0) {
  //       setState({ ...state, failedApprovedBatchLoans: response.data.failure });
  //     }
  //     setTimeout(() => {
  //       onCloseModal();
  //       setSuccess(null);
  //     }, 3000);
  //   } catch (error) {
  //     console.log("error", error);
  //     queryCache.invalidateQueries(["getBatchDisbursement"]);
  //     setDisburseError(errorHandler(error));

  //     setTimeout(() => {
  //       setDisburseError(null);
  //     }, 3000);
  //   }
  // };
  let renderSuccessfulApprovedData,
    renderApprovedFailedData,
    renderSuccessfulRejectedData,
    renderRejectedFailedData;
  const {
    failedApprovedBatchLoans,
    successfulApprovedBatchLoans,
    failedRejectedBatchLoans,
    successfulRejectedBatchLoans,
  } = state;
  if (successfulApprovedBatchLoans || failedApprovedBatchLoans) {
    if (successfulApprovedBatchLoans) {
      renderSuccessfulApprovedData = (
        <div>
          <p className="text-success">See successful approved loans below: </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan Id</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {successfulApprovedBatchLoans.map((successfulBatchLoan) => {
                return (
                  <tr>
                    {/* <td style={{ width: 50 }}>{successfulBatchLoan.id}</td> */}
                    <td style={{ width: 50 }}>
                      {successfulBatchLoan.data.data.entityTypeId}
                    </td>
                    <td>{successfulBatchLoan.data.data.message}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
    if (failedApprovedBatchLoans) {
      renderApprovedFailedData = (
        <div>
          <p className="text-danger">
            See failed approved loan reasons below:{" "}
          </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan Id</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {failedApprovedBatchLoans.map((failedBatchLoan) => {
                return (
                  <tr>
                    {/* <td style={{ width: 50 }}>{failedBatchLoan.id}</td> */}
                    <td style={{ width: 50 }}>
                      {failedBatchLoan.data.data.data.entityTypeId}
                    </td>
                    <td>{failedBatchLoan.data.data.message}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  } else if (successfulRejectedBatchLoans || failedRejectedBatchLoans) {
    if (successfulRejectedBatchLoans) {
      renderSuccessfulRejectedData = (
        <div className="m-b-2 m-t-1">
          <p className="text-success">See successful rejected loans below: </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan ref</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {successfulRejectedBatchLoans.map(
                (successfulRejectedBatchLoan) => {
                  return (
                    <tr>
                      {/* <td style={{ width: 50 }}>
                          {successfulRejectedBatchLoan.id}
                        </td> */}
                      <td style={{ width: 50 }}>
                        {successfulRejectedBatchLoan.data}
                      </td>
                      <td>{successfulRejectedBatchLoan.message}</td>
                    </tr>
                  );
                }
              )}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
    if (failedRejectedBatchLoans) {
      renderRejectedFailedData = (
        <div className="m-b-2 m-t-1">
          <p className="text-danger">
            See failed rejected loans reasons below:{" "}
          </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan ref</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {failedRejectedBatchLoans.map((failedRejectedBatchLoan) => {
                return (
                  <tr>
                    {/* <td style={{ width: 50 }}>
                        {failedRejectedBatchLoan.id}
                      </td> */}
                    <td style={{ width: 50 }}>
                      {failedRejectedBatchLoan.data}
                    </td>
                    <td>{failedRejectedBatchLoan.message}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h2 className="Modal__title p-b-2 text-center font-weight-bold">
            Disburse Summary
          </h2>
          {/*disburse batch approved loan  Modal */}
          <div id="disbursement-confirm">
            <h5 className="text-center">
              Are you sure you want to disburse loans for?{" "}
            </h5>

            <div className="m-t-2 m-b-2">
              {loans &&
                loans.map((loan) => {
                  return (
                    <>
                      <span>
                        <span className="font-weight-bold">Product Id:</span>{" "}
                        {loan.productId}
                      </span>
                      &nbsp; &nbsp; &nbsp;
                      <span>
                        <span className="font-weight-bold">Loan Count:</span>{" "}
                        {loan.loanCount}
                      </span>
                    </>
                  );
                })}
            </div>

            <form>
              <div className="Form__action-buttons m-t-1">
                <button
                  type="button"
                  className="btn zoom-element btn login-btn m-0 mr-auto"
                  onClick={onCloseModal}
                >
                  <i className="fas fa-times-circle"></i>
                  &nbsp; Cancel
                </button>
                <button
                  type="submit"
                  className="btn zoom-element btn login-btn m-0 ml-auto"
                  onClick={submitDisbursedBatchLoans}
                  data-dismiss="modal"
                >
                  <i className="fas fa-arrow-right"></i>
                  &nbsp; Proceed
                </button>
              </div>
            </form>
          </div>
          {/* 2. Disbursement Loader*/}
          <div
            id="disbursement-loader"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className=" form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier fas fa-user-cog" />
                  Processing Disbursement ...
                </div>
              </div>
            </div>
          </div>
          {/* 3. Disbursement Success Message*/}
          <div
            id="disbursement-success"
            className="hidden speed-1x fadeInRight"
          >
            {(successfulApprovedBatchLoans || failedApprovedBatchLoans) && (
              <div className="m-b-3">
                <div>
                  <div className="row m-t-2 grid__padding">
                    {successfulApprovedBatchLoans &&
                    successfulApprovedBatchLoans.length > 0 ? (
                      <div> {renderSuccessfulApprovedData} </div>
                    ) : (
                      "No Successful Approval."
                    )}
                  </div>
                  <div className="row m-t-2 grid__padding">
                    {failedApprovedBatchLoans &&
                    failedApprovedBatchLoans.length > 0 ? (
                      <div>{renderApprovedFailedData}</div>
                    ) : (
                      "No Failed Approval."
                    )}
                  </div>
                </div>
              </div>
            )}
            {(successfulRejectedBatchLoans || failedRejectedBatchLoans) && (
              <div className="m-b-3">
                <div>
                  <div className="row m-t-2 grid__padding">
                    {successfulRejectedBatchLoans &&
                    successfulRejectedBatchLoans.length > 0 ? (
                      <div>{renderSuccessfulRejectedData}</div>
                    ) : (
                      "No Successful Rejection."
                    )}
                  </div>
                  <div className="row m-t-2 grid__padding">
                    {failedRejectedBatchLoans &&
                    failedRejectedBatchLoans.length > 0 ? (
                      <div>{renderRejectedFailedData}</div>
                    ) : (
                      "No Failed Rejection."
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="Form__action-buttons m-t-2"></div>
            <button
              type="button"
              onClick={props.onCloseModal}
              className="btn zoom-element btn login-btn m-0"
            >
              <i className="fa fa-times-circle" /> Close
            </button>
          </div>

          {/* 4. Disbursement Error */}
          <div
            id="disbursement-error"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <ErrorLoader
              icon="fas fa-wifi"
              text="Network Issue"
              subText="Unable to connect to Advancly services."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisburseApprovedBatchedLoans;
