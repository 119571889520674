import styles from "./NoGraphData.module.scss";
import { ReactComponent as DummyGraph } from "../../../../../../assets/svg/icons/dummy-graph.svg";

export default function NoGraphData() {
  return (
    <div className={styles.noGraphData}>
      <DummyGraph />
      There is no data available
    </div>
  );
}
