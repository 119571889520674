import React from "react";

import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface IPrivateRoute {
  component: React.ComponentType;
  path: string;
  permittedRoles: string[];
  exact: boolean;
}

const PrivateRoute = ({
  component: PrivateComponent,
  path,
  permittedRoles,
  exact,
}: IPrivateRoute) => {
  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const isUserPermitted = (permittedRolesForRoute: string[]): boolean => {
    const truth = [] as boolean[];

    permittedRolesForRoute.forEach((_permittedRole) => {
      const result = loggedInUser?.roles.includes(_permittedRole);
      if (result) {
        truth.push(true);
      } else {
        truth.push(false);
      }
    });

    const finalResult = truth.every((item) => item === true);
    if (finalResult) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return isUserPermitted(permittedRoles) &&
          !loggedInUser?.firstTimeLogin ? (
          <PrivateComponent />
        ) : (
          <Redirect to={{ pathname: "/access-denied" }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
