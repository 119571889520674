import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  Page: number;
  PageSize: number;
  Reference?: string;
  AggregatorId?: string;
  DateRepaidFrom?: string;
  DateRepaidTo?: string;
  RepaymentStatus?: string;
  RepaymentChannel?: string;
};

const getAutoPaidRepaymentdetails = async ({
  Page,
  PageSize,
  Reference,
  AggregatorId,
  DateRepaidFrom,
  DateRepaidTo,
  RepaymentStatus,
  RepaymentChannel,
}: TQuery) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}`;
  if (Reference) {
    queryParams = `${queryParams}&Reference=${Reference}`;
  }
  if (AggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${AggregatorId}`;
  }
  if (DateRepaidFrom) {
    queryParams = `${queryParams}&DateRepaidFrom=${DateRepaidFrom}`;
  }
  if (DateRepaidTo) {
    queryParams = `${queryParams}&DateRepaidTo=${DateRepaidTo}`;
  }
  if (RepaymentStatus) {
    queryParams = `${queryParams}&RepaymentStatus=${RepaymentStatus}`;
  }
  if (RepaymentChannel) {
    queryParams = `${queryParams}&RepaymentChannel=${RepaymentChannel}`;
  }

  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GETALLPAIDREPAYMENTDETAILS}${queryParams && queryParams}`
  );
  return data;
};

export default function useAutoPaidRepaymentdetails({
  Page,
  PageSize,
  Reference,
  AggregatorId,
  DateRepaidFrom,
  DateRepaidTo,
  RepaymentStatus,
  RepaymentChannel,
}: TQuery) {
  return useQuery(
    [
      {
        Page,
        PageSize,
        Reference,
        AggregatorId,
        DateRepaidFrom,
        DateRepaidTo,
        RepaymentStatus,
        RepaymentChannel,
      },
      "getAutoPaidRepaymentdetails",
    ],
    getAutoPaidRepaymentdetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
