import { useState, useEffect, SyntheticEvent } from "react";
import { MDBTable } from "mdbreact";

// Router
import { NavLink, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import useAuditTrail from "../../custom-hooks/useAuditTrailData";
import { showModal } from "../../redux/actions/modal";

// UI Components
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import useAuditTrailTypes from "../../custom-hooks/useAuditTrailTypes";
import { appInsights } from "../../config/appInsights";
import { RootState } from "../../redux/store";

const today = new Date().toISOString().slice(0, 10);
const lastMonth = new Date(new Date());
lastMonth.setDate(lastMonth.getDate() - 30);
const aMonthAgo = lastMonth.toISOString().slice(0, 10);

const AuditTrail = () => {
  const [auditType, setAuditType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [size, setSize] = useState(20);
  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [date, setDate] = useState({
    start_date: aMonthAgo,
    end_date: today,
  });

  const { isAuthenticated, loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();

  //  Methods
  const modalHandler = (modalData: any, type?: any) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      })
    );
  };

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  const filterData = (e: SyntheticEvent) => {
    e.preventDefault();

    const diffTime =
      new Date(endDate).valueOf() - new Date(startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    }

    resetTable();
    setDate({
      start_date: startDate,
      end_date: endDate,
    });
  };

  //  Life Cycle Hooks
  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Audit/Trail - (AuditTrail.js)",
      isLoggedIn: true,
    });
  }, []);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error } = useAuditTrail({
    audit_type_id: auditType,
    start_date: date.start_date,
    end_date: date.end_date,
    page: pageNumber,
    size,
  });
  const {
    data: auditTrailTypesData,
    status: auditTrailTypesStatus,
    error: auditTrailTypesError,
  } = useAuditTrailTypes();

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Audit trails
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading" || auditTrailTypesStatus === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading audit trail..." />
    );
  } else {
    if (error || auditTrailTypesError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              audit trails, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <thead className="bg-lighter-grey">
              <tr>
                <th>S/N</th>
                <th>Username</th>
                <th>Audit Type</th>
                <th>Client IP Address</th>
                <th>Date</th>
                <th>Time</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.data &&
                data?.data?.data
                  // .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                  .map((auditTrail, _id) => {
                    const {
                      audit_type,
                      client_ip_address,
                      action_date,
                      username,
                      id,
                    } = auditTrail;
                    return (
                      <tr key={id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{username}</td>
                        <td>{audit_type}</td>
                        <td>{client_ip_address}</td>
                        <td>{action_date.substring(0, 10)}</td>
                        <td>{action_date.substring(11, 19)}</td>
                        <td>
                          <span
                            id="0"
                            data-table="Notifications"
                            data-action="view"
                            className="Notifications view btn-manage zoom-element btn-slim"
                            onClick={() => {
                              modalHandler(auditTrail);
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </MDBTable>
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Audit Trail Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12 dashboard-title flex__row">
              <h3 className="page-title">Audit Trail</h3>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="m-l-2 m-r-1"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <label htmlFor="table-fetch" className="form-group">
                  Showing results for:{" "}
                  <select
                    onChange={(e) => {
                      resetTable();
                      setAuditType(e.target.value);
                    }}
                    id="table-fetch"
                    className="table-fetch-select"
                    style={{ maxWidth: "200px" }}
                  >
                    <option value="">All audits</option>
                    {auditTrailTypesData &&
                      auditTrailTypesData.map((audit_type: any) => {
                        const { name, id } = audit_type;
                        return (
                          <option value={id} key={id}>
                            {name}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
              <label htmlFor="table-fetch" className="form-group">
                Number of results:{" "}
                <select
                  onChange={(e) => {
                    resetTable();
                    setSize(Number(e.target.value));
                  }}
                  id="table-fetch"
                  className="table-fetch-select m-l-1"
                  value={size}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>

            <form className="d-flex flex-row m-t-md" onSubmit={filterData}>
              <div
                className="d-flex flex-row flex-wrap justify-content-start"
                style={{ alignItems: "flex-end" }}
              >
                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">Start Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={aMonthAgo}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="m-l-1 m-r-1 m-b-1 form-group">
                  <label htmlFor="table-fetch">End Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    defaultValue={today}
                    max={today}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <button
                  className="m-l-1 m-r-1 zoom-element btn login-btn"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </form>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={1000}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </div>
  );
};

export default AuditTrail;
