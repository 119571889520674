import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";

// Router
import { NavLink, withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { reportIssue } from "../../redux/actions/issues";
import { showModal } from "./../../redux/actions/modal";

//Table Helper Controllers
import {
  filterTableData,
  filterDataByColumns,
  attachActionButtons,
} from "./../../helpers/tableControllers";

// UI Components
import { InfoLoader, Loader } from "../../UI/Loaders/Loaders";
import Tabs from "../Tabs/Tabs";

export class ReportIssues extends Component {
  // Component State
  state = {
    activeTab: "all",
    tabs: [
      {
        name: "All Issues",
        id: "all",
        icon: "fas fa-bug",
        identifier: "issuesTab",
      },
      {
        name: "Pending Issues",
        id: "pending",
        icon: "fas fa-hourglass-start",
        identifier: "issuesTab",
      },
      {
        name: "Resolved Issues",
        id: "resolved",
        icon: "fas fa-check-circle",
        identifier: "issuesTab",
      },
      {
        name: "Cancelled Issues",
        id: "cancelled",
        icon: "fas fa-times-circle",
        identifier: "issuesTab",
      },
    ],
  };

  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      activeTab: content,
    });
  };

  // Open modal
  modalHandler = (modalData) => {
    this.props.showModal(modalData);
  };

  //Component Life Cycle Hooks
  componentDidMount() {
    // If user is not authenticated, redirect to login
    if (!this.props.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.reportIssue();
  }

  //JSX Template
  render() {
    const { tabs, activeTab } = this.state;

    let RenderedComponent = null;

    // Load Issues properties from Issues Reducer - Redux
    const { issuesTableData, loading } = this.props;

    //  If issues object is null e.g. Initial component render before API fetch
    if (issuesTableData === null && loading === false) {
      RenderedComponent = (
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <InfoLoader
            text="There was an issue fetching your reported issues"
            subText="Kindly refresh the page."
          />
        </div>
      );
    } else if (issuesTableData === null && loading === true) {
      RenderedComponent = <Loader />;
    } else {
      // Verify that a issues object exists
      if (issuesTableData === undefined || issuesTableData === null) {
        RenderedComponent = (
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <InfoLoader
              text="You haven not reported any issue."
              subText="Do have a pleasant experience."
            />
          </div>
        );
      } else if (issuesTableData.rows) {
        if (issuesTableData.rows.length > 0) {
          // If a issues object exists, filter the rows property to reflect selected Tab
          const tableName = "Issue";
          const actions = ["View", "Cancel"];
          const filteredData = filterTableData(issuesTableData, activeTab);
          const columnsToExclude = [""];
          let newTableData = filterDataByColumns(
            filteredData,
            "exclude",
            columnsToExclude
          );
          let newTableRows = attachActionButtons(
            newTableData.rows,
            tableName,
            actions,
            this.modalHandler
          );
          newTableData = {
            columns: [...newTableData.columns],
            rows: [...newTableRows],
          };

          RenderedComponent = (
            <div className="grid__padding animated fadeInLeft">
              <MDBDataTable
                responsive
                minimal="true"
                striped
                bordered
                hover
                data={newTableData}
              />
            </div>
          );
        }
      } else {
        RenderedComponent = (
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <InfoLoader
              text="There was an issue fetching your reported issues"
              subText="Kindly refresh the page."
            />
          </div>
        );
      }
    }

    return (
      <div className="datatable-wrapper">
        <div className="table-view">
          {/*Manage Issues Header*/}
          <div className="block-header">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-7 dashboard-title animated fadeInRight flex__row">
                <h3 className="m-l-1">
                  <aside className="rounder">
                    <i className="fas fa-bug" />
                  </aside>
                  &nbsp; Reported Issues
                </h3>

                <div className="breadcrumb__location">
                  <NavLink to="dashboard">
                    <h3 className="breadcrumb__location__title">Dashboard</h3>
                  </NavLink>
                  <span className="breadcrumb__location__icon">
                    <i className="fas fa-angle-double-right" />
                  </span>
                  <NavLink to="manage-issues">
                    <h3 className="breadcrumb__location__title">
                      Reported Issues
                    </h3>
                  </NavLink>
                </div>
              </div>
            </div>

            {/*User & Financial Stats*/}
            <div className="row clearfix mt-30 grid__padding animated fadeInRight">
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="body text-center">
                    <h3 className="m-t-0">500</h3>
                    <p className="text-muted">Your Reported Issues</p>
                    <div className="progress progress-xs m-t-sm white bg">
                      <div
                        className="progress-bar success"
                        datatoggle="tooltip"
                        dataoriginaltitle="50%"
                        style={{ width: "50%" }}
                      />
                    </div>
                    <small>20% higher than last month</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="body text-center">
                    <h3 className="m-t-0">50</h3>
                    <p className="text-muted">Your Cancelled Issues</p>
                    <div className="progress progress-xs m-t-sm white bg">
                      <div
                        className="progress-bar progress-bar-danger"
                        datatoggle="tooltip"
                        dataoriginaltitle="10%"
                        style={{ width: "10%" }}
                      />
                    </div>
                    <small>2% higher than last month</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="body text-center">
                    <h3 className="m-t-0">350</h3>
                    <p className="text-muted">Your Pending Issues</p>
                    <div className="progress progress-xs m-t-sm white bg">
                      <div
                        className="progress-bar progress-bar-warning"
                        datatoggle="tooltip"
                        dataoriginaltitle="68%"
                        style={{ width: "68%" }}
                      />
                    </div>
                    <small>15% higher than last month</small>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="body text-center">
                    <h3 className="m-t-0">100</h3>
                    <p className="text-muted">Your Resolved Issues</p>
                    <div className="progress progress-xs m-t-sm white bg">
                      <div
                        className="progress-bar progress-bar-success"
                        datatoggle="tooltip"
                        dataoriginaltitle="38%"
                        style={{ width: "38%" }}
                      />
                    </div>
                    <small> 1% higher than last month</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-lg-12 dashboard-title m-l-1">
                <Tabs onContentToggle={this.toggleTabContent} tabs={tabs} />
              </div>
            </div>
          </div>

          {/*Datatable*/}
          <div>{RenderedComponent}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, loggedInUser } = state.authReducer;
  const { issuesTableData, loading, error } = state.issuesReducer;

  return {
    isAuthenticated,
    loggedInUser,
    issuesTableData,
    loading,
    error,
  };
};

export default withRouter(
  connect(mapStateToProps, { reportIssue, showModal })(ReportIssues)
);
