import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getBatchDisbursement = async () => {
  const data = await getData(ajaxEndpoints.ALL_APPROVER_BATCH_DISBURSEMENT);
  return data.data;
};

export default function useBatchDisbursement() {
  return useQuery(["getBatchDisbursement"], getBatchDisbursement);
}
