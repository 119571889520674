import { useEffect } from "react";

// Style
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useHistory } from "react-router-dom";

import "./ContactUs.css";

const ContactUs = () => {
  const history = useHistory();

  const { isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, []);

  return (
    <div className="Login center-container  normal-1x zoomIn">
      <div className="center-container-wrapper">
        <div className="container">
          <div className="contact-row">
            <div className="column--1">
              <div className="contact-info">
                <div className="contact-details">
                  <i className="fas fa-mobile-alt" aria-hidden="true" />
                  <h2>Phone</h2>
                  <p>+234 000 000 000</p>
                </div>

                <div className="contact-details">
                  <i className="fas fa-address-book" aria-hidden="true" />
                  <h2>Address</h2>
                  <p>No 42, Local Airport road, concord press, Ikeja Lagos</p>
                </div>

                <div className="contact-details">
                  <i className="fas fa-mail-reply" aria-hidden="true" />
                  <h2>Email</h2>
                  <p>support@info.com</p>
                </div>

                <div className="contact-social">
                  <a href="##">
                    <i className="fab fa-facebook-square" aria-hidden="true" />
                  </a>

                  <a href="##">
                    <i className="fab fa-instagram" aria-hidden="true" />
                  </a>

                  <a href="##">
                    <i className="fab fa-twitter-square" aria-hidden="true" />
                  </a>

                  <a href="##">
                    <i className="fab fa-youtube-square" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
            <div className="column--2">
              <form className="contact__form">
                <h2 className="form-header">Get in touch</h2>
                <div className="form__group">
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    className="input__length"
                    placeholder="First Name"
                  />
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    className="input__length"
                    placeholder="Last Name"
                  />
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    className="input__length"
                    placeholder="Phone"
                  />
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    className="input__length"
                    placeholder="Company name"
                  />
                </div>

                <textarea
                  className="text-area"
                  id="subject"
                  name="subject"
                  placeholder="Message Us"
                ></textarea>
                <input
                  className="contact-button"
                  type="submit"
                  value="Submit"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
