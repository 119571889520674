import React, { ChangeEvent, useEffect } from "react";
import moment from "moment";
import { appInsights } from "../../../../../config/appInsights";
import CustomInputField from "../../../../../NewComponents/CustomHTMLElements/CustomInputField";
import { formatMoney } from "../../../../../helpers/formatter";
import style from "../../../Components/RollOverModal/RollOverModal.module.scss";
import { ITopUpInfo } from "./index";
import { convertToBase64 } from "../../../../../helpers/convertToBase64";
import { useForm } from "react-hook-form";

interface IProps {
  currency: string;
  availableBalance: number;
  topUpInfo: ITopUpInfo;
  setTopUpInfo: (info: ITopUpInfo) => void;
  handleSubmitTopUp: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChangeAmount: (e: ChangeEvent) => void;
  handleChangeNarration: (e: ChangeEvent) => void;
  isLoading: boolean;
  setErrorMessage: (msg: string) => void;
}

const InvestorWalletTopUpViaBankTransfer = ({
  currency,
  setErrorMessage,
  handleSubmitTopUp,
  topUpInfo,
  setTopUpInfo,
  handleChangeAmount,
  handleChangeNarration,
  isLoading,
}: IProps) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "InvestorWalletTopUpViaBankTransfer.tsx",
      isLoggedIn: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError: setFieldError,
  } = useForm({});

  const onChangeReceipt = async (e: any) => {
    if (!e.target.files[0]) return;
    const fileType = e.target.files[0].type;
    const isValidFile =
      fileType.startsWith("image/png") ||
      fileType.startsWith("image/jpg") ||
      fileType.startsWith("image/jpeg") ||
      fileType.startsWith("application/pdf");

    if (!isValidFile) {
      return setErrorMessage("Invalid File type.");
    }
    const fileBase64: any = await convertToBase64(e.target.files[0]);
    setValue("transactionReceipt", e.target.files[0].name);
    setTopUpInfo({
      ...topUpInfo,
      base64_file_string: fileBase64,
      filename_with_extension: e.target.files[0].name,
    });
  };

  function handleChangeEffectiveDate(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setTopUpInfo({
      ...topUpInfo,
      transaction_date: moment(target.value).format("YYYY-MM-DDTHH:mm:ss"),
    });
  }

  return (
    <div>
      <div className="col-md-6">
        <h3 className="page-subtitle">Top Up Via Bank Transfer</h3>
        <form className="m-t-md" onSubmit={handleSubmit(handleSubmitTopUp)}>
          <div>
            <CustomInputField
              type="number"
              name="amount"
              label={`Enter Amount ${formatMoney(topUpInfo.amount, currency)}`}
              placeholder="e.g. 1000"
              onChange={handleChangeAmount}
              value={topUpInfo.amount || ""}
            />
          </div>
          <div>
            <CustomInputField
              name="narration"
              label="Narration"
              placeholder="Enter Narration"
              onChange={handleChangeNarration}
              value={topUpInfo.narration || ""}
            />
          </div>
          <div>
            <CustomInputField
              type="date"
              label="Effective Date"
              max={moment(new Date()).format("YYYY-MM-DD")}
              name="transaction_date"
              onChange={handleChangeEffectiveDate}
            />
          </div>
          <div>
            <CustomInputField
              type="file"
              placeholder=""
              label="Kindly upload the transaction receipt"
              onChange={onChangeReceipt}
              name="transactionReceipt"
              accept="image/png, image/jpg, image/jpeg, application/pdf"
            />
          </div>
          <div className={style.finalSection}>
            <button
              className="btn advancly-btn m-0 btn-block"
              disabled={
                !topUpInfo?.narration ||
                !topUpInfo?.amount ||
                isLoading ||
                !topUpInfo.base64_file_string ||
                !topUpInfo?.transaction_date
              }
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm text-white" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvestorWalletTopUpViaBankTransfer;
