import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";

export const getAllIssues = (queryString: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.ALL_ISSUES_START,
    });

    let endpoint = ajaxEndpoints.ALL_ISSUES;
    if (queryString) {
      endpoint = `${endpoint}?${queryString}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch(getAllIssuesSuccess(response));
        } else {
          dispatch({
            type: actionTypes.ALL_ISSUES_NETWORK_FAILURE,
            error: {
              message: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "issues.js",
          },
        });
        dispatch({
          type: actionTypes.ALL_ISSUES_ERROR,
          error,
        });
      });
  };
};

export const getPendingIssues = (queryString: any) => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.PENDING_ISSUES_START,
    });

    let endpoint = ajaxEndpoints.PENDING_ISSUES;
    if (queryString) {
      endpoint = `${endpoint}?${queryString}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch(getPendingIssuesSuccess(response));
        } else {
          dispatch({
            type: actionTypes.PENDING_ISSUES_NETWORK_FAILURE,
            error: {
              message: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "issues.js",
          },
        });
        dispatch({
          type: actionTypes.PENDING_ISSUES_ERROR,
          error: {
            message: "Network Error",
          },
        });
      });
  };
};

export const reportIssue = () => {
  return function (dispatch: any) {
    dispatch({
      type: actionTypes.REPORT_ISSUE_START,
    });

    ajaxMethods
      .get(ajaxEndpoints.REPORT_ISSUE)
      .then((response) => {
        if (response) {
          dispatch({
            type: actionTypes.REPORT_ISSUE_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: actionTypes.REPORT_ISSUE_NETWORK_FAILURE,
            error: {
              message: "Network Error",
            },
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "issues.js",
          },
        });
        dispatch({
          type: actionTypes.REPORT_ISSUE_ERROR,
          error,
        });
      });
  };
};

export const getAllIssuesSuccess = (response: any) => {
  const tableColumns = [
    { label: "ID", field: "issue_id", sort: "asc", width: 150 },
    { label: "ISSUE REFERENCE", field: "issue_ref", sort: "asc", width: 150 },
    { label: "USER ROLE", field: "user_type", sort: "asc", width: 200 },
    { label: "REPORTED BY", field: "creator", sort: "asc", width: 200 },
    { label: "ISSUE DESCRIPTION", field: "issue_msg", sort: "asc", width: 100 },
    { label: "ISSUE TYPE", field: "issue_type", sort: "asc", width: 200 },
    { label: "DATE CREATED", field: "pub_date", sort: "asc", width: 100 },
    { label: "STATUS", field: "issue_status", sort: "asc", width: 100 },
    { label: "MANAGE", field: "manage", sort: "asc", width: 100 },
  ];

  const reOrderedTableData = reOrderRows(tableColumns, response.data);

  return {
    type: actionTypes.ALL_ISSUES_SUCCESS,
    payload: {
      allIssuesTableData: reOrderedTableData,
      // issues: [...response.data],
      count: response.count,
      next_page: response.next_page,
      prev_page: response.prev_page,
    },
  };
};

export const getPendingIssuesSuccess = (response: any) => {
  const tableColumns = [
    { label: "ID", field: "issue_id", sort: "asc", width: 150 },
    { label: "ISSUE REFERENCE", field: "issue_ref", sort: "asc", width: 150 },
    { label: "USER ROLE", field: "user_type", sort: "asc", width: 200 },
    { label: "REPORTED BY", field: "creator", sort: "asc", width: 200 },
    { label: "ISSUE DESCRIPTION", field: "issue_msg", sort: "asc", width: 100 },
    { label: "ISSUE TYPE", field: "issue_type", sort: "asc", width: 200 },
    { label: "DATE CREATED", field: "pub_date", sort: "asc", width: 100 },
    { label: "STATUS", field: "issue_status", sort: "asc", width: 100 },
    { label: "MANAGE", field: "manage", sort: "asc", width: 100 },
  ];

  const reOrderedTableData = reOrderRows(tableColumns, response.data);

  return {
    type: actionTypes.PENDING_ISSUES_SUCCESS,
    payload: {
      pendingIssuesTableData: reOrderedTableData,
      // issues: [...response.data],
      count: response.count,
      next_page: response.next_page,
      prev_page: response.prev_page,
    },
  };
};

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns: any, responseDataRecords: any) => {
  let newRows = [] as any[];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row: any, index: number) => {
        let newRow = {} as any;
        tableColumns.forEach((column: any) => {
          if (column.field === "id") {
            newRow[column.field] = index + 1;
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};
