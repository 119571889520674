import React, { Fragment } from "react";

interface IModalDetailsRow {
  label?: any;
  value?: any;
  icon?: any;
  type?: any;
}

export default function ModalDetailsRow({
  label,
  value,
  icon,
  type,
}: IModalDetailsRow) {
  return (
    <Fragment>
      {type && type === "textarea" ? (
        <Fragment>
          <label className="label-txt">{label}</label>
          <div className="form-group inputWithIcon">
            <textarea
              className="user__form form-control"
              readOnly
              value={value}
              rows={3}
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <label className="label-txt">{label}</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={value}
            />
            <i className={icon} />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
