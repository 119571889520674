import React, { useState, ChangeEvent } from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler.component";

interface ICustomInputField {
  type?: string;
  name?: string;
  placeholder?: string;
  reference?: string;
  label?: string;
  errors?: any;
  maxLength?: number;
  max?: string | number;
  defaultValue?: string | number;
  minLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  extraLabel?: string;
  min?: string | number;
  showRequiredIcon?: boolean;
  step?: string | number;
  hasActionButton?: () => void;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  onBlur?: () => void;
  value?: string | number | readonly string[] | undefined;
  id?: string;
  accept?: string;
  styles?: React.CSSProperties;
}
const CustomInputField = ({
  type,
  name,
  placeholder,
  reference,
  label,
  errors,
  maxLength,
  max,
  defaultValue,
  minLength,
  onChange,
  readOnly,
  extraLabel,
  min,
  showRequiredIcon,
  step,
  hasActionButton,
  actionButtonText,
  onClickActionButton,
  value,
  id,
  onBlur,
  accept,
  styles,
}: ICustomInputField) => {
  const [showPassword] = useState(false);
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        {label && <label className="form-label">{label}</label>}
        {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
        {extraLabel && extraLabel.length && (
          <label className="form-label ml-2">
            <small className="crimson">{extraLabel}</small>
          </label>
        )}
      </div>
      <div className="input-group">
        <input
          className={`form-control ${hasActionButton && "border-right-0"}`}
          type={
            showPassword ? (showPassword === true ? "text" : "password") : type
          }
          name={name}
          max={max}
          min={min}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue}
          onChange={onChange}
          readOnly={readOnly}
          step={step}
          value={value}
          id={id}
          onBlur={onBlur}
          accept={accept}
          style={styles}
        />
        {hasActionButton && (
          <div
            className="input-group-append cursor-pointer"
            onClick={onClickActionButton}
          >
            <span className="input-group-text text-capitalize">
              {actionButtonText}
            </span>
          </div>
        )}
      </div>
      <ErrorHandler errors={errors} />
    </div>
  );
};

export default CustomInputField;
