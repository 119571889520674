import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TResponse = {
  data: {
    aggregator_details: {
      aggregator_id: number;
      biz_Address: string;
      biz_category: string;
      biz_email: string;
      biz_name: string;
      biz_phone: string;
      biz_phone_dial_code: string | null;
      currency: string;
      is_active: boolean;
      photo: string;
    };
    borrower_details: {
      borrower_id: number;
      first_name: string;
      last_name: string;
    };
    loan_id: number;
    loan_amount: number;
    loan_interest_amount: number;
    loan_ref: string;
    product_name: string;
    pub_date: string;
  }[];
};

const getFailedPendingDisbursement = async () => {
  const { data } = await getData(ajaxEndpoints.GET_FAILED_DISBURSEMENT);
  return data;
};

export default function useFailedPendingDisbursement() {
  return useQuery<TResponse>(
    ["getFailedPendingDisbursement"],
    getFailedPendingDisbursement,
    {
      refetchOnWindowFocus: false,
    }
  );
}
