import ajaxApi, { ajaxReportApi } from "../api/axios-register";

export const getData = async (url) => {
  try {
    const data = await ajaxApi({
      method: "GET",
      url,
    });
    return data.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/logout");
    } else {
      return error;
    }
  }
};

export const getReportData = async (url) => {
  try {
    const data = await ajaxReportApi({
      method: "GET",
      url,
    });
    return data.data;
  } catch (error) {
    if (error.response.status === 401) {
      window.location.replace("/logout");
    } else {
      return error;
    }
  }
};

export const postData = async (url, reqBody, headers) => {
  const data = await ajaxApi({
    method: "POST",
    url,
    data: reqBody,
    headers: headers,
  });
  return data.data;
};

export const deleteData = async (url, reqBody) => {
  const data = await ajaxApi({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data.data;
};
