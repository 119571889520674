const RepayForm = (props: any) => {
  const {
    repaymentChannelOptions,
    selectedRepaymentId,
    bankId,
    accountNumber,
    bankOptionsList,
    repayEdit,
    error,
    accountLoading,
    accountName,
  } = props;

  return (
    <div className="card">
      <div className="body text-center">
        <h3 className="m-t-0 m-b-3">
          <i className="adv-icon fas fa-cogs" />
          Repayment Channel Settings
        </h3>

        <div className="grid__full m-b-1">
          <div className="p-l-0 grid__padding">
            <label className="label-txt"> Repayment channel </label>
            <div className="form-group">
              <select
                data-form="repayment"
                className="round user__form form-control"
                value={selectedRepaymentId}
                placeholder="Select a channel"
                name="selectedRepaymentId"
                onChange={(e) => props.handleRepaySelect(e)}
              >
                <option selected>Select a channel</option>
                {repaymentChannelOptions}
              </select>
            </div>
            <div className="errorHandler form-group text-left grid__full">
              <span id="email-msg" className="hidden error-element"></span>
            </div>
          </div>
        </div>

        {/* {(repayEdit)&&<div className="grid__full m-b-1">
                    <div className="p-l-0 grid__padding">
                        <label className="label-txt">List of Accounts Numbers for</label>
                        <div className="form-group">
                            <select 
                                className="round user__form form-control"
                                value={bankId} 
                                placeholder="Select bank" 
                                name="bankId"
                                onChange={ e => props.handleOptionSelect(e)}
                                >
                            <option selected>Select bank</option>
                                {bankOptionsList}  
                            </select>
                        </div>
                        <div className="errorHandler form-group text-left grid__full">
                            <span id="email-msg" className="hidden error-element"></span>
                        </div>
                    </div> 
                </div>} */}
        {repayEdit && (
          <div className="grid__full m-b-1">
            <div className="p-l-0 grid__padding">
              <label className="label-txt">Bank </label>
              <div className="form-group">
                <select
                  className="round user__form form-control"
                  value={bankId}
                  placeholder="Select bank"
                  name="bankId"
                  onChange={(e) => props.handleOptionSelect(e)}
                >
                  <option selected>Select bank</option>
                  {bankOptionsList}
                </select>
              </div>
            </div>
          </div>
        )}

        {error && <span className="text-danger">{error}</span>}
        {repayEdit && (
          <div className="grid__full m-b-1">
            <div className="p-l-0 grid__padding">
              <label className="label-txt">Account number</label>
              <div className="form-group">
                <input
                  type="number"
                  className="round user__form form-control"
                  data-form="accountNumber"
                  name="accountNumber"
                  maxLength={10}
                  value={accountNumber}
                  onChange={(e) => props.onInputChange(e)}
                  onInput={(e) => props.onAccountNumberInput(e)}
                />
              </div>
            </div>
          </div>
        )}
        {repayEdit && (
          <div className="grid__full m-b-1">
            <div className="p-l-0 grid__padding">
              <div className="form-group">
                <label className="label-txt">
                  Account name{" "}
                  {accountLoading && (
                    <span className="text-primary ">Loading</span>
                  )}
                </label>

                <input
                  type="text"
                  name="accountName"
                  className="round user__form form-control"
                  defaultValue={accountName}
                  disabled
                />
              </div>
            </div>
          </div>
        )}

        <div className="grid__full">
          {repayEdit ? (
            <div className="Form__action-buttons">
              <button
                type="button"
                className="btn zoom-element login-btn m-r-1"
                data-form="repay"
                onClick={(e) => props.onCancelUpdate(e.currentTarget)}
              >
                <i className="fas fa-times-circle" />
                &nbsp; Cancel
              </button>
              <button
                type="button"
                data-form="repay"
                className="btn zoom-element login-btn m-l-1"
                onClick={(e) =>
                  props.onCreateRepaymentChannelAccount(e.currentTarget)
                }
              >
                <i className="fas fa-save" />
                &nbsp; Create
              </button>
            </div>
          ) : (
            <button
              type="submit"
              className="zoom-element btn login-btn"
              data-form="repay"
              disabled={!selectedRepaymentId}
              onClick={(e) => props.onEditMode(e.currentTarget)}
            >
              <i className="fas fa-plus" />
              &nbsp; Create Account
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepayForm;
