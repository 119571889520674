import React from "react";

export const CrcBusinessData = ({ tableData }) => {
  const { name, phonE_NO1 } = tableData;
  return (
    <div>
      {Object.keys(tableData).length > 0 && (
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0 mb-3">
              <i className="adv-icon fas fa-cogs" />
              &nbsp;Details
            </h3>

            <div className="row col-md-12">
              <div>
                <label className="label-txt">Business Name</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="user__form form-control"
                    value={name ? name : "Name not found"}
                    disabled
                  />
                </div>
                <label className="label-txt">Phone Number</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="user__form form-control"
                    value={phonE_NO1 ? phonE_NO1 : "Phone number not found"}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
