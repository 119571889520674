import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAggregatorEligibilityDocuments = async ({
  isActive,
  providerType,
}) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.DOCUMENT}?is_active=${isActive}&provider_type=${providerType}`
  );
  return data;
};

export default function useAggregatorEligibilityDocuments({
  isActive,
  providerType,
}) {
  // The id is a query identifier here
  return useQuery(
    [{ isActive, providerType }, "getAggregatorEligibilityDocuments"],
    getAggregatorEligibilityDocuments
  );
}
