import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { showModal, hideModal } from "../../redux/actions/modal";
import { formatMoney } from "../../helpers/formatter";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import useAllWalletTransactions from "../../custom-hooks/useAllWalletTransactions";
import { appInsights } from "../../config/appInsights";

const WalletTransactions = ({ showModal }) => {
  const location = useLocation();
  const { data: locationData } = location?.state;
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    accountNumber: locationData?.accountNumber,
    walletId: locationData?.walletId,
    consumerType: location?.state?.consumerType,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    consumerType: location?.state?.consumerType,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Wallet Transactions - (WalletTransactions.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const { data, status, error } = useAllWalletTransactions(preState);
  const [count, setCount] = useState(null);
  if (data) {
    if (count === null) {
      setCount(data?.total);
    }
  }

  const resetTable = () => {
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  const filterData = (e) => {
    e.preventDefault();
    const diffTime = new Date(state.endDate) - new Date(state.startDate);
    if (diffTime < 0) {
      showModal({
        modalIdentifier: "audittrail",
        action: "customrange",
        dataRecord: "",
        type: "alert",
      });
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let RenderedComponent = null;
  if (!data?.transactions && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Wallet Transactions.
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading wallet transactions..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              wallet transactions, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data?.transactions && data?.transactions.length === 0) ||
      (data && !data.transactions)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no wallet
              transactions.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Wallet ID</th>
                  <th>Created Date</th>
                  <th>Transaction ID</th>
                  <th>Transaction Amount</th>
                  <th>Transaction Charge</th>
                  <th>Transaction Type</th>
                  <th>Transaction Status</th>
                  <th>Credit </th>
                  <th>Debit</th>
                </tr>
              </thead>
              <tbody>
                {data?.transactions &&
                  data?.transactions?.map((request, _id) => {
                    const {
                      walletId,
                      transactionDate,
                      amount,
                      transactionCharge,
                      transactionType,
                      transactionId,
                      transactionStatus,
                      creditAmount,
                      debitAmount,
                    } = request;
                    // Transaction Status added
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{walletId}</td>
                        <td>{transactionDate.substring(0, 10)}</td>
                        <td>{transactionId}</td>
                        <td>{formatMoney(amount, data?.currency)}</td>
                        <td>
                          {formatMoney(transactionCharge, data?.currency)}
                        </td>
                        <td>{transactionType}</td>
                        <td>{transactionStatus}</td>
                        <td>{formatMoney(creditAmount, data?.currency)}</td>
                        <td>{formatMoney(debitAmount, data?.currency)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="dataTables_wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-3">
            <span className="d-flex align-items-center">
              <BackButtonComponent />
            </span>
            <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
              <div className="form-group mr-3 mt-2">
                <div className="input-group ">
                  <label className="mr-2">From </label>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={state.startDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-group mt-2 mr-3">
                <div className="input-group">
                  <label className="mr-2">To </label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate"
                    value={state.endDate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "7px" }}>
                <button
                  type="submit"
                  className="btn advancly-btn btn-md"
                  value="submit"
                  disabled={status === "loading"}
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          <div className="d-flex flex-row flex-wrap mb-3">
            <h3 className="d-flex align-items-center">
              <span className="page-subtitle">Available Balance: </span>
              <span className="d-flex align-items-center">
                {formatMoney(data?.availableBalance, data?.currency) || "N/A"}
              </span>
            </h3>
          </div>
          <div className="d-flex flex-row flex-wrap mb-3">
            <h3 className="d-flex align-items-center">
              <span className="page-subtitle">Ledger Balance: </span>
              <span className="d-flex align-items-center">
                {" "}
                {formatMoney(data?.ledgerBalance, data?.currency) || "N/A"}
              </span>
            </h3>
          </div>
          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          {data?.transactions && (
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count}
              changeCurrentPage={(pageNumber) =>
                setPreState({ ...state, pageNumber })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loggedInUser } = state.authReducer;

  return {
    loggedInUser,
  };
};
export default connect(mapStateToProps, { showModal, hideModal })(
  withRouter(WalletTransactions)
);
