// Redux Action CONSTANTS
import * as actionTypes  from '../actions/actionTypes';

// Initial Application-wide Ecosystem State
const initialState = {
    allFunders: {
        fundersTableData: {
            columns: [],
            rows: []
        },
        funders: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
    funderInvestmentPool: {
        investmentPoolTableData: {
            columns: [],
            rows: []
        },
        investmentPool: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
    funderProfile: {
        profile: null,
        loading: false,
        error: null
    },
    funderNotifications: {
        notificationsTableData: {
            columns: [],
            rows: []
        },
        notifications: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
    funderTransactions: {
        transactionsTableData: {
            columns: [],
            rows: []
        },
        transactions: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
    funderInvestments: {
        investmentsTableData: {
            columns: [],
            rows: []
        },
        investments: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
    funderProducts: {
        funderProductsTableData: {
            columns: [],
            rows: []
        },
        products: [],
        count: 0,
        next_page: null,
        prev_page: null,
        loading: false,
        error: null
    },
    funderSubscription: {
        loading: false,
        error: null,
        message: ''
    }
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.GET_FUNDERS_START:
            return {
                ...state,
                loading: true
            }
        
        case actionTypes.GET_FUNDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
        }

        case actionTypes.GET_FUNDERS_FAILURE:
            return {
                loading: false,
                error: action.error
            }

        case actionTypes.FUNDER_INVESTMENT_POOL_START:
            return {
                ...state,
                funderInvestmentPool: {
                    ...state.funderInvestmentPool,
                    loading: true,
                    error: null
                }
            }
        
        case actionTypes.FUNDER_INVESTMENT_POOL_SUCCESS:
            return {
                ...state,
                funderInvestmentPool: {
                    ...state.funderInvestmentPool,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }

        case actionTypes.FUNDER_INVESTMENT_POOL_ERROR:
            return {
                ...state,
                funderInvestmentPool: {
                    ...state.funderInvestmentPool,
                    loading: false,
                    error: action.payload.error
                }
            }

        case actionTypes.FUNDER_PROFILE_START:
            return {
                ...state,
                funderProfile: {
                    ...state.funderProfile,
                    loading: true,
                    error: null
                }
            }
        
        case actionTypes.FUNDER_PROFILE_SUCCESS:
            return {
                ...state,
                funderProfile: {
                    ...state.funderProfile,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }

        case actionTypes.FUNDER_PROFILE_ERROR:
            return {
                ...state,
                funderProfile: {
                    ...state.funderProfile,
                    loading: false,
                    error: action.payload.error
                }
            }

        case actionTypes.FUNDER_NOTIFICATIONS_START:
            return {
                ...state,
                funderNotifications: {
                    ...state.funderNotifications,
                    loading: true,
                    error: null
                }
            }
        
        case actionTypes.FUNDER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                funderNotifications: {
                    ...state.funderNotifications,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }

        case actionTypes.FUNDER_NOTIFICATIONS_ERROR:
            return {
                ...state,
                funderNotifications: {
                    ...state.funderNotifications,
                    loading: false,
                    error: action.payload.error
                }
            }

        case actionTypes.FUNDER_TRANSACTIONS_START:
            return {
                ...state,
                funderTransactions: {
                    ...state.funderTransactions,
                    loading: true,
                    error: null
                }
            }
        
        case actionTypes.FUNDER_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                funderTransactions: {
                    ...state.funderTransactions,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }

        case actionTypes.FUNDER_TRANSACTIONS_ERROR:
            return {
                ...state,
                funderTransactions: {
                    ...state.funderTransactions,
                    loading: false,
                    error: action.payload.error
                }
            }

        case actionTypes.FUNDER_INVESTMENTS_START:
            return {
                ...state,
                funderInvestments: {
                    ...state.funderInvestments,
                    loading: true,
                    error: null
                }
            }
        
        case actionTypes.FUNDER_INVESTMENTS_SUCCESS:
            return {
                ...state,
                funderInvestments: {
                    ...state.funderInvestments,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }

        case actionTypes.FUNDER_INVESTMENTS_ERROR:
            return {
                ...state,
                funderInvestments: {
                    ...state.funderInvestments,
                    loading: false,
                    error: action.payload.error
                }
            }

        case actionTypes.FUNDER_PRODUCTS_START:
            return {
                ...state,
                funderProducts: {
                    ...state.funderProducts,
                    loading: true,
                    error: null
                }
            }
        
        case actionTypes.FUNDER_PRODUCTS_SUCCESS:
            return {
                ...state,
                funderProducts: {
                    ...state.funderProducts,
                    ...action.payload,
                    loading: false,
                    error: null
                }
            }

        case actionTypes.FUNDER_PRODUCTS_ERROR:
            return {
                ...state,
                funderProducts: {
                    ...state.funderProducts,
                    loading: false,
                    error: action.payload.error
                }
            }

            case actionTypes.SUBSCRIPTION_START:
                return {
                    ...state,
                    funderSubscription: {
                        ...state.funderSubscription,
                        loading: true,
                        error: null
                    }
                }
            
            case actionTypes.SUBSCRIPTION_SUCCESS:
                return {
                    ...state,
                    funderSubscription: {
                        ...state.funderSubscription,
                        message: action.payload,
                        loading: false,
                        error: null
                    }
                }
    
            case actionTypes.SUBSCRIPTION_ERROR:
                return {
                    ...state,
                    funderSubscription: {
                        ...state.funderSubscription,
                        loading: false,
                        error: action.payload.error
                    }
                }

            case actionTypes.SUBSCRIPTION_CLOSE:
                return {
                    ...state,
                    funderSubscription: {
                        ...state.funderSubscription,
                        message: '',
                        loading: false,
                        error: null
                    }
                }
        default:
            return state;
    }
}

export default reducer;