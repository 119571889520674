import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import CustomSearchDropDown from "../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { appInsights } from "../../../config/appInsights";
import useAllProductType from "../../../custom-hooks/useAllProductType";
import { getLoanProductAggregatorProductTypes } from "../../../custom-hooks/useLoanProductAggregatorProductTypes";
import { putDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import { showModal } from "../../../redux/actions/modal";

const ProductTypeModal = ({ properties, onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [defaultProductTypes, setDefaultProductTypes] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const fetchLoanProductAggregatorType = async () => {
    setIsFetching(true);
    try {
      const response = await getLoanProductAggregatorProductTypes({
        productId: properties?.dataRecord?.id,
      });
      setDefaultProductTypes(response);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ProductTypeModal.jsx",
        },
      });
    }
    setIsFetching(false);
  };

  const { data: availableProductTypes } = useAllProductType();

  const _getAggregatorProductTypes = (listInQuestion) => {
    if (listInQuestion && listInQuestion.length > 0) {
      const result = listInQuestion.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const cleanProductTypes = (arrayInQuestion) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      const result = arrayInQuestion.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      return result;
    }

    return [];
  };

  const onProductTypeChange = (listInQuestion) => {
    const productTypeIds = listInQuestion.map((item) => item.value);
    setProductIds(productTypeIds);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const payload = {
        product_type_ids: productIds,
        loan_product_id: properties?.dataRecord?.id,
      };
      const response = await putDataWithDotNet(
        "/account/aggregator_loan_product",
        payload
      );
      if (response?.status) {
        onCloseModal();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.message },
          })
        );
      } else {
        onCloseModal();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.message },
          })
        );
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ProductTypeModal.jsx",
        },
      });
      onCloseModal();
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: { type: "failure", message: error?.response?.message },
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLoanProductAggregatorType();
  }, []);

  return (
    <div
      className="Modal__dialog  lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ height: "50vh" }}>
        <div className="Modal__body">
          <h3
            className="Modal__title m-b-1 p-x-2 p-y-1"
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            Aggregator Product Types
          </h3>
          {isFetching ? (
            "Loading..."
          ) : (
            <div className="p-l-0 grid__padding">
              <CustomSearchDropDown
                label="Select Product Type"
                labelClass="d-block"
                defaultValue={_getAggregatorProductTypes(
                  defaultProductTypes?.data?.product_types
                )}
                defaultOptions={cleanProductTypes(availableProductTypes)}
                onChange={onProductTypeChange}
                isSearchable={false}
                isMulti={true}
              />
              <div className="d-flex justify-content-end">
                <button
                  data-mode="close"
                  className="create zoom-element btn m-0 login-btn p-2"
                  onClick={handleUpdate}
                >
                  {isLoading ? "Loading..." : <>Submit</>}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTypeModal;
