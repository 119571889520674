import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { appInsights } from "../../../config/appInsights";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";

import { maximumMessageTitleCount } from "../constants";

import Styles from "./viewMessage.module.scss";

type LocationState = {
  id: number;
  message: string;
  messageClass: string;
  title: string;
  userCategory: string;
};

const ViewMessage = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "View Message Details - (viewMessage.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="animated fadeInRight">
      <h3 className="page-title d-flex align-items-center mb-5">
        <BackButtonComponent /> Messaging
      </h3>

      <div className={Styles.FormContainer}>
        <form>
          <div className={Styles.Form}>
            <div className={Styles.FormHeader}>
              <h3>Message</h3>
              <span
                className={Styles.FormCancelIcon}
                onClick={() => history.goBack()}
              />
            </div>

            <div className="row m-t-1">
              <div className="col-sm-6 col-xs-12">
                <div className={Styles.FormLabel}>
                  <label>Title</label>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    defaultValue={state?.title}
                    className={Styles.MessagingInput}
                    name="title"
                    disabled
                    maxLength={maximumMessageTitleCount}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-xs-12">
                <label className={Styles.FormLabel}>Message Type</label>
                <div className="form-group">
                  <input
                    type="text"
                    name="messageClass"
                    defaultValue={state.messageClass}
                    className={Styles.MessagingInput}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row m-t-1">
              <div className="col-sm-6 col-xs-12">
                <label className={Styles.FormLabel}>User Category</label>
                <div className="form-group">
                  <input
                    type="text"
                    name="userCategory"
                    defaultValue={state.userCategory}
                    className={Styles.MessagingInput}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row m-t-1">
              <div className="col-sm-12 col-xs-12">
                <div className={Styles.FormLabel}>
                  <label>Message</label>
                </div>
                <div className="form-group">
                  <textarea
                    className={`p-t-1 ${Styles.MessagingInput} ${Styles.TextArea}`}
                    name="message"
                    disabled
                    defaultValue={state?.message}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewMessage;
