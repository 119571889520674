import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getPendingAdminWalletWithdrawalRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.ADMIN_WALLET_WITHDRAWAL
  );
  return data;
};

export function usePendingAdminWalletWithdrawalRequests() {
  return useQuery(
    ["getPendingAdminWalletWithdrawalRequests"],
    getPendingAdminWalletWithdrawalRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}

const getPendingAdminWalletTopUpsRequests = async () => {
  const { data } = await getDataWithDotNet(ajaxEndpoints.ADMIN_WALLET_TOP_UP);
  return data;
};

export function usePendingAdminWalletTopUpsRequests() {
  return useQuery(
    ["getPendingAdminWalletTopUpsRequests"],
    getPendingAdminWalletTopUpsRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
