import React from "react";
import FailedBatchDisbursementDetails from "./FailedBatchDisbursementDetails.js/FailedBatchDisbursementDetails";
import RetryFailedBatchDisbursement from "./RetryFailedBatchDisbursement.jsx/RetryFailedBatchDisbursement";

export default function FailedBatchDisbursement(props) {
  const { properties } = props;
  const { action } = properties;
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      {(() => {
        switch (action) {
          case "failedbatchdisbursementdetails":
            return (
              <FailedBatchDisbursementDetails
                properties={properties}
                onCloseModal={props.onCloseModal}
              />
            );

          case "retryfailedbatchdisbursement":
            return (
              <RetryFailedBatchDisbursement
                properties={properties}
                onCloseModal={props.onCloseModal}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
}
