import { useState, useEffect } from "react";

import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { formatMoney } from "../../helpers/formatter";
import usePendingAggregatorLoans from "../../custom-hooks/usePendingAggregatorLoans";
import { RootState } from "../../redux/store";

const PendingAggregatorLoans = () => {
  const [count, setCount] = useState(null);
  const [preState, setPreState] = useState({ pageNumber: "1", size: "20" });
  const [refresh, setRefresh] = useState(false);

  const { loggedInUser, isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const history = useHistory();

  const resetTable = () => {
    setCount(null);
  };

  //  Life Cycle Hooks
  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error } = usePendingAggregatorLoans(preState);

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Uploaded Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Loans Pending Approval..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              pending aggregator loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              aggregator loans.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover id="table-to-xls">
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Aggregator Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Borrower Type</th>
                <th>Product Name</th>
                <th>Amount</th>
                <th>Interest</th>
                <th>Loan Tenure</th>
                <th>Reference</th>
                <th>Repayment Start Date</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data?.data.map((data: any, _id: number) => {
                const {
                  aggregator_name,
                  borrower_details,
                  loan_tenure,
                  application_reference_no,
                  product_name,
                  repayment_start_date,
                  currency,
                  amount,
                  amount_interest,
                } = data;

                return (
                  <tr key={_id}>
                    <td>
                      {(Number(preState.pageNumber) - 1) *
                        Number(preState.size) +
                        (_id + 1)}
                    </td>
                    <td>{aggregator_name}</td>
                    <td>{borrower_details?.first_name}</td>
                    <td>{borrower_details?.last_name}</td>
                    <td>{borrower_details?.borrower_type_value}</td>
                    <td>{product_name}</td>
                    <td>{formatMoney(Number(amount), currency)}</td>
                    <td>{formatMoney(Number(amount_interest), currency)}</td>
                    <td>{loan_tenure}</td>
                    <td>{application_reference_no}</td>
                    <td>{moment(repayment_start_date).format("DD/MM/YYYY")}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Audit Trail Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-12 dashboard-title flex__row">
              <h3>Pending Aggregator Loans</h3>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-between align-items-end mt-4">
          <div className="m-r-1">
            <label htmlFor="table-fetch" className="form-group">
              Number of results:{" "}
              <select
                onChange={(e) => {
                  resetTable();
                  setPreState({
                    ...preState,
                    size: e.target.value,
                    pageNumber: "1",
                  });
                }}
                id="table-fetch"
                className="table-fetch-select"
                value={preState.size}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </label>
          </div>

          {/* <div className="flex-row mb-1">
            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className="m-r-1 create btn advancly-btn"
              onClick={(e) => {
                props.showModal({
                  modalIdentifier: "loan",
                  action: "pendingapproval",
                  setPreState,
                  preState,
                  refresh,
                });
              }}
            >
              <i className="fas fa-search" />
              &nbsp; Advance Search
            </button>
            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className=" btn advancly-white-btn"
              onClick={(e) => {
                resetSearch(e);
              }}
            >
              <i className="fas fa-refresh" />
            </button>
          </div> */}
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={preState.size}
          page={preState.pageNumber}
          count={count || 0}
          changeCurrentPage={(pageNumber: number) =>
            setPreState({ ...preState, pageNumber: pageNumber.toString() })
          }
        />
      </div>
    </div>
  );
};

export default PendingAggregatorLoans;
