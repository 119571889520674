import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAggregatorOnboardingQuestions = async ({ ProductType }) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_AGGREGATOR_ONBOARDING_QUESTIONS}?ProductType=${ProductType}`
  );
  return data;
};

export default function useAggregatorOnboardingQuestions({ ProductType }) {
  return useQuery(
    [{ ProductType }, "getAggregatorOnboardingQuestions"],
    getAggregatorOnboardingQuestions
  );
}
