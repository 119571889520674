import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { QueryResult, useQuery } from "react-query";
import { IWalletProvider } from "../interfaces/funderInvestment";

const getDepositTypes = async () => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_INVESTMENT_DEPOSIT_TYPES}`
  );
  return data.data;
};

export function useDepositTypes(): QueryResult<IWalletProvider[]> {
  return useQuery(["getDepositTypes"], getDepositTypes, {
    refetchOnWindowFocus: false,
  });
}
