import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getLoggedPendingOfflineRequests = async () => {
  const { data } = await getDataWithDotNet(
    ajaxEndpoints.LOGGED_PENDING_OFFLINE_INVESTMENT_APPROVAL
  );
  return data;
};

export default function useLoggedPendingOfflineRequests() {
  return useQuery(
    ["getLoggedPendingOfflineRequests"],
    getLoggedPendingOfflineRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
