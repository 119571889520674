import React, { Component } from "react";

// Validators
import { exists, isEmail } from "./../../../../helpers/validators";

export default class Funder extends Component {
  // Component State
  state = {
    onboardingForm: {
      password: "",
      personal_email: "",
      first_name: "",
      last_name: "",
      personal_phone: "",
      confirm_password: "",
    },
  };

  // Class Methods
  // Handle onChange event // Bind form input fields to component state
  inputChangeHandler = (e) => {
    let { name, value } = e.target;
    this.setState({
      onboardingForm: {
        ...this.state.onboardingForm,
        [name]: value,
      },
    });
  };

  resetValidationFields = () => {};

  // Trigger onFocus event on Form input fields to clear all error indicators
  removeErrorHandler = (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    document.querySelector(`#${target.id}`).classList.remove("error");
    document.querySelector(`#${target.id}-msg`).classList.add("hidden");
  };

  // Trigger onFocus event on Form input fields to clear all error indicators
  validateOnboardingCredentials = (onboardingData) => {
    const { first_name, last_name, personal_phone, personal_email, password } =
      onboardingData;

    const Objectkeys = Object.keys(onboardingData);

    // Initialize error template variables
    const errorTemplates = {
      error_first_name:
        '<i class="fas fa-envelope"> </i>&nbsp; First Name Field is Empty',
      error_last_name:
        '<i className="fas fa-envelope"> </i>&nbsp; Last Name Field is Empty',
      error_personal_phone:
        '<i className="fas fa-envelope"> </i>&nbsp; Phone Field is Empty',
      error_personal_email:
        '<i className="fas fa-envelope"> </i>&nbsp; Email Field is Empty',
      error_emailInvalid:
        '<i className="fas fa-envelope"> </i>&nbsp; Enter a Valid Email Address',
      error_password:
        '<i className="fas fa-key"> </i>&nbsp; Password Field is Empty',
      error_confirm_password:
        '<i className="fas fa-envelope"> </i>&nbsp; Email Field is Empty',
      error_Msg:
        '<i className="fas fa-user-times"> </i>&nbsp; Enter onboarding Credentials',
    };

    // if (!isEmail(personal_email)) {
    //     document.querySelector('#email').classList.add('error');
    //     document.querySelector('#email-msg').classList.remove('hidden');
    //     document.querySelector('#email-msg').innerHTML = errorEmailInvalid;
    //     return false;
    // }

    if (
      !(
        exists(first_name) &&
        exists(last_name) &&
        exists(personal_email) &&
        exists(personal_phone) &&
        exists(password) &&
        isEmail(personal_email)
      )
    ) {
      Objectkeys.forEach((key) => {
        if (onboardingData[key] === "") {
          // document.querySelector(`#${key}`).classList.add('error');
          document.querySelector(`#${key}-msg`).classList.remove("hidden");
          document.querySelector(`#${key}-msg`).innerHTML =
            errorTemplates[`error_${key}`];
        }
      });

      return false;
    }

    onboardingData = {
      first_name: onboardingData.first_name,
      last_name: onboardingData.last_name,
      personal_phone: onboardingData.personal_phone,
      personal_email: onboardingData.personal_email,
      password: onboardingData.password,
    };

    return onboardingData;
  };

  onboardingHandler = (e) => {
    e.preventDefault();
    let response = this.validateOnboardingCredentials(
      this.state.onboardingForm
    );

    if (!response) {
      return false;
    }

    this.props.onBoard(response);
  };

  render() {
    const {
      first_name,
      last_name,
      personal_email,
      personal_phone,
      password,
      confirm_password,
    } = this.state.onboardingForm;

    return (
      <div className="tfx-user-form-wrapper box-effect full-border">
        <div className="tfx-user-form-inner">
          <div className="adv-form-bg">
            {/* 1. login Form */}
            <div id="adv-login-form">
              <div className="grid__full"></div>
              <div className="grid__full m-t-1 m-b-2">
                <div className="adv-form">
                  <form name="form">
                    <div className="row">
                      <div className="col-xs-6">
                        {/* First Name */}
                        {/* Label */}
                        <label className="label-txt">First name</label>
                        {/* Input */}
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="user__form form-control"
                            placeholder="First Name"
                            autoComplete="off"
                            value={first_name}
                            onChange={this.inputChangeHandler}
                            onFocus={this.removeErrorHandler}
                          />
                          <i
                            className="fas fa-user-circle"
                            aria-hidden="true"
                          />
                        </div>
                        {/* Input error Message */}
                        <div className="errorHandler form-group text-left grid__full">
                          <span
                            id="first_name-msg"
                            className="hidden error-element"
                          ></span>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        {/* Last Name */}
                        {/* Label */}
                        <label className="label-txt">Last name</label>
                        {/* Input */}
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            className="user__form form-control"
                            placeholder="Last name"
                            autoComplete="off"
                            required
                            value={last_name}
                            onChange={this.inputChangeHandler}
                            onFocus={this.removeErrorHandler}
                          />
                          <i className="far fa-user-circle" />
                        </div>
                        {/* Input error Message */}
                        <div className="errorHandler form-group text-left grid__full">
                          <span
                            id="last_name-msg"
                            className="hidden error-element"
                          ></span>
                        </div>
                      </div>
                    </div>

                    {/* Email */}
                    <label className="label-txt">Email</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="email"
                        name="personal_email"
                        id="personal_email"
                        className="user__form form-control"
                        placeholder="Email"
                        autoComplete="off"
                        required
                        value={personal_email}
                        onChange={this.inputChangeHandler}
                        onFocus={this.removeErrorHandler}
                      />
                      <i className="fas fa-envelope" />
                    </div>
                    {/* Input error Message */}
                    <div className="errorHandler form-group text-left grid__full">
                      <span
                        id="personal_email-msg"
                        className="hidden error-element"
                      ></span>
                    </div>

                    {/* Phone Number */}
                    <label className="label-txt">Phone Number</label>
                    <div className="form-group inputWithIcon">
                      <input
                        type="number"
                        name="personal_phone"
                        id="personal_phone"
                        className="user__form form-control"
                        placeholder="Phone number"
                        autoComplete="off"
                        required
                        value={personal_phone}
                        onChange={this.inputChangeHandler}
                        onFocus={this.removeErrorHandler}
                      />
                      <i className="fas fa-phone" />
                    </div>
                    <div className="errorHandler form-group text-left grid__full">
                      <span
                        id="personal_phone-msg"
                        className="hidden error-element"
                      ></span>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        {/* Password */}
                        <label className="label-txt">Password</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="password"
                            name="password"
                            id="password"
                            className="user__form form-control"
                            placeholder="Password"
                            autoComplete="off"
                            required
                            value={password}
                            onChange={this.inputChangeHandler}
                            onFocus={this.removeErrorHandler}
                          />
                          <i className="fas fa-key" />
                        </div>
                        <div className="errorHandler form-group text-left grid__full">
                          <span
                            id="password-msg"
                            className="hidden error-element"
                          ></span>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        {/* Retype Password */}
                        <label className="label-txt">Confirm Password</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            className="user__form form-control"
                            placeholder="Confirm Password"
                            required
                            value={confirm_password}
                            onChange={this.inputChangeHandler}
                            onFocus={this.removeErrorHandler}
                          />
                          <i className="fas fa-key" />
                        </div>
                        <div className="errorHandler form-group text-left grid__full">
                          <span
                            id="confirm_password-msg"
                            className="hidden error-element"
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div className="grid__full mt-20">
                      <button
                        type="button"
                        className="zoom-element btn login-btn"
                        onClick={(e) => this.onboardingHandler(e)}
                      >
                        <i className="fas fa-lock" />
                        &nbsp; Sign up
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
