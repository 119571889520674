import { useState, Fragment, useEffect } from "react";
import ToggleTab from "../../NewComponents/ToggleTab/ToggleTab.component";
// Redux
import BatchLoanRepaymentUpload from "./Tabs/BatchLoanRepaymentUpload";
import UploadedBatchLoans from "./Tabs/UploadedBatchLoans";
import ApprovableBatchRepayment from "./Tabs/ApprovableBatchRepayment";
import DueLoanRepayment from "./Tabs/DueLoanRepayment";
import AutoRepaymentLoans from "./Tabs/AutoRepaymentLoans";
import { appInsights } from "../../config/appInsights";

const BatchLoanRepayment = () => {
  const [activeTab, setActiveTab] = useState("batch-loan-upload");

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Repayments - (BatchLoanRepayment.jsx)",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      <div className="animated fadeInRight">
        <div className="d-flex flex-row flex-wrap mt-4 mb-1">
          <h3 className="page-title">Loan Repayments</h3>
        </div>

        <div className="alignToggleTabItems mb-3">
          <ToggleTab
            text="Batch Repayment"
            id="batch-loan-upload"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Uploaded Repayments"
            id="uploaded-repayment-loans"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Approvable Repayments"
            id="approvable-repayment-loans"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Due Loans"
            id="due-repayment-loans"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Loan Repayments"
            id="paid-repayment-loans"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case "batch-loan-upload":
                return <BatchLoanRepaymentUpload />;
              case "uploaded-repayment-loans":
                return <UploadedBatchLoans />;
              case "approvable-repayment-loans":
                return <ApprovableBatchRepayment />;
              case "due-repayment-loans":
                return <DueLoanRepayment />;
              case "paid-repayment-loans":
                return <AutoRepaymentLoans />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Fragment>
  );
};

export default BatchLoanRepayment;
