import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { NotificationListParam } from "../components/BirthdayNotifications/types";

import { getData } from "../newApis/dotNetApiMethods";

const getBirthNotifications = async (queryParams: NotificationListParam) => {
  const response = await getData(
    `${ajaxEndpoints.GET_BIRTHDAY_NOTIFICATIONS}?${new URLSearchParams(
      queryParams
    ).toString()}`
  );
  return response?.data;
};

export default function useBirthNotifications({
  Page,
  PageSize,
  from_date,
  to_date,
  message_status,
  searchParam,
}: NotificationListParam) {
  return useQuery(
    [
      { Page, PageSize, message_status, to_date, from_date, searchParam },
      "notificatonMessageList",
    ],
    getBirthNotifications,
    {
      refetchOnWindowFocus: false,
    }
  );
}
