import * as actionTypes  from './../actions/actionTypes';

const initialState = {
    systemConfig: {
        investment:{
            min: 100000,
            max: 10000000,
            step: 10000
        }
    },
    loading: false,
    error: null
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.SETTINGS_START:
            return {
                ...state,
                loading: true
            }

        default:
            return state;
    }
}

export default reducer;