import CreateWalletProvider from "./Components/CreateWalletProvider";
import CreateWallet from "./Components/CreateWallet";
import CreateBorrowerWallet from "./Components/BorrowerWallet";

// Select Action
const Wallets = ({ properties, onCloseModal }) => {
  const { action } = properties;
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      {(() => {
        switch (action) {
          case "defautwalletprovider":
            return (
              <CreateWalletProvider
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "createwallet":
            return (
              <CreateWallet
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );

          case "borrowerwallet":
            return (
              <CreateBorrowerWallet
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Wallets;
