import React from "react";
import { MDBTable } from "mdbreact";
import useRepaymentApprovalLog from "./../../custom-hooks/useRepaymentsApprovalLog";
import { Fragment } from "react";
import { Loader } from "../../UI/Loaders/Loaders";

const RepaymentsApprovalLog = ({ trans_ref }) => {
  const { data, error, isLoading } = useRepaymentApprovalLog(trans_ref);

  if (isLoading)
    return <Loader centered={true} text="Loading approval trail..." />;

  if (error) {
    return (
      <div className="centerLoader m-b-40" style={{ paddingTop: "0" }}>
        <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" />
            An error occured, please try again.
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div style={{ maxHeight: "50vh", "overflow-x": "hidden" }}>
      <>
        {data?.length ? (
          <Fragment>
            <MDBTable responsive minimal="true" bordered hover>
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Email</th>
                  <th>Approval Comment</th>
                  <th>Date/Time</th>
                </tr>
              </thead>
              <tbody>
                {data.map((trail, _id) => {
                  const { email, approvalComment, createdAt } = trail;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{email}</td>
                      <td>{approvalComment}</td>
                      <td>{createdAt}</td>
                    </tr>
                  );
                })}
              </tbody>
            </MDBTable>
          </Fragment>
        ) : (
          <div className="centerLoader m-b-40" style={{ paddingTop: "0" }}>
            <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" />
                No approval log available.
              </h4>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default RepaymentsApprovalLog;
