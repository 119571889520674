import { useQuery } from "react-query";

import * as ajaxEndpoints from "../api/ajax-endpoints";
import { TCompanyWalletParams } from "../components/Wallet/types";
import { getData } from "../newApis/dotNetApiMethods";

const getAllCompanyWallets = async ({
  ConsumerType,
  TargetId,
  Currency,
  Page,
  PageSize,
}: TCompanyWalletParams) => {
  let queryParams = `Page=${Page}&PageSize=${PageSize}&ConsumerType=${ConsumerType}`;

  if (TargetId) {
    queryParams = `${queryParams}&TargetId=${TargetId}`;
  }
  if (Currency) {
    queryParams = `${queryParams}&Currency=${Currency}`;
  }
  if (Currency && TargetId) {
    queryParams = `${queryParams}&Currency=${Currency}&TargetId=${TargetId}`;
  }

  const data = await getData(
    `${ajaxEndpoints.GET_COMPANY_WALLETS}?${queryParams}`
  );
  return data;
};

export default function useAllCompanyWallets({
  ConsumerType,
  TargetId,
  Currency,
  Page,
  PageSize,
}: TCompanyWalletParams) {
  return useQuery(
    [
      { ConsumerType, TargetId, Currency, Page, PageSize },
      "getAllCompanyWallets",
    ],
    getAllCompanyWallets
  );
}
