import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import Badge from "../Components/Badge/badge";
import moment from "moment";
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import usePendingDealSlips from "../../../custom-hooks/usePendingDealSlips";
import { appInsights } from "../../../config/appInsights";
import { formatMoney } from "../../../helpers/formatter";

import Styles from "./pendingDealSlips.module.scss";

type TComments = {
  id: number;
  comment: string;
};

type TSlip = {
  id: string;
  approval_status_comment: string;
  status: string;
  datetime_created: string;
  loan_id: number;
  loan_ref: string;
  loan_amount: number;
  biz_name: string;
  signed_slip_upload_path: string;
  signed_slip_filename: string;
  admin_uploaded_slip_filename: string;
  admin_uploaded_slip_path: string;
  comments: TComments[];
  document_upload_type: "string";
};

const PendingDealSlips = () => {
  const [Page, setPage] = useState(1);
  const [PageSize] = useState(10);

  const history = useHistory();

  const {
    data: pendingSlips,
    status,
    error,
  } = usePendingDealSlips({
    Page: Page.toString(),
    PageSize: PageSize.toString(),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Pending Deal Slips - (pendingDealSlips.tsx)",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;

  if (!pendingSlips && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to fetch Pending Deal Slips
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Pending Deal Slips..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Deal Slips, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (pendingSlips &&
        pendingSlips?.data?.data &&
        pendingSlips?.data?.data.length === 0) ||
      (pendingSlips && !pendingSlips?.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Pending Deal
              Slips.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className={`table-responsive ${Styles.TableResponsive} `}>
            <table className={`table ${Styles.Table}`}>
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Loan Reference</th>
                  <th>Loan Amount</th>
                  <th>Business Name</th>
                  <th>Date Created</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {pendingSlips?.data?.data.map((request: TSlip, _id: number) => {
                  return (
                    <tr key={_id}>
                      <td className={Styles.TableCell}>{_id + 1}</td>
                      <td className={Styles.TableCell}>{request?.loan_ref}</td>
                      <td className={Styles.TableCell}>
                        {formatMoney(request?.loan_amount, "NGN")}
                      </td>
                      <td className={Styles.TableCell}>{request?.biz_name}</td>
                      <td
                        className={Styles.TableCell}
                        style={{ fontWeight: 600 }}
                      >
                        {moment(request?.datetime_created).format("YYYY-MM-DD")}
                      </td>
                      <td className={Styles.TableCell}>
                        <Badge message={request.status} />
                      </td>
                      <td>
                        <span
                          onClick={() =>
                            history.push({
                              pathname: `/pending-slip/${request.loan_id}`,
                              state: {
                                loan_id: request.loan_id,
                                loan_ref: request?.loan_ref,
                                id: request.id,
                                document: {
                                  signed_slip_upload_path:
                                    request.signed_slip_upload_path,
                                  signed_slip_filename:
                                    request.signed_slip_filename,
                                  admin_uploaded_slip_path:
                                    request.admin_uploaded_slip_path,
                                  admin_uploaded_slip_filename:
                                    request?.admin_uploaded_slip_filename,
                                  status: request?.status,
                                  document_upload_type:
                                    request?.document_upload_type,
                                },
                                comments: request?.comments,
                              },
                            })
                          }
                          className={`color-blue ${Styles.ViewDetails}`}
                        >
                          View Details
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="animated fadeInRight">
      <div>
        <h3 className={`${Styles.Header} page-title`}> Pending Deal Slips</h3>
      </div>

      <div className={Styles.ActionContainer}>
        <div className={Styles.FilterOptions}>
          <div className={`form-group ${Styles.FormGroup}`}>
            <input
              type="text"
              className={Styles.SearchInput}
              placeholder="Search"
              name="search"
            />
            <i className={`fas fa-search ${Styles.Icon}`}></i>
          </div>
        </div>
        <div className={Styles.FormButtonContainer}>
          <button
            type="submit"
            className={`btn advancly-btn btn-md ${Styles.SearchActionButton}`}
          >
            Search
          </button>
        </div>
      </div>

      <div>{RenderedComponent}</div>

      <Paginator
        size={PageSize}
        page={Page}
        count={pendingSlips?.data?.totalCount}
        changeCurrentPage={(pageNumber: number) => setPage(pageNumber)}
      />
    </div>
  );
};

export default PendingDealSlips;
