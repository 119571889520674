import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";
import { useQuery } from "react-query";
import { IcreditBureauAPI } from "./useCreditBureauCheckHistory";
type creditBureauSubmissions = Omit<IcreditBureauAPI, "customerId">;
const getCreditBureauAllSubmissions = async ({
  page,
  pageSize,
}: creditBureauSubmissions) => {
  const { data } = await getData(
    `${ajaxEndpoints.SEND_ALL_CREDIT_BUREAU_SUBMISSIONS}?page=${page}&pageSize=${pageSize}
    `
  );
  return data;
};

export default function useCreditBureauAllSubmissions({
  page,
  pageSize,
}: creditBureauSubmissions) {
  return useQuery(
    [{ page, pageSize }, "getCreditBureauAllSubmissions"],
    getCreditBureauAllSubmissions,
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );
}
