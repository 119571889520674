import React from "react";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";

const ManageAggregatorSettingsPromptModal = ({
  properties: { dataRecord },
  onCloseModal,
}) => {
  const {
    action,
    type,
    settings: { reference, first_name, last_name },
  } = dataRecord;
  const manageLoan = () => {
    onCloseModal();
    setTimeout(() => {
      action(reference);
    }, 100);
  };

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          <h2 className="Modal__title p-b-2 text-center font-weight-bold">
            {capitalizeFirstLetter(type)} Aggregator Settings
          </h2>
          {/* Aggregator Setting Modal */}

          <h5 className="text-center">
            Are you sure you want to {type} this Aggregator Settings?{" "}
          </h5>

          <div className="m-t-2 m-b-2">
            <p>
              <span className="font-weight-bold">Aggregator Name:</span>
              {first_name} {last_name}
            </p>
            <p>
              <span className="font-weight-bold">Reference Number:</span>
              {reference}
            </p>
          </div>

          <form>
            <div className="Form__action-buttons m-t-1">
              <button
                type="button"
                className="btn zoom-element btn login-btn m-0 mr-auto"
                onClick={onCloseModal}
              >
                <i className="fas fa-times-circle"></i>
                &nbsp; Cancel
              </button>
              <button
                type="submit"
                className="btn zoom-element btn login-btn m-0 ml-auto"
                onClick={manageLoan}
                data-dismiss="modal"
              >
                <i className="fas fa-arrow-right"></i>
                &nbsp; Proceed
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ManageAggregatorSettingsPromptModal;
