import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

interface BankStatementSearchDetails {
  Page: number;
  PageSize: number;
  DateFrom?: string | null;
  DateTo?: string | null;
  CustomerId: number;
  AccountNumber: string;
  BankName: string;
}

const getCustomerBankDetails = async ({
  Page,
  PageSize,
  DateFrom,
  DateTo,
  CustomerId,
  AccountNumber,
  BankName
}: BankStatementSearchDetails) => {
  let queryParams = `?customer_id=${CustomerId}&page=${Page}&page_size=${PageSize}`;
  if (AccountNumber) {
    queryParams = `${queryParams}&account_number=${AccountNumber}`;
  }
  if(BankName) {
    queryParams = `${queryParams}&bank_name=${BankName}`;
  }
  if (DateFrom) {
    queryParams = `${queryParams}&from_date=${DateFrom}`;
  }
  if (DateTo) {
    queryParams = `${queryParams}&to_date=${DateTo}`;
  }

  const data = await getData(
    `${ajaxEndpoints.BANK_STATEMENT_DATA_OF_CUSTOMER}${queryParams}`
  );
  return data?.data;
};

export const useCustomerBankDetails = ({
  Page,
  PageSize,
  DateFrom,
  DateTo,
  CustomerId,
  AccountNumber,
  BankName
}: BankStatementSearchDetails) => {
  return useQuery(
    [
      { Page, PageSize, DateFrom, DateTo, CustomerId, AccountNumber, BankName },
      "getCustomerBankDetails",
    ],
    getCustomerBankDetails,
    {
      refetchOnWindowFocus: false,
      enabled: AccountNumber && CustomerId && DateFrom && DateTo && BankName ? true : false,
    }
  );
};
