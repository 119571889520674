import React, { useState } from "react";

import ajaxMethods from "../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { Loader } from "../../../UI/Loaders/Loaders";
import { enforceMaxLength } from "../../../helpers/enforceMaxLength";
import { filterDataByColumns } from "../../../helpers/tableControllers";
import { CreditBureauTable } from "../CreditBureauTable/CreditBureauTable";
import { CrcPersonalData } from "../CreditBureauTable/CRC/CrcPersonalData";
import { appInsights } from "../../../config/appInsights";

export const CrcIndividual = ({ onSetToast, clearToast }) => {
  const [bvn, setBvn] = useState("");
  const [loading, setLoading] = useState(false);
  const [newTableData, setNewTableData] = useState({});
  const [tableData, setTableData] = useState({});
  const [scoreData, setScoreData] = useState({});
  const getCreditReport = (e) => {
    e.preventDefault();

    clearToast();
    if (!bvn || !bvn.length) {
      return onSetToast("error", "Please enter your BVN");
    }
    setLoading(true);
    ajaxMethods
      .get(ajaxEndpoints.GET_CRC_INDIVIDUAL + "?BVN=" + bvn)
      .then((response) => {
        setLoading(false);
        if (response) {
          if (response.consumerHitResponse.body) {
            setTableData(
              response.consumerHitResponse.body.consCommDetails
                .consCommDetails_Subject
            );
            setScoreData(
              response.consumerHitResponse.body.crediT_SCORE_DETAILS
                .crediT_SCORE_SUMMARY
            );

            if (response.consumerHitResponse.body.summaryOfPerformance) {
              onSetToast("success", "Request successful");
              sortTableData(
                response.consumerHitResponse.body.summaryOfPerformance
              );
            } else {
              onSetToast("success", "Request successful but no available data");
            }
          } else {
            onSetToast("success", "Request successful but no available data");
          }
        } else {
          onSetToast("error", "Unable to connect to advancly services.");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "CrcIndividual.js",
          },
        });

        setLoading(false);
        onSetToast("error", "Unable to connect to advancly services.");
      });
  };

  const sortTableData = (creditAgreementSummary) => {
    let rows = [];
    creditAgreementSummary.map((data, index) => {
      let row_data = {
        id: index + 1,
        institutioN_NAME: data.institutioN_NAME,
        facilitieS_COUNT: data.facilitieS_COUNT,
        performinG_FACILITY: data.performinG_FACILITY,
        nonperforminG_FACILITY: data.nonperforminG_FACILITY,
        approveD_AMOUNT: data.approveD_AMOUNT,
        accounT_BALANCE: data.accounT_BALANCE,
        overduE_AMOUNT: data.overduE_AMOUNT,
      };
      rows.push(row_data);
      return rows;
    });

    let tableHeader = {
      columns: [
        { field: "id", label: "id", sort: "asc", width: 370 },
        {
          field: "institutioN_NAME",
          label: "INSTITUTION",
          sort: "asc",
          width: 670,
        },
        {
          field: "facilitieS_COUNT",
          label: "FACILITY COUNT",
          sort: "asc",
          width: 700,
        },
        {
          field: "performinG_FACILITY",
          label: "PERFORMING FACILITY",
          sort: "asc",
          width: 700,
        },
        {
          field: "nonperforminG_FACILITY",
          label: "NON PERFORMING FACILITY",
          sort: "asc",
          width: 500,
        },
        {
          field: "approveD_AMOUNT",
          label: "APPROVED AMOUNT",
          sort: "asc",
          width: 600,
        },
        {
          field: "accounT_BALANCE",
          label: "ACCOUNT BALANCE",
          sort: "asc",
          width: 600,
        },

        {
          field: "overduE_AMOUNT",
          label: "OVERDUE AMOUNT",
          sort: "asc",
          width: 670,
        },
      ],
      rows: [],
    };

    const columnsToExclude = [];
    let filteredTableData = filterDataByColumns(
      tableHeader,
      "exclude",
      columnsToExclude
    );
    filteredTableData = {
      columns: [...tableHeader.columns],
      rows: [...rows],
    };
    setNewTableData(filteredTableData);
  };

  if (loading) return <Loader text="Loading..." centered={true} />;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="card animated speed-1x fadeInRight"
          style={{ width: "auto" }}
        >
          <div className="body text-center">
            <h3 className="m-t-0">
              <i className="adv-icon fas fa-search" />
              &nbsp; CRC Individual
            </h3>
            <p className="settings-block text-muted">
              View the Credit History for an Individual Borrower
            </p>
            <form onSubmit={getCreditReport}>
              <label className="label-txt">BVN</label>
              <div className="form-group inputWithIcon">
                <input
                  type="number"
                  className="user__form form-control"
                  placeholder="Enter Bank Verification Number"
                  autoComplete="off"
                  name="treshold_amount"
                  value={bvn}
                  onKeyPress={enforceMaxLength}
                  maxLength="11"
                  required
                  onChange={(e) => setBvn(e.target.value)}
                />
                <i className="fas fa-lock" />
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn login-btn"
                  data-form="investment"
                  disabled={loading}
                >
                  <i className="fas fa-share" />
                  &nbsp; Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <CrcPersonalData tableData={tableData} scoreData={scoreData} />
      </div>

      <CreditBureauTable newTableData={newTableData} loading={loading} />
    </div>
  );
};
