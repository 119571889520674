import { useState } from "react";

import moment from "moment";
import { getTokenFromLocalStorage } from "../../../../helpers/localStorage";
import { BASE_URL } from "../../../../appConstants";
import { useHistory } from "react-router-dom";
import { appInsights } from "../../../../config/appInsights";

const GetPendingDisbursementRecordsModal = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const history = useHistory();

  const onDateChange = (e: any) => {
    const { name } = e.target;
    switch (name) {
      case "startDate":
        setEndDate("");
        return setStartDate(e.target.value);
      case "endDate":
        return setEndDate(e.target.value);
      default:
        return;
    }
  };

  const handleSubmit = () => {
    setButtonLoading(true);

    let token = getTokenFromLocalStorage();

    fetch(
      BASE_URL +
        `/eco/ready_for_settlement/csv?fromdate=${startDate}&todate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => {
        setButtonLoading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `loans from ${startDate} to ${endDate}.csv`
            );
            link.click();
            // link.parentNode.removeChild(link);
          });
        }

        if (response.status === 400) {
          // response.json()
          // .then(res => {
          //     this.setState({ error: res.message });
          // })
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "GetPendingDisbursementRecordsModal.js",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setButtonLoading(false);
      });

    // props.onCloseModal();
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        <h3 className="Modal__title p-b-1">Fetch Approved Loans</h3>
        <span>Start Date</span>
        <input
          className="form-control"
          style={{ marginBottom: "20px" }}
          type="date"
          id="start"
          name="startDate"
          onChange={onDateChange}
          value={startDate}
          // min={moment(new Date()).subtract(2,'d').format('YYYY-MM-DD')}
          max={moment(new Date()).format("YYYY-MM-DD")}
        />
        <span>End Date</span>
        <input
          className="form-control"
          style={{ marginBottom: "20px" }}
          type="date"
          id="end"
          name="endDate"
          onChange={onDateChange}
          value={endDate}
          min={startDate}
          // max={moment(startDate).add(2,'d').format('YYYY-MM-DD')}
          max={moment(new Date()).format("YYYY-MM-DD")}
          disabled={!startDate}
        />
        <button
          type="button"
          className="btn login-btn"
          disabled={buttonLoading || !startDate || !endDate}
          onClick={handleSubmit}
        >
          {buttonLoading && (
            <span className="spinner-border spinner-border-sm ml-3"></span>
          )}
          <i className="fas fa-download" />
          &nbsp; Fetch
        </button>
      </div>
    </div>
  );
};

export default GetPendingDisbursementRecordsModal;
