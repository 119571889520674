import React from "react";
import moment from "moment";
import styles from "../CreditBureau.module.scss";

const CreditBureauTableContactHistory = ({ contactHistory }: any) => {
  return (
    <div>
      <h5 className={` ${styles.creditBureauHeading}`}>Contact History</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              S/N
            </th>
            <th className="th-td-style th-style" scope="col">
              Contact Type
            </th>
            <th className="th-td-style th-style" scope="col">
              Details
            </th>
            <th className="th-td-style th-style" scope="col">
              Date Reported
            </th>
          </tr>
        </thead>
        <tbody>
          {contactHistory?.map(
            (contactHist: {
              sno: string;
              details: string;
              datE_REPORTED: string;
              contacT_TYPE: string;
            }) => {
              const { sno, details, datE_REPORTED, contacT_TYPE } = contactHist;
              return (
                <tr key={sno}>
                  <td className="th-td-style ">{sno}</td>
                  <td className="th-td-style ">{contacT_TYPE}</td>
                  <td className="th-td-style ">{details}</td>
                  <td className="th-td-style ">
                    {" "}
                    {moment(datE_REPORTED).format("DD/MM/YYYY")}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableContactHistory;
