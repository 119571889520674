import { useState } from "react";
import styles from "./UploadedDocument.module.scss";
import { ReactComponent as Folder } from "../../assets/svg/icons/folder_2.svg";
import { ReactComponent as Bin } from "../../assets/svg/icons/bin.svg";
import { ReactComponent as Download } from "../../assets/svg/icons/download-icon-round.svg";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { getData } from "./../../newApis/apiMethods";
import Loader from "./../Loader/Loader.component";
import { appInsights } from "../../config/appInsights";

export const DeleteUploadedDocument = ({
  name,
  deleteFile,
  hideDeleteIcon,
}) => {
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{name}</span>
      </div>
      {hideDeleteIcon ? null : (
        <div className={styles.right} onClick={deleteFile}>
          <Bin />
        </div>
      )}
    </div>
  );
};

export const DownloadUploadedDocument = ({ name, pathname, handleView }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const prepareDownloadLink = (content, fileName) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (fileName) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${fileName}`
      );
      prepareDownloadLink(data.base64_string, fileName);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedDocumentCard.component.jsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{name}</span>
      </div>
      <span className={styles.viewDocument} onClick={handleView}>
        View
      </span>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <Loader /> : <Download />}
      </div>
    </div>
  );
};
export const DownloadOfferLetterDocument = ({
  name,
  pathname,
  documentName,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const prepareDownloadLink = (content, fileName) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (fileName) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${fileName}`
      );

      prepareDownloadLink(data.base64_string, fileName);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "UploadedDocumentCard.component.jsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className={styles.uploadedDocCard}>
      <div className={styles.left}>
        <Folder />
        <span>{documentName}</span>
      </div>
      <div
        className={`${styles.right} ${isDownloading ? "disabled" : ""}`}
        onClick={() => downloadFile(pathname)}
      >
        {isDownloading ? <Loader /> : <Download />}
      </div>
    </div>
  );
};
