import { ChangeEvent, DragEvent, useRef, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import { ReactComponent as UploadIcon } from "../../assets/svg/icons/file_2.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/icons/delete_2.svg";
import { getData, postData } from "../../newApis/dotNetApiMethods";
import { showModal } from "../../redux/actions/modal";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { convertToBase64 } from "../../helpers/convertToBase64";

import style from "./uploadDealSlip.module.scss";
import { appInsights } from "../../config/appInsights";

type LocationData = {
  aggregatorName: string;
};

enum DealSlipType {
  Manual = 1,
  Automated = 2,
}

enum DownloadType {
  Pdf = "PDF",
  Doc = "DOC",
}

const UploadDealSlip = () => {
  const [error, setError] = useState<null | string>(null);
  const [fileToUpload, setFileToUpload] = useState<FileList | null>();
  const [isSendingDealSlip, setIsSendingDealSlip] = useState(false);
  const [documentPayload, setDocumentPayload] = useState({});
  const [dealSlipType, setDealSlipType] = useState(DealSlipType.Manual);
  const [isDownloadingSlip, setIsDownloadingSlip] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { aggregatorName } = location?.state as LocationData;

  const dispatch = useDispatch();

  const fileInputElement = useRef<HTMLInputElement>(null);

  const getLoanIdFromPath = (pathName: string) => {
    if (pathName) {
      let id = pathName.split("/")[2];
      return Number(id);
    }
  };

  const handleBrowseClick = () => {
    if (fileInputElement.current !== null) {
      return fileInputElement.current.click();
    }
  };

  const enableDropping = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const validateFileToUpload = async (file: FileList | null) => {
    setError(null);

    if (file && file.length) {
      if (file[0].name.endsWith(".pdf")) {
        setFileToUpload(file);
        const fileBase64 = await convertToBase64(file[0]);

        const documentToUpload = {
          loan_id: Number(getLoanIdFromPath(location?.pathname)),
          base64_file_string: fileBase64,
          filename_with_extension: file[0].name,
        };

        setDocumentPayload(documentToUpload);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
      }
    }
  };

  const handleSendDealSlip = async () => {
    setIsSendingDealSlip(true);
    try {
      let response = null;

      if (dealSlipType === DealSlipType.Manual) {
        const payload = {
          upload_type: "Manual",
          ...documentPayload,
        };

        response = await postData(ajaxEndpoints.POST_DEAL_SLIP, payload);
      } else {
        const payload = {
          loan_id: Number(getLoanIdFromPath(location?.pathname)),
          upload_type: "Automated",
        };

        response = await postData(ajaxEndpoints.POST_DEAL_SLIP, payload);
      }

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );

        setTimeout(() => {
          history.push(`/pending-slips`);
        }, 3000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "uploadDealSlip.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsSendingDealSlip(false);
  };

  const handleDeleteFile = () => {
    setFileToUpload(null);
    (
      document.getElementById("deal-slip-upload-input") as HTMLInputElement
    ).value = "";
  };

  const handleChangeDealSlipType = (e: ChangeEvent<HTMLInputElement>) => {
    setDealSlipType(Number(e.target.value));
  };

  const prepareDownload = (base64String: string, fileName: string) => {
    const contentType = "application/octet-stream";
    const linkSource = `data:${contentType};base64,${base64String}`;

    const element = document.createElement("a");
    element.href = linkSource;
    element.download = fileName;
    element.click();
    setIsDownloadingSlip(false);
  };

  const handleDownloadGeneratedDealSlip = async (
    downloadType: DownloadType
  ) => {
    setIsDownloadingSlip(true);
    const loanId = Number(getLoanIdFromPath(location?.pathname));

    try {
      let response = null;
      if (downloadType === DownloadType.Doc) {
        response = await getData(
          `${ajaxEndpoints.DOWNLOA_GENERATED_SLIP}/${loanId}?formatType=docx`
        );
      } else {
        response = await getData(
          `${ajaxEndpoints.DOWNLOA_GENERATED_SLIP}/${loanId}?formatType=pdf`
        );
      }

      if (response?.data?.status_code === 200) {
        const { file_name, base64_string } = response?.data?.data;
        prepareDownload(base64_string, file_name);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "uploadDealSlip.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
  };

  return (
    <div className="animated fadeInRight">
      <h3 className="page-title d-flex align-items-center">
        <BackButtonComponent /> {aggregatorName}
      </h3>

      <div className={style.uploadContainer}>
        <h2 className={`page-title ${style.title}`}>
          {dealSlipType === DealSlipType?.Manual
            ? "Upload Deal Slip"
            : "Send Auto Generated Slip"}
        </h2>

        <input
          type="file"
          className="d-none"
          ref={fileInputElement}
          id="deal-slip-upload-input"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            validateFileToUpload(e.target.files);
          }}
        />

        <div
          className={style.uploadCard}
          id="deal-slip-upload-div"
          onDragOver={enableDropping}
          onDrop={(e: DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            validateFileToUpload(e.dataTransfer.files);
          }}
        >
          {dealSlipType !== DealSlipType.Automated && (
            <>
              <div className={style.iconContainer}>
                <UploadIcon />
              </div>
              <h3 className={style.header}>Upload Deal Slip</h3>
              <p>
                Drag & drop your file here or{" "}
                <span
                  className={style.browseAction}
                  onClick={handleBrowseClick}
                >
                  browse
                </span>
              </p>
              {error && (
                <h5 className="text-danger font-weight-bold text-center ">
                  {error}
                </h5>
              )}
            </>
          )}

          {dealSlipType !== DealSlipType.Manual && (
            <div className={style.automaticSection}>
              <div>
                <p className={style.autoSectiontext}>
                  Kindly select download format{" "}
                </p>
                <div className={`dropdown ${style.dropdownButton}`}>
                  <button
                    className="btn advancly-btn create zoom-element dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {isDownloadingSlip ? "Downloading..." : "Download"}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span
                      className="dropdown-item cursor-pointer"
                      onClick={() =>
                        handleDownloadGeneratedDealSlip(DownloadType.Pdf)
                      }
                    >
                      PDF
                    </span>
                    <span
                      className="dropdown-item cursor-pointer"
                      onClick={() =>
                        handleDownloadGeneratedDealSlip(DownloadType.Doc)
                      }
                    >
                      DOCX
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <hr className={style.divider}></hr>
        {dealSlipType !== DealSlipType.Automated && (
          <div className={style.uploadedDoc}>
            <h3>Uploaded Deal Slip</h3>
            <div className={style.uploadedItem}>
              {fileToUpload && (
                <>
                  <p>
                    {fileToUpload && fileToUpload[0].name}
                    <DeleteIcon
                      className={style.deleteIcon}
                      onClick={handleDeleteFile}
                    />
                  </p>
                </>
              )}
            </div>
          </div>
        )}

        <div className={style.radioContainer}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="manual"
              name="deal_slip_type"
              value={DealSlipType.Manual || ""}
              onChange={handleChangeDealSlipType}
              checked={dealSlipType === DealSlipType.Manual}
            />
            <label htmlFor="manual" className="form-check-label">
              Manual
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="automatic"
              name="deal_slip_type"
              value={DealSlipType.Automated || ""}
              onChange={handleChangeDealSlipType}
              checked={dealSlipType === DealSlipType.Automated}
            />
            <label htmlFor="automatic" className="form-check-label">
              Automatic
            </label>
          </div>
        </div>

        {dealSlipType !== DealSlipType.Automated && (
          <button
            className={`create zoom-element btn login-btn ${style.sendDocument}`}
            onClick={handleSendDealSlip}
            disabled={!fileToUpload}
          >
            {isSendingDealSlip ? "Loading..." : "Send Document"}
          </button>
        )}
        {dealSlipType !== DealSlipType.Manual && (
          <button
            className={`create zoom-element btn login-btn ${style.sendDocument}`}
            onClick={handleSendDealSlip}
          >
            {isSendingDealSlip ? "Loading..." : "Send AutoGenerated Document"}
          </button>
        )}
      </div>
    </div>
  );
};

export default UploadDealSlip;
