import { useEffect, useState } from "react";
// import { settingsStart, getSettingsSuccess, settingsFailure } from '../../../../../../../src/redux/actions/settings'

// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";

// Forms
import RepayForm from "./RepaymentChannelForms/RepayForm";
import RepaymentForm from "./RepaymentChannelForms/RepaymentForm";
import {
  RepaymentAccountColumns,
  reOrderTableData,
} from "../../../../../../redux/actions/loans";
import { appInsights } from "../../../../../../config/appInsights";

const RepaymentChannelSettings = (props: any) => {
  const [state, setState] = useState<any>({
    loading: false,
    repaymentChannelOptions: [],
    selectedRepaymentId: "",
    bankId: "",
    accountNumber: "",
    bankOptionsList: [],
    repayEdit: false,
    bankName: "",
    error: "",
    accountName: "",
    accountLoading: false,
    selectedRepaymentIdSearch: "",
    RepaymentChannelList: [],
    channelAccountsDetails: [],
    reOrderedTableData: [],
  });

  useEffect(() => {
    getRepaymentChannels();
    getBankList();
  }, []);

  // Component Methods
  const editModeHandler = (target: any) => {
    setState((prev: any) => {
      return { ...prev, bankId: "", accountNumber: "" };
    });
    let selectedForm = target.dataset["form"];
    // if(state.selectedRepaymentId){
    //     getDisbursementChannelDetails()

    // }

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: true,
      };
    });
  };

  const cancelUpdateHandler = (target: any) => {
    let selectedForm = target.dataset["form"];

    setState((prev: any) => {
      return {
        ...prev,
        [`${selectedForm}Edit`]: false,
        [`${selectedForm}FormCopy`]: state[`${selectedForm}Form`],
        accountName: "",
      };
    });
  };

  const handleRepaySelect = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        repayEdit: false,
      };
    });
  };

  const handleOptionSelect = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // fetch repayment channel list
  const getRepaymentChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_REPAYMENT_CHANNEL_LIST)
      .then((response) => {
        let RepaymentChannelLists = [] as any[];
        if (response.data.length > 0) {
          RepaymentChannelLists = [...response.data];
        } else {
          RepaymentChannelLists = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const RepaymentChannelListOption = RepaymentChannelLists.map(
          (ecosystem, index) => {
            const { id, channel_name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {channel_name}
              </option>
            );
          }
        );

        setState((prev: any) => {
          return {
            ...prev,
            repaymentChannelOptions: RepaymentChannelListOption,
            RepaymentChannelList: RepaymentChannelLists,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepaymentChannelSettings.js",
          },
        });
      });
  };

  // fetch accounts for a specific disburse channel
  const getRepaymentChannelAccounts = () => {
    setState((prev: any) => {
      return {
        ...prev,
        loading: true,
        channelAccountsDetails: [],
        reOrderedTableData: [],
      };
    });
    ajaxMethods
      .get(
        ajaxEndpoints.GET_REPAYMENT_ACCOUNTS +
          `?repayment_channel_id=${state.selectedRepaymentIdSearch}`
      )
      .then((response) => {
        setState((prev: any) => {
          return { ...prev, loading: false };
        });

        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            setState((prev: any) => {
              return { ...prev, channelAccountsDetails: response.data };
            });
            const reOrderedTableData = reOrderTableData(
              RepaymentAccountColumns,
              response.data
            );

            setState((prev: any) => {
              return { ...prev, reOrderedTableData: reOrderedTableData };
            });
          } else {
            setState((prev: any) => {
              return { ...prev, channelAccountsDetails: [] };
            });
          }
        } else {
          setState((prev: any) => {
            return { ...prev, channelAccountsDetails: [] };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepaymentChannelSettings.js",
          },
        });
      });
  };

  //get bank list
  const getBankList = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let bankList = response.data;
          const bankOptionsList = bankList.map((data: any, index: number) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            );
          });
          setState((prev: any) => {
            return { ...prev, bankOptionsList: bankOptionsList };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepaymentChannelSettings.js",
          },
        });
      });
  };
  //create disbursement channel accounts
  const onCreateRepaymentChannelAccount = () => {
    const { selectedRepaymentId, bankId, accountNumber, accountName } = state;
    const Repay = {
      repayment_channel_id: Number(selectedRepaymentId),
      bank_id: Number(bankId),
      account_number: accountNumber,
      account_name: accountName,
    };

    ajaxMethods
      .post(ajaxEndpoints.ADD_REPAYMENT_CHANNEL_ACCOUNT, Repay)
      .then((response) => {
        let message = "";
        if (
          response.status_code === 200 ||
          response.status === 200 ||
          response.data.status === 200
        ) {
          message = response.data.message;
          props.onSetToast("success", message);
          setState((prev: any) => ({ ...prev, repayEdit: false }));
        } else if (response.status === 400) {
          message = response.data.message;
          props.onSetToast("error", message);
        } else {
          props.onSetToast("warning", "Unable to connect to Advancly services");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepaymentChannelSettings.js",
          },
        });
        //
        props.onSetToast("warning", "Unable to connect to Advancly services");
      });
  };
  // validate account number
  const onAccountNumberInput = (e: any) => {
    setState({ error: false, accountName: "" });
    let number = e.target.value;
    if (state.bankId === "") {
      setState({ error: `Please select a bank` });
      return;
    }

    setState((prev: any) => {
      return {
        ...prev,
        accountNumber: number,
      };
    });
    getBankCode();
    if (number.length === 10 && !state.accountLoading) {
      setState((prev: any) => {
        return {
          ...prev,
          accountLoading: true,
        };
      });

      //validate account number and bank name
      let data = {
        bank_code: state.bankCode,
        bank_account_num: number,
      };
      validateAccount(data);
    }
  };

  // fetch product category list
  const getBankCode = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${state.bankId}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];
          setState((prev: any) => {
            return {
              ...prev,
              bankCode: res.code,
            };
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepaymentChannelSettings.js",
          },
        });
      });
  };

  const validateAccount = (data: any) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setState({
            accountName: response.data.bank_account_name,
            accountLoading: false,
          });
          // response.json()
          //   .then(res => {
          //   })
        } else if (response.status === 400) {
          setState((prev: any) => {
            return {
              ...prev,
              error: response.data.message,
              accountLoading: false,
            };
          });
        }
        if (response.status >= 500) {
          setState((prev: any) => {
            return {
              ...prev,
              error: "Unable to connect to Advancly services.",
              accountLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "RepaymentChannelSettings.js",
          },
        });
      });
  };

  const handleChange = (e: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        error: false,
      };
    });
  };

  return (
    <div className="GlobalSettings col-sm-12 mt-30">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <RepayForm
            inputs={state?.systemFormCopy}
            onEditMode={editModeHandler}
            onCancelUpdate={cancelUpdateHandler}
            onInputChange={handleChange}
            repayEdit={state.repayEdit}
            repaymentChannelOptions={state.repaymentChannelOptions}
            selectedRepaymentId={state.selectedRepaymentId}
            bankId={state.bankId}
            error={state.error}
            accountLoading={state.accountLoading}
            accountName={state.accountName}
            accountNumber={state.accountNumber}
            bankOptionsList={state.bankOptionsList}
            handleOptionSelect={handleOptionSelect}
            handleRepaySelect={handleRepaySelect}
            onCreateRepaymentChannelAccount={onCreateRepaymentChannelAccount}
            onAccountNumberInput={onAccountNumberInput}
          />
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12">
          <RepaymentForm
            selectedRepaymentIdSearch={state.selectedRepaymentIdSearch}
            handleOptionSelect={handleOptionSelect}
            RepaymentChannelLists={state.RepaymentChannelList}
            channelAccountsDetails={state.channelAccountsDetails}
            reOrderedTableData={state.reOrderedTableData}
            loading={state.loading}
            onSearch={getRepaymentChannelAccounts}
          />
        </div>
      </div>
    </div>
  );
};

export default RepaymentChannelSettings;
