import { Fragment, useState } from "react";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { useQueryCache } from "react-query";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import { errorHandler } from "../../../../helpers/errorHandler";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { appInsights } from "../../../../config/appInsights";

const FailedInvestmentModal = ({ properties, onCloseModal }) => {
  const { investmentId, pageNumber, size, setSuccessMessage, setSubmitError } =
    properties;
  const [loading, setLoading] = useState(false);
  const queryCache = useQueryCache();

  const submitHandleApprove = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setSubmitError("");

    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.RETRY_FAILED_INVESTMENTS}?id=${investmentId}`
      );
      setLoading(false);
      onCloseModal();
      setSuccessMessage(response.message);
      queryCache.invalidateQueries([
        { page: pageNumber, size },
        "getFailedInvestments",
      ]);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FailedInvestmentModal.jsx",
        },
      });
      onCloseModal();
      setLoading(false);
      setSubmitError(errorHandler(error));
    }
  };

  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          <h3 className="Modal__title p-b-1">Failed Investment Detail</h3>
          <hr />
          <h6 className="text-center p-y-2 color-red">
            Are you sure you want to retry this investment?
          </h6>
          <form onSubmit={submitHandleApprove}>
            <div className="row">
              <div className="col-12 mb-1">
                <label>Investment ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="reference"
                  defaultValue={investmentId}
                  disabled
                ></input>
              </div>

              <div className="col-12 d-flex justify-content-center flex-wrap">
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                  type="button"
                  disabled={loading || !investmentId}
                  onClick={submitHandleApprove}
                >
                  Retry {loading && <ButtonLoader />}
                </button>
              </div>
            </div>
          </form>
        </Fragment>
      </div>
    </div>
  );
};
export default FailedInvestmentModal;
