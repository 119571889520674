import React from "react";
import moment from "moment";
import styles from "../CreditBureau.module.scss";

// interface Iaddress {
//   addR_TYPE: "string";
//   address: "string";
//   datE_REPORTED: "string";
//   sno: "string";
// }
const CreditBureauCheckTablesAddressHistory = ({ addressHistory }: any) => {
  return (
    <div>
      {/* Address History */}
      <h5 className={` ${styles.creditBureauHeading}`}>Address History</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style " scope="col">
              S/N
            </th>
            <th className="th-td-style th-style " scope="col">
              Address Type
            </th>
            <th className="th-td-style th-style " scope="col">
              Date Reported
            </th>
            <th className="th-td-style th-style" scope="col">
              Address
            </th>
          </tr>
        </thead>
        <tbody>
          {addressHistory?.map((addressProp: any) => {
            const { sno, addR_TYPE, address, datE_REPORTED } = addressProp;
            return (
              <tr key={sno}>
                <td className="th-td-style ">{sno}</td>
                <td className="th-td-style ">{addR_TYPE}</td>
                <td className="th-td-style ">
                  {" "}
                  {moment(datE_REPORTED).format("DD/MM/YYYY")}
                </td>
                <td className="th-td-style ">{address}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauCheckTablesAddressHistory;
