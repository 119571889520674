import { useState } from "react";
import useLiquidationAccounts from "../../../../../../../custom-hooks/useLiquidationAccounts";
import useLiquidationChannels from "../../../../../../../custom-hooks/useLiquidationChannels";
import useMifosProduct from "../../../../../../../custom-hooks/useMifosProduct";
import useBvnKycProviders from "../../../../../../../custom-hooks/useBvnKycProviders";
import CustomSelectDropdown from "../../../../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";

interface IRevenueForm {
  inputs: any;
  onEditMode: any;
  onCancelUpdate: any;
  onInputChange: any;
  revenueEdit: any;
  onRevenueUpdate: any;
}
const RevenueForm = ({
  inputs,
  onEditMode,
  onCancelUpdate,
  onInputChange,
  revenueEdit,
  onRevenueUpdate,
}: IRevenueForm) => {
  const {
    aggregator_share,
    funder_share,
    platform_share,
    primary_bvn_provider,
    secondary_bvn_provider,
    first_repayment_NotificationDays,
    second_repayment_NotificationDays,
    emails,
    default_non_nigerian_id_provider,
    loan_notification_recipients,
    usd_liquidation_channel_account_id,
    usd_liquidation_channel_id,
    funder_usd_wallet_product_id,
    funder_lcy_wallet_product_id,
    maximum_number_of_rollover,
    principal_repayment_percentage,
    maximum_rollover_percentage,
  } = inputs;

  const [usdLiquidationId, setUsdLiquidationId] = useState("");

  const { data: usdliquidationAccount } = useLiquidationAccounts(
    usdLiquidationId || usd_liquidation_channel_id
  );

  const { data: usdLiquidationChannel } = useLiquidationChannels();
  const { data: bvnProviders } = useBvnKycProviders();
  const { data: mifosData } = useMifosProduct({ productType: 3 });

  return (
    <div className="card">
      <div className="body text-center">
        <h3 className="m-t-0">
          <i className="adv-icon fas fa-cogs" />
          &nbsp; Revenue Settings
        </h3>
        <p className="settings-block text-muted">
          Advancly global revenue settings
        </p>
        <div className="grid__full settings-block">
          <form>
            <p className="text-center">Revenue Share</p>
            <label className="label-txt">Aggregator Share</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="aggregator_share"
                value={aggregator_share}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
              <i className="fas fa-percent" aria-hidden="true" />
            </div>
            <label className="label-txt">Funder Share</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                placeholder="Funder Share"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="funder_share"
                value={funder_share}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
              <i className="fas fa-percent" aria-hidden="true" />
            </div>
            <label className="label-txt">Platform Share</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                placeholder="Platform Share"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="platform_share"
                value={platform_share}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
              <i className="fas fa-percent" aria-hidden="true" />
            </div>

            <div>
              <label className="label-txt">Primary BVN Provider</label>
              <select
                value={primary_bvn_provider}
                onChange={(e) => {
                  onInputChange(e.target);
                }}
                disabled={!revenueEdit}
                name="primary_bvn_provider"
                data-form="revenue"
                className=" form-control mb-2"
              >
                {bvnProviders?.data?.map((provider: any) => {
                  const { provider_id, provider_name } = provider;
                  return (
                    <option value={provider_id} key={provider_id}>
                      {provider_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="">
              <label className="label-txt">Secondary BVN Provider</label>
              <select
                value={secondary_bvn_provider}
                onChange={(e) => {
                  onInputChange(e.target);
                }}
                disabled={!revenueEdit}
                name="secondary_bvn_provider"
                data-form="revenue"
                className=" form-control mb-2"
              >
                {bvnProviders?.data?.map((provider: any) => {
                  const { provider_id, provider_name } = provider;
                  return (
                    <option value={provider_id} key={provider_id}>
                      {provider_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <label className="label-txt">Channel</label>
            <select
              onChange={(e) => {
                setUsdLiquidationId(e.target.value);
                onInputChange(e.currentTarget);
              }}
              name="usd_liquidation_channel_id"
              value={usd_liquidation_channel_id}
              data-form="revenue"
              disabled={!revenueEdit}
              className="form-control mb-2"
            >
              <option value=""> Select a liquidation channel</option>
              {usdLiquidationChannel &&
                usdLiquidationChannel.map((data: any) => {
                  const { id, name } = data;
                  return (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  );
                })}
            </select>
            <label className="label-txt">Liquidation Channel Account</label>
            <select
              onChange={(e) => {
                onInputChange(e.currentTarget);
              }}
              name="usd_liquidation_channel_account_id"
              value={usd_liquidation_channel_account_id}
              data-form="revenue"
              disabled={!revenueEdit}
              className="form-control mb-2"
            >
              <option value=""> Select a liquidation account</option>
              {usdliquidationAccount &&
                usdliquidationAccount.map((data: any) => {
                  const { id, account_name, account_number, bank_name } = data;
                  return (
                    <option value={id} key={id}>
                      {`${account_name} / ${account_number}/ ${bank_name}`}
                    </option>
                  );
                })}
            </select>
            <label className="label-txt">Max Number of Rollover</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="maximum_number_of_rollover"
                value={maximum_number_of_rollover}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
            </div>
            <label className="label-txt">
              Max interest percentage for Rollover
            </label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="maximum_rollover_percentage"
                value={maximum_rollover_percentage}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
            </div>
            <label className="label-txt">Principal Repayment Percentage</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="principal_repayment_percentage"
                value={principal_repayment_percentage}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
            </div>
            <label className="label-txt">
              First Repayment Notification Days
            </label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="first_repayment_NotificationDays"
                value={first_repayment_NotificationDays}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
            </div>
            <label className="label-txt">
              Second Repayment Notification Days
            </label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="second_repayment_NotificationDays"
                value={second_repayment_NotificationDays}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              />
            </div>
            <label className="label-txt">Repayment Email(s)</label>
            <div className="form-group inputWithIcon">
              <textarea
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="emails"
                value={emails}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              ></textarea>
            </div>
            <label className="label-txt">Loan App Email Notification</label>
            <div className="form-group inputWithIcon">
              <textarea
                className="user__form form-control"
                autoComplete="off"
                data-form="revenue"
                readOnly={!revenueEdit}
                name="loan_notification_recipients"
                value={loan_notification_recipients}
                onChange={(e) => {
                  onInputChange(e.currentTarget);
                }}
                required
              ></textarea>
            </div>
            <label className="label-txt">Non Nigeria KYC Provider</label>
            <div className="form-group grid__full">
              <div className="glass input-group">
                <div className="login-session">
                  <label
                    htmlFor="loginSession"
                    className="adv-check form-component"
                  >
                    <input
                      type="radio"
                      name="default_non_nigerian_id_provider"
                      value={1}
                      checked={
                        default_non_nigerian_id_provider === 1
                          ? true
                          : undefined
                      }
                      data-form="revenue"
                      disabled={!revenueEdit}
                      onChange={(e) => {
                        onInputChange(e.currentTarget);
                      }}
                      className="has-value"
                    />
                    <i className="primary" />
                    SMILE ID
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <CustomSelectDropdown
                onChange={(e: any) => {
                  onInputChange(e.currentTarget);
                }}
                name="funder_usd_wallet_product_id"
                value={funder_usd_wallet_product_id}
                label="Usd Default Investment Fund"
                disabled={!revenueEdit}
                className="round user__form form-control mb-3"
              >
                <option value=""> Select Usd default product</option>
                {mifosData &&
                  mifosData.map((data: any) => {
                    const { id, name } = data;
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>
            <div className="col-md-12">
              <CustomSelectDropdown
                onChange={(e: any) => {
                  onInputChange(e.currentTarget);
                }}
                name="funder_lcy_wallet_product_id"
                value={funder_lcy_wallet_product_id}
                label="Local Default Investment Fund"
                disabled={!revenueEdit}
                className="round user__form form-control mb-3"
              >
                <option value=""> Select Naira default product</option>
                {mifosData &&
                  mifosData.map((data) => {
                    const { id, name } = data;
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>
          </form>
        </div>

        <div className="grid__full">
          {revenueEdit ? (
            <div className="Form__action-buttons">
              <button
                type="button"
                className="btn zoom-element login-btn m-r-1"
                data-form="revenue"
                onClick={(e) => onCancelUpdate(e.currentTarget)}
              >
                <i className="fas fa-times-circle" />
                &nbsp; Cancel
              </button>
              <button
                type="button"
                className="btn zoom-element login-btn m-l-1"
                data-form="revenue"
                onClick={(e) => onRevenueUpdate(e.currentTarget)}
              >
                <i className="fas fa-save" />
                &nbsp; Save
              </button>
            </div>
          ) : (
            <button
              type="submit"
              className="zoom-element btn login-btn"
              data-form="revenue"
              onClick={(e) => onEditMode(e.currentTarget)}
            >
              <i className="fas fa-edit" />
              &nbsp; Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenueForm;
