import React, { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import { postDataWithDotNet } from "../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import InvestorWalletToWalletFunding from "./InvestorWalletToWalletFunding";
import InvestorTopUpMethod from "./InvestorTopUpMethod";
import InvestorBankTransferTopUp from "./InvestorBankTransferTopUp";
import { ADMIN_INITIATE_WALLET_TOP_UP } from "../../../../../api/ajax-endpoints";

interface IProps {
  funderId: number;
  wallet_id: string;
  currency: string;
  availableBalance: number;
  goBack: () => void;
}
export interface ITopUpInfo {
  wallet_id: String;
  funder_id: number | undefined;
  amount: number | undefined;
  narration: string;
  transaction_date: string;
  currency: string;
  base64_file_string: string;
  filename_with_extension: string;
}

export enum WalletFundingSteps {
  PaymentMethod = "PaymentMethod",
  BankTransfer = "BankTransfer",
  UploadReceipt = "UploadReceipt",
  FundWithWallet = "FundWithWallet",
  FundWithBankTransfer = "FundWithBankTransfer",
}

const InvestorWalletTopUp = ({
  funderId,
  wallet_id,
  currency,
  goBack,
  availableBalance,
}: IProps) => {
  const defaultTopUpInfo = {
    wallet_id: String(wallet_id) || "",
    funder_id: funderId,
    amount: undefined,
    narration: "",
    transaction_date: "",
    currency: currency,
    base64_file_string: "",
    filename_with_extension: "",
  };
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [topUpInfo, setTopUpInfo] = useState<ITopUpInfo>(defaultTopUpInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState<"wallet" | "bankTransfer" | undefined>();
  const [currentStep, setCurrentStep] = useState(
    WalletFundingSteps.FundWithBankTransfer
  );

  function handleChangeAmount(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setTopUpInfo({ ...topUpInfo, amount: Number(target.value) });
  }

  function handleChangeNarration(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setTopUpInfo({ ...topUpInfo, narration: target.value });
  }

  const handleSubmitTopUp = async (e: React.FormEvent<HTMLFormElement>) => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.ADMIN_INITIATE_WALLET_TOP_UP,
        topUpInfo
      );
      console.log({
        response,
      });
      setSuccessMessage(response?.message);
      setTimeout(() => {
        handleResetState();
      }, 2000);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "An error occurred");
      console.log({ error: error?.response?.data?.message });
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetState = () => {
    setTopUpInfo(defaultTopUpInfo);
    setCurrentStep(WalletFundingSteps.PaymentMethod);
    goBack();
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "InvestorWalletTopUp.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (type === "wallet") {
      setCurrentStep(WalletFundingSteps.FundWithWallet);
    }
    if (type === "bankTransfer") {
      setCurrentStep(WalletFundingSteps.FundWithBankTransfer);
    }
  }, [type]);

  const handleGoBack = () => {
    switch (currentStep) {
      case WalletFundingSteps.PaymentMethod:
        return goBack();
      case WalletFundingSteps.FundWithWallet:
      case WalletFundingSteps.FundWithBankTransfer:
        return setCurrentStep(WalletFundingSteps.PaymentMethod);
    }
  };

  const handleCurrentScreen = () => {
    switch (currentStep) {
      case WalletFundingSteps.FundWithWallet:
        return (
          <InvestorWalletToWalletFunding
            topUpInfo={topUpInfo}
            handleSubmitTopUp={handleSubmitTopUp}
            handleChangeAmount={handleChangeAmount}
            handleChangeNarration={handleChangeNarration}
            currency={currency}
            availableBalance={availableBalance}
            isLoading={isLoading}
          />
        );

      case WalletFundingSteps.FundWithBankTransfer:
        return (
          <InvestorBankTransferTopUp
            topUpInfo={topUpInfo}
            handleSubmitTopUp={handleSubmitTopUp}
            handleChangeAmount={handleChangeAmount}
            handleChangeNarration={handleChangeNarration}
            currency={currency}
            availableBalance={availableBalance}
            isLoading={isLoading}
            setErrorMessage={setErrorMessage}
            setTopUpInfo={setTopUpInfo}
          />
        );

      case WalletFundingSteps.PaymentMethod:
        return <InvestorTopUpMethod setType={setType} />;
    }
  };

  return (
    <div>
      <p className="cursor-pointer font-weight-bold mb-3" onClick={goBack}>
        <i className="fas fa-arrow-left mr-2" /> Back
      </p>
      <div className="row">
        {successMessage ? (
          <div className="col-md-6">
            <p className="alert alert-success">{successMessage}</p>
          </div>
        ) : errorMessage ? (
          <div className="col-md-6">
            <p className="alert alert-danger">{errorMessage}</p>
          </div>
        ) : null}
      </div>
      {/*  {handleCurrentScreen()}*/}
      <InvestorBankTransferTopUp
        topUpInfo={topUpInfo}
        setTopUpInfo={setTopUpInfo}
        setErrorMessage={setErrorMessage}
        handleSubmitTopUp={handleSubmitTopUp}
        handleChangeAmount={handleChangeAmount}
        handleChangeNarration={handleChangeNarration}
        currency={currency}
        availableBalance={availableBalance}
        isLoading={isLoading}
      />
    </div>
  );
};

export default InvestorWalletTopUp;
