import * as actionTypes from "./../actions/actionTypes";

const initialState = {
  settings: [],
  loading: false,
  error: null,
  profileForm: {
    fullName: "",
    phone: "",
    address: "",
  },
  loanForm: {
    loanProcess: "",
    automaticChecked: true,
    manualChecked: false,
    interestRate: "",
  },
  investmentForm: {
    treshold_amount: 0,
    minimum_amount: 0,
    maximum_amount: 2,
    callback_url: "",
  },
  repaymentForm: {
    repaymentCycle: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_DETAILS:
      return {
        ...state,
        profileForm: action.payload,
      };

    case actionTypes.AGGREGATORS_LOAN_SETTINGS:
      return {
        ...state,
        loanForm: action.payload,
      };

    case actionTypes.AGGREGATORS_INVESTMENT_SETTINGS:
      return {
        ...state,
        investmentForm: action.payload,
      };

    case actionTypes.AGGREGATORS_REPAYMENT_SETTINGS:
      return {
        ...state,
        repaymentForm: action.payload,
      };

    case actionTypes.SETTINGS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: [...action.transactions],
      };

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.UPDATE_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.SETTINGS_FAILURE:
      return {
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
