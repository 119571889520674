import SignUpInvestor from "./SignUpInvestor/SignUpInvestor";

const Investors = ({ properties, onCloseModal }: any) => {
  const { action } = properties;
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      {(() => {
        switch (action) {
          case "signupinvestor":
            return <SignUpInvestor />;

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Investors;
