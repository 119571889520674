import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export const getBankStatementProvider = async () => {
  const { data } = await getData(
    `${ajaxEndpoints.BANK_STATEMENT_PROVIDER_TYPES}`
  );
  return data?.data;
};
export default function useBankStatementProvider() {
  return useQuery(["getBankStatementProvider"], getBankStatementProvider, {
    refetchOnWindowFocus: false,
  });
}
