import { useState } from "react";

import CustomDropDownCard from "../../../../../../../components/CustomDropDownCard/CustomDropDownCard";
import addIcon from "../../../../../../../assets/svg/icons/add.svg";
import minusIcon from "../../../../../../../assets/svg/icons/minus.svg";

import styles from "./CreditScoreResultsAccordion.module.scss";

const CreditScoreResultsAccordion = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.CustomAccordionContainer}>
      <div className={styles.Trigger}>
        {title}
        <span
          className={
            data?.status_name === "Accepted" ? styles.Approved : styles.Rejected
          }
        >
          {data?.status_name}
        </span>
        <img
          src={isOpen ? minusIcon : addIcon}
          width={24}
          height={24}
          style={{ marginLeft: "0.75rem", cursor: "pointer" }}
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </div>

      {isOpen && (
        <CustomDropDownCard>
          <div className={styles.DropDownContainer}>
            <div className={styles.GeneralReason}>
              <span className={styles.Title}>Failed Verification</span>
              <ul>
                {data &&
                  data?.reasons !== null &&
                  data?.reasons.length > 0 &&
                  data?.reasons.map((_item) => <li>{_item?.description}</li>)}
              </ul>
            </div>

            <div className={styles.VerificationStates}>
              <span className={styles.Title}>All Verifications</span>
              <ul>
                {data &&
                  data?.verification_states !== null &&
                  data?.verification_states.length > 0 &&
                  data?.verification_states.map((_item) => (
                    <li>
                      <span className={styles?.Status}>
                        {" "}
                        Status:{" "}
                        {_item?.hasRuleFailed === false ? (
                          <strong className={styles.Passed}>Passed</strong>
                        ) : (
                          <strong className={styles.Failed}>Failed</strong>
                        )}
                      </span>
                      <br />
                      {_item?.description}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </CustomDropDownCard>
      )}
    </div>
  );
};

export default CreditScoreResultsAccordion;
