import { TCurrencyCodes } from "../interfaces/currencies";
import { getCurrencyNameByCode, getCurrencySymbolByCode } from "./currencies";

export const resolveCurrencyCodeBadges = (
  badges: { code: TCurrencyCodes }[]
) => {
  return badges
    .map(({ code }) => {
      return getCurrencySymbolByCode(code) || null;
    })
    .filter((badge) => badge);
};
