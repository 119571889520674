import React from "react";
import styles from "../CreditBureau.module.scss";

const CreditBureauTableProfileOverview = ({ creditProfileOverview }: any) => {
  return (
    <div>
      <h5 className={` ${styles.creditBureauHeading}`}>Credit Risk Overview</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Indicator
            </th>
            <th className="th-td-style th-style" scope="col">
              value
            </th>
          </tr>
        </thead>
        <tbody>
          {creditProfileOverview?.map((creditOverView: any, index: number) => {
            const { indicator, value } = creditOverView;
            return (
              <tr key={index}>
                <td className="th-td-style">{indicator}</td>
                <td className="th-td-style">{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableProfileOverview;
