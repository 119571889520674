import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAllFailedBatchDisbursement = async () => {
  const data = await getDataWithDotNet(ajaxEndpoints.Failed_BATCH_DISBURSEMENT);
  return data;
};

export default function useAllFailedBatchDisbursement() {
  return useQuery(
    "getAllFailedBatchDisbursement",
    getAllFailedBatchDisbursement
  );
}
