import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TInvestmentProductDetails = {
  core_banking_product_id: number;
  core_banking_tenant_id: string;
  created_date: string;
  currency_code: string;
  id: number;
  investment_size: number;
  is_locked: boolean;
  maximum_investment: number;
  minimum_investment: number;
  modified_date: string;
  percentage_raised: number;
};

type TInvestmentProduct = {
  description: string;
  end_date: string;
  id: number;
  investment_product_details: TInvestmentProductDetails[];
  investment_type: number;
  issuers: string;
  lcy_corebanking_id: number;
  lcy_currency_code: string;
  lcy_investment_size: number;
  lcy_liquidation_channel_account_id: number;
  lcy_liquidation_channel_id: number;
  lcy_locked: boolean;
  lcy_maximum_investment: number;
  lcy_minimum_investment: number;
  lcy_percentage_raised: number;
  modifiedDate: string;
  name: string;
  objective: string;
  pubDate: string;
  start_date: string;
  target_investors: string;
  usd_corebanking_id: string;
  usd_currency_code: string;
  usd_investment_size: string;
  usd_liquidation_channel_account_id: number;
  usd_liquidation_channel_id: number;
  usd_locked: boolean;
  usd_maximum_investment: number;
  usd_minimum_investment: number;
  usd_percentage_raised: number;
};

const getAllInvestmentsProducts = async (searchBy: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT}?status=${searchBy}`
  );
  return data;
};

export default function useAllInvestmentsProducts(searchBy: number) {
  return useQuery<IResponse<TInvestmentProduct[]>>(
    [searchBy, "getAllInvestmentsProducts"],
    getAllInvestmentsProducts,
    {
      refetchOnWindowFocus: false,
    }
  );
}
