import { useEffect, useState } from "react";
import { appInsights } from "../../../config/appInsights";
import {
  TAggregatorResponse,
  getAllAggregators,
} from "../../../custom-hooks/useAllAggregatorsTyped";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import useFundingPartners from "../../../custom-hooks/useFundingPartners";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { ICreateWallet } from "../interface";
import { getData, postData } from "../../../newApis/dotNetApiMethods";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { useHistory } from "react-router-dom";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";

const schema = yup.object().shape({
  fundingPartnerId: yup.string().required("Funding partner is required"),
  // aggregatorId: yup.string().required("Aggregator is required"),
  incorporationDate: yup.string().required("Date of incorporation is required"),
  directorBvn: yup
    .string()
    .min(11, "BVN must be 11 numbers")
    .required("BVN is required")
    .max(11, "BVN must not be more that 11 numbers"),
});

const CreateFundingWallet = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [selectedAggregator, setSelectedAggregator] = useState<{
    label: string;
    value: { aggregator_id: number; biz_registration_number: string };
    fullData?: {
      registrationNo: string;
      registrationDate: string;
    };
  }>();

  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "CreateFundingWallet",
      isLoggedIn: true,
    });
  }, []);

  const dispatch = useDispatch();

  const { data: fundingPartners } = useFundingPartners();

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAggregatorChange = async (record: {
    label: string;
    value: { aggregator_id: number; biz_registration_number: string };
  }) => {
    setValue("incorporationDate", "");
    setSelectedAggregator(record);
    setValue("aggregatorId", record?.value?.aggregator_id);
    if (record?.value?.biz_registration_number) {
      await handleVerifyBusiness(record?.value?.biz_registration_number);
    }
  };

  function getOptions(query: string) {
    return new Promise((resolve, reject) => {
      getAllAggregators({ SearchValue: query })
        .then((response: TAggregatorResponse) => {
          resolve(
            response.data.data.map(
              ({ aggregator_id, biz_name, biz_registration_number }) => ({
                value: { aggregator_id, biz_registration_number },
                label: biz_name,
              })
            )
          );
        })
        .catch(reject);
    });
  }

  const convert = (dateString: string) => {
    // Convert the date string to a Date object
    var dateObject = new Date(dateString);

    // Extract the year, month, and day components from the Date object
    var year = dateObject.getFullYear();
    // Month in JavaScript Date object is zero-indexed, so we add 1 to get the correct month
    var month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    var day = dateObject.getDate().toString().padStart(2, "0");

    // Construct the ISO 8601 date string in the format "YYYY-MM-DD"
    var isoDateString = year + "-" + month + "-" + day;

    return isoDateString;
  };

  const onSubmit = async (values: ICreateWallet) => {
    setIsLoading(true);
    const payload = {
      fundingPartnerId: Number(values?.fundingPartnerId),
      aggregatorId: selectedAggregator?.value?.aggregator_id,
      directorBvn: values?.directorBvn.toString(),
      incorporationDate: values?.incorporationDate,
    };
    try {
      const response = await postData(ajaxEndpoints.FUNDING_WALLET, payload);

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "success",
              message: "Wallet Created Successfully!!",
            },
          })
        );
        setTimeout(() => {
          history.push("/funding-wallets");
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message,
            },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsLoading(false);
  };

  const handleVerifyBusiness = async (businessRegistrationNumber: string) => {
    setIsVerifying(true);
    try {
      const response = await getData(
        `${ajaxEndpoints.VERIFY_BUSINESS}?businessRegistrationNumber=${businessRegistrationNumber}&countryCode=NG`
      );

      if (response?.status === 200) {
        setValue(
          "incorporationDate",
          convert(response?.data?.data?.fullData?.registrationDate)
        );
        setSelectedAggregator((prev) => {
          return {
            ...prev!,
            fullData: {
              registrationNo: response?.data?.data?.fullData?.registrationNo,
              registrationDate:
                response?.data?.data?.fullData?.registrationDate,
            },
          };
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreateFundingWallet.tsx" },
      });
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <div>
      <div className="datatable-wrapper">
        <div className="table-view speed-1x animated fadeInRight">
          <div className="d-flex flex-row flex-wrap mb-4">
            <h3 className="page-title d-flex align-items-center">
              <BackButtonComponent /> Create Wallet
            </h3>
          </div>
          <h4 className="mb-4">
            Available Wallets: Create New or Select Existing
          </h4>
          <div className="d-flex flex-row flex-wrap"></div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
              <CustomSelectDropdown
                label="Select Funding Partner"
                reference={register}
                name="fundingPartnerId"
                errors={errors.fundingPartnerId}
                labelClass="label-txt form-label d-flex justify-content-between"
              >
                <option value="">Select Funding Partner</option>
                {fundingPartners &&
                  fundingPartners?.data.map((_fundingPartner) => (
                    <option
                      key={_fundingPartner?.id}
                      value={_fundingPartner?.id}
                    >
                      {_fundingPartner?.name}
                    </option>
                  ))}
              </CustomSelectDropdown>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
              {/* <CustomSelectDropdown
                label="Select Customer (Aggregator)"
                name="aggregatorId"
                reference={register}
                errors={errors.aggregatorId}
                labelClass="label-txt form-label d-flex justify-content-between"
              >
                <option value="">Select Aggregator</option>
                {aggregatorData &&
                  aggregatorData?.data?.data.map((_aggregator) => (
                    <option
                      key={_aggregator?.aggregator_id}
                      value={_aggregator?.aggregator_id}
                    >
                      {_aggregator?.biz_name}
                    </option>
                  ))}
              </CustomSelectDropdown> */}

              <CustomSearchDropDown
                label="Select Aggregator"
                loadOptions={getOptions}
                onChange={handleAggregatorChange}
                isShowLabel
                inputHeight={50}
                name="aggregatorId"
                register={register}
                errors={errors.aggregatorId}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <CustomInputField
                type="date"
                label="Company's Incorporation Date"
                reference={register}
                name="incorporationDate"
                errors={errors.incorporationDate}
                loadingMessage={isVerifying ? "Verifying business..." : ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-0">
              <CustomInputField
                type="text"
                label="BVN of the company's board of Directors"
                reference={register}
                name="directorBvn"
                errors={errors.directorBvn}
              />
            </div>
          </div>

          <div className="row">
            <button className="ml-3 btn advancly-btn">
              {isLoading ? <ButtonLoader /> : "Create Wallet"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateFundingWallet;
