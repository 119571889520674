import { ChangeEvent, Fragment, useState, memo } from "react";

import Alert from "../../../../NewComponents/Alert/Alert.component";
import { RollOverType } from "../../Tabs/EachInvestorInvestment/types";

import style from "./PrincipalOrInterest.module.scss";

interface IPrincipalOrInterestModal {
  setSelectedRollOverType: React.Dispatch<
    React.SetStateAction<RollOverType | null>
  >;
}

const PrincipalOrInterestModal = ({
  setSelectedRollOverType,
}: IPrincipalOrInterestModal) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [rollOverType, setRollOverType] = useState<RollOverType>("Principal");

  const handleRollOverTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as RollOverType;
    setRollOverType(value);
  };

  const handleContinue = () => {
    const principalOrInterestContinueButton = document.getElementById(
      "principalOrInterestContinue"
    );

    if (principalOrInterestContinueButton != undefined) {
      principalOrInterestContinueButton.dataset.dismiss = "modal";
      principalOrInterestContinueButton.click();

      setSelectedRollOverType(rollOverType);
      principalOrInterestContinueButton.dataset.toggle = "modal";
      principalOrInterestContinueButton.dataset.target = "#rollOverModal";
      principalOrInterestContinueButton.click();
    }
  };

  return (
    <Fragment>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type={null}
          place={null}
          autoDismiss={null}
          icon={null}
        />
      )}
      {successMessage && (
        <Alert
          message={successMessage}
          place={null}
          autoDismiss={null}
          icon={null}
          type="success"
        />
      )}
      <div
        className="modal fade"
        id="principalOrInterestModal"
        tabIndex={-1}
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
        aria-labelledby="principalOrInterestModalLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="page-subtitle">Which do you want roll over?</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className={`modal-body ${style.principalorinterestBody}`}>
              <div
                className={`${style.action} ${
                  rollOverType === "Principal" && style.highlightedAction
                }`}
              >
                <div>
                  <input
                    type="radio"
                    id="principal"
                    name="roll_over_type"
                    value={"Principal" || ""}
                    className={style.principalRadio}
                    onChange={handleRollOverTypeChange}
                    checked={rollOverType === "Principal"}
                  />
                  <label htmlFor="principal">Principal</label>
                </div>
                <p className={style.rolloverTypeText}>
                  Roll over the original amount you invested. This does not
                  include interest earned. 
                </p>
              </div>

              <div
                className={`${style.action} ${
                  rollOverType === "MaturityAmount" && style.highlightedAction
                }`}
              >
                <div>
                  <input
                    type="radio"
                    id="interest"
                    name="roll_over_type"
                    className={style.principalRadio}
                    value={"MaturityAmount" || ""}
                    onChange={handleRollOverTypeChange}
                    checked={rollOverType === "MaturityAmount"}
                  />
                  <label htmlFor="principal">Maturity Amount</label>
                </div>
                <p className={style.rolloverTypeText}>
                  Roll over the profits you earned on your investment. This does
                  not include the principal invested. 
                </p>
              </div>

              <button
                className="create zoom-element btn login-btn w-100"
                id="principalOrInterestContinue"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default memo(PrincipalOrInterestModal);
