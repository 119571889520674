import React from "react";
// Redux
import { connect } from "react-redux";
import { setToast } from "./../../../../redux/actions/toast";
// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import {
  getAllLoans,
  setLoanApprovedValue,
  setLoanEndDateValue,
  setLoanStartDateValue,
  setLoanDisbursedValue,
  setLoanStatusValue,
  setLoanOnMifos,
  setLoanFilterEndDate,
  setLoanFilterStartDate,
  setBorrowerName,
  setLoanId,
  setBvn,
  setRcNumber,
  setAggregatorId,
  setLoanRef,
} from "../../../../redux/actions/loans";
import { BASE_URL } from "../../../../appConstants";
import { getTokenFromLocalStorage } from "../../../../helpers/localStorage";
import useAllAggregators from "../../../../custom-hooks/useAllAggregators";
import { appInsights } from "../../../../config/appInsights";

const AdvanceSearch = (props) => {
  const { data: aggregatorData } = useAllAggregators();

  const {
    getAllLoans,
    onCloseModal,
    setLoanApprovedValue,
    setLoanEndDateValue,
    setLoanStartDateValue,
    setLoanDisbursedValue,
    setLoanStatusValue,
    setLoanFilterEndDate,
    setLoanFilterStartDate,
    setBorrowerName,
    setLoanId,
    setBvn,
    setRcNumber,
    setAggregatorId,
    setLoanRef,
    IsMigratedtoCBA,
    setLoanOnMifos,
    startDate,
    endDate,
    is_disbursed,
    is_approved,
    loan_status,
    loanStartDate,
    loanEndDate,
    borrowerName,
    loanId,
    bvn,
    rcNumber,
    aggregatorId,
    loanRef,
  } = props;
  const searchLoan = (e) => {
    e.preventDefault();
    const params = {
      page: 1,
      size: 20,
      disbursed: is_disbursed,
      approved: is_approved,
      status: Number(loan_status),
      disbursement_from: loanStartDate,
      disbursement_to: loanEndDate,
      IsMigratedtoCBA,
      startDate,
      endDate,
      borrowerName,
      loanId,
      bvn,
      rcNumber,
      aggregatorId,
      loanRef,
    };
    //   setButtonLoading(true);
    onCloseModal();
    getAllLoans(params);
  };
  const getLoanRecordsModal = async (e) => {
    e.preventDefault();
    let queryParams = "";
    if (
      is_disbursed === "true" ||
      is_disbursed === true ||
      is_disbursed === "false" ||
      is_disbursed === false
    ) {
      // Convert to boolean if it is a string
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }disbursed=${JSON.parse(is_disbursed)}`;
    }
    if (
      is_approved === "true" ||
      is_approved === true ||
      is_approved === "false" ||
      is_approved === false
    ) {
      // Convert to boolean if it is a string
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }approved=${JSON.parse(is_approved)}`;
    }
    if (
      IsMigratedtoCBA === "true" ||
      IsMigratedtoCBA === true ||
      IsMigratedtoCBA === "false" ||
      IsMigratedtoCBA === false
    ) {
      // Convert to boolean if it is a string
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }IsMigratedtoCBA=${JSON.parse(IsMigratedtoCBA)}`;
    }
    if (loan_status) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }status=${loan_status}`;
    }
    if (loanStartDate) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }disbursement_from=${loanStartDate}`;
    }
    if (loanEndDate) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }disbursement_to=${loanEndDate}`;
    }
    if (startDate && endDate) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }from_date=${startDate}&to_date=${endDate}`;
    }
    if (borrowerName) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }borrowername=${borrowerName}`;
    }
    if (bvn) {
      queryParams = `${queryParams ? queryParams + "&" : ""}bvn=${bvn}`;
    }
    if (rcNumber) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }rcnumber=${rcNumber}`;
    }
    if (aggregatorId) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }aggregatorId=${Number(aggregatorId)}`;
    }
    if (loanId) {
      queryParams = `${queryParams ? queryParams + "&" : ""}loanid=${Number(
        loanId
      )}`;
    }
    if (loanRef) {
      queryParams = `${
        queryParams ? queryParams + "&" : ""
      }loanreference=${loanRef}`;
    }
    let token = getTokenFromLocalStorage();

    await fetch(BASE_URL + `/eco/search_loan_csv?${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `loans.csv`);
            link.click();
          });
        }
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          props.history.push("/login");
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AdvanceSearch.jsx",
          },
        });
      });
    onCloseModal();
    getAllLoans({
      page: 1,
      size: 20,
      disbursed: is_disbursed,
      approved: is_approved,
      status: Number(loan_status),
      disbursement_from: loanStartDate,
      disbursement_to: loanEndDate,
      IsMigratedtoCBA,
      startDate,
      endDate,
      borrowerName,
      loanId,
      bvn,
      rcNumber,
      aggregatorId,
      loanRef,
    });
  };

  const resetFields = () => {
    setLoanDisbursedValue("");
    setLoanOnMifos("");
    setLoanApprovedValue("");
    setLoanStatusValue("");
    setBorrowerName("");
    setLoanId("");
    setBvn("");
    setRcNumber("");
    setAggregatorId("");
    setLoanRef("");
    setLoanStartDateValue("");
    setLoanEndDateValue("");
    setLoanFilterStartDate("");
    setLoanFilterEndDate("");
  };

  return (
    <div className="LoanModal Modal__content">
      <div className="Modal__body" style={{ overflow: "hidden" }}>
        <React.Fragment>
          <h3 className="Modal__title p-b-1">Search By:</h3>
          {/* 2. Confirm Update Role Modal*/}
          <div id="disbursement-confirm">
            <div className="m-b-30">
              <form>
                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <CustomSelectDropdown
                      label="Is Disbursed"
                      name="is_disbursed"
                      className="round user__form form-control"
                      value={is_disbursed}
                      onChange={(e) => {
                        setLoanDisbursedValue(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <CustomSelectDropdown
                      label="On Mifos"
                      name="IsMigratedtoCBA"
                      value={IsMigratedtoCBA}
                      className="round user__form form-control"
                      onChange={(e) => {
                        setLoanOnMifos(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <CustomSelectDropdown
                      label="Is Approved"
                      name="is_approved"
                      value={is_approved}
                      className="round user__form form-control"
                      onChange={(e) => {
                        setLoanApprovedValue(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <CustomSelectDropdown
                      label="Loan Status"
                      value={loan_status}
                      className="round user__form form-control"
                      onChange={(e) => {
                        setLoanStatusValue(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option value={100}>Awaiting Approval</option>
                      <option value={200}>Approved</option>
                      <option value={300}>Active</option>
                      <option value={400}>Withdrawn By Client</option>
                      <option value={500}>Rejected</option>
                      <option value={600}>Closed</option>
                      <option value={601}>Written Off</option>
                      <option value={602}>Resheduled</option>
                      <option value={700}>Over Paid</option>
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Borrower Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setBorrowerName(e.target.value);
                      }}
                      value={borrowerName}
                      name="borrowerName"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Loan Id:</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => {
                        setLoanId(e.target.value);
                      }}
                      value={loanId}
                      name="loanId"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">BVN:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setBvn(e.target.value);
                      }}
                      value={bvn}
                      name="bvn"
                    />
                  </div>
                  <div className="col-md-3 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Rc Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setRcNumber(e.target.value);
                      }}
                      value={rcNumber}
                      name="rcNumber"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-2">
                    <CustomSelectDropdown
                      label="Aggregator:"
                      name="aggregatorId"
                      onChange={(e) => {
                        setAggregatorId(e.target.value);
                      }}
                      value={Number(aggregatorId)}
                      className="round user__form form-control"
                    >
                      <option value=""></option>
                      {aggregatorData &&
                        aggregatorData.map((aggregator) => {
                          const { biz_name, aggregator_id } = aggregator;
                          return (
                            <option
                              value={Number(aggregator_id)}
                              key={aggregator_id}
                            >
                              {biz_name}
                            </option>
                          );
                        })}
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-md-4 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Loan Reference:</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setLoanRef(e.target.value);
                      }}
                      value={loanRef}
                      name="loanRef"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Disbursed Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setLoanStartDateValue(e.target.value);
                      }}
                      value={loanStartDate}
                      name="loanStartDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Disbursed End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setLoanEndDateValue(e.target.value);
                      }}
                      disabled={!loanStartDate}
                      value={loanEndDate}
                      min={loanStartDate}
                      name="loanEndDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>

                  <div className="col-md-4 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Created Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setLoanFilterStartDate(e.target.value);
                      }}
                      value={startDate}
                      name="startDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 mt-2">
                    <label htmlFor="table-fetch">Created End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setLoanFilterEndDate(e.target.value);
                      }}
                      min={startDate}
                      value={endDate}
                      name="endDate"
                      placeholder="YYYY/MM/DD"
                    />
                  </div>
                </div>
                <div className="Form__action-buttons mt-4 d-flex justify-content-between">
                  <button
                    type="submit"
                    onClick={(e) => searchLoan(e)}
                    className="btn zoom-element btn login-btn"
                  >
                    <i className="fa fa-check-circle" /> Filter
                  </button>
                  <button
                    id="create"
                    data-id="create-role"
                    data-action="create"
                    className="create zoom-element btn login-btn"
                    onClick={getLoanRecordsModal}
                    disabled={
                      !IsMigratedtoCBA &&
                      !setLoanOnMifos &&
                      !startDate &&
                      !endDate &&
                      !is_disbursed &&
                      !is_approved &&
                      !loan_status &&
                      !loanStartDate &&
                      !loanEndDate &&
                      !borrowerName &&
                      !loanId &&
                      !bvn &&
                      !rcNumber &&
                      !aggregatorId &&
                      !loanRef
                    }
                  >
                    <i className="fas fa-download" /> &nbsp; Download
                  </button>

                  <button
                    type="button"
                    onClick={resetFields}
                    className="btn zoom-element btn login-btn"
                  >
                    <i className="fa fa-refresh" /> Reset
                  </button>

                  <button
                    type="button"
                    onClick={props.onCloseModal}
                    className="btn zoom-element btn login-btn"
                  >
                    <i className="fa fa-times-circle" /> Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* 2. Disbursement Loader*/}
          <div
            id="disbursement-loader"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className=" form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading" id="adv-loader-alt" />
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier fas fa-user-cog" />
                  Searching ...
                </div>
              </div>
            </div>
          </div>

          {/* 3. Disbursement Success Message*/}
          <div
            id="disbursement-success"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <div className="form-loader form-loader-login verify">
              <div className="centerLoader">
                <div className="loading success-loader">
                  <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                </div>
                <div className="center-txt webapp-ripple-text">
                  <i className="notifier far fa-check-circle" />
                  Search Successful
                </div>
              </div>
            </div>
          </div>

          {/* 4. Disbursement Error */}
          <div
            id="disbursement-error"
            className="hidden loading-overlay speed-1x fadeInRight"
          >
            <ErrorLoader
              icon="fas fa-wifi"
              text="Network Issue"
              subText="Unable to connect to Advancly services."
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    loansReducer: {
      loans: {
        is_approved,
        is_disbursed,
        loan_status,
        loanStartDate,
        loanEndDate,
        IsMigratedtoCBA,
        borrowerName,
        loanId,
        bvn,
        rcNumber,
        aggregatorId,
        loanRef,
        endDate,
        startDate,
      },
    },
  } = state;
  return {
    is_approved,
    is_disbursed,
    loan_status,
    loanStartDate,
    loanEndDate,
    IsMigratedtoCBA,
    borrowerName,
    loanId,
    bvn,
    rcNumber,
    aggregatorId,
    loanRef,
    endDate,
    startDate,
  };
};

export default connect(mapStateToProps, {
  setToast,
  getAllLoans,
  setLoanApprovedValue,
  setLoanEndDateValue,
  setLoanStartDateValue,
  setLoanDisbursedValue,
  setLoanStatusValue,
  setLoanFilterEndDate,
  setLoanFilterStartDate,
  setLoanOnMifos,
  setBorrowerName,
  setLoanId,
  setBvn,
  setRcNumber,
  setAggregatorId,
  setLoanRef,
})(AdvanceSearch);
