import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

export type TFundingRequests = {
  status: string;
  loanReference: string;
  aggregator: string;
  amount: number;
  interestRate: number;
  outstandingBalance: number;
  tenor: number;
  tenorType: string;
  dateTimeCreated: string;
};

type TQuery = {
  fundingWalletId: string;
};

const getFundingRequests = async ({ fundingWalletId }: TQuery) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_FUNDING_REQUESTS}?fundingWalletId=${fundingWalletId}`
  );

  return data;
};

export default function useFundingRequests({ fundingWalletId }: TQuery) {
  return useQuery<IResponse<TFundingRequests[]>>(
    [{ fundingWalletId }, "getFundingRequests"],
    getFundingRequests,
    {
      refetchOnWindowFocus: false,
    }
  );
}
