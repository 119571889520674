import React from "react";

import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { useForm } from "react-hook-form";
const schema = yup.object().shape({
  comment: yup.string().trim().required("Please enter your comment"),
});
const ApproveOrDeclineRolloverCommentsChecker = ({
  setcurrentStep,
  setCurrentComments,
  action,
  onCloseModal,
}: any) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const handleComments = (data: { comment: string }) => {
    const { comment } = data;
    setCurrentComments(comment);
    setcurrentStep(2);
  };
  return (
    <div>
      <h2>
        {action === "approve"
          ? " Approve Rollover Request"
          : " Decline Rollover Request"}
      </h2>
      <div>
        <p
          className="text-justify py-3
        "
        >
          Please add a comment to complete this process.
        </p>
        <form onSubmit={handleSubmit(handleComments)}>
          <div className="row">
            <div className="col-12">
              <CustomTextArea
                maxLength={256}
                reference={register}
                placeholder="Enter comment here..."
                label="Comment"
                name="comment"
                showRequiredIcon={true}
                errors={errors?.comment}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end flex-wrap">
              <button
                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                type="button"
                onClick={() => onCloseModal()}
              >
                Cancel
              </button>
              <button
                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                type="submit"
              >
                Proceed
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApproveOrDeclineRolloverCommentsChecker;
