// Redux Action CONSTANTS
import * as actionTypes from "./actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";
export const getAggregators = () => {
  return function (dispatch) {
    dispatch({
      type: actionTypes.GET_AGGREGATORS_START,
    });

    ajaxMethods
      .get(ajaxEndpoints.ECOSYSTEMS)
      .then((response) => {
        if (response) {
          if (response.status === true) {
            dispatch({
              type: actionTypes.GET_AGGREGATORS_SUCCESS,
              aggregators: response.data,
            });
          } else {
            dispatch({
              type: actionTypes.GET_AGGREGATORS_FAILURE,
              error: { message: "Network" },
            });
          }
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "aggregators.js",
          },
        });
        dispatch({
          type: actionTypes.GET_AGGREGATORS_FAILURE,
          error,
        });
      });
  };
};

export const getAggregatorDrilldown = (aggregator_id, queryString) => {
  return function (dispatch) {
    dispatch({ type: actionTypes.GET_DRILLDOWN_START });

    let endpoint = `${ajaxEndpoints.GET_AGG_DRILLDOWN}?aggregator_id=${aggregator_id}`;
    if (queryString) {
      endpoint = `${endpoint}&${new URLSearchParams(queryString).toString()}`;
    }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          if (response.status === true) {
            dispatch(getAggregatorDrilldownSuccess(response));
          } else {
            dispatch({
              type: actionTypes.GET_DRILLDOWN_FAILURE,
              error: { message: "Network" },
            });
          }
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "aggregators.js",
          },
        });
        dispatch({
          type: actionTypes.GET_DRILLDOWN_FAILURE,
          error,
        });
      });
  };
};

export const getAggregatorDrilldownSuccess = (response) => {
  // Columns for Funder DataTable
  const funderTableColumns = [
    { label: "#", field: "id", sort: "asc" },
    { label: "FIRST NAME", field: "first_name", sort: "asc" },
    { label: "LAST NAME", field: "last_name", sort: "asc" },
    { label: "PHONE", field: "phone", sort: "asc" },
    { label: "EMAIL", field: "email", sort: "asc" },
    { label: "MANAGE", field: "manage", sort: "asc" },
  ];

  const {
    aggregator_photo_url,
    aggregator_rep,
    biz_address,
    biz_category,
    biz_email,
    biz_name,
    biz_phone,
    is_active,
    modified_date,
    photo,
    pub_date,
    all_funders,
    all_borrowers,
  } = response.data;

  const reOrderedFunderTableData = reOrderRows(funderTableColumns, all_funders);

  return {
    type: actionTypes.GET_DRILLDOWN_SUCCESS,
    payload: {
      aggregatorDetails: {
        aggregator_photo_url,
        aggregator_rep: { ...aggregator_rep },
        biz_address,
        biz_category,
        biz_email,
        biz_name,
        biz_phone,
        is_active,
        modified_date,
        photo,
        pub_date,
      },
      funderTableData: reOrderedFunderTableData,
      partnerTableData: all_borrowers,
    },
  };
};

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns, responseDataRecords) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row, index) => {
        let newRow = {};
        tableColumns.forEach((column) => {
          if (column.field === "id") {
            newRow[column.field] = index + 1;
            return;
          }
          newRow[column.field] = row[column.field] ? row[column.field] : null;
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};
