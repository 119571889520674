import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

interface CustomerStockSearchDetails {
  Page: number;
  PageSize: number;
  DateFrom?: string | null;
  DateTo?: string | null;
  CustomerId: number;
}

const getCustomerStockDetails = async ({
  Page,
  PageSize,
  DateFrom,
  DateTo,
  CustomerId,
}: CustomerStockSearchDetails) => {
  let queryParams = `?customer_id=${CustomerId}&page=${Page}&page_size=${PageSize}`;
  if (DateFrom) {
    queryParams = `${queryParams}&from_date=${DateFrom}`;
  }
  if (DateTo) {
    queryParams = `${queryParams}&to_date=${DateTo}`;
  }

  const data = await getData(
    `${ajaxEndpoints.STOCK_DATA_OF_CUSTOMER}${queryParams}`
  );
  return data;
};

export const useCustomerStockDetails = ({
  Page,
  PageSize,
  DateFrom,
  DateTo,
  CustomerId,
}: CustomerStockSearchDetails) => {
  return useQuery(
    [
      { Page, PageSize, DateFrom, DateTo, CustomerId },
      "getCustomerStockDetails",
    ],
    getCustomerStockDetails,
    {
      refetchOnWindowFocus: false,
      enabled: CustomerId ? true : false,
    }
  );
};
