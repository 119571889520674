import * as apiEndpoints from "../api/ajax-endpoints"
import { useQuery } from "react-query"
import { getBankStatementFormats } from "../newApis/dotNetApiMethods"


async function fetchBankStatementFormats() {
    try {
        const res = await getBankStatementFormats(apiEndpoints.GET_BANK_STATEMENT_FORMATS)

        return res.data
    } catch (error) {
        console.error(error)
    }
}

export default function useFetchBankStatementFormats() {
    return useQuery(["fetchBankStatementFormats"], fetchBankStatementFormats, {
        refetchOnWindowFocus: true
    })
}