import { capitalize } from "./../helpers/stringControllers";

export const filterTableData = (tableData, filterBy, tableName) => {
  let { columns, rows } = { ...tableData },
    filteredRows,
    filteredData;

  if (filterBy.toLowerCase() === "all") {
    filteredRows = rows;
  } else {
    filteredRows = rows.filter((row) => {
      if (tableName === "transactions") {
        return row.transaction_type.toLowerCase() === filterBy.toLowerCase();
      }

      if (tableName.toLowerCase() === "issues") {
        return row.issue_status.toLowerCase() === filterBy.toLowerCase();
      }

      return row.status.props.children.toLowerCase() === filterBy.toLowerCase();
    });
  }

  filteredData = {
    columns,
    rows: filteredRows,
  };

  return filteredData;
};

export const modifyTransactionTableRows = (
  rows,
  tableName,
  actions,
  handler
) => {
  let newTableRows = rows.map((row, i) => {
    const { transaction_id, is_done } = row;
    const newRow = { ...row };

    if (row.hasOwnProperty("is_done")) {
      let StatusTag = (
        <span
          key={i}
          id={transaction_id}
          data-mode=""
          data-action=""
          className={`btn-display ${is_done ? "bg-success" : "bg-warning"}`}
        >
          {is_done ? "Done" : "Pending"}
        </span>
      );

      newRow.is_done = StatusTag;
    }

    const ManageButtons = (
      <span key={i} className="manage-options">
        {actions.map((action, j) => {
          return (
            <span
              key={j}
              id={j}
              data-payload={JSON.stringify(row)}
              data-table={tableName}
              data-action={action}
              onClick={(e) => {
                handler({
                  modalIdentifier: tableName,
                  dataRecord: row,
                  action: "",
                });
              }}
              className={`${tableName} ${action} btn-manage zoom-element btn-slim`}
            >
              {capitalize(action)}
            </span>
          );
        })}
      </span>
    );

    newRow.manage = ManageButtons;

    return newRow;
  });

  return newTableRows;
};

export const modifyIssuesTableRows = (rows, tableName, actions, handler) => {
  let newTableRows = rows.map((row, i) => {
    const { issue_id, issue_status } = row;
    const newRow = { ...row };

    if (row.hasOwnProperty("issue_status")) {
      let style = "";
      let label = "";

      if (issue_status === "open") {
        style = "bg-warning";
        label = "Pending";
      }

      if (issue_status === "closed") {
        style = "bg-success";
        label = "Resolved";
      }

      if (issue_status === "pending") {
        style = "bg-danger";
        label = "Cancelled";
      }

      let StatusTag = (
        <span
          key={i}
          id={issue_id}
          data-mode=""
          data-action=""
          className={`btn-display ${style}`}
        >
          {label}
        </span>
      );

      newRow.issue_status = StatusTag;
    }

    const ManageButtons = (
      <span key={i} className="manage-options">
        {actions.map((action, j) => {
          return (
            <span
              key={j}
              id={j}
              data-index={i}
              data-id={issue_id}
              data-table={tableName}
              data-action={action}
              onClick={(e) => {
                handler({
                  modalIdentifier: tableName,
                  dataRecord: row,
                  eventTarget: e.target,
                  action: "",
                });
              }}
              className={`${tableName} ${action} btn-manage zoom-element btn-slim`}
            >
              {capitalize(action)}
            </span>
          );
        })}
      </span>
    );

    newRow.manage = ManageButtons;

    return newRow;
  });

  return newTableRows;
};

export const attachActionButtons = (rows, tableName, actions, handler) => {
  let newTableRows = rows.map((row, i) => {
    const { loan_id } = row;
    const newRow = { ...row };

    let actionButtons = (
      <span key={i} className="manage-options">
        {actions.map((action, j) => {
          return (
            <span
              key={j}
              id={j}
              data-id={loan_id}
              data-table={tableName}
              data-action={action}
              onClick={(e) => {
                handler({
                  modalIdentifier: tableName,
                  dataRecord: row,
                  eventTarget: e.target,
                  action: action,
                });
              }}
              className={`${tableName} ${action} btn-manage zoom-element btn-slim`}
            >
              {capitalize(action)}
            </span>
          );
        })}
      </span>
    );
    newRow.manage = actionButtons;
    return newRow;
  });
  return newTableRows;
};

export const StatusTag = (props) => {
  const { id, property, activeVerb, inActiveVerb, negativeClass } = props;
  return (
    <span
      id={id}
      data-mode=""
      data-action=""
      className={`btn-display ${
        property ? "bg-success" : negativeClass || "bg-warning"
      }`}
    >
      {property ? activeVerb : inActiveVerb}
    </span>
  );
};

export const filterDataByColumns = (
  tableData,
  mode = "exclude",
  fields = []
) => {
  const { columns, rows } = tableData;

  let newColumns = columns.filter((column) => {
    let newArrayOfColumns = [];
    if (mode === "exclude") {
      newArrayOfColumns = fields.indexOf(column.field) === -1;
    } else if (mode === "include") {
      newArrayOfColumns = fields.indexOf(column.field) !== -1;
    }

    return newArrayOfColumns;
  });

  let newRows = rows.map((row) => {
    const filtered = Object.keys(row)
      .filter((key) =>
        mode === "exclude" ? !fields.includes(key) : fields.includes(key)
      )
      .reduce((obj, key) => {
        obj[key] = row[key];
        return obj;
      }, {});
    return { ...filtered };
  });

  return {
    columns: newColumns,
    rows: newRows,
  };
};

//TableFetchSelect

export const TableFetchSelect = (props) => {
  const { id, options, onChange, label, style, className, value } = props;
  return (
    <label htmlFor={id} className="form-group">
      {label}
      <select
        onChange={onChange}
        id={id}
        className={className}
        style={style}
        value={value}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value} defaultValue={option.selected}>
            {option.name}
          </option>
        ))}
      </select>
    </label>
  );
};

export const onTableFetchSelectChange = (
  e,
  showModal,
  endpointFn,
  endpointFnStr,
  queryString,
  endpointFnArguments
) => {
  e.preventDefault();
  const { value } = e.target;

  if (value === "customrange") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        endpointFn: endpointFnStr,
        endpointFnArguments,
      },
      action: "customrange",
    });
  } else if (value === "custom_range") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        endpointFn: endpointFnStr,
        endpointFnArguments,
      },
      action: "customrange",
    });
  } else if (value === "first_20_records") {
    endpointFn();
  } else if (endpointFnArguments) {
    // getTableData(`custom_range=${value}`)
    endpointFn([...endpointFnArguments], queryString + value);
  } else if (value === "borrowername") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        value: "borrowername",
        labelName: "Borrower Name",
      },
      action: "searchvalue",
    });
  } else if (value === "bvn") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        value: "bvn",
        labelName: "BVN",
      },
      action: "searchvalue",
    });
  } else if (value === "rcnumber") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        value: "rcnumber",
        labelName: "RC Number",
      },
      action: "searchvalue",
    });
  } else if (value === "loanid") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        value: "loanid",
        labelName: "Loan ID",
      },
      action: "searchvalue",
    });
  } else if (value === "loanreference") {
    showModal({
      modalIdentifier: "loan",
      dataRecord: {
        value: "loanreference",
        labelName: "Loan Reference",
      },
      action: "searchvalue",
    });
  } else {
    endpointFn(queryString + value);
  }
};

export const tableFetchSelectOptions = [
  { name: "search by", value: "" },
  // { name: "date range", value: "customrange" },
  { name: "borrower name", value: "borrowername" },
  { name: "bvn", value: "bvn" },
  { name: "rc number", value: "rcnumber" },
  { name: "loan id", value: "loanid" },
  { name: "loan reference", value: "loanreference" },
];
