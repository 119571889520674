import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  pageNumber: number;
  size: number;
  startDate: string;
  endDate: string;
  searchStatus: string;
};
const getWalletDisbursementTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  searchStatus,
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&start=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&end=${endDate}`;
  }
  if (searchStatus) {
    queryParams = `${queryParams}&status=${Number(searchStatus)}`;
  }

  const data = await getData(
    `${ajaxEndpoints.WALLET_DISBURSEMENT_TRANSACTION}?${queryParams}`
  );

  return data;
};

export default function useWalletDisbursementTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  searchStatus,
}: TQuery) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, searchStatus },
      "getWalletDisbursementTransactions",
    ],
    getWalletDisbursementTransactions
  );
}
