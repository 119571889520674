import * as actionTypes  from '../actions/actionTypes';

const initialState = {
    auditTrailTableData: {
        columns: [],
        rows: []
    },
    auditTrail: [],
    count: 0,
    next_page: '',
    prev_page: '',
    loading: false,
    error: null
};

export const reducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.AUDIT_AJAX_START:
            return {
                ...state,
                loading: true
            };
        
        case actionTypes.GET_AUDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ...action.payload
        };

        case actionTypes.GET_AUDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
        };

        default:
            return state;
    }
};

export default reducer;