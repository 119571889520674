import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonLoader from "../UI/Loaders/Loaders";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  error?: string | null;
  isLoading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  error,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Confirm Action</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to perform this action?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={onConfirm} disabled={isLoading}>
          Confirm
          {isLoading ? <ButtonLoader /> : null}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
