import { useState } from "react";
import { useQueryCache } from "react-query";
import usePendingDisbursements from "../../../../custom-hooks/usePendingDisbursements";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import Approver from "./Tabs/Approver";
import BatchDisbursement from "./Tabs/BatchDisbursement";
import BatchedDetails from "./Tabs/BatchedDetails";
import ApprovedLoans from "./Tabs/ApprovedLoans";

const Checker = () => {
  const [activeTab, setActiveTab] = useState("pending-disbursements");
  const queryCache = useQueryCache();

  const getPendingDisbursement = () => {
    return queryCache.invalidateQueries("getPendingDisbursements");
  };

  const { data, status, error } = usePendingDisbursements();
  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Approver</h3>
        <div className="alignToggleTabItems mb-3">
          <ToggleTab
            text="Pending Disbursements"
            id="pending-disbursements"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Approved Loans"
            id="approved-loans"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Batch Disbursement"
            id="batchdisbursement"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Batched Details"
            id="batchedDisbursementDetails"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case "pending-disbursements":
                return (
                  <Approver
                    Loading={status === "loading"}
                    pendingDisbursementData={data}
                    error={error}
                    getPendingDisbursement={getPendingDisbursement}
                  />
                );
              case "approved-loans":
                return <ApprovedLoans />;
              case "batchdisbursement":
                return <BatchDisbursement />;
              case "batchedDisbursementDetails":
                return <BatchedDetails />;

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default Checker;
