import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { ReactComponent as DollarIcon } from "../../../../assets/svg/icons/dollar.svg";
import { ReactComponent as NairaIcon } from "../../../../assets/svg/icons/naira.svg";
import { ReactComponent as KenyaIcon } from "../../../../assets/svg/icons/KSH.svg";
import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import { formatMoney } from "../../../../helpers/formatter";
import { getCurrencyInfo } from "../../helper";
import useCoreBankingTenantInfo from "../../../../custom-hooks/useCoreBankingTenant";
import useMifosProduct from "../../../../custom-hooks/useMifosProduct";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import { useWalletProviders } from "../../../../custom-hooks/useWallet";
import { useDepositTypes } from "../../../../custom-hooks/useDepositTypes";
import {
  TCreateInvestmentProductState,
  TInvestmentProductDetails,
} from "../CreateInvestmentProduct";

import styles from "./../../Investment.module.scss";
import { appInsights } from "../../../../config/appInsights";
import { onChangeFile } from "../../../../helpers/convertToFormData";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";

interface IEditFinancials {
  handleChange: (e: ChangeEvent<HTMLInputElement>, id?: number) => void;
  state: TCreateInvestmentProductState;
  setState: React.Dispatch<React.SetStateAction<TCreateInvestmentProductState>>;
  selectedProductDetails: any;
  editType: string;
  updateParentState: (details: TInvestmentProductDetails) => void;
}

export default function EditFinancials({
  handleChange,
  state,
  setState,
  editType,
  selectedProductDetails,
    updateParentState,
}: IEditFinancials) {
  const [checkStatus, setCheckStatus] = useState("off");

  const [selectedCoreBankingTenant, setSelectedCoreBankingTenant] =
    useState("");
  const [selectedExternalWalletProvider, setSelectedExternalWalletProvider] =
    useState("");
  const [selectedSystemWalletProvider, setSelectedSystemWalletProvider] =
    useState("");
  const [
    monthlyPrematureLiquidationRate,
    setMonthly_premature_liquidation_rate,
  ] = useState("");
  const [
    endOfTenurePrematureLiquidationRate,
    setEnd_of_tenure_premature_liquidation_rate,
  ] = useState("");
  const [selectedDepositType, setSelectedDepositType] = useState(
    selectedProductDetails?.depositType === "RecurringDeposit"
      ? 4
      : selectedProductDetails?.depositType === "FixedDeposit"
        ? 3
        : undefined,
  );

  const [error, setError] = useState<null | string>(null);
  const [isPickingDocument, setIsPickingDocument] = useState(false);
  const [termSheetFile, setTermSheetFile] = useState<{
    file: File | undefined;
    fileName: string;
  }>({
    file: undefined,
    fileName: "",
  });

  const { data: depositTypes } = useDepositTypes();

  const { data: walletProvider } = useWalletProviders();


  useEffect(() => {
    if (walletProvider) {
      const externalWalletProvider = walletProvider.find(
        (w) => w.name === selectedProductDetails?.externalWalletProvider,
      );
      const systemWalletProvider = walletProvider.find(
        (w) => w.name === selectedProductDetails?.systemWalletProvider,
      );
      if (externalWalletProvider) {
        setSelectedExternalWalletProvider(String(externalWalletProvider.id));
      }
      if (systemWalletProvider) {
        setSelectedSystemWalletProvider(String(systemWalletProvider.id));
      }
      handleSetProviderIdsOnMount();
    }
    setSelectedCoreBankingTenant(
      selectedProductDetails?.coreBankingTenantId?.toString(),
    );
  }, [walletProvider]);

  const handleSetProviderIdsOnMount = () => {
    const currenState = { ...state };
    const result = currenState.investmentProductDetails.find(
      (item) => item.currencyCode === selectedProductDetails?.currencyCode,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
      (item) => item === result,
    );
    const newProductDetailsArray = currenState?.investmentProductDetails;

    const externalWalletProvider = walletProvider?.find(
      (w) => w.name === selectedProductDetails?.externalWalletProvider,
    );
    const systemWalletProvider = walletProvider?.find(
      (w) => w.name === selectedProductDetails?.systemWalletProvider,
    );
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      externalWalletProvider: Number(externalWalletProvider),
      systemWalletProvider: Number(systemWalletProvider),
    };
  };

  const { data: coreBankingTenant } = useCoreBankingTenantInfo();

  const {
    data: mifosProduct,
    isError: isMifosProductError,
    error: mifosProductError,
    isLoading: isMifosProductLoding,
  } = useMifosProduct({
    productType: selectedDepositType,
    tenantId: selectedCoreBankingTenant,
  });

  const getAppropriateCurrencyIcon = (currencyInQuestion: string) => {
    switch (currencyInQuestion) {
      case "NGN":
        return {
          icon: <NairaIcon />,
          symbol: "\u20A6",
        };
      case "USD":
        return {
          icon: <DollarIcon />,
          symbol: "\u0024",
        };
      case "KES":
        return {
          icon: <KenyaIcon />,
          symbol: "KES",
        };

      default:
        break;
    }
  };

  const checkInvestmentCap = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (checkStatus === "on") {
      setCheckStatus("off");
    } else {
      setCheckStatus("on");
    }
  };

  const handleChangeTenant = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      setSelectedCoreBankingTenant("");
    } else {
      setSelectedCoreBankingTenant(e.target.value);
    }
  };

  const handleSearchBy = (e: ChangeEvent<HTMLSelectElement>) => {
    const eachData = mifosProduct?.find(
      (data) => data.id === Number(e.target.value),
    );

    if (eachData) {
      const { minimum_investment, maximum_investment, currency_code } =
        eachData;

      const currenState = { ...state };

      const result = currenState.investmentProductDetails.find(
        (item) => item.currencyCode === selectedProductDetails?.currencyCode,
      );

      const indexOfResult = currenState?.investmentProductDetails.findIndex(
        (item) => item === result,
      );

      const newProductDetailsArray = currenState?.investmentProductDetails;
      newProductDetailsArray[indexOfResult] = {
        ...newProductDetailsArray[indexOfResult],
        currencyCode: currency_code,
        coreBankingProductId: Number(e.target.value),
        coreBankingTenantId: selectedCoreBankingTenant,
        minimumInvestment: minimum_investment,
        maximumInvestment: maximum_investment,
        externalWalletProvider: Number(selectedExternalWalletProvider),
        systemWalletProvider: Number(selectedSystemWalletProvider),
        endOfTenurePrematureLiquidationRate: Number(
          endOfTenurePrematureLiquidationRate,
        ),
        monthlyPrematureLiquidationRate: Number(
          monthlyPrematureLiquidationRate,
        ),
        depositType: selectedDepositType,
      };

      setState((prev) => ({
        ...prev,
        investmentProductDetails: newProductDetailsArray,
      }));
    }
  };

  const handleChangeExternalWalletProvider = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setSelectedExternalWalletProvider(e.target.value);
    const currenState = { ...state };
    const result = currenState.investmentProductDetails.find(
      (item) => item.currencyCode === selectedProductDetails?.currencyCode,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
      (item) => item === result,
    );
    const newProductDetailsArray = currenState?.investmentProductDetails;
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      externalWalletProvider: Number(e.target.value),
    };

    setState((prev) => ({
      ...prev,
      investmentProductDetails: newProductDetailsArray,
    }));
  };

  const handleChangeSystemWalletProvider = (e: any) => {
    setSelectedSystemWalletProvider(e.target.value);
    const currenState = { ...state };
    const result = currenState.investmentProductDetails.find(
      (item) => item.currencyCode === selectedProductDetails?.currencyCode,
    );

    const indexOfResult = currenState?.investmentProductDetails.findIndex(
      (item) => item === result,
    );
    const newProductDetailsArray = currenState?.investmentProductDetails;
    newProductDetailsArray[indexOfResult] = {
      ...newProductDetailsArray[indexOfResult],
      systemWalletProvider: Number(e.target.value),
    };

    setState((prev) => ({
      ...prev,
      investmentProductDetails: newProductDetailsArray,
    }));
  };

  function handleChangeDepositType(e: any) {
    setSelectedDepositType(e.target?.value);
  }

  function handleChangeEndOfTenure(e: any) {
    const newRate = e.target.value;
    setEnd_of_tenure_premature_liquidation_rate(newRate);
    // Call the parent function to update the state in the parent component
    updateParentState({
      ...selectedProductDetails,
      endOfTenurePrematureLiquidationRate: newRate,
    });
  }

  useEffect(() => {
    if (selectedProductDetails?.endOfTenurePrematureLiquidationRate){
      setEnd_of_tenure_premature_liquidation_rate(selectedProductDetails?.endOfTenurePrematureLiquidationRate)
    }
    if (selectedProductDetails?.monthlyPrematureLiquidationRate){
      setMonthly_premature_liquidation_rate(selectedProductDetails?.monthlyPrematureLiquidationRate)
    }
  }, [selectedProductDetails]);

  function handleChangeMonthlyPrematureRate(e: React.ChangeEvent<HTMLInputElement>) {
    const newRate = e.target.value;
    setMonthly_premature_liquidation_rate(newRate);

    // Call the parent function to update the state in the parent component
    updateParentState({
      ...selectedProductDetails,
      monthlyPrematureLiquidationRate: newRate,
    });
  }

  async function onChangeTermSheet(e: any) {
    if (isPickingDocument) return; // Prevent multiple invocations
    setIsPickingDocument(true); // Set document picking status to true
    const errorCallback = (error: any) => {
      setError(error);
      appInsights.trackException({
        exception: new Error(error),
        properties: { fileName: "Financials.tsx" },
      });
      setIsPickingDocument(false); // Reset document picking status
    };

    const setInfoCallback = (file: File, fileName: string) => {
      setTermSheetFile({
        file,
        fileName,
      });
      setState({
        ...state,
        termSheetFile: {
          file,
          fileName,
        },
      });
      setIsPickingDocument(false); // Reset document picking status
    };

    await onChangeFile(e, setInfoCallback, errorCallback);
  }

  return (
    <>
      <div className="page-subtitle col-md-12 mt-3">
        <h3
          className="mb-3"
          style={{
            backgroundColor: "#F4F5F6",
            fontSize: "16px",
            padding: "10px",
          }}
        >
          {getCurrencyInfo(selectedProductDetails?.currencyCode)?.name}
        </h3>
      </div>
      <div className="col-md-12 mb-3">
        <CustomSelectDropdown
          onChange={handleChangeExternalWalletProvider}
          name="providerType"
          className="round user__form form-control w-100"
          label="Select External Wallet Provider (Investor)"
          value={selectedExternalWalletProvider}
        >
          <option value="">Select Provider</option>
          {walletProvider &&
            walletProvider.map((item) => {
              const { name, id } = item;
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>

      <div className="col-md-12 mb-3">
        <CustomSelectDropdown
          onChange={handleChangeSystemWalletProvider}
          name="providerType"
          className="round user__form form-control w-100"
          label="Select Internal Wallet Provider (System)"
          value={selectedSystemWalletProvider}
          disabled
        >
          <option value="">Select Provider</option>
          {walletProvider &&
            walletProvider.map((item) => {
              const { name, id } = item;
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-12">
        <CustomSelectDropdown
          onChange={handleChangeTenant}
          disabled
          name="coreBankingTenantId"
          value={selectedProductDetails?.coreBankingTenantId}
          label="Core Banking Tenant"
          className="round user__form form-control mb-3"
        >
          <option value=""> Select Core Banking Tenant</option>
          {coreBankingTenant &&
            coreBankingTenant?.data.map((data) => {
              const { full_name, id, tenant_id } = data;
              return (
                <option value={tenant_id} key={id}>
                  {full_name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>

      <div className="col-md-12">
        <CustomSelectDropdown
          onChange={handleChangeDepositType}
          // disabled={editType === "active"}
          name="depositType"
          value={selectedDepositType}
          label="Deposit Type"
          className="round user__form form-control mb-3"
        >
          <option value="">Select Deposit Type</option>
          {depositTypes &&
            depositTypes?.map((data) => {
              const { name, id } = data;
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              );
            })}
        </CustomSelectDropdown>
      </div>
      <div className="col-md-6 mb-3 mt-3">
        <label className="label-txt">Monthly Premature Liquidation Rate</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter a rate from 0 - 100"
          value={monthlyPrematureLiquidationRate}
          name="monthlyPrematureLiquidationRate"
          onChange={handleChangeMonthlyPrematureRate}
        />
        {(Number(monthlyPrematureLiquidationRate) < 0 ||
          Number(monthlyPrematureLiquidationRate) > 100) && (
          <p className="text-danger">
            Monthly Premature Liquidation rate must be between 0 and 100
          </p>
        )}
      </div>

      <div className="col-md-6 mb-3 mt-3">
        <label className="label-txt">
          End Of Tenure Premature Liquidation Rate
        </label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter a rate from 0 - 100"
          value={endOfTenurePrematureLiquidationRate}
          name="endOfTenurePrematureLiquidationRate"
          onChange={handleChangeEndOfTenure}
        />
        {(Number(endOfTenurePrematureLiquidationRate) < 0 ||
          Number(endOfTenurePrematureLiquidationRate) > 100) && (
          <p className="text-danger">
            End Of Tenor Premature Liquidation rate must be between 0 and 100
          </p>
        )}
      </div>
      {selectedCoreBankingTenant && (
        <div className="col-md-12">
          {isMifosProductLoding ? (
            <Loader />
          ) : (
            <CustomSelectDropdown
              // disabled={editType === "active"}
              onChange={(e) => {
                handleSearchBy(e);
              }}
              name="coreBankingProductId"
              defaultValue={selectedProductDetails?.coreBankingProductId}
              label="Mifos Product"
              className="round user__form form-control mb-3"
            >
              <option value=""> Select a mifos product</option>
              {selectedProductDetails?.currencyCode &&
                mifosProduct &&
                mifosProduct
                  ?.filter(
                    (m) =>
                      m.currency_code === selectedProductDetails?.currencyCode,
                  )
                  .map((data) => {
                    const { id, name, currency_code } = data;
                    return (
                      <option value={id} key={id}>
                        {currency_code} - {name}
                      </option>
                    );
                  })}
            </CustomSelectDropdown>
          )}
        </div>
      )}

      <div className="col-md-6">
        <label>
          Minimum Investment{" "}
          {formatMoney(
            selectedProductDetails?.minimumInvestment,
            getAppropriateCurrencyIcon(selectedProductDetails?.currencyCode)
              ?.symbol,
          )}
        </label>
        <div className={styles.inputIcons}>
          <div className={`${styles.icon}`}>
            {
              getAppropriateCurrencyIcon(selectedProductDetails?.currencyCode)
                ?.icon
            }
          </div>
          <input
            type="number"
            name="minimumInvestment"
            value={selectedProductDetails?.minimumInvestment}
            disabled={editType !== "active"}
            onChange={(e) => handleChange(e, selectedProductDetails?.id)}
            className={`${styles.inputField}`}
          />
        </div>
      </div>

      <div className="col-md-6">
        <label>
          Maximum Investment{" "}
          {formatMoney(
            selectedProductDetails?.maximumInvestment,
            getAppropriateCurrencyIcon(selectedProductDetails?.currencyCode)
              ?.symbol,
          )}
        </label>
        <div className={styles.inputIcons}>
          <div className={`${styles.icon}`}>
            {
              getAppropriateCurrencyIcon(selectedProductDetails?.currencyCode)
                ?.icon
            }
          </div>
          <input
            type="number"
            name="maximumInvestment"
            value={selectedProductDetails?.maximumInvestment}
            disabled={editType !== "active"}
            onChange={(e) => handleChange(e, selectedProductDetails?.id)}
            className={`${styles.inputField}`}
          />
        </div>
      </div>



      {editType === "active" && (
        <div className="col-md-12 my-2">
          <label className="label-txt">{`Lock ${selectedProductDetails?.currencyCode} Investment fund`}</label>

          <div className=" input-group">
            <label className="mr-3">
              <input
                type="radio"
                name={`isLocked${selectedProductDetails?.id}`}
                value={"true"}
                checked={JSON.parse(selectedProductDetails?.isLocked!) === true}
                onChange={(e) => handleChange(e, selectedProductDetails?.id)}
                className="mr-1"
              />
              <i className="primary" />
              True
            </label>

            <label className="mr-3">
              <input
                type="radio"
                name={`isLocked${selectedProductDetails?.id}`}
                value={"false"}
                checked={
                  JSON.parse(selectedProductDetails?.isLocked!) === false
                }
                onChange={(e) => handleChange(e, selectedProductDetails?.id)}
                className="mr-1"
              />
              <i className="primary" />
              False
            </label>
          </div>
        </div>
      )}
      <div className="col-md-12 mb-3 mt-3">
        <div className="rounded-border d-flex ">
          <span>Turn on automatic investment cap:</span>
          <div
            onClick={(e) => {
              checkInvestmentCap(e);
            }}
          >
            <i
              className={`investment-adv-icon fas fa-toggle-${checkStatus} fa-lg ml-2`}
            />
          </div>
        </div>
      </div>
      {/* {checkStatus === "on" && (
        <div className="mb-3">
          <label className="label-txt">
            Investment Size{" "}
            {formatMoney(
              selectedProductDetails?.investment_size,
              getAppropriateCurrencyIcon(selectedProductDetails?.currencyCode)
                ?.symbol
            )}
          </label>
          <input
            type="number"
            className="form-control"
            value={selectedProductDetails?.investment_size}
            name="investment_size"
            onChange={(e) => handleChange(e, selectedProductDetails?.id)}
          />
        </div>
      )} */}
    </>
  );
}
