import React from "react";
import EligibilityQuestionSegment from "../EligibilityQuestionSegment.component";

export default function EligibilityQuestionsSectionTwo({
  questions,
  editMode,
  section,
  setSection,
  setQuestions,
  addNewQuestion,
  onSubmit,
  submittingNewQuestion,
}) {
  return (
    <div>
      {questions &&
      questions.length &&
      questions.filter(
        (eachQuestion) => eachQuestion.questionCategoryId === section
      ).length ? (
        questions
          .filter((eachQuestion) => eachQuestion.questionCategoryId === section)
          // .sort((a, b) => a.position - b.position)
          .map((eachQuestion, _id) => {
            return (
              <EligibilityQuestionSegment
                eachQuestion={eachQuestion}
                editMode={editMode}
                key={_id}
                questions={questions}
                setQuestions={setQuestions}
                onSubmit={onSubmit}
                submittingNewQuestion={submittingNewQuestion}
              />
            );
          })
      ) : (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are no eligibility questions
            </h4>
          </div>
        </div>
      )}

      {editMode ? (
        <div className="w-100 d-flex justify-content-end flex-wrap mt-3">
          <div className="w-50 ">
            <button
              className="btn advancly-white-btn btn-md w-100 mr-2"
              type="button"
              onClick={addNewQuestion}
            >
              Add Another Question
            </button>
          </div>
        </div>
      ) : (
        <div className="w-100 d-flex align-items-center justify-content-between flex-wrap mt-3">
          <div className="step">Section {section} of 2</div>
          <div>
            <button
              className="btn advancly-white-btn btn-md"
              type="button"
              onClick={() => setSection(1)}
            >
              Previous Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
