import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TQuery = {
  FunderId: number;
  page: number;
  size: number;
  savingsWalletId: number;
};

const getInvestorsInvestmentWallet = async ({
  FunderId,
  page,
  size,
  savingsWalletId,
}: TQuery) => {
  let queryParams = `?page=${page}&pageSize=${size}`;
  if (FunderId) {
    queryParams = `${queryParams}&funderId=${FunderId}`;
  }
  if (savingsWalletId) {
    queryParams = `${queryParams}&savingsWalletId=${savingsWalletId}`;
  }
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.GET_FUNDER_WALLET}${queryParams}`
  );
  return data;
};

export default function useInvestorsInvestmentWallet({
  FunderId,
  page,
  size,
  savingsWalletId,
}: TQuery) {
  return useQuery(
    [
      {
        FunderId,
        page,
        size,
        savingsWalletId,
      },
      "getInvestorsInvestmentWallet",
    ],
    getInvestorsInvestmentWallet,
    {
      refetchOnWindowFocus: false,
    }
  );
}
