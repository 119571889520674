import { FC, Fragment, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import useDashboardDisbursementGraph from "../../../../../custom-hooks/useDisbursementGraph";
import useRecentDisbursements from "../../../../../custom-hooks/useRecentDisbursements";
import { formatGraphData } from "../../../../../helpers/formatGraphData";
import graphDropdownOptions from "../../../../../helpers/graphDropdownOptions";
import { GRAPH_SCALE } from "../../../../../helpers/graphOptions";
import GraphSubTitle from "../GraphComponents/GraphSubTitleSection/GraphSubTitle.component";
import GraphTitleSection from "../GraphComponents/GraphTitleSection/GraphTitleSection.component";
import { formatMoney } from "../../../../../helpers/formatter";
import BarChartSingle from "../Charts/BarChartSingle/BarChartSingle.component";
import EmptyBarChartSingle from "../Charts/BarChartSingle/EmptyBarChartSingle.component";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";

interface IDisbursementsGraph {
  aggregatorId: number;
  currentCurrency: string;
}

const DisbursementsGraph: FC<IDisbursementsGraph> = ({
  aggregatorId,
  currentCurrency,
}) => {
  const [filter, setFilter] = useState(graphDropdownOptions.thisYear);
  const { data, status, error } = useDashboardDisbursementGraph({
    aggregatorId,
    filter,
    currentCurrencyValue: currentCurrency,
  });
  const {
    data: recentDisbursementData,
    status: recentDisbursementStatus,
    error: recentDisbursementError,
  } = useRecentDisbursements({
    currentCurrencyValue: currentCurrency,
    aggregatorId: aggregatorId.toString(),
  });
  const match = useRouteMatch();

  //JSX Template for graph
  let RenderedComponent = null;

  if (status === "loading") {
    RenderedComponent = (
      <div className="d-flex justify-content-center align-items-center m-h-100">
        <ButtonLoader />
      </div>
    );
  } else if (error) {
    RenderedComponent = (
      <h5 className="text-danger text-center font-weight-bold">
        An error occurred
      </h5>
    );
  } else if (data) {
    if (!data.length) {
      RenderedComponent = <EmptyBarChartSingle />;
    } else {
      RenderedComponent = (
        <BarChartSingle
          isDisbursement={true}
          graphData={formatGraphData(data)}
        />
      );
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-body p-3">
          <GraphTitleSection
            filter={filter}
            setFilter={setFilter}
            title="Disbursements"
          >
            <p className="text-dark">
              in {GRAPH_SCALE.toLocaleString("en-NG")}
            </p>
          </GraphTitleSection>
          <Fragment>{RenderedComponent}</Fragment>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body p-3">
          <GraphSubTitle
            text="Recent Disbursements"
            to={match.path + "disbursements"}
          />

          <Fragment>
            {recentDisbursementStatus === "loading" ? (
              <div className="d-flex justify-content-center align-items-center m-h-100">
                <ButtonLoader />
              </div>
            ) : recentDisbursementError ? (
              <h5 className="text-danger text-center font-weight-bold">
                An error occurred
              </h5>
            ) : recentDisbursementData && recentDisbursementData.length ? (
              <ul className="dashboard-graph-list">
                {recentDisbursementData
                  .slice(0, 3)
                  .map((data: any, _i: number) => {
                    const { amount, borrowerName, currency } = data;
                    return (
                      <li key={_i}>
                        <span className="dashbord-graph-list-name">
                          {borrowerName}
                        </span>{" "}
                        <span className="dashboard-graph-list-amount">
                          {formatMoney(amount, currency)}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div>No Data Available</div>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default DisbursementsGraph;
