import React, { useEffect } from "react";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { formatMoney } from "../../../helpers/formatter";
import { appInsights } from "../../../config/appInsights";

const InvestmentDetails = ({
  principal_amount,
  currency,
  tenure,
  monthly_interest_repayment,
  effective_date,
  maturity_date,
  investment_product_name,
  interest_repayment_type,
  pub_date,
  outstanding_interest_amount,
  interest_amount,
  maturity_amount,
  outstanding_settlement,
  total_interest_paid,
  maturityInstruction,
  parent_investment_reference,
  child_investment_reference,
  rollOverType,
  is_roll_over,
  interest_rate,
}) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Investment Details  - (InvestmentDetails.jsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      <h6 className="my-4 page-subtitle">Investment Details</h6>
      <form>
        <div className="row">
          <div className="col-md-6">
            <CustomInputField
              label="Investment Fund"
              defaultValue={investment_product_name}
              readOnly={true}
            ></CustomInputField>
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Investment Tenor"
              defaultValue={tenure}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Interest Rate"
              defaultValue={`${interest_rate}%`}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Repayment Type"
              defaultValue={interest_repayment_type}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Is Roll Over?"
              defaultValue={is_roll_over}
              readOnly={true}
            />
          </div>
          {is_roll_over && (
            <div className="col-md-6">
              <CustomInputField
                label="Roll Over Type"
                defaultValue={rollOverType}
                readOnly={true}
              />
            </div>
          )}

          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Date Invested"
              defaultValue={effective_date.substring(0, 10)}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Maturity Date"
              defaultValue={maturity_date.substring(0, 10)}
              readOnly={true}
            />
          </div>

          <div className="col-md-6">
            <CustomInputField
              label="Created Date"
              defaultValue={pub_date}
              readOnly={true}
            />
          </div>
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Amount Invested"
              defaultValue={formatMoney(principal_amount, currency)}
              readOnly={true}
            ></CustomInputField>
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Total Repayment At Maturity"
              defaultValue={formatMoney(maturity_amount, currency)}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Monthly Yield"
              defaultValue={formatMoney(monthly_interest_repayment, currency)}
              readOnly={true}
            ></CustomInputField>
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Outstanding Interest Amount"
              defaultValue={formatMoney(outstanding_interest_amount, currency)}
              readOnly={true}
            ></CustomInputField>
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Total Interest At Maturity"
              defaultValue={formatMoney(interest_amount, currency)}
              readOnly={true}
            ></CustomInputField>
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Total Paid"
              defaultValue={total_interest_paid}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Outstanding Amount"
              defaultValue={formatMoney(outstanding_settlement, currency)}
              readOnly={true}
            />
          </div>
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Maturity Instruction"
              defaultValue={maturityInstruction}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Parent Investment Reference"
              defaultValue={parent_investment_reference}
              readOnly={true}
            />
          </div>
          <div className="col-md-6">
            <CustomInputField
              label="Child Investment Reference"
              defaultValue={child_investment_reference}
              readOnly={true}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvestmentDetails;
