import { useState } from "react";
import "../../Issues/IssuesModal.css";
// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import useEachLoansBatched from "../../../../custom-hooks/useEachLoansBatched";
import EachBatchedLoanDetails, {
  AccountDetails,
  LoanGatewayDetails,
} from "./EachBatchedLoanDetails";

export default function BatchedDetails({ properties, onCloseModal }: any) {
  const [state, setState] = useState({
    scene: "form",
    formScene: "account_details",
    maker_checker_reference: "",
    tabs: [
      {
        name: "Account Details",
        id: "account_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Gateway Details",
        id: "gateway_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Loan Details",
        id: "loan_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
  });

  const { data } = useEachLoansBatched(
    properties?.request?.batchLoanDisbursementKey
  );

  // Toggle Tab & Tab content for Data Table
  const toggleTabContent = (content: string) => {
    setState({ ...state, formScene: content });
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3 className="Modal__title p-b-2">
            Aggregator:
            <span>{properties?.request?.aggregatorName}</span>
          </h3>
          {(() => {
            switch (state.scene) {
              case "form":
                return (
                  <form>
                    <div className="row m-b-1">
                      <Tabs
                        onContentToggle={toggleTabContent}
                        tabs={state.tabs}
                      />
                    </div>
                    {(() => {
                      switch (state.formScene) {
                        case "account_details":
                          return (
                            <AccountDetails details={properties.request} />
                          );

                        case "gateway_details":
                          return (
                            <LoanGatewayDetails details={properties.request} />
                          );
                        case "loan_details":
                          return (
                            <EachBatchedLoanDetails
                              details={
                                properties?.request?.batchLoanDisbursementKey
                                  ? data
                                  : properties?.request?.loanDetails
                              }
                            />
                          );

                        default:
                          return null;
                      }
                    })()}
                  </form>
                );

              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing your Details
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}

          <div className="Form__action-buttons">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={onCloseModal}
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
