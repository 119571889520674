import React from "react";

import "./Inputs.css";

export const LabelledInput = (props: any) => {
  let { inputValue, label, icon } = props;

  // let InputElement = null;

  // switch(inputType){
  //     case('textarea'):
  //         InputElement = <textarea { ...props } />
  //         break;
  //     case('select'):
  //         InputElement = <select>
  //             props.options.map
  //         </select>
  //         break;
  //     default:
  //         InputElement = <input pr/>
  //         break;
  // }

  return (
    <React.Fragment>
      <label className="label-txt">{label}</label>
      <div className="form-group inputWithIcon">
        <input
          className="user__form form-control"
          readOnly={true}
          value={inputValue}
        />
        <i className={icon} aria-hidden="true" />
      </div>
    </React.Fragment>
  );
};
