import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  FromDate: string;
  ToDate: string;
  Page: number;
  PageSize: number;
  AggregatorId: number;
};

type TResponse = {
  data: {
    data: {
      aggregatorId: number;
      aggregatorName: string;
      amountToRepay: number;
      borrowerName: string;
      borrower_id: number;
      currency: string;
      customerName: string;
      dateDue: string;
      dateDueFormatted: string;
      dateMade: string;
      daysOverdue: number;
      fee: string;
      feesPaid: string;
      installment: number;
      interest: number;
      interestPaid: string;
      isPaid: boolean;
      loanBalance: number;
      loanId: number;
      loanRef: string;
      outstandingAmount: number;
      penalty: string;
      penaltyPaid: string | null;
      principal: string | null;
      principalPaid: string | null;
      productName: string | null;
    }[];
    totalCount: number;
  };
};

const getLoanRepaymentReport = async ({
  FromDate,
  ToDate,
  Page,
  PageSize,
  AggregatorId,
}: TQuery) => {
  let queryParams = `page=${Page}&PageSize=${PageSize}`;
  if (FromDate) {
    queryParams = `${queryParams}&FromDate=${FromDate}`;
  }
  if (ToDate) {
    queryParams = `${queryParams}&ToDate=${ToDate}`;
  }
  if (AggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${AggregatorId}`;
  }
  const data = await getData(
    `${ajaxEndpoints.REPAYMENT_LOAN_REPORT}?${queryParams}`
  );
  return data;
};

export default function useLoanRepaymentReport({
  FromDate,
  ToDate,
  Page,
  PageSize,
  AggregatorId,
}: TQuery) {
  return useQuery<TResponse>(
    [
      { FromDate, ToDate, Page, PageSize, AggregatorId },
      "getLoanRepaymentReport",
    ],
    getLoanRepaymentReport
  );
}
