import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getPendingGlobalSettings = async () => {
  const data = await getDataWithDotNet(
    ajaxEndpoints.GLOBAL_REVENUE_SETTINGS_UPDATE
  );
  return data.data;
};

export default function usePendingGlobalSettings() {
  return useQuery(["getPendingGlobalSettings"], getPendingGlobalSettings);
}
