import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  pageNumber: number;
  size: number;
  productId?: string;
  aggregatorId?: string;
  startDate?: string;
  endDate?: string;
};

const getAllUploadedBatchLoans = async ({
  pageNumber,
  size,
  productId,
  aggregatorId,
  startDate,
  endDate,
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;
  if (productId) {
    queryParams = `${queryParams}&ProductId=${productId}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&FromDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&ToDate=${endDate}`;
  }
  const data = await getData(
    `${ajaxEndpoints.GET_UPLOADED_BATCH_LOAN_REPAYMENT}?${queryParams}`
  );
  return data;
};

export default function useAllUploadedBatchLoans({
  pageNumber,
  size,
  productId,
  aggregatorId,
  startDate,
  endDate,
}: TQuery) {
  return useQuery(
    [
      { pageNumber, size, productId, aggregatorId, startDate, endDate },
      "getAllUploadedBatchLoans ",
    ],
    getAllUploadedBatchLoans
  );
}
