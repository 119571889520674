import React, { useState } from "react";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader from "../../../../UI/Loaders/Loaders";

export default function CreateWalletProvider({ properties }) {
  const [state, setState] = useState({
    provider_type: properties?.dataRecord?.provider_type_id || "",

    currency: properties?.dataRecord?.currency || "",

    consumer_type: properties?.dataRecord?.consumer_type_id || "",

    bank_code: properties?.dataRecord?.bank_code || "",
    id: properties?.dataRecord?.id || "",
    loading: false,
  });

  const handleCreate = (e) => {
    e.preventDefault();
    properties?.onSubmitCreateDFG(state);
  };
  const handleEdit = (e) => {
    e.preventDefault();
    properties?.onSubmitEditDFG(state);
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3 className="Modal__title m-b-2 p-x-2 p-y-1 bg-primary">
            {properties.type === "create" ? (
              <span>Create</span>
            ) : (
              <span>Update</span>
            )}{" "}
            Default wallet Provider
          </h3>

          <form>
            <div className="row">
              <div className="col-xs-6 mb-4">
                <CustomSelectDropdown
                  label="Provider Type"
                  name="provider_type"
                  value={state?.provider_type}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                >
                  <option value=""></option>
                  <option value="1">BishopGate</option>
                  <option value="2">Dojah</option>
                </CustomSelectDropdown>
              </div>
              <div className="col-xs-6 mb-4">
                <CustomSelectDropdown
                  label="Currency"
                  name="currency"
                  value={state?.currency}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                  disabled={!state.provider_type}
                >
                  <option value=""></option>

                  <option value="NGN">Naira</option>

                  {Number(state?.provider_type) === 1 && (
                    <option value="USD">Dollar</option>
                  )}
                </CustomSelectDropdown>
              </div>
              <div className="col-xs-6 mb-4">
                <CustomSelectDropdown
                  label="Bank Code"
                  name="bank_code"
                  value={state?.bank_code}
                  disabled={!state.provider_type}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                >
                  <option value=""></option>
                  {Number(state?.provider_type) === 1 && (
                    <option value="BISHOPGATE">BISHOPGATE</option>
                  )}
                  {Number(state?.provider_type) === 2 && (
                    <option value="VFD">VFD</option>
                  )}
                </CustomSelectDropdown>
              </div>
              <div className="col-xs-6 mb-4">
                <CustomSelectDropdown
                  label="Consumer Type"
                  name="consumer_type"
                  value={state?.consumer_type}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                >
                  <option value=""></option>
                  <option value="1">Aggregator</option>
                  <option value="2">Funder</option>
                </CustomSelectDropdown>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4 mb-4"></div>
              <div className="col-xs-4 mb-4 ml-5 pl-5">
                {properties.type === "update" && (
                  <div className="grid__full m-t-2">
                    <button
                      type="submit"
                      className="zoom-element btn login-btn"
                      onClick={(e) => handleEdit(e)}
                    >
                      &nbsp; Update {state.loading && <ButtonLoader />}
                    </button>
                  </div>
                )}
                {properties.type === "create" && (
                  <div className="grid__full m-t-2">
                    <button
                      type="submit"
                      className="zoom-element btn login-btn"
                      onClick={(e) => handleCreate(e)}
                    >
                      &nbsp; Create {state.loading && <ButtonLoader />}
                    </button>
                  </div>
                )}
              </div>

              <div className="col-xs-4 mb-4"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
