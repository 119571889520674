import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TResponse = {
  data: {
    bank_code: string;
    consumer_type: string;
    consumer_type_id: number;
    currency: string;
    id: number;
    provider_type: string;
    provider_type_id: number;
  }[];
};

const getDefaultWalletProvider = async () => {
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.DEFAULT_WALLET_PROVIDER}`
  );

  return data;
};

export default function useDefaultWalletProvider() {
  return useQuery<TResponse>(
    "getDefaultWalletProvider",
    getDefaultWalletProvider
  );
}
