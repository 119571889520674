import {
  useState,
  Fragment,
  useRef,
  FormEvent,
  ChangeEvent,
  useEffect,
} from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ROLE_TYPES } from "../../../../helpers/roleTypes";
import useAnInvestmentProduct from "../../../../custom-hooks/useAnInvestmentProduct";
import useInvestmentInterest from "../../../../custom-hooks/useInvestmentInterest";
import useEachInvestments from "../../../../custom-hooks/useEachInvestments";
// Redux
import { useSelector } from "react-redux";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import CreateInvestmentModal from "../../CreateInvestmentProducts/CreateInvestmentModal/CreateInvestmentModal";
import {
  postDataWithDotNet,
  putDataWithDotNet,
} from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../../../config/appInsights";
import CustomSearchDropDown from "../../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import InvestmentFundDetails from "./Tabs/InvestmentFundDetails";
import { RootState } from "../../../../redux/store";

type TState = {
  funder_id: number;
  cba_wallet_account_id: number;
  investment_product_id: number;
  cba_product_id: number;
  cba_product_interest_id: number;
  principal_amount: number;
  interest_rate: number;
  tenure: number;
  currency: string;
  interest_repayment_type: string;
  number_of_days_in_year: number;
  effective_date: string;
  transactionReceiptBase64: string;
  transactionReceiptName: string;
};

const ActiveInvestmentTab = () => {
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [saveError, setSaveError] = useState("");
  const [investRateId, setInvestRateId] = useState(0);
  const [tenantId, setTenantId] = useState("");
  const [selectedFundCurrency, setSelectedFundCurrency] = useState("");
  const [selectedDepositType, setSelectedDepositType] = useState("");

  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  const dismissModalRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const history = useHistory();
  const { id, from } = location?.state as { id: number; from: string };

  const {
    data: investmentData,
    status: investmentStatus,
    isError: isEachInvestmentError,
  } = useEachInvestments(id);

  const { data, status, error } = useAnInvestmentProduct(id);

  useEffect(() => {
    console.log({
      data,
    });
  }, [data]);

  const { data: investInterest } = useInvestmentInterest({
    id: investRateId,
    tenantId,
    // depositType:
    //   data?.investmentProductDetails[0]?.depositType === "RecurringDeposit"
    //     ? 4
    //     : 3,
  });

  const [state, setState] = useState<TState>({
    funder_id: 0,
    cba_wallet_account_id: 0,
    investment_product_id: data?.id || "",
    cba_product_id: 0,
    cba_product_interest_id: 0,
    principal_amount: 0,
    interest_rate: 0,
    tenure: 0,
    currency: "",
    interest_repayment_type: "",
    number_of_days_in_year: 0,
    effective_date: "",
    transactionReceiptBase64: "",
    transactionReceiptName: "",
    // depositType:
    //   data?.investmentProductDetails[0]?.depositType === "RecurringDeposit"
    //     ? 4
    //     : 3,
  });
  const [activateState, setActivateState] = useState({
    otp: "",
    comment: "",
  });

  const resetState = () => {
    setState({
      funder_id: 0,
      cba_wallet_account_id: 0,
      investment_product_id: data?.id || "",
      cba_product_id: 0,
      cba_product_interest_id: 0,
      principal_amount: 0,
      interest_rate: 0,
      tenure: 0,
      currency: "",
      interest_repayment_type: "",
      number_of_days_in_year: 0,
      effective_date: "",
      transactionReceiptBase64: "",
      transactionReceiptName: "",
    });
  };

  const handleDeactivation = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await putDataWithDotNet(
        `${ajaxEndpoints.FUNDER_INVESTMENT_PRODUCT}/${Number(id)}/deactivate`,
        activateState,
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      resetState();

      setTimeout(() => {
        history.push("/investments");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ActiveInvestmentTab.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveError(errorHandler(error));
      resetState();
    }
  };

  const logInvestment = async (reqBody: {
    funder_id: number;
    interest_calculation_days_in_year_type: number;
    investment_product_details_id: number;
    principal_amount: number;
    interest_rate: number;
    tenure: number;
    currency: string;
    interest_repayment_type: string;
    effective_date: string;
    base64_file_string: string;
    filename_with_extension: string;
    amount_to_credit: string;
  }) => {
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.LOG_INVESTMENT}`,
        reqBody,
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccessMessage(response.message);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "EditInvestmentProduct.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSaveError(errorHandler(error));
      setTimeout(() => {
        setSaveError("");
      }, 3000);
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setActivateState({ ...activateState, [name]: value });
  };

  const handleDepositType = (currency: string) => {
    const productDetails = data?.investment_product_details.find(
      (item: { currency_code: string }) => item?.currency_code === currency,
    );
    setSelectedDepositType(productDetails?.deposit_type);
  };

  const handleCurrency = (value: any) => {
    const productDetails = data?.investment_product_details.find(
      (item: any) => item?.currency_code === value,
    );
    setTenantId(productDetails?.core_banking_tenant_id);
    setInvestRateId(productDetails?.core_banking_product_id);
    setSelectedDepositType(productDetails?.deposit_type);
  };

  const handleChangedState = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleChangeFund = (item: { label: string; value: string }) => {
    setSelectedFundCurrency(item?.value);
    handleDepositType(item?.value);
  };

  const getDefaultOptions = (arrayInQuestion: string[]) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      let result = arrayInQuestion.map((item) => {
        return {
          label: item,
          value: item,
        };
      });

      return result;
    }

    return [];
  };

  useEffect(() => {
    console.log({
      selectedFundCurrency,
    });
    if (selectedFundCurrency) {
      if (selectedFundCurrency === "USD") {
        setState({
          ...state,
          number_of_days_in_year: data?.usd_product?.number_of_days_in_year,
        });
      } else {
        setState({
          ...state,
          number_of_days_in_year: data?.lcy_product?.number_of_days_in_year,
        });
      }
    }
  }, [selectedFundCurrency]);

  return (
    <Fragment>
      <div className="animated fadeInRight">
        <div className="d-flex flex-row flex-wrap mt-2 mb-1">
          {saveError && <Alert message={saveError} />}
          {successMessage && <Alert message={successMessage} type="success" />}
          <h3 className="page-title d-flex align-items-center">
            <BackButtonComponent />{" "}
            {status === "loading" ? (
              <ButtonLoader />
            ) : !error && data ? (
              data.name
            ) : (
              ""
            )}
          </h3>
          <div className="flex-row mt-2 mb-1">
            {loggedInUser?.roles.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
            ) && from === "active" ? (
              <button
                type="button"
                className="btn advancly-dimred-btn btn-md m-0"
                disabled={loading}
                data-toggle="modal"
                data-target="#createInvestmentModal"
                onClick={() => setModalActionType("deactivate")}
              >
                Deactivate Fund
              </button>
            ) : (
              ""
            )}
            {loggedInUser?.roles.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
            ) || from === "inactive" ? (
              ""
            ) : (
              <button
                type="button"
                className="btn advancly-btn btn-md mr-3"
                disabled={!data}
                onClick={() => {
                  localStorage.setItem(
                    "details",
                    JSON.stringify(data?.investment_product_details),
                  );
                  history.push({
                    pathname: `/investments/${id}/edit`,
                    state: {
                      id,
                      editType: "active",
                    },
                  });
                }}
              >
                Edit
              </button>
            )}
            {loggedInUser?.roles.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER,
            ) || from === "inactive" ? (
              ""
            ) : (
              <button
                type="button"
                className="btn advancly-btn btn-md m-0"
                disabled={loading}
                data-toggle="modal"
                data-target="#createInvestmentModal"
                onClick={() => {
                  setModalActionType("step1");
                }}
              >
                Log Investment
              </button>
            )}
          </div>
        </div>

        <div className="w-100">
          <div className="col-4 mb-3">
            <CustomSearchDropDown
              placeholder="Select Currency"
              onChange={handleChangeFund}
              defaultOptions={getDefaultOptions(
                investmentData?.data.currencies!,
              )}
              name="fund_currencies"
              isShowLabel={false}
              isMulti={false}
              isSearchable={false}
            />
          </div>
        </div>

        <hr />

        {isEachInvestmentError ? (
          <div className="centerLoader">
            <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" /> Unable to fetch Investment fund
                details
              </h4>
            </div>
          </div>
        ) : (
          <>
            {!selectedFundCurrency && (
              <div className="centerLoader">
                <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
                  <h4 className="center-txt p-y-5">
                    <i className="fas fa-bell" /> No currency has been selected.
                    Please select currency
                  </h4>
                </div>
              </div>
            )}

            {selectedFundCurrency && investmentData && (
              <InvestmentFundDetails
                investmentData={investmentData?.data}
                selectedFundCurrency={selectedFundCurrency}
                deposit_type={selectedDepositType}
              />
            )}
          </>
        )}
      </div>
      <CreateInvestmentModal
        onSubmit={handleDeactivation}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
        handleChange={handleChange}
        state={state}
        setState={setState}
        logInvestment={logInvestment}
        data={data}
        setModalActionType={setModalActionType}
        handleChangedState={handleChangedState}
        setLoading={setLoading}
        resetState={resetState}
        handleCurrency={handleCurrency}
        investRateId={investRateId}
        tenantId={tenantId}
      />
    </Fragment>
  );
};

export default ActiveInvestmentTab;
