import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

export type TGender = {
  description: "Male";
  id: 1;
  name: "Male";
};

type TResponse = {
  data: TGender[];
  message: string;
  status: boolean;
  status_code: number;
};

const getAllGender = async (code: string) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_ALL_GENDER}?countryCode=${code}`
  );
  return data;
};

export default function useAllGender(code: string) {
  return useQuery<TResponse>([code, "getAllGender"], getAllGender, {
    enabled: code ? true : false,
    refetchOnWindowFocus: false,
  });
}
