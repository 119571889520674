import React, { useState } from "react";
import styles from "../InvestorsInvestment.module.scss";
import {
  InvestorWalletData,
  IWallet,
} from "../../../../interfaces/funderInvestment";
import InvestorWalletTable from "./InvestorWalletTable";
import InvestorWalletDetails from "./InvestorWalletDetails";
import CreateInvestorWallet from "./CreateInvestorWallet";
import InvestorWalletWithdrawal from "./InvestorWalletWithdrawal";
import InvestorWalletTopUp from "./WalletTopUp";

export interface IProps extends InvestorWalletData {
  wallets: IWallet[] | undefined;
  funderId: number;
  handleFetchWalletList: () => void;
}

const InvestorWalletView = ({
  wallets,
  foreignWalletLink,
  localWalletLink,
  data,
  handleFetchWalletList,
}: IProps) => {
  const [showWalletDetails, setShowWalletDetails] = useState(false);
  const [showWalletWithdrawal, setShowWalletWithdrawal] = useState(false);
  const [showWalletTopUp, setShowWalletTopUp] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [availableBalance, setAvailableBalance] = useState(0);
  const [showCreateWallet, setShowCreateWallet] = useState(false);

  const handleSetSelectedWalletDetails = (
    walletId: string,
    currency: string,
    availableBalance: number
  ) => {
    console.log({
      walletId,
      currency,
      availableBalance,
    });
    setSelectedWalletId(walletId);
    setSelectedCurrency(currency);
    setAvailableBalance(availableBalance || 0);
  };

  function handleShowWalletDetails() {
    setShowWalletDetails(true);
  }

  return (
    <div className={styles.datatableWrapper}>
      <div className="speed-1x animated fadeInRight">
        <br />
        {!showCreateWallet && (
          <button
            className="btn btn-primary mb-5 mt-2"
            onClick={() => setShowCreateWallet(true)}
          >
            Create Wallet
          </button>
        )}
        {wallets?.length === 0 ? (
          <div>
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-bell" /> No Wallets found
              </h4>
            </div>
          </div>
        ) : showCreateWallet ? (
          <CreateInvestorWallet
            funderId={data?.funder_details?.funder_id}
            goBack={() => {
              setShowCreateWallet(false);
              setShowWalletDetails(false);
              handleFetchWalletList();
            }}
          />
        ) : showWalletDetails ? (
          <>
            <InvestorWalletDetails
              funderId={data?.funder_details?.funder_id}
              currency={selectedCurrency}
              investmentProductId={
                data?.funder_investment_products[0]?.investment_product_id
              }
              goBack={() => setShowWalletDetails(false)}
            />
          </>
        ) : showWalletWithdrawal ? (
          <>
            <InvestorWalletWithdrawal
              funderId={data?.funder_details?.funder_id}
              wallet_id={Number(selectedWalletId)}
              currency={selectedCurrency}
              availableBalance={availableBalance}
              goBack={() => setShowWalletWithdrawal(false)}
            />
          </>
        ) : showWalletTopUp ? (
          <InvestorWalletTopUp
            funderId={data?.funder_details?.funder_id}
            wallet_id={String(selectedWalletId)}
            currency={selectedCurrency}
            availableBalance={availableBalance}
            goBack={() => setShowWalletTopUp(false)}
          />
        ) : (
          <InvestorWalletTable
            wallets={wallets}
            funderId={data?.funder_details?.funder_id}
            showWalletDetails={handleShowWalletDetails}
            handleSetSelectedWalletDetails={(
              walletId: string,
              currency: string,
              availableBalance: number
            ) =>
              handleSetSelectedWalletDetails(
                walletId,
                currency,
                availableBalance
              )
            }
            localWalletLink={localWalletLink}
            foreignWalletLink={foreignWalletLink}
            setShowWalletWithdrawal={(show: boolean) =>
              setShowWalletWithdrawal(show)
            }
            setShowWalletTopUp={(show: boolean) => setShowWalletTopUp(show)}
          />
        )}
      </div>
    </div>
  );
};

export default InvestorWalletView;
