import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getCountryById = async (id: string) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_COUNTRY_BY_ID}/${Number(id)}`
  );
  return data;
};

export default function useCountryById(id: string) {
  return useQuery([id, "getCountryById"], getCountryById, {
    refetchOnWindowFocus: false,
  });
}
