import { useEffect } from "react";

import { POWERBI_DASHBOARD_REPORT_TOKEN } from "../../appConstants";
import { appInsights } from "../../config/appInsights";

const PowerBiDashboard = () => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "Business Performance Dashboard - (PowerBiDashboard.js)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="speed-1x fadeInRight">
      <iframe
        style={{ width: "100%", height: "100vh" }}
        title="Admin Business Performance Dashboard Report"
        allow="fullscreen"
        src={`https://app.powerbi.com/view?r=${POWERBI_DASHBOARD_REPORT_TOKEN}`}
      ></iframe>
    </div>
  );
};

export default PowerBiDashboard;
