import { useState } from 'react'
import Styles from "./BankStatement.module.scss"
import BankStatementFormatDetailsModal from './BankStatementFormatDetailsModal/BankStatementFormatDetailsModal'
import { BankStatementFormat } from '../../helpers/bankStatementFormat'
import { Loader } from '../../UI/Loaders/Loaders'
import { Toaster } from "react-hot-toast"
import useFetchBankStatementFormats from '../../custom-hooks/useFetchBankStatementFormats'
import search from "../../assets/icons/fontawesome/svgs/solid/search.svg"

interface BankStatementState {
  openState: {
    [id: number]: boolean;
  },
  selectedBankStatement?: BankStatementFormat
}

function BankStatement() {
  const [values, setValues] = useState<BankStatementState>({
    openState: {},
    }
  )

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [isLoadingCreate, setIsLoadingCreate] = useState(false)
  const [create, setCreate] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const [searchedResult, setSearchedResult] = useState<BankStatementFormat[] | null>([])


  const filterPrompts = (searchQuery: string) => {
    const regex = new RegExp(searchQuery, 'i')
    const bankName = bankStatementFormats?.filter((formatName: BankStatementFormat) => regex.test(formatName.bank_name))

    return bankName
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout as NodeJS.Timeout)
    setSearchQuery(e.target.value)

    setSearchTimeout(
      setTimeout(() => {
        const searchResult = filterPrompts(e.target.value)
        setSearchedResult(searchResult)
      }, 300)
    );
  }

  const handleOpen = (id: number) => {
    const selectedBankStatement = bankStatementFormats?.find((format: BankStatementFormat) => format.id === id);

    if(id === 0) {
      setCreate(true)
    }

    //@ts-ignore
    setValues(prevState => ({
      openState: {
        ...prevState.openState,
        [id]: !prevState.openState[id]
      },
      selectedBankStatement: selectedBankStatement || {
        id: 0,
        bank_name: "",
        country_code: "",
        statement_identifier_area: {
            top: 0,
            height: 0,
            left: 0,
            width: 0
        },
        statement_identifier: "",
        extraction_settings: {
            camelot_extraction_settings: {
                common_extraction_settings: {
                    extraction_flavor: "stream",
                    split_text: null,
                    row_tol: 0,
                    strip_text: "\n"
                },
                specific_extraction_settings: {
                    table_tops: {
                        rest: [
                            {
                                anchor_text: "",
                                translate_x: 0,
                                translate_y: 0
                            }
                        ]
                    },
                    table_bottoms: {
                        rest: [
                            {
                                anchor_text: "",
                                translate_x: 0,
                                translate_y: 0
                            }
                        ]
                    },
                    column_separators: {
                        rest: ""
                    }
                }
            },
            other_extraction_settings: {
                common_extraction_settings: {
                    column_mappings: {
                        trans_date: 0,
                        reference: 1,
                        value_date: 2,
                        debit: 3,
                        credit: 4,
                        balance: 5,
                        remark: 6
                    },
                    headers: {
                        total_debit: {
                            translate_x: 0,
                            translate_y: 0,
                            height: 0,
                            width: 0,
                            page_nos: [
                                0
                            ],
                            anchor_text: "",
                            regex: null
                        },
                        total_credit: {
                            translate_x: 0,
                            translate_y: 0,
                            height: 0,
                            width: 0,
                            page_nos: [
                                0
                            ],
                            anchor_text: "",
                            regex: null
                        },
                        opening_balance: {
                            translate_x: 0,
                            translate_y: 0,
                            height: 0,
                            width: 0,
                            page_nos: [
                                0
                            ],
                            anchor_text: "",
                            regex: null
                        },
                        closing_balance: {
                            translate_x: 0,
                            translate_y: 0,
                            height: 0,
                            width: 0,
                            page_nos: [
                                0
                            ],
                            anchor_text: "",
                            regex: null
                        },
                        start_date: {
                          translate_x: 0,
                          translate_y: 0,
                          height: 0,
                          width: 0,
                          page_nos: [
                              0
                          ],
                          anchor_text: "",
                          regex: null
                        },
                        end_date: {
                          translate_x: 0,
                          translate_y: 0,
                          height: 0,
                          width: 0,
                          page_nos: [
                              0
                          ],
                          anchor_text: "",
                          regex: null
                        }
                    },
                    reverse_transactions: false,
                    date_formats: {
                        trans_date: "",
                        value_date: "",
                        start_date: "",
                        end_date: ""
                    }
                },
                specific_extraction_settings: {
                    row_ranges_to_skip: {
                        rest: []
                    }
                }
            }
        }
      }
    }));
  }

  const handleClose = (id: number) => {
    handleOpen(id)
    setCreate(false)
  }

  const {
    data: bankStatementFormats,
    isLoading: isBankStatementFormatLoading,
    refetch: refetchBankStatementFormat
  } = useFetchBankStatementFormats()

  return (
    <div className={Styles.bankStatement_container}>
    <header>
        <h4>Available Bank Statement Formats</h4>
        <div className={Styles.search}>
          <div className={Styles.searchBox}>
            <input type='search' placeholder='Search format...' value={searchQuery} onChange={handleSearchChange} />
            <img src={search} alt='' />
          </div>
        </div>
    </header>
      {isBankStatementFormatLoading ? (<Loader text="Please wait..." /> 
      ):(
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className={`table-responsive ${Styles.TableResponsive} `}>
            <table className={`table ${Styles.Table}`}>
              <thead className="bg-lighter-grey">
                <tr>
                  <th>ID</th>
                  <th>Bank Name</th>
                  <th>Country Code</th>
                  <th>Statement Identifier</th>
                </tr>
              </thead>
              <tbody>
                {
                bankStatementFormats?.length > 0 ?
                searchQuery ? (searchedResult?.map((format: BankStatementFormat) => (
                      <tr key={format.id} onClick={() => handleOpen(format.id)}>
                        <td className={Styles.TableCell}>{format.id}</td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {format.bank_name}
                        </td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {format.country_code}
                        </td>
                        <td className={Styles.TableCell}>
                          {format.statement_identifier}
                        </td>
                      </tr>
                   )) 
                  ):( 
                    bankStatementFormats?.map((format: BankStatementFormat) => (
                      <tr key={format.id} onClick={() => handleOpen(format.id)}>
                        <td className={Styles.TableCell}>{format.id}</td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {format.bank_name}
                        </td>
                        <td
                          className={Styles.TableCell}
                          style={{ fontWeight: 600 }}
                        >
                          {format.country_code}
                        </td>
                        <td className={Styles.TableCell}>
                          {format.statement_identifier}
                        </td>
                      </tr>
                  )) )
                  : 
                  <tr>
                    <td className={Styles.TableCell}
                        style={{ fontWeight: 600 }}>No available data</td>
                  </tr>
                  }
              </tbody>
            </table>
          </div>
        </div>
        )}

        {!isBankStatementFormatLoading && <div style={{textAlign: "center"}}>
          <button className={Styles.btn} onClick={() => handleOpen(0)}>Add New Format</button>
        </div>}

       {values.selectedBankStatement && values.openState[values.selectedBankStatement.id] &&<BankStatementFormatDetailsModal
       bankStatement={values.selectedBankStatement}
       onClose={() => handleClose(values.selectedBankStatement!.id)}
       isLoadingUpdate={isLoadingUpdate}
       setIsLoadingUpdate={setIsLoadingUpdate}
       isLoadingCreate={isLoadingCreate}
       setIsLoadingCreate={setIsLoadingCreate}
       create={create}
       refetchBankStatementFormat={refetchBankStatementFormat}
       />}
       <Toaster position='top-center' reverseOrder={false} />
    </div>
  )
}

export default BankStatement