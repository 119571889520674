import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IPaginatedResponse, IResponse } from "../interfaces/response";

export type TFundingWallet = {
  walletId: string;
  aggregatorId: number;
  aggregatorName: string;
  accountNumber: string;
  accountName: string;
  bankName: string;
  rcNumber: string;
  currency: string;
  fundingPartner: string;
};

type TQuery = {
  searchQuery: string;
  page: number;
  pageSize: number;
};

const getFundingWallets = async ({ searchQuery, pageSize, page }: TQuery) => {
  let query = `?searchQuery=${searchQuery}&page=${page}&pageSize=${pageSize}`;

  const { data } = await getData(`${ajaxEndpoints.GET_FUNDING_WALLET}${query}`);

  return data.data;
};

export default function useFundingWallets({
  searchQuery,
  page,
  pageSize,
}: TQuery) {
  return useQuery<IPaginatedResponse<TFundingWallet[]>>(
    [{ searchQuery, page, pageSize }, "getFundingWallets"],
    getFundingWallets,
    {
      refetchOnWindowFocus: false,
    }
  );
}
