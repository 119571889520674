import {
  UPDATE_DOCUMENT_VIEWER_RECORD,
  CLEAR_DOCUMENT_VIEWER_RECORD,
} from "./../actions/actionTypes";

export const updateDocumentViewModalRecord = (data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_DOCUMENT_VIEWER_RECORD,
      payload: { documentUrl: data?.base64Url, type: data?.mimeType },
    });
  };
};

export const clearDocumentViewModalRecord = () => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_DOCUMENT_VIEWER_RECORD,
      payload: {
        documentUrl: "",
        type: "",
      },
    });
  };
};
