import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Loader } from "../../../UI/Loaders/Loaders";
import { showModal } from "../../../redux/actions/modal";
import useGlobalWaitlist from "../../../custom-hooks/useGlobalWaitlist";
import { WaitListDetail } from "./type";
import Paginator from "../../Pagination/Pagination.component";
import { appInsights } from "../../../config/appInsights";

import Styles from "./GlobalWaitlist.module.scss";

const GlobalWaitlist = () => {
  const [Page, setPage] = useState<string>("1");
  const [PageSize] = useState<string>("20");

  const dispatch = useDispatch();

  const {
    data: waitlist,
    status,
    error,
  } = useGlobalWaitlist({ Page, PageSize });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Global Waitlist - (GlobalWaitlist.component.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleViewDetails = (item: WaitListDetail) => {
    dispatch(
      showModal({
        modalIdentifier: "globalwaitlist",
        dataRecord: item,
      })
    );
  };

  let RenderedComponent = null;
  if (!waitlist && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Waitlists
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading onboarding requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              global waitlist, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (waitlist && waitlist.data && waitlist.data.length === 0) ||
      (waitlist && !waitlist.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className={`table-responsive ${Styles.TableResponsive}`}>
            <table className={`table ${Styles.Table}`}>
              <thead className="bg-lighter-grey">
                <tr>
                  <th>
                    <span>Email Address</span>
                  </th>
                  <th>
                    <span>Business Name</span>
                  </th>
                  <th>
                    <span>Personal Phone</span>
                  </th>
                  <th>
                    <span>Experience (In years)</span>
                  </th>
                  <th>Country</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {waitlist?.data?.map((item: WaitListDetail, id: number) => {
                  return (
                    <tr key={id}>
                      <td className={Styles.TableCell}>
                        {item?.personal_email}
                      </td>
                      <td className={Styles.TableCell}>{item.biz_name}</td>
                      <td className={Styles.TableCell}>
                        {item.personal_phone}
                      </td>
                      <td className={Styles.TableCell}>
                        {item.lending_experience_in_years}
                      </td>
                      <td className={Styles.TableCell}>{item?.country}</td>
                      <td
                        className={`${Styles.ViewDetails} ${Styles.TableCell}`}
                        onClick={() => handleViewDetails(item)}
                      >
                        View Details
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Global Waitlist</h3>
        {RenderedComponent}
      </div>

      <Paginator
        size={Number(PageSize)}
        page={Number(Page)}
        count={waitlist?.totalCount}
        changeCurrentPage={(pageNumber: number) =>
          setPage(pageNumber.toString())
        }
      />
    </div>
  );
};

export default GlobalWaitlist;
