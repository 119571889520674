import React, { useEffect, useState } from "react";

// import Dropzone from 'react-dropzone';
import { MDBDataTable } from "mdbreact";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../../api/ajax-endpoints";

import ajaxApi from "../../../../../../../api/axios-register";
import {
  ProductCatergorySectorColumn,
  reOrderRows,
  modifyProductTableRows,
  filterDataByColumns,
} from "../../../../../../../redux/actions/Product";
import { setToast } from "../../../../../../../redux/actions/toast";
import { Loader } from "../../../../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../../../../config/appInsights";

export const ProductSector = (props) => {
  const [loading, setLoading] = useState(false);

  const [tableData, setTableData] = useState({});
  useEffect(() => {
    getProductSector();
    //eslint-disable-next-line
  }, []);

  // fetch product category list

  const getProductSector = () => {
    setLoading(true);

    ajaxApi
      .get(`${ajaxEndpoints.GET_PRODUCT_CATEGORY_SECTORS}`)
      .then((response) => {
        setLoading(false);

        if (response.data.status_code === 200) {
          const reOrderedTableData = reOrderRows(
            ProductCatergorySectorColumn,
            response.data.data
          );
          const tableName = "product";
          const actions = [{ action: "view", identifier: tableName }];
          const { columns, rows } = reOrderedTableData;

          let newRows = modifyProductTableRows(
            rows,
            tableName,
            actions,
            props.modalHandler
          );

          let columnFilteredTable = filterDataByColumns(
            {
              columns: columns,
              rows: newRows,
            },
            "exclude",
            ["product_category_id", "sector_id", "id"]
          );

          // reOrderedTableData = { ...columnFilteredTable}

          setTableData(columnFilteredTable);
        } else {
          setTableData({});
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductSector.js",
          },
        });
        setLoading(false);

        if (error.response) {
          setToast("error", error.response.data.message);
        }

        setTableData({});
      });
  };

  return (
    <div>
      {loading && <Loader text="Loading....." background={true} />}

      {!loading && Object.keys(tableData).length > 0 && (
        <div>
          {/*Product category sector*/}

          <div className="grid__padding animated speed-1x fadeInRight">
            <MDBDataTable
              responsive
              minimal="true"
              striped
              bordered
              hover
              data={tableData}
            />
          </div>
        </div>
      )}
    </div>
  );
};
