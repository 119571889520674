import styles from "./badge.module.scss";

interface ICustomBadge {
  text: string;
  customStyle?: React.CSSProperties;
}

const CustomBadge = ({ text, customStyle }: ICustomBadge) => {
  return (
    <span className={styles.CustomBadge} style={customStyle}>
      {text}
    </span>
  );
};

export default CustomBadge;
