import { useEffect, useState } from "react";

import { appInsights } from "../../../../config/appInsights";
import { Loader } from "../../../../UI/Loaders/Loaders";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { TFundingRequests } from "../../../../custom-hooks/useFundingRequests";
import useFundingRequestStatus from "../../../../custom-hooks/useFundRequestStatus";
import { formatMoney } from "../../../../helpers/formatter";
import { attachClassNameToTag } from "../../../../helpers/attachClassNameToTag";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { getObjectFromLocalStorage } from "../../../../helpers/localStorage";
import { StorageKeys } from "../../../../helpers/storageKeys";

import styles from "./repaymentschedule.module.scss";

const FundingRequestApprovalStatus = ({
  properties: { dataRecord },
  onCloseModal,
}: any) => {
  const [fundingRequestInfo, setFundingRequestInfo] =
    useState<TFundingRequests>();

  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "FundingRequestApprovalStatus",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (dataRecord) {
      setFundingRequestInfo(dataRecord);
    }
  }, [dataRecord]);

  const {
    data: fundingRequestStatus,
    status,
    error,
  } = useFundingRequestStatus({ loanRef: dataRecord?.loanReference });

  let RenderedComponent = null;
  if (!fundingRequestStatus && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Funding Request
            Transaction Status
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Funding Request Status..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              funding request status, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (fundingRequestStatus && !fundingRequestStatus.data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> Funding request status unavailable
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>Account number</th>
                <th>Amount </th>
                <th>Disbursement Date</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Description</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td>{fundingRequestStatus?.data?.accountNo}</td>
                <td>{formatMoney(fundingRequestStatus?.data?.amount, "")}</td>
                <td>{fundingRequestStatus?.data?.disburseDate}</td>
                <td>{fundingRequestStatus?.data?.duration}</td>
                <td>
                  <span
                    className={attachClassNameToTag(
                      fundingRequestStatus?.data?.status
                    )}
                  >
                    {attachClassNameToTag(fundingRequestStatus?.data?.status)}
                  </span>
                </td>
                <td>{fundingRequestStatus?.data?.statusDescription}</td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div
      className={`Modal__dialog md-modal animated slideInDown ${styles.FundingWalletDetails}`}
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1 page-title d-flex align-items-center">
            <BackButtonComponent
              customGoBack={() => {
                dispatch(
                  showModal({
                    modalIdentifier: "fundingwalletdetails",
                    dataRecord: getObjectFromLocalStorage(
                      StorageKeys.FUNDING_WALLET_DETAILS
                    ),
                  })
                );
              }}
            />
            Approval Status
          </h3>
          {/* <h3 className="Modal__title p-b-1">Approval Status</h3> */}
          <div className={styles.WalletTransactions}>{RenderedComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default FundingRequestApprovalStatus;
