import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import { aMonthAgo, today } from "../../helpers/dateTimeControllers";
import { getTokenFromLocalStorage } from "../../helpers/localStorage";
import { REGULATORY_REPORT_BASE_URL } from "../../appConstants";
import { appInsights } from "../../config/appInsights";
import useTenants, {
  useTrialBalanceReport,
} from "../../custom-hooks/useTrialBalance";
import { DOWNLOAD_TRIAL_BALANCE } from "../../api/ajax-endpoints";
import Paginator from "../Pagination/Pagination.component";
import { formatMoney } from "../../helpers/formatter";

const Finance = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const [csvDownloading, setCsvDownloading] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [tenantId, setTenantId] = useState("bishopgate");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [downloadError, setDownloadError] = useState("");

  const {
    data: tenants,
    status: tenantsStatus,
    error: tenantsError,
  } = useTenants();
  const { data, status, error } = useTrialBalanceReport({
    startDate,
    endDate,
    tenantId,
  });

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  const filterData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // @ts-ignore
    const diffTime = new Date(endDate) - new Date(startDate);
    if (diffTime < 0) {
      alert("Wrong date range selected");
      return;
    }

    resetTable();
    setStartDate(aMonthAgo);
    setEndDate(today);
  };

  const handleDownloadCsv = () => {
    setCsvDownloading(true);
    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (startDate && endDate && tenantId) {
      queryParams = `?startDate=${startDate}&endDate=${endDate}&tenantId=${tenantId}`;
    }

    fetch(
      REGULATORY_REPORT_BASE_URL + `${DOWNLOAD_TRIAL_BALANCE}${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setCsvDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                startDate && endDate
                  ? `Advancly Trial Balance Report from ${startDate} to ${endDate}.csv`
                  : `Advancly Trial_Balance_Report.csv`
              }`
            );
            link.click();
          });
        }
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }
        if (response.status >= 500) {
          setCsvDownloading(false);
          setTimeout(() => {
            setDownloadError("");
          }, 3000);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Finance.tsx",
          },
        });
        setCsvDownloading(false);
      });
  };

  const handleDownloadPdf = () => {
    setPdfDownloading(true);
    let token = getTokenFromLocalStorage();
    let queryParams = "";
    if (startDate && endDate && tenantId) {
      queryParams = `?startDate=${startDate}&endDate=${endDate}&tenantId=${tenantId}&DocumentFormat=pdf`;
    }

    fetch(
      REGULATORY_REPORT_BASE_URL + `${DOWNLOAD_TRIAL_BALANCE}${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setPdfDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                startDate && endDate
                  ? `Advancly Trial Balance Report from ${startDate} to ${endDate}.pdf`
                  : `Advancly Trial_Balance_Report.pdf`
              }`
            );
            link.click();
          });
        }
        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }
        if (response.status >= 500) {
          setPdfDownloading(false);
          setTimeout(() => {
            setDownloadError("");
          }, 3000);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Finance.tsx",
          },
        });
        setPdfDownloading(false);
      });
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Trial Balance Reports
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Trial Balance Reports..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              trial balance reports, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (!data?.length || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive ">
            <h4 className="mt-4 ml-5 font-weight-bold">GL DETAILS</h4>
            <table className="table mt-4 ">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>GL Code</th>
                  <th>Name</th>
                  <th>Total Debit</th>
                  <th>Total Credit</th>
                </tr>
              </thead>
              <tbody>
                {data?.map(
                  (request: {
                    glCode: string;
                    name: string;
                    description: string;
                    debit: string;
                    credit: string;
                  }) => {
                    const { glCode, name, debit, credit } = request;
                    return (
                      <tr key={glCode}>
                        <td>{glCode}</td>
                        <td>{name}</td>
                        <td>{debit}</td>
                        <td>{credit}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  if (tenantsError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while fetching
            tenants, Please Reload The Page{" "}
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Finance Reports Header*/}
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-12 dashboard-title flex__row">
            <div className="breadcrumb__location">
              <NavLink to="dashboard">
                <h3 className="breadcrumb__location__title">Dashboard</h3>
              </NavLink>
              <span className="breadcrumb__location__icon">
                <i className="fas fa-angle-double-right" />
              </span>
              <NavLink to="/finance">
                <h3 className="breadcrumb__location__title">Finance Report</h3>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row flex-wrap ">
          <form className="d-flex flex-row m-t-md" onSubmit={filterData}>
            <div
              className="d-flex flex-row flex-wrap justify-content-start"
              style={{ alignItems: "flex-end" }}
            >
              <div className="m-l-1 m-r-1 m-b-1 form-group">
                <label htmlFor="tenant">Select Tenant:</label>
                <br />
                <select
                  onChange={(e) => {
                    setTenantId(e.target.value);
                  }}
                  id="tenant"
                  className="table-fetch-select"
                  style={{ width: "100%" }}
                  defaultValue="default"
                >
                  <option value=""></option>
                  {tenants &&
                    tenants.map((item: any) => {
                      const { full_name, tenant_id } = item;
                      return (
                        <option
                          selected={tenant_id === "bishopgate"}
                          value={tenant_id}
                          key={tenant_id}
                        >
                          {full_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="m-l-1 m-r-1 m-b-1 form-group">
                <label htmlFor="table-fetch">Start Date:</label>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={aMonthAgo}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="m-l-1 m-r-1 m-b-1 form-group">
                <label htmlFor="table-fetch">End Date:</label>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={today}
                  max={today}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              <button
                className="btn advancly-white-btn mr-2"
                type="submit"
                value="submit"
                disabled={!tenantId || status === "loading"}
                style={{ marginTop: "30px" }}
              >
                <i className="fas fa-download m-r-1" />
                Search {status === "loading" && <ButtonLoader />}
              </button>

              {/* Start of download button */}
              <button
                type="button"
                className="btn advancly-white-btn"
                onClick={handleDownloadPdf}
                disabled={
                  !tenantId ||
                  tenantsStatus === "loading" ||
                  csvDownloading ||
                  pdfDownloading
                }
                style={{ marginTop: "30px" }}
              >
                <i className="fas fa-download m-r-1" />
                Download Pdf {pdfDownloading && <ButtonLoader />}
              </button>

              <button
                type="button"
                className="btn advancly-white-btn mx-2"
                onClick={handleDownloadCsv}
                disabled={
                  !tenantId ||
                  tenantsStatus === "loading" ||
                  csvDownloading ||
                  pdfDownloading
                }
                style={{ marginTop: "30px" }}
              >
                <i className="fas fa-download m-r-1" />
                Download CSV {csvDownloading && <ButtonLoader />}
              </button>

              {/* end of download button */}
            </div>
          </form>
        </div>
        {RenderedComponent}

        {downloadError && (
          <div className="my-4">
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt p-y-5">
                <i className="fas fa-warning" /> {downloadError}
              </h4>
            </div>
          </div>
        )}
        <Paginator
          size={20}
          page={pageNumber}
          count={count}
          changeCurrentPage={(pageNumber: React.SetStateAction<number>) =>
            setPageNumber(pageNumber)
          }
        />
      </div>
    </div>
  );
};

export default Finance;
