import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

type TQuery = {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  consumerType?: string;
  accountNumber?: string;
  walletProvider?: string
};
const getWalletCustomerTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
  walletProvider
}: TQuery) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&StartDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&EndDate=${endDate}`;
  }
  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (accountNumber) {
    queryParams = `${queryParams}&AccountNumber=${accountNumber}`;
  }
  if(walletProvider) {
    queryParams = `${queryParams}&WalletProvider=${walletProvider}`;
  }
  const data = await getData(
    `${ajaxEndpoints.GET_ALL_WALLET_TRANSACTIONS}?${queryParams}`
  );

  return data?.data;
};

export default function useCustomerWalletTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
  walletProvider
}: TQuery) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, consumerType, accountNumber, walletProvider },
      "getWalletCustomerTransactions",
    ],
    getWalletCustomerTransactions
  );
}
