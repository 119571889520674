import { useQuery } from "react-query";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getLiquidationAccounts = async (id: number) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.ALL_LIQUIDATION_CHANNELS}/${id}/accounts`
  );
  return data;
};
export default function useLiquidationAccounts(id: number) {
  return useQuery([id, "getLiquidationAccounts"], getLiquidationAccounts, {
    refetchOnWindowFocus: false,
  });
}
